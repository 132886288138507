const NotificationsActionTypes = {
    SET_CONTROLS: "Notifications/SET_CONTROLS",
    SET_STATIC_DATA: "Notifications/SET_STATIC_DATA",
    SET_COMPONENT_DATA: "Notifications/SET_COMPONENT_DATA",
    SET_STATIC_DATA_API_STATUS: "Notifications/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS: "Notifications/SET_DYNAMIC_DATA_API_STATUS",
    SET_LANDING_DATA: "Notifications/SET_LANDING_DATA",
    RESET_ALL_DATA: "Notifications/RESET_ALL_DATA",
};

export default NotificationsActionTypes;
