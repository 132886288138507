import React, { useState, useMemo, useEffect, useCallback } from "react";
import PropType from "prop-types";
import stringTemplate from "string-template";

import {
    EvButton,
    EvExpandableCard,
    EvIcon,
    EvText,
} from "../../../common/components";
import {
    getAllErrorMessageFromFormData,
    isFullFormValid,
    parseFormDataForNames,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { COLORS } from "../../../common/static/VmsStatics";
import { EvMiniFormRenderer } from "../../../common/components/form-components";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { TAGS_TYPE } from "../../../common/static/Enums";

import "../styles/notifications-tag-rule-card.scss";

const styles = {
    container: "ev__notifications-tag-rule-card__container",
    contentWrapper: "ev__notifications-tag-rule-card__content-wrapper",
    descriptionHolder: "ev__notifications-tag-rule-card__description-holder",
    ruleHolder: "ev__notifications-tag-rule-card__rule-holder",
    actionButtonContainer:
        "ev__notifications-tag-rule-card__action-button-container",
    actionButtons: "ev__notifications-tag-rule-card__action-buttons",
    formWrapper: "ev__notifications-tag-rule-card__form-wrapper",
    forErrorWrapper: "ev__notifications-tag-rule-card__form-error-wrapper",
};

const NotificationsTagRuleCard = (props) => {
    const {
        tagId,
        tagData,
        isSelected,
        isExpanded,
        isEditable,
        onHeaderClick,
        staticData,
        formFields,
        formData,
        setRule,
        removeRule,
    } = props;

    // we need to keep all form changes in local until set data, then redux
    const [localFormData, setLocalFormData] = useState({});
    // const [hasForChanged, setHasFormChanged] = useState(false);
    // to show the edit view
    const [isEditing, setIsEditing] = useState(false); // TODO: change later
    // to show form validations error
    const [formErrorMessages, setFormErrorMessages] = useState([]);

    // used in multiple places to reparse initial form data, clear errors
    // editView is passed, to set the view as editing after resting
    const resetDataFromProps = useCallback(
        (editView = false) => {
            setLocalFormData({
                ...parseInitialStateFromFormFields(
                    formFields,
                    tagData.tagRuleFields || {}
                ),
                ...formData,
            });
            setFormErrorMessages([]);
            setIsEditing(editView);
        },
        [setLocalFormData, formData, tagData, formFields]
    );

    // initial set local data
    useEffect(() => {
        resetDataFromProps();
    }, [resetDataFromProps]);

    // on unselect, remove editing and temp for data
    useEffect(() => {
        if (!isExpanded) {
            // when card is collapsed, reset data. changes will be lost unless saved
            resetDataFromProps();
        } else if (isExpanded && !isSelected) {
            // when unselected tag is expanded, it should open in edit view
            setIsEditing(true);
        }
    }, [isExpanded, isSelected, resetDataFromProps]);

    // this is the string template of the form data
    const formDataStringTemplate = useMemo(() => {
        return stringTemplate(staticData.ruleDisplayTemplate, {
            ...parseFormDataForNames(localFormData),
        });
    }, [localFormData, staticData.ruleDisplayTemplate]);

    // on setting check the form validity, set rule only if valid, else show error
    const onSetRuleClick = useCallback(
        (e, callbackOptions) => {
            if (isFullFormValid(localFormData) || !isEditable) {
                setIsEditing(false);
                setRule(callbackOptions.id, localFormData);
                // setHasFormChanged(false);
            } else {
                const errorMessage = getAllErrorMessageFromFormData(
                    localFormData
                );
                setFormErrorMessages(errorMessage);
            }
        },
        [localFormData, setRule, isEditable]
    );

    // call parent function to remove tag key
    // clear local form data, and keep the view in editing phase
    const onRemoveRuleClick = useCallback(
        (e, callbackOptions) => {
            resetDataFromProps(true);
            removeRule(callbackOptions.id, {});
        },
        [removeRule, resetDataFromProps]
    );

    // clear all error messages on every edit
    const onFormDataChange = useCallback(
        (key, formDataObject) => {
            const newFormData = {
                ...localFormData,
                ...formDataObject,
            };
            setLocalFormData(newFormData);
            setFormErrorMessages([]);
            // setHasFormChanged(true);
        },
        [localFormData]
    );

    const onHeaderClickLocal = useCallback(
        (e, callbackOptions) => {
            if (!isSelected) {
                setIsEditing(true);
            }
            onHeaderClick(e, callbackOptions);
        },
        [onHeaderClick, setIsEditing, isSelected]
    );

    const onEditButtonClick = useCallback(
        (e) => {
            setIsEditing(true);
            onHeaderClick(e, { id: tagId });
        },
        [onHeaderClick, tagId, setIsEditing]
    );

    const getEditingView = () => (
        <div className={styles.formWrapper}>
            <EvMiniFormRenderer
                formItems={formFields}
                formData={localFormData}
                dataOptions={{
                    mappedField: { displayName: tagData.displayName },
                    operator: { data: tagData.operatorList },
                }}
                onFormDataChange={onFormDataChange}
            />
        </div>
    );

    const getActionableButtonView = () => {
        const callbackValues = {
            id: tagId,
            isSelected,
        };
        return (
            <div className={styles.actionButtonContainer}>
                {/* only for preset tags, dont show when already selected */}
                {!isEditable && !isSelected && (
                    <EvButton
                        className={styles.actionButtons}
                        onClickHandler={onSetRuleClick}
                        callbackValues={callbackValues}
                        primaryFilled
                    >
                        {staticData.selectTagCta.text}
                    </EvButton>
                )}
                {/* only for custom tags, always show for them */}
                {isEditable && isEditing && (
                    // hasForChanged &&
                    <EvButton
                        className={styles.actionButtons}
                        onClickHandler={onSetRuleClick}
                        callbackValues={callbackValues}
                        primaryFilled
                    >
                        {staticData.addTagCta.text}
                    </EvButton>
                )}
                {/* only for selected tags to remove them */}
                {isSelected && (
                    <EvButton
                        className={styles.actionButtons}
                        onClickHandler={onRemoveRuleClick}
                        callbackValues={callbackValues}
                        primary
                    >
                        {staticData.removeTagCta.text}
                    </EvButton>
                )}
            </div>
        );
    };

    const getStringRuleView = () => (
        <div className={styles.ruleHolder}>
            <EvText>{formDataStringTemplate}</EvText>
            {isEditable && (
                <EvButton onlyChild onClickHandler={onEditButtonClick}>
                    <EvIcon
                        iconName="EDIT"
                        size={3}
                        fillColor={COLORS.BLACK_TROUT}
                    />
                </EvButton>
            )}
        </div>
    );

    const getDescriptionView = () => (
        <div className={styles.descriptionHolder}>
            <EvText>{tagData.description}</EvText>
        </div>
    );

    const getErrorText = (message) => (
        <EvText error key={message}>
            {message}
        </EvText>
    );

    const getErrorMessageView = () => (
        <div className={styles.forErrorWrapper}>
            {formErrorMessages.map(getErrorText)}
        </div>
    );

    // BE CAREFUL while changing. Too many cases
    const cardContentDecider = () => (
        <div className={styles.contentWrapper}>
            {/* show description when available if selected or exapnded */}
            {(isExpanded || isSelected) &&
                tagData.description &&
                getDescriptionView()}

            {(isSelected || isExpanded) &&
                tagData.type === TAGS_TYPE.PRESET &&
                getStringRuleView()}

            {/* show string rule when selected or expanded and not when its editing */}
            {(isSelected || isExpanded) &&
                !isEditing &&
                tagData.type === TAGS_TYPE.CUSTOM &&
                getStringRuleView()}

            {/* show edit view when expanded and in edit state */}
            {isEditable && isEditing && isExpanded && getEditingView()}

            {/* for both type of cards */}
            {!isNullOrEmpty(formErrorMessages) && getErrorMessageView()}
            {/* show buttons only when expanded */}
            {isExpanded && getActionableButtonView()}
        </div>
    );

    // BE CAREFUL while changing. Too many cases
    // Moved to cardContentDecider above this. WILL BE REMOVED LATER
    // const cardContentDecider = () => (
    //   <div className={styles.contentWrapper}>
    //     {/* common, show description text if available */}
    //     {isExpanded && tagData.description && getDescriptionView()}
    //
    //     {/* for linked tags, non editable */}
    //     {/* for linked tags, show template string view in both selected and expanded conditions */}
    //     {!isEditable &&
    //       (isSelected || isExpanded) &&
    //       !isNullOrEmpty(tagData.tagRuleFields) &&
    //       getStringRuleView()}
    //
    //     {/* for custom tags */}
    //     {/* if selected show form template string */}
    //     {isEditable && !isEditing && isSelected && getStringRuleView()}
    //     {/* show edit view when expanded as well as expanded */}
    //     {isEditable && isEditing && isExpanded && getEditingView()}
    //
    //     {/* for both type of cards */}
    //     {!isNullOrEmpty(formErrorMessages) && getErrorMessageView()}
    //     {/* show buttons only when expanded */}
    //     {isExpanded && getActionableButtonView()}
    //   </div>
    // );

    return (
        <div className={styles.container}>
            <EvExpandableCard
                name={tagData.displayName}
                id={tagId}
                isSelected={isSelected}
                isExpanded={isExpanded || isSelected}
                value={tagData.value}
                onHeaderClick={onHeaderClickLocal}
            >
                {cardContentDecider()}
            </EvExpandableCard>
        </div>
    );
};

NotificationsTagRuleCard.propTypes = {
    tagId: PropType.string,
    tagData: PropType.object,
    formData: PropType.object,
    staticData: PropType.object,
    formFields: PropType.array,
    isSelected: PropType.bool,
    isExpanded: PropType.bool,
    isEditable: PropType.bool,

    onHeaderClick: PropType.func,
    setRule: PropType.func,
    removeRule: PropType.func,
};

NotificationsTagRuleCard.defaultProps = {
    tagId: "",
    tagData: {},
    formData: {},
    staticData: {},
    formFields: [],
    isSelected: false,
    isExpanded: false,
    isEditable: false,

    onHeaderClick: () => {},
    setRule: () => {},
    removeRule: () => {},
};

export default NotificationsTagRuleCard;
