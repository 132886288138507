import React from "react";

const EvTicketCardData = {
    data1: {
        imgUrl:
            "https://thumbs.dreamstime.com/b/ai-file-icon-web-set-technological-bacground-143848408.jpg",
        containerInlineStyles: { marginRight: 10 },
        content: (
            <div>
                <h3>Image Card</h3>
                <div>Some content along with header</div>
            </div>
        ),
        footer: <div>Footer</div>,
    },
    data2: {
        containerInlineStyles: { marginRight: 10 },
        content: (
            <div>
                <h3>No Image Card</h3>
                <div>Some content along with header</div>
            </div>
        ),
        footer: <div>Footer</div>,
    },
    data3: {
        containerInlineStyles: { marginRight: 10 },
        content: (
            <div>
                <h3>No Image Card</h3>
                <div>No image part, only header and content</div>
            </div>
        ),
        footer: <div>Footer</div>,
    },
    data4: {
        containerInlineStyles: { marginRight: 10 },
        content: (
            <div>
                <h3>No Footer Card</h3>
                <div>No footer part, only header and content with image</div>
            </div>
        ),
    },
    data5: {
        imgUrl: "",
        content: (
            <div>
                <h3>Image Card</h3>
                <div>Some content along with header</div>
            </div>
        ),
        footer: <div>Footer</div>,
    },
};

export default EvTicketCardData;
