import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";

import { EvButton, EvIcon, EvText } from "../../../../common/components";
import { ICON_NAMES } from "../../../../common/static/Enums";
import { COLORS } from "../../../../common/static/VmsStatics";
import {
    isNullOrEmpty,
    removeKeyFromObject,
} from "../../../../utils/CommonUtils";

import "../styles/vendor-program-selection-card.scss";

const styles = {
    container: "ev__vendor-program-selection-card__container",
    header: {
        container: "ev__vendor-program-selection-card__header-container",
        textContainer:
            "ev__vendor-program-selection-card__header-text-container",
        iconContainer:
            "ev__vendor-program-selection-card__header-icon-container",
        logo: "ev__vendor-program-selection-card__header-logo",
    },
    expanded: {
        animation: "ev__vendor-program-selection-card__expanded-animation",
        container: "ev__vendor-program-selection-card__expanded-container",
    },
    programs: {
        container: "ev__vendor-program-selection-card__programs-container",
        listContainer:
            "ev__vendor-program-selection-card__programs-list-container",
        itemWrapper: "ev__vendor-program-selection-card__programs-item-wrapper",
    },
};

const VendorProgramSelectionCard = (props) => {
    const {
        className,
        staticData,
        isExpanded,
        vendorData,
        programsData,
        selectedPrograms,
        onVendorSelect,
        onProgramsChange,
    } = props;

    const onProgramsClick = useCallback(
        (e, callbackValues) => {
            let newSelectedData = {};
            // remove selected program
            if (callbackValues.isSelected) {
                newSelectedData = removeKeyFromObject(
                    callbackValues.programItemData.id,
                    selectedPrograms
                );
            } else {
                // add selected program
                newSelectedData = {
                    ...selectedPrograms,
                    [callbackValues.programItemData.id]: {
                        id: callbackValues.programItemData.id,
                        value: callbackValues.programItemData.id,
                        name: callbackValues.programItemData.name,
                    },
                };
            }
            onProgramsChange(vendorData.id, newSelectedData);
        },
        [onProgramsChange, selectedPrograms, vendorData.id]
    );

    const getVendorSelectedIcon = () => (
        <EvIcon
            outerContainerStyleClass={styles.header.iconContainer}
            iconName="CHECK"
            size={3}
            bgColor={COLORS.FRUIT_SALAD}
            fillColor={COLORS.WHITE}
            circular
        />
    );

    const getHeaderView = () => {
        const isVendorHasSelectedPrograms =
            Object.keys(selectedPrograms).length > 0;
        return (
            <EvButton
                onlyChild
                onClickHandler={onVendorSelect}
                callbackValues={{ vendorId: vendorData.id }}
                className={styles.header.container}
            >
                <div className={styles.header.textContainer}>
                    <EvText subHeading>{vendorData.name}</EvText>
                    {isVendorHasSelectedPrograms && getVendorSelectedIcon()}
                </div>
                <div
                    className={styles.header.logo}
                    style={{ backgroundImage: `url(${vendorData.logo})` }}
                />
            </EvButton>
        );
    };

    const getProgramItem = (programId) => {
        const isSelected = !isNullOrEmpty(selectedPrograms[programId]);
        const programItemData = programsData.value[programId] || {};
        return (
            <EvButton
                onlyChild
                key={programId}
                className={styles.programs.itemWrapper}
                onClickHandler={onProgramsClick}
                callbackValues={{ programItemData, isSelected }}
            >
                {isSelected ? (
                    <EvIcon
                        iconName={ICON_NAMES.CHECKBOX_CHECK}
                        size={3}
                        fillColor={COLORS.PRODUCT}
                    />
                ) : (
                    <EvIcon
                        iconName={ICON_NAMES.CHECKBOX_UNCHECK}
                        strokeColor={COLORS.PRODUCT}
                        fillColor={COLORS.WHITE}
                        size={3}
                    />
                )}
                <EvText>{programItemData.name}</EvText>
            </EvButton>
        );
    };

    const getNoProgramsMessage = () => (
        <EvText italics>{staticData.noProgramsMessage || ""}</EvText>
    );

    const getProgramsView = () => (
        <div className={styles.programs.container}>
            <EvText defaultDarkBold>{staticData.programHeader}</EvText>
            <div className={styles.programs.listContainer}>
                {vendorData.programs.length <= 0 && getNoProgramsMessage()}
                {vendorData.programs.map(getProgramItem)}
            </div>
        </div>
    );

    const getExpandedView = () => (
        <CSSTransition
            in={isExpanded}
            timeout={500}
            unmountOnExit
            classNames={styles.expanded.animation}
            style={{ maxHeight: 70 + 25 * vendorData.programs.length }}
        >
            <div
                className={styles.expanded.container}
                // style={{ maxHeight: 60 + 25 * vendorData.programs.length }}
            >
                <EvText>{vendorData.description}</EvText>
                {getProgramsView()}
            </div>
        </CSSTransition>
    );

    // console.log("RENDER", props);

    const customContainerClass = classNames(styles.container, className);

    const customContainerStyle = {
        backgroundColor: isExpanded ? COLORS.WHITE : COLORS.WHITE_MYSTIC,
    };

    return (
        <div className={customContainerClass} style={customContainerStyle}>
            {getHeaderView()}
            {getExpandedView()}
        </div>
    );
};

VendorProgramSelectionCard.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    isExpanded: PropTypes.bool,
    staticData: PropTypes.object,
    onVendorSelect: PropTypes.func,
    onProgramsChange: PropTypes.func,

    vendorData: PropTypes.object,
    programsData: PropTypes.object,
    selectedPrograms: PropTypes.object,
};

VendorProgramSelectionCard.defaultProps = {
    id: "",
    className: "",
    isExpanded: false,
    staticData: {},
    onVendorSelect: () => {},
    onProgramsChange: () => {},

    vendorData: {},
    programsData: {},
    selectedPrograms: {},
};

export default VendorProgramSelectionCard;
