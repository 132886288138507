const PROGRAM_TAGS_TEMPLATES = {
    PROGRAM_TAGS_STATICS: {
        id: "programTags__statics",
    },
    SELECTION_MODAL: {
        id: "programTags__selectionModal",
    },
};

const PROGRAM_TAGS_COMPONENTS = {
    ALL_TAGS: {
        id: "programTags_all_tags",
    },
};

export { PROGRAM_TAGS_COMPONENTS, PROGRAM_TAGS_TEMPLATES };
