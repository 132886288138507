import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvButton,
    EvIcon,
    EvLoader,
    EvSimpleCard,
    EvText,
} from "../../../common/components";

import "../styles/client-dashboard-activity-card.scss";
import {
    CLIENT_PAGE_PATHS,
    LOADING_STATUS,
} from "../../../common/static/Enums";
import { getData } from "../service/ClientDetailsService";
import API_URLS from "../../../services/apiUrls";
import { COLORS } from "../../../common/static/VmsStatics";
import EvLogger from "../../../utils/EvLogger";
import { isCompleted } from "../../../utils/CommonUtils";

const styles = {
    container: "ev__client-dashboard-activity-card__container",
    contentContainer: "ev__client-dashboard-activity-card__content-container",
    contentContainerInner:
        "ev__client-dashboard-activity-card__content-container-inner",
    header: {
        container: "ev__client-dashboard-activity-card__header-container",
    },
    activity: {
        container: "ev__client-dashboard-activity-card__activity-container",
        contentText:
            "ev__client-dashboard-activity-card__activity-content-text",
    },
    action: {
        container: "ev__client-dashboard-activity-card__action-container",
        button: "ev__client-dashboard-activity-card__action-button",
    },
    loading: {
        container: "ev__client-dashboard-activity-card__loading-container",
    },
};

const MIN_CARD_HEIGHT = 100;

const ClientDashboardActivityCard = (props) => {
    const { staticData, clientCode, goToPage } = props;

    const contentContainerRef = useRef(0);

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [activityData, setActivityData] = useState([]);
    const [cardHeight, setCardHeight] = useState(MIN_CARD_HEIGHT);

    const setContentHeight = useCallback(() => {
        setCardHeight(contentContainerRef.current?.clientHeight || 0);
    }, []);

    useEffect(() => {
        getData(API_URLS.CLIENT_DASHBOARD.ACTIVITY_CARD_DATA, { clientCode })
            .then((activityDataResponse) => {
                setActivityData(activityDataResponse.data.data.preApprovals);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
                setContentHeight();
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "ClientDashboardActivityCard getData"
                );
            });
    }, [clientCode, setContentHeight]);

    const activityCount = useMemo(() => activityData.length, [activityData]);

    const onCtaClick = useCallback(() => {
        goToPage(CLIENT_PAGE_PATHS.PRE_PROD.path);
    }, [goToPage]);

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
        </div>
    );

    const getActivityView = () => (
        <div className={styles.activity.container}>
            <EvIcon
                iconName={activityCount > 0 ? "ALERT" : "CHECK"}
                circular
                bgColor={
                    activityCount > 0 ? COLORS.BLACK_TROUT : COLORS.FRUIT_SALAD
                }
                fillColor={COLORS.WHITE}
                size={3}
            />
            <EvText className={styles.activity.contentText}>
                {stringTemplate(
                    activityCount > 0
                        ? staticData.content
                        : staticData.noActivityPendingText,
                    { count: activityCount }
                )}
            </EvText>
        </div>
    );

    const getActionView = () => (
        <div className={styles.action.container}>
            <EvButton
                className={styles.action.button}
                primary
                onClickHandler={onCtaClick}
            >
                {staticData.cta.text}
            </EvButton>
        </div>
    );

    const getLoadingView = () => (
        <div
            className={styles.loading.container}
            style={{ height: MIN_CARD_HEIGHT }}
        >
            <EvLoader size={8} />
            <EvText italics>{staticData.loadingText}</EvText>
        </div>
    );

    const getContent = () => {
        if (!isCompleted(loadingStatus)) {
            // if (true) {
            return getLoadingView();
        }
        return (
            <div
                style={{ height: cardHeight }}
                className={styles.contentContainer}
            >
                <div
                    ref={contentContainerRef}
                    className={styles.contentContainerInner}
                >
                    {getHeaderView()}
                    {getActivityView()}
                    {getActionView()}
                </div>
            </div>
        );
    };

    return (
        <EvSimpleCard noPadding className={styles.container}>
            {getContent()}
        </EvSimpleCard>
    );
};

ClientDashboardActivityCard.propTypes = {
    staticData: PropTypes.object,
    clientCode: PropTypes.string,
    goToPage: PropTypes.func,
};

ClientDashboardActivityCard.defaultProps = {
    clientCode: "",
    staticData: {
        // header: "Activity Log",
        // content: "{count} pending changes",
        // noActivityPendingText: "All changes approved",
        // cta: { text: "View all activity" },
    },
    goToPage: () => {},
};

export default ClientDashboardActivityCard;
