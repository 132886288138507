import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import stringTemplate from "string-template";

import EvIcon from "./EvIcon";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-icon-text.scss";

/**
 * This is icon along with text beside it.
 * It also includes basic string templating from the same object
 */
const EvIconText = (props) => {
    const styles = {
        container: "ev__ev-icon-text__container",
        iconHolder: "ev__ev-icon-text__icon-holder",
        textHolder: "ev__ev-icon-text__text-holder",
    };

    const getIcon = (iconObject, iconSize, iconProps) => (
        <div className={styles.iconHolder}>
            <EvIcon
                iconName={iconObject.iconName}
                fillColor={iconObject.iconColor || COLORS.PRODUCT}
                bgColor={iconObject.backgroundColor}
                size={iconSize}
                {...iconProps}
            />
        </div>
    );

    const getText = (iconObject, textClass) => {
        const holderClass = classNames(styles.textHolder, textClass);
        return (
            <div
                className={holderClass}
                style={{ color: iconObject.foregroundColor }}
            >
                {stringTemplate(iconObject.text, iconObject)}
            </div>
        );
    };

    const containerClass = classNames(styles.container, props.containerClass);

    return (
        <div className={containerClass} style={props.containerInlineStyle}>
            {props.iconObject.iconName &&
                getIcon(props.iconObject, props.iconSize, props.iconProps)}
            {props.iconObject.text &&
                getText(props.iconObject, props.textClass)}
        </div>
    );
};

EvIconText.propTypes = {
    /** icon object with icon name and text */
    iconObject: PropTypes.object,
    /** specific icon size */
    iconSize: PropTypes.number,

    /** classname for main container class */
    containerClass: PropTypes.string,
    /** classname for the text container class */
    textClass: PropTypes.string,
    /** inline style for container, will overwrite all */
    containerInlineStyle: PropTypes.object,
    /** all props will be destructed and passed to icon object */
    iconProps: PropTypes.object,
};

EvIconText.defaultProps = {
    iconObject: {},
    iconSize: 3,

    containerClass: "",
    textClass: "",
    containerInlineStyle: {},
    iconProps: {},
};

export default memo(EvIconText);
