import React, { memo } from "react";
import { Link } from "react-router-dom";

import {
    EvButton,
    EvIcon,
    EvIconText,
    EvNotificationIcon,
    EvSimpleCard,
    EvUserBar,
    EvTicketCard,
    EvDivider,
    EvText,
    EvDropdownMenu,
    EvLoader,
    EvInputField,
    EvClientTicketCard,
    EvCheckList,
    EvSimpleModal,
    EvToast,
    EvFullScreenOverlay,
    EvTooltip,
    EvInfoBox,
    EvExpandableCard,
    EvTextDivider,
    EvTable,
    EvPopulationCriteriaCard,
    EvBackButton,
} from ".";
import {
    EvFormDropdown,
    EvColorTextPicker,
    EvLazyModalSelection,
    EvDropdownMinimal,
    EvCsvInput,
    EvRadio,
    EvDatePicker,
    EvTimePicker,
    EvTsvInput,
} from "./form-components";
import { COLORS } from "../static/VmsStatics";
import { DROP_DOWN_MENU_TYPE } from "./EvDropdownMenu";
import { TOAST_DURATION } from "./EvToast";

import EvUserBarData from "../static/components-mock/EvUserBarData";
import EvIconTextData from "../static/components-mock/EvIconTextData";
import EvTicketCardData from "../static/components-mock/EvTicketCardData";
import EvDropdownMenuData from "../static/components-mock/EvDropdownMenuData";
import EvFormDropdownData from "../static/components-mock/EvFormDropdownData";
import EvClientTicketCardData from "../static/components-mock/EvClientTicketCardData";
import EvInfoBoxData from "../static/components-mock/EvInfoBoxData";
import { ACTION_TYPES } from "../static/Enums";
import API_URLS from "../../services/apiUrls";

import "../styles/common-components.scss";

const simpleModalData = {
    header: "Some new Header",
    description: "Some new description",
    positiveButtonText: "Ok great!",
    negativeButtonText: "No Idea!",
    onPositiveAction: () => {
        console.log("PositiveAction");
    },
    onNegativeAction: () => {
        console.log("NegativeAction");
    },
    // exitOnOverlayClick: true,
    // exitOnActionClick: true,
    // allowBackgroundScroll: true,
};

const fullScreenOverlayData = {
    header: "Header text",
    description: "Some long description text",
    options: {
        showLoading: true,
        subHeader: "SubHeader text",
        iconOptions: {
            iconName: "ALERT",
            fillColor: COLORS.RED_MONZA,
        },
        buttonText: "Action Button text",
        buttonAction: () => {
            console.log("Action fired");
        },
        onOverlayClick: () => {
            console.log("Overlay clicked");
        },
    },
};

const toastObjects = {
    INFO_WITH_ICON_AND_HEADER: {
        header: "Toast 1",
        description: "Description about Toast 1",
        icon: {
            iconName: "USER",
            fillColor: COLORS.PRODUCT,
        },
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_INFO: {
        header: "Toast 2",
        description: "Description about Toast 2",
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_TOAST: {
        description: "Description about Toast 2",
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_ERROR: {
        description: "This is a error message",
        // type: "ERROR", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_SUCCESS: {
        description: "This is a success message",
        icon: {
            iconName: "CHECK",
            fillColor: COLORS.WHITE,
        },
        // type: "SUCCESS", // no need to send this
        // duration: 5000, // no need to send this
    },
    SIMPLE_WARN: {
        description: "This is a warn message",
        // type: "WARN", // no need to send this
        // duration: 5000, // no need to send this
    },
};

const CommonComponents = () => {
    const styles = {
        mainContainer: "ev__common-components__main-container",
        componentContainer: "ev__common-components__component-container",
        icons: { container: "ev__common-components__icons-container" },
    };

    const getColorItem = (colorKey) => (
        <div
            style={{
                display: "flex",
                marginBottom: 10,
            }}
        >
            <div
                style={{
                    backgroundColor: COLORS[colorKey],
                    width: 20,
                    height: 20,
                    marginRight: 10,
                }}
            />
            <EvText>{colorKey}</EvText>
            <EvText>{COLORS[colorKey]}</EvText>
        </div>
    );

    const getColorPalette = () => (
        <div className={styles.componentContainer}>
            <div>{Object.keys(COLORS).map(getColorItem)}</div>
        </div>
    );

    const getButtonComponent = () => (
        <div className={styles.componentContainer}>
            <EvButton buttonInlineStyle={{ margin: 10 }} primary>
                Primary Button
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} primaryFilled>
                Primary Filled
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} primaryFilledDisabled>
                Primary Filled Disabled
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} default>
                Default Button
            </EvButton>
            <EvButton
                buttonInlineStyle={{
                    margin: 10,
                    width: "100%",
                }}
                secondary
            >
                Secondary Button
            </EvButton>
            <EvButton
                buttonInlineStyle={{
                    margin: 10,
                    width: "100%",
                }}
                negativeAction
            >
                Negative Action
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} textButton>
                Text Button
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} textButton underlined>
                Underlined
            </EvButton>
            <EvButton buttonInlineStyle={{ margin: 10 }} onlyChild>
                <div style={{ backgroundColor: "#00f" }}>Only Child</div>
            </EvButton>
        </div>
    );

    const getToastButtons = () => (
        <div className={styles.componentContainer}>
            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() => EvToast.clear()}
            >
                Clear
            </EvButton>

            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.info(
                        toastObjects.INFO_WITH_ICON_AND_HEADER,
                        TOAST_DURATION.NORMAL
                    )
                }
            >
                Info with header and icon
            </EvButton>
            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.info(toastObjects.SIMPLE_INFO, TOAST_DURATION)
                }
            >
                Simple Info
            </EvButton>

            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.info(toastObjects.SIMPLE_TOAST, TOAST_DURATION.LONG)
                }
            >
                Simple Toast
            </EvButton>

            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.successAdv(
                        toastObjects.SIMPLE_SUCCESS,
                        TOAST_DURATION.NORMAL
                    )
                }
            >
                Simple Success
            </EvButton>

            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.errorAdv(
                        toastObjects.SIMPLE_ERROR,
                        TOAST_DURATION.SHORT
                    )
                }
            >
                Simple Error
            </EvButton>

            <EvButton
                buttonInlineStyle={{ margin: 10 }}
                primary
                onClickHandler={() =>
                    EvToast.warnAdv(
                        toastObjects.SIMPLE_WARN,
                        TOAST_DURATION.SHORT
                    )
                }
            >
                Simple Warn
            </EvButton>
        </div>
    );

    const getIconComponent = () => (
        <div>
            <div className={styles.componentContainer}>
                <div className={styles.icons.container}>
                    <span>Default</span>
                    <EvIcon iconName="EDIT" fillColor={COLORS.PRODUCT} />
                </div>
                <div className={styles.icons.container}>
                    <span>Circular</span>
                    <EvIcon
                        iconName="EDIT"
                        circular
                        bgColor={COLORS.PRODUCT}
                        fillColor={COLORS.WHITE}
                    />
                </div>
                <div className={styles.icons.container}>
                    <span>Bordered</span>
                    <EvIcon
                        iconName="EDIT"
                        bordered
                        circular
                        size={5}
                        borderColor={COLORS.PRODUCT}
                        fillColor={COLORS.PRODUCT}
                    />
                </div>
            </div>
            <Link to="/cc-open/components/icons">Icon Library</Link>
        </div>
    );

    const getEvIconTextComponent = () => (
        <div className={styles.componentContainer}>
            <EvIconText
                iconObject={EvIconTextData.type1}
                containerInlineStyle={{ marginRight: 10 }}
            />
            <EvIconText
                iconProps={{ circular: true }}
                iconObject={EvIconTextData.type2}
                containerInlineStyle={{ marginRight: 10 }}
            />
            <EvIconText
                iconObject={EvIconTextData.type3}
                containerInlineStyle={{ marginRight: 10 }}
            />
            <EvIconText
                iconObject={EvIconTextData.type4}
                containerInlineStyle={{ marginRight: 10 }}
            />
        </div>
    );

    const getEvNotificationIconComponent = () => (
        <div className={styles.componentContainer}>
            <EvNotificationIcon />
            <EvNotificationIcon count={1} />
            <EvNotificationIcon count={10} />
            <EvNotificationIcon count={99} />
            <EvNotificationIcon
                iconFillColor={COLORS.PRODUCT}
                count={10}
                bubbleColor={COLORS.BLACK_TROUT}
            />
        </div>
    );

    const getEvSimpleCard = () => (
        <div className={styles.componentContainer}>
            <EvSimpleCard>Small Card</EvSimpleCard>
            <EvSimpleCard noPadding inlineStyle={{ marginLeft: 10 }}>
                No padding
            </EvSimpleCard>
            <EvSimpleCard noBackground inlineStyle={{ marginLeft: 10 }}>
                Small Card without Background
            </EvSimpleCard>
            <EvSimpleCard
                inlineStyle={{
                    flex: 1,
                    marginLeft: 10,
                }}
            >
                Flex Card
            </EvSimpleCard>
        </div>
    );

    const getEvUserBarComponent = () => (
        <div className={styles.componentContainer}>
            <EvUserBar userBarData={EvUserBarData} />
        </div>
    );

    const getEvTicketCard = () => (
        <div className={styles.componentContainer}>
            <EvTicketCard {...EvTicketCardData.data1} />
            <EvTicketCard
                containerInlineStyles={{ marginRight: 10 }}
                {...EvTicketCardData.data2}
            />
            <EvTicketCard
                containerInlineStyles={{ marginRight: 10 }}
                {...EvTicketCardData.data3}
                showBackgroundOnNoImage={false}
            />
            <EvTicketCard
                containerInlineStyles={{ marginRight: 10 }}
                {...EvTicketCardData.data4}
            />
        </div>
    );

    const getEvTextComponent = () => (
        <div
            className={styles.componentContainer}
            style={{ flexDirection: "column" }}
        >
            <EvText headingPrimary>HEADING PRIMARY, 20px 300 #505664</EvText>
            <EvText headingSecondary>
                Heading Secondary, 20px 800 #000a23
            </EvText>
            <EvText subHeading>SubHeading, 20px normal #000a23</EvText>
            <br />

            <EvText default>Default, 16px normal #505664</EvText>
            <EvText defaultBold>Default Bold, 16px 800 #505664</EvText>
            <EvText defaultDark>Default Dark, 16px normal #000a23</EvText>
            <EvText defaultDarkBold>
                Default Dark Bold, 16px 800 #000aa23
            </EvText>
            <br />

            <EvText default primaryColored>
                Default, Primary Colored, 16px 800
            </EvText>
            <EvText default color="#ff2211">
                Default, Custom Colored, 16px 800
            </EvText>
            <br />

            <EvText smallNormal>Small Normal, 12px normal #505664</EvText>
            <EvText smallBold>Small Bold, 12px 800 #505664</EvText>
            <br />

            <EvText default underlined>
                Default Underlined
            </EvText>
            <EvText default italics>
                Default Italics
            </EvText>
        </div>
    );

    const getEvDropdownMenuComponent = () => (
        <div
            className={styles.componentContainer}
            style={{ alignItems: "center" }}
        >
            <EvDropdownMenu
                label="Dropdown Left"
                data={EvDropdownMenuData.data1.data}
            />
            <EvDropdownMenu
                label="Dropdown Right"
                data={EvDropdownMenuData.data1.data}
                type={DROP_DOWN_MENU_TYPE.BOTTOM_RIGHT}
            />
        </div>
    );

    const getEvDropDownMinimal = () => (
        <div
            className={styles.componentContainer}
            style={{ alignItems: "center" }}
        >
            <EvDropdownMinimal
                label="Dropdown"
                data={EvDropdownMenuData.minimalData1.data}
            />
        </div>
    );

    const getEvCsvInputComponent = () => (
        <div className={styles.componentContainer}>
            <EvCsvInput />
        </div>
    );

    const getEvTsvInputComponent = () => (
        <div
            className={styles.componentContainer}
            style={{ width: 300, overflow: "hidden" }}
        >
            <EvTsvInput />
        </div>
    );

    const getEvRadio = () => (
        <div className={styles.componentContainer}>
            <EvRadio />
        </div>
    );

    const getEvLoader = () => (
        <div>
            <div className={styles.componentContainer}>
                <span>Default</span>
                <EvLoader />
                <span>Custom</span>
                <EvLoader size={4} fillColor="red" />
            </div>
            <Link to="/cc-open/components/loading-page">EvLoadingPage</Link>
        </div>
    );

    const getEvTextDivider = () => (
        <div>
            <div
                className={styles.componentContainer}
                style={{
                    flexDirection: "column",
                    maxWidth: 500,
                }}
            >
                <EvText>With Text Divider</EvText>
                <EvTextDivider>OR</EvTextDivider>
                <EvText>Without Text Divider</EvText>
                <EvTextDivider />
            </div>
        </div>
    );

    const getEvFormDropdownComponent = () => (
        <div className={styles.componentContainer}>
            <EvFormDropdown
                label={EvFormDropdownData.data1.label}
                data={EvFormDropdownData.data1.data}
                placeholder={EvFormDropdownData.data1.placeholder}
            />
        </div>
    );

    const getEvInputFieldComponent = () => (
        <div className={styles.componentContainer}>
            <div className={styles.icons.container}>
                <span>Input field with label</span>
                <EvInputField keyName="inputBoxId1" label="Password" />
            </div>
            <div className={styles.icons.container}>
                <span>Input field without label</span>
                <EvInputField keyName="inputBoxId2" />
            </div>
            <div className={styles.icons.container}>
                <span>Input field with placeholder and custom styles</span>
                <EvInputField
                    keyName="inputBoxId3"
                    placeholder="I am the placeholder"
                    inlineStyles={{ width: "40em" }}
                />
            </div>
        </div>
    );

    const getEvColorTextComponent = () => (
        <div className={styles.componentContainer}>
            <EvColorTextPicker />
        </div>
    );

    const getEvLazyModalSelectionComponent = () => (
        <div className={styles.componentContainer}>
            <EvLazyModalSelection
                keyName="DATA_FIELD"
                formItem={{
                    id: "DATA_FIELD",
                    type: "LAZY_MODAL_SELECTION",
                    label: "",
                    description: "",
                    placeholder: "Browse Data Field",
                    editable: true,
                    defaultValue: "",
                    staticDataEndpoint:
                        API_URLS.COMMON
                            .LAZY_MODAL_SELECTION_USER_MAPPING_STATIC_DATA,
                    listDataEndpoint:
                        API_URLS.COMMON
                            .LAZY_MODAL_SELECTION_USER_MAPPING_LIST_DATA,
                    data: [],
                }}
                formData={{
                    FT: {
                        key: "FT",
                        value: "FT",
                        name: "Full Time",
                    },
                }}
                placeholder="Browse Data Fields"
            />
        </div>
    );

    const getEvClientTicketCard = () => (
        <div className={styles.componentContainer}>
            <EvClientTicketCard {...EvClientTicketCardData.data1} />
        </div>
    );

    const getEvCheckListComponent = () => (
        <div className={styles.componentContainer}>
            <EvCheckList />
        </div>
    );

    const getEvSimpleModal = () => {
        const onModalButtonClick = () => {
            EvSimpleModal.setData(simpleModalData).show();
        };

        return (
            <div className={styles.componentContainer}>
                <EvButton onClickHandler={onModalButtonClick}>
                    Click on open Modal
                </EvButton>
            </div>
        );
    };

    const getEvFullScreenOverlay = () => {
        const { header, description, options } = fullScreenOverlayData;
        const onModalButtonClick = () => {
            EvFullScreenOverlay.setData(header, description, options).show();
        };

        return (
            <div className={styles.componentContainer}>
                <EvButton onClickHandler={onModalButtonClick}>
                    Click to open FS overlay
                </EvButton>
            </div>
        );
    };

    const getEvToolTipComponent = () => {
        const customTooltipContent = () => (
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    border: "2px solid #555",
                    padding: 10,
                    display: "flex",
                }}
            >
                <EvIcon iconName="ALERT" fillColor={COLORS.RED_MONZA} />
                <EvText>Custom tooltip with icon</EvText>
            </div>
        );
        return (
            <div className={styles.componentContainer}>
                <div>
                    <EvTooltip value="This is the tooltip content">
                        <EvText>Some text which has DEFAULT tooltip</EvText>
                    </EvTooltip>
                </div>
                <div style={{ marginLeft: 20 }}>
                    <EvTooltip getCustomTooltipView={customTooltipContent}>
                        <EvText>Some text which has CUSTOM tooltip</EvText>
                    </EvTooltip>
                </div>
            </div>
        );
    };

    const getEvInfoBox = () => (
        <div className={styles.componentContainer}>
            <EvInfoBox
                inlineContainerStyle={{ maxWidth: 500 }}
                header={EvInfoBoxData.data1.header}
                description={EvInfoBoxData.data1.description}
                iconName={EvInfoBoxData.data1.iconName}
            />
        </div>
    );

    const getEvExpandableCard = () => (
        <EvExpandableCard
            title="title*"
            description="description*"
            ctaText="ctaText*"
            ctaAction={() => console.log("cta action*")}
            ctaType={ACTION_TYPES.POSITIVE}
            image="https://d25hn4jiqx5f7l.cloudfront.net/companies/logos/original/livongo_1501179918.png"
        />
    );

    const getEvDatePicker = () => (
        <div className={styles.componentContainer}>
            <EvDatePicker />
        </div>
    );

    const getEvTimePicker = () => (
        <div className={styles.componentContainer}>
            <EvTimePicker />
        </div>
    );

    const getEvTable = () => (
        <div className={styles.componentContainer}>
            <EvTable />
        </div>
    );

    const getEvPopulationCriteriaCard = () => (
        <div className={styles.componentContainer}>
            <EvPopulationCriteriaCard />
        </div>
    );

    const getEvBackButton = () => (
        <div className={styles.componentContainer}>
            <EvBackButton>Ev Back</EvBackButton>
        </div>
    );

    return (
        <div className={styles.mainContainer}>
            <h1>EvButton</h1>
            {getColorPalette()}

            <h1>EvButton</h1>
            {getButtonComponent()}

            <EvDivider />

            <h1>EvToast</h1>
            {getToastButtons()}

            <EvDivider />

            <h1>EvIcon</h1>
            {getIconComponent()}

            <EvDivider />

            <h1>EvIconText</h1>
            {getEvIconTextComponent()}

            <EvDivider />

            <h1>EvNotificationIcon</h1>
            {getEvNotificationIconComponent()}

            <EvDivider />

            <h1>EvUserBar</h1>
            {false && getEvUserBarComponent()}

            <EvDivider />

            <h1>EvSimpleCard</h1>
            {getEvSimpleCard()}

            <EvDivider />

            <h1>EvTicketCard</h1>
            {getEvTicketCard()}

            <EvDivider />

            <h1>EvText</h1>
            {getEvTextComponent()}

            <EvDivider />

            <h1>EvTooltip</h1>
            {getEvToolTipComponent()}

            <EvDivider />

            <h1>EvDropdownMenu</h1>
            {getEvDropdownMenuComponent()}

            <EvDivider />

            <h1>EvCheckList</h1>
            {getEvCheckListComponent()}

            <EvDivider />

            {/* all form components here */}

            <h1>FORM ITEMS</h1>

            <h1>EvFormChecklistDropdown</h1>
            {getEvFormDropdownComponent()}

            <h1>EvInputField</h1>
            {getEvInputFieldComponent()}

            <h1>EvColorTextInput</h1>
            {getEvColorTextComponent()}

            <h1>EvLazyModalSelection</h1>
            {getEvLazyModalSelectionComponent()}

            <h1>EvDropDownMinimal</h1>
            {getEvDropDownMinimal()}

            <h1>EvCsvInput</h1>
            {getEvCsvInputComponent()}

            <h1>EvTsvInput</h1>
            {getEvTsvInputComponent()}

            <h1>EvRadio</h1>
            {getEvRadio()}

            <h1>EvDatePicker</h1>
            {getEvDatePicker()}

            <h1>EvTimePicker</h1>
            {getEvTimePicker()}

            {/* end of form components */}

            <EvDivider />

            <h1>EvLoader</h1>
            {getEvLoader()}

            <h1>EvTextDivider</h1>
            {getEvTextDivider()}

            <EvDivider />

            <h1>EvClientTicketCard</h1>
            {getEvClientTicketCard()}

            <EvDivider />

            <h1>EvExpandableCard</h1>
            {getEvExpandableCard()}

            <EvDivider />

            <h1>EvSimpleModal</h1>
            {getEvSimpleModal()}

            <EvDivider />

            <h1>EvFullScreenOverlay</h1>
            {getEvFullScreenOverlay()}

            <h1>EvInfoBox</h1>
            {getEvInfoBox()}

            <EvDivider />

            <h1>EvTable</h1>
            {getEvTable()}

            <EvDivider />

            <h1>EvPopulationCriteriaCard</h1>
            {getEvPopulationCriteriaCard()}

            <h1>Ev Back Button</h1>
            {getEvBackButton()}

            <EvDivider />
        </div>
    );
};

export default memo(CommonComponents);
