import React, { memo } from "react";
import PropTypes from "prop-types";

import { EvText, EvSimpleCard } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-basic-preview.scss";

const EvBasicPreview = (props) => {
    const styles = {
        basicCard: {
            container: "ev__basic-preview__container",
            imageHolder: "ev__basic-preview__image-holder",
            image: "ev__basic-preview__image",
            cardInfo: "ev__basic-preview__card-info",
            descriptionOverlay: "ev__basic-preview__description-overlay",
        },
    };

    const { heading, description, imgUrl } = props;

    return (
        <div className={styles.basicCard.container}>
            <EvSimpleCard
                noPadding
                inlineStyle={{ backgroundColor: COLORS.WHITE }}
            >
                <div className={styles.basicCard.cardInfo}>
                    <EvText headingPrimary>{heading}</EvText>
                </div>
                <div className={styles.basicCard.imageHolder}>
                    <div
                        role="img"
                        aria-label="employer card banner preview"
                        className={styles.basicCard.image}
                        style={{ backgroundImage: `url(${imgUrl})` }}
                    />
                    <div className={styles.basicCard.descriptionOverlay}>
                        <EvText headingSecondary color={COLORS.WHITE}>
                            {description}
                        </EvText>
                    </div>
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvBasicPreview.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    imgUrl: PropTypes.string,
};

EvBasicPreview.defaultProps = {
    heading: "",
    description: "",
    imgUrl: "",
};

export default memo(EvBasicPreview);
