import React from "react";
import PropTypes from "prop-types";

import { isNullOrEmpty } from "../../../utils/CommonUtils";

import { EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/form-components/ev-input-error-view.scss";

const EvInputErrorView = (props) => {
    const styles = {
        container: "ev__ev-input_error-view__container",
    };

    const { errorObject, forcedError } = props;

    const getErrors = ([errId, errMsg]) => (
        <div key={errId}>
            <EvText color={COLORS.RED_MONZA} smallBold>
                {errMsg}
            </EvText>
        </div>
    );

    const getErrorView = () =>
        Object.entries(errorObject.errorMessages).map(getErrors);

    const getContent = () => {
        // for testing
        if (forcedError) {
            return getErrors(["err1", "Forced Error Text"]);
        }
        if (
            isNullOrEmpty(errorObject) ||
            isNullOrEmpty(errorObject.errorMessages) ||
            !errorObject.isUsed
        ) {
            return null;
        }
        return getErrorView();
    };

    return <div className={styles.container}>{getContent()}</div>;
};

EvInputErrorView.propTypes = {
    errorObject: PropTypes.object,
    forcedError: PropTypes.bool,
};

EvInputErrorView.defaultProps = {
    errorObject: {},
    forcedError: false,
};

export default EvInputErrorView;
