const getClientDetailsStaticDataJSON = {
    responseStatus: "SUCCESS",
    data: {
        header: "CLIENT DETAILS",
        logoPreview: {
            header: "Logo preview",
            imageFormId: "clientLogo",
            backgroundColorId: "background",
        },
        submitCta: { text: "Submit" },
        formFields: [
            {
                id: "clientType",
                label: "Client Type",
                description: "",
                placeholder: "",
                type: "RADIO",
                editable: true,
                default: "myEvive",
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "Client type is required",
                    },
                ],
                data: [
                    { id: "myEvive", name: "MyEvive", value: "myEvive" },
                    {
                        id: "eviveInside",
                        name: "Evive Inside",
                        value: "eviveInside",
                    },
                    { id: "hybrid", name: "Hybrid Client", value: "Hybrid" },
                ],
                subForm: [
                    {
                        id: "clientHashCodes",
                        matchId: "eviveInside",
                        type: "FORM_TEMPLATE",
                        label: "Port ID and Container Names",
                        itemLabel: "Port ID {count}:",
                        description: "Pairs of Port ID and Container Name",
                        addItemCta: { text: "Add Port ID" },
                        removeItemCta: { text: "Remove Port ID" },
                        alwaysPreFill: true,
                        formItems: [
                            {
                                id: "clientHash",
                                label: "Client Hash Code",
                                type: "STRING_LABEL",
                                placeholder: "Hash code not yet generated",
                            },
                            {
                                id: "portId",
                                matchId: "eviveInside",
                                label: "Port ID",
                                description: "",
                                placeholder: "Enter port ID",
                                type: "TEXT",
                                editable: true,
                                alwaysPreFill: true,
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        value: "",
                                        errMsg: "Port ID is required",
                                    },
                                    {
                                        type: "MIN_LENGTH",
                                        value: "5",
                                        errMsg:
                                            "Port ID should be of 5 character value",
                                    },
                                    {
                                        type: "MAX_LENGTH",
                                        value: "5",
                                        errMsg:
                                            "Port ID should be of 5 character value",
                                    },
                                ],
                                data: [],
                            },
                            {
                                id: "containerName",
                                matchId: "eviveInside",
                                label: "Container Name",
                                description: "",
                                placeholder: "Enter container name",
                                type: "DROPDOWN",
                                editable: true,
                                alwaysPreFill: true,
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        value: "",
                                        errMsg: "Container name is required",
                                    },
                                ],
                                data: [
                                    {
                                        id: "opt1",
                                        name: "imageset_310x352",
                                        value: "imageset_310x352",
                                    },
                                    {
                                        id: "opt2",
                                        name: "imageset_390X390",
                                        value: "imageset_390X390",
                                    },
                                    {
                                        id: "opt3",
                                        name: "imageset_myEvive",
                                        value: "imageset_myEvive",
                                    },
                                    {
                                        id: "opt4",
                                        name: "imageset_310*352_evive_inside",
                                        value: "imageset_310*352_evive_inside",
                                    },
                                    {
                                        id: "opt5",
                                        name: "imageset_390*390_evive_inside",
                                        value: "imageset_390*390_evive_inside",
                                    },
                                ],
                            },
                        ],
                        validations: [
                            {
                                type: "MIN_ITEM_COUNT",
                                value: 1,
                                errMsg:
                                    "At least 1 pair of port id and container name is required",
                            },
                        ],
                    },
                    {
                        id: "clientHashCodes",
                        matchId: "hybrid",
                        type: "FORM_TEMPLATE",
                        label: "Port ID and Container Names",
                        itemLabel: "Port ID {count}:",
                        description: "Pairs of Port ID and Container Name",
                        addItemCta: { text: "Add Port ID" },
                        removeItemCta: { text: "Remove Port ID" },
                        alwaysPreFill: true,
                        formItems: [
                            {
                                id: "clientHash",
                                label: "Client Hash Code",
                                type: "STRING_LABEL",
                                placeholder: "Hash code not yet generated",
                            },
                            {
                                id: "portId",
                                matchId: "eviveInside",
                                label: "Port ID",
                                description: "",
                                placeholder: "Enter port ID",
                                type: "TEXT",
                                editable: true,
                                alwaysPreFill: true,
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        value: "",
                                        errMsg: "Port ID is required",
                                    },
                                    {
                                        type: "MIN_LENGTH",
                                        value: "5",
                                        errMsg:
                                            "Port ID should be of 5 character value",
                                    },
                                    {
                                        type: "MAX_LENGTH",
                                        value: "5",
                                        errMsg:
                                            "Port ID should be of 5 character value",
                                    },
                                ],
                                data: [],
                            },
                            {
                                id: "containerName",
                                matchId: "eviveInside",
                                label: "Container Name",
                                description: "",
                                placeholder: "Enter container name",
                                type: "DROPDOWN",
                                editable: true,
                                alwaysPreFill: true,
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        value: "",
                                        errMsg: "Container name is required",
                                    },
                                ],
                                data: [
                                    {
                                        id: "opt1",
                                        name: "imageset_310x352",
                                        value: "imageset_310x352",
                                    },
                                    {
                                        id: "opt2",
                                        name: "imageset_390X390",
                                        value: "imageset_390X390",
                                    },
                                    {
                                        id: "opt3",
                                        name: "imageset_myEvive",
                                        value: "imageset_myEvive",
                                    },
                                    {
                                        id: "opt4",
                                        name: "imageset_310*352_evive_inside",
                                        value: "imageset_310*352_evive_inside",
                                    },
                                    {
                                        id: "opt5",
                                        name: "imageset_390*390_evive_inside",
                                        value: "imageset_390*390_evive_inside",
                                    },
                                ],
                            },
                        ],
                        validations: [
                            {
                                type: "MIN_ITEM_COUNT",
                                value: 1,
                                errMsg:
                                    "At least 1 pair of port id and container name is required",
                            },
                        ],
                    },
                ],
                listDataEndpoint: "",
            },
            {
                id: "clientLogo",
                label: "Upload logo",
                description:
                    "Maximum size limit of 1 MB. Maximum dimensions 496px * 280px. Only PNG files accepted.",
                placeholder: "Upload logo",
                type: "IMAGE_UPLOAD",
                editable: true,
                default: "",
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "Client Logo is Required",
                    },
                ],
                options: { showPreview: true },
                subForm: [],
                data: [],
                listDataEndpoint: "",
            },
            { id: "background", type: "HIDDEN_TEXT" },
            {
                id: "eligibilityProvider",
                label: "System Integrator",
                description: "",
                placeholder: "Choose integrator",
                type: "LAZY_DROPDOWN",
                editable: true,
                default: "",
                options: { lazyType: "FROM_EXTERNAL_URL" },
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "This field is required",
                    },
                ],
                subForm: [],
                data: [],
                listDataEndpoint: "/cc/api/eligibility-providers",
            },
            {
                id: "accountId",
                label: "Client Account ID",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                default: "",
                validations: [],
                subForm: [],
                data: [],
                listDataEndpoint: "",
            },
            {
                id: "clientCode",
                label: "Client Code",
                placeholder:
                    "Client code will be generated after client creation",
                description: "",
                type: "STRING_LABEL",
                editable: false,
                default: "",
            },
            {
                id: "clientName",
                label: "Client name",
                description: "",
                placeholder: "Client name",
                type: "TEXT",
                editable: true,
                default: "",
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "Client name is required",
                    },
                ],
                subForm: [],
                data: [],
                listDataEndpoint: "",
            },
            {
                id: "alternateClientNames",
                label: "Alternate Client Names",
                description:
                    "Add all alternate client names in the list. Press enter after every name.",
                placeholder: "Enter alternate name and hit Enter",
                type: "TEXT_LIST",
                editable: true,
                defaultValue: "",
                validations: [],
                options: {
                    noItemAddedInfo:
                        "No alternate names added. Add a name and press Enter",
                },
            },
            {
                id: "locales",
                label: "Languages supported",
                description: "Check all languages for this client",
                placeholder: "Select language",
                type: "CHECKLIST_DROPDOWN",
                editable: false,
                defaultValue: [
                    { id: "en_US", value: "en_US", name: "English" },
                ],
                validations: [],
                data: [
                    { id: "en_US", value: "en_US", name: "English" },
                    { id: "es", value: "es", name: "Spanish" },
                ],
                options: {
                    showSelectedItems: true,
                    dataSourceType: "INTERNAL",
                    noItemAddedInfo: "No languages selected",
                },
            },
            {
                id: "helpCenterNumber",
                label: "Help Center number",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                default: "",
                validations: [],
                subForm: [],
                data: [],
                listDataEndpoint: "",
            },
            {
                id: "coBrandedEnabled",
                label: "",
                type: "HIDDEN_TEXT",
                defaultValue: "",
            },
            {
                id: "mfaEnabled",
                label: "",
                type: "HIDDEN_TEXT",
                defaultValue: "",
            },
            {
                id: "clientDetails",
                label: "",
                type: "HIDDEN_TEXT",
                defaultValue: "",
            },
        ],
        reLoginModalData: {
            header: "New Client created successfully!",
            description:
                "Please give us a moment, we are taking you back to dashboard...",
        },
    },
};

const getClientDetailsDataJSON = {
    responseStatus: "SUCCESS",
    data: {
        clientCode: "70-04c3e90c-c6c6-5e0d-a01b-e57",
        clientName: "HCSC",
        helpCenterNumber: "",
        eligibilityProvider: {
            id: "test_integrator",
            name: "TEST_INTEGRATOR",
            value: "TEST_INTEGRATOR",
        },
        lastUpdated: "2020-08-03",
        clientLogo:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/command_center/images/uat/121ec2f6a4e0d99413534ffc1b63ee9bcfb83790233c90dc76ba788293b0d546ecbacea7105e1ef9570d6444f443328dcc3b9a50b3f9e234ed79053118d7625e.png",
        background: "#FFFFFF",
        accountId: "",
        clientType: "myEvive",
        clientHashCodes: [
            {
                clientHash:
                    "00dc2fd7cb2dcb050b7a798a04a33c84036ff81c5a6672388a7bd2981ae131195918a929456c46b7b83824dae3e46ced7101ac82920a642fc268e61a80a5ae67",
                portId: "P9008",
                containerName: "imageset_myEvive",
            },
        ],
        alternateClientNames: [],
        locales: [{ id: "en_US", name: "en_US", value: "en_US" }],
        coBrandedEnabled: false,
        mfaEnabled: false,
        clientDetails: {
            digitalClient: "V3",
            clientName: "HCSC",
            employerIdHelpText:
                "Register using your BCBS SubscriberID found on your insurance card.",
            bannerCompute: true,
            active: true,
            helpCenterEnabled: true,
            inboxEnabled: false,
            docSearchEnabled: false,
            ssnCheckEnabled: false,
            adultDependentCheckEnabled: true,
        },
    },
};

export { getClientDetailsStaticDataJSON, getClientDetailsDataJSON };
