const isNullOrEmpty = (value) => {
    if (typeof value !== "undefined" && value) {
        if (Object.keys(value).length !== 0) {
            return false;
        }
    }
    return true;
};

function normalizer(data) {
    const obj = {};
    if (data && Array.isArray(data) && data.length && data[0].id) {
        data.forEach((value) => {
            const { id, ...rest } = value;
            if (Object.keys(rest).length) {
                obj[id] = normalizer(rest);
            }
        });
        return obj;
    } else if (
        data &&
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length
    ) {
        Object.keys(data).forEach((value) => {
            obj[value] = normalizer(data[value]);
        });
        return obj;
    }
    return data;
}

function normalizerWithOrderArray(data) {
    const obj = {};
    const ids = [];
    if (data && Array.isArray(data) && data.length && data[0].id) {
        data.forEach((value) => {
            const { id, ...rest } = value;
            ids.push(id);
            if (Object.keys(rest).length) {
                obj[id] = normalizer(value);
            }
        });
        return { value: obj, ids };
    } else if (
        data &&
        typeof data === "object" &&
        !Array.isArray(data) &&
        Object.keys(data).length
    ) {
        Object.keys(data).forEach((value) => {
            obj[value] = normalizer(data[value]);
        });
        return obj;
    }
    return data;
}

const emptyNormalizedObject = { value: {}, ids: [] };

function normalizerWithOrderArraySingleLevel(data) {
    if (isNullOrEmpty(data)) {
        return emptyNormalizedObject;
    }
    const obj = {};
    const ids = [];
    if (isNullOrEmpty(data)) {
        return emptyNormalizedObject;
    }
    if (data && Array.isArray(data) && data.length && data[0].id) {
        data.forEach((value) => {
            const { id, ...rest } = value;
            ids.push(id);
            if (Object.keys(rest).length) {
                obj[id] = value;
            }
        });
        return { value: obj, ids };
    }
    return data;
}

function normalizerWithOrderArrayWithEmptyCheck(data) {
    if (isNullOrEmpty(data)) {
        return emptyNormalizedObject;
    }
    return normalizerWithOrderArray(data);
}

export {
    normalizer,
    normalizerWithOrderArray,
    normalizerWithOrderArraySingleLevel,
    emptyNormalizedObject,
    normalizerWithOrderArrayWithEmptyCheck,
};
