import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    EvBackButton,
    EvButton,
    EvHeaderView,
    EvLoadingPage,
    EvToast,
} from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import { LOADING_STATUS, RESPONSE_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger, {
    LOG_ACTION_TYPE,
    LOG_CATEGORY_TYPES,
} from "../../../utils/EvLogger";
import { parseInitialStateFromFormFields } from "../../../utils/FormUtils";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import { getData } from "../service/GamedayScheduleService";
import {
    GAMEDAY_SCHEDULE_COMPONENTS,
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_STATIC,
    GAMEDAY_SCHEDULE_TEMPLATES,
} from "../static/GamedayScheduleStatics";
import "../styles/gameday-schedule-content-view.scss";

const styles = {
    container: "ev__gameday-schedule-content-view__container",
    header: {
        container: "ev__gameday-schedule-content-view__header-container",
    },
    formView: {
        container: "ev__gameday-schedule-content-view__form-view-container",
    },
    actionView: {
        container: "ev__gameday-schedule-content-view__action-view-container",
    },
};

const GamedayScheduleContentView = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        setControls,
        getStaticData,
        setDynamicDataApiStatus,
    } = props;

    const [formData, setFormData] = useState({});

    const currentGameData = controls.currentGameData || {};
    const scheduleId = controls.scheduleId || "";

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.GAME_DAY_SCHEDULE.CONTENT.STATIC,
                    templateId: GAMEDAY_SCHEDULE_TEMPLATES.CONTENT.id,
                }),
                getData(API_URLS.GAME_DAY_SCHEDULE.CONTENT.PREFILL, {
                    clientCode,
                    gameId: currentGameData.gameId,
                    id: scheduleId,
                }),
            ]),
        [getStaticData, clientCode, currentGameData.gameId, scheduleId]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_SCHEDULE_COMPONENTS.GAME_CONTENT_PREFILL.id]:
                LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, prefillResponse]) => {
                if (
                    prefillResponse.data.responseStatus ===
                    RESPONSE_STATUS.SUCCESS
                ) {
                    const initialFormData = parseInitialStateFromFormFields(
                        staticDataResponse.formFields[
                            currentGameData.gameCategory
                        ],
                        prefillResponse.data.data
                    );
                    setFormData(initialFormData);
                    setDynamicDataApiStatus({
                        [GAMEDAY_SCHEDULE_COMPONENTS.GAME_CONTENT_PREFILL.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                } else if (
                    prefillResponse.data.responseStatus ===
                    RESPONSE_STATUS.SHOW_ERROR
                ) {
                    EvToast.error("Sorry", prefillResponse.data.message);
                    throw new Error(prefillResponse.data);
                } else {
                    throw new Error(prefillResponse);
                }
            })
            .catch((e) => {
                EvLogger.error(
                    `GamedayScheduleContent initial load data: ${clientCode}, error ${JSON.stringify(
                        e
                    )}`,
                    LOG_CATEGORY_TYPES.CC,
                    LOG_ACTION_TYPE.LOAD
                );
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.GAME_CONTENT_PREFILL.id]:
                        LOADING_STATUS.FAILED,
                });
            });
    }, [
        clientCode,
        currentGameData.gameCategory,
        getAllData,
        setDynamicDataApiStatus,
    ]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_SCHEDULE_COMPONENTS.GAME_CONTENT_PREFILL.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onScheduleClick = useCallback(() => {
        setControls({
            currentPageId: GAMEDAY_SCHEDULE_PAGES.TIME.id,
        });
    }, [setControls]);

    const onBackButtonClick = useCallback(() => {
        if (controls.scheduleId) {
            setControls({
                currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
            });
        } else {
            setControls({
                currentPageId: GAMEDAY_SCHEDULE_PAGES.SELECTION.id,
            });
        }
    }, [controls.scheduleId, setControls]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackButtonClick}>
            {GAMEDAY_SCHEDULE_STATIC.backButtonText}
        </EvBackButton>
    );

    const getHeaderView = () => {
        const gameCategoryData =
            staticData.gameCategories[currentGameData.gameCategory] || {};
        return (
            <EvHeaderView
                className={styles.header.container}
                header={staticData.header}
                description={staticData.description}
                headerResolvers={{
                    vendorProgramName: currentGameData.vendorProgramName,
                    gameCategory:
                        gameCategoryData.displayName ||
                        currentGameData.gameCategory,
                }}
            />
        );
    };

    const getFormView = () => (
        <div className={styles.formView.container}>
            <EvFormRenderer
                formFields={staticData.formFields[currentGameData.gameCategory]}
                formData={formData}
                setFormDataState={setFormData}
            />
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton primaryFilled onClickHandler={onScheduleClick}>
                {staticData.submitCta.text}
            </EvButton>
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[GAMEDAY_SCHEDULE_TEMPLATES.CONTENT.id],
            controls.dynamicDataApiStatus[
                GAMEDAY_SCHEDULE_COMPONENTS.GAME_CONTENT_PREFILL.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getActionView()}
            {getFormView()}
            {getActionView()}
        </div>
    );
};

GamedayScheduleContentView.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

GamedayScheduleContentView.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},
    setControls: () => {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
    staticData:
        state.GamedayScheduleReducer.staticData[
            GAMEDAY_SCHEDULE_TEMPLATES.CONTENT.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(GamedayScheduleActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(GamedayScheduleActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(GamedayScheduleActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleContentView);
