import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { EvFormRenderer } from "../../../common/components/form-components";
import {
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvTable,
    EvText,
    EvToast,
} from "../../../common/components";

import {
    isFullFormValid,
    markAllFieldsUsed,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { LIVE_STATUS, LOADING_STATUS } from "../../../common/static/Enums";
import { ENTERPRISE_NOTIFICATIONS_PAGES } from "../static/enterpriseNotificationsStatics";
import { isCompleted } from "../../../utils/CommonUtils";
import { getData } from "../service/enterpriseNotificationsService";
import EnterpriseNotificationsActions from "../redux/EnterpriseNotificationsActions";
import EvLogger from "../../../utils/EvLogger";
import API_URLS from "../../../services/apiUrls";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";

import staticData from "../static/json/client-selection-form.json";

import "../styles/enterprise-notifications-client-selection.scss";

const styles = {
    container: "ev__enterprise-notifications-client-selection__container",
    formContainer:
        "ev__enterprise-notifications-client-selection__form-container",
    actionContainer:
        "ev__enterprise-notifications-client-selection__action-container",
    table: {
        header: "ev__enterprise-notifications-client-selection__table-header",
    },
};

const EnterpriseNotificationsClientSelection = (props) => {
    const { setControls, setClientCodes } = props;

    const [formData, setFormData] = useState({});
    const [notificationsData, setNotificationsData] = useState({});
    const [
        notificationsDataApiStatus,
        setNotificationsDataApiStatus,
    ] = useState(LOADING_STATUS.NOT_YET_STARTED);

    useEffect(() => {
        const initialFormData = parseInitialStateFromFormFields(
            staticData.formFields
        );
        setFormData(initialFormData);
    }, []);

    useEffect(() => {
        setNotificationsDataApiStatus(LOADING_STATUS.LOADING);
        getData(
            API_URLS.ENTERPRISE_NOTIFICATIONS.CLIENT_SELECTION
                .NOTIFICATIONS_DATA
        ).then((dynamicDataResponse) => {
            setNotificationsData(dynamicDataResponse.data.data);
            setNotificationsDataApiStatus(LOADING_STATUS.COMPLETED);
        });
    }, [setNotificationsDataApiStatus]);

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            description={staticData.description}
        />
    );

    const onSubmit = useCallback(() => {
        const isFormDataValid = isFullFormValid(formData);
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
            return;
        }

        const selectedClients = formData.clients.selectedValues.map(
            (client) => client.value
        );
        setClientCodes(selectedClients);

        setControls({
            currentNotificationId: "",
            notificationStatus: LIVE_STATUS.DRAFT,
            selectedPageId: ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id,
        });

        EvToast.success("Saved", "enterprise notification clients selected", {
            icon: TOAST_ICON_OBJECTS.CHECK,
        });
    }, [formData, setClientCodes, setControls]);

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionContainer}
            buttonText={staticData.nextCta.text}
            onSubmitClick={onSubmit}
            addLabelSpacing
        />
    );

    const onFormDataChange = useCallback(
        (key, newFormData) => {
            const selectedClients = newFormData.clients.selectedValues.map(
                (client) => client.value
            );
            setClientCodes(selectedClients);
        },
        [setClientCodes]
    );

    const goToNotificationPage = useCallback(
        (notificationId, newPageId, notificationStatus) => {
            setControls({
                selectedPageId: newPageId,
                currentNotificationId: notificationId,
                notificationStatus: notificationStatus || LIVE_STATUS.DRAFT,
            }); // clear notification data if any
        },
        [setControls]
    );

    const onNotificationClick = useCallback(
        (e, notificationData) => {
            setClientCodes(notificationData.clients.split(","));
            switch (notificationData.status) {
                case LIVE_STATUS.DRAFT:
                    goToNotificationPage(
                        notificationData.notificationId,
                        ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id,
                        notificationData.status
                    );
                    break;
                case LIVE_STATUS.SCHEDULED:
                case LIVE_STATUS.LIVE:
                    goToNotificationPage(
                        notificationData.notificationId,
                        ENTERPRISE_NOTIFICATIONS_PAGES.SUMMARY.id,
                        notificationData.status
                    );
                    break;

                default:
                    EvLogger.warn(
                        `NotificationDashboard openNotification: unknown status: ${notificationData.status}`,
                        "cc",
                        "click"
                    );
            }
        },
        [goToNotificationPage, setClientCodes]
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formData={formData}
                formFields={staticData.formFields}
                setFormDataState={setFormData}
                onFormDataChange={onFormDataChange}
            />
            {getActionView()}
        </div>
    );

    const renderTableHeader = () => (
        <div className={styles.table.header}>
            <EvText subHeading>
                {staticData.latestUploadsStaticData.header}
            </EvText>
        </div>
    );

    const isLoaded = isCompleted(notificationsDataApiStatus);

    const renderNotificationsTable = () => {
        if (!isLoaded) {
            return <EvLoadingPage />;
        }

        return notificationsData.notificationList.length > 0 ? (
            <EvTable
                tableFields={staticData.latestUploadsStaticData.tableFields}
                tableData={notificationsData.notificationList}
                loadMoreCta={staticData.latestUploadsStaticData.loadMoreCta}
                onRowClick={onNotificationClick}
                isRowClickable
            />
        ) : (
            <div>
                <EvText smallNormal italics>
                    {staticData.noNotificationsMessage}
                </EvText>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {renderTableHeader()}
            {renderNotificationsTable()}
        </div>
    );
};

EnterpriseNotificationsClientSelection.propTypes = {
    controls: PropTypes.object,
    setControls: PropTypes.func,
    setClientCodes: PropTypes.func,
};

EnterpriseNotificationsClientSelection.defaultProps = {
    controls: {},
    setControls: () => {},
    setClientCodes: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EnterpriseNotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    setClientCodes: (payload) =>
        dispatch(EnterpriseNotificationsActions.setClientCodes(payload)),
    setControls: (payload) =>
        dispatch(EnterpriseNotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseNotificationsClientSelection);
