const CLIENT_CARDS_APP_ID = "clientCards";

const CLIENT_CARDS_TEMPLATES = {
    DASHBOARD_STATICS: {
        id: "clientCards__dashboardStatic",
    },
    CATEGORIES_STATICS: {
        id: "clientCards__categoriesStatics",
    },
    REORDER_STATICS: {
        id: "clientCards__reorderStatics",
    },
    CARD_TYPES: {
        id: "clientCards__cardTypes",
    },
};

const CLIENT_CARDS_COMPONENTS = {
    CARD_LIST: {
        id: "clientCards__cardList",
    },
    VENDOR_PROGRAMS: {
        id: "clientCards__vendorPrograms",
    },
    SECTIONS: {
        id: "clientCards__sections",
    },
};

const CLIENT_CARDS_ROUTES = {
    DASHBOARD: {
        id: "clientCards__dashboard",
        path: "/cc/client/client-content/cards/dashboard",
    },
    CATEGORIES: {
        id: "clientCards__categories",
        path: "/cc/client/client-content/cards/categories",
    },
    REORDER: {
        id: "clientCards__reorder",
        path: "/cc/client/client-content/cards/reorder",
    },
    CONTENT: {
        id: "clientCards__reorder",
        path: "/cc/client/client-content/cards/content",
    },
};

const CLIENT_CARDS_STATICS = {
    ALL_SECTION_ID: "ALL",
    ADDITIONAL_SECTIONS: [
        {
            id: "ALL",
            name: "All",
        },
    ],
    TABS: {
        CAMPAIGNS: "campaigns",
        VENDOR_PROGRAMS: "vendorPrograms",
    },
};

export {
    CLIENT_CARDS_APP_ID,
    CLIENT_CARDS_TEMPLATES,
    CLIENT_CARDS_COMPONENTS,
    CLIENT_CARDS_STATICS,
    CLIENT_CARDS_ROUTES,
};
