import API_URLS from "../../../services/apiUrls";
import {
    apiDelete,
    apiGet,
    apiMock,
    apiPost,
    apiPut,
} from "../../../services/api";
import EvLogger from "../../../utils/EvLogger";
import getVendorProgramsDashboardJSON from "../statics/getVendorProgramsDashboardJSON";
import getNewVendorProgramJSON from "../statics/getNewVendorProgramJSON";
import getVendorsData from "../statics/getVendorsDataJSON";
import getCustomizeProgramJSON from "../customize-program/statics/getCustomizeProgramsJSON";
import getProgramTagsJSON from "../../program-tags/statics/getProgramTagsJSON";
import { getCalloutsDataJSON } from "../../callouts/static/getCalloutDataJSON";
import getVendorProgramDataJSON from "../statics/getVendorProgramDataJSON";
import getLinkedUnlinkedTagsDataJSON from "../statics/getLinkedUnlinkedDataJSON";
import getVendorProgramSummaryDataJSON from "../../vendor-program-summary/statics/getVendorProgramSummaryDataJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // static data
        case API_URLS.VENDOR_PROGRAMS.SIDEBAR_STATICS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getVendorProgramDataJSON.SIDEBAR_STATICS)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getVendorProgramsDashboardJSON.STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.SELECTION_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getNewVendorProgramJSON.SELECTION_STATIC)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.REVIEW_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getNewVendorProgramJSON.REVIEW_STATIC)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.CUSTOMIZATION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCustomizeProgramJSON.STATIC)
                : apiGet(url, params);

        // Program Tags Static
        case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getProgramTagsJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SELECTION_MODAL_STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getProgramTagsJSON.SELECTION_MODAL_STATIC
                  )
                : apiGet(url, params);

        // CALLOUTS
        case API_URLS.CALLOUTS.STATIC_DATA:
            return USE_MOCK_DATA
                ? // eslint-disable-next-line no-undef
                  apiMock(url, params, CALLOUTS_DATA.STATIC_DATA)
                : apiGet(url, params);

        // LINKED_UNLINKED_TAGS

        case API_URLS.LINKED_UNLINKED_TAGS.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getLinkedUnlinkedTagsDataJSON.STATICS)
                : apiGet(url, params);

        case API_URLS.LINKED_UNLINKED_TAGS.GET_ALL_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getLinkedUnlinkedTagsDataJSON.ALL_TAGS)
                : apiGet(url, params);

        case API_URLS.LINKED_UNLINKED_TAGS.GET_LINKED_TAGS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getLinkedUnlinkedTagsDataJSON.LINKED_TAGS
                  )
                : apiGet(url, params);

        case API_URLS.LINKED_UNLINKED_TAGS.GET_UNLINKED_TAGS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getLinkedUnlinkedTagsDataJSON.UNLINKED_TAGS
                  )
                : apiGet(url, params);

        // PROGRAM SUMMARY
        case API_URLS.VENDOR_PROGRAMS.SUMMARY.PAGE_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getVendorProgramSummaryDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.SUMMARY.SUMMARY_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getVendorProgramSummaryDataJSON.SUMMARY_DATA
                  )
                : apiGet(url, params);

        // CALLOUTS NEW
        case API_URLS.CALLOUTS.LIST_VIEW_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.LIST_VIEW_STATIC)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CONTENT_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CONTENT_STATIC)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CALLOUTS_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CALLOUTS_LIST)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CALLOUTS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CALLOUTS_DATA)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CTA_DATA_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CTA_DATA_LIST)
                : apiGet(url, params);

        // dynamic data
        case API_URLS.VENDOR_PROGRAMS.CURRENT_VENDORS_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getVendorProgramsDashboardJSON.CURRENT_VENDORS
                  )
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.GET_VENDORS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getVendorsData.VENDORS)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.GET_PROGRAMS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getVendorsData.PROGRAMS)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.VENDOR_DETAILS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getNewVendorProgramJSON.VENDOR_DETAILS)
                : apiGet(url, params);

        default:
            EvLogger.warn("VendorProgramsService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const putData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.SAVE_VENDOR_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getNewVendorProgramJSON.SUBMIT, data)
                : apiPut(url, qParams, data);

        default:
            EvLogger.warn("VendorProgramsService putData unknown URL:", url);
            return apiPut(url, qParams, data);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM
            .ADD_CLIENT_VENDOR_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getNewVendorProgramJSON.ADD_PROGRAMS_TO_CLIENT_VENDOR,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.CALLOUTS.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getCalloutsDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        case API_URLS.LINKED_UNLINKED_TAGS.POST_TAG_RULE:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getLinkedUnlinkedTagsDataJSON.SUCCESS,
                      data
                  )
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("VendorProgramsService postData unknown URL:", url);
            return apiPut(url, qParams, data);
    }
};

const deleteData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.VENDOR_PROGRAMS.REMOVE_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getVendorsData.REMOVE_PROGRAM, data)
                : apiDelete(url, qParams, data);

        case API_URLS.LINKED_UNLINKED_TAGS.DELETE_TAG_RULE:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getLinkedUnlinkedTagsDataJSON.SUCCESS,
                      data
                  )
                : apiDelete(url, qParams, data);

        default:
            EvLogger.warn("VendorProgramsService deleteData unknown URL:", url);
            return apiPut(url, qParams, data);
    }
};

export { getData, putData, postData, deleteData };
