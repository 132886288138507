import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvText } from "../index";

import { TEXT_AREA_ROW_COUNT } from "../../static/Enums";
import FormValidation from "../../../utils/FormValidations";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/form-components/ev-text-area.scss";

/**
 * This is a multiline input field along with *some text* on it's left if passed as "label" prop.
 * keyName prop is mandatory.
 */

const EvTextArea = (props) => {
    const styles = {
        mainContainer: "ev__ev-text-area__main-container",
        inputContainer: "ev__ev-text-area__input-container",
        inputBox: "ev__ev-text-area__input-box",
        label: "ev__ev-text-area__label",
        errorContainer: "ev__ev-text-area__error-container",
    };

    const {
        placeholder,
        inlineStyles,
        value,
        editable,
        type,
        formData,
        formItem,
        showError,
    } = props;

    const customClass = classNames(styles.inputBox, props.className);

    const onChange = (e) => {
        const inputFieldObject = {
            [props.keyName]: {
                key: props.keyName,
                value: e.target.value,
                type: props.type,
            },
            errorObject: {},
            formItemType: type,
        };
        props.handleInputChange(props.keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
            },
        });
    };

    const onBlur = (e) => {
        const inputFieldObject = {
            [props.keyName]: {
                key: props.keyName,
                value: e.target.value,
                type: props.type,
            },

            errorObject: {},
            formItemType: type,
        };
        props.handleInputChange(props.keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    };

    const getErrorView = () => {
        if (
            isNullOrEmpty(formData.errorObject) ||
            !formData.errorObject.isUsed
        ) {
            return null;
        }
        return Object.keys(formData.errorObject.errorMessages).map((err) => (
            <div>
                <EvText color={COLORS.RED_MONZA} smallBold>
                    {formData.errorObject.errorMessages[err]}
                </EvText>
            </div>
        ));
    };

    const getInputField = (inputFieldData) => (
        <textarea
            key={props.keyName}
            className={customClass}
            style={inputFieldData.inlineStyles}
            placeholder={inputFieldData.placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={inputFieldData.value}
            disabled={!editable}
            cols="30"
            rows={TEXT_AREA_ROW_COUNT[type]}
        />
    );

    const getLabel = () => (
        <div className={styles.label}>
            <EvText defaultDark>{props.label}</EvText>
        </div>
    );

    return (
        <div className={styles.mainContainer}>
            <div className={styles.inputContainer}>
                {props.label && getLabel()}
                {getInputField({ placeholder, inlineStyles, value })}
            </div>
            <div className={styles.errorContainer}>
                {showError && getErrorView()}
            </div>
        </div>
    );
};

EvTextArea.propTypes = {
    /** use along inputField to give a label */
    label: PropTypes.string,
    /** use along inputField to give a placeholder */
    placeholder: PropTypes.string,
    /** key of the inputField */
    keyName: PropTypes.string,
    /** type of the inputField */
    type: PropTypes.string,
    /** is the field editable */
    editable: PropTypes.bool,
    /** className for the inputField */
    className: PropTypes.string,
    /** className for the inputField */
    value: PropTypes.string,
    showError: PropTypes.bool,

    /** customStyles for the inputField, if any */
    inlineStyles: PropTypes.object,
    formItem: PropTypes.object,
    formData: PropTypes.object,

    /** function to handle input change */
    handleInputChange: PropTypes.func,
};

EvTextArea.defaultProps = {
    label: "",
    placeholder: "",
    keyName: "",
    className: "",
    type: "",
    value: "",
    editable: true,
    showError: true,

    inlineStyles: {},
    formItem: {},
    formData: {},

    handleInputChange: () => {},
};

export default memo(EvTextArea);
