import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import stringTemplate from "string-template";

import { EvText, EvButton } from ".";
import { isNullOrEmpty } from "../../utils/CommonUtils";
import { TABLE_FIELD_TYPES } from "../static/Enums";
import {
    fullDateFormatterFromServerDate,
    fullTimeMeridiemFromServerTime,
} from "../../utils/DateTimeUtils";

import "../styles/ev-table.scss";

const styles = {
    container: "ev__ev-table__container",
    headerWrapper: "ev__ev-table__header-wrapper",
    dataWrapper: "ev__ev-table__data-wrapper",
    rowWrapperButton: "ev__ev-table__row-wrapper-button",
    rowWrapper: "ev__ev-table__row-wrapper",
    loadMoreButton: "ev__ev-table__load-more-button",
    collapsibleRowsContainer: "ev__ev-table__collapsible-row-container",
    collapsibleRowsContainerExpanded:
        "ev__ev-table__collapsible-row-container-expanded",
    row: {
        container: "ev__ev-table__row-container",
        dataRow: "ev__ev-table__row-data-row",
    },
    cells: {
        container: "ev__ev-table__cells-container",
        text: "ev__ev-table__cells-text",
        primaryText: "ev__ev-table__cells-primary-text",
        timeStamp: "ev__ev-table__cells-time-stamp",
        dataOptions: "ev__ev-table__cells-data-options",
        header: "ev__ev-table__cells-header",
    },
};

const EvTableCell = (props) => {
    const { id, field, value, onlyHeader } = props;

    const getTextCellContent = () => (
        <EvText>
            {stringTemplate(field.resolver, {
                [id]: value,
            })}
        </EvText>
    );

    const getPrimaryTextCellContent = () => (
        <EvText defaultBold primaryColored>
            {stringTemplate(field.resolver, {
                [id]: value,
            })}
        </EvText>
    );

    const getTimeStampCellContent = () => (
        <EvText>
            {stringTemplate(field.resolver, {
                date: value.date
                    ? fullDateFormatterFromServerDate(value.date)
                    : "",
                time: value.time
                    ? fullTimeMeridiemFromServerTime(value.time)
                    : "",
                timeZone: value.timeZone,
            })}
        </EvText>
    );

    const getDateOptionCellContent = () => (
        <EvText>{field.data[value].name}</EvText>
    );

    const resolveCellContent = () => {
        switch (field.type) {
            case TABLE_FIELD_TYPES.TEXT:
                return value && getTextCellContent();

            case TABLE_FIELD_TYPES.PRIMARY_TEXT:
                return value && getPrimaryTextCellContent();

            case TABLE_FIELD_TYPES.TIME_STAMP:
                return !isNullOrEmpty(value) && getTimeStampCellContent();

            case TABLE_FIELD_TYPES.DATA_OPTIONS:
                return field.data[value] && getDateOptionCellContent();

            default:
                return <span />;
        }
    };

    const getCellView = () => {
        const customCellStyle = classNames(styles.cells.container, {
            [styles.cells.text]: field.type === TABLE_FIELD_TYPES.TEXT,
            [styles.cells.primaryText]:
                field.type === TABLE_FIELD_TYPES.PRIMARY_TEXT,
            [styles.cells.timeStamp]:
                field.type === TABLE_FIELD_TYPES.TIME_STAMP,
            [styles.cells.dataOptions]:
                field.type === TABLE_FIELD_TYPES.DATA_OPTIONS,
            [styles.cells.header]: onlyHeader,
        });
        if (onlyHeader) {
            return <div className={customCellStyle}>{field.label}</div>;
        }
        // table data cell
        return <div className={customCellStyle}>{resolveCellContent()}</div>;
    };
    return getCellView();
};

EvTableCell.propTypes = {
    id: PropTypes.string,
    field: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onlyHeader: PropTypes.bool,
};

EvTableCell.defaultProps = {
    id: "",
    field: {},
    value: {},
    onlyHeader: false,
};

const EvTableRow = (props) => {
    const { fields, fieldsData, onlyHeader } = props;

    const getCellView = (field) => (
        <EvTableCell
            key={field.id}
            id={field.id}
            field={field}
            value={fieldsData[field.id]}
            onlyHeader={onlyHeader}
        />
    );

    const customRowClass = classNames(styles.row.container, {
        [styles.row.dataRow]: !onlyHeader,
    });

    return <div className={customRowClass}>{fields.map(getCellView)}</div>;
};

EvTableRow.propTypes = {
    id: PropTypes.string,
    fields: PropTypes.array,
    fieldsData: PropTypes.object,
    onlyHeader: PropTypes.bool,
};

EvTableRow.defaultProps = {
    id: "",
    fields: [],
    fieldsData: {},
    onlyHeader: false,
};

const EvTable = (props) => {
    const {
        tableFields,
        tableData,
        className,
        onRowClick,
        collapsible,
        collapsedRowCount,
        loadMoreCta,
        isRowClickable,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const onLoadMoreButtonClick = useCallback(() => {
        setIsExpanded(true);
    }, []);

    const renderTableHeader = () => (
        <div className={styles.headerWrapper}>
            {/* {tableFields.map(renderTableHeader)} */}
            <EvTableRow fields={tableFields} onlyHeader />
        </div>
    );

    const getTableRowView = (rowData) => {
        if (isRowClickable) {
            return (
                <EvButton
                    key={rowData.id}
                    onlyChild
                    onClickHandler={onRowClick}
                    className={styles.rowWrapperButton}
                    callbackValues={rowData}
                >
                    <div className={styles.rowWrapper}>
                        <EvTableRow
                            id={rowData.id}
                            fields={tableFields}
                            fieldsData={rowData}
                        />
                    </div>
                </EvButton>
            );
        }

        return (
            <div
                key={rowData.notificationId || rowData.id}
                className={styles.rowWrapper}
            >
                <EvTableRow
                    id={rowData.notificationId || rowData.id}
                    fields={tableFields}
                    fieldsData={rowData}
                />
            </div>
        );
    };

    const renderTableData = () => {
        const slicedDataDefault = collapsible
            ? tableData.slice(0, collapsedRowCount)
            : tableData;
        const slicedDataExtra = collapsible
            ? tableData.slice(collapsedRowCount)
            : {}; // keep it empty if table not collapsible
        return (
            <div className={styles.dataWrapper}>
                {slicedDataDefault.map(getTableRowView)}
                {isExpanded && (
                    <div className={styles.collapsibleRowsContainer}>
                        {slicedDataExtra.map(getTableRowView)}
                    </div>
                )}
            </div>
        );
    };

    const getLoadMoreButton = () => (
        <EvButton
            secondary
            className={styles.loadMoreButton}
            onClickHandler={onLoadMoreButtonClick}
        >
            {loadMoreCta.text}
        </EvButton>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {renderTableHeader()}
            {renderTableData()}
            {collapsible &&
                !isExpanded &&
                tableData.length > collapsedRowCount &&
                getLoadMoreButton()}
        </div>
    );
};

EvTable.propTypes = {
    tableFields: PropTypes.array,
    tableData: PropTypes.array,
    className: PropTypes.string,

    loadMoreCta: PropTypes.object,
    isRowClickable: PropTypes.bool,
    collapsible: PropTypes.bool,
    collapsedRowCount: PropTypes.number,

    onRowClick: PropTypes.func,
};

EvTable.defaultProps = {
    tableFields: [],
    tableData: [],
    className: "",

    loadMoreCta: {},
    isRowClickable: false,
    collapsible: true,
    collapsedRowCount: 4,

    onRowClick: () => {},
};

export default EvTable;
