import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvIcon } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-loader.scss";

const styles = {
    container: "ev-loader__loader-container",
    loader: "ev-loader__loader",
};

const EvLoader = (props) => {
    const { className } = props;

    const customContainerClass = classNames(styles.container, className);

    return (
        <div
            className={customContainerClass}
            style={{ width: props.size * 10, height: props.size * 10 }}
        >
            <div className={styles.loader}>
                <EvIcon
                    iconName={props.iconName}
                    size={props.size}
                    fillColor={props.fillColor}
                    strokeColor={props.strokeColor}
                />
            </div>
        </div>
    );
};

EvLoader.propTypes = {
    size: PropTypes.number,
    fillColor: PropTypes.string,
    strokeColor: PropTypes.string,
    iconName: PropTypes.string,
    className: PropTypes.string,
};
EvLoader.defaultProps = {
    size: 10,
    fillColor: COLORS.BLACK_TROUT,
    strokeColor: COLORS.WHITE,
    iconName: "LOADING",
    className: "",
};

export default EvLoader;
