import React, { memo } from "react";
import PropTypes from "prop-types";

import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { FORM_FIELD_TYPES } from "../../static/Enums";
import FormValidation from "../../../utils/FormValidations";
import { EvButton, EvIcon, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/form-components/ev-radio.scss";
import { EvInputErrorView } from "./index";

const RADIO_ICON_NAMES = {
    CHECKED: "RADIO_CHECKED",
    UNCHECKED: "RADIO_UNCHECKED",
};

const EvToggleButton = (props) => {
    const styles = {
        container: "ev__ev-radio__container",
        label: "ev__ev-radio__label",
        itemsContainer: "ev__ev-radio__items-container",
        itemWrapper: "ev__ev-radio__item-wrapper",
    };

    const {
        formData,
        keyName,
        handleInputChange,
        type,
        formItem,
        data,
        showError,
        editable,
    } = props;

    const isChecked = (itemId) => {
        if (!isNullOrEmpty(formData[itemId])) {
            return !!formData[itemId].value;
        }
        return false;
    };

    const handleOnChange = (e, radioItem) => {
        if (!editable) {
            return;
        }
        const selectedObject = {
            [radioItem.id]: {
                key: radioItem.id,
                name: radioItem.name,
                value: radioItem.value,
                type,
            },
            errorObject: {},
            formItemType: type,
        };
        handleInputChange(keyName, {
            ...selectedObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, selectedObject),
                isUsed: true,
            },
        });
    };

    const getRadioItem = (radioItem) => (
        <EvButton
            onClickHandler={handleOnChange}
            callbackValues={radioItem}
            onlyChild
            className={styles.itemWrapper}
            key={radioItem.id}
        >
            <EvIcon
                iconName={
                    isChecked(radioItem.id)
                        ? RADIO_ICON_NAMES.CHECKED
                        : RADIO_ICON_NAMES.UNCHECKED
                }
                fillColor={editable ? COLORS.PRODUCT : COLORS.WHITE_GHOST}
                strokeColor={editable ? COLORS.PRODUCT : COLORS.WHITE_GHOST}
            />
            <EvText>{radioItem.name}</EvText>
        </EvButton>
    );

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    return (
        <div className={styles.container}>
            {props.label && <span className={styles.label}>{props.label}</span>}
            <div className={styles.itemsContainer}>
                {data.map(getRadioItem)}
            </div>
            {showError && getErrorView()}
        </div>
    );
};

EvToggleButton.propTypes = {
    keyName: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,

    formItem: PropTypes.object,
    formData: PropTypes.object,
    data: PropTypes.array,
    showError: PropTypes.bool,
    editable: PropTypes.bool,

    handleInputChange: PropTypes.func,
};

EvToggleButton.defaultProps = {
    keyName: "RADIO",
    className: "",
    label: "",
    type: FORM_FIELD_TYPES.RADIO,

    formData: {
        href: { key: "href", value: "href", name: "HREF" },
    },
    formItem: {},
    showError: true,
    editable: true,
    data: [
        {
            id: "href",
            name: "HREF",
            value: "href",
        },
        {
            id: "sso",
            name: "SSO",
            value: "sso",
        },
    ],

    handleInputChange: () => {},
};

export default memo(EvToggleButton);
