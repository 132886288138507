import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";

import DashboardActions from "../redux/DashboardActions";
import {
    ADD_EDIT_USER_STATICS,
    ADD_EDIT_USER_TEMPLATES,
} from "../static/DashboardStatics";
import API_URLS from "../../../services/apiUrls";
import { getData, postData } from "../services/DashboardServices";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { LOADING_STATUS, ROUTES } from "../../../common/static/Enums";
import {
    EvBackButton,
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvToast,
} from "../../../common/components";
import EvLogger from "../../../utils/EvLogger";
import { isCompleted } from "../../../utils/CommonUtils";
import { EvFormRenderer } from "../../../common/components/form-components";

import "../styles/add-edit-user.scss";

const styles = {
    container: "ev__add-edit-user__container",
    headerContainer: "ev__add-edit-user__header-container",
    headerContentContainer: "ev__add-edit-user__header-content-container",
    formContainer: "ev__add-edit-user__form-container",
    actionContainer: "ev__add-edit-user__action-container",
};

const AddEditUser = (props) => {
    const {
        controls,
        staticData,
        getStaticData,
        location,
        navigateToPage,
        goBackAction,
    } = props;

    const [formData, setFormData] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const editUserId = useMemo(
        () =>
            location.state && location.state.editUserId
                ? location.state.editUserId
                : "",
        [location.state]
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    templateId: ADD_EDIT_USER_TEMPLATES.STATIC.id,
                    url: API_URLS.USERS.EDIT_USER_STATIC_DATA,
                }),
                getData(API_URLS.USERS.GET_USER_PREFILL_DATA, {
                    id: editUserId,
                }),
            ]),
        [getStaticData, editUserId]
    );

    useEffect(() => {
        getAllData()
            .then(([staticDataResponse, prefillDataResponse]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formItems,
                    prefillDataResponse.data.data
                );
                setFormData(initialFormData);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Something went wrong");
                EvLogger.errorWithObject(e, "AddEditUser onInitialLoad");
            });
    }, [getStaticData, getAllData]);

    const onSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", ADD_EDIT_USER_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.USERS.SAVE_USER_DATA,
            { id: editUserId },
            {
                ...parseFormDataForApi(formData),
            }
        )
            .then(() => {
                EvToast.success("", ADD_EDIT_USER_STATICS.SAVED);
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                navigateToPage({
                    path: ROUTES.DASHBOARD,
                });
            })
            .catch((e) => {
                EvLogger.errorWithObject(e, "AddEditUser onSave");
                setSubmitStatus(LOADING_STATUS.FAILED);
            });
    }, [editUserId, formData, submitStatus, navigateToPage]);

    const onBackClick = useCallback(() => {
        goBackAction();
    }, [goBackAction]);

    const getHeaderView = () => (
        <div className={styles.headerContainer}>
            <EvBackButton onClickHandler={onBackClick}>
                {staticData.backButtonCta.text}
            </EvBackButton>
            <EvHeaderView
                className={styles.headerContentContainer}
                header={staticData.header}
                description={staticData.description}
            />
        </div>
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formData={formData}
                formFields={staticData.formItems}
                setFormDataState={setFormData}
                qParams={{ userId: editUserId }}
            />
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionContainer}
            onSubmitClick={onSubmit}
            buttonText={staticData.submitCta.text}
            loadingStatus={submitStatus}
            addLabelSpacing
        />
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[ADD_EDIT_USER_TEMPLATES.STATIC.id],
            loadingStatus
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {getActionView()}
        </div>
    );
};

AddEditUser.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    location: PropTypes.object,
    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
    goBackAction: PropTypes.func,
};

AddEditUser.defaultProps = {
    controls: {},
    staticData: {},
    location: {},
    getStaticData: () => {},
    navigateToPage: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.DashboardReducer.controls,
    staticData:
        state.DashboardReducer.staticData[ADD_EDIT_USER_TEMPLATES.STATIC.id],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(DashboardActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUser);
