import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvSimpleCard, EvText } from ".";

import "../styles/ev-ticket-card.scss";

function EvTicketCard(props) {
    const styles = {
        container: "ev__ev-ticket-card__container",
        simpleCardContainer: "ev__ev-ticket-card__simple-card-container",
        innerContainer: "ev__ev-ticket-card__inner-container",
        imageContainer: "ev__ev-ticket-card__image-container",
        image: "ev__ev-ticket-card__image",
        imageDiv: "ev__ev-ticket-card__image-div",
        noLogoContainer: "ev__ev-ticket-card__no-logo-container",
        noLogoText: "ev__ev-ticket-card__no-logo-text",
        contentContainer: "ev__ev-ticket-card__content-container",
        contentContainerPadded: "ev__ev-ticket-card__content-container-padded",
        contentContainerFlexed: "ev__ev-ticket-card__content-container-flexed",
        contentContainerColored:
            "ev__ev-ticket-card__content-container-colored",
        footerContainer: "ev__ev-ticket-card__footer-container",
    };

    const { useImgTag, noContentPadding, noLogoMessage } = props;

    const isImageViewVisible = () =>
        props.imgUrl || props.showBackgroundOnNoImage;

    const getImage = (imageUrl, imageInlineStyle) => {
        if (useImgTag) {
            return (
                <img
                    alt={imageUrl}
                    className={styles.image}
                    src={imageUrl}
                    style={imageInlineStyle}
                />
            );
        }
        return (
            <div
                role="img"
                aria-label="vendor logo"
                className={styles.imageDiv}
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    ...imageInlineStyle,
                }}
            />
        );
    };

    const getNotImageView = () =>
        noLogoMessage ? (
            <div className={styles.noLogoContainer}>
                <EvText className={styles.noLogoText}>{noLogoMessage}</EvText>
            </div>
        ) : null;

    const getImageView = (imageUrl, imageInlineStyle, backgroundColor) => (
        <div className={styles.imageContainer} style={{ backgroundColor }}>
            {imageUrl
                ? getImage(imageUrl, imageInlineStyle)
                : getNotImageView()}
        </div>
    );

    const getCardContent = (
        content,
        contentContainerInlineStyle,
        contentContainerClassName
    ) => {
        const customClass = classNames(
            styles.contentContainer,
            {
                [styles.contentContainerFlexed]: !isImageViewVisible(),
                [styles.contentContainerPadded]: !noContentPadding,
            },
            contentContainerClassName
        );
        return (
            <div className={customClass} style={contentContainerInlineStyle}>
                {content}
            </div>
        );
    };

    const getFooter = (
        footer,
        footerContainerInlineStyle,
        footerContainerClassName
    ) => {
        const customClass = classNames(
            styles.footerContainer,
            footerContainerClassName
        );
        return (
            <div className={customClass} style={footerContainerInlineStyle}>
                {footer}
            </div>
        );
    };

    const customContainerClass = classNames(
        styles.container,
        props.containerClassName
    );

    return (
        <div
            className={customContainerClass}
            style={props.containerInlineStyles}
        >
            <EvSimpleCard noPadding className={styles.simpleCardContainer}>
                <div className={styles.innerContainer}>
                    {isImageViewVisible() &&
                        getImageView(
                            props.imgUrl,
                            props.imageInlineStyle,
                            props.backgroundColor
                        )}
                    {props.content &&
                        getCardContent(
                            props.content,
                            props.contentContainerInlineStyle,
                            props.contentContainerClassName
                        )}
                    {props.footer &&
                        getFooter(
                            props.footer,
                            props.footerContainerInlineStyle,
                            props.footerContainerClassName
                        )}
                </div>
            </EvSimpleCard>
        </div>
    );
}

EvTicketCard.propTypes = {
    imgUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    showBackgroundOnNoImage: PropTypes.bool,
    useImgTag: PropTypes.bool,
    noContentPadding: PropTypes.bool,
    noLogoMessage: PropTypes.string,

    containerInlineStyles: PropTypes.object,
    imageInlineStyle: PropTypes.object,
    contentContainerInlineStyle: PropTypes.object,
    footerContainerInlineStyle: PropTypes.object,

    containerClassName: PropTypes.string,
    contentContainerClassName: PropTypes.string,
    footerContainerClassName: PropTypes.string,

    content: PropTypes.node,
    footer: PropTypes.node,
};

EvTicketCard.defaultProps = {
    imgUrl: "",
    backgroundColor: "",
    showBackgroundOnNoImage: true,
    useImgTag: false,
    noContentPadding: false,
    noLogoMessage: "",

    containerInlineStyles: {},
    imageInlineStyle: {},
    contentContainerInlineStyle: {},
    footerContainerInlineStyle: {},

    containerClassName: "",
    contentContainerClassName: "",
    footerContainerClassName: "",

    content: null,
    footer: null,
};

export default memo(EvTicketCard);
