const NOTIFICATIONS_PAGES = {
    DASHBOARD: {
        id: "notificationsDashboard",
        displayName: "Dashboard",
    },
    PROGRAM_SELECTION: {
        id: "notificationsProgramSelection",
        displayName: "Program Selection",
    },
    CONTENT: {
        id: "notificationsContent",
        displayName: "Content",
    },
    TARGET_POPULATION: {
        id: "notificationTargetPopulation",
        displayName: "Target",
    },
    UPLOAD_POPULATION: {
        id: "notificationUploadPopulation",
        displayName: "Upload Population",
    },
    NEW_POPULATION: {
        id: "notificationNewPopulation",
        displayName: "New Population",
    },
    TAG_SELECTION: {
        id: "notificationsTagSelection",
        displayName: "Tag Selection",
    },
    SCHEDULE: {
        id: "notificationSchedule",
        displayName: "Schedule",
    },
    SUMMARY: {
        id: "notificationSummary",
        displayName: "Summary",
    },
};

const NOTIFICATIONS_COMPONENTS = {
    PROGRAM_SELECTION_FORM_DATA: {
        id: "notificationsProgramSelectionFormData",
    },
    CONTENT_FORM_DATA: {
        id: "notificationsContentFormData",
    },
    NEW_POPULATION_CRITERIA_LIST: {
        id: "notificationsNewPopulationCriteriaList",
    },
    NEW_POPULATION_DETAILS: {
        id: "notificationsNewPopulationDetails",
    },
    ALL_TAGS: {
        id: "notificationsAllTags",
    },
    SUMMARY_DATA: {
        id: "notificationsSummaryData",
    },
};

const NOTIFICATION_TEMPLATES = {
    PROGRAM_SELECTION_STATIC: {
        id: "notificationsProgramSelectionStatic",
    },
    CRITERIA_RULE_FORM_DATA: {
        id: "notificationsCriteriaRuleFormData",
    },
    SUMMARY_PAGE_STATIC: {
        id: "notificationsSummaryPageStatic",
    },
    TAG_SELECTION_STATIC: {
        id: "notificationsTagSelectionStatic",
    },
    TAG_CATEGORIES: {
        id: "notificationsTagSelectionCategories",
    },
    UPLOAD_POPULATION_STATIC: {
        id: "notificationsUploadPopulationStatic",
    },
};

const NOTIFICATIONS_BACK_BUTTON = {
    [NOTIFICATIONS_PAGES.DASHBOARD.id]: {},
    [NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id]: {
        text: "Back to notifications home",
        actionPageId: NOTIFICATIONS_PAGES.DASHBOARD.id,
    },
    [NOTIFICATIONS_PAGES.CONTENT.id]: {
        text: "Back to notification program selection",
        actionPageId: NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id,
    },
    [NOTIFICATIONS_PAGES.TARGET_POPULATION.id]: {
        text: "Back to create new notification",
        actionPageId: NOTIFICATIONS_PAGES.CONTENT.id,
    },
    [NOTIFICATIONS_PAGES.UPLOAD_POPULATION.id]: {
        text: "Back to create target population",
        actionPageId: NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
    },
    [NOTIFICATIONS_PAGES.NEW_POPULATION.id]: {
        text: "Back to create target population",
        actionPageId: NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
    },
    [NOTIFICATIONS_PAGES.TAG_SELECTION.id]: {
        text: "Back to create population criteria",
        actionPageId: NOTIFICATIONS_PAGES.NEW_POPULATION.id,
    },
    [NOTIFICATIONS_PAGES.SCHEDULE.id]: {
        text: "Back to create target population",
        actionPageId: NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
    },
    [NOTIFICATIONS_PAGES.SUMMARY.id]: {
        text: "Back",
        actionPageId: NOTIFICATIONS_PAGES.DASHBOARD.id,
    },
};

const NOTIFICATION_CALLOUT_PREVIEW_STATIC = {
    newTagText: "New",
};

export {
    NOTIFICATIONS_PAGES,
    NOTIFICATIONS_COMPONENTS,
    NOTIFICATION_TEMPLATES,
    NOTIFICATIONS_BACK_BUTTON,
    NOTIFICATION_CALLOUT_PREVIEW_STATIC,
};
