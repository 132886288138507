import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../../styles/ev-cards/ev-preview-action-card.scss";
import { EvSimpleCard, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

const styles = {
    container: "ev__ev-preview-action-card__container",
    simpleCard: "ev__ev-preview-action-card__simple-card",
    innerContainer: "ev__ev-preview-action-card__inner-container",
    content: {
        container: "ev__ev-preview-action-card__content-container",
        headerContainer: "ev__ev-preview-action-card__content-header-container",
        descriptionContainer:
            "ev__ev-preview-action-card__content-description-container",
    },
    action: {
        container: "ev__ev-preview-action-card__action-container",
        text: "ev__ev-preview-action-card__action-text",
    },
};

const EvPreviewActionCard = (props) => {
    const { header, description, actionText, className } = props;

    const getContent = () => (
        <div className={styles.content.container}>
            <div className={styles.content.headerContainer}>
                <EvText defaultDarkBold>{header}</EvText>
            </div>
            <div className={styles.content.descriptionContainer}>
                <EvText>{description}</EvText>
            </div>
        </div>
    );

    const getActionView = () => (
        <div className={styles.action.container}>
            <EvText
                defaultBold
                color={COLORS.WHITE}
                className={styles.action.text}
            >
                {actionText}
            </EvText>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding className={styles.simpleCard}>
                <div className={styles.innerContainer}>
                    {getContent()}
                    {getActionView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvPreviewActionCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    actionText: PropTypes.string,
    className: PropTypes.string,
};

EvPreviewActionCard.defaultProps = {
    header: "",
    description: "",
    actionText: "",
    className: "",
};

export default EvPreviewActionCard;
