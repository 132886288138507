import PropType from "prop-types";
import React from "react";
import { getFirstIdFromSelectedFormData } from "../../../utils/FormUtils";
import "../../styles/form-components/ev-form-label.scss";
import { EvText } from "../index";

const styles = { container: "ev__ev-form-label__container" };

const EvFormLabel = (props) => {
    const { formData } = props;

    const displayStringKey = getFirstIdFromSelectedFormData(formData);
    return (
        <div className={styles.container}>
            <EvText defaultDarkBold>
                {displayStringKey ? formData[displayStringKey].name : ""}
            </EvText>
        </div>
    );
};

EvFormLabel.propTypes = {
    keyName: PropType.string,
    formItem: PropType.object,
    formData: PropType.object,
    options: PropType.object,
};

EvFormLabel.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    options: {},
};

export default EvFormLabel;
