import React, { useCallback } from "react";
import PropTypes from "prop-types";

import {
    EvLazyModalSelection,
    EvCsvInput,
    EvDropdownMinimal,
    EvFormLabel,
    EvTsvInput,
} from ".";
import { FORM_FIELD_TYPES, KEY_CODES } from "../../static/Enums";

import "../../styles/form-components/ev-mini-form-renderer.scss";

const styles = {
    container: "ev__ev-mini-form-renderer__container",
    formItemsContainer: "ev__ev-mini-form-renderer__form-items-container",
    formItemsWrapper: "ev__ev-mini-form-renderer__form-items-wrapper",
};

const EvMiniFormRenderer = (props) => {
    const { formItems, formData, onFormDataChange, dataOptions } = props;

    const onFormDataChangeLocal = useCallback(
        (key, formItemObject, formItemData) => {
            const newFormItemObject = { [key]: formItemObject };
            onFormDataChange(key, newFormItemObject);
        },
        [onFormDataChange]
    );

    const formItemResolver = (formItem) => {
        switch (formItem.type) {
            case FORM_FIELD_TYPES.LAZY_MODAL_SELECTION:
                return (
                    <div key={formItem.id} className={styles.formItemsWrapper}>
                        <EvLazyModalSelection
                            keyName={formItem.id}
                            formItem={formItem}
                            formData={formData[formItem.id]}
                            placeholder={formItem.placeholder}
                            handleInputChange={onFormDataChangeLocal}
                            dataOptions={dataOptions[formItem.id] || {}} // not in use yet
                        />
                    </div>
                );

            case FORM_FIELD_TYPES.CSV_TEXT:
                return (
                    <div key={formItem.id} className={styles.formItemsWrapper}>
                        <EvCsvInput
                            keyName={formItem.id}
                            formItem={formItem}
                            formData={formData[formItem.id]}
                            placeholder={formItem.placeholder}
                            handleInputChange={onFormDataChangeLocal}
                            dataOptions={dataOptions[formItem.id] || {}} // not in use yet
                        />
                    </div>
                );

            case FORM_FIELD_TYPES.TSV_TEXT:
                return (
                    <div key={formItem.id} className={styles.formItemsWrapper}>
                        <EvTsvInput
                            keyName={formItem.id}
                            formItem={formItem}
                            formData={formData[formItem.id]}
                            placeholder={formItem.placeholder}
                            handleInputChange={onFormDataChangeLocal}
                            separator={
                                formItem.dataOptions
                                    ? formItem.dataOptions.separator || [
                                          KEY_CODES.TAB,
                                      ]
                                    : [KEY_CODES.TAB]
                            }
                            dataOptions={dataOptions[formItem.id] || {}} // not in use yet
                        />
                    </div>
                );

            case FORM_FIELD_TYPES.LAZY_MINIMAL_DROPDOWN:
            case FORM_FIELD_TYPES.MINIMAL_DROPDOWN:
                return (
                    <div key={formItem.id} className={styles.formItemsWrapper}>
                        <EvDropdownMinimal
                            keyName={formItem.id}
                            formItem={formItem}
                            formData={formData[formItem.id]}
                            placeholder={formItem.placeholder}
                            onClickHandler={onFormDataChangeLocal}
                            data={formItem.data}
                            dataOptions={dataOptions[formItem.id] || {}}
                        />
                    </div>
                );

            case FORM_FIELD_TYPES.LABEL:
                return (
                    <div key={formItem.id} className={styles.formItemsWrapper}>
                        <EvFormLabel
                            keyName={formItem.id}
                            formItem={formItem}
                            formData={formData[formItem.id]}
                            dataOptions={dataOptions[formItem.id] || {}}
                            // placeholder={formItem.placeholder}
                            // onClickHandler={onFormDataChangeLocal}
                            // data={formItem.data}
                        />
                    </div>
                );

            default:
                return <span />;
        }
    };

    const getFormItemsView = () => (
        <div className={styles.formItemsContainer}>
            {formItems.map(formItemResolver)}
        </div>
    );

    return <div className={styles.container}>{getFormItemsView()}</div>;
};

EvMiniFormRenderer.propTypes = {
    formItems: PropTypes.array,
    formData: PropTypes.object,
    onFormDataChange: PropTypes.func,
    dataOptions: PropTypes.object,
};

EvMiniFormRenderer.defaultProps = {
    formItems: [],
    formData: {},
    onFormDataChange: () => {},
    dataOptions: {},
};

export default EvMiniFormRenderer;
