import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ICON_DATA from "../static/EvSvgIconData";

import "../styles/ev-icon.scss";

const SVG_TYPES = {
    PATH: "path",
    SVG: "svg",
    G: "g",
    ELLIPSE: "ellipse",
    CIRCLE: "circle",
    LINE: "line",
    RECT: "rect",
};

const SIZE_MULTIPLIER = 5;
const MARGIN_SIZE_MULTIPLIER = 1.5;
const BORDER_SIZE = 2;

const SVG_TAG_ATTRIBUTES = {
    VIEW_BOX: "viewBox",
    TRANSFORM: "transform",
    D: "d",
    CX: "cx",
    CY: "cy",
    RX: "rx",
    RY: "ry",
    R: "r",
    X1: "x1",
    Y1: "y1",
    X2: "x2",
    Y2: "y2",
    FILL: "fill",
    STROKE: "stroke",
    STROKE_LINE_CAP: "stroke-linecap",
    STROKE_WIDTH: "strokeWidth",
    WIDTH: "width",
    HEIGHT: "height",
    CLIP_RULE: "clip-rule",
    FILL_RULE: "fill-rule",
};

/**
 * Ev Icon to render SVG icons from internal library only
 */
const EvIcon = (props) => {
    const styles = {
        mainContainer: "ev-icon__main-container",
        circularContainer: "ev-icon__circular-container",
        borderedContainer: "ev-icon__bordered-container",
        innerContainer: "ev-icon__inner-container",
    };

    const getElementStyleClass = (targetId) =>
        props.targetedElementStyleClass[targetId]
            ? props.targetedElementStyleClass[targetId]
            : props.elementStyleClass;

    const getAllAttributes = (el) => {
        const validAttributes = {};
        Object.keys(SVG_TAG_ATTRIBUTES).forEach((attributeName) => {
            const attr = el.getAttribute(SVG_TAG_ATTRIBUTES[attributeName]);
            if (attr) {
                validAttributes[SVG_TAG_ATTRIBUTES[attributeName]] = attr;
            }
        });
        return validAttributes;
    };

    const renderChild = (el, idString) => {
        const arr = [];
        for (let i = 0; i < el.childNodes.length; i++) {
            const child = el.childNodes[i];
            const elementId = `${idString}_${child.tagName}_${i}`;
            if (child.tagName === SVG_TYPES.SVG) {
                const sizeValue = props.size * SIZE_MULTIPLIER;
                arr.push(
                    <svg
                        width={`${sizeValue}`}
                        height={`${sizeValue}`}
                        id={elementId}
                        key={elementId}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </svg>
                );
            } else if (child.tagName === SVG_TYPES.G) {
                arr.push(
                    <g
                        key={elementId}
                        id={elementId}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </g>
                );
            } else if (child.tagName === SVG_TYPES.PATH) {
                arr.push(
                    <path
                        fill={props.fillColor}
                        stroke={props.strokeColor}
                        strokeWidth="0"
                        id={elementId}
                        key={elementId}
                        className={getElementStyleClass(elementId)}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </path>
                );
            } else if (child.tagName === SVG_TYPES.ELLIPSE) {
                arr.push(
                    <ellipse
                        fill={props.fillColor}
                        id={elementId}
                        key={elementId}
                        className={getElementStyleClass(elementId)}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </ellipse>
                );
            } else if (child.tagName === SVG_TYPES.CIRCLE) {
                arr.push(
                    <circle
                        fill={props.fillColor}
                        stroke={props.strokeColor}
                        id={elementId}
                        key={elementId}
                        className={getElementStyleClass(elementId)}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </circle>
                );
            } else if (child.tagName === SVG_TYPES.LINE) {
                arr.push(
                    <line
                        fill={props.fillColor}
                        stroke={props.strokeColor} // use fill color instead of fill color unless required
                        id={elementId}
                        key={elementId}
                        className={getElementStyleClass(elementId)}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </line>
                );
            } else if (child.tagName === SVG_TYPES.RECT) {
                arr.push(
                    <rect
                        fill={props.fillColor}
                        stroke={props.strokeColor} // use fill color instead of fill color unless required
                        id={elementId}
                        key={elementId}
                        strokeWidth="0" // default 0, anything else will be overwritten by getAttribute
                        className={getElementStyleClass(elementId)}
                        {...getAllAttributes(child)}
                    >
                        {renderChild(child, elementId)}
                    </rect>
                );
            }
        }
        return arr;
    };

    if (!ICON_DATA[props.iconName]) {
        return <span />;
    }
    const el = new DOMParser().parseFromString(
        ICON_DATA[props.iconName],
        "text/xml"
    );
    const containerClass = classNames([
        styles.mainContainer,
        {
            [styles.circularContainer]: props.circular,
            [styles.borderedContainer]: props.bordered,
        },
        props.outerContainerStyleClass,
    ]);
    const containerSize =
        props.size * SIZE_MULTIPLIER +
        (props.addInnerPadding ? props.size * MARGIN_SIZE_MULTIPLIER * 2 : 0);

    // (props.bordered ? BORDER_SIZE * 2 : 0) we might have to add this later

    const innerMargin = props.addInnerPadding
        ? props.size * MARGIN_SIZE_MULTIPLIER
        : 0;
    const innerContainerStyle = classNames([
        styles.innerContainer,
        props.innerContainerStyleClass,
    ]);
    return (
        <div
            className={containerClass}
            style={{
                backgroundColor: props.bgColor,
                borderColor: props.borderColor,
                width: `${containerSize}px`,
                height: `${containerSize}px`,
                ...props.outerContainerInlineStyle,
            }}
        >
            <div
                style={{
                    margin: `${innerMargin}px`,
                    ...props.innerContainerInlineStyle,
                }}
                className={innerContainerStyle}
            >
                {renderChild(el, "id")}
            </div>
        </div>
    );
};

EvIcon.propTypes = {
    /** iconname as ENUM from the library */
    iconName: PropTypes.string,

    /** icon fill color, svg nodes will have this fill color */
    fillColor: PropTypes.string,
    /** full icon background color */
    bgColor: PropTypes.string,
    /** border of the entire icon component */
    borderColor: PropTypes.string,
    /** stoke color of individual svg nodes */
    strokeColor: PropTypes.string,
    /** size of the icon, margin and padding will be calculated based on this */
    size: PropTypes.number,
    /** the container will appear circular, should be used with bgcolor */
    circular: PropTypes.bool,
    /** container border */
    bordered: PropTypes.bool,
    /** if required add extra padding */
    addInnerPadding: PropTypes.bool,

    /** inline styles for outer container, overwrites all other */
    outerContainerInlineStyle: PropTypes.object,
    /** inline styles of inner container, overwrites all other */
    innerContainerInlineStyle: PropTypes.object,
    /** style for individual element id, inspect element for id */
    targetedElementStyleClass: PropTypes.object,
    /** outer container class */
    outerContainerStyleClass: PropTypes.string,
    /** inner container class */
    innerContainerStyleClass: PropTypes.string,
    /** each svgg node class */
    elementStyleClass: PropTypes.string,
};

EvIcon.defaultProps = {
    iconName: "NEARBY_LOCATIONS",
    fillColor: "",
    bgColor: "",
    borderColor: "",
    strokeColor: "#fff",
    size: 5,
    circular: false,
    bordered: false,
    addInnerPadding: true,
    outerContainerInlineStyle: {},
    innerContainerInlineStyle: {},
    targetedElementStyleClass: {},
    outerContainerStyleClass: "",
    innerContainerStyleClass: "",
    elementStyleClass: "dummy-class",
};

export default memo(EvIcon);
export { SIZE_MULTIPLIER, MARGIN_SIZE_MULTIPLIER, BORDER_SIZE };
