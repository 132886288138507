const CUSTOMIZE_PROGRAMS_COMPONENTS = {
    // CUSTOMIZE_PROGRAMS: {
    //   id: "vendorProgramsCustomize",
    // },
};

const CUSTOMIZE_PROGRAMS_TEMPLATES = {
    STATICS: {
        id: "vendorProgramsCustomize__statics",
    },
};

const CUSTOMIZE_PROGRAMS_STATICS = {
    BACK_BUTTON: "Back",
};

const CUSTOMIZE_PROGRAMS_DEPRECATED_COMPONENT_DATA = {
    CALLOUTS: {
        header: "Callouts",
        description: "Callouts has been moved to the new Campaigns Dashboard",
        cta: {
            action: "/cc/client/client-content/cards/dashboard",
            text: "Go to new Campaigns Dashboard",
        },
    },
    CARDS: {
        header: "Cards Dashboard",
        description:
            "Cards Dashboard has been moved to the new Campaigns Dashboard",
        cta: {
            action: "/cc/client/client-content/cards/dashboard",
            text: "Go to new Campaigns Dashboard",
        },
    },
};

export {
    CUSTOMIZE_PROGRAMS_COMPONENTS,
    CUSTOMIZE_PROGRAMS_TEMPLATES,
    CUSTOMIZE_PROGRAMS_STATICS,
    CUSTOMIZE_PROGRAMS_DEPRECATED_COMPONENT_DATA,
};
