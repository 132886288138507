import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { EvSimpleCard, EvText } from "../../../../common/components";

import "../styles/vendor-programs-review-card.scss";

const styles = {
    container: "ev__vendor-programs-review-card__container",
    contentContainer: "ev__vendor-programs-review-card__content-container",
    logoView: "ev__vendor-programs-review-card__logo-view",
    vendorNameText: "ev__vendor-programs-review-card__vendor-name-text",
    selectedProgramText:
        "ev__vendor-programs-review-card__selected-programs-text",
};

const VendorProgramsReviewCard = (props) => {
    const { staticData, vendorData, selectedPrograms } = props;

    const allProgramsString = useMemo(() => {
        let programsString = "";
        Object.values(selectedPrograms).forEach((programItem) => {
            programsString = `${programsString}${programsString ? ", " : ""} ${
                programItem.name
            }`;
        });
        return programsString;
    }, [selectedPrograms]);

    const getContent = () => (
        <div className={styles.contentContainer}>
            <div
                className={styles.logoView}
                style={{ backgroundImage: `url(${vendorData.logo})` }}
            />
            <EvText defaultDarkBold className={styles.vendorNameText}>
                {vendorData.name}
            </EvText>
            <EvText dangerous className={styles.selectedProgramText}>
                {`${staticData.selectedProgramsHeader} <b>${allProgramsString}</b>`}
            </EvText>
        </div>
    );

    return (
        <EvSimpleCard className={styles.container} noPadding>
            {getContent()}
        </EvSimpleCard>
    );
};

VendorProgramsReviewCard.propTypes = {
    staticData: PropTypes.object,
    vendorData: PropTypes.object,
    selectedPrograms: PropTypes.object,
};

VendorProgramsReviewCard.defaultProps = {
    staticData: {},
    vendorData: {},
    selectedPrograms: {},
};

export default VendorProgramsReviewCard;
