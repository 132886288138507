import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { EvText } from "../index";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import "../../styles/content-items/ev-content-array.scss";

const styles = {
    container: "ev__ev-content-array__container",
    header: {
        container: "ev__ev-content-array__header-container",
    },
    list: {
        container: "ev__ev-content-array__list-container",
        itemWrapper: "ev__ev-content-array__list-item-wrapper",
    },
};

const EvContentArray = (props) => {
    const { label, dependency, displayResolver, allValues } = props;

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText>{label}</EvText>
        </div>
    );

    const getListItemView = (itemData) => (
        <div key={itemData.id} className={styles.list.itemWrapper}>
            <EvText dangerous>
                {stringTemplate(displayResolver, itemData)}
            </EvText>
        </div>
    );

    const getListView = () => {
        const arrayData = allValues[dependency];
        if (isNullOrEmpty(arrayData)) {
            return <span />;
        }
        return (
            <div className={styles.list.container}>
                {allValues[dependency].map(getListItemView)}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {label && getHeaderView()}
            {getListView()}
        </div>
    );
};

EvContentArray.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentArray.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
};

export default EvContentArray;
