import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import API_URLS from "../../../services/apiUrls";
import { getData, postData } from "../service/NotificationsService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { NOTIFICATIONS_PAGES } from "../static/NotificationsStatics";
import NotificationsActions from "../redux/NotificationsActions";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvText,
    EvToast,
    EvLoadingPage,
    EvSubmitActionView,
} from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";

import "../styles/notifications-scheduling.scss";

const NotificationsScheduling = (props) => {
    const styles = {
        container: "ev__notifications-scheduling__container",
        header: "ev__notifications-scheduling__header",
        description: "ev__notifications-scheduling__description",
        formContainer: "ev__notifications-scheduling__form-container",
        nextButton: "ev__notifications-scheduling__next-button",
    };

    const {
        clientCode,
        controls,
        staticData,
        getStaticData,
        setControls,
    } = props;

    const [formData, setFormData] = useState({});
    const [schedulingDataApiStatus, setSchedulingDataApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getStaticDataLocal = useCallback(
        () =>
            getStaticData({
                url: API_URLS.NOTIFICATIONS.SCHEDULE.STATIC,
                templateId: NOTIFICATIONS_PAGES.SCHEDULE.id,
            }),
        [getStaticData]
    );

    const getDynamicData = useCallback(
        () =>
            getData(API_URLS.NOTIFICATIONS.SCHEDULE.PRE_FILLED_DATA, {
                clientCode,
                notificationId: controls.currentNotificationId || "",
            }),
        [clientCode, controls.currentNotificationId]
    );

    useEffect(() => {
        setSchedulingDataApiStatus(LOADING_STATUS.LOADING);
        Promise.all([getStaticDataLocal(), getDynamicData()]).then(
            ([staticDataResponse, dynamicDataResponse]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formFields,
                    dynamicDataResponse.data.data
                );
                setFormData(initialFormData);
                setSchedulingDataApiStatus(LOADING_STATUS.COMPLETED);
            }
        );
    }, [getDynamicData, getStaticDataLocal]);

    const isLoading = () =>
        !isCompleted(
            controls.staticDataApiStatus[NOTIFICATIONS_PAGES.SCHEDULE.id],
            schedulingDataApiStatus
        );

    const onFormDataChange = (key, formItemObject) => {
        setFormData({
            ...formData,
            ...formItemObject,
        });
    };

    const onFormSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const notificationId = controls.currentNotificationId || "";
        const isFormDataValid = isFullFormValid(formData);
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
        } else {
            setSubmitStatus(LOADING_STATUS.LOADING);
            const apiData = parseFormDataForApi(formData);
            postData(
                API_URLS.NOTIFICATIONS.SCHEDULE.POST_DATA,
                {
                    notificationId,
                },
                {
                    ...apiData,
                    clientCode,
                }
            )
                .then((response) => {
                    setSubmitStatus(LOADING_STATUS.COMPLETED);
                    EvToast.success("Saved", "", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    setControls({
                        selectedPageId: NOTIFICATIONS_PAGES.SUMMARY.id,
                    });
                })
                .catch((e) => {
                    setSubmitStatus(LOADING_STATUS.FAILED);
                    EvToast.error("Sorry", "Something went wrong", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    EvLogger.errorWithObject(
                        e,
                        "NotificationScheduling onFormSubmitClick"
                    );
                });
        }
    }, [
        formData,
        clientCode,
        submitStatus,
        controls.currentNotificationId,
        setControls,
    ]);

    const getHeader = () => (
        <div className={styles.header}>
            <EvText subHeading>{staticData.header}</EvText>
        </div>
    );

    const getDescription = () => (
        <div className={styles.description}>
            <EvText defaultDark>{staticData.description}</EvText>
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            buttonText={staticData.nextCta.text}
            onSubmitClick={onFormSubmitClick}
            className={styles.nextButton}
            loadingStatus={submitStatus}
            addLabelSpacing
        />
    );

    const getForm = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                onFormDataChange={onFormDataChange}
            />
            {getActionView()}
        </div>
    );

    const renderPageContent = () => (
        <div className={styles.container}>
            <div>
                {getHeader()}
                {getDescription()}
            </div>
            {getForm()}
        </div>
    );

    return isLoading() ? <EvLoadingPage /> : renderPageContent();
};

NotificationsScheduling.propTypes = {
    staticData: PropTypes.object,
    controls: PropTypes.object,
    clientCode: PropTypes.string,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
};

NotificationsScheduling.defaultProps = {
    staticData: {},
    controls: {},
    clientCode: "",

    getStaticData: () => {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.NotificationsReducer.staticData[NOTIFICATIONS_PAGES.SCHEDULE.id],
    controls: state.NotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsScheduling);
