const EvIconTextData = {
    type1: {
        iconColor: "",
        iconName: "CONTACT",
        header: "",
        value: "",
        text: "Basic Icon Text",
        backgroundColor: "",
        foregroundColor: "#4a4a4a",
    },
    type2: {
        iconColor: "#fff",
        iconName: "CONTACT",
        header: "",
        value: "",
        text: "Circular Icon Text",
        backgroundColor: "#3899EB",
        foregroundColor: "#4a4a4a",
    },
    type3: {
        iconColor: "#3899EB",
        iconName: "CONTACT",
        header: "",
        value: "",
        text: "Colored Text",
        backgroundColor: "",
        foregroundColor: "#3899EB",
    },
    type4: {
        iconColor: "#3899EB",
        iconName: "CONTACT",
        header: "",
        value: "Value",
        text: "Text with resolver {value}",
        backgroundColor: "",
        foregroundColor: "#4a4a4a",
    },
};

export default EvIconTextData;
