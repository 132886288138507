import DashboardActionTypes from "./DashbaordActionTypes";
import { getData } from "../services/DashboardServices";

import { LOADING_STATUS } from "../../../common/static/Enums";
import { isCompleted } from "../../../utils/CommonUtils";

const DashboardActions = {
    // fetchClientListData: payload => dispatch => {
    //   dispatch(
    //     DashboardActions.setControls({
    //       clientListDataLoadingStatus: LOADING_STATUS.LOADING,
    //       clientListStaticDataLoadingStatus: LOADING_STATUS.LOADING,
    //     })
    //   );
    //   Promise.all([
    //     getData(API_URLS.DASHBOARD.GET_CLIENT_LIST_STATIC_DATA),
    //     getData(API_URLS.DASHBOARD.GET_CLIENT_LIST_DATA),
    //   ])
    //     .then(([clientListStaticData, clientListData]) => {
    //       dispatch(
    //         DashboardActions.setData({
    //           clientListStaticData: clientListStaticData.data.data,
    //           clientListData: clientListData.data.data,
    //         })
    //       );
    //       dispatch(
    //         DashboardActions.setControls({
    //           clientListDataLoadingStatus: LOADING_STATUS.COMPLETED,
    //           clientListStaticDataLoadingStatus: LOADING_STATUS.COMPLETED,
    //         })
    //       );
    //     })
    //     .catch(e => {
    //       DashboardActions.setControls({
    //         clientListDataLoadingStatus: LOADING_STATUS.FAILED,
    //         clientListStaticDataLoadingStatus: LOADING_STATUS.FAILED,
    //       });
    //       EvLogger.errorWithObject(e, "DashboardActions");
    //     });
    // },

    // setControls: payload => ({
    //   type: DashboardActionTypes.SET_CONTROLS,
    //   payload,
    // }),
    //
    // setData: payload => ({ type: DashboardActionTypes.SET_DATA, payload }),

    setStaticDataApiStatus: (payload) => ({
        type: DashboardActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    setStaticData: (payload) => ({
        type: DashboardActionTypes.SET_STATIC_DATA,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        // TODO: check this logic later
        // do not load static data if already in reducer
        if (
            isCompleted(
                getState().DashboardReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            // aready in store
            return Promise.resolve(
                getState().DashboardReducer.staticData[payload.templateId]
            );
        }
        dispatch(
            DashboardActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        return getData(payload.url).then((response) => {
            dispatch(
                DashboardActions.setStaticData({
                    templateId: payload.templateId,
                    data: response.data.data,
                })
            );
            dispatch(
                DashboardActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },
};

export default DashboardActions;
