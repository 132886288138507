import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvSimpleCard, EvText } from "../index";

import "../../styles/ev-cards/ev-simple-item-card.scss";

const styles = {
    container: "ev__ev-simple-item-card__container",
    card: "ev__ev-simple-item-card__card",
    innerContainer: "ev__ev-simple-item-card__inner-container",
    headerText: "ev__ev-simple-item-card__header-text",
    subHeaderText: "ev__ev-simple-item-card__sub-header-text",
    descriptionText: "ev__ev-simple-item-card__description-text",
};

const EvSimpleItemCard = (props) => {
    const { header, subHeader, description, className } = props;

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding className={styles.card}>
                <div className={styles.innerContainer}>
                    {header && (
                        <EvText
                            defaultBold
                            primaryColored
                            className={styles.headerText}
                        >
                            {header}
                        </EvText>
                    )}
                    {subHeader && (
                        <EvText smallNormal className={styles.subHeaderText}>
                            {subHeader}
                        </EvText>
                    )}
                    {description && (
                        <EvText className={styles.descriptionText}>
                            {description}
                        </EvText>
                    )}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvSimpleItemCard.propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
};

EvSimpleItemCard.defaultProps = {
    header: "",
    subHeader: "",
    description: "",
    className: "",
};

export default EvSimpleItemCard;
