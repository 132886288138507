import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";

import { EvDropdownComponent, EvInputErrorView } from "./index";
import FormValidation from "../../../utils/FormValidations";
import { reservedKeywordCheck } from "../../../utils/FormUtils";
import CacheActions from "../../../reducers/cache/CacheActions";
import { CACHE_DATA_TYPE, LOADING_STATUS } from "../../static/Enums";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";

import "../../styles/form-components/ev-form-dropdown.scss";

const DATA_SOURCE_TYPE = {
    FROM_EXTERNAL_URL: "FROM_EXTERNAL_URL", // not yet implemented
    FROM_PROPS: "FROM_PROPS",
    INTERNAL: "INTERNAL",
};

const EvFormDropdown = (props) => {
    const styles = {
        container: "ev__ev-form-dropdown__container",
    };

    const [dataList, setDataList] = useState([]);
    const [lazyListLoadingStatus, setLazyLoadingListStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const {
        placeholder,
        data,
        type,
        formData,
        formItem,
        showError,
        editable,
        className,
        dataOptions,

        // actions
        getCachedData,
    } = props;

    const isLazyLoaded = useMemo(() => {
        if (formItem.options && formItem.options.lazyType) {
            return (
                formItem.options.lazyType === DATA_SOURCE_TYPE.FROM_EXTERNAL_URL
            );
        }
        return false;
    }, [formItem.options]);

    useEffect(() => {
        if (isLazyLoaded && formItem.listDataEndpoint) {
            setLazyLoadingListStatus(LOADING_STATUS.LOADING);
            getCachedData({
                url: formItem.listDataEndpoint,
                type: CACHE_DATA_TYPE.FORM_DROPDOWN,
            })
                .then((response) => {
                    setDataList(response.listData);
                    setLazyLoadingListStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    setLazyLoadingListStatus(LOADING_STATUS.FAILED);
                    EvLogger.errorWithObject(e, "EvFormDropdown getData");
                });
        } else if (
            formItem.options &&
            formItem.options.lazyType === DATA_SOURCE_TYPE.FROM_PROPS &&
            !isNullOrEmpty(dataOptions)
        ) {
            setDataList(dataOptions);
        } else {
            setDataList(data);
        }
    }, [
        isLazyLoaded,
        formItem,
        setDataList,
        setLazyLoadingListStatus,
        getCachedData,
        data,
        dataOptions,
    ]);

    const onDropdownSelect = (id, name, value) => {
        const inputFieldObject = {
            [id]: {
                key: id,
                value,
                type,
                name,
            },
            errorObject: {}, // will be overwritten later
            formItemType: type,
        };

        props.handleInputChange(props.keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    };

    const selectedObject = useMemo(() => {
        let obj = null;
        Object.keys(formData).forEach((keyname) => {
            if (reservedKeywordCheck(keyname)) {
                return;
            }
            obj = formData[keyname];
        });

        return obj;
    }, [formData]);

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvDropdownComponent
                dataList={dataList}
                lazyListLoadingStatus={lazyListLoadingStatus}
                placeholder={placeholder}
                showTooltip={
                    formItem.options ? formItem.options.showTooltip : false
                }
                editable={isNullOrEmpty(editable) ? true : formItem.editable}
                isLazyLoaded={isLazyLoaded}
                onChange={onDropdownSelect}
                selectedObject={selectedObject}
            />
            {showError && getErrorView()}
        </div>
    );
};

EvFormDropdown.propTypes = {
    data: PropTypes.array,

    keyName: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,

    // value: PropTypes.object,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    dataOptions: PropTypes.oneOfType(PropTypes.array, PropTypes.object),

    editable: PropTypes.bool,
    showError: PropTypes.bool,
    handleInputChange: PropTypes.func,
    getCachedData: PropTypes.func,
};

EvFormDropdown.defaultProps = {
    // label: "",
    // placeholder: "",

    data: [],

    keyName: "",
    type: "",
    className: "",
    placeholder: "",
    // value: {},
    formItem: {},
    formData: {},
    dataOptions: [],

    editable: true,
    showError: true,
    handleInputChange: () => {},
    getCachedData: () => {},
};

const mapStateToProps = (state) => ({
    // controls: state.UserMappingReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getCachedData: (payload) => dispatch(CacheActions.getCachedData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvFormDropdown);

export { DATA_SOURCE_TYPE };
