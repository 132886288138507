import ClientDetailsActionTypes from "./ClientDetailsActionTypes";
import { getData } from "../service/ClientDetailsService";

import { LOADING_STATUS } from "../../../common/static/Enums";
import { isCompleted, isAPISuccessStatus } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";

const ClientDetailsActions = {
    setControls: (payload) => ({
        type: ClientDetailsActionTypes.SET_CONTROLS,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: ClientDetailsActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    setDynamicDataApiStatus: (payload) => ({
        type: ClientDetailsActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: ClientDetailsActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    setStaticDataForPage: (payload) => ({
        type: ClientDetailsActionTypes.SET_STATIC_DATA_FOR_PAGE,
        payload,
    }),

    // resets only dynamic data
    resetClientDetailsDynamicData: (payload) => ({
        type: ClientDetailsActionTypes.RESET_CLIENT_DETAILS_DYNAMIC_DATA,
        payload,
    }),

    // checks if static data is already loaded,
    // returns promise with static data itself
    getStaticData: (payload) => (dispatch, getState) => {
        if (
            isCompleted(
                getState().ClientDetailsReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            // return existing data as promise
            return Promise.resolve(
                getState().ClientDetailsReducer.staticData[payload.templateId]
            );
        }
        dispatch(
            ClientDetailsActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );

        return getData(payload.url)
            .then((response) => {
                if (isAPISuccessStatus(response)) {
                    dispatch(
                        ClientDetailsActions.setStaticDataForPage({
                            templateId: payload.templateId,
                            staticData: response.data.data,
                        })
                    );
                    dispatch(
                        ClientDetailsActions.setStaticDataApiStatus({
                            [payload.templateId]: LOADING_STATUS.COMPLETED,
                        })
                    );
                    return Promise.resolve(response.data.data);
                }
                // on else throw error
                throw new Error(`Response : ${JSON.stringify(response.data)}`);
            })
            .catch((e) => {
                EvLogger.errorWithObject(e, "ClientDetailsAction getData");
                dispatch(
                    ClientDetailsActions.setStaticDataApiStatus({
                        [payload.templateId]: LOADING_STATUS.FAILED,
                    })
                );
                return Promise.reject();
            });
    },
};

export default ClientDetailsActions;
