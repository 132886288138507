import React from "react";
import PropTypes from "prop-types";

import { EvDatePicker, EvTimePicker, EvFormDropdown } from ".";

import "../../styles/form-components/ev-date-time-zone.scss";

const EvDateTimeZone = (props) => {
    const styles = {
        container: "ev__ev-date-time-zone__container",
        dateTimeContainer: "ev__ev-date-time-zone__date-time-container",
    };

    const {
        dateFormField,
        timeFormField,
        timeZoneFormField,
        formData,
        handleInputChange,
        className,
    } = props;

    const renderDateComponent = () => (
        <EvDatePicker
            keyName={dateFormField.id}
            type={dateFormField.type}
            className={className}
            handleInputChange={handleInputChange}
            editable={dateFormField.editable}
            formItem={dateFormField}
            formData={formData[dateFormField.id]}
            value={
                formData[dateFormField.id][dateFormField.id]
                    ? formData[dateFormField.id][dateFormField.id].value
                    : ""
            }
        />
    );

    const renderTimeComponent = () => (
        <EvTimePicker
            keyName={timeFormField.id}
            type={timeFormField.type}
            className={className}
            handleInputChange={handleInputChange}
            editable={timeFormField.editable}
            formItem={timeFormField}
            formData={formData[timeFormField.id]}
            value={
                formData[timeFormField.id][timeFormField.id]
                    ? formData[timeFormField.id][timeFormField.id].value
                    : ""
            }
        />
    );

    const renderTimeZoneComponent = () => (
        <EvFormDropdown
            keyName={timeZoneFormField.id}
            type={timeZoneFormField.type}
            className={className}
            placeholder={timeZoneFormField.placeholder}
            handleInputChange={handleInputChange}
            value={
                formData[timeZoneFormField.id]
                    ? formData[timeZoneFormField.id].value
                    : ""
            }
            editable={timeZoneFormField.editable}
            formItem={timeZoneFormField}
            formData={formData[timeZoneFormField.id]}
            data={timeZoneFormField.data}
        />
    );

    return (
        <div className={styles.container}>
            <div className={styles.dateTimeContainer}>
                {renderDateComponent()}
                {renderTimeComponent()}
            </div>
            {renderTimeZoneComponent()}
        </div>
    );
};

EvDateTimeZone.propTypes = {
    formData: PropTypes.object,
    className: PropTypes.string,
    dateFormField: PropTypes.object,
    timeFormField: PropTypes.object,
    timeZoneFormField: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvDateTimeZone.defaultProps = {
    formData: {},
    className: "",
    dateFormField: {},
    timeFormField: {},
    timeZoneFormField: {},

    handleInputChange: () => {},
};

export default EvDateTimeZone;
