const DATA_UPLOADER_FORM_TYPES = {
    FILE_UPLOAD: "file_uploader",
    RESOURCE_CARD: "resource_card_loader",
    CURRENT_STATUS: "current_status_loader",
    WHERE_TO_GO_FOR_CARE: "wtgfc_loader",
    EVIVE_360: "evive_360_loader",
    GAMEDAY_CONFIGURATION: "gameday_configuration",
    GAMEDAY_MEMORY_GAME: "gameday_memory_game",
    GAMEDAY_PICK_N_WIN_GAME: "gameday_pick_n_win_game",
};

const dummy = null;

export { DATA_UPLOADER_FORM_TYPES, dummy };
