import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock, apiPost } from "../../../services/api";
import getDataUploaderDataJSON from "../statics/getDataUploaderDataJson";
import EvLogger from "../../../utils/EvLogger";
// import { getQueryParameterByName } from "../../../utils/CommonUtils";
import { DATA_UPLOADER_FORM_TYPES } from "../statics/DataUploaderStatics";

/* global USE_MOCK_DATA */

const formFieldsDataDecider = (params) => {
    switch (params.templateId) {
        case DATA_UPLOADER_FORM_TYPES.FILE_UPLOAD:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS.FILE_UPLOAD;

        case DATA_UPLOADER_FORM_TYPES.RESOURCE_CARD:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS.RESOURCE_CARD;

        case DATA_UPLOADER_FORM_TYPES.CURRENT_STATUS:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS.CURRENT_STATUS;

        case DATA_UPLOADER_FORM_TYPES.WHERE_TO_GO_FOR_CARE:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS
                .WHERE_TO_GO_FOR_CARE;

        case DATA_UPLOADER_FORM_TYPES.EVIVE_360:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS.EVIVE_360_CARD;

        case DATA_UPLOADER_FORM_TYPES.GAMEDAY_CONFIGURATION:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS
                .GAMEDAY_CONFIGURATION;

        case DATA_UPLOADER_FORM_TYPES.GAMEDAY_MEMORY_GAME:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS
                .GAMEDAY_MEMORY_GAME;

        case DATA_UPLOADER_FORM_TYPES.GAMEDAY_PICK_N_WIN_GAME:
            return getDataUploaderDataJSON.STATICS_FORM_FIELDS
                .GAMEDAY_PICK_N_WIN_GAME;

        default:
            return {};
    }
};

const getData = (url, params) => {
    switch (url) {
        case API_URLS.DATA_UPLOADER.GET_FORM_TYPES:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getDataUploaderDataJSON.STATICS_FORM_TYPE
                  )
                : apiGet(url, params);

        // case API_URLS.DATA_UPLOADER.GET_FORM_FIELDS_FILE_UPLOAD:
        // case API_URLS.DATA_UPLOADER.GET_FORM_FIELDS_RESOURCE_CARD:
        // case API_URLS.DATA_UPLOADER.GET_FORM_FIELDS_CURRENT_STATUS:
        // case API_URLS.DATA_UPLOADER.GET_FORM_FIELDS_WHERE_TO_GO_FOR_CARE:
        // case API_URLS.DATA_UPLOADER.GET_FORM_FIELDS_EVIVE_360:
        case API_URLS.DATA_UPLOADER.GET_FORM_OF_TYPES:
            return USE_MOCK_DATA
                ? apiMock(url, params, formFieldsDataDecider(params))
                : apiGet(url, params);

        default:
            // can have unknown URLS
            // EvLogger.warn("DataUploaderService postData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.DATA_UPLOADER.GET_PREFILLED_FORM_VALUES__FILE_UPLOADER:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getDataUploaderDataJSON.PREFILL_DATA.FILE_UPLOADER,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.DATA_UPLOADER.GET_PREFILLED_FORM_VALUES__CARD:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getDataUploaderDataJSON.PREFILL_DATA.CARD,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.DATA_UPLOADER.POST_FORM_DATA__FILE_UPLOAD:
        case API_URLS.DATA_UPLOADER.POST_FORM_DATA__RESOURCE_CARD:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getDataUploaderDataJSON.SUBMIT_FORM,
                      data
                  )
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("DataUploaderService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
