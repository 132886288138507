import React, { useCallback } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";
import { EvText } from "../index";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import "../../styles/content-items/ev-content-criteria.scss";

const CRITERIA_RULE_TYPE = {
    OBJECT_RESOLVER: "OBJECT_RESOLVER",
    DATA_LIST_RESOLVER: "DATA_LIST_RESOLVER",
    CSV_ARRAY: "CSV_ARRAY",
};

const styles = {
    container: "ev__ev-content-criteria__container",
    criteria: {
        container: "ev__ev-content-criteria__criteria-container",
        divider: "ev__ev-content-criteria__criteria-divider",
    },
};

const EvContentCriteria = (props) => {
    const { label, dependency, allValues, itemData, allTags } = props;

    const parseRuleValues = useCallback((ruleItems, allRuleData) => {
        const ruleValues = {};
        ruleItems.forEach((ruleItemData) => {
            const ruleData = allRuleData[ruleItemData.id];
            switch (ruleItemData.type) {
                case CRITERIA_RULE_TYPE.OBJECT_RESOLVER:
                    ruleValues[ruleItemData.id] = ruleData.name;
                    break;

                case CRITERIA_RULE_TYPE.DATA_LIST_RESOLVER:
                    ruleValues[ruleItemData.id] =
                        ruleItemData.data[ruleData].name;
                    break;

                case CRITERIA_RULE_TYPE.CSV_ARRAY:
                    ruleValues[ruleItemData.id] = ruleData;
                    break;

                default:
                // do nothing
            }
        });
        return ruleValues;
    }, []);

    const getCriteriaItemView = (criteriaData) => {
        let ruleString = "";
        // if tagRuleFields data exits
        if (!isNullOrEmpty(criteriaData.tagRuleFields)) {
            const ruleValues = parseRuleValues(
                itemData.ruleItems,
                criteriaData.tagRuleFields
            );
            ruleString = stringTemplate(itemData.ruleResolver, ruleValues);
        } else {
            // if tagRuleFields doesnt exit show description
            ruleString = allTags[criteriaData.id].description
                ? allTags[criteriaData.id].description
                : "";
        }
        const labelString = stringTemplate(itemData.tagNameResolver, {
            displayName: allTags[criteriaData.id].displayName,
        });
        return (
            <div key={criteriaData.id}>
                <EvText
                    defaultDark
                    dangerous
                >{`${labelString} ${ruleString}`}</EvText>
            </div>
        );
    };

    const getCriteriaDivider = () => (
        <EvText className={styles.criteria.divider} defaultBold>
            {itemData.ruleDividerText}
        </EvText>
    );

    const getCriteriaListView = (criteriaData, i) => (
        // currently keeping i as key, should change later
        <div key={i} className={styles.criteria.container}>
            {i > 0 && getCriteriaDivider()}
            <EvText>
                {stringTemplate(itemData.criteriaHeading, {
                    count: i + 1,
                })}
            </EvText>
            {criteriaData.map(getCriteriaItemView)}
        </div>
    );

    return (
        <div className={styles.container}>
            <EvText defaultDark>{label}</EvText>
            <div>{allValues[dependency].map(getCriteriaListView)}</div>
        </div>
    );
};

EvContentCriteria.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
    itemData: PropTypes.object,
    allTags: PropTypes.object,
};

EvContentCriteria.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
    itemData: {},
    allTags: {},
};

export default EvContentCriteria;
