import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvLoader } from ".";
import { setAnimationCallback } from "../../utils/AnimationHelper";

import "../styles/ev-loading-page.scss";

const styles = {
    container: "ev-loading-page__container",
    animatedFadeIn: "ev-loading-page__animated-fade-in",
    loadingText: "ev-loading-page__loading-text",
    loadingTextChangeAnimationOut: "ev-loading-page__loading-text-change-out",
    loadingTextChangeAnimationIn: "ev-loading-page__loading-text-change-in",
};

const EvLoadingPage = (props) => {
    const { loadingText, animatedFadeIn, className, style } = props;

    const loadingTextRef = useRef(null);

    const [currentLoadingText, setCurrentLoadingText] = useState(loadingText);

    const [textAnimationClass, setTextAnimationClass] = useState("");

    useEffect(() => {
        if (loadingText === currentLoadingText) {
            return;
        }
        setTextAnimationClass(styles.loadingTextChangeAnimationOut);
        setAnimationCallback(loadingTextRef, () => {
            setCurrentLoadingText(loadingText);
            setTextAnimationClass(styles.loadingTextChangeAnimationIn);
        });
    }, [loadingText, currentLoadingText]);

    const containerStyle = classNames(styles.container, className, {
        [styles.animatedFadeIn]: animatedFadeIn,
    });

    const customLoadingTextClass = classNames(
        styles.loadingText,
        textAnimationClass
    );

    return (
        <div className={containerStyle} style={style}>
            <EvLoader />
            {loadingText && (
                <div ref={loadingTextRef} className={customLoadingTextClass}>
                    {currentLoadingText}
                </div>
            )}
        </div>
    );
};

EvLoadingPage.propTypes = {
    className: PropTypes.string,
    loadingText: PropTypes.string,
    style: PropTypes.object,
    animatedFadeIn: PropTypes.bool,
};

EvLoadingPage.defaultProps = {
    className: "",
    loadingText: "",
    style: {},
    animatedFadeIn: false,
};

export default EvLoadingPage;
