const uploadImageMockData = {
    responseStatus: "SUCCESS",
    data: {
        url:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/chat_bot/Floating%20Icon.png",
    },
    message: "Invalid image size",
};

// for failed
// const uploadImageMockData = {
//   responseStatus: "FAILED",
//   data: {},
//   message: "Image should be in .png format",
// };

const dummy = null;

export { uploadImageMockData, dummy };
