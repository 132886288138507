import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvText, EvIcon, EvButton } from "..";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/ev-pills.scss";

const EvPills = (props) => {
    const styles = {
        container: "ev__ev-pills__container",
        valueContainer: "ev__ev-pills__value-container",
        icon: {
            containerLeft: "ev__ev-pills__icon-container-left",
            containerRight: "ev__ev-pills__icon-container-right",
        },
    };

    const {
        value,
        iconName,
        textOptions,
        onIconClick,
        onValueClick,
        iconOptions,
        iconOnLeft,
        callbackValues,
        className,
        buttonClassName,
    } = props;

    // const onValueClickLocal = useCallback(() => {
    //   onValueClick(id);
    // }, [id, onValueClick]);
    //
    // const onIconClickLocal = useCallback(() => {
    //   onIconClick(id);
    // }, [id, onIconClick]);

    const getValueView = () => {
        const customContainerClass = classNames(
            styles.valueContainer,
            buttonClassName
        );
        return (
            <EvButton
                onlyChild
                className={customContainerClass}
                onClickHandler={onValueClick}
                callbackValues={callbackValues}
            >
                <EvText {...textOptions}>{value}</EvText>
            </EvButton>
        );
    };

    const getIconView = () => {
        const iconContainerClass = classNames(buttonClassName, {
            [styles.icon.containerLeft]: iconOnLeft,
            [styles.icon.containerRight]: !iconOnLeft,
        });
        return (
            <EvButton
                onlyChild
                className={iconContainerClass}
                onClickHandler={onIconClick}
                callbackValues={callbackValues}
            >
                <EvIcon
                    iconName={iconName}
                    size={2}
                    fillColor={COLORS.BLACK_TROUT}
                    {...iconOptions}
                />
            </EvButton>
        );
    };

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {iconOnLeft && iconName && getIconView()}
            {value && getValueView()}
            {!iconOnLeft && iconName && getIconView()}
        </div>
    );
};

EvPills.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    iconName: PropTypes.string,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    textOptions: PropTypes.object,
    iconOptions: PropTypes.object,
    iconOnLeft: PropTypes.bool,
    callbackValues: PropTypes.object,

    onValueClick: PropTypes.func,
    onIconClick: PropTypes.func,
};

EvPills.defaultProps = {
    id: "",
    value: "",
    iconName: "",
    className: "",
    buttonClassName: "",
    textOptions: {},
    iconOptions: {},
    iconOnLeft: false,
    callbackValues: {},

    onValueClick: () => {},
    onIconClick: () => {},
};

export default EvPills;
