const ClientCardsActionTypes = {
    SET_CONTROLS: "ClientCards/SET_CONTROLS",
    SET_STATIC_DATA: "ClientCards/SET_STATIC_DATA",
    SET_COMPONENT_DATA: "ClientCards/SET_COMPONENT_DATA",
    SET_STATIC_DATA_API_STATUS: "ClientCards/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS: "ClientCards/SET_DYNAMIC_DATA_API_STATUS",
    // SET_LANDING_DATA: "ClientCards/SET_LANDING_DATA",
    SOFT_RESET: "ClientCards/SOFT_RESET",
    RESET_ALL_DATA: "ClientCards/RESET_ALL_DATA",
};

export default ClientCardsActionTypes;
