import React, { useCallback } from "react";
import PropTypes from "prop-types";

import {
    EvInputField,
    EvColorTextPicker,
    EvBaseFileUploader,
    EvFileUploader,
    EvToggleButton,
    EvTextArea,
    EvFormDropdown,
    EvRadio,
    EvDatePicker,
    EvTimePicker,
    EvDateTimeZone,
    EvLazyModalSelection,
    EvFormBullets,
    EvTextList,
    EvChecklistDropdown,
    EvPrefixTextInput,
    EvFormStringLabel,
    EvFormCriteriaBuilder,
} from ".";
import { FORM_FIELD_TYPES } from "../../static/Enums";

const EvFormResolver = (props) => {
    const {
        type,
        className,
        keyName,
        formData,
        dataOptions,
        fullFormData,
        formItem,
        handleInputChange,
        qParams,
    } = props;

    const handleInputChangeLocal = useCallback(
        (key, formItemObject) => {
            handleInputChange(key, formItemObject, formItem);
        },
        [formItem, handleInputChange]
    );

    switch (type) {
        case FORM_FIELD_TYPES.COLOR:
            return (
                <EvColorTextPicker
                    keyName={keyName}
                    type={type}
                    // label={formItem.label}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formData={formData}
                    formItem={formItem}
                />
            );
        case FORM_FIELD_TYPES.TEXT:
            return (
                <EvInputField
                    keyName={keyName}
                    type={type}
                    // label={formItem.label}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                />
            );

        case FORM_FIELD_TYPES.PREFIX_TEXT:
            return (
                <EvPrefixTextInput
                    keyName={keyName}
                    type={type}
                    // label={formItem.label}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                />
            );

        case FORM_FIELD_TYPES.BASE_FILE_UPLOAD:
        case FORM_FIELD_TYPES.IMAGE_UPLOAD:
            return (
                <EvBaseFileUploader
                    keyName={keyName}
                    label={formItem.label}
                    type={type}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                />
            );

        case FORM_FIELD_TYPES.FILE_UPLOAD:
            return (
                <EvFileUploader
                    keyName={keyName}
                    label={formItem.label}
                    type={type}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                />
            );

        case FORM_FIELD_TYPES.TOGGLE:
            return (
                <EvToggleButton
                    keyName={keyName}
                    type={type}
                    handleInputChange={handleInputChangeLocal}
                    formData={formData[keyName]}
                    className={className}
                    formItem={formItem}
                />
            );

        case FORM_FIELD_TYPES.SMALL_TEXT_AREA:
        case FORM_FIELD_TYPES.LARGE_TEXT_AREA:
            return (
                <EvTextArea
                    keyName={keyName}
                    type={type}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formData={formData}
                    formItem={formItem}
                />
            );

        case FORM_FIELD_TYPES.DROPDOWN:
            return (
                <EvFormDropdown
                    keyName={keyName}
                    type={type}
                    // label={formItem.label}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                    data={formItem.data}
                    dataOptions={dataOptions}
                />
            );

        case FORM_FIELD_TYPES.RADIO:
            return (
                <EvRadio
                    keyName={keyName}
                    type={type}
                    className={className}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                    data={formItem.data}
                />
            );

        case FORM_FIELD_TYPES.DATE:
            return (
                <EvDatePicker
                    keyName={keyName}
                    type={type}
                    className={className}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                    data={formItem.data}
                    value={formData[keyName] ? formData[keyName].value : ""}
                />
            );

        case FORM_FIELD_TYPES.TIME:
            return (
                <EvTimePicker
                    keyName={keyName}
                    type={type}
                    className={className}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                    data={formItem.data}
                    value={formData[keyName] ? formData[keyName].value : ""}
                />
            );

        case FORM_FIELD_TYPES.DATE_TIME_ZONE:
            return (
                <EvDateTimeZone
                    keyName={keyName}
                    type={type}
                    className={className}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={fullFormData}
                    data={formItem.data}
                    dateFormField={formItem.dateFormField}
                    timeFormField={formItem.timeFormField}
                    timeZoneFormField={formItem.timeZoneFormField}
                />
            );

        case FORM_FIELD_TYPES.LAZY_DROPDOWN:
            return (
                <EvFormDropdown
                    keyName={keyName}
                    type={type}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                    dataOptions={dataOptions}
                />
            );

        case FORM_FIELD_TYPES.LAZY_MODAL_SELECTION:
            return (
                <EvLazyModalSelection
                    keyName={formItem.id}
                    formItem={formItem}
                    formData={formData}
                    dataOptions={dataOptions}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    minimalView={false}
                    qParams={qParams}
                    showError
                    showClearIcon={
                        !!(formItem.options && formItem.options.showClearIcon)
                    }
                />
            );

        case FORM_FIELD_TYPES.BULLETS:
            return (
                <EvFormBullets
                    keyName={formItem.id}
                    formItem={formItem}
                    formData={formData}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    showError
                />
            );

        case FORM_FIELD_TYPES.TEXT_LIST:
            return (
                <EvTextList
                    keyName={formItem.id}
                    formItem={formItem}
                    formData={formData}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    showError
                />
            );

        case FORM_FIELD_TYPES.CHECKLIST_DROPDOWN:
            return (
                <EvChecklistDropdown
                    keyName={formItem.id}
                    formItem={formItem}
                    formData={formData}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    editable={formItem.editable}
                    showError
                />
            );

        case FORM_FIELD_TYPES.STRING_LABEL:
            return (
                <EvFormStringLabel
                    keyName={formItem.id}
                    formItem={formItem}
                    formData={formData}
                />
            );

        case FORM_FIELD_TYPES.HIDDEN_TEXT:
            return <span />;

        case FORM_FIELD_TYPES.CRITERIA_BUILDER:
            return (
                <EvFormCriteriaBuilder
                    keyName={keyName}
                    type={type}
                    className={className}
                    placeholder={formItem.placeholder}
                    handleInputChange={handleInputChangeLocal}
                    value={formData[keyName] ? formData[keyName].value : ""}
                    editable={formItem.editable}
                    formItem={formItem}
                    formData={formData}
                />
            );

        default:
            return <span />;
    }
};

EvFormResolver.propTypes = {
    keyName: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    formData: PropTypes.object,
    fullFormData: PropTypes.object,
    formItem: PropTypes.object,
    qParams: PropTypes.object,
    dataOptions: PropTypes.oneOfType(PropTypes.array, PropTypes.object),

    handleInputChange: PropTypes.func,
};

EvFormResolver.defaultProps = {
    keyName: "",
    type: "",
    className: "",
    formData: {},
    fullFormData: {},
    qParams: {},
    formItem: {},
    dataOptions: {},

    handleInputChange: () => {},
};

export default EvFormResolver;
