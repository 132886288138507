import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { EvText } from "../index";

import "../../styles/content-items/ev-content-label.scss";

const styles = {
    container: "ev__ev-content-label__container",
};

const EvContentLabel = (props) => {
    const { label, displayResolver, allValues } = props;
    const displayString = stringTemplate(displayResolver, allValues);
    return (
        <div className={styles.container}>
            {/* <EvText>{label}</EvText> */}
            {/* <EvText dangerous>{value}</EvText> */}
            <EvText
                defaultDark
                dangerous
            >{`${label} <b>${displayString}</b>`}</EvText>
        </div>
    );
};

EvContentLabel.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentLabel.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
};

export default EvContentLabel;
