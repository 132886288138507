import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { LINKED_UNLINKED_TAGS_COMPONENTS } from "../statics/LinkedUnlinkedStatics";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import {
    isCompleted,
    isCompletedOrLoading,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import { normalizerWithOrderArrayWithEmptyCheck } from "../../../utils/Normalizer";
import { EvLoadingPage, EvText, EvToast } from "../../../common/components";
import { parseInitialFormDataForTags } from "../../../utils/FormUtils";
import UserMappingTagCard from "../../user-mapping/views/UserMappingTagCard";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_GENERIC_MESSAGES } from "../../../common/components/EvToast";
import VendorProgramsActions from "../../vendor-programs/redux/VendorProgramsActions";
import { getData } from "../../vendor-programs/service/VendorProgramsService";

import "../styles/unlinked-tags-view.scss";

const styles = {
    container: "ev__unlinked-tags-view__container",
    tags: {
        container: "ev__unlinked-tags-view__tags-container",
        wrapper: "ev__unlinked-tags-view__tags-wrapper",
        infoText: "ev__unlinked-tags-view__tags-info-text",
    },
};

const UnlinkedTagsView = (props) => {
    const { clientCode, staticData, allTagsData } = props;

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [tagsOrder, setTagsOrder] = useState([]);
    const [tagsFormData, setTagsFormData] = useState({});

    useEffect(() => {
        if (!isCompletedOrLoading(loadingStatus)) {
            setLoadingStatus(LOADING_STATUS.LOADING);
            getData(API_URLS.LINKED_UNLINKED_TAGS.GET_UNLINKED_TAGS, {
                clientCode,
            })
                .then((unlinkedTagsResponse) => {
                    const unlinkedTagsNormalized = normalizerWithOrderArrayWithEmptyCheck(
                        unlinkedTagsResponse.data.data.unlinkedTags
                    );
                    setTagsOrder(unlinkedTagsNormalized.ids);
                    setTagsFormData(
                        parseInitialFormDataForTags(
                            unlinkedTagsNormalized.ids,
                            unlinkedTagsNormalized.value,
                            staticData.tagsFormStatics.formFields,
                            allTagsData
                        )
                    );
                    setLoadingStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    setLoadingStatus(LOADING_STATUS.FAILED);
                    EvToast.error("", TOAST_GENERIC_MESSAGES.WENT_WRONG);
                    EvLogger.errorWithObject(
                        e,
                        "UnlinkedTagsView getUnlinkedTags"
                    );
                });
        }
    }, [loadingStatus, allTagsData, staticData, clientCode]);

    const onTagFormDataChange = useCallback(
        (tagId, key, formDataObject) => {
            setTagsFormData({
                ...tagsFormData,
                [tagId]: {
                    ...tagsFormData[tagId],
                    ...formDataObject,
                },
            });
        },
        [tagsFormData]
    );

    const onSubmitRuleSuccess = useCallback(
        (submittedTagId) => {
            const newTagsOrder = tagsOrder.filter(
                (tagId) => tagId !== submittedTagId
            );
            setTagsOrder(newTagsOrder);
        },
        [tagsOrder]
    );

    const getTagItem = (tagId) => {
        const tagData = allTagsData[tagId];
        if (isNullOrEmpty(tagData)) {
            return <span key={tagId} />;
        }
        return (
            <div key={tagId} className={styles.tags.wrapper}>
                <UserMappingTagCard
                    tagId={tagId}
                    header={tagData.displayName}
                    positiveButtonText={
                        staticData.tagsFormStatics.setRuleCta.text
                    }
                    // negativeButtonText={tagItemStaticData.removeRuleCta.text}
                    formItems={staticData.tagsFormStatics.formFields}
                    onTagFormDataChange={onTagFormDataChange}
                    formData={tagsFormData[tagId]}
                    collapsedTemplate={
                        staticData.tagsFormStatics.ruleDisplayTemplate
                    }
                    isEditable={tagData.editable}
                    // will be edit view by default, tagData.editable always expected to be true
                    isDefaultEditing={tagData.editable}
                    clientCode={clientCode}
                    onSubmitRuleSuccess={onSubmitRuleSuccess}
                />
            </div>
        );
    };

    const getTagsListView = () => (
        <div className={styles.tags.container}>{tagsOrder.map(getTagItem)}</div>
    );

    const getNoTagsView = () => {
        if (tagsOrder.length <= 0) {
            return (
                <EvText italics className={styles.tags.infoText}>
                    {staticData.noUnlinkedTags}
                </EvText>
            );
        }
        return null;
    };

    if (!isCompleted(loadingStatus)) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getNoTagsView()}
            {getTagsListView()}
        </div>
    );
};

UnlinkedTagsView.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    allTagsData: PropTypes.object,
};

UnlinkedTagsView.defaultProps = {
    clientCode: "",
    staticData: {},
    allTagsData: {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    allTagsData:
        state.VendorProgramsReducer.dynamicData[
            LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setComponentData: (payload) =>
        dispatch(VendorProgramsActions.setComponentData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkedTagsView);
