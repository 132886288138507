import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import stringTemplate from "string-template";
import { getData } from "../../service/CommonService";
import { LOADING_STATUS } from "../../static/Enums";
import "../../styles/content-items/ev-content-lazy-text.scss";
import { EvLoadingDots, EvText } from "../index";

const styles = {
    container: "ev__ev-content-lazy-text__container",
    valueContainer: "ev__ev-content-lazy-text__value-container",
    valueText: "ev__ev-content-lazy-text__value-text",
};

const EvContentLazyText = (props) => {
    const { label, displayResolver, dataEndpoint, qParams } = props;

    const [lazyData, setLazyData] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    useEffect(() => {
        getData(dataEndpoint, qParams)
            .then((lazyDataResponse) => {
                setLazyData(lazyDataResponse.data.data);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
            });
    }, [dataEndpoint, qParams]);

    const displayString = useMemo(() => {
        if (loadingStatus === LOADING_STATUS.COMPLETED) {
            return stringTemplate(displayResolver, lazyData);
        }
        return "";
    }, [lazyData, displayResolver, loadingStatus]);

    const getValue = () => {
        if (loadingStatus === LOADING_STATUS.COMPLETED) {
            return (
                <EvText defaultBold className={styles.valueText}>
                    {displayString}
                </EvText>
            );
        }
        return <EvLoadingDots size={1} />;
    };

    return (
        <div className={styles.container}>
            <EvText defaultDark dangerous>
                {label}
            </EvText>
            <div className={styles.valueContainer}>{getValue()}</div>
        </div>
    );
};

EvContentLazyText.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    dataEndpoint: PropTypes.string,
    allValues: PropTypes.object,
    qParams: PropTypes.object,
};

EvContentLazyText.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    dataEndpoint: "",
    allValues: {},
    qParams: {},
};

export default EvContentLazyText;
