import API_URLS from "../../../services/apiUrls";

const NOTIFICATION_PROGRAM_SELECTION = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Select notification Program",
            description: "Select notification Program description",
            nextCta: { text: "Next" },
            formFields: [
                {
                    id: "linkedProgram",
                    type: "LAZY_MODAL_SELECTION",
                    label: "Link Vendor Program",
                    description: "",
                    placeholder: "Select Program",
                    editable: true,
                    defaultValue: "",
                    staticDataEndpoint:
                        API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.STATIC,
                    listDataEndpoint:
                        API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT
                            .LIST_DATA,
                    data: [],
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please select a program",
                        },
                    ],
                    staticData: {
                        noDataListMessage: "No programs available",
                    },
                    options: {
                        listTargetKey: "programs",
                        showClearIcon: true,
                        allowSearch: true,
                        searchPlaceholder: "Search vendor programs",
                        searchNoResultMessage:
                            "No result found for this. Please try a different keyword",
                    },
                },
            ],
        },
    },
    PRE_FILLED_DATA: {
        responseStatus: "SUCCESS",
        data: {
            linkedProgram: {
                id: "pid_13c03060c16dba8852ea2df84e5e2fd8",
                name: "Dental Services",
                value: "pid_13c03060c16dba8852ea2df84e5e2fd8",
            },
        },
    },
    CONTENT_POST_SUCCESS: {
        responseStatus: "SUCCESS",
        data: {
            id: "nid_123_1",
        },
    },
};

const NOTIFICATION_CONTENT_PAGE_JSON = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Create new notification",
            // backCta: {
            //   text: "Back to notifications home",
            // },
            nextCta: { text: "Next" },
            previewHeader: "PREVIEW",
            formFields: [
                {
                    id: "title",
                    label: "Title",
                    description: "26 character max",
                    placeholder: "",
                    type: "TEXT",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                        {
                            type: "MAX_LENGTH",
                            value: "26",
                            errMsg: "26 character max",
                        },
                    ],
                },
                {
                    id: "description",
                    label: "Description",
                    description: "235 character max",
                    placeholder: "",
                    type: "SMALL_TEXT_AREA",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                        {
                            type: "MAX_LENGTH",
                            value: "235",
                            errMsg: "235 character max",
                        },
                    ],
                },
                {
                    id: "ctaType",
                    label: "Cta Type",
                    description: "",
                    placeholder: "Select Type",
                    type: "DROPDOWN",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                    ],
                    data: [
                        { id: "href", name: "HREF", value: "HREF" },
                        { id: "sso", name: "SSO", value: "SSO" },
                        { id: "internal", name: "INTERNAL", value: "INTERNAL" },
                        { id: "tel", name: "TEL", value: "TEL" },
                        { id: "none", name: "NONE", value: "NONE" },
                    ],
                    subForm: [
                        // {
                        //   id: "ctaValue",
                        //   matchId: "sso",
                        //   label: "",
                        //   description: "",
                        //   placeholder: "",
                        //   type: "TEXT",
                        //   editable: true,
                        //   validations: [
                        //     { type: "MANDATORY", errMsg: "This field is mandatory" },
                        //   ],
                        // },
                        {
                            id: "pidSSO",
                            matchId: "sso",
                            type: "LAZY_MODAL_SELECTION",
                            label: "SSO Values",
                            description: "",
                            placeholder: "Select Program",
                            editable: true,
                            defaultValue: "",
                            // staticDataEndpoint:
                            //   API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.STATIC,
                            data: [],
                            validations: [
                                {
                                    type: "MANDATORY",
                                    errMsg: "Please select a program",
                                },
                            ],
                            options: {
                                showClearIcon: true,
                                lazyType: "FROM_PROPS",
                            },
                            staticData: {
                                header: "Link SSO Program",
                                description:
                                    "Some description about selecting SSO program",
                                addButtonCta: "Select Program",
                                noDataListMessage: "No programs available",
                            },
                        },
                        {
                            id: "buttonText",
                            matchId: "sso",
                            label: "Button Text",
                            description: "26 character max",
                            placeholder: "",
                            type: "TEXT",
                            editable: true,
                            validations: [
                                {
                                    type: "MAX_LENGTH",
                                    value: "26",
                                    errMsg: "26 character max",
                                },
                                {
                                    type: "MANDATORY",
                                    errMsg: "This field is mandatory",
                                },
                            ],
                        },
                        {
                            id: "ctaValue",
                            matchId: "href",
                            label: "Cta Value",
                            description: "Href Link",
                            placeholder: "www.myevive.com",
                            prefixPlaceholder: "Select Prefix",
                            type: "PREFIX_TEXT",
                            editable: true,
                            defaultValue: "https://",
                            validations: [
                                {
                                    type: "MIN_LENGTH",
                                    value: "10",
                                    errMsg: "Must enter href link",
                                },
                            ],
                            data: [
                                {
                                    id: "https",
                                    value: "https://",
                                    name: "HTTPS",
                                },
                                {
                                    id: "http",
                                    value: "http://",
                                    name: "HTTP",
                                },
                            ],
                            options: {
                                hrefPreview: true,
                                hrefPreviewCtaText: "Open link {href}",
                            },
                        },
                        {
                            id: "buttonText",
                            matchId: "href",
                            label: "Button Text",
                            description: "25 character max",
                            placeholder: "",
                            type: "TEXT",
                            editable: true,
                            validations: [
                                {
                                    type: "MAX_LENGTH",
                                    value: "26",
                                    errMsg: "26 character max",
                                },
                                {
                                    type: "MANDATORY",
                                    errMsg: "This field is mandatory",
                                },
                            ],
                        },
                        {
                            id: "ctaValue",
                            matchId: "tel",
                            label: "",
                            description: "",
                            placeholder: "",
                            type: "TEXT",
                            editable: true,
                            validations: [
                                {
                                    type: "MANDATORY",
                                    errMsg: "This field is mandatory",
                                },
                            ],
                        },
                        {
                            id: "buttonText",
                            matchId: "tel",
                            label: "Button Text",
                            description: "26 character max",
                            placeholder: "",
                            type: "TEXT",
                            editable: true,
                            validations: [
                                {
                                    type: "MAX_LENGTH",
                                    value: "26",
                                    errMsg: "26 character max",
                                },
                                {
                                    type: "MANDATORY",
                                    errMsg: "This field is mandatory",
                                },
                            ],
                        },
                        // {
                        //   id: "ctaValue",
                        //   matchId: "internal",
                        //   label: "",
                        //   description: "",
                        //   placeholder: "",
                        //   type: "TEXT",
                        //   editable: true,
                        //   validations: [
                        //     { type: "MANDATORY", errMsg: "This field is mandatory" },
                        //   ],
                        // },
                        {
                            id: "pidInternal",
                            matchId: "internal",
                            type: "LAZY_MODAL_SELECTION",
                            label: "Internal Values",
                            description: "",
                            placeholder: "Select Program",
                            editable: true,
                            defaultValue: "",
                            // staticDataEndpoint:
                            // API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.STATIC,
                            data: [],
                            validations: [
                                {
                                    type: "MANDATORY",
                                    errMsg: "Please select a program",
                                },
                            ],
                            options: {
                                showClearIcon: true,
                                lazyType: "FROM_PROPS",
                            },
                            staticData: {
                                header: "Link Internal Program",
                                description:
                                    "Some description about selecting Internal program",
                                addButtonCta: "Select Program",
                                noDataListMessage: "No programs available",
                            },
                        },
                        {
                            id: "buttonText",
                            matchId: "internal",
                            label: "Button Text",
                            description: "26 character max",
                            placeholder: "",
                            type: "TEXT",
                            editable: true,
                            validations: [
                                {
                                    type: "MAX_LENGTH",
                                    value: "26",
                                    errMsg: "26 character max",
                                },
                                {
                                    type: "MANDATORY",
                                    errMsg: "This field is mandatory",
                                },
                            ],
                        },
                    ],
                },
                {
                    id: "priority",
                    label: "Priority",
                    description: "",
                    placeholder: "Select Type",
                    type: "RADIO",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                    ],
                    data: [
                        { id: "high", name: "High", value: "high" },
                        { id: "normal", name: "Normal", value: "normal" },
                    ],
                },
                {
                    id: "takeActionBy",
                    label: "Take action date",
                    description: "",
                    placeholder: "",
                    type: "DATE",
                    editable: true,
                    validations: [],
                },
                {
                    id: "associatedTaskIDs",
                    type: "FORM_TEMPLATE",
                    label: "Associated TaskIds",
                    description: "",
                    previewCta: {
                        text: "Preview",
                        iconName: "VISIBLE",
                    },
                    addItemCta: {
                        text: "Add",
                    },
                    removeItemCta: {
                        text: "Remove",
                    },
                    editable: true,
                    formItems: [
                        {
                            id: "taskId",
                            label: "Task Id",
                            description: "",
                            type: "TEXT",
                            defaultValue: "",
                            validations: [
                                {
                                    type: "MANDATORY",
                                    errMsg: "Please enter value for the field",
                                },
                            ],
                        },
                    ],
                    validations: [
                        {
                            type: "MIN_ITEM_COUNT",
                            value: 1,
                            errMsg: "Must contain at least 1  task id.",
                        },
                    ],
                },
                // {
                //   id: "linkedProgram",
                //   type: "LAZY_MODAL_SELECTION",
                //   label: "Link Vendor Program",
                //   description: "",
                //   placeholder: "Select Program",
                //   editable: true,
                //   defaultValue: "",
                //   staticDataEndpoint:
                //     API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.STATIC,
                //   listDataEndpoint:
                //     API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.LIST_DATA,
                //   data: [],
                //   validations: [
                //     {
                //       type: "MANDATORY",
                //       errMsg: "Please select a program",
                //     },
                //   ],
                //   options: {
                //     listTargetKey: "programs",
                //     showClearIcon: true,
                //   },
                // },
                {
                    id: "notificationImage",
                    label: "Banner Image",
                    description:
                        "Suggested size: 1900x1266 px. If no image is uploaded, the MyEvive default banner will be displayed.",
                    placeholder: "Upload image",
                    type: "IMAGE_UPLOAD",
                    editable: true,
                    defaultValue:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    options: {
                        uploadEndpoint: "/cc/api/upload-image",
                        clearText: "Remove Image",
                    },
                    validations: [
                        // {
                        //   type: "MANDATORY",
                        //   errMsg: "Required fields",
                        // },
                    ],
                },
            ],
            notificationsPreviewCard: {
                contentKeys: {
                    header: "title",
                    description: "description",
                    buttonText: "buttonText",
                    priority: "priority",
                    takeActionDate: "takeActionBy",
                },
                staticContent: {
                    daysAgo: "2 days ago",
                    takeActionDate: "Take action by {takeActionDate}",
                    priority: {
                        high: "High Priority",
                        normal: "",
                    },
                },
            },
            calloutPreviewCard: {
                contentKeys: {
                    header: "title",
                    description: "description",
                    bgImage: "notificationImage",
                },
                staticContent: {
                    typeText: "Notification",
                },
            },
        },
    },
    PRE_FILLED_DATA: {
        responseStatus: "SUCCESS",
        data: {
            title: "Benefits of direct deposit",
            description:
                "Enroll in direct deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
            buttonText: "Notification buttonText",
            ctaType: "SSO",
            // ctaType_vendor_id: "",
            ctaType_button_text: "Enroll Now",
            priority: "high",
            associatedTaskIDs: [
                {
                    taskId: "testTaskId",
                },
            ],
            // linkedProgram: {
            //   id: "pid_13c03060c16dba8852ea2df84e5e2fd8",
            //   name: "Dental Services",
            //   value: "pid_13c03060c16dba8852ea2df84e5e2fd8",
            // },
            pidSSO: {
                id: "pid-sso-1",
                name: "PID SSO 1",
                value: "pid sso 1",
            },
        },
    },
    LAZY_MODAL_DATA: {
        responseStatus: "SUCCESS",
        data: {
            pidSSO: [
                {
                    id: "pid-sso-1",
                    name: "PID SSO 1",
                    value: "pid sso 1",
                    description: "PID SSO 1 Description",
                },
                {
                    id: "pid-sso-2",
                    name: "PID SSO 2",
                    value: "pid sso 2",
                    description: "PID SSO 2 Description",
                },
                {
                    id: "pid-sso-3",
                    name: "PID SSO 3",
                    value: "pid sso 3",
                    description: "PID SSO 3 Description",
                },
            ],
            pidInternal: [
                {
                    id: "pid-internal-1",
                    name: "PID INTERNAL 1",
                    value: "pid internal 1",
                    description: "PID INTERNAL 1 Description",
                },
                {
                    id: "pid-internal-2",
                    name: "PID INTERNAL 2",
                    value: "pid internal 2",
                    description: "PID INTERNAL 2 Description",
                },
                {
                    id: "pid-internal-3",
                    name: "PID INTERNAL 3",
                    value: "pid internal 3",
                    description: "PID INTERNAL 3 Description",
                },
            ],
        },
    },
    CONTENT_POST_SUCCESS: {
        responseStatus: "SUCCESS",
        data: {
            id: "nid_123_1",
        },
    },
};

const DASHBOARD_PAGE_JSON = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "My Evive Notifications",
            description:
                "Notifications enable quick communication to the targeted MyEvive User.",
            scheduleCta: { text: "Schedule new notification" },
            noNotificationsMessage:
                "You have not created any notifications yet.",
            latestUploadsStaticData: {
                header: "Latest Uploads",
                loadMoreCta: { text: "Load more" },
                tableFields: [
                    {
                        id: "notificationTitle",
                        label: "Notification Title",
                        type: "PRIMARY_TEXT",
                        resolver: "{notificationTitle}",
                    },
                    {
                        id: "notificationId",
                        label: "Notification ID",
                        type: "TEXT",
                        resolver: "{notificationId}",
                    },
                    {
                        id: "goLiveDate",
                        label: "Go-live date",
                        type: "TIME_STAMP",
                        resolver: "{date}",
                    },
                    {
                        id: "priority",
                        label: "Priority",
                        type: "DATA_OPTIONS",
                        resolver: "",
                        data: {
                            normal: {
                                id: "normal",
                                name: "Normal",
                                value: "normal",
                            },
                            high: {
                                id: "high",
                                name: "High",
                                value: "high",
                            },
                            low: {
                                id: "low",
                                name: "Low",
                                value: "low",
                            },
                        },
                    },
                    {
                        id: "status",
                        label: "Status",
                        type: "DATA_OPTIONS",
                        resolver: "",
                        data: {
                            DRAFT: {
                                id: "draft",
                                name: "Draft",
                                value: "DRAFT",
                            },
                            LIVE: {
                                id: "live",
                                name: "Live",
                                value: "LIVE",
                            },
                            SCHEDULED: {
                                id: "scheduled",
                                name: "Scheduled",
                                value: "SCHEDULED",
                            },
                        },
                    },
                ],
            },
        },
    },
    NOTIFICATIONS_DATA: {
        responseStatus: "SUCCESS",
        data: {
            notificationList: [
                {
                    notificationId: "abcd6",
                    notificationTitle: "6Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "19:50:00",
                        timeZone: "PST",
                    },
                    priority: "normal",
                    status: "DRAFT",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle: "1Hurricane Safety",
                    goLiveDate: {},
                    priority: "high",
                    status: "SCHEDULED",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle:
                        "2Icecream Social and a really long name to break in two lines",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    priority: "low",
                    status: "SCHEDULED",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle: "3Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "12:30:00",
                        timeZone: "CST",
                    },
                    priority: "normal",
                    status: "LIVE",
                },
                {
                    notificationId: "abcd4",
                    notificationTitle: "4Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "05:30:00",
                        timeZone: "PST",
                    },
                    priority: "normal",
                    status: "LIVE",
                },
                {
                    notificationId: "abcd5",
                    notificationTitle: "5Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "15:08:00",
                        timeZone: "CST",
                    },
                    priority: "normal",
                    status: "LIVE",
                },
            ],
        },
    },
};

const TARGET_POPULATION_JSON = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            backCta: { text: "Back to notifications home" },
            header: "Set target population",
            description:
                "There are a couple ways you can send MyEvive notifications to specific populations. You can segment a new population or use a saved list.",
            newPopulationCta: { text: "Segment new population" },
            continuePopulationHeader: "View/Edit with current selection",
            savedListHeader: "Reuse a saved list",
            lastSavedText: "Last used: ",
            loadMoreCta: { text: "Load more" },
            noPopulationMessage: "You dont have any saved population.",
            populationUploadCta: { text: "Upload UPINS CSV" },
            skipCta: { text: "Continue with current selection" },
        },
    },

    SAVED_POPULATION: {
        responseStatus: "SUCCESS",
        data: {
            savedPopulationList: [
                {
                    id: "hurricane123",
                    title: "Hurricane Send- Florida and some more text with it",
                    lastUsed: "2019-03-25",
                },
                {
                    id: "wellness123",
                    title: "Wellness 5K",
                    lastUsed: "2020-06-25",
                },
                {
                    id: "financialSeminar",
                    title: "Financial Seminar",
                    lastUsed: "2019-08-25",
                },
                {
                    id: "financialSeminar2",
                    title: "Financial Seminar2",
                    lastUsed: "2020-06-25",
                },
                {
                    id: "financialSeminar3",
                    title: "Financial Seminar3",
                    lastUsed: "2020-03-21",
                },
                {
                    id: "financialSeminar4",
                    title: "Financial Seminar4",
                    lastUsed: "2018-03-26",
                },
                {
                    id: "financialSeminar5",
                    title: "Financial Seminar5",
                    lastUsed: "2020-03-25",
                },
                {
                    id: "financialSeminar6",
                    title: "Financial Seminar5",
                    lastUsed: "2020-01-29",
                },
            ],
            currentPopulation: {
                id: "",
                title: "New Population Name",
                type: "CSV", // CSV, TAGS
            },
        },
    },
};

const NEW_POPULATION_PAGE_JSON = {
    PAGE_STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Set target population",
            // description:
            //     "There are a couple ways you can send MyEvive notifications to specific populations. You can segment a new population or use a saved list.",
            // criteriaHeading: "Population Criteria",
            // addCriteriaCta: { text: "Add New Rule" },
            nextButtonCta: { text: "Save & Continue" },
            // removeConfirmationModal: {
            //     header: "Are you sure?",
            //     description:
            //         "Are you sure you want to remove this criteria? Changes will not be saved until saved.",
            //     positiveCta: { text: "Delete" },
            //     negativeCta: { text: "Cancel" },
            // },
            // criteria: {
            //     heading: "Criteria rule {count}",
            //     editButtonCta: { text: "Edit Rule" },
            //     removeButtonCta: { text: "Remove" },
            //     ruleDisplayTemplate: "{mappedField} {operator} [{values}]",
            //     criteriaDividerText: "OR",
            //     placeholder: "Add Tags",
            // },
            populationFormFields: [
                {
                    id: "title",
                    label: "Population Name",
                    description: "",
                    placeholder: "",
                    type: "TEXT",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter population name",
                        },
                        {
                            type: "ALPHA_NUMERIC",
                            errMsg: "Population name should be alpha numeric only",
                        },
                    ],
                },
                {
                    id: "audiences",
                    label: "Audience",
                    description: "",
                    placeholder: "",
                    type: "TEXT",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                    ],
                },
            ],
        },
    },

    CRITERIA_RULE_FORM_DATA: {
        responseStatus: "SUCCESS",
        data: {
            formFields: [
                {
                    id: "mappedField",
                    label: "",
                    description: "",
                    placeholder: "",
                    type: "LABEL",
                    editable: false,
                    validations: [],
                },
                {
                    id: "operator",
                    type: "LAZY_MINIMAL_DROPDOWN",
                    label: "",
                    description: "",
                    placeholder: "Set operator",
                    editable: true,
                    defaultValue: "",
                    dataEndpoint: "",
                    options: {
                        lazyType: "FROM_PROPS",
                    },
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter operator",
                        },
                    ],
                    data: [
                        // {
                        //   id: "isEqual",
                        //   name: "is Equal to",
                        //   value: "isEqual",
                        // },
                        // {
                        //   id: "isNotEqual",
                        //   name: "is Not Equal to",
                        //   value: "isNotEqual",
                        // },
                        // {
                        //   id: "isIn",
                        //   name: "is equal to one of the following",
                        //   value: "isIn",
                        // },
                        // {
                        //   id: "contains",
                        //   name: "contains",
                        //   value: "contains",
                        // },
                    ],
                },
                {
                    id: "values",
                    type: "TSV_TEXT",
                    label: "",
                    description: "",
                    placeholder: "[Enter values separated by tabs]",
                    editable: true,
                    defaultValue: "",
                    dataEndpoint: "",
                    data: [],
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter values",
                        },
                    ],
                },
            ],
        },
    },

    GET_POPULATION_DETAILS: {
        responseStatus: "SUCCESS",
        data: {
            populationDetails: { title: "" },
            audiences: "",
            // criteriaList: [
            //     [
            //         {
            //             id: "IS_REGISTERED",
            //         },
            //         {
            //             id: "cus_cityWork",
            //             tagRuleFields: {
            //                 mappedField: {
            //                     id: "cus_cityWork",
            //                     name: "City Work",
            //                     value: "cus_cityWork",
            //                 },
            //                 operator: {
            //                     id: "isEqual",
            //                     name: "is Equal to",
            //                     value: "isEqual",
            //                 },
            //                 values: ["Chicago"],
            //             },
            //         },
            //     ],
            // ],
        },
    },

    SUBMIT_POPULATION_POST: {
        populationDetails: {
            name: "PopulationName",
        },
        criteriaList: [
            [
                {
                    id: "TAG_1",
                    tagRuleFields: {
                        mappedField: "tagId1",
                        operator: {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        values: ["1", "2", "3"],
                    },
                },
                {
                    id: "TAG_2",
                    tagRuleFields: {
                        mappedField: "tagId2",
                        operator: {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        values: ["1", "2", "3"],
                    },
                },
            ],
            [
                {
                    id: "TAG_3",
                    tagRuleFields: {
                        mappedField: "tagId3",
                        operator: {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        values: ["1", "2", "3"],
                    },
                },
                {
                    id: "TAG_4",
                    tagRuleFields: {
                        mappedField: "tagId4",
                        operator: {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        values: ["1", "2", "3"],
                    },
                },
            ],
        ],
    },
};

const TAG_SELECTION_PAGE_JSON = {
    PAGE_STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Set Target population",
            description: "Select the criteria for this notification.",
            forwardCta: { text: "Proceed with selection" },

            selectedMenu: {
                id: "SELECTED",
                displayName: "Selected",
            },
            allOptions: {
                id: "ALL",
                displayName: "All Tags",
            },

            tags: {
                selectTagCta: { text: "Select" },
                addTagCta: { text: "Set Rule" },
                removeTagCta: { text: "Remove Rule" },
                ruleDisplayTemplate: "{mappedField} {operator} [{values}]",
            },
            categoriesHeader: "SEARCH BY CATEGORIES",
            noTagsForCategory: "No tags present for the selected catgegory",
            changesNotSaved: "Changes currently not saved. Please continue.",
        },
    },
    CATEGORIES: {
        responseStatus: "SUCCESS",
        data: {
            categories: [
                {
                    id: "eligibility",
                    displayName: "Eligibility",
                    subCategories: [
                        { id: "demographics", displayName: "Demographics" },
                        {
                            id: "employerspecific",
                            displayName: "EmployerSpecific",
                        },
                        { id: "benefits", displayName: "Benefits" },
                    ],
                },
                {
                    id: "pharmacy",
                    displayName: "Pharmacy",
                    subCategories: [
                        { id: "pharmacy", displayName: "Pharmacy" },
                    ],
                },
                { id: "CUSTOM", displayName: "CUSTOM" },
            ],
        },
    },

    COMBINED_TAGS: {
        responseStatus: "SUCCESS",
        data: {
            tags: [
                {
                    id: "ELIG_IS_EMPLOYEE",
                    displayName: "Employee",
                    category: "demographics",
                    description:
                        "The person employed by this company (versus their spouse or other dependents)",
                    editable: false,
                    type: "PRESET",
                    tagRuleFields: {
                        mappedField: {
                            id: "relationship",
                            name: "Relationship code",
                            description:
                                "The field contains code which describes the familiar or sponsor relationship between an enrolled member and the employee subscriber under whose plan of benefits the user is enrolled.",
                            value: "relationship",
                            resolver: "${member_profile.relationship}",
                        },
                        operator: {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        values: ["EMPLOYEE"],
                    },
                },
                {
                    id: "ELIG_IS_MEDICALLY_ENROLLED_BCBS",
                    displayName: "BCBS enrolled",
                    category: "benefits",
                    description: "Currently enrolled in a BCBS medical plan",
                    editable: false,
                    type: "PRESET",
                    tagRuleFields: {
                        mappedField: {
                            id: "medical_plan_code",
                            name: "Medical plan code",
                            description:
                                "This field contains the medical plan code of the medical plan that the user is enrolled in",
                            value: "medical_plan_code",
                            resolver: "${benefits.medical_plan_code}",
                        },
                        operator: {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                        values: ["1", "2", "42", "76", "85", "86"],
                    },
                },
                {
                    id: "cus_cityHome",
                    displayName: "City",
                    category: "CUSTOM",
                    description:
                        "Refine your population to only include residents of certain areas of certain states, provinces, or regions.",
                    editable: true,
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_stateHome",
                    displayName: "State",
                    description:
                        "Refine your population to only include residents of certain states, provinces, or regions.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_cityWork",
                    displayName: "City Work",
                    description:
                        "Refine your population to only include employees working in certain areas of a state, province, or region.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_postcodeHome",
                    displayName: "Zip Code",
                    description:
                        "Refine your population to only include residents of certain postal codes.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_ageRange",
                    displayName: "Age",
                    description:
                        "Refine your population to only include certain ages or age groups.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isGreaterThan",
                            name: "is greater than",
                            value: "isGreaterThan",
                        },
                        {
                            id: "isLessThan",
                            name: "is less than",
                            value: "isLessThan",
                        },
                        { id: "inRange", name: "In Range", value: "inRange" },
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                    ],
                },
                {
                    id: "cus_stateWork",
                    displayName: "State Work",
                    description:
                        "Refine your population to only include employees working within certain states, provinces, or regions.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_postcodeWork",
                    displayName: "Work Zip Code",
                    description:
                        "Refine your population to only include employees with certain employer postal codes.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "cus_employeeId",
                    displayName: "Employee Id",
                    description:
                        "Refine your population to only include certain employees.",
                    editable: true,
                    category: "CUSTOM",
                    type: "CUSTOM",
                    operatorList: [
                        {
                            id: "isEqual",
                            name: "is Equal to",
                            value: "isEqual",
                        },
                        {
                            id: "isIn",
                            name: "is equal to one of the following",
                            value: "contains",
                        },
                    ],
                },
                {
                    id: "IS_REGISTERED",
                    displayName: "Registered for MyEvive",
                    description:
                        "Employee or spouse who is a registered MyEvive user",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ACCIDENT_CAUSED_BY_TRAVEL_AND_MOTION",
                    displayName: "Accident: Travel and motion",
                    description:
                        "An accident caused by travel and motion, such as a car accident",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "HYPERTENSION",
                    displayName: "Heart disease: Hypertension",
                    description: 'Also known as "hypertension"',
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "OTHER_AND_UNSPECIFIED_MALIGNANT_NEOPLASMS_OF_LYMPHOID__HEMATOPOIETIC_AND_RELATED_TISSUE",
                    displayName: "Cancer: Lymphoid, unspecified",
                    description:
                        "Cancer that affects unspecified lymphoid, hematopoietic, and related tissues",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "SHOULDER_SURGERY_PREDICTED_HIGH",
                    displayName: "SHOULDER SURGERY PREDICTED HIGH",
                    description: "",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ACCIDENTS_INVOLVING_POWERED_VEHICLES_USED_SOLELY_WITHIN_THE_BUILDINGS_AND_PREMISES_OF_INDUSTRIAL_OR_COMMERCIAL_ESTABLISHMENT",
                    displayName:
                        "Accident: Powered vehicle on industrial/commercial premises",
                    description:
                        "In an accident involving powered vehicles used on the premises of an industrial or commercial establishment",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "OTHER_MALIGNANT_NEOPLASMS_OF_LYMPHOID_AND_HISTIOCYTIC_TISSUE",
                    displayName: "Cancer: Lymphoid and/or histiocytic",
                    description:
                        "Cancer in unspecified lymphoid and histiocytic tissues",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "SHOULDER_SURGERY_PREDICTED_LOW",
                    displayName: "SHOULDER SURGERY PREDICTED LOW",
                    description: "",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ALLERGY_STATUS_TO_SERUM_OR_VACCINE_STATUS",
                    displayName: "Allergy to vaccine or serum transfusion",
                    description: "Allergic to a vaccine or serum transfusion",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "MALIGNANT_NEOPLASM_OF_PENIS_AND_OTHER_MALE_GENITAL_ORGANS",
                    displayName: "Cancer: Male genital organ",
                    description:
                        "Cancer of the penis and/or other male genital organs",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "MALIGNANT_NEOPLASM_OF_SPINAL_CORD_CRANIAL_NERVES_AND_OTHER_PARTS_OF_CENTRAL_NERVOUS_SYSTEM",
                    displayName: "Cancer: Nervous system",
                    description:
                        "Affects the spinal cord cranial nerves and other parts of central nervous system",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "SECONDARY_AND_UNSPECIFIED_MALIGNANT_NEOPLASM_OF_LYMPH_NODES",
                    displayName:
                        "Cancer: Lymph nodes, secondary or unspecified",
                    description:
                        "Secondary or unspecified cancers of the lymph nodes",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "CARCINOMA_IN_SITU_OF_CERVIX_UTERI",
                    displayName: "Cancer: Cervical, stage 0",
                    description:
                        'Non-invasive abnormal cells in the colorectal area that may progress into cancer; also known as "carcinoma in situ"',
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "NONRHEUMATIC_AORTIC_VALVE_DISORDER",
                    displayName: "Heart disease: Aortic valve disease",
                    description:
                        'Also known as "nonrheumatic aortic valve disorder"',
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "DIGEORGE'S_SYNDROME",
                    displayName: "DiGeorge syndrome",
                    description:
                        "A chromosomal disorder that can cause heart problems and poor immune system functioning",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BRAND_TO_GENERIC",
                    displayName: "Eligible for generic",
                    category: "Pharmacy",
                    description:
                        "Prescribed brand-name medication has generic option or options available",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "AUTISM",
                    displayName: "Autism spectrum disorder (ASD)",
                    description:
                        "A developmental disorder that impairs communication and social interaction",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "CCS_EXCLUSION",
                    displayName: "Cervical cancer screening: Not eligible",
                    description:
                        "Not eligible for a screening due to a hysterectomy or other qualifying exclusion",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "IMMUNODEFICIENY_FOLLOW_HEREDITARY_DEFECTIVE_RESPONSE_TO_EPSTEIN-BARR_VIRUS",
                    displayName: "Immunodeficiency due to Epstein-Barr virus",
                    description:
                        'Commonly called "mononucleosis" or "mono," some people\'s response to Epstein-Barr infection can damage immune system functioning',
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "SHOP_XRAY_PREDICTED_HIGH",
                    displayName: "X-ray high possibility",
                    description: "Likely to need an X-ray",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BARIATRIC_SURGERY_PREDICTED_HIGH",
                    displayName: "Bariatric surgery high possibility",
                    description: "Likely to need bariatric surgery",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "GALL_STONES_PREDICTED_HIGH",
                    displayName: "High-risk for gallstones",
                    description: "At high risk of developing gallstones",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BACK_SURGERY_PREDICTED_LOW",
                    displayName: "Back surgery low possibility",
                    description: "May need back surgery",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "SHOP_MRI_PREDICTED_HIGH",
                    displayName: "MRI high possibility",
                    description:
                        "Likely to need an MRI (magnetic resonance imaging)",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "CERTAIN_INFECTIOUS_AND_PARASITIC_DISEASES",
                    displayName: "Infectious/parasitic disease(s)",
                    description:
                        "Bacterial or viral infection(s) such as malaria, dysentery, or STD",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ACEDCHFRM_TEST_ELIGIBLE",
                    displayName: "ACE inhibitor Rx reminder: Eligible",
                    description:
                        "Eligible to receive high BP (blood pressure) prescription reminder",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ANTICOAG_TEST_ELIGIBLE",
                    displayName: "Anticoagulant Rx reminder: Eligible",
                    description:
                        "Eligible to receive anticoagulant prescription reminder",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ASMCONTRM_TEST_ELIGIBLE",
                    displayName: "Asthma Rx reminder: Eligible",
                    description:
                        "Eligible to receive asthma prescription reminder",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BBPMI_TEST_ELIGIBLE",
                    displayName: "Blood pathogen testing: Eligible",
                    description:
                        "Meets the criteria for blood-borne pathogen testing",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BCS_TEST_ELIGIBLE",
                    displayName: "Breast cancer screening: Eligible",
                    description:
                        "Meets the criteria for regular breast cancer screenings",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ACEDCHFRM_TEST_DUE",
                    displayName: "ACE inhibitor Rx reminder: Due",
                    description:
                        "Due for a prescription reminder within 31 days",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ANTICOAG_TEST_DUE",
                    displayName: "Anticoagulant Rx reminder: Due",
                    description:
                        "Due for a prescription reminder within 31 days",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "ASMCONTRM_TEST_DUE",
                    displayName: "Asthma Rx reminder: Due",
                    description:
                        "Due for a prescription reminder within 31 days",
                    editable: false,
                    type: "RESTRICTED",
                },
                {
                    id: "BBPMI_TEST_DUE",
                    displayName: "Blood pathogen testing: Due",
                    description: "Due for a test within 31 days",
                    editable: false,
                    type: "RESTRICTED",
                },
            ],
        },
    },
};

const SCHEDULE_PAGE_JSON = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Schedule your notification",
            description: "Select a date and time to send your notification.",
            backCta: { text: "Back to set target population" },
            nextCta: { text: "Next" },
            formFields: [
                {
                    id: "date",
                    label: "When to send",
                    description: "",
                    placeholder: "",
                    type: "DATE",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "This field is mandatory",
                        },
                    ],
                },
            ],
        },
    },
    PRE_FILLED_DATA: {
        responseStatus: "SUCCESS",
        data: {
            date: "2020-08-25",
            time: "21:30:15",
            timeZone: "CST",
        },
    },
};

const SUMMARY_PAGE_JSON = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Summary and confirmation",
            description:
                "Please double-check that all the information below is correct.",
            sendCta: { text: "Schedule Notification" },
            loadingText:
                "Calculating the count, this is going to take a few seconds...",
            segments: [
                {
                    id: "notificationCard",
                    actionPage: "notificationsContent",
                    editable: true,
                    heading: "CONTENT",
                    fields: [
                        {
                            id: "title",
                            label: "Notification title:",
                            dependency: "title",
                            displayResolver: "{title}",
                            type: "LABEL",
                        },
                        {
                            id: "description",
                            label: "Description:",
                            dependency: "description",
                            displayResolver: "{description}",
                            type: "LABEL",
                        },
                        {
                            id: "taskIds",
                            label: "TaskIds:",
                            dependency: "taskIds",
                            displayResolver: "{taskIds}",
                            type: "LABEL",
                        },
                        {
                            id: "populationCount",
                            label: "Number of population seeing this notification:",
                            dependency: "",
                            displayResolver: "{count}",
                            type: "LAZY_TEXT",
                            dataEndpoint: "/cc/api/GET_POPULATION_COUNT",
                        },
                        {
                            id: "cta",
                            label: "CTA Type:",
                            dependency: "cta",
                            displayResolver: "{cta}",
                            type: "LABEL",
                        },
                        {
                            id: "ctaValue",
                            label: "SSO Link:",
                            dependency: "ctaValue",
                            displayResolver: "{ctaValue}",
                            type: "LABEL",
                        },
                        {
                            id: "buttonText",
                            label: "Button Text:",
                            dependency: "buttonText",
                            displayResolver: "{buttonText}",
                            type: "LABEL",
                        },
                        {
                            id: "priority",
                            label: "Priority:",
                            dependency: "priority",
                            displayResolver: "{priority}",
                            type: "OPTIONS",
                            data: {
                                low: { id: "low", value: "low", name: "Low" },
                                high: {
                                    id: "high",
                                    value: "high",
                                    name: "High",
                                },
                            },
                        },
                        {
                            id: "takeActionBy",
                            label: "Take Action Date:",
                            dependency: "takeActionBy",
                            displayResolver: "{takeActionBy}",
                            type: "DATE",
                        },
                    ],
                },
                {
                    id: "notificationPopulation",
                    actionPage: "notificationTargetPopulation",
                    editable: true,
                    heading: "SEGMENTED POPULATION",
                    fields: [
                        {
                            id: "populationName",
                            label: "Population Name:",
                            dependency: "populationName",
                            displayResolver: "{populationName}",
                            type: "LABEL",
                        },
                        {
                            id: "count",
                            label: "Total recipients:",
                            dependency: "count",
                            displayResolver: "{count}",
                            type: "LABEL",
                        },
                        {
                            id: "csvPopulation",
                            label: "CSV File: ",
                            dependency: "csvPopulationFileName",
                            displayResolver: "{csvPopulationFileName}",
                            type: "LABEL",
                        },
                        {
                            id: "audiences",
                            label: "Population audience:",
                            type: "LABEL",
                            editable: true,
                            displayResolver: "<br> {audiences}",
                        },
                        // {
                        //     id: "criteriaList",
                        //     label: "Population criteria:",
                        //     type: "CRITERIA_ARRAY",
                        //     dependency: "criteriaList",
                        //     criteriaHeading: "Criteria rule {count}",
                        //     tagNameResolver: "<b>{displayName}</b> - ",
                        //     ruleResolver: "{mappedField} {operator} [{values}]",
                        //     ruleDividerText: "- OR -",
                        //     ruleItems: [
                        //         {
                        //             id: "mappedField",
                        //             type: "OBJECT_RESOLVER",
                        //         },
                        //         {
                        //             id: "operator",
                        //             type: "OBJECT_RESOLVER",
                        //             // data: {
                        //             // isEqual: {
                        //             //   id: "isEqual",
                        //             //   name: "is Equal to",
                        //             //   value: "isEqual",
                        //             // },
                        //             // isNotEqual: {
                        //             //   id: "isNotEqual",
                        //             //   name: "is Not Equal to",
                        //             //   value: "isNotEqual",
                        //             // },
                        //             // contains: {
                        //             //   id: "isIn",
                        //             //   name: "is equal to one of the following",
                        //             //   value: "contains",
                        //             // },
                        //             // isGreaterThan: {
                        //             //   id: "isGreaterThan",
                        //             //   name: "is greater than",
                        //             //   value: "isGreaterThan",
                        //             // },
                        //             // isLessThan: {
                        //             //   id: "isLessThan",
                        //             //   name: "is less than",
                        //             //   value: "isLessThan",
                        //             // },
                        //             // },
                        //         },
                        //         {
                        //             id: "values",
                        //             type: "CSV_ARRAY",
                        //         },
                        //     ],
                        // },
                    ],
                },
                {
                    id: "scheduleNotification",
                    actionPage: "notificationSchedule",
                    editable: true,
                    heading: "SEND DETAILS",
                    fields: [
                        {
                            id: "date",
                            label: "Date of send:",
                            dependency: "date",
                            displayResolver: "{date}",
                            type: "DATE",
                        },
                        // {
                        //   id: "time",
                        //   label: "Time of send",
                        //   dependency: "time",
                        //   displayResolver: "{time} {timeZone}",
                        //   type: "TIME",
                        // },
                    ],
                },
            ],
        },
    },
    NOTIFICATION_SUMMARY_DATA: {
        responseStatus: "SUCCESS",
        data: {
            notificationCard: {
                title: "Benefits of direct deposit",
                description:
                    "Enroll in Direct Deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
                ctaType: "HREF",
                ctaValue: "directdeposit.sso",
                buttonText: "Enroll Now",
                priority: "high",
                takeActionBy: "2020-01-15",
                taskIds: ["Id1", "Id2"],
            },
            // title: "Benefits of direct deposit",
            // description:
            //   "Enroll in Direct Deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
            notificationPopulation: {
                populationName: "Hurricane Pop - IL",
                count: "<b>30,000</b> (5,000 Unregistered / 25,000 Registered)",
                audiences:
                    "[{'demo-company' : 'active employees in u.s. - dnow lp' , 'demo-loc-home_zip_NOT_IN' : ['04936','04945']}]",
                // csvPopulationFileName: "PopulationFileName.csv",
                // criteriaList: [
                //     [
                //         {
                //             id: "IS_REGISTERED",
                //         },
                //         {
                //             id: "cus_cityWork",
                //             tagRuleFields: {
                //                 mappedField: {
                //                     id: "cus_cityWork",
                //                     name: "City Work",
                //                     value: "cus_cityWork",
                //                 },
                //                 operator: {
                //                     id: "isEqual",
                //                     name: "is Equal to",
                //                     value: "isEqual",
                //                 },
                //                 values: ["Chicago"],
                //             },
                //         },
                //     ],
                // ],
            },
            scheduleNotification: {
                date: "2020-01-31",
                time: "16:00:00",
                timeZone: "CST",
            },
            // date: "2020-12-01",
            // time: "18:00",
        },
    },
};

const POPULATION_UPLOAD_JSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Set target population",
            description:
                "There are a couple ways you can send MyEvive notifications to specific populations. You can segment a new population or use a saved list.",
            nextButtonCta: { text: "Save & Continue" },
            uploadFile: {
                label: "Upload CSV",
                buttonText: "Upload new CSV file",
                fileDetailsResolver: "Currently Selected: {fileName}",
            },
            populationFormFields: [
                {
                    id: "populationName",
                    label: "Population Name",
                    description: "",
                    placeholder: "",
                    type: "TEXT",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter population name",
                        },
                        {
                            type: "ALPHA_NUMERIC",
                            errMsg: "Population name should be alpha numeric only",
                        },
                    ],
                },
            ],
        },
    },
    POPULATION_DATA: {
        responseStatus: "SUCCESS",
        data: {
            populationData: {
                populationName: "CSV population name",
            },
            fileData: {
                fileName: "SelectedFileName.csv", // or something from where i can identify if file is uploaded or not
            },
        },
    },
};

const COMMON_RESPONSE = {
    successResponse: {
        responseStatus: "SUCCESS",
        data: {},
    },

    failedResponse: {
        responseStatus: "FAILED",
        data: {},
    },

    showErrorResponse: {
        responseStatus: "SHOW_ERROR",
        data: {},
        message: "Some failed save message",
    },
};

export {
    DASHBOARD_PAGE_JSON,
    NOTIFICATION_PROGRAM_SELECTION,
    NOTIFICATION_CONTENT_PAGE_JSON,
    TARGET_POPULATION_JSON,
    POPULATION_UPLOAD_JSON,
    NEW_POPULATION_PAGE_JSON,
    TAG_SELECTION_PAGE_JSON,
    SCHEDULE_PAGE_JSON,
    COMMON_RESPONSE,
    SUMMARY_PAGE_JSON,
};
