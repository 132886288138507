// noinspection DuplicatedCode
const SAVED_POPULATION_DASHBOARD_DATA = {
    DASHBOARD: {
        STATIC_DATA: {
            responseStatus: "SUCCESS",
            data: {
                header: "Saved lists",
                description:
                    "Saved lists are used to send MyEvive notifications to specific populations. You can segment a new population or manage a previously saved list.",
                newPopulationCta: { text: "Segment new population" },
                savedListHeader: "Latest saved lists",
                loadMoreCta: { text: "Load more" },
                noPopulationMessage: "You dont have any saved population.",
            },
        },
        SAVED_POPULATION: {
            responseStatus: "SUCCESS",
            data: {
                savedPopulationList: [
                    {
                        id: "hurricane123",
                        title:
                            "Hurricane Send- Florida and some more text with it",
                        lastUsed: "2019-03-25",
                    },
                    {
                        id: "wellness123",
                        title: "Wellness 5K",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "financialSeminar",
                        title: "Financial Seminar",
                        lastUsed: "2019-08-25",
                    },
                    {
                        id: "financialSeminar2",
                        title: "Financial Seminar2",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "1hurricane123",
                        title:
                            "Hurricane Send- Florida and some more text with it",
                        lastUsed: "2019-03-25",
                    },
                    {
                        id: "1wellness123",
                        title: "Wellness 5K",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "1financialSeminar",
                        title: "Financial Seminar",
                        lastUsed: "2019-08-25",
                    },
                    {
                        id: "1financialSeminar2",
                        title: "Financial Seminar2",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "2hurricane123",
                        title:
                            "Hurricane Send- Florida and some more text with it",
                        lastUsed: "2019-03-25",
                    },
                    {
                        id: "2wellness123",
                        title: "Wellness 5K",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "2financialSeminar",
                        title: "Financial Seminar",
                        lastUsed: "2019-08-25",
                    },
                    {
                        id: "2financialSeminar2",
                        title: "Financial Seminar2",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "21hurricane123",
                        title:
                            "Hurricane Send- Florida and some more text with it",
                        lastUsed: "2019-03-25",
                    },
                    {
                        id: "21wellness123",
                        title: "Wellness 5K",
                        lastUsed: "2020-06-25",
                    },
                    {
                        id: "21financialSeminar",
                        title: "Financial Seminar",
                        lastUsed: "2019-08-25",
                    },
                    {
                        id: "21financialSeminar2",
                        title: "Financial Seminar2",
                        lastUsed: "2020-06-25",
                    },
                ],
            },
        },
    },
};

const NEW_EDIT_POPULATION_DATA = {
    STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Manage saved list",
            description: "",
            criteriaHeading: "Population Criteria",
            addCriteriaCta: { text: "Add New Rule" },
            nextButtonCta: { text: "Save" },
            deletePopulationCta: { text: "Delete saved list" },
            deletePopulationConfirmationModal: {
                header: "Are you sure?",
                description:
                    "If you delete this saved list, you will have to add it again.",
                positiveCta: { text: "Yes, delete this saved list" },
                negativeCta: { text: "No, keep this saved list" },
            },
            removeConfirmationModal: {
                header: "Are you sure?",
                description:
                    "Are you sure you want to remove this criteria? Changes will not be saved until saved.",
                positiveCta: { text: "Delete" },
                negativeCta: { text: "Cancel" },
            },
            backConfirmationModal: {
                header: "Are you sure?",
                description:
                    "All unsaved changes to this population will be lost",
                positiveCta: { text: "Yes, Go back" },
                negativeCta: { text: "Cancel" },
            },
            backCta: { text: "Back to Saved lists" },
            criteria: {
                heading: "Criteria rule {count}",
                editButtonCta: { text: "Edit Rule" },
                removeButtonCta: { text: "Remove" },
                ruleDisplayTemplate: "{mappedField} {operator} [{values}]",
                criteriaDividerText: "OR",
                placeholder: "Add Tags",
            },
            populationFormFields: [
                {
                    id: "populationName",
                    label: "Population Name",
                    description: "",
                    placeholder: "",
                    type: "TEXT",
                    editable: true,
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter population name",
                        },
                        {
                            type: "ALPHA_NUMERIC",
                            errMsg:
                                "Population name should be alpha numeric only",
                        },
                    ],
                },
            ],
        },
    },

    // reused in Tag selection page
    CRITERIA_RULE_FORM_DATA: {
        responseStatus: "SUCCESS",
        data: {
            formFields: [
                {
                    id: "mappedField",
                    label: "",
                    description: "",
                    placeholder: "",
                    type: "LABEL",
                    editable: false,
                    validations: [],
                },
                {
                    id: "operator",
                    type: "LAZY_MINIMAL_DROPDOWN",
                    label: "",
                    description: "",
                    placeholder: "Set operator",
                    editable: true,
                    defaultValue: "",
                    dataEndpoint: "",
                    options: {
                        lazyType: "FROM_PROPS",
                    },
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter operator",
                        },
                    ],
                    data: [],
                },
                {
                    id: "values",
                    type: "TSV_TEXT",
                    label: "",
                    description: "",
                    placeholder: "[Enter values separated by tabs]",
                    editable: true,
                    defaultValue: "",
                    dataEndpoint: "",
                    data: [],
                    validations: [
                        {
                            type: "MANDATORY",
                            errMsg: "Please enter values",
                        },
                    ],
                },
            ],
        },
    },
    // pre-fill
    GET_POPULATION_DETAILS: {
        responseStatus: "SUCCESS",
        data: {
            populationDetails: { populationName: "Population Name" },
            criteriaList: [
                [
                    {
                        id: "IS_REGISTERED",
                    },
                    {
                        id: "cus_cityWork",
                        tagRuleFields: {
                            mappedField: {
                                id: "cus_cityWork",
                                name: "City Work",
                                value: "cus_cityWork",
                            },
                            operator: {
                                id: "isEqual",
                                name: "is Equal to",
                                value: "isEqual",
                            },
                            values: ["Chicago"],
                        },
                    },
                ],
            ],
        },
    },
};

const TAG_SELECTION_DATA = {
    PAGE_STATIC_DATA: {
        responseStatus: "SUCCESS",
        data: {
            header: "Create saved list",
            description: "Select the criteria for this saved list.",
            backCta: { text: "Back to Saved lists" },
            forwardCta: { text: "Proceed with selection" },
            selectedMenu: {
                id: "SELECTED",
                displayName: "Selected",
            },
            allOptions: {
                id: "ALL",
                displayName: "All Tags",
            },

            tags: {
                selectTagCta: { text: "Select" },
                addTagCta: { text: "Set Rule" },
                removeTagCta: { text: "Remove Rule" },
                ruleDisplayTemplate: "{mappedField} {operator} [{values}]",
            },
            categoriesHeader: "SEARCH BY CATEGORIES",
            noTagsForCategory: "No tags present for the selected catgegory",
            changesNotSaved: "Changes currently not saved. Please continue.",
        },
    },
    CATEGORIES: {
        responseStatus: "SUCCESS",
        data: {
            categories: [
                {
                    id: "eligibility",
                    displayName: "Eligibility",
                    subCategories: [
                        { id: "demographics", displayName: "Demographics" },
                        {
                            id: "employerspecific",
                            displayName: "EmployerSpecific",
                        },
                        { id: "benefits", displayName: "Benefits" },
                    ],
                },
                {
                    id: "pharmacy",
                    displayName: "Pharmacy",
                    subCategories: [
                        { id: "pharmacy", displayName: "Pharmacy" },
                    ],
                },
                { id: "CUSTOM", displayName: "CUSTOM" },
            ],
        },
    },
};

const COMBINED_TAGS = {
    responseStatus: "SUCCESS",
    data: {
        tags: [
            {
                id: "ELIG_IS_EMPLOYEE",
                displayName: "Employee",
                category: "demographics",
                description:
                    "The person employed by this company (versus their spouse or other dependents)",
                editable: false,
                type: "PRESET",
                tagRuleFields: {
                    mappedField: {
                        id: "relationship",
                        name: "Relationship code",
                        description:
                            "The field contains code which describes the familiar or sponsor relationship between an enrolled member and the employee subscriber under whose plan of benefits the user is enrolled.",
                        value: "relationship",
                        resolver: "${member_profile.relationship}",
                    },
                    operator: {
                        id: "isEqual",
                        name: "is Equal to",
                        value: "isEqual",
                    },
                    values: ["EMPLOYEE"],
                },
            },
            {
                id: "ELIG_IS_MEDICALLY_ENROLLED_BCBS",
                displayName: "BCBS enrolled",
                category: "benefits",
                description: "Currently enrolled in a BCBS medical plan",
                editable: false,
                type: "PRESET",
                tagRuleFields: {
                    mappedField: {
                        id: "medical_plan_code",
                        name: "Medical plan code",
                        description:
                            "This field contains the medical plan code of the medical plan that the user is enrolled in",
                        value: "medical_plan_code",
                        resolver: "${benefits.medical_plan_code}",
                    },
                    operator: {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                    values: ["1", "2", "42", "76", "85", "86"],
                },
            },
            {
                id: "cus_cityHome",
                displayName: "City",
                category: "CUSTOM",
                description:
                    "Refine your population to only include residents of certain areas of certain states, provinces, or regions.",
                editable: true,
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_stateHome",
                displayName: "State",
                description:
                    "Refine your population to only include residents of certain states, provinces, or regions.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_cityWork",
                displayName: "City Work",
                description:
                    "Refine your population to only include employees working in certain areas of a state, province, or region.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_postcodeHome",
                displayName: "Zip Code",
                description:
                    "Refine your population to only include residents of certain postal codes.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_ageRange",
                displayName: "Age",
                description:
                    "Refine your population to only include certain ages or age groups.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    {
                        id: "isGreaterThan",
                        name: "is greater than",
                        value: "isGreaterThan",
                    },
                    {
                        id: "isLessThan",
                        name: "is less than",
                        value: "isLessThan",
                    },
                    { id: "inRange", name: "In Range", value: "inRange" },
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                ],
            },
            {
                id: "cus_stateWork",
                displayName: "State Work",
                description:
                    "Refine your population to only include employees working within certain states, provinces, or regions.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_postcodeWork",
                displayName: "Work Zip Code",
                description:
                    "Refine your population to only include employees with certain employer postal codes.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "cus_employeeId",
                displayName: "Employee Id",
                description:
                    "Refine your population to only include certain employees.",
                editable: true,
                category: "CUSTOM",
                type: "CUSTOM",
                operatorList: [
                    { id: "isEqual", name: "is Equal to", value: "isEqual" },
                    {
                        id: "isIn",
                        name: "is equal to one of the following",
                        value: "contains",
                    },
                ],
            },
            {
                id: "IS_REGISTERED",
                displayName: "Registered for MyEvive",
                description:
                    "Employee or spouse who is a registered MyEvive user",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ACCIDENT_CAUSED_BY_TRAVEL_AND_MOTION",
                displayName: "Accident: Travel and motion",
                description:
                    "An accident caused by travel and motion, such as a car accident",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "HYPERTENSION",
                displayName: "Heart disease: Hypertension",
                description: 'Also known as "hypertension"',
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "OTHER_AND_UNSPECIFIED_MALIGNANT_NEOPLASMS_OF_LYMPHOID__HEMATOPOIETIC_AND_RELATED_TISSUE",
                displayName: "Cancer: Lymphoid, unspecified",
                description:
                    "Cancer that affects unspecified lymphoid, hematopoietic, and related tissues",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "SHOULDER_SURGERY_PREDICTED_HIGH",
                displayName: "SHOULDER SURGERY PREDICTED HIGH",
                description: "",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "ACCIDENTS_INVOLVING_POWERED_VEHICLES_USED_SOLELY_WITHIN_THE_BUILDINGS_AND_PREMISES_OF_INDUSTRIAL_OR_COMMERCIAL_ESTABLISHMENT",
                displayName:
                    "Accident: Powered vehicle on industrial/commercial premises",
                description:
                    "In an accident involving powered vehicles used on the premises of an industrial or commercial establishment",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "OTHER_MALIGNANT_NEOPLASMS_OF_LYMPHOID_AND_HISTIOCYTIC_TISSUE",
                displayName: "Cancer: Lymphoid and/or histiocytic",
                description:
                    "Cancer in unspecified lymphoid and histiocytic tissues",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "SHOULDER_SURGERY_PREDICTED_LOW",
                displayName: "SHOULDER SURGERY PREDICTED LOW",
                description: "",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ALLERGY_STATUS_TO_SERUM_OR_VACCINE_STATUS",
                displayName: "Allergy to vaccine or serum transfusion",
                description: "Allergic to a vaccine or serum transfusion",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "MALIGNANT_NEOPLASM_OF_PENIS_AND_OTHER_MALE_GENITAL_ORGANS",
                displayName: "Cancer: Male genital organ",
                description:
                    "Cancer of the penis and/or other male genital organs",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "MALIGNANT_NEOPLASM_OF_SPINAL_CORD_CRANIAL_NERVES_AND_OTHER_PARTS_OF_CENTRAL_NERVOUS_SYSTEM",
                displayName: "Cancer: Nervous system",
                description:
                    "Affects the spinal cord cranial nerves and other parts of central nervous system",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "SECONDARY_AND_UNSPECIFIED_MALIGNANT_NEOPLASM_OF_LYMPH_NODES",
                displayName: "Cancer: Lymph nodes, secondary or unspecified",
                description:
                    "Secondary or unspecified cancers of the lymph nodes",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "CARCINOMA_IN_SITU_OF_CERVIX_UTERI",
                displayName: "Cancer: Cervical, stage 0",
                description:
                    'Non-invasive abnormal cells in the colorectal area that may progress into cancer; also known as "carcinoma in situ"',
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "NONRHEUMATIC_AORTIC_VALVE_DISORDER",
                displayName: "Heart disease: Aortic valve disease",
                description:
                    'Also known as "nonrheumatic aortic valve disorder"',
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "DIGEORGE'S_SYNDROME",
                displayName: "DiGeorge syndrome",
                description:
                    "A chromosomal disorder that can cause heart problems and poor immune system functioning",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BRAND_TO_GENERIC",
                displayName: "Eligible for generic",
                category: "Pharmacy",
                description:
                    "Prescribed brand-name medication has generic option or options available",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "AUTISM",
                displayName: "Autism spectrum disorder (ASD)",
                description:
                    "A developmental disorder that impairs communication and social interaction",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "CCS_EXCLUSION",
                displayName: "Cervical cancer screening: Not eligible",
                description:
                    "Not eligible for a screening due to a hysterectomy or other qualifying exclusion",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id:
                    "IMMUNODEFICIENY_FOLLOW_HEREDITARY_DEFECTIVE_RESPONSE_TO_EPSTEIN-BARR_VIRUS",
                displayName: "Immunodeficiency due to Epstein-Barr virus",
                description:
                    'Commonly called "mononucleosis" or "mono," some people\'s response to Epstein-Barr infection can damage immune system functioning',
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "SHOP_XRAY_PREDICTED_HIGH",
                displayName: "X-ray high possibility",
                description: "Likely to need an X-ray",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BARIATRIC_SURGERY_PREDICTED_HIGH",
                displayName: "Bariatric surgery high possibility",
                description: "Likely to need bariatric surgery",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "GALL_STONES_PREDICTED_HIGH",
                displayName: "High-risk for gallstones",
                description: "At high risk of developing gallstones",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BACK_SURGERY_PREDICTED_LOW",
                displayName: "Back surgery low possibility",
                description: "May need back surgery",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "SHOP_MRI_PREDICTED_HIGH",
                displayName: "MRI high possibility",
                description:
                    "Likely to need an MRI (magnetic resonance imaging)",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "CERTAIN_INFECTIOUS_AND_PARASITIC_DISEASES",
                displayName: "Infectious/parasitic disease(s)",
                description:
                    "Bacterial or viral infection(s) such as malaria, dysentery, or STD",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ACEDCHFRM_TEST_ELIGIBLE",
                displayName: "ACE inhibitor Rx reminder: Eligible",
                description:
                    "Eligible to receive high BP (blood pressure) prescription reminder",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ANTICOAG_TEST_ELIGIBLE",
                displayName: "Anticoagulant Rx reminder: Eligible",
                description:
                    "Eligible to receive anticoagulant prescription reminder",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ASMCONTRM_TEST_ELIGIBLE",
                displayName: "Asthma Rx reminder: Eligible",
                description: "Eligible to receive asthma prescription reminder",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BBPMI_TEST_ELIGIBLE",
                displayName: "Blood pathogen testing: Eligible",
                description:
                    "Meets the criteria for blood-borne pathogen testing",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BCS_TEST_ELIGIBLE",
                displayName: "Breast cancer screening: Eligible",
                description:
                    "Meets the criteria for regular breast cancer screenings",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ACEDCHFRM_TEST_DUE",
                displayName: "ACE inhibitor Rx reminder: Due",
                description: "Due for a prescription reminder within 31 days",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ANTICOAG_TEST_DUE",
                displayName: "Anticoagulant Rx reminder: Due",
                description: "Due for a prescription reminder within 31 days",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "ASMCONTRM_TEST_DUE",
                displayName: "Asthma Rx reminder: Due",
                description: "Due for a prescription reminder within 31 days",
                editable: false,
                type: "RESTRICTED",
            },
            {
                id: "BBPMI_TEST_DUE",
                displayName: "Blood pathogen testing: Due",
                description: "Due for a test within 31 days",
                editable: false,
                type: "RESTRICTED",
            },
        ],
    },
};

export {
    SAVED_POPULATION_DASHBOARD_DATA,
    NEW_EDIT_POPULATION_DATA,
    TAG_SELECTION_DATA,
    COMBINED_TAGS,
};
