import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/NotificationsActions";
import { getData, postData } from "../service/NotificationsService";
import API_URLS from "../../../services/apiUrls";
import {
    parseInitialStateFromFormFields,
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parsePreviewVariablesFromFormData,
} from "../../../utils/FormUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { NOTIFICATIONS_PAGES } from "../static/NotificationsStatics";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import {
    EvLoadingPage,
    EvSubmitActionView,
    EvText,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { EvFormRenderer } from "../../../common/components/form-components";
import EvLogger from "../../../utils/EvLogger";
import EvNotificationsPreviewCard from "./EvNotificationsPreviewCard";
import EvNotificationsCalloutPreviewCard from "./EvNotificationsCalloutPreviewCard";

import "../styles/notifications-content.scss";

const styles = {
    container: "ev__notifications-content__container",
    loadingContainer: "ev__notifications-content__loading-container",
    form: {
        container: "ev__notifications-content__form-container",
        itemContainer: "ev__notifications-content__form-item-container",
        labelContainer: "ev__notifications-content__form-label-container",
        inputContainer: "ev__notifications-content__form-input-container",
        submitButton: "ev__notifications-content__form-submit-button",
        submitContainer: "ev__notifications-content__form-submit-container",
    },
    preview: {
        container: "ev__notifications-content__preview-container",
        header: "ev__notifications-content__preview-header",
        notificationCard:
            "ev__notifications-content__preview-notification-card",
        calloutCard: "ev__notifications-content__preview-callout-card",
    },
};

const NotificationsContent = (props) => {
    const {
        clientCode,
        controls,
        staticData,

        // actions
        getStaticData,
        setControls,
    } = props;

    const [formData, setFormData] = useState({});
    const [lazyModalDataList, setLazyModalDataList] = useState({});

    const [preFillDataApiStatus, setPreFillDataApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.NOTIFICATIONS.CONTENT.STATIC,
                    templateId: NOTIFICATIONS_PAGES.CONTENT.id,
                }),
                getData(API_URLS.NOTIFICATIONS.CONTENT.PRE_FILLED_DATA, {
                    notificationId: controls.currentNotificationId || "",
                    clientCode,
                }),
                getData(API_URLS.NOTIFICATIONS.CONTENT.LAZY_MODAL_DATA, {
                    notificationId: controls.currentNotificationId || "",
                    clientCode,
                }),
            ]),
        [getStaticData, clientCode, controls.currentNotificationId]
    );

    useEffect(() => {
        setPreFillDataApiStatus(LOADING_STATUS.LOADING);
        getAllData().then(
            ([
                staticDataResponse,
                dynamicDataResponse,
                lazyModalDataResponse,
            ]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formFields,
                    dynamicDataResponse.data.data
                );
                setFormData(initialFormData);
                setLazyModalDataList(lazyModalDataResponse.data.data);
                setPreFillDataApiStatus(LOADING_STATUS.COMPLETED);
            }
        );
    }, [getAllData, setFormData, setPreFillDataApiStatus]);

    const isLoaded = isCompleted(
        controls.staticDataApiStatus[NOTIFICATIONS_PAGES.CONTENT.id],
        preFillDataApiStatus
    );

    const onFormSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const notificationId = controls.currentNotificationId || "";
        const isFormDataValid = isFullFormValid(formData);
        // if not valid, show all error messages
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
        } else {
            setSubmitStatus(LOADING_STATUS.LOADING);
            const apiData = parseFormDataForApi(formData);
            postData(
                API_URLS.NOTIFICATIONS.CONTENT.POST_DATA,
                {
                    notificationId,
                },
                {
                    ...apiData,
                    clientCode,
                }
            )
                .then((response) => {
                    EvToast.success("Saved", "", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    setSubmitStatus(LOADING_STATUS.COMPLETED);
                    setControls({
                        selectedPageId:
                            NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
                        // currentNotificationId: response.data.data.id || "",
                    });
                })
                .catch((e) => {
                    setSubmitStatus(LOADING_STATUS.FAILED);
                    EvToast.error("Sorry", "Something went wrong", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    EvLogger.errorWithObject(
                        e,
                        "NotificationContent onFormSubmitClick"
                    );
                });
        }
    }, [
        clientCode,
        controls.currentNotificationId,
        formData,
        setControls,
        submitStatus,
    ]);

    const getFormView = () => (
        <div className={styles.form.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormData}
                dataOptions={lazyModalDataList}
            />
            <EvSubmitActionView
                addLabelSpacing
                buttonClass={styles.form.submitButton}
                className={styles.form.submitContainer}
                buttonText={staticData.nextCta.text}
                onSubmitClick={onFormSubmitClick}
                loadingStatus={submitStatus}
            />
        </div>
    );

    const getNotificationPreviewView = () => {
        const { contentKeys, staticContent } =
            staticData.notificationsPreviewCard;
        const previewVariables = parsePreviewVariablesFromFormData(
            formData,
            contentKeys,
            staticContent
        );

        // if header and description is not entered, do no show preview
        if (!previewVariables.header && !previewVariables.description) {
            return <span />;
        }

        return (
            <div className={styles.preview.notificationCard}>
                <EvNotificationsPreviewCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                    ageString={staticContent.daysAgo}
                    // optional params
                    buttonText={previewVariables.buttonText}
                    takeActionString={previewVariables.takeActionDate}
                    priorityString={previewVariables.priority}
                />
            </div>
        );
    };

    const getCalloutPreviewCard = () => {
        const { contentKeys, staticContent } = staticData.calloutPreviewCard;
        const previewVariables = parsePreviewVariablesFromFormData(
            formData,
            contentKeys,
            staticContent
        );
        if (!previewVariables.header && !previewVariables.description) {
            return <span />;
        }
        return (
            <div className={styles.preview.calloutCard}>
                <EvNotificationsCalloutPreviewCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                    bgImage={previewVariables.bgImage}
                />
            </div>
        );
    };

    const getPreviewView = () => (
        <div className={styles.preview.container}>
            <EvText className={styles.preview.header}>
                {staticData.previewHeader}
            </EvText>
            {!isNullOrEmpty(staticData.notificationsPreviewCard) &&
                getNotificationPreviewView()}
            {!isNullOrEmpty(staticData.calloutPreviewCard) &&
                getCalloutPreviewCard()}
        </div>
    );

    const getHeaderView = () => (
        <div>
            <EvText subHeading>{staticData.header}</EvText>
        </div>
    );

    if (!isLoaded) {
        return (
            <div className={styles.loadingContainer}>
                <EvLoadingPage />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {(!isNullOrEmpty(staticData.notificationsPreviewCard) ||
                !isNullOrEmpty(staticData.calloutPreviewCard)) &&
                getPreviewView()}
        </div>
    );
};

NotificationsContent.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    // actions
    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    // setDynamicDataApiStatus: PropTypes.func,
    // resetDynamicData: PropTypes.func,
};

NotificationsContent.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    // actions
    getStaticData: () => {},
    setControls: () => {},
    // setDynamicDataApiStatus: () => {},
    // resetDynamicData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[NOTIFICATIONS_PAGES.CONTENT.id],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    // setDynamicDataApiStatus: payload =>
    //   dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
    // resetDynamicData: payload =>
    //   dispatch(NotificationsActions.resetDynamicData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContent);
