import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import API_URLS from "../../../services/apiUrls";
import {
    LINKED_UNLINKED_TABS,
    LINKED_UNLINKED_TAGS_COMPONENTS,
    LINKED_UNLINKED_TAGS_TEMPLATES,
} from "../statics/LinkedUnlinkedStatics";
import {
    EvHeaderView,
    EvLoadingPage,
    EvTabSelector,
    EvToast,
} from "../../../common/components";
import { isCompleted } from "../../../utils/CommonUtils";
import { normalizer } from "../../../utils/Normalizer";
import { LOADING_STATUS } from "../../../common/static/Enums";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_GENERIC_MESSAGES } from "../../../common/components/EvToast";
import UnlinkedTagsView from "./UnlinkedTagsView";
import LinkedTagsView from "./LinkedTagsView";
import VendorProgramsActions from "../../vendor-programs/redux/VendorProgramsActions";
import { getData } from "../../vendor-programs/service/VendorProgramsService";

import "../styles/linked-unlinked-tags-view.scss";

const styles = {
    container: "ev__linked-unlinked-tags-view__container",
    tabsContainer: "ev__linked-unlinked-tags-view__tabs-container",
};

const LinkedUnlinkedTagsView = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        getStaticData,
        setDynamicDataApiStatus,
        setComponentData,
    } = props;

    const [selectedTabId, setSelectedTabId] = useState(
        LINKED_UNLINKED_TABS.UNLINKED_TAGS
    );

    useEffect(() => {
        getStaticData({
            url: API_URLS.LINKED_UNLINKED_TAGS.STATIC,
            templateId: LINKED_UNLINKED_TAGS_TEMPLATES.STATICS.id,
        });
        setDynamicDataApiStatus({
            [LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id]:
                LOADING_STATUS.LOADING,
        });
        getData(API_URLS.LINKED_UNLINKED_TAGS.GET_ALL_TAGS, { clientCode })
            .then((allTagsResponse) => {
                const allTagsNormalized = normalizer(
                    allTagsResponse.data.data.tags
                );
                setComponentData({
                    data: allTagsNormalized,
                    componentId: LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id,
                });
                setDynamicDataApiStatus({
                    [LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id]:
                        LOADING_STATUS.FAILED,
                });
                EvToast.error("", TOAST_GENERIC_MESSAGES.WENT_WRONG);
                EvLogger.errorWithObject(
                    e,
                    "LinkedUnlinkedTagsView getAllTagsData"
                );
            });
    }, [getStaticData, setComponentData, setDynamicDataApiStatus, clientCode]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getNavView = () => (
        <EvTabSelector
            className={styles.tabsContainer}
            tabsData={staticData.tabsData}
            onTabSelect={setSelectedTabId}
        />
    );

    const getContent = () => {
        switch (selectedTabId) {
            case LINKED_UNLINKED_TABS.UNLINKED_TAGS:
                return (
                    <UnlinkedTagsView
                        clientCode={clientCode}
                        staticData={staticData}
                    />
                );

            case LINKED_UNLINKED_TABS.LINKED_TAGS:
                return (
                    <LinkedTagsView
                        clientCode={clientCode}
                        staticData={staticData}
                    />
                );

            default:
                return <span />;
        }
    };

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                LINKED_UNLINKED_TAGS_TEMPLATES.STATICS.id
            ],
            controls.dynamicDataApiStatus[
                LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getNavView()}
            {getContent()}
        </div>
    );
};

LinkedUnlinkedTagsView.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    setComponentData: PropTypes.func,
};

LinkedUnlinkedTagsView.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
    setComponentData: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.VendorProgramsReducer.staticData[
            LINKED_UNLINKED_TAGS_TEMPLATES.STATICS.id
        ],
    controls: state.VendorProgramsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(VendorProgramsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(VendorProgramsActions.setComponentData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkedUnlinkedTagsView);
