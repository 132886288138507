import React from "react";
import { Route, Switch } from "react-router-dom";

import { GAMEDAY_CONTENT_PAGES } from "../statics/GamedayContentStatics";
import GamedayContentDashboard from "./GamedayContentDashboard";
import GamedayContentGame from "./GamedayContentGame";
import GamedayContentConfigure from "./GamedayContentConfigure";

const GamedayRoutes = () => {
    // const {} = props;

    const getRoute = () => (
        <Switch>
            <Route
                path={GAMEDAY_CONTENT_PAGES.GAME_CONFIGURE.path}
                render={() => <GamedayContentConfigure />}
            />
            <Route
                path={GAMEDAY_CONTENT_PAGES.GAME.path}
                render={() => <GamedayContentGame />}
            />
            {/* default */}
            <Route render={() => <GamedayContentDashboard />} />
        </Switch>
    );

    return getRoute();
};

GamedayRoutes.propTypes = {};

GamedayRoutes.defaultProps = {};

export default GamedayRoutes;
