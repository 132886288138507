import DateTime from "date-and-time";
import stringTemplate from "string-template";

import {
    FORM_FIELD_TYPES,
    FORM_VISIBILITY_MODE,
    UI_FORMATS,
} from "../common/static/Enums";
import {
    isNullOrEmpty,
    guidGenerator,
    getDateForApi,
    getTimeForApi,
    isNullOrUndefined,
} from "./CommonUtils";
import FormValidation from "./FormValidations";
import EvLogger from "./EvLogger";
import { DATA_SOURCE_TYPE } from "../common/components/form-components/EvFormDropdown";

const reservedKeywordCheck = (itemKey) =>
    ["errorObject", "formItemType", "isVisible", "selectedValues"].indexOf(
        itemKey
    ) !== -1;

const getValuesForFormFieldDirect = (formField, preFilledFormItemData) => {
    const itemId = formField.id;
    const defaultValue = formField.defaultValue || "";
    return !isNullOrEmpty(preFilledFormItemData) ||
        preFilledFormItemData === false ||
        preFilledFormItemData === 0
        ? {
              [itemId]: {
                  key: itemId,
                  value: preFilledFormItemData,
              },
          }
        : {
              [itemId]: {
                  key: itemId,
                  value: defaultValue,
              },
          };
};

const getValuesForFormFieldCSV = (formField, preFilledFormItemData) => {
    const itemId = formField.id;
    const defaultValue = formField.defaultValue
        ? `${formField.defaultValue.join()},`
        : "";
    return !isNullOrEmpty(preFilledFormItemData)
        ? {
              [itemId]: {
                  key: itemId,
                  value: `${preFilledFormItemData.join()},`,
              },
          }
        : {
              [itemId]: {
                  key: itemId,
                  value: defaultValue,
              },
          };
};

const getValuesForFormFieldTSV = (formField, preFilledFormItemData) => {
    let valuesArray = Array.isArray(formField.defaultValue)
        ? formField.defaultValue
        : [];
    valuesArray = !isNullOrEmpty(preFilledFormItemData)
        ? preFilledFormItemData
        : valuesArray;
    const formData = valuesArray.reduce((obj, value) => {
        const guid = guidGenerator();
        return {
            ...obj,
            [guid]: { key: guid, value, name: "" },
        };
    }, {});
    return formData;
};

const getValuesForFormFieldBoolean = (formField, preFilledFormItemData) => {
    const itemId = formField.id;
    const defaultValue = formField.defaultValue || false;
    return preFilledFormItemData
        ? {
              [itemId]: {
                  key: itemId,
                  value: preFilledFormItemData,
              },
          }
        : {
              [itemId]: {
                  key: itemId,
                  value: defaultValue,
              },
          };
};

// for dropdown or where option id not same as item id, but only single selection. NOT ARRAY
const getValuesForFormFieldFromOptions = (formField, preFilledFormItemData) => {
    // if pre-fill data present
    if (preFilledFormItemData) {
        const selectedItemObject = formField.data.find(
            (dataItem) => preFilledFormItemData === dataItem.value
        );
        // if pre-fill data object found
        if (selectedItemObject) {
            return {
                [selectedItemObject.id]: {
                    key: selectedItemObject.id,
                    value: selectedItemObject.value,
                    name: selectedItemObject.name,
                },
            };
        }
        // no match for pre-fill value
        EvLogger.warn(
            `getValuesForFormFieldFromOptions preFill data doesn't match any option for field ${JSON.stringify(
                formField
            )}, and preFill data ${JSON.stringify(preFilledFormItemData)}`
        );
    }

    // else id default value present
    if (formField.defaultValue) {
        const defaultSelectedItemObject = formField.data.find(
            (dataItem) => formField.defaultValue === dataItem.value
        );
        // if default data object found
        if (defaultSelectedItemObject) {
            return {
                [defaultSelectedItemObject.id]: {
                    key: defaultSelectedItemObject.id,
                    value: defaultSelectedItemObject.value,
                    name: defaultSelectedItemObject.name,
                },
            };
        }
        // no match for default value
        EvLogger.warn(
            `getValuesForFormFieldFromOptions default data doesn't match any option for field ${JSON.stringify(
                formField
            )}, and default data ${JSON.stringify(preFilledFormItemData)}`
        );
    }

    // if no luck in both return empty
    return {};
};

// for dropdown or where option id not same as item id, but multiple selection. ARRAYS
const getValuesForMultipleOptions = (formField, preFilledFormItemData) => {
    // if pre-fill data present
    if (
        !isNullOrUndefined(preFilledFormItemData) &&
        typeof preFilledFormItemData === "object"
    ) {
        const formDataItems = {};
        preFilledFormItemData.forEach((itemData) => {
            const selectedItemObject = formField.data.find(
                (dataItem) => itemData.id === dataItem.id
            );
            // if pre-fill data object found
            if (selectedItemObject) {
                formDataItems[selectedItemObject.id] = {
                    key: selectedItemObject.id,
                    value: selectedItemObject.value,
                    name: selectedItemObject.name,
                };
            } else if (
                formField.options.dataSourceType ===
                DATA_SOURCE_TYPE.FROM_EXTERNAL_URL
            ) {
                formDataItems[itemData.id] = {
                    key: itemData.id,
                    value: itemData.value,
                    name: itemData.name,
                };
            } else {
                // no match for pre-fill value
                EvLogger.warn(
                    `getValuesForMultipleOptions preFill data doesn't match any option for field ${JSON.stringify(
                        formField
                    )}, and preFill data ${JSON.stringify(
                        preFilledFormItemData
                    )}`
                );
            }
        });
        return formDataItems;
    }

    // else id default value present
    if (!isNullOrEmpty(formField.defaultValue)) {
        const formDataItems = {};
        formField.defaultValue.forEach((itemData) => {
            const selectedItemObject = formField.data.find(
                (dataItem) => itemData.id === dataItem.id
            );
            if (selectedItemObject) {
                formDataItems[selectedItemObject.id] = {
                    key: selectedItemObject.id,
                    value: selectedItemObject.value,
                    name: selectedItemObject.name,
                };
            } else {
                // no match for pre-fill value
                EvLogger.warn(
                    `getValuesForMultipleOptions default data doesn't match any option for field ${JSON.stringify(
                        formField
                    )}, and default data ${JSON.stringify(
                        preFilledFormItemData
                    )}`
                );
            }
        });

        return formDataItems;
    }

    // if no luck in both return empty
    return {};
};

const getValuesForFormLazyOptions = (formField, preFilledFormItemData) =>
    // const itemId = formField.id;
    // no default value for now, change in structure
    // const defaultValue = formField.defaultValue || "";
    !isNullOrEmpty(preFilledFormItemData)
        ? {
              [preFilledFormItemData.id]: {
                  key: preFilledFormItemData.id,
                  value: preFilledFormItemData.value,
                  name: preFilledFormItemData.name,
              },
          }
        : {};

const getValuesForFormFieldDateTime = (formField, preFilledFormItemData) => {
    const DATE_TIME_FORMAT = {
        DATE: "YYYY-MM-DD",
        TIME: "HH:mm:ss",
    };
    const itemId = formField.id;
    let timeValue = preFilledFormItemData || formField.defaultValue;
    if (timeValue) {
        timeValue = DateTime.parse(timeValue, DATE_TIME_FORMAT[formField.type]);
    }
    return {
        [itemId]: {
            key: itemId,
            value: timeValue,
        },
    };
};

const getValuesForFormTemplate = (formField, preFilledFormItemData) => {
    const formTemplateData = parseInitialObjectForFormTemplates(
        formField.formItems,
        preFilledFormItemData
    );
    return {
        formTemplateData,
        errorObject: {
            ...FormValidation(formField, formTemplateData),
        },
        formItemType: formField.type,
    };
};

const getAllValuesForFormFieldByType = (
    formItemId,
    formField,
    preFilledFormData
) => {
    switch (formField.type) {
        case FORM_FIELD_TYPES.TEXT:
        case FORM_FIELD_TYPES.PREFIX_TEXT:
        case FORM_FIELD_TYPES.IMAGE_UPLOAD:
        case FORM_FIELD_TYPES.BASE_FILE_UPLOAD:
        case FORM_FIELD_TYPES.COLOR:
        case FORM_FIELD_TYPES.LARGE_TEXT_AREA:
        case FORM_FIELD_TYPES.SMALL_TEXT_AREA:
        case FORM_FIELD_TYPES.HIDDEN_TEXT:
        case FORM_FIELD_TYPES.STRING_LABEL:
        case FORM_FIELD_TYPES.DESCRIPTION_TEXT:
        case FORM_FIELD_TYPES.FILE_UPLOAD:
            return getValuesForFormFieldDirect(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.DATE:
        case FORM_FIELD_TYPES.TIME:
            return getValuesForFormFieldDateTime(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.CSV_TEXT:
            return getValuesForFormFieldCSV(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.TSV_TEXT:
        case FORM_FIELD_TYPES.BULLETS:
        case FORM_FIELD_TYPES.TEXT_LIST:
            return getValuesForFormFieldTSV(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.TOGGLE:
            return getValuesForFormFieldBoolean(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.DROPDOWN:
        case FORM_FIELD_TYPES.MINIMAL_DROPDOWN:
        case FORM_FIELD_TYPES.RADIO:
            return getValuesForFormFieldFromOptions(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.LAZY_MODAL_SELECTION:
        case FORM_FIELD_TYPES.LAZY_DROPDOWN:
        case FORM_FIELD_TYPES.LABEL:
        case FORM_FIELD_TYPES.LAZY_MINIMAL_DROPDOWN:
            return getValuesForFormLazyOptions(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.CHECKLIST_DROPDOWN:
            return getValuesForMultipleOptions(
                formField,
                preFilledFormData[formItemId]
            );

        case FORM_FIELD_TYPES.FORM_TEMPLATE:
            return getValuesForFormTemplate(
                formField,
                preFilledFormData[formItemId]
            );

        default:
            return getValuesForFormFieldDirect(
                formField,
                preFilledFormData[formItemId]
            );
    }
};

const getSubFormSubItemsForSelectedData = (
    subFormArray,
    parentSelectedData
) => {
    // eslint-disable-next-line no-use-before-define
    const selectedId = getFirstIdFromSelectedFormData(parentSelectedData);

    // should be an array of elements
    // for multiple matches using filter, we can change here
    return subFormArray.filter(
        (subFormItem) => subFormItem.matchId === selectedId
    );
};

const getInitialObjectForFormField = (
    formFieldItem,
    preFilledFormData = {}
) => {
    const formItemId = formFieldItem.id;
    const initialValueForForm = getAllValuesForFormFieldByType(
        formItemId,
        formFieldItem,
        preFilledFormData
    );
    const errorObject = {
        ...FormValidation(formFieldItem, initialValueForForm),
    };
    const isVisible = isFormItemVisible(formFieldItem, preFilledFormData);
    let formData = {
        // TODO: should be changed later.
        //  right now can only handle single selection and not checkbox types
        [formItemId]: {
            ...initialValueForForm,
            errorObject:
                formFieldItem.type === FORM_FIELD_TYPES.FORM_TEMPLATE
                    ? initialValueForForm.errorObject
                    : errorObject,
            // errorObject: {
            //   ...initialValueForForm.errorObject,
            //   isUsed: initialValueForForm.errorObject
            //     ? !initialValueForForm.errorObject.isValid
            //     : false,
            // },
            formItemType: formFieldItem.type,
            isVisible,
        },
    };

    // add sub form default values
    if (!isNullOrEmpty(formFieldItem.subForm)) {
        const subFormSelected = getSubFormSubItemsForSelectedData(
            formFieldItem.subForm,
            initialValueForForm
        );
        const subFormInitialState = parseInitialStateFromFormFields(
            subFormSelected,
            preFilledFormData
        );
        formData = {
            ...formData,
            ...subFormInitialState,
        };
    }
    return formData;
};

const getInitialObjectForTimeZoneField = (
    formFieldItem,
    preFilledFormData = {}
) => {
    const initialState = {
        ...(!isNullOrEmpty(formFieldItem.dateFormField)
            ? getInitialObjectForFormField(
                  formFieldItem.dateFormField,
                  preFilledFormData
              )
            : {}),
        ...(!isNullOrEmpty(formFieldItem.timeFormField)
            ? getInitialObjectForFormField(
                  formFieldItem.timeFormField,
                  preFilledFormData
              )
            : {}),
        ...(!isNullOrEmpty(formFieldItem.timeZoneFormField)
            ? getInitialObjectForFormField(
                  formFieldItem.timeZoneFormField,
                  preFilledFormData
              )
            : {}),
    };
    return initialState;
};

const parseInitialObjectForFormTemplates = (
    formFields,
    preFilledFormData = []
) => {
    let initialState = {};
    preFilledFormData.forEach((currentPreFilledItem) => {
        const guid = guidGenerator();
        initialState = {
            ...initialState,
            [guid]: parseInitialStateFromFormFields(
                formFields,
                currentPreFilledItem
            ),
        };
    });
    return initialState;
};

const parseInitialStateFromFormFields = (
    formFields,
    preFilledFormData = {}
) => {
    let initialState = {};
    for (let i = 0; i < formFields.length; i++) {
        switch (formFields[i].type) {
            // special case for dateTimeZone where it has internal form items
            // it internally calls getInitialObjectForFormField() for individual form item
            case FORM_FIELD_TYPES.DATE_TIME_ZONE:
                initialState = {
                    ...initialState,
                    ...getInitialObjectForTimeZoneField(
                        formFields[i],
                        preFilledFormData
                    ),
                };
                break;

            // case FORM_FIELD_TYPES.FORM_TEMPLATE:
            // formTemplateData = parseInitialObjectForFormTemplates(
            //   formFields[i].formItems,
            //   preFilledFormData[formFields[i].id]
            // );
            // initialState = {
            //   ...initialState,
            //   [formFields[i].id]: {
            //     formTemplateData,
            //     errorObject: {
            //       ...FormValidation(formFields[i], formTemplateData),
            //     },
            //     formItemType: formFields[i].type,
            //   },
            // };
            // break;

            default:
                initialState = {
                    ...initialState,
                    ...getInitialObjectForFormField(
                        formFields[i],
                        preFilledFormData
                    ),
                };
        }
    }

    return initialState;
};

const getArrayValuesForFormItem = (formItemData) => {
    const formValuesArray = [];
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        // formItemData[valueId].forEach(valueObject => {
        //   formValuesArray.push(valueObject.value);
        // });
        if (formItemData[valueId].value) {
            formValuesArray.push(formItemData[valueId].value);
        }
    });
    return formValuesArray;
};

const getArrayValuesForFormTemplateItem = (formItemData) => {
    const formValuesArray = [];
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        // formItemData[valueId].forEach(valueObject => {
        //   formValuesArray.push(valueObject.value);
        // });
        Object.keys(formItemData.formTemplateData).forEach((formTemplateId) => {
            formValuesArray.push(
                // eslint-disable-next-line no-use-before-define
                parseFormDataForApi(
                    formItemData.formTemplateData[formTemplateId]
                )
            );
        });
    });
    return formValuesArray;
};

const getStringValuesForFormItem = (formItemData) => {
    let formValueString = "";
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        // formItemData[valueId].forEach(valueObject => {
        //   formValueString = valueObject.value;
        // });
        formValueString = formItemData[valueId].value;
    });
    return formValueString;
};

const getObjectValuesForFormItem = (formItemData) => {
    let formValueString = {};
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        formValueString = {
            id: formItemData[valueId].key,
            name: formItemData[valueId].name,
            value: formItemData[valueId].value,
        };
    });
    return formValueString;
};

const getObjectArrayValuesForFormItem = (formItemData) => {
    const formValuesArray = [];
    Object.entries(formItemData).forEach(([valueId, itemData]) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        formValuesArray.push({
            id: itemData.key,
            value: itemData.value,
            name: itemData.name,
        });
    });
    return formValuesArray;
};

const getArrayValuesFromStringForFormItem = (formItemData) => {
    let formValuesArray = [];
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        formValuesArray.push(...formItemData[valueId].value.split(","));
    });
    formValuesArray = formValuesArray.filter((el) => !!el);
    return formValuesArray;
};

const getDateValuesForFormItem = (formItemData) => {
    let formValueString = "";
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        // formItemData[valueId].forEach(valueObject => {
        //   formValueString = valueObject.value;
        // });
        formValueString = getDateForApi(formItemData[valueId].value);
    });
    return formValueString;
};

const getTimeValueForFormItem = (formItemData) => {
    let formValueString = "";
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        // formItemData[valueId].forEach(valueObject => {
        //   formValueString = valueObject.value;
        // });
        formValueString = getTimeForApi(formItemData[valueId].value);
    });
    return formValueString;
};

const getFormValuesBasedOnType = (formItemData) => {
    switch (formItemData.formItemType) {
        case FORM_FIELD_TYPES.TEXT:
        case FORM_FIELD_TYPES.PREFIX_TEXT:
        case FORM_FIELD_TYPES.IMAGE_UPLOAD:
        case FORM_FIELD_TYPES.BASE_FILE_UPLOAD:
        case FORM_FIELD_TYPES.COLOR:
        case FORM_FIELD_TYPES.LARGE_TEXT_AREA:
        case FORM_FIELD_TYPES.SMALL_TEXT_AREA:
        case FORM_FIELD_TYPES.TOGGLE:
        case FORM_FIELD_TYPES.HIDDEN_TEXT:
        case FORM_FIELD_TYPES.DROPDOWN:
        case FORM_FIELD_TYPES.FILE_UPLOAD:
        case FORM_FIELD_TYPES.MINIMAL_DROPDOWN:
        case FORM_FIELD_TYPES.RADIO:
        case FORM_FIELD_TYPES.LABEL:
        case FORM_FIELD_TYPES.STRING_LABEL:
        case FORM_FIELD_TYPES.DESCRIPTION_TEXT:
        case FORM_FIELD_TYPES.LAZY_MODAL_SELECTION:
        case FORM_FIELD_TYPES.CRITERIA_BUILDER:
            return getStringValuesForFormItem(formItemData);
        case FORM_FIELD_TYPES.DATE:
            return getDateValuesForFormItem(formItemData);
        case FORM_FIELD_TYPES.TIME:
            return getTimeValueForFormItem(formItemData);
        case FORM_FIELD_TYPES.CSV_TEXT:
            return getArrayValuesFromStringForFormItem(formItemData);
        case FORM_FIELD_TYPES.TSV_TEXT:
        case FORM_FIELD_TYPES.BULLETS:
        case FORM_FIELD_TYPES.TEXT_LIST:
            return getArrayValuesForFormItem(formItemData);
        case FORM_FIELD_TYPES.CHECKLIST_DROPDOWN:
            return getObjectArrayValuesForFormItem(formItemData);
        case FORM_FIELD_TYPES.FORM_TEMPLATE:
            return getArrayValuesForFormTemplateItem(formItemData);
        case FORM_FIELD_TYPES.LAZY_MINIMAL_DROPDOWN:
        case FORM_FIELD_TYPES.LAZY_DROPDOWN:
            return getObjectValuesForFormItem(formItemData);

        // default case, if not sure of the case. TREAT THIS AS END OF WORLD SCENARIO
        // for any case to use getArrayValuesForFormItem, specifically mention it
        default:
            return getArrayValuesForFormItem(formItemData);
    }
};

const parseFormDataForApi = (formData) => {
    const parsedFormData = {};
    Object.keys(formData).forEach((itemId) => {
        // for empty objects don't add to form object for API
        if (isNullOrEmpty(formData[itemId])) {
            return;
        }
        parsedFormData[itemId] = getFormValuesBasedOnType(formData[itemId]);
    });
    return parsedFormData;
};

const parseCriteriaFormDataForApi = (formData) => {
    const apiData = Object.entries(formData).map((criteriaItemObject) =>
        Object.entries(criteriaItemObject[1]).map((tagItemObject) => ({
            id: tagItemObject[0],
            mappedRule: parseFormDataForApi(tagItemObject[1]),
        }))
    );
    return apiData;
};

const getStringNamesForFormItem = (formItemData) => {
    let formValueString = "";
    Object.keys(formItemData).forEach((valueId) => {
        if (reservedKeywordCheck(valueId)) {
            return;
        }
        formValueString = formItemData[valueId].name;
    });
    return formValueString;
};

const getFormNamesBasedOnType = (formItemData) => {
    switch (formItemData.formItemType) {
        case FORM_FIELD_TYPES.LAZY_MODAL_SELECTION:
        case FORM_FIELD_TYPES.MINIMAL_DROPDOWN:
        case FORM_FIELD_TYPES.LABEL:
        case FORM_FIELD_TYPES.LAZY_MINIMAL_DROPDOWN:
            return getStringNamesForFormItem(formItemData);
        case FORM_FIELD_TYPES.CSV_TEXT:
            // same as values
            return getArrayValuesFromStringForFormItem(formItemData);
        // case FORM_FIELD_TYPES.DROPDOWN:
        //   return getArrayValuesForFormItem(formItemData);

        // default case, if not sure of the case. TREAT THIS AS END OF WORLD SCENARIO
        // for any case to use getArrayValuesForFormItem, specifically mention it
        default:
            return getArrayValuesForFormItem(formItemData);
    }
};

const parseFormDataForNames = (formData) => {
    const parsedFormData = {};
    Object.keys(formData).forEach((itemId) => {
        // for empty objects don't add to form object for API
        if (isNullOrEmpty(formData[itemId])) {
            return;
        }
        parsedFormData[itemId] = getFormNamesBasedOnType(formData[itemId]);
    });
    return parsedFormData;
};

// remove empty objects
const cleanFormObject = (formData) => {
    let cleanedObject = {};
    Object.keys(formData).forEach((itemKey) => {
        if (!isNullOrEmpty(formData[itemKey])) {
            cleanedObject = {
                ...cleanedObject,
                [itemKey]: formData[itemKey],
            };
        }
    });
    return cleanedObject;
};

const isFullFormValidForFormTemplate = (formTemplateData) => {
    const isFormTemplateValid = Object.keys(formTemplateData).some(
        (formTemplateId) => {
            // eslint-disable-next-line no-use-before-define
            if (reservedKeywordCheck(formTemplateId)) {
                return false;
            }
            // eslint-disable-next-line no-use-before-define
            return !isFullFormValid(formTemplateData[formTemplateId]);
        }
    );
    return isFormTemplateValid;
};

const isFullFormValid = (formObject) => {
    const isFormInValid = Object.keys(formObject).some((formItemKey) => {
        // empty object check, ignore. Sub forms will have empty objects
        if (isNullOrEmpty(formObject[formItemKey])) {
            return false;
        }

        // for FORM TEMPLATES
        if (
            formObject[formItemKey].formItemType ===
            FORM_FIELD_TYPES.FORM_TEMPLATE
        ) {
            return (
                isFullFormValidForFormTemplate(
                    formObject[formItemKey].formTemplateData
                ) || !formObject[formItemKey].errorObject.isValid
            );
        }

        // for hidden object always true
        if (formObject[formItemKey].isVisible === false) {
            return false;
        }

        if (
            !isNullOrEmpty(formObject[formItemKey].errorObject) &&
            formObject[formItemKey].errorObject.isValid
        ) {
            return false; // will continue with the rest of the form items
        }
        return true; // will exit loop when iit sees a true
    });
    return !isFormInValid;
};

const markAllFieldsUsedForFormTemplate = (formTemplateData) => {
    let newFormObject = {};
    Object.keys(formTemplateData).forEach((formTemplateId) => {
        newFormObject = {
            ...newFormObject,
            // eslint-disable-next-line no-use-before-define
            [formTemplateId]: markAllFieldsUsed(
                formTemplateData[formTemplateId]
            ),
        };
    });
    return newFormObject;
};

const markAllFieldsUsed = (formObject) => {
    let newFormObject = {};
    Object.keys(formObject).forEach((formItemKey) => {
        let currentNewFormObject = {};
        if (
            formObject[formItemKey].formItemType ===
            FORM_FIELD_TYPES.FORM_TEMPLATE
        ) {
            currentNewFormObject = {
                ...formObject[formItemKey],
                errorObject: {
                    ...formObject[formItemKey].errorObject,
                    isUsed: true,
                },
                formTemplateData: markAllFieldsUsedForFormTemplate(
                    formObject[formItemKey].formTemplateData
                ),
            };
        } else {
            // for sub forms it will b empty and should not be marked as used
            currentNewFormObject = isNullOrEmpty(formObject[formItemKey])
                ? {}
                : {
                      ...formObject[formItemKey],
                      errorObject: {
                          ...formObject[formItemKey].errorObject,
                          isUsed: true,
                      },
                  };
        }
        newFormObject = {
            ...newFormObject,
            [formItemKey]: currentNewFormObject,
        };
    });
    return newFormObject;
};

const getFirstIdFromSelectedFormData = (selectedFormData) =>
    Object.keys(selectedFormData).find(
        (formDataKey) =>
            !reservedKeywordCheck(formDataKey) &&
            !isNullOrEmpty(selectedFormData[formDataKey])
    );

// return an array of form items
// for multiple subforms
const getSelectedSubFormItemData = (subFormArray, parentFormData) => {
    // we are assuming that it will have only one valid selected object
    const selectedItemId = getFirstIdFromSelectedFormData(parentFormData);
    const selectedSubFormArray = subFormArray.filter(
        (subFormItem) => subFormItem.matchId === selectedItemId
    );
    return isNullOrEmpty(selectedSubFormArray) ? [] : selectedSubFormArray;
};

const resetSubFormObjects = (
    parentKey,
    formData,
    formItemData,
    initialFormData
) => {
    let newFormData = formData;

    // remove all values for subform
    formItemData.subForm.forEach((subFormItem) => {
        newFormData = {
            ...newFormData,
            [subFormItem.id]: {},
        };
    });

    // add selected subform object
    const selectedSubFormItems = getSelectedSubFormItemData(
        formItemData.subForm,
        formData[parentKey]
    );
    // selected item has sub form
    selectedSubFormItems.forEach((selectedSubFormItemData) => {
        if (!isNullOrEmpty(selectedSubFormItemData)) {
            // if (selectedSubFormItemData.type === FORM_FIELD_TYPES.FORM_TEMPLATE) {
            //   newFormData = {
            //     ...newFormData,
            //   };
            // }
            // const defaultSubFormValue = getAllValuesForFormFieldByType(
            //   selectedSubFormItemData.id,
            //   selectedSubFormItemData,
            //   selectedSubFormItemData.alwaysPreFill ? initialFormData : {} // if alwaysPreFill is there only then prefill data is used, otherwise not
            // );
            newFormData = {
                ...newFormData,
                /* [selectedSubFormItemData.id]: {
          ...defaultSubFormValue,
          errorObject: {
            ...FormValidation(selectedSubFormItemData, defaultSubFormValue),
          },
          formItemType: selectedSubFormItemData.type,
        }, */
                ...getInitialObjectForFormField(
                    selectedSubFormItemData,
                    selectedSubFormItemData.alwaysPreFill ? initialFormData : {}
                ),
            };
        }
    });
    return newFormData;
};

const parseInitialStateFromPillsInput = (selectedData, allData) => {
    let formData = {};
    selectedData.forEach((selectedRow) => {
        const rowId = guidGenerator();
        selectedRow.forEach((selectedItemId) => {
            // if id not present in superset
            if (isNullOrEmpty(allData[selectedItemId])) {
                EvLogger.error(
                    `parseInitialStateFromPillsInput id:${selectedItemId} not present in superset.`
                );
                return;
            }
            formData = {
                ...formData,
                [rowId]: {
                    ...formData[rowId],
                    [selectedItemId]: {
                        id: selectedItemId,
                        value: selectedItemId,
                        name: allData[selectedItemId].header,
                    },
                },
            };
        });
    });
    return formData;
};

// {key1: {val1: {...}, val2: {...}}, key2: {val3: {...}}, key3: {}}
// -----> [['val1', 'val2'],['val3']]
const parseFormDataToDirectArrayValues = (formData) => {
    const parsedData = [];
    Object.keys(formData).forEach((formItemId) => {
        // if items not present, don't add to array
        if (isNullOrEmpty(formData[formItemId])) {
            return;
        }
        const items = Object.keys(formData[formItemId]).map(
            (formValueItemId) => formData[formItemId][formValueItemId].id
        );
        parsedData.push(items);
    });
    return parsedData;
};

const getAllErrorMessageFromFormData = (formData) => {
    const errorMessages = [];
    Object.keys(formData).forEach((formItemId) => {
        if (
            formData[formItemId].errorObject &&
            !formData[formItemId].errorObject.isValid
        ) {
            errorMessages.push(
                ...Object.values(formData[formItemId].errorObject.errorMessages)
            );
        }
    });
    return errorMessages;
};

// used for User Mapping Tags
const parseInitialFormDataForTags = (
    tagIdList,
    tagData,
    formFields,
    allTagsData
) => {
    const formData = {};
    tagIdList.forEach((tagId) => {
        const currentTagData = tagData[tagId];
        if (isNullOrEmpty(allTagsData[tagId])) {
            EvLogger.error(
                `parseInitialFormDataForTags: unknown tag id: ${tagId}, not in all tags list`,
                "cc",
                "parse"
            );
            return;
        }
        const currentFormData = parseInitialStateFromFormFields(
            formFields,
            currentTagData ? currentTagData.tagRuleFields : {} // fields comes empty for unlinked tags
        );
        formData[tagId] = currentFormData;
    });
    return formData;
};

const parseInitialFormDataForCriteriaList = (formFields, criteriaList = []) => {
    const formDataForAllCriteria = {};
    criteriaList.forEach((criteriaData) => {
        const guid = guidGenerator();
        const tagFormData = {};
        criteriaData.forEach((tagData) => {
            tagFormData[tagData.id] = parseInitialStateFromFormFields(
                formFields,
                tagData.tagRuleFields
            );
        });
        formDataForAllCriteria[guid] = tagFormData;
    });
    return formDataForAllCriteria;
};

const parseInitialFormDataForFormList = (formFields, formDataList = []) => {
    const fullFormData = {};
    formDataList.forEach((formDataItem) => {
        const itemId = formDataItem.id || guidGenerator();
        fullFormData[itemId] = parseInitialStateFromFormFields(
            formFields,
            formDataItem
        );
    });
    return fullFormData;
};

const parseCombinedFormListDataForApi = (formDataList) => {
    const apiData = [];
    Object.entries(formDataList).forEach((formData) => {
        const currentFormData = parseFormDataForApi(formData[1]);
        apiData.push({
            id: formData[0],
            ...currentFormData,
        });
    });
    return apiData;
};

const previewVariablesStringTemplateArrayFromTemplate = (
    key,
    targetKey,
    formData,
    templateString
) => {
    const valuesArray = [];
    if (isNullOrEmpty(formData) || isNullOrEmpty(formData.formTemplateData)) {
        return valuesArray;
    }
    const secondaryKey = targetKey.split(".")[1];
    Object.keys(formData.formTemplateData).forEach((templateKey) => {
        const previewObject = parsePreviewVariablesFromFormData(
            formData.formTemplateData[templateKey],
            {
                [key]: secondaryKey,
            },
            {
                [key]: templateString,
            }
        );
        if (!isNullOrEmpty(previewObject[key])) {
            valuesArray.push(previewObject[key]);
        }
    });
    return valuesArray;
};

const previewVariablesStringTemplateArray = (key, formData, templateString) => {
    const valuesArray = [];
    Object.keys(formData).forEach((itemId) => {
        if (reservedKeywordCheck(itemId)) {
            return;
        }
        const itemData = formData[itemId];
        if (templateString) {
            valuesArray.push(
                stringTemplate(templateString, { [key]: itemData.value })
            );
        } else {
            valuesArray.push(itemData.value);
        }
    });
    return valuesArray;
};
const previewVariablesStringTemplateDirect = (
    key,
    keyValue,
    templateString
) => {
    if (templateString) {
        return stringTemplate(templateString, { [key]: keyValue });
    }
    return keyValue;
};

const parsePreviewVariablesFromFormData = (
    formData,
    contentKeys,
    staticContent
) => {
    // add all default values, will be overwritten by form values later on
    const previewVariables = {
        ...staticContent,
    };
    Object.keys(contentKeys).forEach((key) => {
        const targetKey = contentKeys[key].split(".")[0];
        const keyFormData = formData[targetKey] ? formData[targetKey] : {};
        const keyStaticContent = staticContent[key];
        let selectedId = ""; // used by radio for now
        switch (keyFormData.formItemType) {
            case FORM_FIELD_TYPES.TEXT:
            case FORM_FIELD_TYPES.PREFIX_TEXT:
            case FORM_FIELD_TYPES.COLOR:
            case FORM_FIELD_TYPES.SMALL_TEXT_AREA:
            case FORM_FIELD_TYPES.LARGE_TEXT_AREA:
            case FORM_FIELD_TYPES.IMAGE_UPLOAD:
            case FORM_FIELD_TYPES.BASE_FILE_UPLOAD:
            case FORM_FIELD_TYPES.HIDDEN_TEXT:
            case FORM_FIELD_TYPES.STRING_LABEL:
            case FORM_FIELD_TYPES.DESCRIPTION_TEXT:
                // case FORM_FIELD_TYPES.LABEL:
                previewVariables[key] = isNullOrEmpty(keyFormData[targetKey])
                    ? ""
                    : previewVariablesStringTemplateDirect(
                          key,
                          keyFormData[targetKey].value,
                          keyStaticContent
                      );
                break;
            case FORM_FIELD_TYPES.DATE:
                // TODO: change dateTime to util
                previewVariables[key] =
                    !isNullOrEmpty(keyFormData[targetKey]) &&
                    keyFormData[targetKey].value
                        ? previewVariablesStringTemplateDirect(
                              key,
                              DateTime.format(
                                  keyFormData[targetKey].value,
                                  UI_FORMATS.FULL_DATE
                              ),
                              keyStaticContent
                          )
                        : "";
                break;
            case FORM_FIELD_TYPES.RADIO:
            case FORM_FIELD_TYPES.DROPDOWN:
                selectedId = isNullOrEmpty(keyFormData)
                    ? ""
                    : getFirstIdFromSelectedFormData(keyFormData);
                if (selectedId) {
                    previewVariables[key] = staticContent[key]
                        ? staticContent[key][selectedId]
                        : keyFormData[selectedId].name;
                } else {
                    previewVariables[key] = "";
                }
                // previewVariables[key] = selectedId &&
                //   ? staticContent[key][selectedId]
                //   : "";
                break;

            case FORM_FIELD_TYPES.BULLETS:
            case FORM_FIELD_TYPES.TEXT_LIST:
            case FORM_FIELD_TYPES.CHECKLIST_DROPDOWN:
                previewVariables[key] = isNullOrEmpty(keyFormData)
                    ? ""
                    : previewVariablesStringTemplateArray(
                          key,
                          keyFormData,
                          keyStaticContent
                      );
                break;

            case FORM_FIELD_TYPES.FORM_TEMPLATE:
                previewVariables[key] = isNullOrEmpty(keyFormData)
                    ? ""
                    : previewVariablesStringTemplateArrayFromTemplate(
                          key,
                          contentKeys[key],
                          keyFormData,
                          keyStaticContent
                      );
                break;

            default:
                break;
        }
    });
    return previewVariables;
};

const clearFormDataExceptKeywords = ({ errorObject, formItemType }) => ({
    errorObject,
    formItemType,
});

const clearFormDataOfKeywords = ({
    errorObject: _,
    formItemType: __,
    ...rest
}) => rest;

const isFormItemValuePresent = (formItemData) => {
    if (isNullOrEmpty(formItemData)) {
        return false;
    }
    let validItemsCount = 0;
    Object.keys(formItemData).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (formItemData[itemKey].value) {
            validItemsCount++;
        }
    });
    return validItemsCount > 0;
};

const isFormItemVisible = (formItem, fullFormData) => {
    switch (formItem.visibilityMode) {
        case FORM_VISIBILITY_MODE.ONLY_IF_VALUE_PRESENT:
            return !!(fullFormData[formItem.id] || formItem.defaultValue);

        case FORM_VISIBILITY_MODE.DEPENDENCY_KEY:
            return !(
                formItem.visibilityDependencyKey &&
                fullFormData[formItem.visibilityDependencyKey] === false
            );

        case FORM_VISIBILITY_MODE.ALWAYS:
        default:
            return true;
    }
};

export {
    parseInitialStateFromFormFields,
    getInitialObjectForFormField,
    parseInitialFormDataForTags,
    parseInitialFormDataForCriteriaList,
    parseInitialFormDataForFormList,
    parseFormDataForApi,
    getFormValuesBasedOnType,
    parseCriteriaFormDataForApi,
    parseCombinedFormListDataForApi,
    parseFormDataForNames,
    cleanFormObject,
    isFullFormValid,
    markAllFieldsUsed,
    reservedKeywordCheck,
    getFirstIdFromSelectedFormData,
    getSelectedSubFormItemData,
    resetSubFormObjects,
    parseInitialStateFromPillsInput,
    parseFormDataToDirectArrayValues,
    getAllErrorMessageFromFormData,
    parsePreviewVariablesFromFormData,
    clearFormDataExceptKeywords,
    clearFormDataOfKeywords,
    isFormItemValuePresent,
    isFormItemVisible,
};
