import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvText } from ".";

import "../styles/ev-dropdown-menu.scss";

const DROP_DOWN_MENU_TYPE = {
    BOTTOM_LEFT: "BOTTOM_LEFT",
    BOTTOM_RIGHT: "BOTTOM_RIGHT",
};

/**
 * Dropdown menu usually used in navbars
 */

const styles = {
    container: "ev__ev-dropdown-menu__container",
    button: {
        container: "ev__ev-dropdown-menu__button-container",
        label: "ev__ev-dropdown-menu__button-label",
    },
    dropdown: {
        container: "ev__ev-dropdown-menu__dropdown-container",
        containerBottomLeft:
            "ev__ev-dropdown-menu__dropdown-container-bottom-left",
        containerBottomRight:
            "ev__ev-dropdown-menu__dropdown-container-bottom-right",
    },
    secondary: {
        button: "ev__ev-dropdown-menu__secondary-button",
    },
};

const EvDropdownMenu = (props) => {
    const {
        onClickHandler,
        label,
        buttonType,
        iconName,
        menuClassName,
        buttonClassName,
        callbackValues,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef(null);

    const onWindowMouseClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onWindowMouseClick);
        return () => {
            document.removeEventListener("mousedown", onWindowMouseClick);
        };
    }, []);

    const onElementClickMemo = useCallback(
        (elementData) => {
            setIsOpen(false);
            onClickHandler({
                ...callbackValues,
                ...elementData,
            });
        },
        [onClickHandler, setIsOpen, callbackValues]
    );

    const onDropdownButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const getDefaultButton = () => {
        const customClassName = classNames(
            styles.button.container,
            buttonClassName
        );
        return (
            <button
                type="button"
                className={customClassName}
                onClick={onDropdownButtonClick}
            >
                {label && props.type === DROP_DOWN_MENU_TYPE.BOTTOM_LEFT ? (
                    <EvText className={styles.button.label}>{label}</EvText>
                ) : null}
                {iconName && (
                    <EvIcon
                        iconName={iconName}
                        size={2}
                        innerContainerInlineStyle={{ margin: 0 }}
                    />
                )}
                {label && props.type === DROP_DOWN_MENU_TYPE.BOTTOM_RIGHT ? (
                    <EvText className={styles.button.label}>{label}</EvText>
                ) : null}
            </button>
        );
    };

    const getCustomButton = () => {
        const customClassName = classNames(
            styles.secondary.button,
            buttonClassName
        );
        const customProps = { [buttonType]: true };
        return (
            <EvButton
                {...customProps}
                className={customClassName}
                onClickHandler={onDropdownButtonClick}
            >
                {label}
            </EvButton>
        );
    };

    const getDropdownButton = () => {
        if (buttonType) {
            return getCustomButton();
        }
        // add other cases later on
        return getDefaultButton();
    };

    const getDropdownElement = (elementData) => (
        <EvDropdownMenuElement
            key={elementData.id}
            text={elementData.text}
            elementData={elementData}
            onClickHandler={onElementClickMemo}
            className={menuClassName}
        />
    );

    const getDropdownView = (dropdownData) => {
        const customClass = classNames(
            styles.dropdown.container,
            menuClassName,
            {
                [styles.dropdown.containerBottomLeft]:
                    dropdownData.type === DROP_DOWN_MENU_TYPE.BOTTOM_LEFT,
                [styles.dropdown.containerBottomRight]:
                    dropdownData.type === DROP_DOWN_MENU_TYPE.BOTTOM_RIGHT,
            }
        );
        return (
            <div className={customClass}>
                {dropdownData.data.map(getDropdownElement)}
            </div>
        );
    };

    const { data, type } = props;

    return (
        <div className={styles.container} ref={containerRef}>
            {getDropdownButton()}
            {isOpen && getDropdownView({ data, type })}
        </div>
    );
};

EvDropdownMenu.propTypes = {
    /** icon name for the dropdown, must be from svg library */
    iconName: PropTypes.string,
    /** optional label node beside the icon */
    label: PropTypes.node,
    /** dropdown list data, list of cta */
    data: PropTypes.array,
    /** type BOTTOM_LEFT, BOTTOM_RIGHT, which side the dropdown should open */
    type: PropTypes.string,
    callbackValues: PropTypes.object,

    /** common onclick handler, receives the full cta object of the element */
    onClickHandler: PropTypes.func,
    buttonType: PropTypes.string,
    buttonClassName: PropTypes.oneOfType(PropTypes.string, PropTypes.array),
    menuClassName: PropTypes.oneOfType(PropTypes.string, PropTypes.array),
};

EvDropdownMenu.defaultProps = {
    iconName: "ARROW_ANGLE_DOWN",
    label: null,
    data: [],
    type: DROP_DOWN_MENU_TYPE.BOTTOM_LEFT,
    callbackValues: {},

    onClickHandler: () => {},
    buttonType: "",
    buttonClassName: "",
    menuClassName: "",
};

const EvDropdownMenuElement = (props) => {
    const styles = {
        container: "ev__ev-dropdown-menu-element__container",
        text: "ev__ev-dropdown-menu-element__text",
    };

    const onElementClick = () => {
        props.onClickHandler(props.elementData);
    };

    return (
        <button
            type="button"
            className={styles.container}
            onClick={onElementClick}
        >
            <EvText default className={styles.text}>
                {props.text}
            </EvText>
        </button>
    );
};

EvDropdownMenuElement.propTypes = {
    text: PropTypes.string,
    elementData: PropTypes.object,

    onClickHandler: PropTypes.func,
};

EvDropdownMenuElement.defaultProps = {
    text: "",
    elementData: [],

    onClickHandler: () => {},
};

export default memo(EvDropdownMenu);
export { DROP_DOWN_MENU_TYPE };
