import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";

import VendorProgramsActions from "../../redux/VendorProgramsActions";
import {
    VENDOR_PROGRAM_COMPONENTS,
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAMS_TEMPLATES,
} from "../../statics/VendorProgramsStatics";
import API_URLS from "../../../../services/apiUrls";
import {
    getData,
    postData,
    putData,
} from "../../service/VendorProgramsService";
import { normalizerWithOrderArraySingleLevel } from "../../../../utils/Normalizer";
import NewVendorProgramSelection from "./NewVendorProgramSelection";
import NewVendorProgramReview from "./NewVendorProgramReview";
import {
    isCompleted,
    isNullOrEmpty,
    removeKeyFromObject,
} from "../../../../utils/CommonUtils";
import {
    EvFullScreenOverlay,
    EvLoadingPage,
    EvToast,
} from "../../../../common/components";
import { LOADING_STATUS } from "../../../../common/static/Enums";
import EvLogger from "../../../../utils/EvLogger";
import {
    parsePreselectedVendorDataForStore,
    parseVendorSelectionDataForApi,
} from "../../../../utils/SpecificDataModelUtils";
import CacheActions from "../../../../reducers/cache/CacheActions";
import { fuzzySearchInNormalizedArray } from "../../../../utils/FuzzySearch";

const NewVendorProgramContainer = (props) => {
    const {
        clientCode,
        clientName,

        // static data
        selectionStaticData,
        reviewStaticData,

        // store
        controls,
        vendorData,
        clientVendorData,
        programsData,

        // actions
        setControls,
        setDynamicDataApiStatus,
        getStaticData,
        setComponentData,
        navigateToPage,
        goBackAction,
        clearCacheByURL,
    } = props;

    const [selectedVendorPrograms, setSelectedVendorPrograms] = useState({});
    const [selectedPageId, setSelectedPageId] = useState(
        VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM_SELECTION.id
    );
    const [filteredVendorData, setFilteredVendorData] = useState({});

    useEffect(() => {
        getStaticData({
            templateId:
                VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_SELECTION.id,
            url: API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.SELECTION_STATIC,
        });
        getStaticData({
            templateId: VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_REVIEW.id,
            url: API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.REVIEW_STATIC,
        });
    }, [getStaticData]);

    useEffect(() => {
        setDynamicDataApiStatus({
            [VENDOR_PROGRAM_COMPONENTS.VENDORS.id]: LOADING_STATUS.LOADING,
        });
        Promise.all([
            getData(API_URLS.VENDOR_PROGRAMS.GET_VENDORS, { clientCode }),
            getData(API_URLS.VENDOR_PROGRAMS.GET_PROGRAMS, { clientCode }),
            getData(API_URLS.VENDOR_PROGRAMS.CURRENT_VENDORS_DATA, {
                clientCode,
            }),
        ])
            .then(
                ([
                    vendorsResponse,
                    programsResponse,
                    currentVendorsResponse,
                ]) => {
                    const vendorDataNorimalized = normalizerWithOrderArraySingleLevel(
                        vendorsResponse.data.data.defaultVendors
                    );
                    // forcefully change it to array, BE assumed only one vendor, but not us
                    // const clientVendorData = vendorsResponse.data.data.clientVendor;

                    const programsDataNormalized = normalizerWithOrderArraySingleLevel(
                        programsResponse.data.data.programs
                    );

                    const selectedVendors = parsePreselectedVendorDataForStore(
                        currentVendorsResponse.data.data.preSelected
                    );

                    setComponentData({
                        componentId: VENDOR_PROGRAM_COMPONENTS.VENDORS.id,
                        data: vendorDataNorimalized,
                    });
                    setFilteredVendorData(vendorDataNorimalized);
                    setComponentData({
                        componentId:
                            VENDOR_PROGRAM_COMPONENTS.CLIENT_VENDORS.id,
                        data: vendorsResponse.data.data.clientVendor,
                    });
                    setComponentData({
                        componentId: VENDOR_PROGRAM_COMPONENTS.PROGRAMS.id,
                        data: programsDataNormalized,
                    });

                    EvLogger.log(
                        `VendorProgram: User landed on add new vendor page with vendorPrograms: ${JSON.stringify(
                            selectedVendors
                        )}`
                    );
                    setSelectedVendorPrograms(selectedVendors);

                    setDynamicDataApiStatus({
                        [VENDOR_PROGRAM_COMPONENTS.VENDORS.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                }
            )
            .catch((e) => {
                setDynamicDataApiStatus({
                    [VENDOR_PROGRAM_COMPONENTS.VENDORS.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(
                    e,
                    `NewVendorProgramContainer initialLoad ${clientCode}`
                );
            });
    }, [clientCode, setComponentData, setDynamicDataApiStatus]);

    const onSearchKeyChange = useCallback(
        (searchKey) => {
            try {
                if (searchKey) {
                    setFilteredVendorData(
                        fuzzySearchInNormalizedArray(
                            vendorData.value,
                            searchKey,
                            ["name"]
                        )
                    );
                    EvLogger.log(
                        `NewVendorProgramContainer: Searched with key: ${searchKey}`
                    );
                } else {
                    setFilteredVendorData(vendorData);
                }
            } catch (e) {
                EvLogger.errorWithObject(
                    e,
                    `NewVendorProgramContainer onSearch by key: ${searchKey}`
                );
            }
        },
        [vendorData]
    );

    const onSelectedVendorProgramsChange = useCallback(
        (vendorId, newSelectedPrograms) => {
            EvLogger.log(
                `VendorProgram: User changed programs on vendorId:${vendorId}, with programs: ${JSON.stringify(
                    newSelectedPrograms
                )}`
            );
            let newSelectedVendorProgramsData = {
                ...selectedVendorPrograms,
                [vendorId]: newSelectedPrograms,
            };
            if (Object.keys(newSelectedPrograms).length <= 0) {
                newSelectedVendorProgramsData = removeKeyFromObject(
                    vendorId,
                    newSelectedVendorProgramsData
                );
            }
            setSelectedVendorPrograms(newSelectedVendorProgramsData);
        },
        [selectedVendorPrograms]
    );

    const onClientProgramAdd = useCallback(
        (programItemData) => {
            EvLogger.log(
                `VendorProgram: User added new client program: ${programItemData.id}`
            );
            const newProgramsList = [
                ...(clientVendorData.programs || []),
                programItemData.id,
            ];
            EvFullScreenOverlay.setData("", "Adding client vendors", {
                showLoading: true,
            });
            EvFullScreenOverlay.show();

            postData(
                API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM
                    .ADD_CLIENT_VENDOR_PROGRAM,
                {
                    clientCode,
                },
                newProgramsList
            )
                .then((addProgramsResponse) => {
                    if (
                        isNullOrEmpty(
                            addProgramsResponse.data.data.clientVendor
                        )
                    ) {
                        throw new Error(
                            `Add client programs responded without client vendors, response ${addProgramsResponse.data}`
                        );
                    }
                    setComponentData({
                        componentId:
                            VENDOR_PROGRAM_COMPONENTS.CLIENT_VENDORS.id,
                        data: addProgramsResponse.data.data.clientVendor,
                    });
                    EvFullScreenOverlay.hide();
                    EvToast.success("Added", "Programs added to client");
                })
                .catch((e) => {
                    EvFullScreenOverlay.hide();
                    EvToast.error("Sorry", "Failed to add programs to client");
                    EvLogger.errorWithObject(
                        e,
                        `NewVendorProgramContainer onClientProgramAdd ${clientCode}`
                    );
                });
        },
        [clientCode, clientVendorData.programs, setComponentData]
    );

    const goToInternalPage = useCallback(
        (pageId) => {
            setFilteredVendorData(vendorData);
            setSelectedPageId(pageId);
        },
        [vendorData]
    );

    const submitSelectedPrograms = useCallback(() => {
        EvLogger.log(
            `VendorProgram: User submitted new programs: ${JSON.stringify(
                selectedVendorPrograms
            )}`
        );
        const apiData = parseVendorSelectionDataForApi(selectedVendorPrograms);
        EvFullScreenOverlay.setData("", "Saving vendors programs", {
            showLoading: true,
        });
        EvFullScreenOverlay.show();
        putData(
            API_URLS.VENDOR_PROGRAMS.NEW_VENDOR_PROGRAM.SAVE_VENDOR_PROGRAM,
            {
                clientCode,
            },
            {
                clientCode, // remove in future
                newClientPrograms: apiData,
            }
        )
            .then((saveResponse) => {
                EvToast.success("Saved", "Programs added to vendors");
                setControls({
                    newVendorProgramSaved: true,
                });
                EvFullScreenOverlay.hide();
                navigateToPage({
                    path: VENDOR_PROGRAMS_PAGES.DASHBOARD.path,
                    state: {
                        clientCode,
                        clientName,
                    },
                });
                clearCacheByURL({
                    url:
                        API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT
                            .LIST_DATA,
                });
            })
            .catch((e) => {
                EvFullScreenOverlay.hide();
                EvToast.error("Sorry", "Failed to save programs");
                EvLogger.errorWithObject(
                    e,
                    `NewVendorProgramContainer submitSelectedPrograms ${clientCode}`
                );
            });
    }, [
        clientCode,
        navigateToPage,
        selectedVendorPrograms,
        setControls,
        clearCacheByURL,
        clientName,
    ]);

    const getContent = () => {
        switch (selectedPageId) {
            case VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM_REVIEW.id:
                return (
                    <NewVendorProgramReview
                        clientCode={clientCode}
                        clientName={clientName}
                        staticData={reviewStaticData}
                        selectedVendorPrograms={selectedVendorPrograms}
                        vendorData={filteredVendorData}
                        clientVendorData={clientVendorData}
                        goToInternalPage={goToInternalPage}
                        submitSelectedPrograms={submitSelectedPrograms}
                    />
                );

            case VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM_SELECTION.id:
            default:
                return (
                    <NewVendorProgramSelection
                        clientCode={clientCode}
                        clientName={clientName}
                        staticData={selectionStaticData}
                        allVendorData={vendorData}
                        filteredVendorData={filteredVendorData}
                        clientVendorData={clientVendorData}
                        programsData={programsData}
                        selectedVendorPrograms={selectedVendorPrograms}
                        onSelectedVendorProgramsChange={
                            onSelectedVendorProgramsChange
                        }
                        onClientProgramAdd={onClientProgramAdd}
                        goToInternalPage={goToInternalPage}
                        goBackAction={goBackAction}
                        onSearchKeyChange={onSearchKeyChange}
                    />
                );
        }
    };

    if (
        !isCompleted(
            controls.dynamicDataApiStatus[VENDOR_PROGRAM_COMPONENTS.VENDORS.id],
            controls.staticDataApiStatus[
                VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_SELECTION.id
            ],
            controls.staticDataApiStatus[
                VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_REVIEW.id
            ]
        )
    ) {
        return <EvLoadingPage animatedFadeIn />;
    }

    return getContent();
};

NewVendorProgramContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,

    controls: PropTypes.object,
    selectionStaticData: PropTypes.object,
    reviewStaticData: PropTypes.object,
    vendorData: PropTypes.object,
    clientVendorData: PropTypes.object,
    programsData: PropTypes.object,

    setControls: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
    navigateToPage: PropTypes.func,
    goBackAction: PropTypes.func,
    clearCacheByURL: PropTypes.func,
};

NewVendorProgramContainer.defaultProps = {
    clientCode: "",
    clientName: "",

    controls: {},
    selectionStaticData: {},
    reviewStaticData: {},
    vendorData: {},
    clientVendorData: {},
    programsData: {},

    setControls: () => {},
    setDynamicDataApiStatus: () => {},
    getStaticData: () => {},
    setComponentData: () => {},
    navigateToPage: () => {},
    goBackAction: () => {},
    clearCacheByURL: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    selectionStaticData:
        state.VendorProgramsReducer.staticData[
            VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_SELECTION.id
        ],
    reviewStaticData:
        state.VendorProgramsReducer.staticData[
            VENDOR_PROGRAMS_TEMPLATES.NEW_VENDOR_PROGRAM_REVIEW.id
        ],
    vendorData:
        state.VendorProgramsReducer.dynamicData[
            VENDOR_PROGRAM_COMPONENTS.VENDORS.id
        ],
    clientVendorData:
        state.VendorProgramsReducer.dynamicData[
            VENDOR_PROGRAM_COMPONENTS.CLIENT_VENDORS.id
        ],
    programsData:
        state.VendorProgramsReducer.dynamicData[
            VENDOR_PROGRAM_COMPONENTS.PROGRAMS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(VendorProgramsActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(VendorProgramsActions.setDynamicDataApiStatus(payload)),
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    setComponentData: (payload) =>
        dispatch(VendorProgramsActions.setComponentData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    goBackAction: () => dispatch(goBack()),
    clearCacheByURL: (payload) =>
        dispatch(CacheActions.clearCacheByURL(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewVendorProgramContainer);
