import PropTypes from "prop-types";
import React from "react";
import stringTemplate from "string-template";
import "../../styles/content-items/ev-content-pills-array.scss";
import { EvText } from "../index";

const styles = {
    container: "ev__ev-content-pills-array__container",
    rowContainer: "ev__ev-content-pills-array__row-container",
    pills: "ev__ev-content-pills-array__pills",
    pillsContainer: "ev__ev-content-pills-array__pills-container",
};

const EvContentPillsArray = (props) => {
    const { label, displayResolver, allValues, dependency } = props;

    const getPillItem = (itemData) => (
        <div key={itemData.id} className={styles.pills}>
            <EvText>{stringTemplate(displayResolver, itemData)}</EvText>
        </div>
    );

    const getItemRow = (rowData, i) => (
        <div key={i} className={styles.rowContainer}>
            <EvText>{stringTemplate(label, { count: i + 1 })}</EvText>
            <div className={styles.pillsContainer}>
                {rowData.map(getPillItem)}
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {allValues[dependency].map(getItemRow)}
        </div>
    );
};

EvContentPillsArray.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentPillsArray.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
};

export default EvContentPillsArray;
