const API_URLS = {
    LOGGER_SERVICE: "/cc/api/uiLog",

    APP_USER_DATA: "/cc/api/profile",
    APP_USER_STATIC_DATA:
        "/cc/api/static/content?templateId=static_user_content",

    IMAGE_UPLOADER: { UPLOAD_IMAGE: "/cc/api/upload-image" },

    // TODO REMOVE LATER, replaced by data uploader
    FILE_UPLOADER: {
        GET_FORM_TYPES: "",
        GET_FILE_UPLOAD_FORM_FIELDS: "/cc/api/static/content",
        GET_PREFILLED_FORM_VALUES: "/cc/api/static/loader",
        POST_CURRENT_FORM_DATA: "/cc/api/static/loader",
    },

    DATA_UPLOADER: {
        GET_FORM_TYPES:
            "/cc/api/static/content/json?templateId=data_loader&fileName=data_loader",
        GET_FORM_OF_TYPES: "/cc/api/static/content/json",
        // FORM_TYPES
        // GET_FORM_FIELDS_FILE_UPLOAD:
        //   "/static/data-upload?templateId=file-upload",
        // GET_FORM_FIELDS_RESOURCE_CARD:
        //   "/static/data-upload?templateId=resource-card",
        // GET_FORM_FIELDS_CURRENT_STATUS:
        //   "/static/data-upload?templateId=current-status",
        // GET_FORM_FIELDS_WHERE_TO_GO_FOR_CARE:
        //   "/static/data-upload?templateId=wtgfc",
        // GET_FORM_FIELDS_EVIVE_360: "/static/data-upload?templateId=evive-360",

        // prefills POST
        GET_PREFILLED_FORM_VALUES__FILE_UPLOADER: "/cc/api/get-prefil-data",
        GET_PREFILLED_FORM_VALUES__CARD: "/cc/api/prefill-form",
        // submit
        POST_FORM_DATA__FILE_UPLOAD: "/cc/api/file/upload",
        POST_FORM_DATA__RESOURCE_CARD: "/cc/api/save-form",
    },

    DASHBOARD: {
        GET_CLIENT_LIST_DATA: "/cc/api/all-clients",
        GET_CLIENT_LIST_STATIC_DATA:
            "/cc/api/static/content/json?templateId=all_clients_dashboard&fileName=all_clients_dashboard",
    },

    USERS: {
        LIST_STATIC:
            "/cc/api/static/content/json?templateId=user_dashboard&fileName=user_dashboard",
        USERS_LIST: "/cc/api/users/dashboard",
        EDIT_USER_STATIC_DATA:
            "/cc/api/static/content/json?templateId=add_edit_user&fileName=user_details",
        GET_USER_PREFILL_DATA: "/cc/api/users/details",
        SAVE_USER_DATA: "/cc/api/users/details",
    },

    CLIENT_DASHBOARD: {
        STATIC:
            "/cc/api/static/content/json?templateId=client_benefit_dashboard&fileName=client_benefit_dashboard",
        // CLIENT_BENEFITS_STATIC:
        //   "/cc/api/static/content/json?templateId=client_benefit_programs&fileName=client_benefit_dashboard",
        // CLIENT_BENEFITS_LIST: "/cc/api/all-client-programs", // same as manage programs
        // CLIENT_BENEFITS_SUMMARY_STATIC:
        //   "/cc/api/static/content/json?templateId=client_benefit_summary&fileName=client_benefit_summary",
        // CLIENT_BENEFITS_SUMMARY_DATA: "/cc/api/client-benefit-summary",
        ACTIVITY_CARD_DATA: "/cc/api/pre-prod",
        VENDOR_CARD_DATA: "/cc/api/all-client-programs",
    },

    CLIENT_DETAILS: {
        GET_CLIENT_DETAILS_STATIC_DATA:
            "/cc/api/static/content/json?templateId=client_profile&fileName=client_profile",
        GET_CLIENT_DETAILS_DATA: "/cc/api/by-client-code",
        GET_NAVIGATION_DATA:
            "/cc/api/static/content?templateId=static_menu_options",
        PUT_CLIENT_DETAILS: "/cc/api/add-client",
    },

    VENDOR_PROGRAMS: {
        SIDEBAR_STATICS:
            "/cc/api/static/content?templateId=manage_programs_sidebar",
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=vendor_program_card_static_content&fileName=vendor_program_card_content",
        },
        NEW_VENDOR_PROGRAM: {
            SELECTION_STATIC:
                "/cc/api/static/content/json?templateId=manage_program_selection_page&fileName=manage_program_selection_page",
            REVIEW_STATIC:
                "/cc/api/static/content/json?templateId=manage_program_review_page&fileName=manage_program_review_page",
            VENDOR_DETAILS: "/cc/api/vendor-contact-details",
            SAVE_VENDOR_PROGRAM: "/cc/api/update-client-programs",
            ADD_CLIENT_VENDOR_PROGRAM: "/cc/api/add-vendor-program",
        },
        GET_PROGRAMS: "/cc/api/all-programs",
        GET_VENDORS: "/cc/api/all-vendors",
        // GET_CATEGORIES: "/cc/api/all-categories",
        CURRENT_VENDORS_DATA: "/cc/api/all-client-programs", // used in client benefit dashboard also
        CUSTOMIZATION: {
            STATIC:
                "/cc/api/static/content?templateId=static_manage_programs_sidebar",
        },
        REMOVE_PROGRAM: "/cc/api/client-program",
        PROGRAM_TAGS: {
            STATIC:
                "/cc/api/static/content?templateId=static_data_program_eligibility",
            SELECTION_MODAL_STATIC:
                "/cc/api/static/content?templateId=static_content_cc_tag_selection",
            SELECTED_TAGS: "/cc/api/feature-rule",
            ALL_TAGS: "/cc/api/all-tags",
            SUBMIT_TAGS: "/cc/api/feature-rule",
        },
        SUMMARY: {
            PAGE_STATIC:
                "/cc/api/static/content/json?templateId=client_benefit_summary&fileName=client_benefit_summary",
            SUMMARY_DATA: "/cc/api/client-benefit-summary",
        },
    },

    CARDS_DASHBOARD: {
        STATIC:
            "/cc/api/static/content/json?templateId=cards_dashboard&fileName=cards_dashboard",
        STATIC_CLIENT_PROGRAM_CARDS:
            "/cc/api/static/content/json?templateId=client_program_cards_dashboard&fileName=cards_dashboard",
        CARD_LIST: "/cc/api/cards-dashboard",
    },

    CLIENT_CARDS: {
        CARD_TYPES:
            "/cc/api/static/content/json?templateId=client_card_types&fileName=client_cards_dashboard",
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=client_cards_dashboard&fileName=client_cards_dashboard",
            CARD_LIST: "/cc/api/all-client-cards",
            VENDOR_PROGRAM_LIST: "/cc/api/all-client-programs",
        },
        CATEGORIES: {
            SECTIONS: "/cc/api/all-sections",
            STATIC:
                "/cc/api/static/content/json?templateId=client_card_categories&fileName=client_cards_dashboard",
        },
        REORDER: {
            STATICS:
                "/cc/api/static/content/json?templateId=client_cards_reorder&fileName=client_cards_dashboard",
            SAVE_CARD_ORDER: "/cc/api/card-order",
        },
    },

    RESOURCE_CARD: {
        STATICS:
            "/cc/api/static/content/json?templateId=resource_card&fileName=resource_card",
        STATICS_CLIENT_PROGRAM:
            "/cc/api/static/content/json?templateId=resource_card_client-program&fileName=resource_card",
        PREFILL_DATA: "/cc/api/resource-card",
        SUBMIT: "/cc/api/resource-card",
    },

    CURRENT_STATUS: {
        STATIC:
            "/cc/api/static/content/json?templateId=current_status_card&fileName=current_status_card",
        STATIC_CLIENT_PROGRAM:
            "/cc/api/static/content/json?templateId=current_status_client_program&fileName=current_status_card",
        PREFILL_DATA: "/cc/api/current-status-card",
        SUBMIT: "/cc/api/current-status-card",
    },

    WHERE_TO: {
        STATIC:
            "/cc/api/static/content/json?templateId=wtgfc_card&fileName=wtgfc_card",
        PREFILL_DATA: "/cc/api/wtgfc-card",
        SUBMIT: "/cc/api/wtgfc-card",
    },

    EVIVE_360: {
        STATIC:
            "/cc/api/static/content/json?templateId=evive360_static_content&fileName=evive360_card",
        STATIC_CLIENT_PROGRAM:
            "/cc/api/static/content/json?templateId=evive_360_client_program&fileName=evive360_card",
        PREFILL_DATA: "/cc/api/benefit-card",
        SUBMIT: "/cc/api/benefit-card",
    },

    // VENDOR_PROGRAM_CONTENT: {
    //   STATIC:
    //     "/cc/api/static/content/json?templateId=callouts_content&fileName=callouts_content",
    //   PREFILL_DATA: "/cc/api/callout-content",
    //   SUBMIT: "/cc/api/callout-content",
    // },

    CALLOUTS: {
        // STATIC_DATA:
        //   "/cc/api/static/content/json?templateId=callouts_static_page&fileName=callouts_static_page",
        // // SUBMIT: "/cc/api/callouts",
        // ALL_CALLOUTS: "/cc/api/all-callouts",
        // CURRENT_CALLOUTS_DATA: "/cc/api/callouts",

        LIST_VIEW_STATIC:
            "/cc/api/static/content/json?templateId=callout_dashboard&fileName=callouts_static_page",
        CONTENT_STATIC:
            "/cc/api/static/content/json?templateId=callouts_content&fileName=callouts_content",
        CALLOUTS_LIST: "/cc/api/callout-dashboard",
        CALLOUTS_DATA: "/cc/api/callout-details",
        CTA_DATA_LIST: "/cc/api/cta-types-values",
        SUBMIT: "/cc/api/callout-details",
    },

    CLIENT_CONTENT: {
        STATIC_DATA:
            "/cc/api/static/content?templateId=static_client_content_sidebar",
    },

    EMPLOYER_CARD: {
        STATIC:
            "/cc/api/static/content/json?templateId=employer_card&fileName=employer_card",
        PREFILL: "/cc/api/employer-card",
        SUBMIT: "/cc/api/employer-card",
    },

    // USER_MAPPING: {
    //   GET_MAIN_PAGE_STATIC_DATA:
    //     "/cc/api/static/content?templateId=static_user_mapping_sidebar",
    //   GET_SIDEBAR_COUNT_DATA: "/cc/api/count-tags",
    //   GET_UNLINKED_PAGE_STATIC_DATA:
    //     "/cc/api/static/content?templateId=static_unlinked_tags_content",
    //   GET_LINKED_PAGE_STATIC_DATA:
    //     "/cc/api/static/content?templateId=static_linked_tags_content",
    //   GET_ALL_TAGS: "/cc/api/all-tags-data",
    //   GET_UNLINKED_TAGS: "/cc/api/unlinked-tags",
    //   GET_LINKED_TAGS: "/cc/api/linked-tags",
    //   GET_TAG_ITEM_STATIC_DATA:
    //     "/cc/api/static/content/json?templateId=user_mapping_tag_rule_content&fileName=user_mapping",
    //   // GET_DATA_FIELD_MODAL_DATA: "/cc/api/mapped-field",
    //   POST_TAG_RULE: "/cc/api/user-mapping",
    //   DELETE_TAG_RULE: "/cc/api/tag-rule",
    // },

    LINKED_UNLINKED_TAGS: {
        GET_ALL_TAGS: "/cc/api/all-tags-data",
        GET_UNLINKED_TAGS: "/cc/api/unlinked-tags",
        GET_LINKED_TAGS: "/cc/api/linked-tags",
        POST_TAG_RULE: "/cc/api/user-mapping",
        DELETE_TAG_RULE: "/cc/api/tag-rule",
        STATIC:
            "/cc/api/static/content/json?templateId=tag_definition&fileName=tag_definition",
    },
    ENTERPRISE_NOTIFICATIONS: {
        CLIENT_SELECTION: {
            CLIENTS_DATA: "/cc/api/enterprise-notification/all-clients",
            NOTIFICATIONS_DATA:
                "/cc/api/enterprise-notification/all-notifications",
        },
        PROGRAM_SELECTION: {
            PRE_FILLED_DATA: "/cc/api/enterprise-notification/linked-program",
            PROGRAM_LIST_DATA: "/cc/api/enterprise-notification/programs",
            POST_DATA: "/cc/api/enterprise-notification/linked-program",
        },
        CONTENT: {
            PRE_FILLED_DATA:
                "/cc/api/enterprise-notification/notification-details",
            CTA_DATA: "/cc/api/enterprise-notification/cta-pid-mapping",
            POST_DATA: "/cc/api/enterprise-notification/notification-details",
        },
        SUMMARY: {
            SUMMARY_DATA: "/cc/api/enterprise-notification/summary",
            NOTIFICATION_USER_COUNT:
                "/cc/api/enterprise-notification/count-for-notification",
            POST_PUBLISH_NOTIFICATION:
                "/cc/api/enterprise-notification/publish-notification",
        },
    },
    NOTIFICATIONS: {
        PROGRAM_SELECTION: {
            STATIC:
                "/cc/api/static/content/json?templateId=notification_linked_program&fileName=notification_linked_program",
            PRE_FILLED_DATA: "/cc/api/notification/notification-linked-program",
            POST_DATA: "/cc/api/notification/notification-linked-program",
        },
        CONTENT: {
            STATIC:
                "/cc/api/static/content/json?templateId=notification_static_content&fileName=notification_static_content",
            PRE_FILLED_DATA: "/cc/api/notification/notification-card",
            LAZY_MODAL_DATA: "/cc/api/notification/cta-pid-mapping",
            POST_DATA: "/cc/api/notification/notification-card",
        },
        NEW_POPULATIONS: {
            STATIC:
                "/cc/api/static/content/json?templateId=static_target_population&fileName=notification_static_target_population",
            TEMPLATE_CRITERIA_FORM_DATA:
                "/cc/api/static/content/json?templateId=static_rule_form_data&fileName=notification_static_target_population",
            GET_POPULATION_DETAILS:
                "/cc/api/notification/get-saved-target-population",
            SAVE_POPULATION_DETAILS:
                "/cc/api/notification/save-target-population",
            TAG_CATEGORIES: "/cc/api/category-mapping",
        },
        TARGET_POPULATION: {
            STATIC:
                "/cc/api/static/content/json?templateId=dashboard_target_population&fileName=notification_target_population",
            SAVED_POPULATION_DATA:
                "/cc/api/population/get-saved-population-list",
        },
        UPLOAD_POPULATION: {
            STATIC:
                "/cc/api/static/content/json?templateId=upin_audience_selection&fileName=notification_target_population",
            UPLOADED_POPULATION_DATA: "/cc/api/notification/upin-population",
            SUBMIT_POPULATION: "/cc/api/notification/upin-population",
        },
        TAG_SELECTION: {
            STATIC:
                "/cc/api/static/content/json?templateId=tag_selection_static_data&fileName=notifications_tag_selection",
            ALL_TAGS: "/cc/api/notification/tags",
        },
        SCHEDULE: {
            STATIC:
                "/cc/api/static/content/json?templateId=schedule_notification_static_data&fileName=schedule_notification_page",
            PRE_FILLED_DATA: "/cc/api/notification/schedule-notification",
            POST_DATA: "/cc/api/notification/schedule-notification",
        },
        SUMMARY: {
            STATIC:
                "/cc/api/static/content/json?templateId=notification_summary_static_data&fileName=notification_summary_static_page",
            DYNAMIC: "/cc/api/notification/summary",
            POST_SCHEDULE: "/cc/api/notification/schedule-summary",
        },
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=notification_dashboard_page&fileName=notification_dashboard_page",
            NOTIFICATIONS_DATA: "/cc/api/notification/dashboard",
        },
    },

    SAVED_POPULATION: {
        ALL_TAGS: "/cc/api/notification/tags",
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=saved_population_dashboard_data&fileName=saved_population",
            POPULATION_LIST: "/cc/api/population/get-saved-population-list",
        },
        NEW_EDIT_POPULATION: {
            STATIC:
                "/cc/api/static/content/json?templateId=new_edit_population_data&fileName=saved_population",
            POPULATION_DATA: "/cc/api/population/details",
            CRITERIA_RULE_FORM_DATA:
                "/cc/api/static/content/json?templateId=criteria_rule_form_data&fileName=saved_population",
            SAVE_POPULATION: "/cc/api/population/details",
            DELETE_POPULATION: "/cc/api/population/details",
        },
        TAG_SELECTION: {
            STATIC:
                "/cc/api/static/content/json?templateId=tag_selection_data&fileName=saved_population",
            TAG_CATEGORIES: "/cc/api/category-mapping",
        },
    },

    PRE_PROD: {
        STATIC_DATA:
            "/cc/api/static/content/json?templateId=approval_page&fileName=approval_page",
        CARD_LIST: "/cc/api/pre-prod",
        APPROVED_CARD_LIST: "/cc/api/recently-published",
        APPROVE_CARD: "/cc/api/approve",
        APPROVE_ALL: "/cc/api/approve-all",
    },

    COMMON: {
        LAZY_MODAL_SELECTION_USER_MAPPING_STATIC_DATA:
            "/cc/api/static/content?templateId=cc_static_data_field_selection", // **
        LAZY_MODAL_SELECTION_USER_MAPPING_LIST_DATA: "/cc/api/mapped-field", // **
        LAZY_MODAL_NOTIFICATION_CONTENT: {
            STATIC:
                "/cc/api/static/content/json?templateId=notification_lazy_modal_static_data&fileName=notification_static_content", // **
            LIST_DATA: "/cc/api/client-programs", // **
        },
        GET_LAZY_DROPDOWN_LIST_DATA: "/cc/api/eligibility-providers", // **
        CONTENT_LAZY_TEXT: "/cc/api/GET_POPULATION_COUNT", // **
        GET_LAZY_CLIENTS_FOR_USERS: "/cc/api/GET_LAZY_CLIENTS_FOR_USERS", // **

        CRITERIA_BUILDER: {
            GET_REF_ID: "/cc/api/audience",
            GET_VALUE: "/cc/api/audience", // POST CALL
        },
    },

    GAME_DAY_CONTENT: {
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=gameday_content_dashboard&fileName=gameday_configuration",
            GAMES_LIST: "/cc/api/game-day/games",
        },
        GAME: {
            STATIC: {
                MEMORY:
                    "/cc/api/static/content/json?templateId=memory_game_content&fileName=gameday_memory_game",
                PICK_N_WIN:
                    "/cc/api/static/content/json?templateId=pick_n_win_content&fileName=gameday_pick_n_win_game",
                LOST_LETTERS:
                    "/cc/api/static/content/json?templateId=lost_letters_content&fileName=gameday_lost_letters_game",
            },
            PREFILL: {
                MEMORY: "/cc/api/game-day/memory-game",
                PICK_N_WIN: "/cc/api/game-day/pick-n-win-game",
                LOST_LETTERS: "/cc/api/game-day/lost-letters-game",
            },
            SUBMIT: {
                MEMORY: "/cc/api/game-day/memory-game",
                PICK_N_WIN: "/cc/api/game-day/pick-n-win-game",
                LOST_LETTERS: "/cc/api/game-day/lost-letters-game",
            },
        },
        CONFIGURE: {
            STATIC:
                "/cc/api/static/content/json?templateId=game_settings&fileName=gameday_configuration",
            PREFILL: "/cc/api/game-day/game-settings",
            SUBMIT: "/cc/api/game-day/game-settings",
        },
    },

    GAME_DAY_SCHEDULE: {
        DASHBOARD: {
            STATIC:
                "/cc/api/static/content/json?templateId=gameday_schedule_dashboard&fileName=gameday_schedule_dashboard",
            SCHEDULED_GAMES: "/cc/api/game-day/dashboard",
        },
        GAME_SELECTION: {
            STATIC:
                "/cc/api/static/content/json?templateId=client_gameday_cards&fileName=client_gameday_cards",
            GAME_LIST: "/cc/api/game-day/client-game-cards",
        },
        CONTENT: {
            STATIC:
                "/cc/api/static/content/json?templateId=gameday_content_review&fileName=gameday_content_review",
            PREFILL: "/cc/api/game-day/game-content-review",
        },
        TIME: {
            STATIC:
                "/cc/api/static/content/json?templateId=schedule_gameday_static_data&fileName=schedule_gameday",
            PREFILL: "/cc/api/game-day/schedule-game",
            SUBMIT: "/cc/api/game-day/schedule-game",
        },
        SUMMARY: {
            STATIC:
                "/cc/api/static/content/json?templateId=gameday_summary&fileName=gameday_summary",
            PREFILL: "/cc/api/game-day/summary",
            SUBMIT: "/cc/api/game-day/summary",
        },
    },
};

export default API_URLS;
