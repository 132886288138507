import React, { useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import "../../styles/form-components/ev-csv-input.scss";
import { EvText } from "../index";
import FormValidation from "../../../utils/FormValidations";
import { KEY_CODES } from "../../static/Enums";

const styles = {
    container: "ev__ev-csv-input__container",
    placeholder: "ev__ev-csv-input__placeholder",
    pills: {
        container: "ev__ev-csv-input__pills-container",
        item: "ev__ev-csv-input__pills-item",
    },
    input: {
        container: "ev__ev-csv-input__input-container",
        box: "ev__ev-csv-input__input-box",
    },
};

const cleanInputValue = (val, extraVal = ",") => {
    let newInputValueString = "";
    let newInputValueArray = val.split(",");
    newInputValueArray = newInputValueArray.filter((el) => !!el);
    if (newInputValueArray.length > 0) {
        // if array is not empty then add , at the end
        newInputValueString = `${newInputValueArray.join()}${extraVal}`;
    } else {
        // else dont add comma, empty array
        newInputValueString = `${newInputValueArray.join()}`;
    }
    return newInputValueString;
};

const EvCsvInput = (props) => {
    const {
        keyName,
        placeholder,
        formData,
        formItem,
        handleInputChange,
    } = props;

    const inputBoxRef = useRef(null);
    const inputValue =
        formData[keyName] && formData[keyName].value
            ? formData[keyName].value
            : "";
    const valuesArray = useMemo(() => inputValue.split(","), [inputValue]);

    const onChange = useCallback(
        (value) => {
            const inputFieldObject = {
                [keyName]: {
                    key: keyName,
                    value,
                    name: value,
                    type: formItem.type,
                },
                errorObject: {}, // will be overwritten later
                formItemType: formItem.type,
            };

            handleInputChange(keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [handleInputChange, formData.errorObject, formItem, keyName]
    );

    const onInputChange = useCallback(() => {}, []);

    const onKeyDown = useCallback(
        (e) => {
            // do not remove console, required frequently to add new characters
            // console.log("KEY CODE", e.keyCode);
            switch (e.keyCode) {
                // delete one character
                case KEY_CODES.BACKSPACE:
                    onChange(inputValue.substr(0, inputValue.length - 1));
                    break;

                // add comma cases
                case KEY_CODES.COMMA:
                case KEY_CODES.ENTER:
                case KEY_CODES.ESC:
                    onChange(cleanInputValue(inputValue)); // it adds comma in the cleanup
                    break;

                // ignore cases
                case KEY_CODES.SHIFT:
                case KEY_CODES.LEFT_ARROW:
                case KEY_CODES.RIGHT_ARROW:
                    break;

                // special characters to be added
                case KEY_CODES.SPACE:
                    onChange(inputValue + e.key);
                    break;

                // alphabets and everything else
                default:
                    // alphabets
                    if (e.keyCode >= KEY_CODES.A && e.keyCode <= KEY_CODES.Z) {
                        onChange(inputValue + e.key);
                    }
                    // numbers
                    if (
                        e.keyCode >= KEY_CODES.NUMBER_0 &&
                        e.keyCode <= KEY_CODES.NUMBER_9
                    ) {
                        onChange(inputValue + e.key);
                    }
                    // else ignore for everything else
                    break;
            }
        },
        [onChange, inputValue]
    );

    const onBlur = useCallback(() => {
        onChange(cleanInputValue(inputValue));
    }, [onChange, inputValue]);

    const onPillsClick = useCallback(() => {
        inputBoxRef.current.focus();
    }, [inputBoxRef]);

    const getPillsItem = (pillData, i) => {
        // dont render last element
        if (i === valuesArray.length - 1) {
            return null;
        }
        return (
            <div
                role="button"
                onClick={onPillsClick}
                key={pillData}
                className={styles.pills.item}
            >
                <EvText>{pillData}</EvText>
            </div>
        );
    };

    const getPillsView = () => {
        const value = valuesArray[valuesArray.length - 1];
        let inputBoxWidth = value.length * 7;
        inputBoxWidth = inputBoxWidth < 50 ? 50 : inputBoxWidth;
        inputBoxWidth = inputBoxWidth > 300 ? 300 : inputBoxWidth;
        inputBoxWidth = inputValue ? inputBoxWidth : 200; // show full placeholder width, if no value
        return (
            <div className={styles.pills.container}>
                {valuesArray.map(getPillsItem)}
                <input
                    onChange={onInputChange}
                    style={{ width: inputBoxWidth }}
                    ref={inputBoxRef}
                    placeholder={inputValue ? "" : placeholder}
                    className={styles.input.box}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    value={value}
                />
            </div>
        );
    };

    return <div className={styles.container}>{getPillsView()}</div>;
};

EvCsvInput.propTypes = {
    keyName: PropTypes.string,
    minimalView: PropTypes.bool,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func,
};

EvCsvInput.defaultProps = {
    keyName: "VALUES",
    minimalView: true,
    placeholder: "[Enter values separated by comma]",
    formItem: {
        id: "VALUES",
        type: "CSV_TEXT",
        label: "",
        description: "",
        placeholder: "[Enter values separated by comma]",
        editable: true,
        defaultValue: "",
    },
    formData: {
        VALUES: { key: "VALUES", value: "1234,1523,1422", name: "" },
    },
    handleInputChange: () => {},
};

export default EvCsvInput;
