import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/ev-text.scss";

/**
 * Ev Text Typography required for VMS
 */
const EvText = (props) => {
    const styles = {
        common: "ev__ev-text__common",
        headingPrimary: "ev__ev-text__heading-primary",
        headingSecondary: "ev__ev-text__heading-secondary",
        subHeading: "ev__ev-text__sub-heading",

        defaultDarkBold: "ev__ev-text__default-dark-bold",
        defaultDark: "ev__ev-text__default-dark",
        default: "ev__ev-text__default",
        error: "ev__ev-text__error",
        defaultBold: "ev__ev-text__default-bold",

        smallNormal: "ev__ev-text__small-normal",
        smallBold: "ev__ev-text__small-bold",

        primaryColored: "ev__ev-text__primary-colored",
        underlined: "ev__ev-text__underlined",
        italics: "ev__ev-text__italics",
    };

    const customStyle = classNames(
        styles.common,
        {
            [styles.headingPrimary]: props.headingPrimary,
            [styles.headingSecondary]: props.headingSecondary,
            [styles.subHeading]: props.subHeading,

            [styles.defaultDarkBold]: props.defaultDarkBold,
            [styles.defaultDark]: props.defaultDark,
            [styles.default]: props.default,
            [styles.error]: props.error,
            [styles.defaultBold]: props.defaultBold,

            [styles.smallNormal]: props.smallNormal,
            [styles.smallBold]: props.smallBold,

            [styles.primaryColored]: props.primaryColored,
            [styles.underlined]: props.underlined,
            [styles.italics]: props.italics,
        },
        props.className
    );

    const getDangerousHtml = () => (
        <span dangerouslySetInnerHTML={{ __html: props.children }} />
    );

    return (
        <div
            className={customStyle}
            style={{ color: props.color, ...props.inlineStyles }}
        >
            {props.dangerous ? getDangerousHtml() : props.children}
        </div>
    );
};

EvText.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,

    headingPrimary: PropTypes.bool,
    headingSecondary: PropTypes.bool,
    subHeading: PropTypes.bool,

    defaultDark: PropTypes.bool,
    defaultDarkBold: PropTypes.bool,
    default: PropTypes.bool,
    error: PropTypes.bool,
    defaultBold: PropTypes.bool,

    smallNormal: PropTypes.bool,
    smallBold: PropTypes.bool,

    primaryColored: PropTypes.bool,
    underlined: PropTypes.bool,
    italics: PropTypes.bool,

    inlineStyles: PropTypes.object,
    className: PropTypes.string,

    dangerous: PropTypes.bool,
};

EvText.defaultProps = {
    /** child text to render */
    children: null,
    /** custom inline color of the text, will overwrite others */
    color: "",

    /** 20px, 300, #505664 */
    headingPrimary: false,
    /** 20px 800 #000a23 */
    headingSecondary: false,
    /** 20px normal #000a23 */
    subHeading: false,

    /** 16px normal #000a23 */
    defaultDark: false,
    /** 16px 800 #000aa23 */
    defaultDarkBold: false,
    /** 16px normal #505664 */
    default: false,
    /** 16px normal #da002f */
    error: false,
    /** 16px 800 #505664 */
    defaultBold: false,

    /** 12px normal #505664 */
    smallNormal: false,
    /** 12px 800 #505664 */
    smallBold: false,

    /** text color will be product primary color */
    primaryColored: false,
    /** underlined text, underline color can be changed by inline styles */
    underlined: false,
    /** italics text */
    italics: false,

    /** overwrite any other style inline */
    inlineStyles: {},
    /** external classname, can be used for hover changes too */
    className: "",
    dangerous: false,
};

export default memo(EvText);
