const PRE_PROD_TAB_IDS = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
};

const PRE_PROD_TEMPLATES = {
    PAGE_STATIC_DATA: {
        id: "preProdPage",
    },
};

const PRE_PROD_COMPONENTS = {
    PRE_APPROVALS: {
        id: "preProdPreApprovals",
    },
    APPROVED_LIST: {
        id: "preProdApprovedList",
    },
};

const PRE_PROD_CARD_STATUS = {
    APPROVED: "APPROVED",
    PENDING: "PENDING",
};

export {
    PRE_PROD_TAB_IDS,
    PRE_PROD_COMPONENTS,
    PRE_PROD_TEMPLATES,
    PRE_PROD_CARD_STATUS,
};
