import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getUserDataSelector } from "../redux/DashboardSelector";
import { isCompleted } from "../../../utils/CommonUtils";
import { EvButton, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";
import { push } from "connected-react-router";
import ClientListView from "./ClientListView";
import UsersListView from "./UsersListView";

import "../styles/dashboard-container.scss";

const styles = {
    container: "ev__dashboard-container__container",
    header: {
        container: "ev__dashboard-container__header-container",
        button: "ev__dashboard-container__header-button",
    },
};

const STATIC_DATA = {
    enterprise: {
        notification: {
            add: "Schedule new notification",
        },
    },
};

const DashboardContainer = (props) => {
    const { welcomeHeader, navigateToPage } = props;

    const onClickHandler = () => {
        navigateToPage({
            path: "/cc/enterprise/notifications",
        });
    };

    const getHeaderView = () => {
        return (
            <div className={styles.header.container}>
                <EvText headingSecondary>{welcomeHeader}</EvText>
                <EvButton
                    secondary
                    thickButton
                    className={styles.header.button}
                    iconName="PLUS"
                    iconFillColor={COLORS.PRODUCT}
                    onClickHandler={onClickHandler}
                >
                    {STATIC_DATA.enterprise.notification.add}
                </EvButton>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {getHeaderView()}
            <ClientListView />
            <UsersListView />
        </div>
    );
};

DashboardContainer.propTypes = {
    welcomeHeader: PropTypes.string,
};

DashboardContainer.defaultProps = {
    welcomeHeader: "",
};

const mapStateToProps = (state) => ({
    welcomeHeader: isCompleted(
        state.AppContainerReducer.controls.staticDataLoadingStatus,
        state.AppContainerReducer.controls.userDataLoadingStatus
    )
        ? getUserDataSelector(state).welcomeHeader
        : "",
});

const mapDispatchToProps = (dispatch) => ({
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
