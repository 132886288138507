import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { LINKED_UNLINKED_TAGS_COMPONENTS } from "../statics/LinkedUnlinkedStatics";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import {
    isCompleted,
    isCompletedOrLoading,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import { getData } from "../../vendor-programs/service/VendorProgramsService";
import { normalizerWithOrderArrayWithEmptyCheck } from "../../../utils/Normalizer";
import { parseInitialFormDataForTags } from "../../../utils/FormUtils";
import { EvLoadingPage, EvText } from "../../../common/components";
import UserMappingTagCard from "../../user-mapping/views/UserMappingTagCard";

import "../styles/linked-tags-view.scss";

const styles = {
    container: "ev__linked-tags-view__container",
    tags: {
        container: "ev__linked-tags-view__tags-container",
        wrapper: "ev__linked-tags-view__tags-wrapper",
        infoText: "ev__linked-tags-view__tags-info-text",
    },
};

const LinkedTagsView = (props) => {
    const { clientCode, staticData, allTagsData } = props;

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [tagsOrder, setTagsOrder] = useState([]);
    const [tagsFormData, setTagsFormData] = useState({});

    useEffect(() => {
        if (!isCompletedOrLoading(loadingStatus)) {
            setLoadingStatus(LOADING_STATUS.LOADING);
            getData(API_URLS.LINKED_UNLINKED_TAGS.GET_LINKED_TAGS, {
                clientCode,
            })
                .then((linkedTagsResponse) => {
                    const linkedTagsNormalized = normalizerWithOrderArrayWithEmptyCheck(
                        linkedTagsResponse.data.data.linkedTags
                    );
                    setTagsOrder(linkedTagsNormalized.ids);
                    setTagsFormData(
                        parseInitialFormDataForTags(
                            linkedTagsNormalized.ids,
                            linkedTagsNormalized.value,
                            staticData.tagsFormStatics.formFields,
                            allTagsData
                        )
                    );
                    setLoadingStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    setLoadingStatus(LOADING_STATUS.FAILED);
                });
        }
    }, [
        loadingStatus,
        allTagsData,
        clientCode,
        staticData.tagsFormStatics.formFields,
    ]);

    const onTagFormDataChange = useCallback(
        (tagId, key, formDataObject) => {
            setTagsFormData({
                ...tagsFormData,
                [tagId]: {
                    ...tagsFormData[tagId],
                    ...formDataObject,
                },
            });
        },
        [tagsFormData]
    );

    const onRemoveRuleSuccess = useCallback(
        (removedTagId) => {
            const newTagsOrder = tagsOrder.filter(
                (tagId) => tagId !== removedTagId
            );
            setTagsOrder(newTagsOrder);
        },
        [tagsOrder]
    );

    const getTagItem = (tagId) => {
        const tagData = allTagsData[tagId];
        if (isNullOrEmpty(tagData)) {
            return <span key={tagId} />;
        }
        return (
            <div key={tagId} className={styles.tags.wrapper}>
                <UserMappingTagCard
                    tagId={tagId}
                    header={tagData.displayName}
                    positiveButtonText={
                        staticData.tagsFormStatics.setRuleCta.text
                    }
                    negativeButtonText={
                        staticData.tagsFormStatics.removeRuleCta.text
                    }
                    formItems={staticData.tagsFormStatics.formFields}
                    onTagFormDataChange={onTagFormDataChange}
                    formData={tagsFormData[tagId]}
                    collapsedTemplate={
                        staticData.tagsFormStatics.ruleDisplayTemplate
                    }
                    isEditable={tagData.editable}
                    clientCode={clientCode}
                    onRemoveRuleSuccess={onRemoveRuleSuccess}
                />
            </div>
        );
    };

    const getTagsListView = () => (
        <div className={styles.tags.container}>{tagsOrder.map(getTagItem)}</div>
    );

    const getNoTagsView = () => {
        if (tagsOrder.length <= 0) {
            return (
                <EvText italics className={styles.tags.infoText}>
                    {staticData.noLinkedTags}
                </EvText>
            );
        }
        return null;
    };

    if (!isCompleted(loadingStatus)) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getNoTagsView()}
            {getTagsListView()}
        </div>
    );
};

LinkedTagsView.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    allTagsData: PropTypes.object,
};

LinkedTagsView.defaultProps = {
    clientCode: "",
    staticData: {},
    allTagsData: {},
};

const mapStateToProps = (state) => ({
    // controls: state.VendorProgramsReducer.controls,
    allTagsData:
        state.VendorProgramsReducer.dynamicData[
            LINKED_UNLINKED_TAGS_COMPONENTS.ALL_TAGS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    // setDynamicDataApiStatus: payload =>
    //   dispatch(VendorProgramsActions.setDynamicDataApiStatus(payload)),
    // setComponentData: payload =>
    //   dispatch(VendorProgramsActions.setComponentData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkedTagsView);
