import React from "react";
import { Route, Switch } from "react-router-dom";
import EnterpriseNotificationsContainer from "../../enterprise-notifications/views/EnterpriseNotificationContainer";
import { ENTERPRISE_CONTENT_PAGES } from "../static/EnterpriseContentStatics";

import "../styles/enterprise-content-view.scss";

const styles = {
    container: "ev__enterprise-content-view__container",
    sidebarContainer: "ev__enterprise-content-view__sidebar-container",
    applicationContainer: "ev__enterprise-content-view__application-container",
};

const EnterpriseContentView = () => {
    const getApplication = () => (
        <div className={styles.applicationContainer}>
            <Switch>
                <Route
                    path={ENTERPRISE_CONTENT_PAGES.NOTIFICATIONS.path}
                    exact
                    component={() => <EnterpriseNotificationsContainer />}
                />
            </Switch>
        </div>
    );

    return <div className={styles.container}>{getApplication()}</div>;
};

export default EnterpriseContentView;
