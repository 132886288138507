import React, { useCallback } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvFormRenderer,
    EvInputErrorView,
    EvPreviewFormRenderer,
} from "./index";
import { EvButton, EvDivider, EvText } from "../index";
import {
    guidGenerator,
    isNullOrEmpty,
    removeKeyFromObject,
} from "../../../utils/CommonUtils";
import { parseInitialStateFromFormFields } from "../../../utils/FormUtils";
import FormValidation from "../../../utils/FormValidations";

import "../../styles/form-components/ev-form-template-renderer.scss";

const styles = {
    container: "ev__ev-form-template-renderer__container",
    header: {
        container: "ev__ev-form-template-renderer__header-container",
    },
    form: {
        wrapper: "ev__ev-form-template-renderer__form-wrapper",
        itemsContainer: "ev__ev-form-template-renderer__form-items-container",
        labelContainer: "ev__ev-form-template-renderer__form-label-container",
        contentContainer:
            "ev__ev-form-template-renderer__form-content-container",
    },
    actionViewContainer: "ev__ev-form-template-renderer__action-view-container",
    deleteItemButton: "ev__ev-form-template-renderer__delete-item-button",
    addItemButton: "ev__ev-form-template-renderer__add-item-button",
};

const EvFormTemplateRenderer = (props) => {
    const {
        keyName,
        formItem,
        formData,
        initialFormData,
        handleInputChange,
        showError,
        qParams,
    } = props;

    const onAddNewItemClick = useCallback(() => {
        const guid = guidGenerator();
        const formTemplateData = {
            ...formData.formTemplateData,
            [guid]: parseInitialStateFromFormFields(formItem.formItems, {}),
        };
        const newFormData = {
            ...formData,
            formTemplateData,
            errorObject: {
                ...formData.errorObject,
                isUsed: true,
                ...FormValidation(formItem, formTemplateData),
            },
        };
        handleInputChange(keyName, newFormData, formItem);
    }, [formData, keyName, formItem, handleInputChange]);

    const onDeleteItemClick = useCallback(
        (e, callbackOptions) => {
            const newFormTemplateData = removeKeyFromObject(
                callbackOptions.formTemplateId,
                formData.formTemplateData
            );
            const newFormData = {
                ...formData,
                formTemplateData: newFormTemplateData,
                errorObject: {
                    ...formData.errorObject,
                    isUsed: true,
                    ...FormValidation(formItem, newFormTemplateData),
                },
            };
            handleInputChange(keyName, newFormData, formItem);
        },
        [formData, keyName, formItem, handleInputChange]
    );

    const onFormDataChangeLocal = useCallback(
        (key, newFormData, callbackValues) => {
            const combinedFormData = {
                ...formData,
                errorObject: {
                    ...formData.errorObject,
                    isUsed: true,
                },
                formTemplateData: {
                    ...formData.formTemplateData,
                    [callbackValues.formTemplateId]: {
                        ...formData.formTemplateData[
                            callbackValues.formTemplateId
                        ],
                        ...newFormData,
                    },
                },
            };
            handleInputChange(keyName, combinedFormData, formItem);
        },
        [formData, handleInputChange, formItem, keyName]
    );

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    const getActionView = () => (
        <div className={styles.actionViewContainer}>
            <div className={styles.form.labelContainer} />
            <div className={styles.form.contentContainer}>
                {!isNullOrEmpty(formItem.removeItemCta) && (
                    <EvButton
                        className={styles.addItemButton}
                        iconButton
                        iconName="PLUS"
                        onClickHandler={onAddNewItemClick}
                    >
                        {formItem.addItemCta.text}
                    </EvButton>
                )}
                {showError && getErrorView()}
            </div>
        </div>
    );

    const getFormItemActionView = (formTemplateId) => (
        <div className={styles.actionViewContainer}>
            <div className={styles.form.labelContainer} />
            <div className={styles.form.contentContainer}>
                {!isNullOrEmpty(formItem.addItemCta) && (
                    <EvButton
                        className={styles.deleteItemButton}
                        iconButton
                        iconName="DELETE"
                        callbackValues={{ formTemplateId }}
                        onClickHandler={onDeleteItemClick}
                    >
                        {formItem.removeItemCta.text}
                    </EvButton>
                )}
            </div>
        </div>
    );

    const getFormView = (formTemplateId, i) => (
        <div key={formTemplateId} className={styles.form.wrapper}>
            {formItem.itemLabel && (
                <EvText defaultDark>
                    {stringTemplate(formItem.itemLabel, { count: i + 1 })}
                </EvText>
            )}
            {isNullOrEmpty(formItem.cardPreview) ? (
                <EvFormRenderer
                    formFields={formItem.formItems}
                    formData={formData.formTemplateData[formTemplateId]}
                    onFormDataChange={onFormDataChangeLocal}
                    callbackValues={{ formTemplateId }}
                    initialFormData={initialFormData}
                    qParams={qParams}
                />
            ) : (
                <EvPreviewFormRenderer
                    formFields={formItem.formItems}
                    formData={formData.formTemplateData[formTemplateId]}
                    editCta={formItem.editCta}
                    previewCta={formItem.previewCta}
                    cardPreview={formItem.cardPreview}
                    callbackValues={{ formTemplateId }}
                    onFormDataChange={onFormDataChangeLocal}
                    initialFormData={initialFormData}
                    editable={formItem.editable}
                    qParams={qParams}
                />
            )}
            {getFormItemActionView(formTemplateId)}
            <EvDivider marginVertical={10} />
        </div>
    );

    const getFormItems = () => (
        <div className={styles.form.itemContainer}>
            {formData.formTemplateData &&
                Object.keys(formData.formTemplateData).map(getFormView)}
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            {formItem.label && <EvText defaultDark>{formItem.label}</EvText>}
            {formItem.description && <EvText>{formItem.description}</EvText>}
        </div>
    );

    return (
        <div className={styles.container}>
            <EvDivider marginVertical={10} />
            {getHeaderView()}
            {getFormItems()}
            {getActionView()}
        </div>
    );
};

EvFormTemplateRenderer.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    handleInputChange: PropTypes.func,
    formData: PropTypes.object,
    initialFormData: PropTypes.object,
    qParams: PropTypes.object,
    showError: PropTypes.bool,
};

EvFormTemplateRenderer.defaultProps = {
    keyName: "",
    formItem: {},
    handleInputChange: () => {},
    formData: {},
    initialFormData: {},
    qParams: {},
    showError: true,
};

export default EvFormTemplateRenderer;
