import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
    EvDivider,
    EvDropdownMenu,
    EvHeaderView,
    EvLoadingPage,
    EvTabSelector,
    EvText,
} from "../../../common/components";
import { EvChecklistDropdown } from "../../../common/components/form-components";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import { getInitialObjectForFormField } from "../../../utils/FormUtils";
import { createVendorProgramNormalizedDictionary } from "../../../utils/SpecificDataModelUtils";
import ClientCardsActions from "../redux/ClientCardsActions";
import { getData } from "../service/ClientCardsService";
import {
    CLIENT_CARDS_COMPONENTS,
    CLIENT_CARDS_ROUTES,
    CLIENT_CARDS_STATICS,
    CLIENT_CARDS_TEMPLATES,
} from "../statics/ClientCardsStatics";
import "../styles/client-cards-dashboard.scss";
import ClientCardsCampaignsView from "./ClientCardsCampaignsView";
import ClientCardsVendorView from "./ClientCardsVendorView";

const styles = {
    container: "ev__client-cards-dashboard__container",
    filterContainer: "ev__client-cards-dashboard__filter-container",
    actionView: {
        container: "ev__client-cards-dashboard__action-view-container",
        button: "ev__client-cards-dashboard__action-view-button",
        reorderMenu: "ev__client-cards-dashboard__action-view-reorder-menu",
    },
};

const ClientCardsDashboard = ({
    controls,
    staticData,
    cardTypesStatics,
    cardList,
    vendorProgramList,
    location,
    getStaticData,
    setComponentData,
    setDynamicDataApiStatus,
    navigateToPage,
    softReset,
}) => {
    const [selectedTabId, setSelectedTabId] = useState(
        CLIENT_CARDS_STATICS.TABS.CAMPAIGNS
    );
    const [campaignsDropdownFormData, setCampaignsDropdownFormData] = useState(
        {}
    );

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );

    const clientName = useMemo(
        () =>
            location.state && location.state.clientName
                ? location.state.clientName
                : "",
        [location.state]
    );

    useEffect(() => {
        (async () => {
            const response = await getStaticData({
                templateId: CLIENT_CARDS_TEMPLATES.DASHBOARD_STATICS.id,
                url: API_URLS.CLIENT_CARDS.DASHBOARD.STATIC,
            });

            const obj = getInitialObjectForFormField(
                response.campaignsTypeDropdown
            );

            setCampaignsDropdownFormData(
                obj[response.campaignsTypeDropdown.id]
            );
        })();
    }, [getStaticData]);

    useEffect(() => {
        getStaticData({
            templateId: CLIENT_CARDS_TEMPLATES.CARD_TYPES.id,
            url: API_URLS.CLIENT_CARDS.CARD_TYPES,
        });
    }, [getStaticData]);

    useEffect(() => {
        (async () => {
            if (
                isCompletedOrLoading(
                    controls.dynamicDataApiStatus[
                        CLIENT_CARDS_COMPONENTS.CARD_LIST.id
                    ]
                )
            ) {
                return;
            }

            setDynamicDataApiStatus({
                [CLIENT_CARDS_COMPONENTS.CARD_LIST.id]: LOADING_STATUS.LOADING,
            });

            try {
                var { data } = await getData(
                    API_URLS.CLIENT_CARDS.DASHBOARD.CARD_LIST,
                    { clientCode }
                );
            } catch (e) {
                setDynamicDataApiStatus({
                    [CLIENT_CARDS_COMPONENTS.CARD_LIST.id]:
                        LOADING_STATUS.FAILED,
                });

                EvLogger.errorWithObject(
                    e,
                    "ClientCardsDashboard onCardListLoad"
                );

                return;
            }

            setComponentData({
                componentId: CLIENT_CARDS_COMPONENTS.CARD_LIST.id,
                data: [...data.data.featureCardList, ...data.data.calloutsList],
            });

            setDynamicDataApiStatus({
                [CLIENT_CARDS_COMPONENTS.CARD_LIST.id]:
                    LOADING_STATUS.COMPLETED,
            });
        })();
    }, [
        clientCode,
        controls.dynamicDataApiStatus,
        setComponentData,
        setDynamicDataApiStatus,
    ]);

    useEffect(() => {
        (async () => {
            if (
                isCompletedOrLoading(
                    controls.dynamicDataApiStatus[
                        CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id
                    ]
                )
            ) {
                return;
            }

            setDynamicDataApiStatus({
                [CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id]:
                    LOADING_STATUS.LOADING,
            });

            try {
                var { data } = await getData(
                    API_URLS.CLIENT_CARDS.DASHBOARD.VENDOR_PROGRAM_LIST,
                    { clientCode }
                );
            } catch (e) {
                setDynamicDataApiStatus({
                    [CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id]:
                        LOADING_STATUS.FAILED,
                });

                EvLogger.errorWithObject(
                    e,
                    "ClientCardsDashboard onVendorProgramsListLoad"
                );

                return;
            }

            setComponentData({
                componentId: CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id,
                data: createVendorProgramNormalizedDictionary(
                    data.data.preSelected
                ),
            });

            setDynamicDataApiStatus({
                [CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id]:
                    LOADING_STATUS.COMPLETED,
            });
        })();
    }, [
        clientCode,
        controls.dynamicDataApiStatus,
        setComponentData,
        setDynamicDataApiStatus,
    ]);

    const onCampaignsDropdownChange = useCallback((_, newFormData) => {
        setCampaignsDropdownFormData(newFormData);
    }, []);

    const onReorderClick = useCallback(
        (callbackValues) => {
            navigateToPage({
                path: CLIENT_CARDS_ROUTES.CATEGORIES.path,
                state: {
                    ...location.state,
                    cardDashboardType: callbackValues.id,
                },
            });
        },
        [location.state, navigateToPage]
    );

    const onCardClick = useCallback(
        (_, callbackValues) => {
            const currentCardStaticData =
                cardTypesStatics.cardTypes[callbackValues.cardType];
            const cardVendorProgramData =
                vendorProgramList[
                    `${callbackValues.vendorId}-${callbackValues.programId}`
                ];

            navigateToPage({
                path: currentCardStaticData.path,
                state: {
                    ...location.state,
                    vendorCardData: {
                        vendorId: cardVendorProgramData.vendorId,
                        vendorName: cardVendorProgramData.vendorName,
                        programId: cardVendorProgramData.programId,
                        programName: cardVendorProgramData.programName,
                        vendorType: cardVendorProgramData.vendorType,
                        cardId: callbackValues.id,
                        cardName: callbackValues.displayName,
                    },
                },
            });

            // clear dynamic data after navigation
            setTimeout(() => {
                softReset();
            }, 200);
        },
        [
            cardTypesStatics,
            vendorProgramList,
            navigateToPage,
            softReset,
            location.state,
        ]
    );

    const onAddCardClick = useCallback(
        (callbackValues) => {
            onCardClick(null, {
                ...callbackValues,
                cardType: callbackValues.id,
                id: "",
            });
        },
        [onCardClick]
    );

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            headerResolvers={{ clientName }}
            description={staticData.subHeader}
            descriptionResolvers={{ clientName }}
        />
    );

    const getFilterView = () => (
        <div className={styles.filterContainer}>
            <div>
                <EvText defaultBold>{staticData.categoryLabel}</EvText>
                <EvTabSelector
                    tabsData={staticData.categories}
                    onTabSelect={setSelectedTabId}
                    initialSelectedId={selectedTabId}
                />
            </div>
            <EvChecklistDropdown
                formItem={staticData.campaignsTypeDropdown}
                handleInputChange={onCampaignsDropdownChange}
                keyName={staticData.campaignsTypeDropdown.id}
                formData={campaignsDropdownFormData}
                placeholder={staticData.campaignsTypeDropdown.placeholder}
            />
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvDropdownMenu
                data={staticData.reorderActionDropdown.data}
                label={staticData.reorderActionDropdown.text}
                buttonType="primary"
                buttonClassName={styles.actionView.button}
                menuClassName={styles.actionView.reorderMenu}
                onClickHandler={onReorderClick}
            />
        </div>
    );

    const getContentView = () => {
        switch (selectedTabId) {
            case CLIENT_CARDS_STATICS.TABS.CAMPAIGNS:
                return (
                    <ClientCardsCampaignsView
                        staticData={cardTypesStatics}
                        cardList={cardList}
                        campaignsTypeFilterData={campaignsDropdownFormData}
                        vendorProgramsList={vendorProgramList}
                        onCardClick={onCardClick}
                    />
                );

            case CLIENT_CARDS_STATICS.TABS.VENDOR_PROGRAMS:
                return (
                    <ClientCardsVendorView
                        staticData={staticData}
                        cardTypesStatics={cardTypesStatics}
                        cardList={cardList}
                        vendorProgramsList={vendorProgramList}
                        campaignsTypeFilterData={campaignsDropdownFormData}
                        onCardClick={onCardClick}
                        onAddCardClick={onAddCardClick}
                    />
                );

            default:
                return <span>View not found</span>;
        }
    };

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                CLIENT_CARDS_TEMPLATES.DASHBOARD_STATICS.id
            ],
            controls.staticDataApiStatus[CLIENT_CARDS_TEMPLATES.CARD_TYPES.id],
            controls.dynamicDataApiStatus[CLIENT_CARDS_COMPONENTS.CARD_LIST.id],
            controls.dynamicDataApiStatus[
                CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFilterView()}
            {getActionView()}
            <EvDivider marginVertical="4rem" />
            {getContentView()}
        </div>
    );
};

ClientCardsDashboard.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    cardTypesStatics: PropTypes.object,
    cardList: PropTypes.array,
    vendorProgramList: PropTypes.object,
    location: PropTypes.object,
    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    navigateToPage: PropTypes.func,
    softReset: PropTypes.func,
};

ClientCardsDashboard.defaultProps = {
    controls: {},
    staticData: {},
    cardTypesStatics: {},
    cardList: [],
    vendorProgramList: {},
    location: {},
    getStaticData: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
    navigateToPage: () => {},
    softReset: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientCardsReducer.controls,
    staticData:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.DASHBOARD_STATICS.id
        ],
    cardTypesStatics:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.CARD_TYPES.id
        ],
    cardList:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.CARD_LIST.id
        ],
    vendorProgramList:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientCardsActions.getStaticData(payload)),
    softReset: (payload) => dispatch(ClientCardsActions.softReset(payload)),
    setComponentData: (payload) =>
        dispatch(ClientCardsActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(ClientCardsActions.setDynamicDataApiStatus(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientCardsDashboard);
