import { goBack, push, replace } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { EvLoadingPage } from "../../../../common/components";
import { ROUTES } from "../../../../common/static/Enums";
import CacheActions from "../../../../reducers/cache/CacheActions";
import API_URLS from "../../../../services/apiUrls";
import { isCompleted, isNullOrEmpty } from "../../../../utils/CommonUtils";
import EvLogger, {
    LOG_ACTION_TYPE,
    LOG_CATEGORY_TYPES,
} from "../../../../utils/EvLogger";
import VendorProgramsActions from "../../redux/VendorProgramsActions";
import { VENDOR_PROGRAMS_PAGES } from "../../statics/VendorProgramsStatics";
import { CUSTOMIZE_PROGRAMS_TEMPLATES } from "../statics/CustomizeProgramsStatics";
import CustomizeProgramsView from "./CustomizeProgramsView";

const CustomizeProgramsContainer = (props) => {
    const {
        controls,
        staticData,
        location,
        // actions
        getStaticData,
        navigateToPage,
        replaceAction,
        goBackAction,
    } = props;

    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    // check valid vendorCardData else land on dashboard
    useEffect(() => {
        // check its trying to access a customize program page only
        // otherwise it will run when redirecting to vendor dashboard after removing program
        if (
            !location.pathname.includes(
                VENDOR_PROGRAMS_PAGES.CUSTOMIZE_PROGRAMS.path
            )
        ) {
            return;
        }
        if (
            isNullOrEmpty(vendorCardData) ||
            !vendorCardData.vendorId ||
            !vendorCardData.programId
        ) {
            EvLogger.warn(
                `Landed on CustomizeProgramsContainer without vendorCardData: ${JSON.stringify(
                    vendorCardData
                )}. Redirection to CC dashboard`,
                LOG_CATEGORY_TYPES.CC,
                LOG_ACTION_TYPE.LOAD
            );
            navigateToPage({ path: ROUTES.DASHBOARD });
        }
    }, [vendorCardData, navigateToPage, location.pathname]);

    // in case no specific route in customize is found, navigate to first application
    useEffect(() => {
        // if static data not yet loaded
        if (isNullOrEmpty(staticData.sideBarsTabs)) {
            return;
        }
        if (
            location.pathname === VENDOR_PROGRAMS_PAGES.CUSTOMIZE_PROGRAMS.path
        ) {
            replaceAction({
                path: staticData.sideBarsTabs[0].action,
                state: location.state,
            });
        }
    }, [location, staticData, replaceAction]);

    useEffect(() => {
        getStaticData({
            templateId: CUSTOMIZE_PROGRAMS_TEMPLATES.STATICS.id,
            url: API_URLS.VENDOR_PROGRAMS.CUSTOMIZATION.STATIC,
        });
    }, [getStaticData]);

    // const onRemoveProgramConfirm = useCallback(() => {
    //   deleteData(
    //     API_URLS.VENDOR_PROGRAMS.REMOVE_PROGRAM,
    //     {},
    //     {
    //       clientCode,
    //       programId: vendorCardData.programId,
    //       vendorId: vendorCardData.vendorId,
    //     }
    //   )
    //     .then(removeProgramResponse => {
    //       EvToast.success("", "Program Removed", {
    //         icon: TOAST_ICON_OBJECTS.CHECK,
    //       });
    //       navigateToPage({
    //         path: VENDOR_PROGRAMS_PAGES.DASHBOARD.path,
    //         state: {
    //           clientCode,
    //           clientName,
    //         },
    //       });
    //       clearCacheByURL({
    //         url: API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.LIST_DATA,
    //       });
    //     })
    //     .catch(e => {
    //       EvToast.error("Sorry", "Failed to remove program");
    //       EvLogger.errorWithObject(
    //         e,
    //         "ClientProgramsContainer onRemoveProgramConfirm"
    //       );
    //     });
    // }, [vendorCardData, navigateToPage]);

    // const onRemoveProgramClick = useCallback(() => {
    //   const confirmationModalData = {
    //     header: staticData.removeProgramCta.confirmationModal.header,
    //     description: staticData.removeProgramCta.confirmationModal.description,
    //     positiveButtonText:
    //       staticData.removeProgramCta.confirmationModal.positiveCta.text,
    //     negativeButtonText:
    //       staticData.removeProgramCta.confirmationModal.negativeCta.text,
    //     onPositiveAction: onRemoveProgramConfirm,
    //   };
    //   EvSimpleModal.setData(confirmationModalData).show();
    // }, [staticData, onRemoveProgramConfirm]);

    // const selectedApplicationId = useMemo(
    //   () =>
    //     getSelectedApplicationIdFromSidebar(
    //       staticData.sideBarsTabs,
    //       location.pathname
    //     ),
    //   [staticData, location]
    // );

    const onBackButtonClick = useCallback(() => {
        // navigateToPage({
        //   path: VENDOR_PROGRAMS_PAGES.DASHBOARD.path,
        //   state: {
        //     clientCode,
        //     clientName,
        //   },
        // });
        goBackAction();
    }, [goBackAction]);

    // const onSidebarClick = useCallback(
    //   (e, selectedSidebarData) => {
    //     if (selectedSidebarData.itemData.type === CTA_TYPE.INTERNAL) {
    //       // navigateToPage({
    //       //   path: selectedSidebarData.itemData.action,
    //       //   state: {
    //       //     clientCode,
    //       //     clientName,
    //       //     vendorCardData,
    //       //   },
    //       // });
    //       // clicked on same URL
    //       if (location.pathname === selectedSidebarData.itemData.action) {
    //         return;
    //       }
    //       replaceAction({
    //         path: selectedSidebarData.itemData.action,
    //         state: {
    //           clientCode,
    //           clientName,
    //           vendorCardData,
    //         },
    //       });
    //     } else if (
    //       // special case, remove program
    //       selectedSidebarData.itemData.type === CTA_TYPE.ACTION &&
    //       selectedSidebarData.itemData.id === "removeProgram"
    //     ) {
    //       onRemoveProgramClick();
    //     }
    //   },
    //   [replaceAction, onRemoveProgramClick, location]
    // );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                CUSTOMIZE_PROGRAMS_TEMPLATES.STATICS.id
            ]
        )
    ) {
        return <EvLoadingPage animatedFadeIn />;
    }

    return (
        <CustomizeProgramsView
            staticData={staticData}
            onBackButtonClick={onBackButtonClick}
            // selectedApplicationId={selectedApplicationId}
            // onSidebarClick={onSidebarClick}
            navigateToPage={navigateToPage}
            location={location}
        />
    );
};

CustomizeProgramsContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,

    controls: PropTypes.object,
    staticData: PropTypes.object,
    location: PropTypes.object,

    // actions
    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
    replaceAction: PropTypes.func,
    goBackAction: PropTypes.func,
};

CustomizeProgramsContainer.defaultProps = {
    clientCode: "",
    clientName: "",

    controls: {},
    staticData: {},
    location: {},

    // actions
    getStaticData: () => {},
    navigateToPage: () => {},
    replaceAction: () => {},
    goBackAction: () => {},
    clearCacheByURL: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    staticData:
        state.VendorProgramsReducer.staticData[
            CUSTOMIZE_PROGRAMS_TEMPLATES.STATICS.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    replaceAction: (payload) => dispatch(replace(payload.path, payload.state)),
    goBackAction: () => dispatch(goBack()),
    clearCacheByURL: (payload) =>
        dispatch(CacheActions.clearCacheByURL(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizeProgramsContainer);
