import React from "react";
import PropTypes from "prop-types";
import dateTime from "date-and-time";
import stringTemplate from "string-template";

import { EvText } from "../index";

import "../../styles/content-items/ev-content-date.scss";
import { SERVER_FORMATS, UI_FORMATS } from "../../static/Enums";

const styles = {
    container: "ev__ev-content-date__container",
};

const EvContentDate = (props) => {
    const { label, displayResolver, allValues, dateFormat, dependency } = props;
    const date = dateTime.parse(allValues[dependency], SERVER_FORMATS.DATE);
    const displayDate = dateTime.format(date, dateFormat);
    const displayString = stringTemplate(displayResolver, {
        ...allValues,
        [dependency]: displayDate,
    });
    return (
        <div className={styles.container}>
            <EvText
                defaultDark
                dangerous
            >{`${label} <b>${displayString}</b>`}</EvText>
        </div>
    );
};

EvContentDate.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
    dateFormat: PropTypes.string,
};

EvContentDate.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
    dateFormat: UI_FORMATS.FULL_DATE,
};

export default EvContentDate;
