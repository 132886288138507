import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    EvButton,
    EvLoadingPage,
    EvSimpleCard,
    EvText,
} from "../../../common/components";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import SavedPopulationActions from "../redux/SavedPopulationActions";
import { getData } from "../service/SavedPopulationService";
import {
    SAVED_POPULATION_COMPONENTS,
    SAVED_POPULATION_TEMPLATES,
} from "../static/SavedPopulationStatics";
import "../styles/saved-population-dashboard.scss";

const styles = {
    container: "ev__saved-population__container",
    header: {
        container: "ev__saved-population__header-container",
        headerText: "ev__saved-population__header-text",
    },
    newPopulationButton: "ev__saved-population__new-population-button",
    populationList: {
        header: "ev__saved-population__population-list-header",
        listContainer: "ev__saved-population__population-list-list-container",
        listContainerFixed:
            "ev__saved-population__population-list-list-container-fixed",
        listContainerExpanded:
            "ev__saved-population__population-list-list-container-expanded",
        cardWrapper: "ev__saved-population__population-list-card-wrapper",
        card: "ev__saved-population__population-list-card",
        populationName: "ev__saved-population__population-list-population-name",
        infoText: "ev__saved-population__population-list-info-text",
    },
    loadMoreButton: "ev__saved-population__load-more-button",
};

const initialNumToRender = 6;

const SavedPopulationDashboard = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        savedPopulationList,

        // actions
        setControls,
        getStaticData,
        setComponentData,
        setDynamicDataApiStatus,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        getStaticData({
            url: API_URLS.SAVED_POPULATION.DASHBOARD.STATIC,
            templateId: SAVED_POPULATION_TEMPLATES.DASHBOARD.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        setDynamicDataApiStatus({
            [SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id]:
                LOADING_STATUS.LOADING,
        });
        getData(API_URLS.SAVED_POPULATION.DASHBOARD.POPULATION_LIST, {
            clientCode,
        })
            .then((response) => {
                setComponentData({
                    componentId:
                        SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id,
                    data: response.data.data.savedPopulationList,
                });
                setDynamicDataApiStatus({
                    [SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "SavedPopulationDashboard getData");
            });
    }, [clientCode, setComponentData, setDynamicDataApiStatus]);

    const onLoadMoreClick = useCallback(() => {
        setIsExpanded(true);
    }, []);

    const onPopulationCardClick = useCallback(
        (e, callbackValues) => {
            const { populationData } = callbackValues;
            // clear  population criteria list data, it only clears from here
            // it should not clear when landing to new population page from tag selection
            setDynamicDataApiStatus({
                [SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
            setControls({
                selectedPageId:
                    SAVED_POPULATION_TEMPLATES.NEW_EDIT_POPULATION.id,
                currentNotificationPopulationId: populationData.id || "",
                // isNewPopulation: isNewPopulation || "",
            });
        },
        [setControls, setDynamicDataApiStatus]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText className={styles.header.headerText} subHeading>
                {staticData.header}
            </EvText>
            <EvText className={styles.header.headerText} defaultDark>
                {staticData.description}
            </EvText>
        </div>
    );

    const getNewPopulationButton = () => (
        <div className={styles.newPopulationButton}>
            <EvButton
                onClickHandler={onPopulationCardClick}
                primaryFilled
                callbackValues={{ populationData: {} }}
            >
                {staticData.newPopulationCta.text}
            </EvButton>
        </div>
    );

    const getSavedPopulationHeader = () => (
        <div className={styles.populationList.header}>
            <EvText defaultDark>{staticData.savedListHeader}</EvText>
        </div>
    );

    const getSavedPopulationCard = (populationData) => (
        <EvButton
            key={populationData.id}
            onClickHandler={onPopulationCardClick}
            onlyChild
            className={styles.populationList.cardWrapper}
            callbackValues={{ populationData, isNewPopulation: false }}
        >
            <EvSimpleCard className={styles.populationList.card}>
                <EvText
                    defaultDarkBold
                    className={styles.populationList.populationName}
                >
                    {populationData.title}
                </EvText>
                {populationData.lastUsed && (
                    <EvText smallNormal>
                        {staticData.lastSavedText}
                        {populationData.lastUsed}
                    </EvText>
                )}
            </EvSimpleCard>
        </EvButton>
    );

    const getFixedList = () => (
        <div className={styles.populationList.listContainerFixed}>
            {savedPopulationList
                .slice(0, initialNumToRender)
                .map(getSavedPopulationCard)}
        </div>
    );

    const getExpandedList = () => (
        <div className={styles.populationList.listContainerExpanded}>
            {savedPopulationList
                .slice(initialNumToRender)
                .map(getSavedPopulationCard)}
        </div>
    );

    const getSavedPopulationList = () => {
        // empty population list
        if (savedPopulationList.length <= 0) {
            return (
                <EvText
                    className={styles.populationList.infoText}
                    italics
                    smallNormal
                >
                    {staticData.noPopulationMessage}
                </EvText>
            );
        }
        return (
            <div className={styles.populationList.listContainer}>
                {getFixedList()}
                {isExpanded && getExpandedList()}
            </div>
        );
    };

    const getLoadMoreButton = () => (
        <div className={styles.loadMoreButton}>
            <EvButton
                secondary
                buttonInlineStyle={{
                    flex: 1,
                    height: "5rem",
                }}
                onClickHandler={onLoadMoreClick}
            >
                {staticData.loadMoreCta.text}
            </EvButton>
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                SAVED_POPULATION_TEMPLATES.DASHBOARD.id
            ],
            controls.dynamicDataApiStatus[
                SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }
    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getNewPopulationButton()}
            {getSavedPopulationHeader()}
            {getSavedPopulationList()}
            {!isExpanded &&
                savedPopulationList.length > initialNumToRender &&
                getLoadMoreButton()}
        </div>
    );
};

SavedPopulationDashboard.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    savedPopulationList: PropTypes.object,

    // actions
    setControls: PropTypes.func,
    setComponentData: PropTypes.func,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

SavedPopulationDashboard.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},
    savedPopulationList: {},

    // actions
    setControls: () => {},
    setComponentData: () => {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.SavedPopulationReducer.controls,
    staticData:
        state.SavedPopulationReducer.staticData[
            SAVED_POPULATION_TEMPLATES.DASHBOARD.id
        ],
    savedPopulationList:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.SAVED_POPULATION_LIST.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(SavedPopulationActions.setControls(payload)),
    setComponentData: (payload) =>
        dispatch(SavedPopulationActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(SavedPopulationActions.setDynamicDataApiStatus(payload)),
    getStaticData: (payload) =>
        dispatch(SavedPopulationActions.getStaticData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedPopulationDashboard);
