import { goBack } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
    EvDivider,
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvToast,
} from "../../../common/components";
import { EvPreviewFormRenderer } from "../../../common/components/form-components";
import CommonActions from "../../../common/redux/CommonActions";
import { getData, postData } from "../../../common/service/CommonService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { campaignsAPI } from "../../../services/security";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    CALLOUTS_APP_ID,
    CALLOUTS_TEMPLATES,
    CALLOUT_STATICS,
} from "../static/CalloutsStatics";
import "../styles/callouts-content.scss";

const styles = {
    container: "ev__callouts-content__container",
    form: {
        container: "ev__callouts-content__form-container",
    },
    actionView: "ev__callouts-content__action-view",
};

const CalloutsContent = (props) => {
    const { controls, staticData, location, getStaticData, goBackAction } =
        props;

    const [initialFormData, setInitialFormData] = useState({});
    const [calloutsFormData, setCalloutsFormData] = useState({});
    const [contentFormData, setContentFormData] = useState({});
    const [detailsFormData, setDetailsFormData] = useState({});
    const [eviveCardFormData, setEviveCardFormData] = useState({});

    const [ctaDataList, setCtaDataList] = useState({});

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode =
        location.state && location.state.clientCode
            ? location.state.clientCode
            : "";

    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    templateId: CALLOUTS_TEMPLATES.CONTENT_STATIC.id,
                    url: API_URLS.CALLOUTS.CONTENT_STATIC,
                }),
                getData(API_URLS.CALLOUTS.CALLOUTS_DATA, {
                    id: vendorCardData.cardId,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    clientCode,
                }),
                getData(API_URLS.CALLOUTS.CTA_DATA_LIST, {
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    clientCode,
                }),
                campaignsAPI.get(
                    `cc/api/customers/${clientCode}/callouts/definitions/${vendorCardData.cardId}`
                ),
            ]),
        [
            clientCode,
            getStaticData,
            vendorCardData.vendorId,
            vendorCardData.programId,
            vendorCardData.cardId,
        ]
    );

    useEffect(() => {
        if (isCompletedOrLoading(loadingStatus)) {
            return;
        }
        getAllData()
            .then(
                ([
                    staticDataResponse,
                    calloutsDataResponse,
                    ctaDataListResponse,
                    { data: campaignsDefinition },
                ]) => {
                    setInitialFormData(calloutsDataResponse.data.data);
                    setCalloutsFormData(
                        parseInitialStateFromFormFields(
                            staticDataResponse.calloutsForm.formFields,
                            {
                                ...calloutsDataResponse.data.data,
                                audiences: campaignsDefinition["audiences"]
                                    ? JSON.stringify(
                                          campaignsDefinition["audiences"]
                                      )
                                    : "",
                                criteria: campaignsDefinition["criteria"]
                                    ? JSON.stringify(
                                          campaignsDefinition["criteria"]
                                      )
                                    : "",
                            }
                        )
                    );
                    setContentFormData(
                        parseInitialStateFromFormFields(
                            staticDataResponse.contentForm.formFields,
                            calloutsDataResponse.data.data
                        )
                    );
                    setDetailsFormData(
                        parseInitialStateFromFormFields(
                            staticDataResponse.detailsForm.formFields,
                            calloutsDataResponse.data.data
                        )
                    );
                    setEviveCardFormData(
                        parseInitialStateFromFormFields(
                            staticDataResponse.eviveCardForm.formFields,
                            calloutsDataResponse.data.data
                        )
                    );

                    setCtaDataList(ctaDataListResponse.data.data);
                    setLoadingStatus(LOADING_STATUS.COMPLETED);
                }
            )
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "CalloutsContent getCalloutsData");
            });
    }, [loadingStatus, getStaticData, getAllData]);

    const onSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (
            !isFullFormValid(contentFormData) ||
            !isFullFormValid(calloutsFormData)
        ) {
            setCalloutsFormData(markAllFieldsUsed(calloutsFormData));
            setContentFormData(markAllFieldsUsed(contentFormData));
            EvToast.warn("", CALLOUT_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = {
            calloutId: vendorCardData.cardId,
            vendorId: vendorCardData.vendorId,
            programId: vendorCardData.programId,
            ...parseFormDataForApi(calloutsFormData),
            ...parseFormDataForApi(contentFormData),
            ...parseFormDataForApi(detailsFormData),
            ...parseFormDataForApi(eviveCardFormData),
        };
        postData(API_URLS.CALLOUTS.SUBMIT, { clientCode }, apiData)
            .then(() => {
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                goBackAction();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Something went wrong");
                setSubmitStatus(LOADING_STATUS.FAILED);
            });
    }, [
        clientCode,
        goBackAction,
        submitStatus,
        vendorCardData,
        contentFormData,
        calloutsFormData,
        detailsFormData,
        eviveCardFormData,
    ]);

    const getHeaderView = () => (
        <EvHeaderView
            dangerous
            header={staticData.header}
            headerResolvers={{
                ...vendorCardData,
                calloutName: vendorCardData.cardName,
            }}
            description={staticData.description}
        />
    );

    const getConfigFormView = () => (
        <div className={styles.form.container}>
            <EvDivider marginVertical="2rem" />
            <EvPreviewFormRenderer
                header={staticData.contentForm.header}
                description={staticData.contentForm.description}
                formFields={staticData.contentForm.formFields}
                formData={contentFormData}
                setFormDataState={setContentFormData}
            />
            <EvDivider marginVertical="2rem" />
            <EvPreviewFormRenderer
                header={staticData.detailsForm.header}
                description={staticData.detailsForm.description}
                previewCta={staticData.detailsForm.previewCta}
                editCta={staticData.detailsForm.editCta}
                formFields={staticData.detailsForm.formFields}
                formData={detailsFormData}
                setFormDataState={setDetailsFormData}
                cardPreview={staticData.detailsForm.cardPreview}
                options={staticData.detailsForm.options}
            />
            <EvDivider marginVertical="2rem" />
            <EvPreviewFormRenderer
                header={staticData.eviveCardForm.header}
                description={staticData.eviveCardForm.description}
                formFields={staticData.eviveCardForm.formFields}
                formData={eviveCardFormData}
                setFormDataState={setEviveCardFormData}
            />
            <EvDivider marginVertical="2rem" />
            <EvPreviewFormRenderer
                header={staticData.calloutsForm.header}
                description={staticData.calloutsForm.description}
                formFields={staticData.calloutsForm.formFields}
                formData={calloutsFormData}
                setFormDataState={setCalloutsFormData}
                initialFormData={initialFormData}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                }}
                dataOptions={ctaDataList}
            />
            <EvDivider marginVertical="2rem" />
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionView}
            buttonText={staticData.submitCta.text}
            loadingStatus={submitStatus}
            onSubmitClick={onSubmit}
        />
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[CALLOUTS_TEMPLATES.CONTENT_STATIC.id],
            loadingStatus
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getConfigFormView()}
            {getActionView()}
        </div>
    );
};

CalloutsContent.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    location: PropTypes.object,
    getStaticData: PropTypes.func,
    goBackAction: PropTypes.func,
};

CalloutsContent.defaultProps = {
    controls: {},
    staticData: {},
    location: {},
    getStaticData: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    location: state.router.location,
    controls: state.CommonReducer[CALLOUTS_APP_ID].controls,
    staticData:
        state.CommonReducer[CALLOUTS_APP_ID].staticData[
            CALLOUTS_TEMPLATES.CONTENT_STATIC.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: CALLOUTS_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalloutsContent);
