import React, { memo } from "react";
import PropTypes from "prop-types";
import className from "classnames";

import { isNullOrEmpty, removeKeyFromObject } from "../../../utils/CommonUtils";

import { EvPills } from "..";

import "../../styles/form-components/ev-pills-input.scss";

const EvPillsInput = (props) => {
    const styles = {
        container: "ev__ev-pills-input__container",
        inputContainer: "ev__ev-pills-input__input-container",
        errorContainer: "ev__ev-pills-input__error-container",
        pillsWrapper: "ev__ev-pills-input__pills-wrapper",
    };

    const { id, formData, placeHolder, onFormDataChange, onValueClick } = props;

    const onFormDataChangeLocal = (key, formItemObject, formItemData) => {
        // remove and add new sub form data
        const newFormItemObject = { [id]: formItemData };
        onFormDataChange(id, newFormItemObject);
    };

    const onValueClickLocal = () => {
        onValueClick(id, formData);
    };

    const onRemoveIconClick = (e, callbackValues) => {
        const newFormData = removeKeyFromObject(callbackValues.id, formData);
        onFormDataChangeLocal(id, null, newFormData);
    };

    const getPillItem = (itemId) => {
        // empty item, probably removed
        if (isNullOrEmpty(formData[itemId])) {
            return <span />;
        }
        return (
            <EvPills
                key={itemId}
                id={itemId}
                className={styles.pillsWrapper}
                value={formData[itemId].name}
                iconName="CROSS_THICK"
                onIconClick={onRemoveIconClick}
                onValueClick={onValueClickLocal}
                callbackValues={{ id: itemId }}
            />
        );
    };

    const getPlaceholderPill = () => {
        if (placeHolder) {
            return (
                <EvPills
                    onValueClick={onValueClickLocal}
                    value={placeHolder}
                    className={styles.pillsWrapper}
                    textOptions={{ primaryColored: true, defaultBold: true }}
                />
            );
        }
        return <span />;
    };

    const getInputView = () => (
        <div className={styles.inputContainer}>
            {Object.keys(formData).map(getPillItem)}
            {/* show placeholder all the time */}
            {getPlaceholderPill()}
        </div>
    );

    // placeholder will be shown all the time for now
    // const getEmptyInputView = () => {
    //   if (placeHolder) {
    //     return (
    //       <div className={styles.inputContainer}>{getPlaceholderPill()}</div>
    //     );
    //   }
    //   return <div className={styles.inputContainer} />;
    // };

    const customContainerClass = className(styles.container, props.className);

    // placeholder will be shown all the time for now
    return (
        <div className={customContainerClass}>
            {getInputView()}
            {/* {Object.keys(formData).length > 0 ? getInputView() : getEmptyInputView()} */}
            {/* add error object if require later */}
        </div>
    );
};

EvPillsInput.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    formData: PropTypes.object,
    placeHolder: PropTypes.node,
    onValueClick: PropTypes.func,
    onFormDataChange: PropTypes.func,
};

EvPillsInput.defaultProps = {
    id: "",
    className: "",
    formData: {},
    placeHolder: "",
    onValueClick: () => {},
    onFormDataChange: () => {},
};

export default memo(EvPillsInput);
