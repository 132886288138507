import React, { Component } from "react";
import classNames from "classnames";

import { EvText, EvButton } from "./index";
import { setAnimationCallback } from "../../utils/AnimationHelper";

import "../styles/ev-simple-modal.scss";

// to reset data
const defaultModalState = {
    isVisible: false,
    isExiting: false,
    exitOnOverlayClick: true,
    exitOnActionClick: true,
    // allowBackgroundScroll: false,
};

// let scrollTop = 0;
// let scrollLeft = 0;

class EvSimpleModal extends Component {
    styles = {
        container: "ev__ev-simple-modal__container",
        overlay: "ev__ev-simple-modal__overlay",
        overlayExiting: "ev__ev-simple-modal__overlay-exiting",
        modal: {
            container: "ev__ev-simple-modal__modal-container",
            header: "ev__ev-simple-modal__modal-header",
            description: "ev__ev-simple-modal__modal-description",
            buttonContainer: "ev__ev-simple-modal__modal-button-container",
            buttonItem: "ev__ev-simple-modal__modal-button-item",
        },
    };

    modalContainerRef = React.createRef();

    constructor(props) {
        super(props);
        EvSimpleModal.singletonRef = this;
    }

    state = {
        isVisible: false,
        isExiting: false,

        // data: {
        //   header: "Modal Header",
        //   description:
        //     "If you remove this vendor program, you will have to add it again.",
        //   positiveButtonText: "Yes, remove this vendor program.",
        //   negativeButtonText: "No, keep this program.",
        // },
        //
        // onPositiveAction: () => {},
        // onNegativeAction: () => {},

        //  exitOnOverlayClick: false,
        //  exitOnActionClick: false,
        //  allowBackgroundScroll: false,
    };

    static setData = (dateObject) => {
        EvSimpleModal.singletonRef.setData(dateObject);
        return EvSimpleModal.singletonRef;
    };

    static show() {
        EvSimpleModal.singletonRef.show();
        return EvSimpleModal.singletonRef;
    }

    static hide() {
        EvSimpleModal.singletonRef.hide();
        return EvSimpleModal.singletonRef;
    }

    getDefaultIfUndefined = (otherData, keyName) =>
        otherData[keyName] === undefined
            ? defaultModalState[keyName]
            : otherData[keyName];

    showHideModal = (isVisible) => {
        this.setState({
            isVisible,
            isExiting: false,
        });
    };

    closeModalWithAnimation = (onCloseCallback) => {
        // window.removeEventListener("scroll", this.onMouseScroll);
        this.setState({
            isExiting: true,
        });
        setAnimationCallback(this.modalContainerRef, () => {
            this.showHideModal(false);
            if (onCloseCallback) {
                onCloseCallback();
            }
        });
    };

    // onMouseScroll = (e) => {
    //     if (!this.state.allowBackgroundScroll) {
    //         window.scrollTo(scrollLeft, scrollTop);
    //         e.preventDefault();
    //     }
    // };

    show = () => {
        this.showHideModal(true);
        // scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        // window.addEventListener("scroll", this.onMouseScroll, false);
    };

    hide = () => {
        this.closeModalWithAnimation();
    };

    setData = (dateObject) => {
        const { onNegativeAction, onPositiveAction, ...otherData } = dateObject;
        this.setState({
            // default state
            ...defaultModalState,

            data: {
                header: otherData.header || "",
                description: otherData.description || "",
                positiveButtonText: otherData.positiveButtonText || "",
                negativeButtonText: otherData.negativeButtonText || "",
            },
            onPositiveAction,
            onNegativeAction,

            // exitOnOverlayClick:
            //   otherData.exitOnOverlayClick === undefined
            //     ? defaultModalState.exitOnOverlayClick
            //     : otherData.exitOnOverlayClick, // set to default(true) if not passed explicitly

            exitOnOverlayClick: this.getDefaultIfUndefined(
                otherData,
                "exitOnOverlayClick"
            ),

            exitOnActionClick: this.getDefaultIfUndefined(
                otherData,
                "exitOnActionClick"
            ),

            // allowBackgroundScroll: this.getDefaultIfUndefined(
            //     otherData,
            //     "allowBackgroundScroll"
            // ),
        });
    };

    onPositiveAction = () => {
        if (this.state.exitOnActionClick) {
            this.closeModalWithAnimation(this.state.onPositiveAction);
        } else {
            this.state.onPositiveAction();
        }
    };

    onNegativeAction = () => {
        if (this.state.exitOnActionClick) {
            this.closeModalWithAnimation(this.state.onNegativeAction);
        } else {
            this.state.onNegativeAction();
        }
    };

    onOverlayClick = (e) => {
        if (
            this.modalContainerRef.current &&
            e.target === this.modalContainerRef.current &&
            this.state.exitOnOverlayClick
        ) {
            this.closeModalWithAnimation(this.state.onNegativeAction);
        }
    };

    getHeaderView = () => (
        <div className={this.styles.modal.header}>
            <EvText headingSecondary>{this.state.data.header}</EvText>
        </div>
    );

    getDescriptionView = () => (
        <div className={this.styles.modal.description}>
            <EvText>{this.state.data.description}</EvText>
        </div>
    );

    getActionableButtonsView = () => (
        <div className={this.styles.modal.buttonContainer}>
            {this.state.data.positiveButtonText && (
                <EvButton
                    onClickHandler={this.onPositiveAction}
                    className={this.styles.modal.buttonItem}
                    default
                >
                    {this.state.data.positiveButtonText}
                </EvButton>
            )}
            {this.state.data.negativeButtonText && (
                <EvButton
                    onClickHandler={this.onNegativeAction}
                    className={this.styles.modal.buttonItem}
                    default
                >
                    {this.state.data.negativeButtonText}
                </EvButton>
            )}
        </div>
    );

    getContent = () => (
        <div className={this.styles.modal.container}>
            {this.state.data.header && this.getHeaderView()}
            {this.state.data.description && this.getDescriptionView()}
            {this.getActionableButtonsView()}
        </div>
    );

    getModal = () => {
        const customOverlayClass = classNames(this.styles.overlay, {
            [this.styles.overlayExiting]: this.state.isExiting,
        });
        return (
            <div className={this.styles.container}>
                <div
                    onClick={this.onOverlayClick}
                    className={customOverlayClass}
                    ref={this.modalContainerRef}
                >
                    {this.getContent()}
                </div>
            </div>
        );
    };

    render() {
        return this.state.isVisible ? this.getModal() : null;
    }
}

export default EvSimpleModal;
