import React from "react";
import PropTypes from "prop-types";
import { NOTIFICATION_CALLOUT_PREVIEW_STATIC } from "../static/NotificationsStatics";

import "../styles/ev-notifications-callout-preview-card.scss";
import { EvIcon, EvSimpleCard, EvText } from "../../../common/components";

const styles = {
    container: "ev__ev-notifications-callout-preview-card__container",
    innerContainer:
        "ev__ev-notifications-callout-preview-card__inner-container",
    image: {
        container: "ev__ev-notifications-callout-preview-card__image-container",
    },
    content: {
        container:
            "ev__ev-notifications-callout-preview-card__content-container",
        typeContainer:
            "ev__ev-notifications-callout-preview-card__content-type-container",
        headerText:
            "ev__ev-notifications-callout-preview-card__content-header-text",
        descriptionText:
            "ev__ev-notifications-callout-preview-card__content-description-text",
    },
    newTag: {
        container:
            "ev__ev-notifications-callout-preview-card__new-tag-container",
        text: "ev__ev-notifications-callout-preview-card__new-tag-text",
        icon: "ev__ev-notifications-callout-preview-card__new-tag-icon",
    },
};

const EvNotificationsCalloutPreviewCard = (props) => {
    const { header, description, bgImage } = props;

    const getContentView = () => (
        <div className={styles.content.container}>
            <EvText defaultBold className={styles.content.headerText}>
                {header}
            </EvText>
            <EvText subHeading className={styles.content.descriptionText}>
                {description}
            </EvText>
        </div>
    );

    const getBackgroundImage = () => (
        <div
            className={styles.image.container}
            style={{ backgroundImage: `url(${bgImage})` }}
        />
    );

    return (
        <EvSimpleCard noPadding className={styles.container}>
            {getBackgroundImage()}
            <div className={styles.newTag.container}>
                <EvIcon addInnerPadding={false} iconName="NEW" size={2} />
                <div className={styles.newTag.text}>
                    {NOTIFICATION_CALLOUT_PREVIEW_STATIC.newTagText}
                </div>
            </div>
            <div className={styles.innerContainer}>{getContentView()}</div>
        </EvSimpleCard>
    );
};

EvNotificationsCalloutPreviewCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    bgImage: PropTypes.string,
    typeString: PropTypes.string,
};

EvNotificationsCalloutPreviewCard.defaultProps = {
    header: "",
    description: "",
    bgImage: "",
    typeString: "",
};

export default EvNotificationsCalloutPreviewCard;
