import React from "react";
import PropTypes from "prop-types";
import { EvIcon, EvSimpleCard, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/ev-notifications-preview-card.scss";

const styles = {
    container: "ev__ev-notifications-preview-card__container",
    innerContainer: "ev__ev-notifications-preview-card__inner-container",
    contentWrapper: "ev__ev-notifications-preview-card__content-wrapper",
    actionContainer: "ev__ev-notifications-preview-card__action-container",
    header: {
        container: "ev__ev-notifications-preview-card__header-container",
        text: "ev__ev-notifications-preview-card__header-text",
    },
    priority: {
        container: "ev__ev-notifications-preview-card__priority-container",
        text: "ev__ev-notifications-preview-card__priority-text",
    },
    description: {
        container: "ev__ev-notifications-preview-card__description-container",
        ageText: "ev__ev-notifications-preview-card__description-age-text",
    },
    dateView: {
        container: "ev__ev-notifications-preview-card__date-view-container",
        ageText: "ev__ev-notifications-preview-card__date-view-age-text",
    },
    takeAction: {
        container: "ev__ev-notifications-preview-card__take-action-container",
        text: "ev__ev-notifications-preview-card__take-action-text",
    },
    button: {
        container: "ev__ev-notifications-preview-card__button-container",
    },
};

const EvNotificationsPreviewCard = (props) => {
    const {
        header,
        description,
        buttonText,
        takeActionString,
        ageString,
        priorityString,
    } = props;

    const getPriorityPill = () => (
        <div className={styles.priority.container}>
            <EvIcon
                size={2}
                iconName="ALERT"
                bgColor={COLORS.PRODUCT}
                fillColor={COLORS.WHITE_MYSTIC}
                circular
            />
            <EvText className={styles.priority.text} defaultBold primaryColored>
                {priorityString}
            </EvText>
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingSecondary className={styles.header.text}>
                {header}
            </EvText>
            {priorityString && getPriorityPill()}
        </div>
    );

    const getTakeActionView = () => (
        <div className={styles.takeAction.container}>
            <EvIcon iconName="CLOCK" size={2} fillColor={COLORS.BLACK_TROUT} />
            <EvText defaultBold className={styles.takeAction.text}>
                {takeActionString}
            </EvText>
        </div>
    );

    const getDescriptionView = () => (
        <div className={styles.description.container}>
            <EvText>{description}</EvText>
        </div>
    );

    const getDateView = () => (
        <div className={styles.dateView.container}>
            <EvText className={styles.dateView.ageText}>{ageString}</EvText>
            {takeActionString && getTakeActionView()}
        </div>
    );

    const getContent = () => (
        <div className={styles.contentWrapper}>
            {getHeaderView()}
            {getDescriptionView()}
            {getDateView()}
        </div>
    );

    const getActionView = () => (
        <div className={styles.button.container}>
            <EvText defaultBold color={COLORS.WHITE}>
                {buttonText}
            </EvText>
        </div>
    );

    return (
        <EvSimpleCard noPadding>
            <div className={styles.innerContainer}>
                {getContent()}
                {buttonText && getActionView()}
            </div>
        </EvSimpleCard>
    );
};

EvNotificationsPreviewCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    ageString: PropTypes.string,

    // optional
    buttonText: PropTypes.string,
    takeActionString: PropTypes.string,
    priorityString: PropTypes.string,
};

EvNotificationsPreviewCard.defaultProps = {
    header: "",
    description: "",
    ageString: "",

    // optional
    buttonText: "",
    takeActionString: "",
    priorityString: "",
};

export default EvNotificationsPreviewCard;
