import VendorProgramsActionType from "./VendorProgramsActionTypes";

const initialState = {
    controls: {
        staticDataApiStatus: {},
        dynamicDataApiStatus: {},
        newVendorProgramSaved: false,
    },
    staticData: {},
    dynamicData: {},
};

const VendorProgramsReducer = (state = initialState, action) => {
    switch (action.type) {
        case VendorProgramsActionType.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };

        case VendorProgramsActionType.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case VendorProgramsActionType.SET_DYNAMIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case VendorProgramsActionType.SET_COMPONENT_DATA:
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    [action.payload.componentId]: action.payload.data,
                },
            };

        case VendorProgramsActionType.SET_STATIC_DATA:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.data,
                },
            };

        default:
            return state;
    }
};

export default VendorProgramsReducer;
