import SavedPopulationActionTypes from "./SavedPopulationActionTypes";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData } from "../service/SavedPopulationService";
// import { createAllTagsDictionary } from "../../../utils/SpecificDataModelUtils";
import API_URLS from "../../../services/apiUrls";
import EvLogger from "../../../utils/EvLogger";
import { EvToast } from "../../../common/components";
import { normalizerWithOrderArraySingleLevel } from "../../../utils/Normalizer";
import { SAVED_POPULATION_COMPONENTS } from "../static/SavedPopulationStatics";

const SavedPopulationActions = {
    setControls: (payload) => ({
        type: SavedPopulationActionTypes.SET_CONTROLS,
        payload,
    }),

    setStaticData: (payload) => ({
        type: SavedPopulationActionTypes.SET_TEMPLATE_DATA,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: SavedPopulationActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    setDynamicDataApiStatus: (payload) => ({
        type: SavedPopulationActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: SavedPopulationActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            !isNullOrEmpty(
                getState().SavedPopulationReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().SavedPopulationReducer.staticData[payload.templateId]
            ); // static data already loaded
        }
        dispatch(
            SavedPopulationActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        // return internal promise from then
        return getData(payload.url).then((response) => {
            dispatch(
                SavedPopulationActions.setStaticData({
                    templateId: payload.templateId,
                    staticData: response.data.data,
                })
            );
            dispatch(
                SavedPopulationActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    createTagsDictionary: () => (dispatch, getState) => {
        const clientCode = getState().router.location.state.clientCode || "";

        if (
            getState().SavedPopulationReducer.controls.dynamicDataApiStatus[
                SAVED_POPULATION_COMPONENTS.ALL_TAGS.id
            ] === LOADING_STATUS.COMPLETED
        ) {
            return;
        }

        dispatch(
            SavedPopulationActions.setDynamicDataApiStatus({
                [SAVED_POPULATION_COMPONENTS.ALL_TAGS.id]:
                    LOADING_STATUS.LOADING,
            })
        );

        getData(API_URLS.SAVED_POPULATION.ALL_TAGS, {
            clientCode,
        })
            .then((allTagsResponse) => {
                const normalisedTags = normalizerWithOrderArraySingleLevel(
                    allTagsResponse.data.data.tags
                );
                normalisedTags.ids.forEach((tagId) => {
                    normalisedTags.value[tagId] = {
                        ...normalisedTags.value[tagId],
                        tagRuleFields: {
                            ...(normalisedTags.value[tagId].tagRuleFields
                                ? normalisedTags.value[tagId].tagRuleFields
                                : {}),
                            mappedField: {
                                id: tagId,
                                name: normalisedTags.value[tagId].displayName,
                                value: tagId,
                            },
                        },
                    };
                });
                dispatch(
                    SavedPopulationActions.setComponentData({
                        componentId: SAVED_POPULATION_COMPONENTS.ALL_TAGS.id,
                        data: {
                            ...normalisedTags,
                            rawData: allTagsResponse.data.data.tags,
                        },
                    })
                );
                dispatch(
                    SavedPopulationActions.setDynamicDataApiStatus({
                        [SAVED_POPULATION_COMPONENTS.ALL_TAGS.id]:
                            LOADING_STATUS.COMPLETED,
                    })
                );
            })
            .catch((e) => {
                dispatch(
                    SavedPopulationActions.setDynamicDataApiStatus({
                        [SAVED_POPULATION_COMPONENTS.ALL_TAGS.id]:
                            LOADING_STATUS.FAILED,
                    })
                );
                EvToast.error("Sorry", "Something went wrong");
                EvLogger.errorWithObject(
                    e,
                    "SavedPopulationActions getTagDictionary"
                );
            });
    },

    resetData: (payload) => ({
        type: SavedPopulationActionTypes.RESET_DATA,
        payload,
    }),
};

export default SavedPopulationActions;
