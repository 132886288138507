import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { EvButton, EvIcon, EvLoader, EvText } from "../../../common/components";

import { EvFormRenderer } from "../../../common/components/form-components";
import { COLORS } from "../../../common/static/VmsStatics";
import { LOADING_STATUS } from "../../../common/static/Enums";

import "../styles/resource-card-view.scss";
import EvPreviewCardResolver from "../../../common/components/ev-cards/EvPreviewCardResolver";
import { parsePreviewVariablesFromFormData } from "../../../utils/FormUtils";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

const styles = {
    container: "ev__resource-card-view__container",
    header: {
        container: "ev__resource-card-view__header-container",
        descriptionText: "ev__resource-card-view__header-description-text",
    },
    formView: {
        container: "ev__resource-card-view__form-view-container",
        actionContainer: "ev__resource-card-view__form-view-action-container",
        actionIcon: "ev__resource-card-view__form-view-action-icon",
    },
    preview: {
        container: "ev__resource-card-view__preview-container",
        wrapper: "ev__resource-card-view__preview-wrapper",
    },
};

const ResourceCardView = (props) => {
    const {
        staticData,
        formData,
        initialFormData,
        vendorCardData,
        submitStatus,
        clientCode,

        setFormDataState,
        onFormSubmit,
    } = props;

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText subHeading dangerous>
                {stringTemplate(staticData.header, {
                    vendorName: vendorCardData.vendorName,
                    programName: vendorCardData.programName,
                })}
            </EvText>
            <EvText className={styles.header.descriptionText}>
                {staticData.description}
            </EvText>
        </div>
    );

    const getFormView = () => (
        <div className={styles.formView.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormDataState}
                initialFormData={initialFormData}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    cardId: vendorCardData.cardId,
                }}
            />
        </div>
    );

    const getStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                        size={3}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                        size={3}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionableView = () => (
        <div className={styles.formView.actionContainer}>
            <EvButton
                onClickHandler={onFormSubmit}
                primaryFilled={submitStatus !== LOADING_STATUS.LOADING}
                primaryFilledDisabled={submitStatus === LOADING_STATUS.LOADING}
            >
                {staticData.updateCta.text}
            </EvButton>
            <div className={styles.formView.actionIcon}>{getStatusIcon()}</div>
        </div>
    );

    const getPreviewSection = () => {
        const previewVariables = parsePreviewVariablesFromFormData(
            formData,
            staticData.cardPreview.contentKeys,
            staticData.cardPreview.staticContent
        );
        return (
            <div className={styles.preview.container}>
                <EvText default>{staticData.cardPreview.header}</EvText>
                <div className={styles.preview.wrapper}>
                    <EvPreviewCardResolver
                        previewType={staticData.cardPreview.previewType}
                        staticContent={staticData.cardPreview.staticContent}
                        previewVariables={previewVariables}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {getActionableView()}
            {!isNullOrEmpty(staticData.cardPreview) && getPreviewSection()}
        </div>
    );
};

ResourceCardView.propTypes = {
    staticData: PropTypes.object,
    formData: PropTypes.object,
    initialFormData: PropTypes.object,
    vendorCardData: PropTypes.object,
    submitStatus: PropTypes.string,
    clientCode: PropTypes.string,

    setFormDataState: PropTypes.func,
    onFormSubmit: PropTypes.func,
};

ResourceCardView.defaultProps = {
    clientCode: "",
    staticData: {},
    formData: {},
    initialFormData: {},
    vendorCardData: {},
    submitStatus: LOADING_STATUS.NOT_YET_STARTED,

    setFormDataState: () => {},
    onFormSubmit: () => {},
};

export default ResourceCardView;
