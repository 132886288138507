import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    EvBackButton,
    EvButton,
    EvHeaderView,
    EvLoadingPage,
    EvText,
} from "../../../common/components";
import { EvSimpleItemCard } from "../../../common/components/ev-cards";
import { LIVE_STATUS, LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import { getData } from "../service/GamedayScheduleService";
import {
    CLIENT_GAME_CARDS_TYPE,
    GAMEDAY_SCHEDULE_COMPONENTS,
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_STATIC,
    GAMEDAY_SCHEDULE_TEMPLATES,
} from "../static/GamedayScheduleStatics";
import "../styles/gameday-schedule-selection-view.scss";

const styles = {
    container: "ev__gameday-schedule-selection-view__container",
    header: {
        container: "ev__gameday-schedule-selection-view__header-container",
    },
    cards: {
        listContainer:
            "ev__gameday-schedule-selection-view__cards-list-container",
        button: "ev__gameday-schedule-selection-view__cards-button",
        wrapper: "ev__gameday-schedule-selection-view__cards-wrapper",
        wrapperDisabled:
            "ev__gameday-schedule-selection-view__cards-wrapper-disabled",
    },
};

const GamedayScheduleSelectionView = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        setControls,
        getStaticData,
        setDynamicDataApiStatus,
    } = props;

    const [gameList, setGameList] = useState([]);

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_SCHEDULE_COMPONENTS.GAME_LIST.id]: LOADING_STATUS.LOADING,
        });
        getStaticData({
            url: API_URLS.GAME_DAY_SCHEDULE.GAME_SELECTION.STATIC,
            templateId: GAMEDAY_SCHEDULE_TEMPLATES.SELECTION.id,
        });
        getData(API_URLS.GAME_DAY_SCHEDULE.GAME_SELECTION.GAME_LIST, {
            clientCode,
        })
            .then((gameListResponse) => {
                setGameList(gameListResponse.data.data.gameCards);
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.GAME_LIST.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.GAME_LIST.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(
                    e,
                    "GamedayScheduleSelectionView onLoad"
                );
            });
    }, [getStaticData, clientCode, setDynamicDataApiStatus]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_SCHEDULE_COMPONENTS.GAME_LIST.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onCardClick = useCallback(
        (e, callbackValues) => {
            setControls({
                currentPageId: GAMEDAY_SCHEDULE_PAGES.CONTENT.id,
                currentGameData: {
                    ...callbackValues,
                    status: LIVE_STATUS.DRAFT,
                },
            });
        },
        [setControls]
    );

    const onBackButtonClick = useCallback(() => {
        setControls({
            currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
        });
    }, [setControls]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackButtonClick}>
            {GAMEDAY_SCHEDULE_STATIC.backButtonText}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            className={styles.header.container}
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getGameCard = (gameCardData) => {
        const gameCategoryData =
            staticData.gameCategories[gameCardData.gameCategory];
        const isDisabled =
            gameCardData.gameStatus === CLIENT_GAME_CARDS_TYPE.UNCHECKED;
        const customWrapperClass = classNames(styles.cards.wrapper, {
            [styles.cards.wrapperDisabled]: isDisabled,
        });
        return (
            <EvButton
                key={gameCardData.gameId}
                onlyChild
                className={styles.cards.button}
                onClickHandler={onCardClick}
                disabledView={isDisabled}
                noClickCallbackOnDisabled={isDisabled}
                callbackValues={gameCardData}
            >
                <EvSimpleItemCard
                    className={customWrapperClass}
                    header={gameCardData.gameName}
                    subHeader={gameCategoryData.displayName || "Game"}
                    description={gameCardData.vendorProgramName}
                />
            </EvButton>
        );
    };

    const getGameListView = () => (
        <div className={styles.cards.listContainer}>
            {gameList.length > 0 ? (
                gameList.map(getGameCard)
            ) : (
                <EvText italics>{staticData.noGamesMessage}</EvText>
            )}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                GAMEDAY_SCHEDULE_TEMPLATES.SELECTION.id
            ],
            controls.dynamicDataApiStatus[
                GAMEDAY_SCHEDULE_COMPONENTS.GAME_LIST.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getGameListView()}
        </div>
    );
};

GamedayScheduleSelectionView.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

GamedayScheduleSelectionView.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    setControls: () => {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
    staticData:
        state.GamedayScheduleReducer.staticData[
            GAMEDAY_SCHEDULE_TEMPLATES.SELECTION.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(GamedayScheduleActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(GamedayScheduleActions.setDynamicDataApiStatus(payload)),
    getStaticData: (payload) =>
        dispatch(GamedayScheduleActions.getStaticData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleSelectionView);
