import React, { useState, useEffect, useCallback } from "react";

import {
    FORM_ITEMS,
    initialFormValue,
    MINIMAL_FORM_ITEMS,
    minimalFormInitialData,
    VALIDATED_FORM_ITEMS,
} from "../statics/EvLibraryFormStatics";
import { parseInitialStateFromFormFields } from "../../../utils/FormUtils";
import {
    EvFormRenderer,
    EvMiniFormRenderer,
} from "../../../common/components/form-components";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { EvDivider, EvText } from "../../../common/components";

import "../styles/ev-library-forms.scss";

const styles = {
    container: "ev__ev-library-form__container",
    formContainer: "ev__ev-library-form__form-container",
    validatedFormContainer: "ev__ev-library-form__validated-form-container",
    minimalFormContainer: "ev__ev-library-form__minimal-form-container",
    minimalFormWrapper: "ev__ev-library-form__minimal-form-wrapper",
};

const EvLibraryForms = () => {
    const [formData, setFormData] = useState({});
    const [validatedFormData, setValidatedFormData] = useState({});
    const [minimalFormData, setMinimalFormData] = useState({});

    useEffect(() => {
        setFormData(
            parseInitialStateFromFormFields(FORM_ITEMS, initialFormValue)
        );
        setValidatedFormData(
            parseInitialStateFromFormFields(VALIDATED_FORM_ITEMS)
        );
        setMinimalFormData(
            parseInitialStateFromFormFields(
                MINIMAL_FORM_ITEMS,
                minimalFormInitialData
            )
        );
    }, []);

    const onMinimalFormDataChange = useCallback(
        (key, formDataObject) => {
            const newFormData = {
                ...minimalFormData,
                ...formDataObject,
            };
            setMinimalFormData(newFormData);
        },
        [minimalFormData]
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formFields={FORM_ITEMS}
                formData={formData}
                setFormDataState={setFormData}
            />
        </div>
    );

    const getValidatedFormView = () => (
        <div className={styles.validatedFormContainer}>
            <EvText subHeading>Form Validations</EvText>
            <EvFormRenderer
                formData={validatedFormData}
                formFields={VALIDATED_FORM_ITEMS}
                setFormDataState={setValidatedFormData}
            />
        </div>
    );

    const getMinimalFormView = () => (
        <div className={styles.minimalFormContainer}>
            <EvText subHeading>Minimal Form View</EvText>
            <EvText>Dropdown and Tab separated input box</EvText>
            <div className={styles.minimalFormWrapper}>
                <EvMiniFormRenderer
                    formData={minimalFormData}
                    formItems={MINIMAL_FORM_ITEMS}
                    onFormDataChange={onMinimalFormDataChange}
                />
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            <EvText subHeading>Form Types</EvText>
            {!isNullOrEmpty(formData) && getFormView()}
            <EvDivider marginVertical={20} />
            {!isNullOrEmpty(validatedFormData) && getValidatedFormView()}
            <EvDivider marginVertical={20} />
            {!isNullOrEmpty(minimalFormData) && getMinimalFormView()}
        </div>
    );
};

export default EvLibraryForms;
