import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvIcon, EvSimpleCard, EvText } from "../index";

import "../../styles/ev-cards/ev-preview-checklist-card.scss";

const styles = {
    container: "ev__ev-preview-checklist-card__container",
    innerContainer: "ev__ev-preview-checklist-card__inner-container",
    header: {
        container: "ev__ev-preview-checklist-card__header-container",
    },
    list: {
        container: "ev__ev-preview-checklist-card__list-container",
        itemContainer: "ev__ev-preview-checklist-card__list-item-container",
        iconContainer:
            "ev__ev-preview-checklist-card__list-item-icon-container",
        itemText: "ev__ev-preview-checklist-card__list-item-text",
    },
};

const EvPreviewChecklistCard = (props) => {
    const {
        header,
        list,
        iconName,
        fillColor,
        bgColor,
        circularIcon,
        className,
    } = props;

    const getHeader = () => (
        <div className={styles.header.container}>
            <EvText defaultDarkBold>{header}</EvText>
        </div>
    );

    const getListItemView = (itemData) => {
        // if value is empty dont show check
        if (!itemData) {
            return <span />;
        }
        return (
            <div key={itemData} className={styles.list.itemContainer}>
                <EvIcon
                    outerContainerStyleClass={styles.list.iconContainer}
                    iconName={iconName}
                    fillColor={fillColor}
                    bgColor={bgColor}
                    circular={circularIcon}
                    size={2}
                />
                <EvText className={styles.list.itemText}>{itemData}</EvText>
            </div>
        );
    };

    const getListView = () => (
        <div className={styles.list.container}>{list.map(getListItemView)}</div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding>
                <div className={styles.innerContainer}>
                    {getHeader()}
                    {getListView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvPreviewChecklistCard.propTypes = {
    header: PropTypes.string,
    iconName: PropTypes.string,
    fillColor: PropTypes.string,
    bgColor: PropTypes.string,
    circularIcon: PropTypes.bool,
    list: PropTypes.array,

    className: PropTypes.string,
};

EvPreviewChecklistCard.defaultProps = {
    header: "",
    iconName: "CHECK",
    fillColor: "#fff",
    bgColor: "#1B67DA",
    circularIcon: true,
    list: [],

    className: "",
};

export default EvPreviewChecklistCard;
