import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { reservedKeywordCheck } from "../../../utils/FormUtils";
import { EvButton } from "../index";
import { guidGenerator, removeKeyFromObject } from "../../../utils/CommonUtils";
import FormValidation from "../../../utils/FormValidations";
import { EvInputErrorView } from "./index";

import "../../styles/form-components/ev-form-bullets.scss";

const styles = {
    container: "ev__ev-form-bullets__container",
    bulletContainer: "ev__ev-form-bullets__bullets-container",
    bullet: {
        wrapper: "ev__ev-form-bullets__bullet-wrapper",
        actionContainer: "ev__ev-form-bullets__bullet-action-container",
        textAreaContainer: "ev__ev-form-bullets__bullet-text-area-container",
        textArea: "ev__ev-form-bullets__bullet-text-area",
        input: "ev__ev-form-bullets__bullet-input",
    },
    actionContainer: "ev__ev-form-bullets__action-container",
};

const EvFormBullets = (props) => {
    const { keyName, formData, formItem, handleInputChange, showError } = props;

    const onAddBulletClick = useCallback(() => {
        const guid = guidGenerator();
        const newFormData = {
            ...formData,
            [guid]: {
                key: guid,
                value: "",
                type: formItem.type,
            },
            errorObject: {}, // will be overwritten later
            formItemType: formItem.type,
        };
        handleInputChange(keyName, {
            ...newFormData,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, newFormData),
            },
        });
    }, [formData, formItem, handleInputChange, keyName]);

    const onInputChange = useCallback(
        (e) => {
            const guid = e.target.getAttribute("guid");
            if (guid) {
                const newFormData = {
                    ...formData,
                    [guid]: {
                        key: guid,
                        value: e.target.value,
                        type: formItem.type,
                    },
                    errorObject: {}, // will be overwritten later
                    formItemType: formItem.type,
                };
                handleInputChange(keyName, {
                    ...newFormData,
                    errorObject: {
                        ...formData.errorObject,
                        ...FormValidation(formItem, newFormData),
                    },
                });
            }
        },
        [formData, formItem, handleInputChange, keyName]
    );

    const onRemoveBulletClick = useCallback(
        (e, callbackOptions) => {
            const { guid } = callbackOptions;
            if (guid) {
                const newFormData = removeKeyFromObject(guid, formData);
                handleInputChange(keyName, {
                    ...newFormData,
                    errorObject: {
                        ...formData.errorObject,
                        ...FormValidation(formItem, newFormData),
                    },
                });
            }
        },
        [formData, handleInputChange, keyName, formItem]
    );

    const getInputItem = (bulletKey, bulletValue) => {
        if (formItem.options && formItem.options.useTextBox) {
            return (
                <input
                    guid={bulletKey}
                    onChange={onInputChange}
                    className={styles.bullet.input}
                    value={bulletValue.value}
                />
            );
        }
        return (
            <textarea
                guid={bulletKey}
                onChange={onInputChange}
                className={styles.bullet.textArea}
                value={bulletValue.value}
            />
        );
    };

    const getBulletItem = ([bulletKey, bulletValue]) => {
        if (reservedKeywordCheck(bulletKey)) {
            return <span key={bulletKey} />;
        }
        return (
            <div key={bulletKey} className={styles.bullet.wrapper}>
                <div className={styles.bullet.textAreaContainer}>
                    {getInputItem(bulletKey, bulletValue)}
                </div>
                <div className={styles.bullet.actionContainer}>
                    <EvButton
                        iconButton
                        iconName={formItem.options.removeCta.iconName}
                        onClickHandler={onRemoveBulletClick}
                        callbackValues={{ guid: bulletKey }}
                    >
                        {formItem.options.removeCta.text}
                    </EvButton>
                </div>
            </div>
        );
    };

    const getBulletsView = () => (
        <div className={styles.bulletContainer}>
            {Object.entries(formData).map(getBulletItem)}
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionContainer}>
            <EvButton
                iconButton
                iconName={formItem.options.addCta.iconName}
                onClickHandler={onAddBulletClick}
            >
                {formItem.options.addCta.text}
            </EvButton>
        </div>
    );

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    return (
        <div className={styles.container}>
            {getBulletsView()}
            {getActionView()}
            {showError && getErrorView()}
        </div>
    );
};

EvFormBullets.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    handleInputChange: PropTypes.func,
    editable: PropTypes.bool,
    showError: PropTypes.bool,
};

EvFormBullets.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    handleInputChange: () => {},
    editable: true,
    showError: true,
};

export default EvFormBullets;
