import NotificationsActionTypes from "./NotificationsActionTypes";
import { NOTIFICATIONS_PAGES } from "../static/NotificationsStatics";

const initialState = {
    controls: {
        staticDataApiStatus: {},
        dynamicDataApiStatus: {},
        selectedPageId: NOTIFICATIONS_PAGES.DASHBOARD.id,
        // selectedPageId: NOTIFICATIONS_PAGES.NEW_POPULATION.id,
        tagSelectionCriteriaId: "", // tag selection page is for one criteria, selected in new population page
        currentNotificationPopulationId: "",
        currentNotificationId: "",
        isNewPopulation: false,
        notificationStatus: "",
    },
    staticData: {},
    dynamicData: {},
};

const NotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case NotificationsActionTypes.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };

        case NotificationsActionTypes.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case NotificationsActionTypes.SET_STATIC_DATA:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.staticData,
                },
            };

        case NotificationsActionTypes.SET_DYNAMIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case NotificationsActionTypes.SET_COMPONENT_DATA:
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    // [action.payload.componentId]: {
                    // clear previous data if it come as true
                    // ...(action.payload.clearPrevious
                    //   ? {}
                    //   : state.dynamicData[action.payload.componentId]),
                    // ...action.payload.data,
                    // },

                    // overwrite every time for now
                    [action.payload.componentId]: action.payload.data,
                },
            };

        case NotificationsActionTypes.SET_LANDING_DATA:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {},
                    selectedPageId: NOTIFICATIONS_PAGES.DASHBOARD.id,
                    tagSelectionCriteriaId: "",
                    currentNotificationPopulationId: "",
                    currentNotificationId: "",
                    isNewPopulation: false,
                    notificationStatus: "",
                },
                dynamicData: {},
            };

        case NotificationsActionTypes.RESET_ALL_DATA:
            return initialState;

        default:
            return state;
    }
};

export default NotificationsReducer;
