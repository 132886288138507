import ClientCardsActionTypes from "./ClientCardsActionTypes";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData } from "../service/ClientCardsService";

const ClientCardsActions = {
    setControls: (payload) => ({
        type: ClientCardsActionTypes.SET_CONTROLS,
        payload,
    }),

    setStaticData: (payload) => ({
        type: ClientCardsActionTypes.SET_STATIC_DATA,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: ClientCardsActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            !isNullOrEmpty(
                getState().ClientCardsReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().ClientCardsReducer.staticData[payload.templateId]
            ); // static data already loaded
        }
        dispatch(
            ClientCardsActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        // return internal promise from then
        return getData(payload.url).then((response) => {
            dispatch(
                ClientCardsActions.setStaticData({
                    templateId: payload.templateId,
                    staticData: response.data.data,
                })
            );
            dispatch(
                ClientCardsActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    setDynamicDataApiStatus: (payload) => ({
        type: ClientCardsActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: ClientCardsActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    // setLandingData: payload => ({
    //   type: ClientCardsActionTypes.SOFT_RESET,
    //   payload,
    // }),

    softReset: (payload) => ({
        type: ClientCardsActionTypes.SOFT_RESET,
        payload,
    }),

    resetAllData: (payload) => ({
        type: ClientCardsActionTypes.RESET_ALL_DATA,
        payload,
    }),
};

export default ClientCardsActions;
