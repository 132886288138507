import React, { memo } from "react";
import PropTypes from "prop-types";

import { EvCheckBox } from ".";

import "../styles/ev-check-list.scss";
import { isNullOrEmpty } from "../../utils/CommonUtils";

/**
 * CheckList component for VMS
 */
const EvCheckList = (props) => {
    const styles = {
        container: "ev__ev-check-list__container",
    };

    const { list, checkListData, checkListId, onCheckBoxSelection } = props;

    const handleCheckBox = (id, key, value, isChecked) => {
        const checkBoxObject = isChecked
            ? {
                  [key]: {
                      key,
                      id,
                      value,
                  },
              }
            : { [key]: {} };
        let updatedCheckListData = {
            ...checkListData,
            [checkListId]: {
                ...checkListData[checkListId],
                ...checkBoxObject,
            },
        };

        const removeKeyFromObject = (thisKey, { [thisKey]: _, ...rest }) =>
            rest;

        if (!isChecked) {
            updatedCheckListData = {
                ...updatedCheckListData,
                [checkListId]: removeKeyFromObject(
                    key,
                    updatedCheckListData[checkListId]
                ),
            };
        }

        onCheckBoxSelection(updatedCheckListData);
    };

    const isChecked = (key) =>
        checkListData[checkListId] &&
        !isNullOrEmpty(checkListData[checkListId][key]);

    const getEvCheckList = (item) => (
        <EvCheckBox
            keyName={item.key}
            key={item.key}
            id={item.id}
            value={item.value}
            label={item.label}
            handleCheckBox={handleCheckBox}
            isChecked={!!isChecked(item.key)}
        />
    );

    return <div className={styles.container}>{list.map(getEvCheckList)}</div>;
};

EvCheckList.propTypes = {
    /** an array of checkboxes to be created where each element has
     * key, id, value(must) and a label(optional) */
    list: PropTypes.array,

    /** checklist data from the parent */
    checkListData: PropTypes.object,

    /** checklist id */
    checkListId: PropTypes.string,

    /** function that triggers every time user checks or un-checks the checkbox */
    onCheckBoxSelection: PropTypes.func,
};

EvCheckList.defaultProps = {
    list: [],

    checkListData: {},

    checkListId: "",

    onCheckBoxSelection: () => {},
};

export default memo(EvCheckList);
