import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvSimpleCard, EvText } from "../index";

import "../../styles/ev-cards/ev-preview-question-card.scss";

const styles = {
    container: "ev__ev-preview-question-card__container",
    contentWrapper: "ev__ev-preview-question-card__content-wrapper",
    headerContainer: "ev__ev-preview-question-card__header-container",
    descriptionContainer: "ev__ev-preview-question-card__description-container",
};

const EvPreviewQuestionCard = (props) => {
    const { header, description, className } = props;

    const getHeaderView = () => (
        <div className={styles.headerContainer}>
            <EvText defaultDarkBold>{header}</EvText>
        </div>
    );

    const getDescriptionView = () => (
        <div className={styles.descriptionContainer}>
            <EvText>{description}</EvText>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding>
                <div className={styles.contentWrapper}>
                    {getHeaderView()}
                    {getDescriptionView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvPreviewQuestionCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
};

EvPreviewQuestionCard.defaultProps = {
    header: "",
    description: "",
    className: "",
};

export default EvPreviewQuestionCard;
