import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { LOADING_STATUS, ROUTES } from "../../../common/static/Enums";
import {
    CLIENT_DETAILS_COMPONENTS,
    CLIENT_DETAILS_TEMPLATES,
    EDIT_CLIENT_DETAILS_STATICS,
} from "../statics/ClientDashboardStatics";
import ClientDetailsActions from "../redux/ClientDetailsActions";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import { getData, putData } from "../service/ClientDetailsService";

import "../styles/client-details-edit.scss";
import ClientDetailsEditView from "./ClientDetailsEditView";
import {
    EvLoadingPage,
    EvSimpleModal,
    EvToast,
} from "../../../common/components";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";

// const styles = {};

const RE_LOGIN_DELAY = 5000;

const ClientDetailsEditContainer = (props) => {
    const {
        clientCode,
        staticData,
        clientData,
        getStaticData,
        setDynamicDataApiStatus,
        goBackAction,
    } = props;

    const [initialFormData, setInitialFormData] = useState({});
    const [formData, setFormData] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getPageStaticData = useCallback(
        () =>
            getStaticData({
                url: API_URLS.CLIENT_DETAILS.GET_CLIENT_DETAILS_STATIC_DATA,
                templateId: CLIENT_DETAILS_TEMPLATES.EDIT_CLIENT_STATIC.id,
            }),
        [getStaticData]
    );

    const getClientData = useCallback(() => {
        return new Promise((resolve) => {
            // new client
            if (!clientCode) {
                resolve({});
            }
            // client data already exist
            else if (!isNullOrEmpty(clientData)) {
                resolve(clientData);
            } else {
                getData(API_URLS.CLIENT_DETAILS.GET_CLIENT_DETAILS_DATA, {
                    clientCode,
                }).then((clientDataResponse) =>
                    resolve(clientDataResponse.data.data)
                );
            }
        });
    }, [clientCode, clientData]);

    useEffect(() => {
        Promise.all([getPageStaticData(), getClientData()]).then(
            ([staticDataResponse, clientDataResponse]) => {
                const parsedFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formFields,
                    clientDataResponse
                );
                setFormData(parsedFormData);
                setInitialFormData(clientDataResponse);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            }
        );
    }, [getPageStaticData, getClientData]);

    const onReLoginClick = useCallback(() => {
        window.location.href = ROUTES.LOGIN;
    }, []);

    const onFormSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", EDIT_CLIENT_DETAILS_STATICS.FORM_INVALID);
            return;
        }
        const formDataForApi = parseFormDataForApi(formData);
        setSubmitStatus(LOADING_STATUS.LOADING);
        putData(
            API_URLS.CLIENT_DETAILS.PUT_CLIENT_DETAILS,
            {},
            {
                clientCode: clientCode || "",
                ...formDataForApi,
            }
        )
            .then((response) => {
                EvToast.success("Saved", "", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setDynamicDataApiStatus({
                    [CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id]:
                        LOADING_STATUS.NOT_YET_STARTED,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);

                // Redirect user back to login page when creating a new client
                if (
                    !clientCode &&
                    !isNullOrEmpty(staticData.reLoginModalData)
                ) {
                    EvSimpleModal.setData({
                        ...staticData.reLoginModalData,
                        onPositiveAction: onReLoginClick,
                        exitOnOverlayClick: false,
                        // allowBackgroundScroll: false,
                        exitOnActionClick: false,
                    }).show();
                    setTimeout(onReLoginClick, RE_LOGIN_DELAY);
                } else {
                    goBackAction();
                }
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "ClientDetailsEditContainer onFormSubmit"
                );
            });
    }, [
        goBackAction,
        clientCode,
        setDynamicDataApiStatus,
        formData,
        onReLoginClick,
        staticData.reLoginModalData,
        submitStatus,
    ]);

    const onBackClick = useCallback(() => {
        goBackAction();
    }, [goBackAction]);

    if (!isCompleted(loadingStatus)) {
        return <EvLoadingPage />;
    }

    return (
        <ClientDetailsEditView
            formData={formData}
            initialFormData={initialFormData}
            formFields={staticData.formFields}
            staticData={staticData}
            setFormDataState={setFormData}
            onFormSubmit={onFormSubmit}
            submitStatus={submitStatus}
            clientCode={clientCode}
            onBackClick={onBackClick}
        />
    );
};

ClientDetailsEditContainer.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    clientData: PropTypes.object,
    controls: PropTypes.object,

    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    goBackAction: PropTypes.func,
};

ClientDetailsEditContainer.defaultProps = {
    clientCode: "",
    staticData: {},
    clientData: {},
    controls: {},

    getStaticData: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientDetailsReducer.controls,
    staticData:
        state.ClientDetailsReducer.staticData[
            CLIENT_DETAILS_TEMPLATES.EDIT_CLIENT_STATIC.id
        ],
    clientData:
        state.ClientDetailsReducer.dynamicData[
            CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientDetailsActions.getStaticData(payload)),
    setComponentData: (payload) =>
        dispatch(ClientDetailsActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(ClientDetailsActions.setDynamicDataApiStatus(payload)),
    goBackAction: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDetailsEditContainer);
