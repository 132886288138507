import React, { useEffect, useRef, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvText } from ".";

import "../styles/ev-tooltip.scss";

const EvTooltip = (props) => {
    const styles = {
        container: "ev__ev-tooltip__container",
        contentContainer: "ev__ev-tooltip__content-container",
        childrenWrapper: "ev__ev-tooltip__children-wrapper",
    };

    const {
        children,
        wrapperClassName,
        className,
        value,
        tooltipClassName,
        getCustomTooltipView,
        offsetTop,
        showTooltip,
    } = props;

    const wrapperRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({});

    const onMouseOver = useCallback(() => {
        setIsVisible(true);
        setTooltipPosition({
            left:
                wrapperRef.current.getBoundingClientRect().left +
                window.scrollX, // offset for scrolled window first
            top:
                wrapperRef.current.getBoundingClientRect().top +
                window.scrollY +
                offsetTop, // offset for scrolled window first, then lower it down a bit
        });
    }, [offsetTop, wrapperRef, setIsVisible]);

    const onMouseLeave = useCallback(() => {
        setIsVisible(false);
    }, [setIsVisible]);

    useEffect(() => {
        const currentRef = wrapperRef.current;
        currentRef.addEventListener("mouseover", onMouseOver);
        currentRef.addEventListener("mouseleave", onMouseLeave);
        return () => {
            currentRef.removeEventListener("mouseover", onMouseOver);
            currentRef.removeEventListener("mouseleave", onMouseLeave);
        };
    }, [onMouseOver, onMouseLeave, wrapperRef]);

    const getDefaultToolTipContent = () => {
        const customContentContainerClass = classNames(
            styles.contentContainer,
            tooltipClassName
        );
        return (
            <div className={customContentContainerClass}>
                <EvText>{value}</EvText>
            </div>
        );
    };

    const getTooltipView = () => {
        if (!isVisible || !showTooltip || !value) {
            return <span />;
        }
        const customContainerClass = classNames(styles.container, className);
        return (
            <div
                className={customContainerClass}
                style={{ left: tooltipPosition.left, top: tooltipPosition.top }}
            >
                {getCustomTooltipView
                    ? getCustomTooltipView()
                    : getDefaultToolTipContent()}
            </div>
        );
    };

    const getTooltipPortal = () =>
        createPortal(
            getTooltipView(),
            document.getElementById("portal-tooltip")
        );

    const childrenContainerCustomClass = classNames(
        styles.childrenWrapper,
        wrapperClassName
    );

    return (
        <div className={childrenContainerCustomClass} ref={wrapperRef}>
            {children}
            {getTooltipPortal()}
        </div>
    );
};

EvTooltip.propTypes = {
    children: PropTypes.node,
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    tooltipClassName: PropTypes.string,
    value: PropTypes.string,
    getCustomTooltipView: PropTypes.func,
    offsetTop: PropTypes.number,
    showTooltip: PropTypes.bool,
};

EvTooltip.defaultProps = {
    children: <span />,
    wrapperClassName: "",
    className: "",
    tooltipClassName: "",
    value: "",
    getCustomTooltipView: null,
    offsetTop: 20,
    showTooltip: true,
};

export default EvTooltip;
