import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvLoader } from "./index";
import { LOADING_STATUS } from "../static/Enums";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-submit-action-view.scss";

const styles = {
    container: "ev__ev-submit-action-view__container",
    labelSpacing: "ev__ev-submit-action-view__label-spacing",
    buttonContainer: "ev__ev-submit-action-view__button-container",
    buttonSpacing: "ev__ev-submit-action-view__button-spacing",
    button: "ev__ev-submit-action-view__button",
};

const EvSubmitActionView = (props) => {
    const {
        buttonText,
        onSubmitClick,
        className,
        buttonClass,
        loadingStatus,
        isDisabled,
        addLabelSpacing,
    } = props;

    const customContainerClass = classNames(styles.container, className);
    const customButtonContainerClass = classNames(styles.buttonContainer, {
        [styles.buttonSpacing]: addLabelSpacing,
    });
    const customButtonClass = classNames(styles.button, buttonClass);

    const getIcon = () => {
        switch (loadingStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={4} />;

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        iconName="ALERT"
                        size={4}
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        iconName="CHECK"
                        size={4}
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                );

            default:
                return <span />;
        }
    };

    return (
        <div className={customContainerClass}>
            {addLabelSpacing && <div className={styles.labelSpacing} />}
            <div className={customButtonContainerClass}>
                <EvButton
                    className={customButtonClass}
                    primaryFilled={!isDisabled}
                    primaryFilledDisabled={isDisabled}
                    noClickCallbackOnDisabled
                    onClickHandler={onSubmitClick}
                >
                    {buttonText}
                </EvButton>
                {getIcon()}
            </div>
        </div>
    );
};

EvSubmitActionView.propTypes = {
    buttonText: PropTypes.string,
    className: PropTypes.string,
    buttonClass: PropTypes.string,
    onSubmitClick: PropTypes.func,
    loadingStatus: PropTypes.string,
    isDisabled: PropTypes.bool,
    addLabelSpacing: PropTypes.bool,
};

EvSubmitActionView.defaultProps = {
    buttonText: "",
    className: "",
    buttonClass: "",
    onSubmitClick: () => {},
    loadingStatus: LOADING_STATUS.NOT_YET_STARTED,
    isDisabled: false,
    addLabelSpacing: false,
};

export default EvSubmitActionView;
