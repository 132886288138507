import API_URLS from "../../../services/apiUrls";
import { PRE_PROD_JSON, COMMON_RESPONSE } from "../statics/getPreProdDataJSON";
import { apiGet, apiMock, apiPost } from "../../../services/api";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        case API_URLS.PRE_PROD.STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, PRE_PROD_JSON.STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.PRE_PROD.CARD_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, PRE_PROD_JSON.CARD_LIST)
                : apiGet(url, params);

        case API_URLS.PRE_PROD.APPROVED_CARD_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, PRE_PROD_JSON.APPROVED_LIST)
                : apiGet(url, params);

        default:
            EvLogger.warn("PreProdService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.PRE_PROD.APPROVE_ALL:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        case API_URLS.PRE_PROD.APPROVE_CARD:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("PreProdService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
