import React, { useState, useCallback, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvPills, EvText } from "..";
import { COLORS } from "../../static/VmsStatics";
import { guidGenerator, removeKeyFromObject } from "../../../utils/CommonUtils";
import { KEY_CODES } from "../../static/Enums";
import {
    clearFormDataExceptKeywords,
    clearFormDataOfKeywords,
    reservedKeywordCheck,
} from "../../../utils/FormUtils";
import FormValidation from "../../../utils/FormValidations";

import "../../styles/form-components/ev-tsv-input.scss";

const styles = {
    container: "ev__ev-tsv-input__container",
    placeholderContainer: "ev__ev-tsv-input__placeholder-container",
    pills: {
        container: "ev__ev-tsv-input__pills-container",
        wrapper: "ev__ev-tsv-input__pills-wrapper",
        buttonWrapper: "ev__ev-tsv-input__pills-button-wrapper",
    },
    input: {
        wrapper: "ev__ev-tsv-input__input-wrapper",
        inputBox: "ev__ev-tsv-input__input-input-box",
        inputBoxExpanded: "ev__ev-tsv-input__input-input-box-expanded",
    },
};

const EvTsvInput = (props) => {
    const {
        keyName,
        formData,
        formItem,
        handleInputChange,
        separator,
        placeholder,
    } = props;
    const inputBoxRef = useRef(null);

    const changeFormValue = useCallback(
        (newFormDataObject) => {
            handleInputChange(keyName, {
                ...newFormDataObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, newFormDataObject),
                    isUsed: true,
                },
            });
        },
        [handleInputChange, formData, formItem, keyName]
    );

    const [editingPillId, setEditingPillId] = useState("");

    const setInputBoxFocus = useCallback(() => {
        // set timeout so that it gets focus only after rendering
        setTimeout(() => {
            if (inputBoxRef.current) {
                inputBoxRef.current.focus();
            }
        }, 100);
    }, [inputBoxRef]);

    const onInputChange = useCallback(
        (e) => {
            changeFormValue({
                ...formData,
                [editingPillId]: {
                    key: editingPillId,
                    value: e.target.value,
                    name: "",
                },
            });
        },
        [formData, editingPillId, changeFormValue]
    );

    const onClearClick = useCallback(() => {
        changeFormValue(clearFormDataExceptKeywords(formData));
    }, [formData, changeFormValue]);

    const onRemovePillClick = useCallback(
        (e, callbackValues) => {
            const newFormData = removeKeyFromObject(
                callbackValues.key,
                formData
            );
            changeFormValue(newFormData);
            setEditingPillId("");
        },
        [formData, changeFormValue]
    );

    const onInputBlur = useCallback(() => {
        if (!formData[editingPillId].value) {
            const newFormData = removeKeyFromObject(editingPillId, formData);
            changeFormValue(newFormData);
        }
        setEditingPillId("");
    }, [formData, editingPillId, changeFormValue]);

    const onPillValueClick = useCallback(
        (e, callbackValues) => {
            setEditingPillId(callbackValues.key);
            setInputBoxFocus();
        },
        [setInputBoxFocus]
    );

    const onAddButtonClick = useCallback(() => {
        const guid = guidGenerator();
        changeFormValue({
            ...formData,
            [guid]: {
                key: guid,
                value: "",
                name: "",
            },
        });
        setEditingPillId(guid);
        setInputBoxFocus();
    }, [formData, changeFormValue, setInputBoxFocus]);

    const onKeyDown = useCallback(
        (e) => {
            // if input key is a separator
            if (separator.indexOf(e.keyCode) !== -1) {
                if (formData[editingPillId].value) {
                    onAddButtonClick();
                }
                return false;
            }

            // for other cases
            switch (e.keyCode) {
                case KEY_CODES.ENTER:
                case KEY_CODES.ESC:
                    onInputBlur();
                    return false;

                default:
                    return true;
            }
        },
        [onAddButtonClick, editingPillId, formData, onInputBlur, separator]
    );

    const getAddButton = () => (
        <EvPills
            className={styles.pills.wrapper}
            value="Add"
            iconName="PLUS"
            onIconClick={onAddButtonClick}
            onValueClick={onAddButtonClick}
            textOptions={{ primaryColored: true, defaultBold: true }}
            iconOptions={{ fillColor: COLORS.PRODUCT }}
            iconOnLeft
        />
    );

    const getClearButton = () => (
        <EvPills
            className={styles.pills.wrapper}
            value="Clear all"
            textOptions={{ primaryColored: true, defaultBold: true }}
            onIconClick={onClearClick}
            onValueClick={onClearClick}
        />
    );

    const getPillInputItem = (pillData) => {
        const customInputClass = classNames(styles.input.inputBox, {
            [styles.input.inputBoxExpanded]: !!editingPillId,
        });
        return (
            <div key={pillData.key} className={styles.input.wrapper}>
                <input
                    ref={inputBoxRef}
                    className={customInputClass}
                    value={pillData ? pillData.value : ""}
                    onChange={onInputChange}
                    onBlur={onInputBlur}
                    onKeyDown={onKeyDown}
                />
            </div>
        );
    };

    const getPillsItem = (pillData) => (
        <EvPills
            key={pillData.key}
            className={styles.pills.wrapper}
            value={pillData.value}
            iconName="CROSS_THIN"
            onIconClick={onRemovePillClick}
            onValueClick={onPillValueClick}
            callbackValues={pillData}
            buttonClassName={styles.pills.buttonWrapper}
        />
    );

    const pillViewResolver = (pillData) => {
        // if this key reserved(errorObject, formItemType), dont render anything for this item
        if (reservedKeywordCheck(pillData[0])) {
            return <span key={pillData[0]} />;
        }
        if (pillData[0] === editingPillId) {
            return getPillInputItem(pillData[1]);
        }
        return getPillsItem(pillData[1]);
    };

    const getPlaceHolderView = () => (
        <EvButton
            onlyChild
            onClickHandler={onAddButtonClick}
            className={styles.placeholderContainer}
        >
            <EvText italics smallNormal>
                {placeholder}
            </EvText>
        </EvButton>
    );

    const getActionableButton = () => {
        const cleanFormData = clearFormDataOfKeywords(formData);
        if (Object.keys(cleanFormData).length > 0) {
            return (
                <Fragment>
                    {getAddButton()}
                    {getClearButton()}
                </Fragment>
            );
        }
        return getPlaceHolderView();
    };

    const getPillsView = () => (
        <div className={styles.pills.container}>
            {Object.entries(formData).map(pillViewResolver)}
            {/* buttons as well as placeholder based on logic */}
            {getActionableButton()}
        </div>
    );

    return <div className={styles.container}>{getPillsView()}</div>;
};

EvTsvInput.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func,
    separator: PropTypes.array,
};

EvTsvInput.defaultProps = {
    keyName: "TSV",
    placeholder: "[Enter values separated by comma]",
    handleInputChange: () => {},
    formItem: {
        id: "VALUES",
        type: "TSV_TEXT",
        label: "",
        description: "",
        placeholder: "[Enter values separated by tab]",
        editable: true,
        defaultValue: "",
    },
    separator: [KEY_CODES.TAB],
    formData: {
        // VALUES: { key: "", value: "", name: "" },
    },
};

export default EvTsvInput;
