import GamedayScheduleActionTypes from "./GamedayScheduleActionTypes";
import { GAMEDAY_SCHEDULE_PAGES } from "../static/GamedayScheduleStatics";

const initialState = {
    controls: {
        dynamicDataApiStatus: {},
        staticDataApiStatus: {},
        currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
        currentGameData: {
            // TEMPORARY
            // gameName: "Game ABC",
            // gameCategory: "MEMORY",
            // gameId: "LOST_LETTERS~gid_0784f765-66b0-4e2b-90d2-f67a91d537c0",
            // vendorProgramName: "Amul - Dairy Products",
            // gameStatus: "CHECKED",
            // status: "DRAFT",
        },
        scheduleId: "",
    },
    staticData: {},
    componentData: {},
};

const GamedayScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GamedayScheduleActionTypes.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };

        case GamedayScheduleActionTypes.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case GamedayScheduleActionTypes.SET_STATIC_DATA:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.staticData,
                },
            };

        case GamedayScheduleActionTypes.SET_DYNAMIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case GamedayScheduleActionTypes.SET_COMPONENT_DATA:
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    [action.payload.componentId]: action.payload.data,
                },
            };

        case GamedayScheduleActionTypes.SET_LANDING_DATA:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
                    currentGameData: {},
                    scheduleId: "",
                },
            };

        // only keep static data
        case GamedayScheduleActionTypes.RESET_ALL_DATA:
            return {
                ...initialState,
                controls: {
                    ...initialState.controls,
                    staticDataApiStatus: state.controls.staticDataApiStatus,
                    currentGameData: {},
                    scheduleId: "",
                },
                staticData: state.staticData,
            };

        default:
            return state;
    }
};

export default GamedayScheduleReducer;
