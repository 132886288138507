import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock, apiPost } from "../../../services/api";
import {
    getGamedayScheduleContentDataJSON,
    getGamedayScheduleDashboardDataJSON,
    getGamedayScheduleGameSelectionDataJSON,
    getGamedayScheduleTimeDataJSON,
    getGamedayScheduleSummaryDataJSON,
} from "../static/getGamedayScehduleDataJSON";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // dashboard
        case API_URLS.GAME_DAY_SCHEDULE.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleDashboardDataJSON.STATIC
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_SCHEDULE.DASHBOARD.SCHEDULED_GAMES:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleDashboardDataJSON.SCHEDULED_GAMES
                  )
                : apiGet(url, params);

        // game selection
        case API_URLS.GAME_DAY_SCHEDULE.GAME_SELECTION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleGameSelectionDataJSON.STATIC
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_SCHEDULE.GAME_SELECTION.GAME_LIST:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleGameSelectionDataJSON.GAME_LIST
                  )
                : apiGet(url, params);

        // content
        case API_URLS.GAME_DAY_SCHEDULE.CONTENT.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getGamedayScheduleContentDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.GAME_DAY_SCHEDULE.CONTENT.PREFILL:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleContentDataJSON.PREFILL
                  )
                : apiGet(url, params);

        // time
        case API_URLS.GAME_DAY_SCHEDULE.TIME.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getGamedayScheduleTimeDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.GAME_DAY_SCHEDULE.TIME.PREFILL:
            return USE_MOCK_DATA
                ? apiMock(url, params, getGamedayScheduleTimeDataJSON.PREFILL)
                : apiGet(url, params);

        // summary
        case API_URLS.GAME_DAY_SCHEDULE.SUMMARY.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getGamedayScheduleSummaryDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.GAME_DAY_SCHEDULE.SUMMARY.PREFILL:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGamedayScheduleSummaryDataJSON.PREFILL
                  )
                : apiGet(url, params);

        default:
            EvLogger.warn("GamedayScheduleService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.GAME_DAY_SCHEDULE.TIME.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getGamedayScheduleTimeDataJSON.SUBMIT,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.GAME_DAY_SCHEDULE.SUMMARY.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getGamedayScheduleSummaryDataJSON.SUBMIT,
                      data
                  )
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("GamedayScheduleService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
