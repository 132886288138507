import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import DashboardActions from "../redux/DashboardActions";
import {
    DASHBOARD_LOADING_MESSAGES,
    DASHBOARD_TEMPLATES,
} from "../static/DashboardStatics";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvButton,
    EvLoadingPage,
    EvTable,
    EvText,
} from "../../../common/components";
import { LOADING_STATUS, ROUTES } from "../../../common/static/Enums";
import { getData } from "../services/DashboardServices";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/users-list-view.scss";

const styles = {
    loadingPage: "ev__users-list-view__loading-page",
    container: "ev__users-list-view__container",
    header: {
        container: "ev__users-list-view__header-container",
        button: "ev__users-list-view__header-button",
    },
    infoMessage: "ev__users-list-view__info-message",
    userTable: "ev__users-list-view__user-table",
};

const UsersListView = (props) => {
    const { controls, staticData, getStaticData, navigateToPage } = props;

    const [userList, setUserList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [otherData, setOtherData] = useState({});

    useEffect(() => {
        getStaticData({
            url: API_URLS.USERS.LIST_STATIC,
            templateId: DASHBOARD_TEMPLATES.USERS_LIST_STATICS.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        getData(API_URLS.USERS.USERS_LIST)
            .then((userListResponse) => {
                setUserList(userListResponse.data.data.users);
                setOtherData({
                    addUserEnabled: userListResponse.data.data.addUserEnabled,
                });
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
            });
    }, []);

    const onUserClick = useCallback(
        (e, callbackValues) => {
            navigateToPage({
                path: ROUTES.USER,
                state: {
                    editUserId: callbackValues.id,
                },
            });
        },
        [navigateToPage]
    );

    const onAddUserClick = useCallback(() => {
        navigateToPage({
            path: ROUTES.USER,
        });
    }, [navigateToPage]);

    // addUserEnabled is only temporary till permission engine is in place.
    // DO NOT USE THIS IMPLEMENTATION IN OTHER PLACES
    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
            {otherData.addUserEnabled && (
                <EvButton
                    primary
                    className={styles.header.button}
                    iconName={staticData.addUserCta.iconName}
                    iconFillColor={COLORS.PRODUCT}
                    onClickHandler={onAddUserClick}
                >
                    {staticData.addUserCta.text}
                </EvButton>
            )}
        </div>
    );

    const getUserList = () => {
        if (userList.length <= 0) {
            return (
                <div className={styles.infoMessage}>
                    <EvText italics>{staticData.noUsersMessage}</EvText>
                </div>
            );
        }
        return (
            <EvTable
                className={styles.userTable}
                tableFields={staticData.userListTable.tableFields}
                tableData={userList}
                collapsible
                loadMoreCta={staticData.userListTable.loadMoreCta}
                isRowClickable
                onRowClick={onUserClick}
            />
        );
    };

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                DASHBOARD_TEMPLATES.USERS_LIST_STATICS.id
            ],
            loadingStatus
        )
    ) {
        return (
            <EvLoadingPage
                className={styles.loadingPage}
                loadingText={DASHBOARD_LOADING_MESSAGES.USERS}
                animatedFadeIn
            />
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getUserList()}
        </div>
    );
};

UsersListView.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
};

UsersListView.defaultProps = {
    controls: {},
    staticData: {},
    getStaticData: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.DashboardReducer.controls,
    staticData:
        state.DashboardReducer.staticData[
            DASHBOARD_TEMPLATES.USERS_LIST_STATICS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(DashboardActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListView);
