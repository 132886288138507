import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvIcon } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-button.scss";
/**
 * Ev Button with different types used across VMS
 */
const EvButton = (props) => {
    const styles = {
        // container: "ev__ev-button__container",
        buttonItem: "ev__ev-button__button-item",
        primary: "ev__ev-button__primary",
        primaryFilled: "ev__ev-button__primary-filled",
        primaryFilledDisabled: "ev__ev-button__primary-filled-disabled",
        disabledView: "ev__ev-button__disabled-view",
        default: "ev__ev-button__default",
        secondary: "ev__ev-button__secondary",
        negativeAction: "ev__ev-button__negative-action",
        textButton: "ev__ev-button__text-button",
        underlined: "ev__ev-button__underlined",
        linkButton: "ev__ev-button__link-button",
        thickButton: "ev__ev-button__thick-button",
        onlyChild: "ev__ev-button__only-child",
        iconButton: "ev__ev-button__icon-button",
        iconViewContainer: "ev__ev-button__icon-view-container",
        countViewContainer: "ev__ev-button__count-view-container",
        countWrapper: "ev__ev-button__count-wrapper",
        countWrapperPrimary: "ev__ev-button__count-wrapper-primary",
        countWrapperPrimaryFilled:
            "ev__ev-button__count-wrapper-primary-filled",
        countWrapperSecondary: "ev__ev-button__count-wrapper-secondary",
        countWrapperDefault: "ev__ev-button__count-wrapper-default",
    };

    const {
        className,
        noClickCallbackOnDisabled,
        onClickHandler,
        callbackValues,
        iconName,
        iconFillColor,
        children,
        count,
    } = props;

    const customStyle = classNames(
        styles.buttonItem,
        {
            [styles.primary]: props.primary,
            [styles.primaryFilled]: props.primaryFilled,
            [styles.primaryFilledDisabled]: props.primaryFilledDisabled,
            [styles.disabledView]: props.disabledView,
            [styles.default]: props.default,
            [styles.secondary]: props.secondary,
            [styles.negativeAction]: props.negativeAction,
            [styles.textButton]: props.textButton,
            [styles.underlined]: props.underlined,
            [styles.linkButton]: props.linkButton,
            [styles.thickButton]: props.thickButton,
            [styles.onlyChild]: props.onlyChild,
            [styles.iconButton]: props.iconButton,
        },
        className
    );

    const onClickHandlerLocal = (e) => {
        // no callback on disabled
        if (noClickCallbackOnDisabled && props.primaryFilledDisabled) {
            return;
        } else if (noClickCallbackOnDisabled && props.disabledView) {
            return;
        }
        onClickHandler(e, callbackValues);
    };

    const getIconView = () => (
        <div className={styles.iconViewContainer}>
            <EvIcon iconName={iconName} size={3} fillColor={iconFillColor} />
            {children}
        </div>
    );

    const getCountView = () => {
        const countWrapperClass = classNames(styles.countWrapper, {
            [styles.countWrapperPrimary]: props.primary,
            [styles.countWrapperPrimaryFilled]: props.primaryFilled,
            [styles.countWrapperSecondary]: props.secondary,
            [styles.countWrapperDefault]: props.default,
        });
        return (
            <div className={styles.countViewContainer}>
                {children}
                <div className={countWrapperClass}>{count}</div>
            </div>
        );
    };

    const getButtonContent = () => {
        if (iconName) {
            return getIconView();
        } else if (count) {
            return getCountView();
        }
        return children;
    };

    return (
        <button
            type="button"
            className={customStyle}
            style={props.buttonInlineStyle}
            onClick={onClickHandlerLocal}
        >
            {getButtonContent()}
        </button>
    );
};

EvButton.propTypes = {
    /** node elements to render */
    children: PropTypes.node.isRequired,
    count: PropTypes.number,

    /** inline styling to overwrite everything else */
    buttonInlineStyle: PropTypes.object,
    /** classname for custom style */
    className: PropTypes.string,
    /** will not call onclick handler for disabled states */
    noClickCallbackOnDisabled: PropTypes.bool,
    /** shows disabled view with no highlight or pointer */
    disabledView: PropTypes.bool,

    /** shows primary type button, bordered with shadow */
    primary: PropTypes.bool,
    /** shows primary color filled button, with white text */
    primaryFilled: PropTypes.bool,
    /** corresponding disabled button for primary filled */
    primaryFilledDisabled: PropTypes.bool,

    /** shows default type button. This is not set by default */
    default: PropTypes.bool,
    /** secondary type button */
    secondary: PropTypes.bool,
    /** negativeAction type button */
    negativeAction: PropTypes.bool,
    /** shows only text, looks like a hyperlink */
    textButton: PropTypes.bool,
    /** use along with textButton to underline */
    underlined: PropTypes.bool,
    /** to make entire component clickable */
    onlyChild: PropTypes.bool,
    /** to add icon on the left side of the text */
    iconButton: PropTypes.bool,
    linkButton: PropTypes.bool,
    thickButton: PropTypes.bool,
    iconName: PropTypes.string,
    iconFillColor: PropTypes.string,

    /** this object gets returned on clicking of button as second param after e */
    callbackValues: PropTypes.object,
    /** on click handler  */
    onClickHandler: PropTypes.func,
};

EvButton.defaultProps = {
    buttonInlineStyle: {},
    className: "",
    count: null,

    noClickCallbackOnDisabled: false,
    disabledView: false,

    primary: false,
    primaryFilled: false,
    primaryFilledDisabled: false,

    default: false,
    secondary: false,
    negativeAction: false,
    textButton: false,
    underlined: false,
    linkButton: false,
    onlyChild: false,
    thickButton: false,

    iconButton: false,
    iconName: "",
    iconFillColor: COLORS.BLACK_TROUT,

    callbackValues: {},
    onClickHandler: () => {},
};

export default memo(EvButton);
