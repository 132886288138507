import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push, replace } from "connected-react-router";

import { EvUserBar, EvLoadingPage } from "../../../common/components";
import EvFooter from "./EvFooter";

import { AppContainerContext } from "../../../contexts/Context";
import AppContainerActions from "../redux/AppContainerActions";
import { getUserBarDataSelector } from "../redux/AppContainerSelector";
import { isCompleted } from "../../../utils/CommonUtils";

import "../styles/app-container.scss";
import "../../../sass/animations.scss";

const AppContainer = (props) => {
    const styles = {
        container: "ev__app-container__container",
        header: "ev__app-container__header-container",
        content: "ev__app-container__content-container",
        footer: "ev__app-container__footer-container",
    };

    const [secondaryUserBar, setSecondaryUserBar] = useState(null);
    const [userBarIconsData, setUserBarIconsData] = useState({});

    const setSecondaryUserBarView = (view) => {
        setSecondaryUserBar(view);
    };

    const {
        children,
        controls,
        userBarData,
        dropdownData,
        fetchData,
        navigationReplace,
        navigateToPage,
    } = props;

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const setUserBarIconsDataToState = useCallback(
        (data) => {
            setUserBarIconsData({
                ...userBarIconsData,
                ...data,
            });
        },
        [userBarIconsData]
    );

    const isPageLoading = () =>
        !isCompleted(
            controls.staticDataLoadingStatus,
            controls.userDataLoadingStatus
        );

    const getUserBar = () => (
        <div className={styles.header}>
            <EvUserBar
                userBarData={userBarData}
                dropdownData={dropdownData}
                navigationReplace={navigationReplace}
                userIconData={userBarIconsData}
                navigateToPage={navigateToPage}
            />
            {secondaryUserBar}
        </div>
    );

    const getFooter = () => (
        <div className={styles.footer}>
            <EvFooter />
        </div>
    );

    const getContent = () => (
        <div className={styles.container}>
            {getUserBar()}
            <AppContainerContext.Provider
                value={{
                    setSecondaryUserBarView,
                    setUserBarIconsData: setUserBarIconsDataToState,
                }}
            >
                <div className={styles.content}>{children}</div>
            </AppContainerContext.Provider>
            {getFooter()}
        </div>
    );

    const getLoadingPage = () => (
        <div className={styles.container}>
            <EvLoadingPage animatedFadeIn />
        </div>
    );

    return isPageLoading() ? getLoadingPage() : getContent();
};

AppContainer.propTypes = {
    userBarData: PropTypes.object,
    dropdownData: PropTypes.array,
    controls: PropTypes.object,

    children: PropTypes.node,

    fetchData: PropTypes.func,
    navigationReplace: PropTypes.func,
    navigateToPage: PropTypes.func,
};

AppContainer.defaultProps = {
    userBarData: {},
    dropdownData: [],
    controls: {},

    children: null,

    fetchData: () => {},
    navigationReplace: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.AppContainerReducer.controls,
    userBarData: getUserBarDataSelector(state),
    dropdownData: state.AppContainerReducer.staticData.userNavData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => {
        dispatch(AppContainerActions.fetchData());
    },
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    navigationReplace: (payload) => {
        dispatch(replace(payload.action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
