import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    EvButton,
    EvLoader,
    EvSimpleCard,
    EvText,
} from "../../../common/components";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import { VENDOR_PROGRAMS_PAGES } from "../../vendor-programs/statics/VendorProgramsStatics";
import { getData } from "../service/ClientDetailsService";
import "../styles/client-dashboard-vendor-cards.scss";

const styles = {
    container: "ev__client-dashboard-vendor-cards__container",
    loading: {
        container: "ev__client-dashboard-vendor-cards__loading-container",
        text: "ev__client-dashboard-vendor-cards__loading-text",
    },
    header: {
        container: "ev__client-dashboard-vendor-cards__header-container",
    },
    content: {
        container: "ev__client-dashboard-vendor-cards__content-container",
        innerContainer:
            "ev__client-dashboard-vendor-cards__content-inner-container",
        vendorContainer:
            "ev__client-dashboard-vendor-cards__content-vendor-container",
        vendorWrapper:
            "ev__client-dashboard-vendor-cards__content-vendor-wrapper",
        vendorName: "ev__client-dashboard-vendor-cards__content-vendor-name",
        vendorLogo: "ev__client-dashboard-vendor-cards__content-vendor-logo",
    },
    actionView: {
        container: "ev__client-dashboard-vendor-cards__action-view-container",
        button: "ev__client-dashboard-vendor-cards__action-view-button",
    },
};

const MIN_CARD_HEIGHT = 100;
const VISIBLE_VENDOR_COUNT = 5;

const ClientDashboardVendorCard = (props) => {
    const { staticData, clientCode, goToPage } = props;

    const contentContainerRef = useRef(0);

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [vendorList, setVendorList] = useState([]);
    const [cardHeight, setCardHeight] = useState(MIN_CARD_HEIGHT);

    const calculateCardHeight = useCallback(() => {
        setCardHeight(contentContainerRef.current?.clientHeight || 0);
    }, []);

    useEffect(() => {
        getData(API_URLS.CLIENT_DASHBOARD.VENDOR_CARD_DATA, { clientCode })
            .then((vendorDataResponse) => {
                setVendorList(
                    vendorDataResponse.data.data.preSelected.slice(
                        0,
                        VISIBLE_VENDOR_COUNT
                    )
                );
                setLoadingStatus(LOADING_STATUS.COMPLETED);
                calculateCardHeight();
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "ClientDashboardVendorCard getData"
                );
            });
    }, [clientCode, calculateCardHeight]);

    const onCtaClick = useCallback(
        (e, { path }) => {
            goToPage(path);
        },
        [goToPage]
    );

    const onVendorClick = useCallback(
        (e, callbackOptions) => {
            goToPage(VENDOR_PROGRAMS_PAGES.CUSTOMIZE_PROGRAMS.path, {
                vendorCardData: {
                    vendorId: callbackOptions.vendorId,
                    vendorName: callbackOptions.vendorName,
                    programId: callbackOptions.programId,
                    programName: callbackOptions.programName,
                    vendorType: callbackOptions.vendorType,
                },
            });
        },
        [goToPage]
    );

    const getLoadingView = () => (
        <div
            className={styles.loading.container}
            style={{ height: MIN_CARD_HEIGHT }}
        >
            <EvLoader />
            <EvText italics className={styles.loading.text}>
                {staticData.loadingText}
            </EvText>
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primary
                className={styles.actionView.button}
                onClickHandler={onCtaClick}
                callbackValues={{ path: VENDOR_PROGRAMS_PAGES.DASHBOARD.path }}
            >
                {staticData.cta.text}
            </EvButton>
            {/*<EvButton*/}
            {/*  primary*/}
            {/*  className={styles.actionView.button}*/}
            {/*  onClickHandler={onCtaClick}*/}
            {/*  callbackValues={{ path: CLIENT_DETAILS_ROUTES.CLIENT_BENEFITS.path }}*/}
            {/*>*/}
            {/*  {staticData.summaryCta.text}*/}
            {/*</EvButton>*/}
        </div>
    );

    const getVendorCard = (vendorData) => (
        <EvButton
            onlyChild
            className={styles.content.vendorWrapper}
            onClickHandler={onVendorClick}
            callbackValues={vendorData}
            key={`${vendorData.vendorId}-${vendorData.programId}`}
        >
            <EvText className={styles.content.vendorName} defaultDark>
                {`${vendorData.vendorName} - ${vendorData.programName}`}
            </EvText>
            <img
                alt={vendorData.vendorName}
                src={vendorData.programLogo}
                className={styles.content.vendorLogo}
            />
        </EvButton>
    );

    const getVendorListView = () => (
        <div className={styles.content.vendorContainer}>
            {vendorList.length > 0 ? (
                vendorList.map(getVendorCard)
            ) : (
                <EvText italics>{staticData.noVendorText}</EvText>
            )}
        </div>
    );

    const getContent = () => {
        if (!isCompleted(loadingStatus)) {
            return getLoadingView();
        }
        return (
            <div
                className={styles.content.container}
                style={{ height: cardHeight }}
            >
                <div
                    ref={contentContainerRef}
                    className={styles.content.innerContainer}
                >
                    {getHeaderView()}
                    {getVendorListView()}
                    {getActionView()}
                </div>
            </div>
        );
    };

    return (
        <EvSimpleCard noPadding className={styles.container}>
            {getContent()}
        </EvSimpleCard>
    );
};

ClientDashboardVendorCard.propTypes = {
    staticData: PropTypes.object,
    clientCode: PropTypes.string,
    goToPage: PropTypes.func,
};

ClientDashboardVendorCard.defaultProps = {
    staticData: {
        // header: "Vendor Programs",
        // loadingText: "Loading Vendors",
        // noVendorText: "No vendor program selected",
        // cta: { text: "See all vendors programs" },
    },
    clientCode: "",
    goToPage: () => {},
};

export default ClientDashboardVendorCard;
