// import getVendorProgramContentJSON from "../../features/vendor-program-content/statics/getVendorProgramContentJSON";
import { getCalloutsDataJSON } from "../../features/callouts/static/getCalloutDataJSON";
import getCardsDashboardDataJSON from "../../features/cards-dashboard/statics/getCardsDashboardDataJSON";
import getCurrentStatusDataJSON from "../../features/current-status/statics/getCurrentStatusDataJSON";
import getEvive360DataJSON from "../../features/evive-360/statics/getEvive360DataJSON";
import getGameDayContentDataJSON from "../../features/gameday-content/statics/getGamedayContentDataJSON";
import getResourceCardJSON from "../../features/resource-card/statics/getResourceCardJSON";
import getWhereToDataJSON from "../../features/where-to/statics/getWhereToDataJSON";
import {
    CLIENT_SELECTION_MOCK_DATA,
    PROGRAM_SELECTION_MOCK_DATA,
    SUMMARY_MOCK_DATA,
} from "../../features/enterprise-notifications/static/mock-data/getEnterpriseNotificationsData";
import { apiGet, apiMock, apiPost } from "../../services/api";
import API_URLS from "../../services/apiUrls";
import {
    getContentLazyTextData,
    getCriteriaBuilderRefIdData,
    getCriteriaBuilderValue,
    getLazyClientsForUser,
    getLazyDropdownListData,
} from "../static/CommonApiJSON";
import {
    LAZY_MODAL_NOTIFICATION_CONTENT,
    LAZY_MODAL_USER_MAPPING,
} from "../static/LazyModalJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // lazy modal selection
        case API_URLS.COMMON.LAZY_MODAL_SELECTION_USER_MAPPING_STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, LAZY_MODAL_USER_MAPPING.STATIC)
                : apiGet(url, params);

        case API_URLS.COMMON.LAZY_MODAL_SELECTION_USER_MAPPING_LIST_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, LAZY_MODAL_USER_MAPPING.LIST_DATA)
                : apiGet(url, params);

        case API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, LAZY_MODAL_NOTIFICATION_CONTENT.STATIC)
                : apiGet(url, params);

        case API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT.LIST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      LAZY_MODAL_NOTIFICATION_CONTENT.LIST_DATA
                  )
                : apiGet(url, params);

        case API_URLS.COMMON.GET_LAZY_DROPDOWN_LIST_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getLazyDropdownListData)
                : apiGet(url, params);

        case API_URLS.COMMON.GET_LAZY_CLIENTS_FOR_USERS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getLazyClientsForUser)
                : apiGet(url, params);

        case API_URLS.COMMON.CRITERIA_BUILDER.GET_VALUE:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCriteriaBuilderValue)
                : apiGet(url, params);

        // // dynamic data: tag selection
        // case API_URLS.SAVED_POPULATION.ALL_TAGS:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.ALL_TAGS_DATA)
        //     : apiGet(url, params);

        // case API_URLS.NOTIFICATIONS.TAG_SELECTION.ALL_TAGS:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.COMBINED_TAGS)
        //     : apiGet(url, params);

        // case API_URLS.NOTIFICATIONS.TAG_SELECTION.LINKED_TAGS:
        // case API_URLS.SAVED_POPULATION.TAGS.LINKED_TAGS:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.PRESET_TAGS)
        //     : apiGet(url, params);
        //
        // case API_URLS.NOTIFICATIONS.TAG_SELECTION.CUSTOM_TAGS:
        // case API_URLS.SAVED_POPULATION.TAGS.CUSTOM_TAGS:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.CUSTOM_TAGS)
        //     : apiGet(url, params);
        //
        // case API_URLS.NOTIFICATIONS.TAG_SELECTION.RESTRICTED_TAGS:
        // case API_URLS.SAVED_POPULATION.TAGS.RESTRICTED_TAGS:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.RESTRICTED_TAGS)
        //     : apiGet(url, params);

        // VENDOR PROGRAMS CARDS DASHBOARD
        case API_URLS.CARDS_DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCardsDashboardDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.CARDS_DASHBOARD.STATIC_CLIENT_PROGRAM_CARDS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getCardsDashboardDataJSON.STATIC_CLIENT_PROGRAM_CARDS
                  )
                : apiGet(url, params);

        case API_URLS.CARDS_DASHBOARD.CARD_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCardsDashboardDataJSON.CARD_LIST)
                : apiGet(url, params);

        // VENDOR PROGRAMS CONTENT
        // case API_URLS.VENDOR_PROGRAM_CONTENT.STATIC:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getVendorProgramContentJSON.STATIC)
        //     : apiGet(url, params);
        //
        // case API_URLS.VENDOR_PROGRAM_CONTENT.PREFILL_DATA:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getVendorProgramContentJSON.PREFILL)
        //     : apiGet(url, params);

        // RESOURCE CARD
        case API_URLS.RESOURCE_CARD.STATICS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getResourceCardJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.RESOURCE_CARD.STATICS_CLIENT_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getResourceCardJSON.STATIC_CLIENT_PROGRAM
                  )
                : apiGet(url, params);

        case API_URLS.RESOURCE_CARD.PREFILL_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getResourceCardJSON.PREFILL_DATA)
                : apiGet(url, params);

        // CURRENT STATUS
        case API_URLS.CURRENT_STATUS.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCurrentStatusDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.CURRENT_STATUS.STATIC_CLIENT_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCurrentStatusDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.CURRENT_STATUS.PREFILL_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCurrentStatusDataJSON.PREFILL_DATA)
                : apiGet(url, params);

        // WHERE TO
        case API_URLS.WHERE_TO.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getWhereToDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.WHERE_TO.PREFILL_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getWhereToDataJSON.PREFILL_DATA)
                : apiGet(url, params);

        // EVIVE 360
        case API_URLS.EVIVE_360.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getEvive360DataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.EVIVE_360.STATIC_CLIENT_PROGRAM:
            return USE_MOCK_DATA
                ? apiMock(url, params, getEvive360DataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.EVIVE_360.PREFILL_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getEvive360DataJSON.PREFILL_DATA)
                : apiGet(url, params);

        // CALLOUTS
        case API_URLS.CALLOUTS.CONTENT_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CONTENT_STATIC)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CALLOUTS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CALLOUTS_DATA)
                : apiGet(url, params);

        case API_URLS.CALLOUTS.CTA_DATA_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getCalloutsDataJSON.CTA_DATA_LIST)
                : apiGet(url, params);

        // GAMEDAY CONTENT
        case API_URLS.GAME_DAY_CONTENT.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.DASHBOARD.STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.DASHBOARD.GAMES_LIST:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.DASHBOARD.GAMES_LIST
                  )
                : apiGet(url, params);

        // GAME STATIC DATA
        case API_URLS.GAME_DAY_CONTENT.GAME.STATIC.MEMORY:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.STATIC.MEMORY
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.GAME.STATIC.PICK_N_WIN:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.STATIC.PICK_N_WIN
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.GAME.STATIC.LOST_LETTERS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.STATIC.LOST_LETTERS
                  )
                : apiGet(url, params);

        // GAME PREFILL DATA
        case API_URLS.GAME_DAY_CONTENT.GAME.PREFILL.MEMORY:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.PREFILL.MEMORY
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.GAME.PREFILL.PICK_N_WIN:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.PREFILL.PICK_N_WIN
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.GAME.PREFILL.LOST_LETTERS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.GAME.PREFILL.LOST_LETTERS
                  )
                : apiGet(url, params);

        // GAME CONTENT CONFIGURE
        case API_URLS.GAME_DAY_CONTENT.CONFIGURE.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.CONFIGURE.STATIC
                  )
                : apiGet(url, params);

        case API_URLS.GAME_DAY_CONTENT.CONFIGURE.PREFILL:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getGameDayContentDataJSON.CONFIGURE.PREFILL
                  )
                : apiGet(url, params);

        // EV CONTENT
        case API_URLS.COMMON.CONTENT_LAZY_TEXT:
            return USE_MOCK_DATA
                ? apiMock(url, params, getContentLazyTextData)
                : apiGet(url, params);

        // Enterprise Notification
        case API_URLS.ENTERPRISE_NOTIFICATIONS.CLIENT_SELECTION.CLIENTS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, CLIENT_SELECTION_MOCK_DATA.CLIENTS_DATA)
                : apiGet(url, params);
        case API_URLS.ENTERPRISE_NOTIFICATIONS.PROGRAM_SELECTION
            .PROGRAM_LIST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      PROGRAM_SELECTION_MOCK_DATA.PROGRAM_LIST_DATA
                  )
                : apiGet(url, params);
        case API_URLS.ENTERPRISE_NOTIFICATIONS.SUMMARY.NOTIFICATION_USER_COUNT:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      SUMMARY_MOCK_DATA.NOTIFICATIONS_USER_COUNT_DATA
                  )
                : apiGet(url, params);

        default:
            // EvLogger.warn(
            //   `Common Service getData, unknown API endpoint, ${url}, with params ${params}`,
            //   "cc_service",
            //   "api"
            // );
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        // CURRENT STATUS
        case API_URLS.CURRENT_STATUS.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getCurrentStatusDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        // WHERE TO
        case API_URLS.WHERE_TO.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getWhereToDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        // EVIVE 360
        case API_URLS.EVIVE_360.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getEvive360DataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        // RESOURCE CARD
        case API_URLS.RESOURCE_CARD.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getResourceCardJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        // CALLOUTS
        case API_URLS.CALLOUTS.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getCalloutsDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        // VENDOR PROGRAMS CONTENT
        // case API_URLS.VENDOR_PROGRAM_CONTENT.SUBMIT:
        //     return USE_MOCK_DATA
        //         ? apiMock(
        //               url,
        //               qParams,
        //               // eslint-disable-next-line no-undef
        //               getVendorProgramContentJSON.SUBMIT,
        //               data
        //           )
        //         : apiPost(url, qParams, data);

        // GAMEDAY_CONTENT
        case API_URLS.GAME_DAY_CONTENT.GAME.SUBMIT.MEMORY:
        case API_URLS.GAME_DAY_CONTENT.GAME.SUBMIT.PICK_N_WIN:
        case API_URLS.GAME_DAY_CONTENT.GAME.SUBMIT.LOST_LETTERS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getGameDayContentDataJSON.GAME.SUBMIT,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.GAME_DAY_CONTENT.CONFIGURE.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getGameDayContentDataJSON.CONFIGURE.SUBMIT,
                      data
                  )
                : apiPost(url, qParams, data);

        // COMMON

        case API_URLS.COMMON.CRITERIA_BUILDER.GET_REF_ID:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getCriteriaBuilderRefIdData, data)
                : apiPost(url, qParams, data);

        default:
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
