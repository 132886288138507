import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvText } from "../../../../common/components";
import { COLORS } from "../../../../common/static/VmsStatics";

import "../styles/new-vendor-program-selection-modal.scss";

const styles = {
    container: "ev__new-vendor-program-selection-modal__container",
    closeButton: "ev__new-vendor-program-selection-modal__close-button",
    header: {
        container: "ev__new-vendor-program-selection-modal__header-container",
        subHeaderText:
            "ev__new-vendor-program-selection-modal__header-sub-header-text",
    },
    programs: {
        container: "ev__new-vendor-program-selection-modal__programs-container",
        itemButton:
            "ev__new-vendor-program-selection-modal__programs-item-button",
        itemButtonDisabled:
            "ev__new-vendor-program-selection-modal__programs-item-button-disabled",
    },
};

const NewVendorProgramSelectionModal = (props) => {
    const {
        staticData,
        programsData,
        currentPrograms,

        onModalCloseClick,
        onProgramSelect,
    } = props;

    const onSelect = useCallback(
        (e, callbackValues) => {
            onProgramSelect(callbackValues.programItemData);
        },
        [onProgramSelect]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText subHeading>{staticData.header}</EvText>
            <EvText className={styles.header.subHeaderText}>
                {staticData.subHeader}
            </EvText>
        </div>
    );

    const getProgramsItem = (programId) => {
        const isAlreadyAdded = currentPrograms.indexOf(programId) !== -1;
        const programItemData = programsData.value[programId] || {};

        const customButtonStyle = classNames(styles.programs.itemButton, {
            [styles.programs.itemButtonDisabled]: isAlreadyAdded,
        });

        return (
            <EvButton
                key={programId}
                onlyChild
                className={customButtonStyle}
                disabledView={isAlreadyAdded}
                noClickCallbackOnDisabled
                onClickHandler={onSelect}
                callbackValues={{ programItemData }}
            >
                <EvText>{programItemData.name}</EvText>
            </EvButton>
        );
    };

    const getProgramsView = () => (
        <div className={styles.programs.container}>
            {programsData.ids.map(getProgramsItem)}
        </div>
    );

    const getCloseButton = () => (
        <EvButton
            onlyChild
            className={styles.closeButton}
            onClickHandler={onModalCloseClick}
        >
            <EvIcon
                iconName="CROSS_THICK"
                size={4}
                circular
                bgColor={COLORS.BLACK_TROUT}
                fillColor={COLORS.WHITE}
            />
        </EvButton>
    );

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getProgramsView()}
            {getCloseButton()}
        </div>
    );
};

NewVendorProgramSelectionModal.propTypes = {
    staticData: PropTypes.object,
    programsData: PropTypes.object,
    currentPrograms: PropTypes.array,

    onModalCloseClick: PropTypes.func,
    onProgramSelect: PropTypes.func,
};

NewVendorProgramSelectionModal.defaultProps = {
    staticData: {},
    programsData: {},
    currentPrograms: [],

    onModalCloseClick: () => {},
    onProgramSelect: () => {},
};

export default NewVendorProgramSelectionModal;
