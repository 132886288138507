import React, { memo } from "react";
import PropTypes from "prop-types";

import EvIcon from "./EvIcon";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-notification-icon.scss";

/**
 * Notification icon with count display
 */
const EvNotificationIcon = (props) => {
    const styles = {
        container: "ev__ev-notification-icon__container",
        iconHolder: "ev__ev-notification-icon__icon-holder",
        countHolder: "ev__ev-notification-icon__count-holder",
        countBubble: "ev__ev-notification-icon__count-bubble",
    };

    const getIconView = (iconName, fillColor) => (
        <div className={styles.iconHolder}>
            <EvIcon size={4} iconName={iconName} fillColor={fillColor} />
        </div>
    );

    const getCountView = (count, bubbleColor) => (
        <div className={styles.countHolder}>
            <div
                className={styles.countBubble}
                style={{ backgroundColor: bubbleColor }}
            >
                {count}
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {getIconView(props.iconName, props.iconFillColor)}
            {props.count > 0 && getCountView(props.count, props.bubbleColor)}
        </div>
    );
};

EvNotificationIcon.propTypes = {
    /** icon name for the notification, should be left default unless custom icon should be used */
    iconName: PropTypes.string,
    /** number to show in the bubble, will not show anything if 0 */
    count: PropTypes.number,
    /** icon fill color, passed to icon */
    iconFillColor: PropTypes.string,
    /** count bubble background color  */
    bubbleColor: PropTypes.string,
};

EvNotificationIcon.defaultProps = {
    iconName: "BELL",
    count: 0,
    iconFillColor: COLORS.BLACK_TROUT,
    bubbleColor: COLORS.PRODUCT,
};

export default memo(EvNotificationIcon);
