import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    EvBackButton,
    EvButton,
    EvCategoryMenu,
    EvHeaderView,
    EvSearchBar,
    EvText,
} from "../../../common/components";
import fuzzySearch from "../../../utils/FuzzySearch";
import { normalizerWithOrderArraySingleLevel } from "../../../utils/Normalizer";
import { isNullOrEmpty, removeKeyFromObject } from "../../../utils/CommonUtils";
import NotificationsTagRuleCard from "./NotifcationsTagRuleCard";

import "../styles/ev-notifications-tag-selection-view.scss";

const ALL_OPTIONS_ID = "ALL";
const SELECTED_OPTIONS_ID = "SELECTED";

const styles = {
    container: "ev__ev-notifications-tag-selection-view__container",
    backContainer: "ev__ev-notifications-tag-selection-view__back-container",
    content: "ev__ev-notifications-tag-selection-view__content",
    infoContainer: "ev__ev-notifications-tag-selection-view__info-container",
    filter: {
        container: "ev__ev-notifications-tag-selection-view__filter-container",
        searchBar: "ev__ev-notifications-tag-selection-view__filter-search-bar",
        selectedButton:
            "ev__ev-notifications-tag-selection-view__filter-selected-button",
        selectedButtonSelected:
            "ev__ev-notifications-tag-selection-view__filter-selected-button-selected",
        submitButton:
            "ev__ev-notifications-tag-selection-view__filter-submit-button",
        categories:
            "ev__ev-notifications-tag-selection-view__filter-categories",
    },
    tags: {
        container: "ev__ev-notifications-tag-selection-view__tags-container",
    },
};

const EvNotificationsTagSelectionView = (props) => {
    const {
        tagSelectionCriteriaId,
        allTags,
        allSelectedData,
        criteriaRuleFormStaticData,
        categories,
        staticData,
        showBackButton,
        onProceedClick,
        onBackClick,
    } = props;

    const [
        currentCriteriaSelectedData,
        setCurrentCriteriaSelectedData,
    ] = useState(allSelectedData[tagSelectionCriteriaId] || {});
    // id of tag that is expanded
    const [selectedTagId, setSelectedTagId] = useState("");
    // to show changes not saved warn message
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [filteredTagList, setFilteredTagList] = useState(allTags.ids);
    const [selectedCategoryId, setSelectedCategoryId] = useState(
        ALL_OPTIONS_ID
    );

    useEffect(() => {
        if (Object.keys(allSelectedData[tagSelectionCriteriaId]).length > 0) {
            setFilteredTagList(
                Object.keys(allSelectedData[tagSelectionCriteriaId])
            );
            setSelectedCategoryId(SELECTED_OPTIONS_ID);
        }
    }, [allSelectedData, tagSelectionCriteriaId]);

    const allCategories = useMemo(
        () =>
            isNullOrEmpty(staticData)
                ? []
                : [staticData.allOptions, ...(categories || [])],
        [staticData, categories]
    );

    const onSearchKeyChange = useCallback(
        (searchKey) => {
            setSelectedCategoryId("");
            if (searchKey === "") {
                setFilteredTagList(allTags.ids);
                return;
            }
            const newFilteredList = fuzzySearch(allTags.rawData, searchKey, [
                "displayName",
            ]);
            const normalizedFilteredData = normalizerWithOrderArraySingleLevel(
                newFilteredList
            );
            setFilteredTagList(normalizedFilteredData.ids);
        },
        [allTags]
    );

    const onCategorySelect = useCallback(
        (e) => {
            if (selectedCategoryId === e) {
                return;
            } else if (e === ALL_OPTIONS_ID) {
                setFilteredTagList(allTags.ids);
                setSelectedCategoryId(e);
                setSelectedTagId("");
                return;
            }
            const currentFilteredList = allTags.ids.filter(
                (tagId) =>
                    allTags.value[tagId].category &&
                    allTags.value[tagId].category === e
            );
            setFilteredTagList(currentFilteredList);
            setSelectedCategoryId(e);
            setSelectedTagId("");
        },
        [selectedCategoryId, allTags]
    );

    const onSelectedFilterClick = useCallback(
        (e) => {
            if (selectedCategoryId === SELECTED_OPTIONS_ID) {
                return;
            }
            setFilteredTagList(Object.keys(currentCriteriaSelectedData));
            setSelectedTagId("");
            setSelectedCategoryId(SELECTED_OPTIONS_ID);
        },
        [currentCriteriaSelectedData, selectedCategoryId]
    );

    const onProceedButtonClick = useCallback(() => {
        onProceedClick({
            ...allSelectedData,
            [tagSelectionCriteriaId]: currentCriteriaSelectedData,
        });
    }, [
        currentCriteriaSelectedData,
        allSelectedData,
        onProceedClick,
        tagSelectionCriteriaId,
    ]);

    const onTagHeaderClick = useCallback(
        (e, callbackOptions) => {
            setSelectedTagId(callbackOptions.id);
        },
        [setSelectedTagId]
    );

    const setRule = useCallback(
        (tagId, tagFormData) => {
            setIsFormChanged(true);
            setCurrentCriteriaSelectedData({
                ...currentCriteriaSelectedData,
                [tagId]: tagFormData,
            });
        },
        [currentCriteriaSelectedData]
    );

    const removeRule = useCallback(
        (tagId) => {
            setIsFormChanged(true);
            const newFormDataForCriteria = removeKeyFromObject(
                tagId,
                currentCriteriaSelectedData
            );
            setCurrentCriteriaSelectedData(newFormDataForCriteria);

            // if selected category is selected, then refresh list
            if (selectedCategoryId === SELECTED_OPTIONS_ID) {
                setFilteredTagList(Object.keys(newFormDataForCriteria));
            }
        },
        [currentCriteriaSelectedData, selectedCategoryId]
    );

    const getBackButton = () => (
        <EvBackButton
            className={styles.backContainer}
            onClickHandler={onBackClick}
        >
            {staticData.backCta.text}
        </EvBackButton>
    );

    const getSelectedButton = () => {
        const isSelected = selectedCategoryId === SELECTED_OPTIONS_ID;
        const customButtonClass = classNames(styles.filter.selectedButton, {
            [styles.filter.selectedButtonSelected]: isSelected,
        });
        return (
            <EvButton
                negativeAction
                className={customButtonClass}
                onClickHandler={onSelectedFilterClick}
            >
                <EvText>{staticData.selectedMenu.displayName}</EvText>
                <EvText>
                    {Object.keys(currentCriteriaSelectedData).length}
                </EvText>
            </EvButton>
        );
    };

    const getInfoView = (infoText) => (
        <div className={styles.infoContainer}>
            <EvText italics>{infoText}</EvText>
        </div>
    );

    const getFilterView = () => (
        <div className={styles.filter.container}>
            <EvSearchBar
                className={styles.filter.searchBar}
                onChange={onSearchKeyChange}
            />
            {getSelectedButton()}
            <EvButton
                className={styles.filter.submitButton}
                primaryFilled
                onClickHandler={onProceedButtonClick}
            >
                {staticData.forwardCta.text}
            </EvButton>
            {isFormChanged &&
                staticData.changesNotSaved &&
                getInfoView(staticData.changesNotSaved)}
            <EvCategoryMenu
                selectedCategoryIdFromParent={selectedCategoryId}
                header={staticData.categoriesHeader}
                allOptionObject={staticData.allOptions}
                className={styles.filter.categories}
                categoriesData={allCategories}
                onCategorySelect={onCategorySelect}
                hardCategoryIds={[ALL_OPTIONS_ID, SELECTED_OPTIONS_ID, ""]}
            />
        </div>
    );

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getTagItemView = (tagId) => {
        const tagData = allTags.value[tagId];
        const isSelected = !isNullOrEmpty(currentCriteriaSelectedData[tagId]);
        return (
            <NotificationsTagRuleCard
                key={tagId}
                tagData={tagData}
                tagId={tagId}
                isSelected={isSelected}
                isExpanded={selectedTagId === tagId}
                onHeaderClick={onTagHeaderClick}
                isEditable={tagData.editable}
                formData={currentCriteriaSelectedData[tagId]}
                staticData={staticData.tags}
                formFields={criteriaRuleFormStaticData.formFields}
                setRule={setRule}
                removeRule={removeRule}
            />
        );
    };

    const getTagsView = () => (
        <div className={styles.tags.container}>
            {(isNullOrEmpty(filteredTagList) || filteredTagList.length <= 0) &&
                getInfoView(staticData.noTagsForCategory)}
            {filteredTagList.length > 0 && filteredTagList.map(getTagItemView)}
        </div>
    );

    const getContent = () => (
        <div className={styles.content}>
            {getFilterView()}
            {getTagsView()}
        </div>
    );

    return (
        <div className={styles.container}>
            {showBackButton && getBackButton()}
            {getHeaderView()}
            {getContent()}
        </div>
    );
};

EvNotificationsTagSelectionView.propTypes = {
    staticData: PropTypes.object,
    tagSelectionCriteriaId: PropTypes.string,
    allTags: PropTypes.object,
    allSelectedData: PropTypes.object,
    criteriaRuleFormStaticData: PropTypes.object,
    categories: PropTypes.array,
    showBackButton: PropTypes.bool,

    onProceedClick: PropTypes.func,
    onBackClick: PropTypes.func,
};

EvNotificationsTagSelectionView.defaultProps = {
    staticData: {},
    tagSelectionCriteriaId: "",
    allTags: {},
    allSelectedData: {},
    criteriaRuleFormStaticData: {},
    categories: [],
    showBackButton: false,

    onProceedClick: () => {},
    onBackClick: () => {},
};

export default EvNotificationsTagSelectionView;
