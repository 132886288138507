import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { setAnimationCallback } from "../../utils/AnimationHelper";
import "../styles/form-components/ev-content-modal.scss";

// let scrollTop = 0;
// let scrollLeft = 0;

const overlayRef = React.createRef();

const EvContentModal = (props) => {
    const styles = {
        container: "ev__ev-content-modal__container",
        overlay: "ev__ev-content-modal__overlay",
        overlayExiting: "ev__ev-content-modal__overlay-exiting",
        content: {
            container: "ev__ev-content-modal__content-container",
            wrapper: "ev__ev-content-modal__content-wrapper",
            padding: "ev__ev-content-modal__content-padding",
        },
    };

    const {
        children,
        contentClassName,
        noPadding,
        // allowBackgroundScroll,
        isVisible,
        exitOnOverlayClick,
        onExitComplete,
    } = props;

    const [isVisibleState, setIsVisibleState] = useState(false);
    const [isExiting, setIsExiting] = useState(false);

    // const onMouseScroll = useCallback(
    //     (e) => {
    //         if (!allowBackgroundScroll && isVisible) {
    //             window.scrollTo(scrollLeft, scrollTop);
    //             e.preventDefault();
    //         }
    //     },
    //     [allowBackgroundScroll, isVisible]
    // );

    // const setMouseScrollListeners = useCallback(() => {
    //     scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    //     console.log('ADDING LISTENER');
    //     window.addEventListener("scroll", onMouseScroll, false);
    //
    //     // return () => {
    //     //     window.removeEventListener('scroll', onMouseScroll);
    //     // }
    // }, [onMouseScroll]);

    const startExitAnimation = useCallback(() => {
        setAnimationCallback(overlayRef, () => {
            console.log("REMOVING LISTENER");
            // window.removeEventListener("scroll", onMouseScroll);
            setIsVisibleState(false);
            onExitComplete();
        });
        setIsExiting(true);
    }, [onExitComplete]);

    const onOverlayClick = useCallback(
        (e) => {
            if (
                overlayRef.current &&
                e.target === overlayRef.current &&
                exitOnOverlayClick
            ) {
                startExitAnimation();
            }
        },
        [exitOnOverlayClick, startExitAnimation]
    );

    useEffect(
        (prevIsVisibleState) => {
            // props change to close modal
            if (prevIsVisibleState !== isVisible && !isVisible) {
                startExitAnimation();
            }

            // props change to open modal
            if (prevIsVisibleState !== isVisible && isVisible) {
                // setMouseScrollListeners();
                setIsExiting(false);
                setIsVisibleState(true);
            }
        },
        [isVisible, startExitAnimation]
    );

    const getContent = () => {
        const customWrapperClass = classNames(
            styles.content.wrapper,
            {
                [styles.content.padding]: !noPadding,
            },
            contentClassName
        );
        return (
            <div className={styles.content.container}>
                <div className={customWrapperClass}>{children}</div>
            </div>
        );
    };

    const getModal = () => {
        const overlayCustomClass = classNames(styles.overlay, {
            [styles.overlayExiting]: isExiting,
        });
        return isVisibleState ? (
            <div className={styles.container}>
                <div
                    ref={overlayRef}
                    className={overlayCustomClass}
                    onClick={onOverlayClick}
                >
                    {getContent()}
                </div>
            </div>
        ) : (
            <span />
        );
    };

    return createPortal(
        getModal(),
        document.getElementById("portal-container")
    );
};

EvContentModal.propTypes = {
    children: PropTypes.node,
    contentClassName: PropTypes.string,
    noPadding: PropTypes.bool,
    isVisible: PropTypes.bool,
    // allowBackgroundScroll: PropTypes.bool,
    exitOnOverlayClick: PropTypes.bool,
    onExitComplete: PropTypes.func,
};

EvContentModal.defaultProps = {
    children: <span />,
    contentClassName: "",
    noPadding: false,
    isVisible: false,
    // allowBackgroundScroll: false,
    exitOnOverlayClick: true,
    onExitComplete: () => {},
};

export default EvContentModal;
