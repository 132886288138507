import { ConnectedRouter } from "connected-react-router/immutable";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ClosedRoutes from "./routes/ClosedRoutes";
import OpenRoutes from "./routes/OpenRoutes";
import store, { browserHistory } from "./store/Store";

function App() {
    return (
        <Provider store={store}>
            <ConnectedRouter history={browserHistory}>
                <Switch>
                    <Route path="/cc-open" component={OpenRoutes} />
                    <Route path="/cc" component={ClosedRoutes} />
                    <Route
                        path="/"
                        exact={true}
                        component={() => <Redirect to="/cc" />}
                    />
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;
