// import CacheActions from "../reducers/cache/CacheActions";
// import {
//   normalizer,
//   normalizerWithOrderArraySingleLevel,
//   // normalizerWithOrderArrayWithEmptyCheck,
// } from "./Normalizer";
// import { CACHE_DATA_TYPE, TAGS_TYPE } from "../common/static/Enums";
import EvLogger, { LOG_ACTION_TYPE, LOG_CATEGORY_TYPES } from "./EvLogger";
import { isNullOrEmpty } from "./CommonUtils";

// const createAllTagsDictionary = (dispatch, clientCode, URLS) => {
//   // URLS: ALL_TAGS, CUSTOM_TAGS, LINKED_TAGS
//   return Promise.all([
//     dispatch(
//       CacheActions.getCachedData({
//         url: URLS.ALL_TAGS,
//         qParams: { clientCode },
//         type: CACHE_DATA_TYPE.TAGS,
//       })
//     ),
//     dispatch(
//       CacheActions.getCachedData({
//         url: URLS.CUSTOM_TAGS,
//         qParams: { clientCode },
//         type: CACHE_DATA_TYPE.TAGS,
//       })
//     ),
//     dispatch(
//       CacheActions.getCachedData({
//         url: URLS.LINKED_TAGS,
//         qParams: { clientCode },
//         type: CACHE_DATA_TYPE.TAGS,
//       })
//     ),
//     dispatch(
//       CacheActions.getCachedData({
//         url: URLS.RESTRICTED_TAGS,
//         qParams: { clientCode },
//       })
//     ),
//   ])
//     .then(
//       ([
//         allTagsResponse,
//         customTagsResponse,
//         linkedTagsResponse,
//         restrictedTagsResponse,
//       ]) => {
//         // merge linked tags data with all tags (it contains names and other)
//         const allTagsNormalized = normalizer(allTagsResponse.tags);
//         // for custom tags BE sends isEditable true
//         const customTagsNormalizedObject = normalizerWithOrderArraySingleLevel(
//           customTagsResponse.tags
//         );
//         const linkedTagsNormalizedObject = normalizerWithOrderArraySingleLevel(
//           linkedTagsResponse.tags
//         );
//         const restrictedTagsNormalizedObject = normalizerWithOrderArraySingleLevel(
//           restrictedTagsResponse.tags
//         );
//
//         // custom tags doesnt contain mapped field.
//         // manual add mapped field with the name value, this is used later by the label component
//         const customTagsNormalized = {};
//         customTagsNormalizedObject.ids.forEach(tagId => {
//           customTagsNormalized[tagId] = {
//             ...allTagsNormalized[tagId],
//             ...customTagsNormalizedObject.value[tagId],
//             type: TAGS_TYPE.CUSTOM,
//             // add form data even if not present, since its editable
//             tagRuleFields: {
//               ...(customTagsNormalizedObject.value[tagId]
//                 ? customTagsNormalizedObject.value[tagId].tagRuleFields
//                 : {}),
//               mappedField: {
//                 id: tagId,
//                 name: customTagsNormalizedObject.value[tagId].displayName,
//                 value: tagId,
//               },
//             },
//           };
//         });
//
//         const linkedTagsNormalized = {};
//         linkedTagsNormalizedObject.ids.forEach(tagId => {
//           linkedTagsNormalized[tagId] = {
//             ...allTagsNormalized[tagId],
//             ...linkedTagsNormalizedObject.value[tagId],
//             type: TAGS_TYPE.PRESET,
//             // forcefully make it false, in this page linked tags are not editable.
//             // DO NOT use isEditable from the object, that's for UserMapping page only
//             editable: false,
//           };
//         });
//
//         const restrictedTagsNormalized = {};
//         restrictedTagsNormalizedObject.ids.forEach(tagId => {
//           restrictedTagsNormalized[tagId] = {
//             ...allTagsNormalized[tagId],
//             ...restrictedTagsNormalizedObject.value[tagId],
//             type: TAGS_TYPE.RESTRICTED,
//             // forcefully make it false, in this page restricted tags are not editable.
//             editable: false,
//           };
//         });
//
//         // merge custom and linked tags together as one dictionary.
//         // we differentiate them by the ID array later
//         // and also custom tags will have editable:true
//         const tagDictionary = {
//           // ...allTagsNormalized,
//           ...customTagsNormalized,
//           ...linkedTagsNormalized,
//           ...restrictedTagsNormalized,
//         };
//         return Promise.resolve({
//           tagDictionary,
//           customTags: customTagsNormalizedObject.ids,
//           linkedTags: linkedTagsNormalizedObject.ids,
//           restrictedTags: restrictedTagsNormalizedObject.ids,
//         });
//       }
//     )
//     .catch(() => {
//       return Promise.reject(
//         new Error("TagUtils: Error in creating tag Dictionary")
//       );
//     });
// };

// used by card dashboard
const createCardListBasedOnType = (cardList, vendorProgramsList) => {
    const cardListByType = {};
    try {
        cardList.forEach((cardData) => {
            const vendorDataForCard =
                vendorProgramsList[
                    `${cardData.vendorId}-${cardData.programId}`
                ];
            if (cardListByType[cardData.cardType]) {
                cardListByType[cardData.cardType].push({
                    ...cardData,
                    description: vendorDataForCard
                        ? `${vendorDataForCard.vendorName} - ${vendorDataForCard.programName}`
                        : "Not Found",
                });
            } else {
                cardListByType[cardData.cardType] = [
                    {
                        ...cardData,
                        description: vendorDataForCard
                            ? `${vendorDataForCard.vendorName} - ${vendorDataForCard.programName}`
                            : "Not Found",
                    },
                ];
            }
        });
        return cardListByType;
    } catch (e) {
        EvLogger.error(
            `TagUtils: Error in createCardListBasedOnType, cardList: ${JSON.stringify(
                cardList
            )}`,
            LOG_CATEGORY_TYPES.CC,
            LOG_ACTION_TYPE.PARSE
        );
    }
};

const createCardListBasedOnVP = (cardList, vendorProgramsList) => {
    const cardListByVendor = {};

    // this is required, so that all VP has an object, even without cards
    Object.entries(vendorProgramsList).forEach(
        ([currentVPId, currentVPData]) => {
            cardListByVendor[currentVPId] = {
                vendorProgramData: currentVPData,
                cardList: [],
            };
        }
    );
    cardList.forEach((cardData) => {
        const vendorProgramId = `${cardData.vendorId}-${cardData.programId}`;
        if (cardListByVendor[vendorProgramId]) {
            cardListByVendor[vendorProgramId].cardList.push(cardData);
        } else {
            // cardListByVendor[vendorProgramId] = {
            //   vendorProgramData: vendorProgramsList[vendorProgramId],
            //   cardList: [cardData],
            // };
            // ignore if VP data is not present
        }
    });
    return cardListByVendor;
};

const sortObjectArrayBasedOnKey = (list, sortingKey) => {
    const sortedList = list.sort(
        (itemA, itemB) => itemA[sortingKey] - itemB[sortingKey]
    );
    return sortedList;
};

const getApprovalCardIdsFromPreProdList = (cardIdList, cardDataList) =>
    cardIdList.filter((cardId) => cardDataList[cardId].isApprovable);

const mapVendorsWithPrograms = (vendorData, programsData) => {
    let vendorProgramData = {};
    vendorData.ids.forEach((vendorId) => {
        const currentVendorData = vendorData.value[vendorId];
        vendorProgramData = {
            ...vendorProgramData,
            [vendorId]: {
                ...currentVendorData,
                programs: currentVendorData.programs.map((programId) => ({
                    id: programId,
                    ...programsData[programId],
                })),
            },
        };
    });
    return vendorProgramData;
};

const parsePreselectedVendorDataForStore = (selectedVendorData) => {
    let selectedState = {};
    selectedVendorData.forEach((currentVendor) => {
        selectedState = {
            ...selectedState,
            [currentVendor.vendorId]: {
                ...selectedState[currentVendor.vendorId],
                [currentVendor.programId]: {
                    name: currentVendor.programName,
                    id: currentVendor.programId,
                    value: currentVendor.programId,
                },
            },
        };
    });
    return selectedState;
};

const parseVendorSelectionDataForApi = (selectedVendorData) => {
    const result = [];
    Object.keys(selectedVendorData).forEach((vendorId) =>
        Object.keys(selectedVendorData[vendorId]).forEach((programId) =>
            result.push({
                vendorId,
                programId,
            })
        )
    );
    return result;
};

const groupCardsByType = (allCardsArray) => {
    const cardsGroup = {};
    allCardsArray.forEach((cardItem) => {
        if (isNullOrEmpty(cardsGroup[cardItem.cardType])) {
            cardsGroup[cardItem.cardType] = [];
        }
        cardsGroup[cardItem.cardType].push(cardItem);
    });
    return cardsGroup;
};

const createVendorProgramNormalizedDictionary = (vendorProgramList) => {
    const dictionary = {};
    vendorProgramList.forEach((currentVendorProgram) => {
        dictionary[
            `${currentVendorProgram.vendorId}-${currentVendorProgram.programId}`
        ] = currentVendorProgram;
    });
    return dictionary;
};

export {
    // createAllTagsDictionary,
    createCardListBasedOnType,
    createCardListBasedOnVP,
    sortObjectArrayBasedOnKey,
    getApprovalCardIdsFromPreProdList,
    mapVendorsWithPrograms,
    parsePreselectedVendorDataForStore,
    parseVendorSelectionDataForApi,
    groupCardsByType,
    createVendorProgramNormalizedDictionary,
};
