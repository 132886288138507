import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvText } from "./index";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-tab-selector.scss";

const DEFAULT_TAB_WIDTH = 150;

const styles = {
    container: "ev__ev-tab-selector__container",
    slider: {
        container: "ev__ev-tab-selector__slider-container",
        offset: "ev__ev-tab-selector__slider-offset",
        selected: "ev__ev-tab-selector__slider-selected",
        spacer: "ev__ev-tab-selector__slider-spacer",
    },
    tabs: {
        container: "ev__ev-tab-selector__tabs-container",
        button: "ev__ev-tab-selector__tabs-button",
        borderedButton: "ev__ev-tab-selector__tabs-bordered-button",
    },
};

const EvTabSelector = (props) => {
    const {
        initialSelectedId,
        tabsData,
        tabWidth,
        onTabSelect,
        className,
    } = props;

    const [selectedTabId, setSelectedTabId] = useState(
        initialSelectedId || tabsData[0].id
    );

    const selectedIndex = useMemo(
        () =>
            tabsData.findIndex(
                (currentTabData) => currentTabData.id === selectedTabId
            ),
        [selectedTabId, tabsData]
    );

    const onTabClick = useCallback(
        (e, callbackValues) => {
            setSelectedTabId(callbackValues.id);
            onTabSelect(callbackValues.id);
        },
        [onTabSelect]
    );

    const getSliderView = () => (
        <div className={styles.slider.container}>
            <div
                className={styles.slider.offset}
                style={{ width: selectedIndex * tabWidth }}
            />
            <div
                className={styles.slider.selected}
                style={{ width: tabWidth }}
            />
            <div className={styles.slider.spacer} />
        </div>
    );

    const getTabItem = (currentTabData) => {
        const isSelected = selectedTabId === currentTabData.id;
        const isFirstItem = currentTabData.id === tabsData[0].id;
        const buttonClass = classNames(styles.tabs.button, {
            [styles.tabs.borderedButton]: !isFirstItem,
        });
        return (
            <EvButton
                key={currentTabData.id}
                onlyChild
                className={buttonClass}
                buttonInlineStyle={{ width: tabWidth }}
                onClickHandler={onTabClick}
                callbackValues={currentTabData}
            >
                <EvText
                    defaultBold={isSelected}
                    color={isSelected ? COLORS.WHITE : null}
                >
                    {currentTabData.displayName}
                </EvText>
            </EvButton>
        );
    };

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {getSliderView()}
            <div className={styles.tabs.container}>
                {tabsData.map(getTabItem)}
            </div>
        </div>
    );
};

EvTabSelector.propTypes = {
    initialSelectedId: PropTypes.string,
    tabsData: PropTypes.array,
    tabWidth: PropTypes.number,
    className: PropTypes.string,
    onTabSelect: PropTypes.func,
};

EvTabSelector.defaultProps = {
    initialSelectedId: "",
    tabWidth: DEFAULT_TAB_WIDTH,
    tabsData: [],
    className: "",
    onTabSelect: () => {},
};

export default EvTabSelector;
