import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvToast } from "./index";

import "../styles/ev-simple-file-selector.scss";
import { LOADING_STATUS } from "../static/Enums";

const styles = {
    container: "ev__ev-simple-file-selector__container",
    input: "ev__ev-simple-file-selector__input",
    button: "ev__ev-simple-file-selector__button",
};

const EvSimpleFileSelector = (props) => {
    const { buttonText, className, onFileSelect } = props;

    const fileUploadRef = React.createRef(null);
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const onButtonClick = useCallback(() => {
        if (loadingStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (fileUploadRef.current) {
            fileUploadRef.current.value = "";
            fileUploadRef.current.click();
        }
    }, [loadingStatus, fileUploadRef]);

    const onFileChange = useCallback(
        (e) => {
            e.preventDefault();
            if (!e.target.files[0]) {
                return; // if no file is selected
            }
            setLoadingStatus(LOADING_STATUS.LOADING);
            const reader = new FileReader();
            const fileName = e.target.files[0];
            reader.onloadend = () => {
                onFileSelect(reader.result, fileName);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            };
            reader.onerror = () => {
                EvToast.error("Sorry", "Failed to select file");
                setLoadingStatus(LOADING_STATUS.FAILED);
            };
            reader.readAsDataURL(fileName);
        },
        [onFileSelect]
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <input
                ref={fileUploadRef}
                className={styles.input}
                type="file"
                onChange={onFileChange}
            />
            <EvButton
                secondary
                className={styles.button}
                onClickHandler={onButtonClick}
            >
                {buttonText}
            </EvButton>
        </div>
    );
};

EvSimpleFileSelector.propTypes = {
    buttonText: PropTypes.string,
    className: PropTypes.string,
    onFileSelect: PropTypes.func,
};

EvSimpleFileSelector.defaultProps = {
    buttonText: "",
    className: "",
    onFileSelect: () => {},
};

export default EvSimpleFileSelector;
