import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData } from "../service/PreProdService";
import PreProdActionTypes from "./PreProdActionTypes";

const PreProdActions = {
    setStaticData: (payload) => ({
        type: PreProdActionTypes.SET_STATIC_DATA,
        payload,
    }),

    setComponentData: (payload) => ({
        type: PreProdActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: PreProdActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    setDynamicDataApiStatus: (payload) => ({
        type: PreProdActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            !isNullOrEmpty(
                getState().PreProdReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().PreProdReducer.staticData[payload.templateId]
            ); // static data already loaded
        }
        dispatch(
            PreProdActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        // return internal promise from then
        return getData(payload.url).then((response) => {
            dispatch(
                PreProdActions.setStaticData({
                    templateId: payload.templateId,
                    staticData: response.data.data,
                })
            );
            dispatch(
                PreProdActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    resetData: (payload) => ({
        type: PreProdActionTypes.RESET_DATA,
        payload,
    }),
};

export default PreProdActions;
