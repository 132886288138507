const PreProdActionTypes = {
    SET_STATIC_DATA_API_STATUS: "PreProdActionTypes/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS:
        "PreProdActionTypes/SET_DYNAMIC_DATA_API_STATUS",
    SET_COMPONENT_DATA: "PreProdActionTypes/SET_COMPONENT_DATA",
    SET_STATIC_DATA: "PreProdActionTypes/SET_STATIC_DATA",
    RESET_DATA: "PreProdActionTypes/RESET_DATA",
};

export default PreProdActionTypes;
