import { isNullOrEmpty } from "./CommonUtils";
import { FORM_FIELD_TYPES } from "../common/static/Enums";

const VALIDATION_TYPES = {
    MIN_LENGTH: "MIN_LENGTH",
    MAX_LENGTH: "MAX_LENGTH",
    MANDATORY: "MANDATORY",
    CHECK_EMAIL: "CHECK_EMAIL",
    CHECK_NUMBER: "CHECK_NUMBER",
    CHECK_URL: "CHECK_URL",
    HEX_COLOR: "HEX_COLOR",
    ALPHA_NUMERIC: "ALPHA_NUMERIC",
    MAX_ITEM_COUNT: "MAX_ITEM_COUNT",
    MIN_ITEM_COUNT: "MIN_ITEM_COUNT",
};

const reservedKeywordCheck = (itemKey) =>
    ["errorObject", "formItemType"].indexOf(itemKey) !== -1;

const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

const validateNumber = (value) => {
    const re = /^[0-9]+$/;
    return re.test(value);
};

const validateUrl = (value) => {
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator
    return !!pattern.test(value);
};

const validateHexColor = (value) => {
    const re = /^#[0-9A-F]{6}$/i;
    return re.test(value);
};

const validateAlphaNumeric = (value) => {
    const re = new RegExp("^[a-zA-Z0-9 ]*$");
    return !!re.test(value);
};

const validationMinLength = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (formInputObject[itemKey].value.length < validationObject.value) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationMaxLength = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (formInputObject[itemKey].value.length > validationObject.value) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationMandatory = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    // if the whole object is empty, possible for initial parting of dropdowns
    if (isNullOrEmpty(formInputObject)) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
        return newErrorObject;
    }
    let validItemsCount = 0;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        // if no items present
        if (isNullOrEmpty(formInputObject[itemKey])) {
            // newErrorObject = {
            //   ...newErrorObject,
            //   isValid: false,
            //   errorMessages: {
            //     ...newErrorObject.errorMessages,
            //     [validationObject.type]: validationObject.errMsg,
            //   },
            // };
        } else if (!formInputObject[itemKey].value) {
            // if items present, check for value empty
            // newErrorObject = {
            //   ...newErrorObject,
            //   isValid: false,
            //   errorMessages: {
            //     ...newErrorObject.errorMessages,
            //     [validationObject.type]: validationObject.errMsg,
            //   },
            // };
        } else {
            // valid item
            validItemsCount++;
        }
    });
    if (validItemsCount <= 0) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
    }
    return newErrorObject;
};

const validationEmail = (validationObject, formInputObject, errorObject) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (!validateEmail(formInputObject[itemKey].value)) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationNumber = (validationObject, formInputObject, errorObject) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (!validateNumber(formInputObject[itemKey].value)) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationUrl = (validationObject, formInputObject, errorObject) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (!validateUrl(formInputObject[itemKey].value)) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationHexColor = (validationObject, formInputObject, errorObject) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (!validateHexColor(`${formInputObject[itemKey].value}`)) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationAlphaNumeric = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (!validateAlphaNumeric(`${formInputObject[itemKey].value}`)) {
            newErrorObject = {
                ...newErrorObject,
                isValid: false,
                errorMessages: {
                    ...newErrorObject.errorMessages,
                    [validationObject.type]: validationObject.errMsg,
                },
            };
        }
    });
    return newErrorObject;
};

const validationMaxItemCount = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    let count = 0;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (formInputObject[itemKey].value) {
            count++;
        }
    });
    if (count > validationObject.value) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
    }
    return newErrorObject;
};

const validationMinItemCount = (
    validationObject,
    formInputObject,
    errorObject
) => {
    let newErrorObject = errorObject;
    let count = 0;
    Object.keys(formInputObject).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        if (formInputObject[itemKey].value) {
            count++;
        }
    });
    if (count < validationObject.value) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
    }
    return newErrorObject;
};

const validationMaxItemCountFormTemplate = (
    validationObject,
    formTemplateData,
    errorObject
) => {
    let newErrorObject = errorObject;
    let count = 0;
    Object.keys(formTemplateData).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        count++;
    });
    if (count > validationObject.value) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
    }
    return newErrorObject;
};

const validationMinItemCountFormTemplate = (
    validationObject,
    formTemplateData,
    errorObject
) => {
    let newErrorObject = errorObject;
    let count = 0;
    Object.keys(formTemplateData).forEach((itemKey) => {
        if (reservedKeywordCheck(itemKey)) {
            return;
        }
        count++;
    });
    if (count < validationObject.value) {
        newErrorObject = {
            ...newErrorObject,
            isValid: false,
            errorMessages: {
                ...newErrorObject.errorMessages,
                [validationObject.type]: validationObject.errMsg,
            },
        };
    }
    return newErrorObject;
};

const validateTemplateForm = (formItemData, formTemplateData) => {
    let errorObject = {
        isValid: true,
        errorMessages: {},
    };
    formItemData.validations.forEach((validationObject) => {
        switch (validationObject.type) {
            case VALIDATION_TYPES.MAX_ITEM_COUNT:
                errorObject = validationMaxItemCountFormTemplate(
                    validationObject,
                    formTemplateData,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.MIN_ITEM_COUNT:
                errorObject = validationMinItemCountFormTemplate(
                    validationObject,
                    formTemplateData,
                    errorObject
                );
                break;

            default:
        }
    });
    return errorObject;
};

const validateFormItem = (formItemData, formInputObject) => {
    let errorObject = {
        isValid: true,
        errorMessages: {},
    };
    if (!formItemData.validations) {
        return errorObject;
    }
    if (formItemData.type === FORM_FIELD_TYPES.FORM_TEMPLATE) {
        errorObject = validateTemplateForm(formItemData, formInputObject);
        return errorObject;
    }
    formItemData.validations.forEach((validationObject) => {
        switch (validationObject.type) {
            case VALIDATION_TYPES.MIN_LENGTH:
                errorObject = validationMinLength(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.MAX_LENGTH:
                errorObject = validationMaxLength(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.MANDATORY:
                errorObject = validationMandatory(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.CHECK_EMAIL:
                errorObject = validationEmail(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.CHECK_NUMBER:
                errorObject = validationNumber(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.CHECK_URL:
                errorObject = validationUrl(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.HEX_COLOR:
                errorObject = validationHexColor(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.ALPHA_NUMERIC:
                errorObject = validationAlphaNumeric(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.MAX_ITEM_COUNT:
                errorObject = validationMaxItemCount(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            case VALIDATION_TYPES.MIN_ITEM_COUNT:
                errorObject = validationMinItemCount(
                    validationObject,
                    formInputObject,
                    errorObject
                );
                break;

            default:
        }
    });
    return errorObject;
};

export default validateFormItem;
