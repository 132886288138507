import React, { useMemo } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvButton,
    EvDivider,
    EvLoader,
    EvLoadingPage,
    EvTabSelector,
    EvText,
} from "../../../common/components";
import PreProdChecklistCard from "./PreProdChecklistCard";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import {
    PRE_PROD_COMPONENTS,
    PRE_PROD_TAB_IDS,
} from "../statics/PreProdStatics";

import "../styles/pre-prod-view.scss";

const styles = {
    container: "ev__pre-prod-view__container",
    header: {
        container: "ev__pre-prod-view__header-container",
    },
    actionableView: {
        container: "ev__pre-prod-view__actionable-view-container",
        tabs: "ev__pre-prod-view__actionable-view-tabs",
        actionsContainer:
            "ev__pre-prod-view__actionable-view-actions-container",
        button: "ev__pre-prod-view__actionable-view-button",
    },
    cards: {
        container: "ev__pre-prod-view__cards-container",
        cardWrapper: "ev__pre-prod-view__cards-card-wrapper",
    },
    infoContainer: "ev__pre-prod-view__info-container",
};

const PreProdView = (props) => {
    const {
        clientName,
        clientCode,
        staticData,
        preApprovals,
        approvedCardList,
        approveAllApiStatus,
        selectedTabId,
        controls,

        onApproveAll,
        onEditCard,
        onTestClick,
        onTabChange,
        onCardApprove,
    } = props;

    const pendingApprovalCardCount = useMemo(
        () => (preApprovals.ids ? preApprovals.ids.length : 0),
        [preApprovals]
    );
    const approvedCardCount = useMemo(
        () => (approvedCardList.ids ? approvedCardList.ids.length : 0),
        [approvedCardList]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary dangerous>
                {stringTemplate(staticData.header, {
                    clientName,
                })}
            </EvText>
            <EvText>{staticData.description}</EvText>
        </div>
    );

    const getActionableButtons = () => (
        <div className={styles.actionableView.actionsContainer}>
            {pendingApprovalCardCount > 0 &&
                staticData.approveAllCta && ( // keep it conditional, as the button will be added back later
                    <EvButton
                        className={styles.actionableView.button}
                        onClickHandler={onApproveAll}
                        primaryFilled
                    >
                        {staticData.approveAllCta.text}
                    </EvButton>
                )}
            {!isNullOrEmpty(staticData.testAllCta) && (
                <EvButton
                    default
                    className={styles.actionableView.button}
                    onClickHandler={onTestClick}
                >
                    {staticData.testAllCta.text}
                </EvButton>
            )}
            {approveAllApiStatus === LOADING_STATUS.LOADING && (
                <EvLoader size={3} />
            )}
        </div>
    );

    const cardDecider = (cardData) => {
        return (
            <div key={cardData.id} className={styles.cards.cardWrapper}>
                <PreProdChecklistCard
                    clientCode={clientCode}
                    cardData={cardData}
                    staticData={staticData.cardStatics.CHECKLIST}
                    dataTypeStaticData={staticData.dataTypes[cardData.dataType]}
                    onEditCard={onEditCard}
                    onCardApprove={onCardApprove}
                />
            </div>
        );
    };

    // for now we are keeping only checklist. more type will be added later on
    const getPendingCardItem = (cardId) =>
        cardDecider(preApprovals.value[cardId]);

    const getApprovedCardItem = (cardId) =>
        cardDecider(approvedCardList.value[cardId]);

    const getInfoView = (infoMessage) => (
        <div className={styles.infoContainer}>
            <EvText italics>{infoMessage}</EvText>
        </div>
    );

    const getPendingList = () => {
        if (
            !isCompleted(
                controls.dynamicDataApiStatus[
                    PRE_PROD_COMPONENTS.PRE_APPROVALS.id
                ]
            )
        ) {
            return <EvLoadingPage />;
        } else if (pendingApprovalCardCount > 0) {
            return (
                <div className={styles.cards.container}>
                    {preApprovals.ids.map(getPendingCardItem)}
                </div>
            );
        }
        return getInfoView(staticData.noCardsApprovalMessage);
    };

    const getApprovedList = () => {
        if (
            !isCompleted(
                controls.dynamicDataApiStatus[
                    PRE_PROD_COMPONENTS.APPROVED_LIST.id
                ]
            )
        ) {
            return <EvLoadingPage />;
        } else if (approvedCardCount > 0) {
            return (
                <div className={styles.cards.container}>
                    {approvedCardList.ids.map(getApprovedCardItem)}
                </div>
            );
        }
        return getInfoView(staticData.noCardsApprovedMessage);
    };

    const getActionsView = () => (
        <div className={styles.actionableView.container}>
            <EvTabSelector
                tabsData={staticData.tabsData}
                onTabSelect={onTabChange}
                className={styles.actionableView.tabs}
            />
            {selectedTabId === PRE_PROD_TAB_IDS.PENDING &&
                getActionableButtons()}
        </div>
    );

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getActionsView()}
            <EvDivider marginVertical={20} />
            {selectedTabId === PRE_PROD_TAB_IDS.PENDING && getPendingList()}
            {selectedTabId === PRE_PROD_TAB_IDS.APPROVED && getApprovedList()}
        </div>
    );
};

PreProdView.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    staticData: PropTypes.object,
    preApprovals: PropTypes.object,
    approvedCardList: PropTypes.object,
    controls: PropTypes.object,
    approveAllApiStatus: PropTypes.string,
    selectedTabId: PropTypes.string,

    onApproveAll: PropTypes.func,
    onEditCard: PropTypes.func,
    onTestClick: PropTypes.func,
    onTabChange: PropTypes.func,
    onCardApprove: PropTypes.func,
};

PreProdView.defaultProps = {
    clientCode: "",
    clientName: "",
    staticData: {},
    preApprovals: {},
    approvedCardList: {},
    controls: {},
    approveAllApiStatus: "",
    selectedTabId: "",

    onApproveAll: () => {},
    onEditCard: () => {},
    onTestClick: () => {},
    onTabChange: () => {},
    onCardApprove: () => {},
};

export default PreProdView;
