const VENDOR_PROGRAMS_PAGES = {
    BASE: {
        id: "vendorPrograms_base",
        path: "/cc/client/manage-programs",
    },
    DASHBOARD: {
        id: "vendorPrograms_dashboard",
        path: "/cc/client/manage-programs/vendor-programs",
    },
    TAGS: {
        id: "venodorProgram_tags",
        path: "/cc/client/manage-programs/tags",
    },
    SAVED_POPULATION: {
        id: "vendorProgram_savedPopulation",
        path: "/cc/client/manage-programs/saved-population",
    },
    CUSTOMIZE_PROGRAMS: {
        id: "vendorPrograms_customize",
        path: "/cc/client/manage-programs/customize-programs",
    },
    NEW_VENDOR_PROGRAM: {
        id: "vendorPrograms_new",
        path: "/cc/client/manage-programs/new-vendor-program",
    },
    // REMOVE
    NEW_VENDOR_PROGRAM_SELECTION: {
        id: "vendorPrograms_newSelection",
    },
    // REMOVE
    NEW_VENDOR_PROGRAM_REVIEW: {
        id: "vendorPrograms_newReview",
    },
    PROGRAM_TAGS: {
        id: "vendorPrograms__programTags",
        path: "/cc/client/manage-programs/customize-programs/tags",
    },
    CALLOUTS: {
        id: "vendorPrograms__callouts",
        path: "/cc/client/manage-programs/customize-programs/callouts",
    },
    VENDOR_PROGRAM_CONTENT: {
        id: "vendorPrograms__vendorProgramContent",
        path:
            "/cc/client/manage-programs/customize-programs/vendor-program-content",
    },
    CARDS_DASHBOARD: {
        id: "vendorPrograms__cardsDashboard",
        path: "/cc/client/manage-programs/customize-programs/cards",
    },
    GAMEDAY_CONTENT: {
        id: "vendorPrograms__gamedayContent",
        path: "/cc/client/manage-programs/customize-programs/gameday-content",
    },
    PROGRAM_SUMMARY: {
        id: "vendorPrograms_summary",
        path: "/cc/client/manage-programs/customize-programs/summary",
    },
};

const VENDOR_PROGRAMS_TEMPLATES = {
    VENDOR_PROGRAM_SIDEBAR: {
        id: "vendorPrograms_sidebar",
    },
    VENDOR_DASHBOARD: {
        id: "vendorPrograms_dashboard",
    },
    NEW_VENDOR_PROGRAM_SELECTION: {
        id: "vendorPrograms_newSelection",
    },
    NEW_VENDOR_PROGRAM_REVIEW: {
        id: "vendorPrograms_newReview",
    },
};

const VENDOR_PROGRAM_COMPONENTS = {
    CURRENT_VENDORS: {
        id: "currentVendors",
    },
    VENDORS: {
        id: "vendorPrograms_vendors",
    },
    CLIENT_VENDORS: {
        id: "vendorPrograms_clientVendors",
    },
    PROGRAMS: {
        id: "vendorPrograms_programs",
    },
};

const VENDOR_PROGRAMS_STATICS = {
    BACK_BUTTON_TEXT: "Back",
};

export {
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAM_COMPONENTS,
    VENDOR_PROGRAMS_TEMPLATES,
    VENDOR_PROGRAMS_STATICS,
};
