import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GamedayScheduleDashboard from "./GamedayScheduleDashboard";
import GamedayScheduleSelectionView from "./GamedayScheduleSelectionView";
import GamedayScheduleContentView from "./GamedayScheduleContentView";
import GamedayScheduleTimeView from "./GamedayScheduleTimeView";
import GamedayScheduleSummary from "./GamedayScheduleSummary";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import { GAMEDAY_SCHEDULE_PAGES } from "../static/GamedayScheduleStatics";

const GamedayScheduleContainer = (props) => {
    const { clientCode, controls, resetAllData } = props;

    useEffect(
        () => () => {
            resetAllData();
        },
        [resetAllData]
    );

    const getContent = () => {
        switch (controls.currentPageId) {
            case GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id:
                return <GamedayScheduleDashboard clientCode={clientCode} />;

            case GAMEDAY_SCHEDULE_PAGES.SELECTION.id:
                return <GamedayScheduleSelectionView clientCode={clientCode} />;

            case GAMEDAY_SCHEDULE_PAGES.CONTENT.id:
                return <GamedayScheduleContentView clientCode={clientCode} />;

            case GAMEDAY_SCHEDULE_PAGES.TIME.id:
                return <GamedayScheduleTimeView clientCode={clientCode} />;

            case GAMEDAY_SCHEDULE_PAGES.SUMMARY.id:
                return <GamedayScheduleSummary clientCode={clientCode} />;

            default:
                return <span>Invalid page id</span>;
        }
    };

    return getContent();
};

GamedayScheduleContainer.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,

    resetAllData: PropTypes.func,
};

GamedayScheduleContainer.defaultProps = {
    clientCode: "",
    controls: {},
    resetAllData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    resetAllData: (payload) =>
        dispatch(GamedayScheduleActions.resetAllData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleContainer);
