import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    EvBackButton,
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvToast,
} from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import { LOADING_STATUS, RESPONSE_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger, {
    LOG_ACTION_TYPE,
    LOG_CATEGORY_TYPES,
} from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import { getData, postData } from "../service/GamedayScheduleService";
import {
    GAMEDAY_SCHEDULE_COMPONENTS,
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_STATIC,
    GAMEDAY_SCHEDULE_TEMPLATES,
} from "../static/GamedayScheduleStatics";
import "../styles/gameday-schedule-time-view.scss";

const styles = {
    container: "ev__gameday-schedule-time-view__container",
    header: {
        container: "ev__gameday-schedule-time-view__header-container",
    },
    formView: {
        container: "ev__gameday-schedule-time-view__form-view-container",
    },
    actionView: {
        container: "ev__gameday-schedule-time-view__action-view-container",
        button: "ev__gameday-schedule-time-view__action-view-button",
    },
};

const GamedayScheduleTimeView = (props) => {
    const {
        clientCode,
        controls,
        staticData,

        getStaticData,
        setControls,
        setDynamicDataApiStatus,
    } = props;

    const currentGameData = controls.currentGameData || {};

    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.GAME_DAY_SCHEDULE.TIME.STATIC,
                    templateId: GAMEDAY_SCHEDULE_TEMPLATES.TIME.id,
                }),
                getData(API_URLS.GAME_DAY_SCHEDULE.TIME.PREFILL, {
                    clientCode,
                    gameId: currentGameData.gameId,
                    id: controls.scheduleId,
                }),
            ]),
        [clientCode, controls.scheduleId, currentGameData.gameId, getStaticData]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_SCHEDULE_COMPONENTS.GAME_TIME.id]: LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, prefillDataResponse]) => {
                if (
                    prefillDataResponse.data.responseStatus ===
                    RESPONSE_STATUS.SUCCESS
                ) {
                    const initialPrefillData = parseInitialStateFromFormFields(
                        staticDataResponse.formFields,
                        prefillDataResponse.data.data
                    );
                    setFormData(initialPrefillData);
                    setDynamicDataApiStatus({
                        [GAMEDAY_SCHEDULE_COMPONENTS.GAME_TIME.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                } else if (
                    prefillDataResponse.data.responseStatus ===
                    RESPONSE_STATUS.SHOW_ERROR
                ) {
                    EvToast.error("Sorry", prefillDataResponse.data.message);
                    throw new Error(prefillDataResponse.data);
                } else {
                    throw new Error(prefillDataResponse);
                }
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.GAME_TIME.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.error(
                    `GamedayScheduleTimeView initial load data: ${clientCode}, error ${JSON.stringify(
                        e
                    )}`,
                    LOG_CATEGORY_TYPES.CC,
                    LOG_ACTION_TYPE.LOAD
                );
            });
    }, [clientCode, getAllData, setDynamicDataApiStatus]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_SCHEDULE_COMPONENTS.GAME_TIME.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", GAMEDAY_SCHEDULE_STATIC.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.GAME_DAY_SCHEDULE.TIME.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                id: controls.scheduleId,
                gameId: currentGameData.gameId,
            }
        )
            .then((submitResponse) => {
                setControls({
                    scheduleId: submitResponse.data.data.id,
                    currentPageId: GAMEDAY_SCHEDULE_PAGES.SUMMARY.id,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "GamedayScheduleTimeView onSubmitClick"
                );
            });
    }, [
        clientCode,
        controls.scheduleId,
        currentGameData.gameId,
        formData,
        setControls,
        submitStatus,
    ]);

    const onBackButtonClick = useCallback(() => {
        setControls({
            currentPageId: GAMEDAY_SCHEDULE_PAGES.CONTENT.id,
        });
    }, [setControls]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackButtonClick}>
            {GAMEDAY_SCHEDULE_STATIC.backButtonText}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            className={styles.header.container}
            header={staticData.header}
            headerResolvers={{
                gameName: currentGameData.gameName,
            }}
        />
    );

    const getFormView = () => (
        <div className={styles.formView.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormData}
            />
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionView.container}
            buttonClass={styles.actionView.button}
            buttonText={staticData.submitCta.text}
            onSubmitClick={onSubmitClick}
            loadingStatus={submitStatus}
        />
    );

    if (
        !isCompleted(
            controls.dynamicDataApiStatus[
                GAMEDAY_SCHEDULE_COMPONENTS.GAME_TIME.id
            ],
            controls.staticDataApiStatus[GAMEDAY_SCHEDULE_TEMPLATES.TIME.id]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getFormView()}
            {getActionView()}
        </div>
    );
};

GamedayScheduleTimeView.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

GamedayScheduleTimeView.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    getStaticData: () => {},
    setControls: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
    staticData:
        state.GamedayScheduleReducer.staticData[
            GAMEDAY_SCHEDULE_TEMPLATES.TIME.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(GamedayScheduleActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(GamedayScheduleActions.setDynamicDataApiStatus(payload)),
    setControls: (payload) =>
        dispatch(GamedayScheduleActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleTimeView);
