import React from "react";

import {
    EvDivider,
    EvInfoBox,
    EvSimpleCard,
    EvText,
} from "../../../common/components";
import {
    EvSimpleItemCard,
    Ev360Card,
    EvCardImageLandscape,
    EvCardImagePortrait,
    EvCardWhereTo,
    EvPreviewActionCard,
    EvPreviewChecklistCard,
    EvPreviewQuestionCard,
    EvPreviewResourceCard,
    EvGameContentCard,
    EvClientCard,
} from "../../../common/components/ev-cards";
import {
    ACTION_CARD_DATA,
    CHECKLIST_CARD_DATA,
    CLIENT_CARD_DATA,
    EVIVE_360_CARD_DATA,
    GAME_CONTENT_CARD_DATA,
    INFO_CARD_DATA,
    LANDSCAPE_IMAGE_CARD_DATA,
    PORTRAIT_IMAGE_CARD_DATA,
    QUESTION_CARD_DATA,
    RESOURCE_CARD_DATA,
    SIMPLE_ITEM_CARD_DATA,
    WTGFC_CARD_DATA,
} from "../statics/EvLibraryCardsStatic";

import "../styles/ev-library-cards.scss";

const styles = {
    container: "ev__ev-library-cards__container",
    contentContainer: "ev__ev-library-cards__content-container",
    cards: {
        container: "ev__ev-library-cards__cards-container",
        listContainer: "ev__ev-library-cards__cards-list-container",
        headerContainer: "ev__ev-library-cards__cards-header-container",
        wrapper: "ev__ev-library-cards__cards-wrapper",
        sizeMini: "ev__ev-library-cards__cards-size-mini",
        sizeSmall: "ev__ev-library-cards__cards-size-small",
        sizeMedium: "ev__ev-library-cards__cards-size-medium",
    },
};

const EvLibraryCards = () => {
    const getInfoCard = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Info Box</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvInfoBox
                        inlineContainerStyle={{ maxWidth: 500 }}
                        header={INFO_CARD_DATA.data1.header}
                        description={INFO_CARD_DATA.data1.description}
                        iconName={INFO_CARD_DATA.data1.iconName}
                    />
                </div>
            </div>
        </div>
    );

    const getSimpleItemCard = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Simple Item Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvSimpleItemCard
                        className={styles.cards.sizeSmall}
                        {...SIMPLE_ITEM_CARD_DATA.data1}
                    />
                    <EvSimpleItemCard
                        className={styles.cards.sizeSmall}
                        {...SIMPLE_ITEM_CARD_DATA.data2}
                    />
                    <EvSimpleItemCard
                        className={styles.cards.sizeSmall}
                        {...SIMPLE_ITEM_CARD_DATA.data3}
                    />
                </div>
            </div>
        </div>
    );

    const getSimpleCard = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Simple Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvSimpleCard className={styles.cards.sizeMedium}>
                        <EvText headingSecondary>
                            Can contain any text or any other component
                        </EvText>
                        <EvText>
                            Ten the hastened steepest feelings pleasant few
                            surprise property. An brother he do colonel against
                            minutes uncivil. Can how elinor warmly mrs basket
                            marked. Led raising expense yet demesne weather
                            musical. Me mr what park next busy ever. Elinor her
                            his secure far twenty eat object. Late any far saw
                            size want man. Which way you wrong add shall one. As
                            guest right of he scale these. Horses nearer oh
                            elinor of denote.
                        </EvText>
                    </EvSimpleCard>
                </div>
            </div>
        </div>
    );

    const getGamePreviewCard = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Game Content Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvGameContentCard
                        className={styles.cards.sizeMedium}
                        {...GAME_CONTENT_CARD_DATA.data1}
                    />
                    <EvGameContentCard
                        className={styles.cards.sizeMedium}
                        {...GAME_CONTENT_CARD_DATA.data2}
                    />
                </div>
            </div>
        </div>
    );

    const getEvive360Cards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Evive 360 Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <Ev360Card
                        className={styles.cards.sizeMedium}
                        {...EVIVE_360_CARD_DATA.data1}
                    />
                    <Ev360Card
                        className={styles.cards.sizeMedium}
                        {...EVIVE_360_CARD_DATA.data2}
                    />
                </div>
            </div>
        </div>
    );

    const getWTGFCCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Where To Go For Care Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvCardWhereTo
                        className={styles.cards.sizeMedium}
                        {...WTGFC_CARD_DATA.data1}
                    />
                    <EvCardWhereTo
                        className={styles.cards.sizeMedium}
                        {...WTGFC_CARD_DATA.data2}
                    />
                </div>
            </div>
        </div>
    );

    const getActionCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Action Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvPreviewActionCard
                        className={styles.cards.sizeMedium}
                        {...ACTION_CARD_DATA.data1}
                    />
                    <EvPreviewActionCard
                        className={styles.cards.sizeSmall}
                        {...ACTION_CARD_DATA.data2}
                    />
                </div>
            </div>
        </div>
    );

    const getQuestionCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Question Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvPreviewQuestionCard
                        className={styles.cards.sizeMedium}
                        {...QUESTION_CARD_DATA.data1}
                    />
                </div>
            </div>
        </div>
    );

    const getChecklistCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Checklist Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvPreviewChecklistCard
                        className={styles.cards.sizeMedium}
                        {...CHECKLIST_CARD_DATA.data1}
                    />
                </div>
            </div>
        </div>
    );

    const getImageLandscapeCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Landscape Image Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvCardImageLandscape
                        className={styles.cards.sizeMedium}
                        {...LANDSCAPE_IMAGE_CARD_DATA.data1}
                    />
                    <EvCardImageLandscape
                        className={styles.cards.sizeSmall}
                        {...LANDSCAPE_IMAGE_CARD_DATA.data2}
                    />
                    <EvCardImageLandscape
                        className={styles.cards.sizeSmall}
                        {...LANDSCAPE_IMAGE_CARD_DATA.data3}
                    />
                    <EvCardImageLandscape
                        className={styles.cards.sizeSmall}
                        {...LANDSCAPE_IMAGE_CARD_DATA.data4}
                    />
                    <EvCardImageLandscape
                        className={styles.cards.sizeSmall}
                        {...LANDSCAPE_IMAGE_CARD_DATA.data5}
                    />
                </div>
            </div>
        </div>
    );

    const getImagePortraitCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Portrait Image Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvCardImagePortrait
                        className={styles.cards.sizeMedium}
                        {...PORTRAIT_IMAGE_CARD_DATA.data1}
                    />
                    <EvCardImagePortrait
                        className={styles.cards.sizeMini}
                        {...PORTRAIT_IMAGE_CARD_DATA.data2}
                    />
                    <EvCardImagePortrait
                        className={styles.cards.sizeMini}
                        {...PORTRAIT_IMAGE_CARD_DATA.data3}
                    />
                    <EvCardImagePortrait
                        className={styles.cards.sizeMini}
                        {...PORTRAIT_IMAGE_CARD_DATA.data4}
                    />
                    <EvCardImagePortrait
                        className={styles.cards.sizeMedium}
                        {...PORTRAIT_IMAGE_CARD_DATA.data4}
                    />
                </div>
            </div>
        </div>
    );

    const getResourceCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Resource Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvPreviewResourceCard
                        className={styles.cards.sizeMedium}
                        {...RESOURCE_CARD_DATA.data1}
                    />
                    <EvPreviewResourceCard
                        className={styles.cards.sizeMedium}
                        {...RESOURCE_CARD_DATA.data2}
                    />
                    <EvPreviewResourceCard
                        className={styles.cards.sizeMedium}
                        {...RESOURCE_CARD_DATA.data3}
                    />
                    <EvPreviewResourceCard
                        className={styles.cards.sizeMedium}
                        {...RESOURCE_CARD_DATA.data4}
                    />
                </div>
            </div>
        </div>
    );

    const getClientCards = () => (
        <div className={styles.cards.container}>
            <div className={styles.cards.headerContainer}>
                <EvText subHeading>Client Card</EvText>
            </div>
            <EvDivider marginVertical={10} />
            <div className={styles.cards.listContainer}>
                <div className={styles.cards.wrapper}>
                    <EvClientCard
                        className={styles.cards.sizeMedium}
                        {...CLIENT_CARD_DATA.data1}
                    />
                </div>
            </div>
        </div>
    );

    const getContent = () => (
        <div className={styles.contentContainer}>
            {getInfoCard()}
            {getSimpleItemCard()}
            {getSimpleCard()}
            {getGamePreviewCard()}
            {getEvive360Cards()}
            {getWTGFCCards()}
            {getActionCards()}
            {getQuestionCards()}
            {getChecklistCards()}
            {getImageLandscapeCards()}
            {getImagePortraitCards()}
            {getResourceCards()}
            {getClientCards()}
        </div>
    );

    return <div className={styles.container}>{getContent()}</div>;
};

export default EvLibraryCards;
