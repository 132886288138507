import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock, apiPost } from "../../../services/api";
// import getClientCardsDashboardDataJSONOLD from "../statics/getClientCardsDashboardDataJSONOLD";
import EvLogger from "../../../utils/EvLogger";
import getClientCardsDataJSON from "../statics/getClientCardsDataJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // TEMPLATES
        case API_URLS.CLIENT_CARDS.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.DASHBOARD.STATIC)
                : apiGet(url, params);

        case API_URLS.CLIENT_CARDS.CATEGORIES.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.CATEGORIES.STATIC)
                : apiGet(url, params);

        case API_URLS.CLIENT_CARDS.REORDER.STATICS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.REORDER.STATIC)
                : apiGet(url, params);

        case API_URLS.CLIENT_CARDS.CARD_TYPES:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.CARD_TYPES_STATIC)
                : apiGet(url, params);

        // COMPONENTS
        case API_URLS.CLIENT_CARDS.CATEGORIES.SECTIONS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.SECTIONS)
                : apiGet(url, params);

        case API_URLS.CLIENT_CARDS.DASHBOARD.CARD_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientCardsDataJSON.CARDS_LIST)
                : apiGet(url, params);

        case API_URLS.CLIENT_CARDS.DASHBOARD.VENDOR_PROGRAM_LIST:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getClientCardsDataJSON.DASHBOARD.VENDOR_PROGRAM_LIST
                  )
                : apiGet(url, params);

        default:
            EvLogger.warn("ClientCardsService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.CLIENT_CARDS.REORDER.SAVE_CARD_ORDER:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      getClientCardsDataJSON.REORDER.SAVE_ORDER,
                      data
                  )
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("ClientCardsService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
