import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import { EvSideNavBar } from "../../../common/components";
import { CLIENT_CONTENT_PAGES } from "../static/ClientContentStatics";
import { NotificationsContainer } from "../../notifications/views";
import EmployerCardContainer from "../../employer-card/views/EmployerCardContainer";
import ClientCardsRoutes from "../../client-cards/views/ClientCardsRoutes";
import GamedayScheduleContainer from "../../gameday-schedule/views/GamedayScheduleContainer";

import "../styles/client-content-view.scss";

const styles = {
    container: "ev__client-content-view__container",
    sidebarContainer: "ev__client-content-view__sidebar-container",
    applicationContainer: "ev__client-content-view__application-container",
};

const ClientContentView = (props) => {
    const {
        clientCode,
        clientName,
        selectedApplicationId,
        staticData,

        onSidebarClick,
    } = props;

    const getSideBar = () => (
        <div className={styles.sidebarContainer}>
            <EvSideNavBar
                header={staticData.header}
                listData={staticData.sideBarsTabs}
                selectedId={selectedApplicationId}
                onClickHandler={onSidebarClick}
            />
        </div>
    );

    const getApplication = () => (
        <div className={styles.applicationContainer}>
            <Switch>
                <Route
                    path={CLIENT_CONTENT_PAGES.NOTIFICATIONS.path}
                    exact
                    render={() => (
                        <NotificationsContainer clientCode={clientCode} />
                    )}
                />
                <Route
                    path={CLIENT_CONTENT_PAGES.EMPLOYER_CARD.path}
                    exact
                    render={() => (
                        <EmployerCardContainer clientCode={clientCode} />
                    )}
                />
                <Route
                    path={CLIENT_CONTENT_PAGES.CARDS_DASHBOARD.path}
                    render={() => (
                        <ClientCardsRoutes
                            clientCode={clientCode}
                            clientName={clientName}
                        />
                    )}
                />
                <Route
                    path={CLIENT_CONTENT_PAGES.GAMEDAY_SCHEDULE.path}
                    exact
                    render={() => (
                        <GamedayScheduleContainer clientCode={clientCode} />
                    )}
                />
            </Switch>
        </div>
    );

    return (
        <div className={styles.container}>
            {getSideBar()}
            {getApplication()}
        </div>
    );
};

ClientContentView.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    selectedApplicationId: PropTypes.string,
    staticData: PropTypes.object,

    onSidebarClick: PropTypes.func,
};

ClientContentView.defaultProps = {
    clientCode: "",
    clientName: "",
    selectedApplicationId: "",
    staticData: {},

    onSidebarClick: () => {},
};

export default ClientContentView;
