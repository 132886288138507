import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvSimpleCard, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/ev-cards/ev-card-where-to.scss";

const styles = {
    container: "ev__ev-card-where-to__container",
    innerContainer: "ev__ev-card-where-to__inner-container",
    headerView: {
        container: "ev__ev-card-where-to__header-view-container",
        headerText: "ev__ev-card-where-to__header-view-header-text",
        descriptionText: "ev__ev-card-where-to__header-view-description-text",
    },
    list: {
        container: "ev__ev-card-where-to__list-container",
        itemContainer: "ev__ev-card-where-to__list-item-container",
        icon: "ev__ev-card-where-to__list-item-icon",
        text: "ev__ev-card-where-to__list-item-text",
    },
    amount: {
        container: "ev__ev-card-where-to__amount-container",
        wrapper: "ev__ev-card-where-to__amount-wrapper",
        text: "ev__ev-card-where-to__amount-text",
    },
    actionView: {
        container: "ev__ev-card-where-to__action-view-container",
        button: "ev__ev-card-where-to__action-view-button",
    },
};

const EvCardWhereTo = (props) => {
    const { header, description, list, amountText, ctaText, className } = props;

    const getHeader = () => (
        <div className={styles.headerView.container}>
            <EvText
                headingSecondary
                color={COLORS.PRODUCT}
                className={styles.headerView.headerText}
            >
                {header}
            </EvText>
            <EvText defaultBold className={styles.headerView.descriptionText}>
                {description}
            </EvText>
        </div>
    );

    const getListItem = (itemData, i) => (
        <div key={i} className={styles.list.itemContainer}>
            <EvIcon
                iconName="CIRCLE"
                fillColor={COLORS.BLACK_TROUT}
                size={3}
                outerContainerStyleClass={styles.list.icon}
            />
            <EvText className={styles.list.text}>{itemData}</EvText>
        </div>
    );

    const getListView = () => (
        <div className={styles.list.container}>{list.map(getListItem)}</div>
    );

    const getAmountView = () => (
        <div className={styles.amount.container}>
            <div className={styles.amount.wrapper}>
                <EvText
                    defaultBold
                    color={COLORS.PRODUCT}
                    className={styles.amount.text}
                >
                    {amountText}
                </EvText>
            </div>
        </div>
    );

    const getActionButton = (currentCtaText) => (
        <EvButton primaryFilled className={styles.actionView.button}>
            {currentCtaText}
        </EvButton>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            {typeof ctaText === "string"
                ? getActionButton(ctaText)
                : ctaText.map(getActionButton)}
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding>
                <div className={styles.innerContainer}>
                    {getHeader()}
                    {getListView()}
                    {getAmountView()}
                    {getActionView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvCardWhereTo.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    list: PropTypes.array,
    amountText: PropTypes.string,
    ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

    className: PropTypes.string,
};

EvCardWhereTo.defaultProps = {
    header: "",
    description: "",
    list: [],
    amountText: "",
    ctaText: "",

    className: "",
};

export default EvCardWhereTo;
