import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    EvPills,
    EvContentModal,
    EvText,
    EvIcon,
    EvLoadingPage,
    EvToast,
    EvButton,
    EvExpandableCard,
    EvSearchBar,
} from "..";
import { getFirstIdFromSelectedFormData } from "../../../utils/FormUtils";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import { COLORS } from "../../static/VmsStatics";
import { CACHE_DATA_TYPE, LOADING_STATUS } from "../../static/Enums";
import FormValidation from "../../../utils/FormValidations";
import CacheActions from "../../../reducers/cache/CacheActions";
import EvLogger from "../../../utils/EvLogger";
import { EvInputErrorView } from "./index";
import fuzzySearch from "../../../utils/FuzzySearch";

import "../../styles/form-components/ev-lazy-modal-selection.scss";

const DATA_SOURCE_TYPE = {
    FROM_EXTERNAL_URL: "FROM_EXTERNAL_URL", // DEFAULT
    FROM_PROPS: "FROM_PROPS",
    INTERNAL: "INTERNAL", // not yet implemented
};

const styles = {
    container: "ev__ev-lazy-modal-selection__container",
    placeholderContainer: "ev__ev-lazy-modal-selection__placeholder-container",
    selectedContainer: "ev__ev-lazy-modal-selection__selected-container",
    customPillPlaceholder:
        "ev__ev-lazy-modal-selection__custom-pill-placeholder",
    customPillSelected: "ev__ev-lazy-modal-selection__custom-pill-selected",
    customPillSelectedButton:
        "ev__ev-lazy-modal-selection__custom-pill-selected-button",
    modal: {
        modalCustomClass: "ev__ev-lazy-modal-selection__modal-custom-class",
        loadingContainer:
            "ev__ev-lazy-modal-selection__modal-loading-container",
        container: "ev__ev-lazy-modal-selection__modal-container",
        headerContainer: "ev__ev-lazy-modal-selection__modal-header-container",
        headerText: "ev__ev-lazy-modal-selection__modal-header-text",
        listView: "ev__ev-lazy-modal-selection__modal-list-view",
        listContainer: "ev__ev-lazy-modal-selection__modal-list-container",
        descriptionText: "ev__ev-lazy-modal-selection__modal-description-text",
        listItemWrapper: "ev__ev-lazy-modal-selection__modal-list-item-wrapper",
    },
    normal: {
        container: "ev__ev-lazy-modal-selection__normal-container",
        wrapper: "ev__ev-lazy-modal-selection__normal-wrapper",
    },
};

const EvLazyModalSelection = (props) => {
    const {
        keyName,
        placeholder,
        formData,
        dataOptions,
        minimalView,
        formItem,
        showError,
        showClearIcon,
        handleInputChange,
        qParams,

        getCachedData,
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalExpandedId, setModalExpandedId] = useState("");

    const [modalStaticData, setModalStaticData] = useState({});
    const [
        modalStaticDataLoadingStatus,
        setModalStaticDataLoadingStatus,
    ] = useState(LOADING_STATUS.NOT_YET_STARTED);

    const [modalListData, setModalListData] = useState({});
    const [filteredModalListData, setFilteredModalListData] = useState({});
    const [
        modalListDataLoadingStatus,
        setModalListDataLoadingStatus,
    ] = useState(LOADING_STATUS.NOT_YET_STARTED);

    const getModalData = useCallback(() => {
        // FOR STATIC DATA
        if (isNullOrEmpty(formItem.staticDataEndpoint)) {
            // FOR FROM PROPS
            setModalStaticData(formItem.staticData);
            setModalStaticDataLoadingStatus(LOADING_STATUS.COMPLETED);
        } else if (!isCompleted(modalStaticDataLoadingStatus)) {
            // FOR EXTERNAL
            setModalStaticDataLoadingStatus(LOADING_STATUS.LOADING);
            getCachedData({
                url: formItem.staticDataEndpoint,
                type: CACHE_DATA_TYPE.LAZY_MODAL,
            })
                .then((response) => {
                    setModalStaticData(response);
                    setModalStaticDataLoadingStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    EvToast.error("Sorry", "Something went wrong!");
                    EvLogger.errorWithObject(
                        e,
                        "EvLazyModalSelection getStaticData"
                    );
                });
        }

        // FOR LIST DATA
        if (
            formItem.options &&
            formItem.options.lazyType === DATA_SOURCE_TYPE.FROM_PROPS
        ) {
            // FOR FROM PROPS
            const currentListData = isNullOrEmpty(dataOptions)
                ? []
                : dataOptions;
            setModalListData(currentListData);
            setFilteredModalListData(currentListData);
            setModalListDataLoadingStatus(LOADING_STATUS.COMPLETED);
        } else if (!isCompleted(modalListDataLoadingStatus)) {
            // FOR EXTERNAL
            setModalListDataLoadingStatus(LOADING_STATUS.LOADING);
            const listTargetKey =
                formItem.options && formItem.options.listTargetKey
                    ? formItem.options.listTargetKey
                    : "mappedField";
            getCachedData({
                url: formItem.listDataEndpoint,
                qParams: {
                    ...qParams,
                    id: keyName,
                },
            })
                .then((response) => {
                    const currentListData = isNullOrEmpty(
                        response[listTargetKey]
                    )
                        ? []
                        : response[listTargetKey];
                    setModalListData(currentListData);
                    setFilteredModalListData(currentListData);
                    setModalListDataLoadingStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    EvToast.error("Sorry", "Something went wrong!");
                    EvLogger.errorWithObject(
                        e,
                        "EvLazyModalSelection getListData"
                    );
                });
        }
    }, [
        dataOptions,
        formItem.listDataEndpoint,
        formItem.options,
        formItem.staticData,
        formItem.staticDataEndpoint,
        getCachedData,
        keyName,
        modalListDataLoadingStatus,
        modalStaticDataLoadingStatus,
        qParams,
    ]);

    const selectedId = useMemo(() => getFirstIdFromSelectedFormData(formData), [
        formData,
    ]);

    const onPillClick = useCallback(() => {
        setIsModalVisible(true);
        getModalData();
    }, [setIsModalVisible, getModalData]);

    const onSearchValueChange = useCallback(
        (value) => {
            if (value) {
                const newFilteredList = fuzzySearch(modalListData, value);
                setFilteredModalListData(newFilteredList);
            } else {
                setFilteredModalListData(modalListData);
            }
        },
        [modalListData]
    );

    // Start of modal functions

    const onModalCloseClick = useCallback(() => {
        setIsModalVisible(false);
        setModalExpandedId("");
    }, [setIsModalVisible, setModalExpandedId]);

    const onModalHeaderClick = useCallback(
        (e, callbackOptions) => {
            if (modalExpandedId === callbackOptions.id) {
                // if clicked on same, then collapse
                setModalExpandedId("");
            } else {
                setModalExpandedId(callbackOptions.id);
            }
        },
        [setModalExpandedId, modalExpandedId]
    );

    const onModalItemSelect = useCallback(
        (e, callbackOptions) => {
            setIsModalVisible(false);
            setModalExpandedId("");

            const inputFieldObject = {
                [callbackOptions.id]: {
                    key: callbackOptions.id,
                    value: callbackOptions.value,
                    name: callbackOptions.name,
                    type: formItem.type,
                },
                errorObject: {}, // will be overwritten later
                formItemType: formItem.type,
            };

            handleInputChange(keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [
            handleInputChange,
            setIsModalVisible,
            setModalExpandedId,
            formData.errorObject,
            formItem,
            keyName,
        ]
    );

    const onClearIconClick = useCallback(() => {
        const inputFieldObject = {
            [selectedId]: {},
            errorObject: {}, // will be overwritten later
            formItemType: formItem.type,
        };
        handleInputChange(keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    }, [
        handleInputChange,
        formItem,
        formData.errorObject,
        keyName,
        selectedId,
    ]);

    // End of modal functions

    // Start of modal render functions

    const getModalHeaderView = () => (
        <div className={styles.modal.headerContainer}>
            <EvText className={styles.modal.headerText} subHeading>
                {modalStaticData.header}
            </EvText>
            <EvButton onlyChild onClickHandler={onModalCloseClick}>
                <EvIcon
                    iconName="CROSS_THICK"
                    size={3}
                    circular
                    fillColor={COLORS.WHITE}
                    bgColor={COLORS.BLACK_TROUT}
                />
            </EvButton>
        </div>
    );

    const getModalListItemView = (itemData) => (
        <div key={itemData.id}>
            <EvExpandableCard
                id={itemData.id}
                value={itemData.value}
                name={itemData.name}
                description={itemData.description}
                ctaText={modalStaticData.addButtonCta}
                isExpanded={itemData.id === modalExpandedId}
                onHeaderClick={onModalHeaderClick}
                isSelected={itemData.id === selectedId}
                ctaAction={onModalItemSelect}
            />
        </div>
    );

    const getNoDataListView = (message) => (
        <div>
            <EvText italics>{message}</EvText>
        </div>
    );

    const getSearchView = () => (
        <EvSearchBar
            placeholder={formItem.options.searchPlaceholder}
            onChange={onSearchValueChange}
        />
    );

    const getModalListView = () => (
        <div className={styles.modal.listView}>
            <div className={styles.modal.descriptionText}>
                <EvText>{modalStaticData.description}</EvText>
            </div>
            <div className={styles.modal.listContainer}>
                {modalListData.length <= 0 &&
                    getNoDataListView(formItem.staticData.noDataListMessage)}
                {modalListData.length > 0 &&
                    filteredModalListData.length <= 0 &&
                    getNoDataListView(formItem.options.searchNoResultMessage)}
                {modalListData.length > 0 &&
                    filteredModalListData.length > 0 &&
                    filteredModalListData.map(getModalListItemView)}
            </div>
        </div>
    );

    const getModalLoadedContent = () => (
        <div className={styles.modal.container}>
            {getModalHeaderView()}
            {!isNullOrEmpty(formItem.options) &&
                formItem.options.allowSearch &&
                modalListData.length > 0 &&
                getSearchView()}
            {getModalListView()}
        </div>
    );

    const getModalContentDecider = () => {
        if (
            isCompleted(
                modalStaticDataLoadingStatus,
                modalListDataLoadingStatus
            )
        ) {
            return getModalLoadedContent();
        }
        if (
            modalStaticDataLoadingStatus === LOADING_STATUS.FAILED ||
            modalListDataLoadingStatus === LOADING_STATUS.FAILED
        ) {
            return <span>Sorry, Something went wrong!</span>;
        }

        if (
            modalStaticDataLoadingStatus === LOADING_STATUS.LOADING ||
            modalListDataLoadingStatus === LOADING_STATUS.LOADING
        ) {
            return <EvLoadingPage className={styles.modal.loadingContainer} />;
        }

        return <span />;
    };

    const getModalView = () => (
        <EvContentModal
            contentClassName={styles.modal.modalCustomClass}
            isVisible={isModalVisible}
            exitOnOverlayClick={false}
        >
            {getModalContentDecider()}
        </EvContentModal>
    );

    // End of modal functions

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    const getMinimalPlaceholderView = () => (
        <div className={styles.placeholderContainer}>
            <EvPills
                className={styles.customPillPlaceholder}
                textOptions={{ defaultBold: true, primaryColored: true }}
                value={placeholder}
                onIconClick={onPillClick}
                onValueClick={onPillClick}
            />
        </div>
    );

    const getSelectedView = () => (
        <div className={styles.selectedContainer}>
            <EvPills
                className={styles.customPillSelected}
                value={formData[selectedId].name}
                // iconName="EDIT"
                iconOptions={{ size: 3 }}
                onIconClick={onPillClick}
                onValueClick={onPillClick}
            />
        </div>
    );

    const getNormalPlaceholderPill = () => (
        <EvPills
            className={styles.customPillPlaceholder}
            textOptions={{ defaultBold: true, primaryColored: true }}
            value={placeholder}
            onIconClick={onPillClick}
            onValueClick={onPillClick}
        />
    );

    const getNormalSelectedVPill = () => (
        <EvPills
            className={styles.customPillSelected}
            buttonClassName={styles.customPillSelectedButton}
            value={formData[selectedId].name}
            onValueClick={onPillClick}
            onIconClick={showClearIcon ? onClearIconClick : null}
            iconName={showClearIcon ? "CROSS_THIN" : ""}
        />
    );

    const getMinimalView = () =>
        isNullOrEmpty(formData[selectedId])
            ? getMinimalPlaceholderView()
            : getSelectedView();

    const getNormalView = () => (
        <div className={styles.normal.container}>
            <div className={styles.normal.wrapper}>
                {isNullOrEmpty(formData[selectedId])
                    ? getNormalPlaceholderPill()
                    : getNormalSelectedVPill()}
            </div>
            {showError && getErrorView()}
        </div>
    );

    return (
        <div className={styles.container}>
            {minimalView ? getMinimalView() : getNormalView()}
            {getModalView()}
        </div>
    );
};

EvLazyModalSelection.propTypes = {
    minimalView: PropTypes.bool,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    qParams: PropTypes.object,
    dataOptions: PropTypes.array,
    placeholder: PropTypes.string,
    keyName: PropTypes.string,
    showError: PropTypes.bool,
    showClearIcon: PropTypes.bool,
    handleInputChange: PropTypes.func,

    getCachedData: PropTypes.func,
};

EvLazyModalSelection.defaultProps = {
    minimalView: true,
    placeholder: "",
    keyName: "",
    formItem: {},
    formData: {},
    qParams: {},
    dataOptions: [],
    showError: false,
    showClearIcon: false,
    handleInputChange: () => {},

    getCachedData: () => {},
};

const mapStateToProps = (state) => ({
    // controls: state.UserMappingReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getCachedData: (payload) => dispatch(CacheActions.getCachedData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EvLazyModalSelection);
