import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
    EvBackButton,
    EvButton,
    EvHeaderView,
} from "../../../../common/components";
import {
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAMS_STATICS,
} from "../../statics/VendorProgramsStatics";
import VendorProgramsReviewCard from "./VendorProgramsReviewCard";

import "../styles/new-vendor-programs-review.scss";

const styles = {
    container: "ev__new-vendor-programs-review__container",
    headerContainer: "ev__new-vendor-programs-review__header-container",
    actionView: {
        container: "ev__new-vendor-programs-review__action-view-container",
    },
    vendorPrograms: {
        container: "ev__new-vendor-programs-review__vendor-programs-container",
    },
};

const NewVendorProgramReview = (props) => {
    const {
        clientName,
        staticData,

        vendorData,
        clientVendorData,
        selectedVendorPrograms,

        goToInternalPage,
        submitSelectedPrograms,
    } = props;

    const onBackPress = useCallback(() => {
        goToInternalPage(VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM_SELECTION.id);
    }, [goToInternalPage]);

    const onSubmitClick = useCallback(() => {
        submitSelectedPrograms();
    }, [submitSelectedPrograms]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackPress}>
            {VENDOR_PROGRAMS_STATICS.BACK_BUTTON_TEXT}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            dangerous
            header={staticData.header}
            headerResolvers={{ clientName }}
            description={staticData.subHeader}
            className={styles.headerContainer}
        />
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton onClickHandler={onSubmitClick} primaryFilled>
                {staticData.proceedCta.text}
            </EvButton>
        </div>
    );

    const getVendorProgramCard = ([vendorId, selectedPrograms]) => {
        const cardVendorData =
            clientVendorData.id === vendorId
                ? clientVendorData
                : vendorData.value[vendorId];

        return (
            <VendorProgramsReviewCard
                key={vendorId}
                staticData={staticData.vendorProgramCard}
                vendorData={cardVendorData}
                selectedPrograms={selectedPrograms}
            />
        );
    };

    const getVendorProgramsView = () => (
        <div className={styles.vendorPrograms.container}>
            {Object.entries(selectedVendorPrograms).map(getVendorProgramCard)}
        </div>
    );

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getActionView()}
            {getVendorProgramsView()}
        </div>
    );
};

NewVendorProgramReview.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    staticData: PropTypes.object,

    vendorData: PropTypes.object,
    clientVendorData: PropTypes.object,
    selectedVendorPrograms: PropTypes.object,

    goToInternalPage: PropTypes.func,
    submitSelectedPrograms: PropTypes.func,
};

NewVendorProgramReview.defaultProps = {
    clientCode: "",
    clientName: "",
    staticData: {},

    vendorData: {},
    clientVendorData: {},
    selectedVendorPrograms: {},

    goToInternalPage: () => {},
    submitSelectedPrograms: () => {},
};

export default NewVendorProgramReview;
