import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvLoader, EvText, EvTooltip } from "../index";
import { COLORS } from "../../static/VmsStatics";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../static/Enums";

import "../../styles/form-components/ev-dropdown-component.scss";

const styles = {
    container: "ev__ev-dropdown-component__container",
    selection: {
        container: "ev__ev-dropdown-component__selection-container",
        selectedContainer:
            "ev__ev-dropdown-component__selection-selected-container",
        selectedContainerOpen:
            "ev__ev-dropdown-component__selection-selected-container-open",
        valueContainer: "ev__ev-dropdown-component__selection-value-container",
        iconContainer: "ev__ev-dropdown-component__selection-icon-container",
    },
    dropdown: {
        container: "ev__ev-dropdown-component__dropdown-container",
        loadingContainer:
            "ev__ev-dropdown-component__dropdown-loading-container",
        itemWrapper: "ev__ev-dropdown-component__dropdown-item-wrapper",
    },
    dropdownItem: {
        container: "ev__ev-dropdown-component__dropdown-item-container",
    },
};

const EvFormDropdownItem = (props) => {
    const { id, name, value, onDropdownSelect, showTooltip } = props;

    const onItemClickMemo = useCallback(() => {
        onDropdownSelect(id, name, value);
    }, [onDropdownSelect, id, name, value]);

    const getItemView = () => (
        <EvTooltip
            wrapperClassName={styles.dropdownItem.container}
            value={value}
            showTooltip={showTooltip}
            offsetTop={40}
        >
            <EvText default>{name}</EvText>
        </EvTooltip>
    );

    return (
        <EvButton onlyChild onClickHandler={onItemClickMemo}>
            {getItemView()}
        </EvButton>
    );
};

EvFormDropdownItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    showTooltip: PropTypes.bool,

    onDropdownSelect: PropTypes.func,
};

EvFormDropdownItem.defaultProps = {
    id: "",
    name: "",
    value: "",
    showTooltip: false,

    onDropdownSelect: () => {},
};

const EvDropdownComponent = (props) => {
    const {
        placeholder,
        dataList,
        className,

        isLazyLoaded,
        editable,
        lazyListLoadingStatus,
        showTooltip,

        selectedId,
        selectedObject,

        onChange,

        minWidth,
    } = props;

    const dropdownContainerRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const onWindowMouseClick = (e) => {
        if (
            dropdownContainerRef.current &&
            !dropdownContainerRef.current.contains(e.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onWindowMouseClick);
        return () => {
            document.removeEventListener("mousedown", onWindowMouseClick);
        };
    }, []);

    const onDropdownIconClickMemo = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const onDropdownItemSelect = useCallback(
        (id, name, value) => {
            setIsOpen(false);
            onChange(id, name, value);
        },
        [onChange]
    );

    const selectedItemName = useMemo(() => {
        if (!isNullOrEmpty(selectedObject)) {
            return selectedObject.name;
        }
        const obj = dataList.find((dataItem) => dataItem.id === selectedId);

        return obj ? obj.name : placeholder;
    }, [selectedObject, dataList, placeholder, selectedId]);

    const getDropdownItemView = (dropdownItem) => (
        <div className={styles.dropdown.itemWrapper} key={dropdownItem.id}>
            <EvFormDropdownItem
                id={dropdownItem.id}
                name={dropdownItem.name}
                value={dropdownItem.value}
                onDropdownSelect={onDropdownItemSelect}
                showTooltip={showTooltip}
            />
        </div>
    );

    const getDropdownListView = (listData) => (
        <div className={styles.dropdown.container}>
            {listData.map(getDropdownItemView)}
        </div>
    );

    const getDropdown = () => {
        if (isLazyLoaded) {
            if (isCompleted(lazyListLoadingStatus)) {
                return getDropdownListView(dataList);
            }
            return (
                <div
                    className={classNames(
                        styles.dropdown.container,
                        styles.dropdown.loadingContainer
                    )}
                >
                    <EvLoader size={4} />
                </div>
            );
        }
        // normal list view. not lazy loaded
        return getDropdownListView(dataList);
    };

    const getSelectionValueView = () => (
        <div className={styles.selection.valueContainer}>
            <EvText default>{selectedItemName}</EvText>
        </div>
    );

    const getSelectedView = () => {
        const customContainerClass = classNames(
            styles.selection.selectedContainer,
            {
                [styles.selection.selectedContainerOpen]: isOpen,
            }
        );

        return (
            <EvButton onlyChild onClickHandler={onDropdownIconClickMemo}>
                <div className={customContainerClass} style={{ minWidth }}>
                    {getSelectionValueView()}
                    <EvIcon
                        outerContainerStyleClass={
                            styles.selection.iconContainer
                        }
                        iconName="ARROW_THICK_DOWN"
                        size={3}
                        fillColor={
                            editable ? COLORS.PRODUCT : COLORS.WHITE_GHOST
                        }
                    />
                </div>
            </EvButton>
        );
    };

    const getSelectionView = () => (
        <div className={styles.selection.container} ref={dropdownContainerRef}>
            {getSelectedView()}
            {isOpen && editable && getDropdown()}
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return <div className={customContainerClass}>{getSelectionView()}</div>;
};

EvDropdownComponent.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    dataList: PropTypes.array,

    editable: PropTypes.bool,
    isLazyLoaded: PropTypes.bool,
    showTooltip: PropTypes.bool,
    lazyListLoadingStatus: PropTypes.string,

    selectedId: PropTypes.string,
    selectedObject: PropTypes.object,

    onChange: PropTypes.func,

    minWidth: PropTypes.number,
};

EvDropdownComponent.defaultProps = {
    placeholder: "",
    className: "",
    dataList: [],
    selectedId: "",
    selectedObject: {},

    editable: true,
    isLazyLoaded: false,
    showTooltip: false,
    lazyListLoadingStatus: LOADING_STATUS.NOT_YET_STARTED,

    onChange: () => {},

    minWidth: 200,
};

export default EvDropdownComponent;
