import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock, apiPost } from "../../../services/api";
import getProgramTagsJSON from "../statics/getProgramTagsJSON";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // static data is in VendorProgramService
        // case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.STATIC:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getProgramTagsJSON.STATIC)
        //     : apiGet(url, params);
        //
        // case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SELECTION_MODAL_STATIC:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getProgramTagsJSON.SELECTION_MODAL_STATIC)
        //     : apiGet(url, params);

        // dynamic
        case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SELECTED_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getProgramTagsJSON.SELECTED_RULES)
                : apiGet(url, params);

        case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.ALL_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, params, getProgramTagsJSON.ALL_TAGS)
                : apiGet(url, params);

        default:
            EvLogger.warn("ProgramTagsService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SUBMIT_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getProgramTagsJSON.SUBMIT_TAGS, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn(
                `ProgramTagsService postData unknown URL: ${url}`,
                "cc",
                "load"
            );
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
