const SAVED_POPULATION_PAGES = {
    DASHBOARD: {
        id: "savedPopulationDashboard",
    },
    NEW_EDIT_POPULATION: {
        id: "savedPopulationNewEditPopulation",
    },
    TAG_SELECTION: {
        id: "savedPopulationTagSelection",
    },
};

const SAVED_POPULATION_TEMPLATES = {
    DASHBOARD: {
        id: "savedPopulationDashboard",
    },
    NEW_EDIT_POPULATION: {
        id: "savedPopulationNewEditPopulation",
    },
    TAG_SELECTION: {
        id: "savedPopulationTagSelection",
    },
    CRITERIA_RULE_FORM_DATA: {
        id: "savedPopulationCriteriaRuleFormData",
    },
    TAG_CATEGORIES: {
        id: "savedPopulationTagCategories",
    },
};

const SAVED_POPULATION_COMPONENTS = {
    ALL_TAGS: {
        id: "savedPopulationAllTags",
    },
    SAVED_POPULATION_LIST: {
        id: "savedPopulationList",
    },
    POPULATION_DATA: {
        id: "savedPopulationData",
    },
    POPULATION_CRITERIA_DATA: {
        id: "savedPopulationCriteriaData",
    },
};

export {
    SAVED_POPULATION_PAGES,
    SAVED_POPULATION_TEMPLATES,
    SAVED_POPULATION_COMPONENTS,
};
