import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import stringTemplate from "string-template";
import { EvButton, EvLoadingPage, EvText } from "../../../common/components";
import { EvSimpleItemCard } from "../../../common/components/ev-cards";
import CommonActions from "../../../common/redux/CommonActions";
import { getData } from "../../../common/service/CommonService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    GAMEDAY_CONTENT_APP_ID,
    GAMEDAY_CONTENT_COMPONENTS,
    GAMEDAY_CONTENT_PAGES,
    GAMEDAY_CONTENT_TEMPLATES,
} from "../statics/GamedayContentStatics";
import "../styles/gameday-content-dashboard.scss";

const styles = {
    container: "ev__gameday-content-dashboard__container",
    header: {
        container: "ev__gameday-content-dashboard__header-container",
        headerText: "ev__gameday-content-dashboard__header-header-text",
        descriptionText:
            "ev__gameday-content-dashboard__header-description-text",
    },
    actionView: {
        container: "ev__gameday-content-dashboard__action-view-container",
        button: "ev__gameday-content-dashboard__action-view-button",
    },
    games: {
        container: "ev__gameday-content-dashboard__games-container",
        noGamesText: "ev__gameday-content-dashboard__games-no-games-text",
        listContainer: "ev__gameday-content-dashboard__games-list-container",
        buttonWrapper: "ev__gameday-content-dashboard__games-button-wrapper",
        cardItem: "ev__gameday-content-dashboard__games-card-item",
    },
};

const GamedayContentDashboard = (props) => {
    const {
        controls,
        staticData,
        getStaticData,
        location,

        setDynamicDataApiStatus,
        navigateToPage,
    } = props;
    const [gameList, setGameList] = useState([]);
    const [featureId, setFeatureId] = useState("");

    const clientCode =
        location.state && location.state.clientCode
            ? location.state.clientCode
            : "";
    const vendorCardData =
        location.state && location.state.vendorCardData
            ? location.state.vendorCardData
            : {};
    const programId = vendorCardData.programId || "";
    const vendorId = vendorCardData.vendorId || "";
    // const featureId = vendorCardData.cardId || "";

    useEffect(() => {
        getStaticData({
            templateId: GAMEDAY_CONTENT_TEMPLATES.DASHBOARD_STATIC.id,
            url: API_URLS.GAME_DAY_CONTENT.DASHBOARD.STATIC,
        });
    }, [getStaticData]);

    useEffect(() => {
        // extra call when going back
        if (!programId) {
            return;
        }
        console.log("EFFECT for", clientCode, vendorId, programId);
        setDynamicDataApiStatus({
            [GAMEDAY_CONTENT_COMPONENTS.GAME_LIST.id]: LOADING_STATUS.LOADING,
        });
        getData(API_URLS.GAME_DAY_CONTENT.DASHBOARD.GAMES_LIST, {
            // programId: vendorCardData.programId,
            // vendorId: vendorCardData.vendorId,
            // featureId: vendorCardData.cardId,
            programId,
            vendorId,
            // featureId,
            clientCode,
        })
            .then((gamesListResponse) => {
                setGameList(gamesListResponse.data.data.gameList);
                setFeatureId(gamesListResponse.data.data.featureId);
                setDynamicDataApiStatus({
                    [GAMEDAY_CONTENT_COMPONENTS.GAME_LIST.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_CONTENT_COMPONENTS.GAME_LIST.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "GamedayContentDashboard getData");
            });
    }, [setDynamicDataApiStatus, programId, vendorId, clientCode]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_CONTENT_COMPONENTS.GAME_LIST.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onGameSettingsClick = useCallback(() => {
        navigateToPage({
            path: GAMEDAY_CONTENT_PAGES.GAME_CONFIGURE.path,
            state: {
                ...location.state,
                vendorCardData: {
                    ...location.state.vendorCardData,
                    featureId,
                },
            },
        });
    }, [navigateToPage, location, featureId]);

    const onGameItemClick = useCallback(
        (e, callbackValues) => {
            navigateToPage({
                path: GAMEDAY_CONTENT_PAGES.GAME.path,
                state: {
                    ...location.state,
                    vendorCardData: {
                        ...location.state.vendorCardData,
                        gameId: callbackValues.gameId,
                        gameType: callbackValues.gameType,
                        featureId,
                    },
                },
            });
        },
        [navigateToPage, location, featureId]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText dangerous subHeading className={styles.header.headerText}>
                {stringTemplate(staticData.header, vendorCardData)}
            </EvText>
            <EvText defaultDark className={styles.header.descriptionText}>
                {staticData.description}
            </EvText>
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            {gameList.length > 0 && (
                <EvButton
                    className={styles.actionView.button}
                    secondary
                    onClickHandler={onGameSettingsClick}
                >
                    {staticData.gameSettingsCta.text}
                </EvButton>
            )}
        </div>
    );

    const getGameItemCard = (gameItemData) => (
        <EvButton
            key={gameItemData.id}
            onlyChild
            className={styles.games.buttonWrapper}
            onClickHandler={onGameItemClick}
            callbackValues={{
                gameId: gameItemData.id,
                gameType: gameItemData.type,
            }}
        >
            <EvSimpleItemCard
                className={styles.games.cardItem}
                header={gameItemData.displayName}
                subHeader={staticData.gameTypes[gameItemData.type].displayName}
            />
        </EvButton>
    );

    const getGameList = () => {
        if (gameList.length <= 0) {
            return (
                <EvText className={styles.games.noGamesText} italics>
                    {staticData.noGameMessage}
                </EvText>
            );
        }
        return (
            <div className={styles.games.listContainer}>
                {gameList.map(getGameItemCard)}
            </div>
        );
    };

    const getGamesView = () => (
        <div className={styles.games.container}>
            <EvText>{staticData.gamesHeader}</EvText>
            {getGameList()}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                GAMEDAY_CONTENT_TEMPLATES.DASHBOARD_STATIC.id
            ],
            controls.dynamicDataApiStatus[
                GAMEDAY_CONTENT_COMPONENTS.GAME_LIST.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getActionView()}
            {getGamesView()}
        </div>
    );
};

GamedayContentDashboard.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    navigateToPage: PropTypes.func,
};

GamedayContentDashboard.defaultProps = {
    controls: {},
    staticData: {},
    location: {},

    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.CommonReducer[GAMEDAY_CONTENT_APP_ID].controls,
    staticData:
        state.CommonReducer[GAMEDAY_CONTENT_APP_ID].staticData[
            GAMEDAY_CONTENT_TEMPLATES.DASHBOARD_STATIC.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    setDynamicDataApiStatus: (payload) =>
        dispatch(
            CommonActions.setDynamicDataApiStatus({
                data: payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayContentDashboard);
