import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import {
    GAMEDAY_SCHEDULE_COMPONENTS,
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_TEMPLATES,
} from "../static/GamedayScheduleStatics";
import API_URLS from "../../../services/apiUrls";

import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvButton,
    EvHeaderView,
    EvLoadingPage,
    EvTable,
    EvText,
    EvToast,
} from "../../../common/components";
import { getData } from "../service/GamedayScheduleService";
import {
    LIVE_STATUS,
    LOADING_STATUS,
    RESPONSE_STATUS,
} from "../../../common/static/Enums";
import EvLogger, {
    LOG_ACTION_TYPE,
    LOG_CATEGORY_TYPES,
} from "../../../utils/EvLogger";

import "../styles/gameday-schedule-dashboard.scss";

const styles = {
    container: "ev__gameday-schedule-dashboard__container",
    header: {
        container: "ev__gameday-schedule-dashboard__header-container",
    },
    actionView: {
        container: "ev__gameday-schedule-dashboard__action-view-container",
    },
    tableView: {
        container: "ev__gameday-schedule-dashboard__table-view-container",
        infoText: "ev__gameday-schedule-dashboard__table-view-info-text",
        wrapper: "ev__gameday-schedule-dashboard__table-view-wrapper",
    },
};

const GamedayScheduleDashboard = (props) => {
    const {
        clientCode,
        controls,
        setControls,
        getStaticData,
        staticData,
        setDynamicDataApiStatus,
    } = props;

    const [scheduledGames, setScheduledGames] = useState([]);

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.GAME_DAY_SCHEDULE.DASHBOARD.STATIC,
                    templateId: GAMEDAY_SCHEDULE_TEMPLATES.DASHBOARD.id,
                }),
                getData(API_URLS.GAME_DAY_SCHEDULE.DASHBOARD.SCHEDULED_GAMES, {
                    clientCode,
                }),
            ]),
        [clientCode, getStaticData]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_SCHEDULE_COMPONENTS.SCHEDULED_GAMES.id]:
                LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, scheduledGamesResponse]) => {
                if (
                    scheduledGamesResponse.data.responseStatus ===
                    RESPONSE_STATUS.SUCCESS
                ) {
                    setScheduledGames(
                        scheduledGamesResponse.data.data.scheduledGames
                    );
                    setDynamicDataApiStatus({
                        [GAMEDAY_SCHEDULE_COMPONENTS.SCHEDULED_GAMES.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                } else if (
                    scheduledGamesResponse.data.responseStatus ===
                    RESPONSE_STATUS.SHOW_ERROR
                ) {
                    EvToast.error("Sorry", scheduledGamesResponse.data.message);
                    throw new Error(scheduledGamesResponse.data);
                }
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.SCHEDULED_GAMES.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.error(
                    `GamedayScheduleDashboard initial load data: ${clientCode}, error ${JSON.stringify(
                        e
                    )}`,
                    LOG_CATEGORY_TYPES.CC,
                    LOG_ACTION_TYPE.LOAD
                );
            });
    }, [clientCode, getAllData, setDynamicDataApiStatus]);

    const onNewGameClick = useCallback(() => {
        setControls({
            currentPageId: GAMEDAY_SCHEDULE_PAGES.SELECTION.id,
            currentGameData: {},
            scheduleId: "",
        });
    }, [setControls]);

    const onScheduledGameClick = useCallback(
        (e, callbackValues) => {
            let nextPageId = GAMEDAY_SCHEDULE_PAGES.CONTENT.id;
            if (callbackValues.status === LIVE_STATUS.DRAFT) {
                nextPageId = GAMEDAY_SCHEDULE_PAGES.CONTENT.id;
            } else {
                nextPageId = GAMEDAY_SCHEDULE_PAGES.SUMMARY.id;
            }
            setControls({
                currentPageId: nextPageId,
                scheduleId: callbackValues.id,
                currentGameData: {
                    gameName: callbackValues.gameName,
                    gameCategory: callbackValues.gameCategory,
                    gameId: callbackValues.gameId,
                    vendorProgramName: callbackValues.vendorProgramName,
                    status: callbackValues.status,
                },
            });
        },
        [setControls]
    );

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_SCHEDULE_COMPONENTS.SCHEDULED_GAMES.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const getHeaderView = () => (
        <EvHeaderView
            className={styles.header.container}
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton primaryFilled onClickHandler={onNewGameClick}>
                {staticData.newGameCta.text}
            </EvButton>
        </div>
    );

    const getTableView = () => (
        <EvTable
            className={styles.tableView.wrapper}
            tableFields={staticData.scheduledGameTable.tableFields}
            tableData={scheduledGames}
            isRowClickable
            loadMoreCta={staticData.scheduledGameTable.loadMoreCta}
            onRowClick={onScheduledGameClick}
        />
    );

    const getScheduledGames = () => (
        <div className={styles.tableView.container}>
            <EvText subHeading>{staticData.scheduledGameTable.header}</EvText>
            {scheduledGames.length <= 0 && (
                <EvText italics className={styles.tableView.infoText}>
                    {staticData.noGamesMessage}
                </EvText>
            )}
            {scheduledGames.length > 0 && getTableView()}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                GAMEDAY_SCHEDULE_TEMPLATES.DASHBOARD.id
            ],
            controls.dynamicDataApiStatus[
                GAMEDAY_SCHEDULE_COMPONENTS.SCHEDULED_GAMES.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getActionView()}
            {getScheduledGames()}
        </div>
    );
};

GamedayScheduleDashboard.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

GamedayScheduleDashboard.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    setControls: () => {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
    staticData:
        state.GamedayScheduleReducer.staticData[
            GAMEDAY_SCHEDULE_TEMPLATES.DASHBOARD.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(GamedayScheduleActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(GamedayScheduleActions.setDynamicDataApiStatus(payload)),
    setControls: (payload) =>
        dispatch(GamedayScheduleActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleDashboard);
