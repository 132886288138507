import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/ev-Info-box.scss";
import { EvText, EvIcon } from "./index";
import { COLORS } from "../static/VmsStatics";

const styles = {
    container: "ev__ev-info-box__container",
    innerContainer: "ev__ev-info-box__inner-container",
    headerContainer: "ev__ev-info-box__header-container",
    iconContainer: "ev__ev-info-box__icon-container",
    descriptionContainer: "ev__ev-info-box__description-container",
};

const EvInfoBox = (props) => {
    const {
        header,
        description,
        iconName,
        containerClassName,
        inlineContainerStyle,
    } = props;

    const getIcon = () => (
        <div className={styles.iconContainer}>
            <EvIcon
                iconName={iconName}
                size={3}
                circular
                fillColor={COLORS.WHITE}
                bgColor={COLORS.BLACK_TROUT}
            />
        </div>
    );

    const customContainerClassName = classNames(
        styles.container,
        containerClassName
    );

    return (
        <div className={customContainerClassName} style={inlineContainerStyle}>
            <div className={styles.innerContainer}>
                <div className={styles.headerContainer}>
                    {iconName && getIcon()}
                    <EvText headingSecondary>{header}</EvText>
                </div>
                <div className={styles.descriptionContainer}>
                    <EvText default>{description}</EvText>
                </div>
            </div>
        </div>
    );
};

EvInfoBox.propTypes = {
    iconName: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    containerClassName: PropTypes.string,
    inlineContainerStyle: PropTypes.object,
};

EvInfoBox.defaultProps = {
    iconName: "",
    header: "",
    description: "",
    containerClassName: "",
    inlineContainerStyle: {},
};

export default EvInfoBox;
