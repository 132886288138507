import React, { memo } from "react";

import EvIcon from "./EvIcon";
import EvSvgIconData from "../static/EvSvgIconData";

import "../styles/icon-library.scss";

const IconLibrary = () => {
    const styles = {
        container: "ev__icon-library__container",
        innerContainer: "ev__icon-library__inner-container",
        iconName: "ev__icon-library__icon-name",
        iconHolder: "ev__icon-library__icon-holder",
    };

    const getIcon = (icon) => (
        <div className={styles.container}>
            <div className={styles.iconName}>{icon}</div>
            <div className={styles.innerContainer}>
                <div key={`${icon}_1`} className={styles.iconHolder}>
                    <EvIcon
                        iconName={icon}
                        fillColor="#43A047"
                        strokeColor="#fff"
                        size={7}
                    />
                </div>
                <div key={`${icon}_2`} className={styles.iconHolder}>
                    <EvIcon
                        iconName={icon}
                        bgColor="#43A047"
                        fillColor="#fff"
                        circular
                        size={4}
                    />
                </div>
                <div key={`${icon}_3`} className={styles.iconHolder}>
                    <EvIcon
                        iconName={icon}
                        fillColor="#43A047"
                        bgColor="#fff"
                        strokeColor="#fff"
                        size={4}
                        borderColor="#aaa"
                        circular
                        bordered
                    />
                </div>
                <div key={`${icon}_4`} className={styles.iconHolder}>
                    <EvIcon
                        iconName={icon}
                        fillColor="#fff"
                        bgColor="#43A047"
                        strokeColor="#fff"
                        size={4}
                        borderColor="#aaa"
                    />
                </div>
            </div>
        </div>
    );

    const arr = [];
    Object.keys(EvSvgIconData).forEach((icon) => {
        arr.push(getIcon(icon));
    });

    return <div>{arr}</div>;
};

export default memo(IconLibrary);
