import PropTypes from "prop-types";
import React from "react";
import "../../styles/content-items/ev-content-cards-array.scss";
import { EvCardImagePortrait } from "../ev-cards";
import { EvText } from "../index";

const styles = {
    container: "ev__ev-content-cards-array__container",
    cardsContainer: "ev__ev-content-cards-array__cards-container",
    cardWrapper: "ev__ev-content-cards-array__card-wrapper",
};

const EvContentCardsArray = (props) => {
    const { label, dependency, allValues } = props;

    const getCardsItem = (cardItemData) => (
        <EvCardImagePortrait
            key={cardItemData.id}
            className={styles.cardWrapper}
            header={cardItemData.displayName}
            cardLogo={cardItemData.cardLogo}
            backgroundImage={cardItemData.backgroundImage}
            backgroundColor={cardItemData.backgroundColor}
        />
    );

    return (
        <div className={styles.container}>
            <EvText subHeading>{label}</EvText>
            <div className={styles.cardsContainer}>
                {allValues[dependency].map(getCardsItem)}
            </div>
        </div>
    );
};

EvContentCardsArray.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentCardsArray.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
};

export default EvContentCardsArray;
