import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import stringTemplate from "string-template";

import { EvText } from "./index";

import "../styles/ev-header-view.scss";
import { isNullOrEmpty } from "../../utils/CommonUtils";

const styles = {
    container: "ev__ev-header-view__container",
    headerText: "ev__ev-header-view__header-text",
    descriptionText: "ev__ev-header-view__description-text",
};

const EvHeaderView = (props) => {
    const {
        header,
        description,
        className,
        headerResolvers,
        descriptionResolvers,
        dangerous,
    } = props;

    const getHeaderView = () => (
        <EvText dangerous={dangerous} subHeading className={styles.headerText}>
            {isNullOrEmpty(headerResolvers)
                ? header
                : stringTemplate(header, headerResolvers)}
        </EvText>
    );

    const getDescriptionView = () => (
        <EvText dangerous={dangerous} className={styles.descriptionText}>
            {isNullOrEmpty(descriptionResolvers)
                ? description
                : stringTemplate(description, descriptionResolvers)}
        </EvText>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {header && getHeaderView()}
            {description && getDescriptionView()}
        </div>
    );
};

EvHeaderView.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    headerResolvers: PropTypes.object,
    descriptionResolvers: PropTypes.object,
    className: PropTypes.string,
    dangerous: PropTypes.bool,
};

EvHeaderView.defaultProps = {
    header: "",
    description: "",
    className: "",
    headerResolvers: {},
    descriptionResolvers: {},
    dangerous: false,
};

export default EvHeaderView;
