const ENTERPRISE_NOTIFICATIONS_PAGES = {
    CLIENT_SELECTION: {
        id: "enterprise_notification_client_selection",
        displayName: "Client Selection",
    },
    PROGRAM_SELECTION: {
        id: "enterprise_notification_program_selection",
        displayName: "Program Selection",
    },
    CONTENT: {
        id: "enterprise_notification_content",
        displayName: "Content",
    },
    SUMMARY: {
        id: "enterprise_notification_summary",
        displayName: "Summary",
    },
};

const ENTERPRISE_NOTIFICATIONS_BACK_BUTTON = {
    [ENTERPRISE_NOTIFICATIONS_PAGES.CLIENT_SELECTION.id]: {},
    [ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id]: {
        text: "Back",
        actionPageId: ENTERPRISE_NOTIFICATIONS_PAGES.CLIENT_SELECTION.id,
    },
    [ENTERPRISE_NOTIFICATIONS_PAGES.CONTENT.id]: {
        text: "Back to program selection",
        actionPageId: ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id,
    },
    [ENTERPRISE_NOTIFICATIONS_PAGES.SUMMARY.id]: {
        text: "Back to content",
        actionPageId: ENTERPRISE_NOTIFICATIONS_PAGES.CONTENT.id,
    },
};

export { ENTERPRISE_NOTIFICATIONS_PAGES, ENTERPRISE_NOTIFICATIONS_BACK_BUTTON };
