import React from "react";
import PropTypes from "prop-types";

import { EvButton, EvIcon, EvLoader, EvText } from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import EvBannerPreviewCard from "./EvBannerPreviewCard";
import { parsePreviewVariablesFromFormData } from "../../../utils/FormUtils";
import EvLandingPagePreviewCard from "./EvLandingPagePreviewCard";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/employer-card-view.scss";

const styles = {
    container: "ev__employer-card-view__container",
    bannerForm: {
        container: "ev__employer-card-view__banner-form-container",
        formWrapper: "ev__employer-card-view__banner-form-form-wrapper",
        previewCard: "ev__employer-card-view__banner-form-preview-card",
        cardWrapper: "ev__employer-card-view__banner-form-preview-card-wrapper",
    },
    landingPageForm: {
        container: "ev__employer-card-view__landing-page-form-container",
        innerContainer:
            "ev__employer-card-view__landing-page-form-inner-container",
        formWrapper: "ev__employer-card-view__landing-page-form-form-wrapper",
        previewContainer:
            "ev__employer-card-view__landing-page-form-preview-container",
        previewCard: "ev__employer-card-view__landing-page-form-preview-card",
        cardWrapper:
            "ev__employer-card-view__landing-page-form-preview-card-wrapper",
    },
    actionableView: {
        container: "ev__employer-card-view__actionable-view-container",
    },
};

const EmployerCardView = (props) => {
    const {
        staticData,
        bannerFormData,
        landingPageFormData,
        submitStatus,

        setBannerFormData,
        setLandingPageFormData,
        onFormSubmit,
    } = props;

    const getBannerFormView = () => (
        <div className={styles.bannerForm.container}>
            <EvText subHeading>{staticData.bannerFormData.header}</EvText>
            <div className={styles.bannerForm.formWrapper}>
                <EvFormRenderer
                    formFields={staticData.bannerFormData.formFields}
                    formData={bannerFormData}
                    setFormDataState={setBannerFormData}
                />
            </div>
        </div>
    );

    const getBannerPreviewCardView = () => {
        const {
            contentKeys,
            staticContent,
        } = staticData.bannerFormData.cardPreview;
        const previewVariables = parsePreviewVariablesFromFormData(
            bannerFormData,
            contentKeys,
            staticContent
        );

        return (
            <div className={styles.bannerForm.previewCard}>
                <EvText>{staticData.bannerFormData.cardPreview.header}</EvText>
                <EvBannerPreviewCard
                    className={styles.bannerForm.cardWrapper}
                    header={previewVariables.header}
                    description={previewVariables.description}
                    bgImage={previewVariables.bgImage}
                />
            </div>
        );
    };

    const getLandingPageFormView = () => (
        <div className={styles.landingPageForm.innerContainer}>
            <EvText subHeading>{staticData.landingPageFormData.header}</EvText>
            <div className={styles.landingPageForm.formWrapper}>
                <EvFormRenderer
                    formFields={staticData.landingPageFormData.formFields}
                    formData={landingPageFormData}
                    setFormDataState={setLandingPageFormData}
                />
            </div>
        </div>
    );

    const getLandingPagePreviewView = () => {
        const {
            contentKeys,
            staticContent,
        } = staticData.landingPageFormData.cardPreview;
        const previewVariables = parsePreviewVariablesFromFormData(
            {
                ...bannerFormData,
                ...landingPageFormData,
            },
            contentKeys,
            staticContent
        );
        return (
            <div className={styles.landingPageForm.previewContainer}>
                <EvText>
                    {staticData.landingPageFormData.cardPreview.header}
                </EvText>
                <EvLandingPagePreviewCard
                    header={previewVariables.header}
                    subHeader={previewVariables.subHeader}
                    descriptionHeader={previewVariables.descriptionHeader}
                    description={previewVariables.description}
                    bgImage={previewVariables.bgImage}
                    clientLogo={previewVariables.clientLogo}
                    className={styles.landingPageForm.cardWrapper}
                />
            </div>
        );
    };

    const getLandingPageView = () => (
        <div className={styles.landingPageForm.container}>
            {getLandingPageFormView()}
            {getLandingPagePreviewView()}
        </div>
    );

    const getStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        size={3}
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        size={3}
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionableButton = () => (
        <div className={styles.actionableView.container}>
            <EvButton primaryFilled onClickHandler={onFormSubmit}>
                {staticData.saveCta.text}
            </EvButton>
            {getStatusIcon()}
        </div>
    );

    return (
        <div className={styles.container}>
            {getBannerFormView()}
            {getBannerPreviewCardView()}
            {getLandingPageView()}
            {getActionableButton()}
        </div>
    );
};

EmployerCardView.propTypes = {
    staticData: PropTypes.object,
    bannerFormData: PropTypes.object,
    landingPageFormData: PropTypes.object,
    submitStatus: PropTypes.string,

    setBannerFormData: PropTypes.func,
    setLandingPageFormData: PropTypes.func,
    onFormSubmit: PropTypes.func,
};

EmployerCardView.defaultProps = {
    staticData: {},
    bannerFormData: {},
    landingPageFormData: {},
    submitStatus: "",

    setBannerFormData: () => {},
    setLandingPageFormData: () => {},
    onFormSubmit: () => {},
};

export default EmployerCardView;
