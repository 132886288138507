import { goBack, push, replace } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import {
    EvBackButton,
    EvDnDWrapper,
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvText,
    EvToast,
} from "../../../common/components";
import { EvCardResolver } from "../../../common/components/ev-cards";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import { sortObjectArrayBasedOnKey } from "../../../utils/SpecificDataModelUtils";
import ClientCardsActions from "../redux/ClientCardsActions";
import { postData } from "../service/ClientCardsService";
import {
    CLIENT_CARDS_COMPONENTS,
    CLIENT_CARDS_ROUTES,
    CLIENT_CARDS_TEMPLATES,
} from "../statics/ClientCardsStatics";
import "../styles/client-cards-order-view.scss";

const styles = {
    container: "ev__client-cards-order-view__container",
    headerContainer: "ev__client-cards-order-view__header-container",
    cardsList: {
        container: "ev__client-cards-order-view__cards-list-container",
        cardWrapper: "ev__client-cards-order-view__cards-list-card-wrapper",
        card: "ev__client-cards-order-view__cards-list-card",
    },
    actionView: {
        container: "ev__client-cards-order-view__action-view-container",
        button: "ev__client-cards-order-view__action-view-button",
    },
};

const ClientCardsOrderView = (props) => {
    const {
        controls,
        staticData,
        cardTypesStatic,
        clientCards,
        location,

        getStaticData,
        setDynamicDataApiStatus,

        navigationReplace,
        goBackAction,
        navigateToPage,
    } = props;

    const [cardsList, setCardsList] = useState([]);
    const [saveStatus, setSaveStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    if (
        controls.dynamicDataApiStatus[CLIENT_CARDS_COMPONENTS.CARD_LIST.id] !==
        LOADING_STATUS.COMPLETED
    ) {
        navigationReplace({
            path: CLIENT_CARDS_ROUTES.DASHBOARD.path,
            state: location.state,
        });
    }

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const clientName = useMemo(
        () =>
            location.state && location.state.clientName
                ? location.state.clientName
                : "",
        [location.state]
    );

    const cardType = useMemo(() => location.state.cardDashboardType || "", [
        location.state,
    ]);

    const cardsOfCurrentType = useMemo(() => {
        if (isNullOrEmpty(clientCards)) {
            return [];
        }
        let filteredList = clientCards.filter(
            (currentCardData) =>
                currentCardData.cardType === cardType &&
                currentCardData.visibility !== false
        );
        filteredList = sortObjectArrayBasedOnKey(filteredList, "cardPosition");
        return filteredList;
    }, [clientCards, cardType]);

    useEffect(() => {
        setCardsList(cardsOfCurrentType || []);
    }, [cardsOfCurrentType]);

    useEffect(() => {
        getStaticData({
            url: API_URLS.CLIENT_CARDS.REORDER.STATICS,
            templateId: CLIENT_CARDS_TEMPLATES.REORDER_STATICS.id,
        });
    }, [getStaticData]);

    const cardTypeData = useMemo(
        () =>
            cardTypesStatic &&
            cardTypesStatic.cardTypes &&
            cardTypesStatic.cardTypes[cardType]
                ? cardTypesStatic.cardTypes[cardType]
                : {},
        [cardTypesStatic, cardType]
    );

    const onSaveOrderClick = useCallback(() => {
        if (saveStatus === LOADING_STATUS.LOADING) {
            return;
        }
        setSaveStatus(LOADING_STATUS.LOADING);
        const cardOrderForApi = cardsList.map((cardItemData, i) => ({
            featureId: cardItemData.id,
            position: i,
        }));
        postData(
            API_URLS.CLIENT_CARDS.REORDER.SAVE_CARD_ORDER,
            {
                clientCode,
            },
            {
                cardType,
                cardOrderList: cardOrderForApi,
            }
        )
            .then((submitResponse) => {
                setSaveStatus(LOADING_STATUS.COMPLETED);
                EvToast.success("Saved", "Card order saved");
                setDynamicDataApiStatus({
                    [CLIENT_CARDS_COMPONENTS.CARD_LIST.id]:
                        LOADING_STATUS.NOT_YET_STARTED,
                });
                navigateToPage({
                    path: CLIENT_CARDS_ROUTES.DASHBOARD.path,
                    state: location.state,
                });
            })
            .catch((e) => {
                // error
                setSaveStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Failed to save card order");
                EvLogger.errorWithObject(e, "ClientCardsOrderView onSaveOrder");
            });
    }, [
        cardsList,
        saveStatus,
        location,
        navigateToPage,
        cardType,
        clientCode,
        setDynamicDataApiStatus,
    ]);

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            // const movedCardData = cardsList[dragIndex];
            const newCardList = Array.from(cardsList);
            newCardList.splice(
                hoverIndex,
                0,
                newCardList.splice(dragIndex, 1)[0]
            );
            setCardsList(newCardList);
        },
        [cardsList]
    );

    const onBackClick = useCallback(() => {
        goBackAction();
    }, [goBackAction]);

    const getCardItem = (cardData, i) => (
        <div key={cardData.id} className={styles.cardsList.cardWrapper}>
            <EvDnDWrapper id={cardData.id} index={i} moveCard={moveCard}>
                <EvCardResolver
                    key={cardData.id}
                    cardData={cardData}
                    cardType={cardTypeData.cardType}
                    cardTypeData={cardTypeData}
                    className={styles.cardsList.card}
                />
            </EvDnDWrapper>
        </div>
    );
    const getCardsList = () => (
        <DndProvider backend={HTML5Backend}>
            <div className={styles.cardsList.container}>
                {cardsList.map(getCardItem)}
            </div>
        </DndProvider>
    );

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackClick}>
            {staticData.backCta.text}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            headerResolvers={{ clientName, cardType: cardTypeData.header }}
            description={staticData.subHeader}
            className={styles.headerContainer}
        />
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionView.container}
            buttonClass={styles.actionView.button}
            buttonText={staticData.saveCta.text}
            loadingStatus={saveStatus}
            onSubmitClick={onSaveOrderClick}
            isDisabled={cardsList.length <= 0}
        />
    );

    const getInfoView = () => (
        <div>
            {cardsList.length <= 0 && (
                <EvText italics>{staticData.noVisibleCardsMessage}</EvText>
            )}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                CLIENT_CARDS_TEMPLATES.REORDER_STATICS.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getActionView()}
            {getCardsList()}
            {getInfoView()}
        </div>
    );
};

ClientCardsOrderView.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    cardTypesStatic: PropTypes.object,
    clientCards: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,

    goBackAction: PropTypes.func,
    navigationReplace: PropTypes.func,
    navigateToPage: PropTypes.func,
};

ClientCardsOrderView.defaultProps = {
    controls: {},
    staticData: {},
    cardTypesStatic: {},
    clientCards: {},
    location: {},

    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},

    goBackAction: () => {},
    navigationReplace: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientCardsReducer.controls,
    staticData:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.REORDER_STATICS.id
        ],
    cardTypesStatic:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.CARD_TYPES.id
        ],
    clientCards:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.CARD_LIST.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientCardsActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(ClientCardsActions.setDynamicDataApiStatus(payload)),
    navigationReplace: (payload) =>
        dispatch(replace(payload.path, payload.state)),
    goBackAction: () => dispatch(goBack()),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientCardsOrderView);
