import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import ResourceCardView from "./ResourceCardView";
import {
    RESOURCE_CARD_APP_ID,
    RESOURCE_CARD_STATICS,
    RESOURCE_CARD_TEMPLATES,
} from "../statics/ResourceCardStatics";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { LOADING_STATUS, VENDOR_CARD_TYPE } from "../../../common/static/Enums";
import { getData, postData } from "../../../common/service/CommonService";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import EvLogger from "../../../utils/EvLogger";
import CommonActions from "../../../common/redux/CommonActions";

const ResourceCardContainer = (props) => {
    const {
        staticData,
        staticDataClientProgram,
        location,

        // actions
        getStaticData,
        goBackAction,
    } = props;

    const [formDataLoadingStatus, setFormDataLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    useEffect(() => {
        if (isCompletedOrLoading(formDataLoadingStatus)) {
            return;
        }
        setFormDataLoadingStatus(LOADING_STATUS.LOADING);
        Promise.all([
            getStaticData({
                templateId: RESOURCE_CARD_TEMPLATES.STATIC.id,
                url: API_URLS.RESOURCE_CARD.STATICS,
            }),
            getStaticData({
                templateId: RESOURCE_CARD_TEMPLATES.STATIC_CLIENT_PROGRAM.id,
                url: API_URLS.RESOURCE_CARD.STATICS_CLIENT_PROGRAM,
            }),
            getData(API_URLS.RESOURCE_CARD.PREFILL_DATA, {
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
                clientCode,
            }),
        ])
            .then(
                ([
                    staticDataResponse,
                    staticClientProgramDataResponse,
                    prefillDataResponse,
                ]) => {
                    const currentStaticData =
                        vendorCardData.vendorType ===
                        VENDOR_CARD_TYPE.CLIENT_VENDOR
                            ? staticClientProgramDataResponse
                            : staticDataResponse;
                    setFormData(
                        parseInitialStateFromFormFields(
                            currentStaticData.formFields,
                            prefillDataResponse.data.data
                        )
                    );
                    setInitialFormData(prefillDataResponse.data.data);
                    setFormDataLoadingStatus(LOADING_STATUS.COMPLETED);
                }
            )
            .catch((e) => {
                setFormDataLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "ResourceCardContainer getData");
            });
    }, [getStaticData, clientCode, vendorCardData, formDataLoadingStatus]);

    const onFormSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", RESOURCE_CARD_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.RESOURCE_CARD.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
            }
        )
            .then((submitResponse) => {
                EvToast.success("Updated", "Resource Card details updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                goBackAction();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "ResourceCardContainer onFormSubmit"
                );
            });
    }, [clientCode, formData, submitStatus, vendorCardData, goBackAction]);

    if (!isCompleted(formDataLoadingStatus)) {
        return <EvLoadingPage />;
    }
    return (
        <ResourceCardView
            staticData={
                vendorCardData.vendorType === VENDOR_CARD_TYPE.CLIENT_VENDOR
                    ? staticDataClientProgram
                    : staticData
            }
            formData={formData}
            vendorCardData={vendorCardData}
            clientCode={clientCode}
            setFormDataState={setFormData}
            onFormSubmit={onFormSubmit}
            submitStatus={submitStatus}
            initialFormData={initialFormData}
        />
    );
};

ResourceCardContainer.propTypes = {
    staticData: PropTypes.object,
    staticDataClientProgram: PropTypes.object,
    location: PropTypes.object,

    // actions
    getStaticData: PropTypes.func,
    goBackAction: PropTypes.func,
};

ResourceCardContainer.defaultProps = {
    staticData: {},
    staticDataClientProgram: {},
    location: {},

    // actions
    getStaticData: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.CommonReducer[RESOURCE_CARD_APP_ID].staticData[
            RESOURCE_CARD_TEMPLATES.STATIC.id
        ],
    staticDataClientProgram:
        state.CommonReducer[RESOURCE_CARD_APP_ID].staticData[
            RESOURCE_CARD_TEMPLATES.STATIC_CLIENT_PROGRAM.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: RESOURCE_CARD_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceCardContainer);
