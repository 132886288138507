import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvFormRenderer,
    EvPreviewFormRenderer,
} from "../../../common/components/form-components";
import {
    EvButton,
    EvDivider,
    EvIcon,
    EvLoader,
    EvText,
} from "../../../common/components";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/current-status-view.scss";

const styles = {
    container: "ev__current-status-view__container",
    headerView: {
        container: "ev__current-status-view__header-view-container",
        headerText: "ev__current-status-view__header-view-header-text",
        descriptionText:
            "ev__current-status-view__header-view-description-text",
    },
    formContainer: "ev__current-status-view__form-container",
    infoFormWrapper: "ev__current-status-view__info-form-wrapper",
    formWrapper: "ev__current-status-view__form-wrapper",
    formDivider: "ev__current-status-view__form-divider",
    actionView: {
        container: "ev__current-status-view__action-view-container",
        button: "ev__current-status-view__action-view-button",
    },
};

const CurrentStatusView = (props) => {
    const {
        staticData,
        vendorCardData,
        clientCode,

        infoFormData,
        introductionCardFormData,
        checklistCardFormData,
        questionCardFormData,
        vendorCardFormData,

        submitStatus,
        onFormSubmit,

        setInfoFormData,
        setIntroductionCardFormData,
        setChecklistCardFormData,
        setQuestionCardFormData,
        setVendorCardFormData,
    } = props;

    const getHeaderView = () => (
        <div className={styles.headerView.container}>
            <EvText
                subHeading
                dangerous
                className={styles.headerView.headerText}
            >
                {stringTemplate(staticData.header, {
                    vendorName: vendorCardData.vendorName,
                    programName: vendorCardData.programName,
                })}
            </EvText>
            <EvText className={styles.headerView.descriptionText}>
                {staticData.description}
            </EvText>
        </div>
    );

    const getInfoFormView = () => (
        <div className={styles.infoFormWrapper}>
            <EvFormRenderer
                formFields={staticData.infoForm.formItems}
                formData={infoFormData}
                setFormDataState={setInfoFormData}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    cardId: vendorCardData.cardId,
                }}
            />
        </div>
    );

    const getPreviewFormViews = (formStaticData, formData, setFormData) => (
        <div className={styles.formWrapper}>
            <EvPreviewFormRenderer
                formFields={formStaticData.formItems}
                formData={formData}
                header={formStaticData.header}
                editCta={formStaticData.editCta}
                previewCta={formStaticData.previewCta}
                cardPreview={formStaticData.cardPreview}
                setFormDataState={setFormData}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    cardId: vendorCardData.cardId,
                }}
            />
        </div>
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            {getInfoFormView()}
            <EvDivider className={styles.formDivider} />
            {/* {getIntroductionFormView()} */}
            {getPreviewFormViews(
                staticData.introductionCardFormItems,
                introductionCardFormData,
                setIntroductionCardFormData
            )}
            <EvDivider className={styles.formDivider} />
            {getPreviewFormViews(
                staticData.checklistCardFormItems,
                checklistCardFormData,
                setChecklistCardFormData
            )}
            <EvDivider className={styles.formDivider} />
            {getPreviewFormViews(
                staticData.questionCardFormItems,
                questionCardFormData,
                setQuestionCardFormData
            )}
            <EvDivider className={styles.formDivider} />
            {getPreviewFormViews(
                staticData.vendorCardFormItems,
                vendorCardFormData,
                setVendorCardFormData
            )}
        </div>
    );

    const getSubmitStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        size={3}
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        size={3}
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primaryFilled
                onClickHandler={onFormSubmit}
                className={styles.actionView.button}
            >
                {staticData.submitCta.text}
            </EvButton>
            {getSubmitStatusIcon()}
        </div>
    );

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {getActionView()}
        </div>
    );
};

CurrentStatusView.propTypes = {
    staticData: PropTypes.object,
    vendorCardData: PropTypes.object,
    clientCode: PropTypes.string,

    infoFormData: PropTypes.object,
    introductionCardFormData: PropTypes.object,
    checklistCardFormData: PropTypes.object,
    questionCardFormData: PropTypes.object,
    vendorCardFormData: PropTypes.object,

    submitStatus: PropTypes.string,
    onFormSubmit: PropTypes.func,

    setInfoFormData: PropTypes.func,
    setIntroductionCardFormData: PropTypes.func,
    setChecklistCardFormData: PropTypes.func,
    setQuestionCardFormData: PropTypes.func,
    setVendorCardFormData: PropTypes.func,
};

CurrentStatusView.defaultProps = {
    staticData: {},
    vendorCardData: {},
    clientCode: "",

    infoFormData: {},
    introductionCardFormData: {},
    checklistCardFormData: {},
    questionCardFormData: {},
    vendorCardFormData: {},

    submitStatus: LOADING_STATUS.NOT_YET_STARTED,
    onFormSubmit: () => {},

    setInfoFormData: () => {},
    setIntroductionCardFormData: () => {},
    setChecklistCardFormData: () => {},
    setQuestionCardFormData: () => {},
    setVendorCardFormData: () => {},
};

export default CurrentStatusView;
