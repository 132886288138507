import dateTime from "date-and-time";

import { SERVER_FORMATS, UI_FORMATS } from "../common/static/Enums";

const dateFormatterFromFullZString = (
    dateString,
    dateFormat = UI_FORMATS.FULL_DATE
) => {
    const dateTimeObject = new Date(dateString);
    return dateTime.format(dateTimeObject, dateFormat);
};

const timeFormatterFromFullZString = (
    dateString,
    timeFormat = UI_FORMATS.FULL_TIME_MERIDIEM
) => {
    const dateTimeObject = new Date(dateString);
    return dateTime.format(dateTimeObject, timeFormat);
};

const fullDateFormatterFromServerDate = (serverData) => {
    return dateTime.format(
        dateTime.parse(serverData, SERVER_FORMATS.DATE),
        UI_FORMATS.FULL_DATE
    );
};

const fullTimeMeridiemFromServerTime = (serverTime) => {
    return dateTime.format(
        dateTime.parse(serverTime, SERVER_FORMATS.TIME),
        UI_FORMATS.FULL_TIME_MERIDIEM
    );
};

export {
    dateFormatterFromFullZString,
    timeFormatterFromFullZString,
    fullDateFormatterFromServerDate,
    fullTimeMeridiemFromServerTime,
};
