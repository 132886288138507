import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useDrag, useDrop } from "react-dnd";

import "../styles/ev-dnd-wrapper.scss";

const styles = {
    container: "ev__ev-dnd-wrapper__container",
};

const CARD_TYPE = "dndCard";

const EvDnDWrapper = (props) => {
    const { id, children, index, moveCard } = props;

    const cardRef = useRef(null);

    const [, drop] = useDrop({
        accept: CARD_TYPE,
        hover(item, monitor) {
            if (!cardRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = cardRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: CARD_TYPE,
        item: { type: CARD_TYPE, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.5 : 1;
    drag(drop(cardRef));
    return (
        <div ref={cardRef} className={styles.container} style={{ opacity }}>
            {children}
        </div>
    );
};

EvDnDWrapper.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    index: PropTypes.number,
    moveCard: PropTypes.func,
};

EvDnDWrapper.defaultProps = {
    id: "",
    children: "",
    index: 0,
    moveCard: () => {},
};

export default EvDnDWrapper;
