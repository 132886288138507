import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvDivider, EvIcon, EvSimpleCard, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";
import { RESOURCE_CARD_STATICS } from "../../../features/resource-card/statics/ResourceCardStatics";

import "../../styles/ev-cards/ev-preview-resource-card.scss";

const styles = {
    container: "ev__ev-preview-resource-card__container",
    card: "ev__ev-preview-resource-card__card",
    innerContainer: "ev__ev-preview-resource-card__inner-container",
    header: {
        container: "ev__ev-preview-resource-card__header-container",
        imageContainer: "ev__ev-preview-resource-card__header-image-container",
        imageWrapper: "ev__ev-preview-resource-card__header-image-wrapper",
        imageInnerWrapper:
            "ev__ev-preview-resource-card__header-image-inner-wrapper",
        logoWrapper: "ev__ev-preview-resource-card__header-logo-wrapper",
        logo: "ev__ev-preview-resource-card__header-logo",
        fullImage: "ev__ev-preview-resource-card__header-full-image",
        noImageText: "ev__ev-preview-resource-card__header-no-image-text",
        favIcon: "ev__ev-preview-resource-card__header-fav-icon",
        text: "ev__ev-preview-resource-card__header-text",
        button: "ev__ev-preview-resource-card__header-button",
    },
    content: {
        container: "ev__ev-preview-resource-card__content-container",
    },
};

const EvPreviewResourceCard = (props) => {
    const {
        header,
        logoImage,
        backgroundColor,
        backgroundImage,
        ctaText,
        description,
        className,
    } = props;

    const getLogoImage = () => (
        <div className={styles.header.logoWrapper} style={{ backgroundColor }}>
            <div
                className={styles.header.logo}
                style={{ backgroundImage: `url(${logoImage})` }}
            />
        </div>
    );

    const getBackgroundImage = () => (
        <div
            className={styles.header.fullImage}
            style={{ backgroundImage: `url(${backgroundImage})` }}
        />
    );

    const noImageView = () => (
        <EvText italics className={styles.header.noImageText}>
            {RESOURCE_CARD_STATICS.NO_IMAGE_TEXT}
        </EvText>
    );

    const getImageView = () => (
        <div className={styles.header.imageContainer}>
            <EvIcon
                iconName="ARROW_THICK_LEFT"
                size={3}
                circular
                fillColor={COLORS.WHITE}
                bgColor={COLORS.BLACK_TROUT}
            />
            <div className={styles.header.imageWrapper}>
                <div className={styles.header.imageInnerWrapper}>
                    {backgroundImage
                        ? getBackgroundImage()
                        : logoImage
                        ? getLogoImage()
                        : noImageView()}
                </div>
            </div>
            <EvIcon
                outerContainerStyleClass={styles.header.favIcon}
                iconName="HEART_FILLED"
                size={4}
                fillColor={COLORS.BLACK_TROUT}
            />
        </div>
    );

    const getActionsView = (currentCtaText) => (
        <EvButton primaryFilled className={styles.header.button}>
            {currentCtaText}
        </EvButton>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            {getImageView()}
            <EvText className={styles.header.text} headingSecondary>
                {header}
            </EvText>
            {typeof ctaText === "string"
                ? getActionsView(ctaText)
                : ctaText.map(getActionsView)}
        </div>
    );

    const getContentView = () => (
        <div className={styles.content.container}>
            <EvText>{description}</EvText>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding className={styles.card}>
                <div className={styles.innerContainer}>
                    {getHeaderView()}
                    <EvDivider height={1} />
                    {getContentView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvPreviewResourceCard.propTypes = {
    header: PropTypes.string,
    logoImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    description: PropTypes.string,
    className: PropTypes.string,
};

EvPreviewResourceCard.defaultProps = {
    header: "",
    logoImage: "",
    backgroundColor: "",
    backgroundImage: "",
    ctaText: "",
    description: "",
    className: "",
};

export default EvPreviewResourceCard;
