const RESOURCE_CARD_APP_ID = "vendorProgramsCards__resourceCard";

const RESOURCE_CARD_TEMPLATES = {
    STATIC: {
        id: "resourceCard__statics",
    },
    STATIC_CLIENT_PROGRAM: {
        id: "resourceCard__staticsClientProgram",
    },
};

const RESOURCE_CARD_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
    NO_IMAGE_TEXT: "No Image selected",
};

export { RESOURCE_CARD_TEMPLATES, RESOURCE_CARD_STATICS, RESOURCE_CARD_APP_ID };
