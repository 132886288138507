import React, { useEffect } from "react";

export const CriteriaBuilderCallback = () => {
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const message = {
            refId: queryParams.get("refId"),
        };
        window.opener.criteriaBuilderCallback(message);
    }, []);
    return <div>Redirecting...</div>;
};
