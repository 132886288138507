const COLORS = {
    WHITE: "#FFF",
    WHITE_ALABASTER: "#f9f9f9",
    WHITE_MYSTIC: "#edf0f5",
    WHITE_ATHENS_GREY: "#e6e9f0",
    WHITE_GHOST: "#cccfd9",
    ALICE_BLUE: "#f6f8fC",

    BLACK: "#000",
    BLACK_TROUT: "#505664",

    BLUE_MIDNIGHT: "#000A23",

    PRODUCT: "#1b67da",

    CLEMENTINE: "#ef6c00",
    ROSE: "#F50076",
    RED_MONZA: "#DA002F",

    FRUIT_SALAD: "#43A047",
};

const CLIENT_LIST_STATICS = { API_ERROR: "Something went wrong" };

const MANAGE_PROGRAMS_STATICS = { REMOVING_PRGRAM: "Removing Program" };

const USER_MAPPING_STATICS = {
    WARNS: { CHANGES_NOT_SAVED: "Changes not saved." },
};

const CLIENT_STATICS = {
    CLIENT_CODE_NOT_PRESET_TOAS: {
        header: "Client not created",
        description: "Please create client to use these options",
    },
};

const USER_BAR_ICONS_DATA = {
    PRE_PROD: {
        iconName: "SEND",
        count: 2,
        route: "/cc/client/pre-prod",
        fillColor: COLORS.BLACK_TROUT,
        tooltip: "Pre prod",
        state: {},
    },
};

export {
    COLORS,
    CLIENT_LIST_STATICS,
    MANAGE_PROGRAMS_STATICS,
    USER_MAPPING_STATICS,
    CLIENT_STATICS,
    USER_BAR_ICONS_DATA,
};
