import AppContainerActionTypes from "./AppContainerActionTypes";
import { LOADING_STATUS } from "../../../common/static/Enums";

const initialState = {
    controls: {
        staticDataLoadingStatus: LOADING_STATUS.NOT_YET_STARTED,
        userDataLoadingStatus: LOADING_STATUS.NOT_YET_STARTED,
    },
    staticData: {},
    userData: {},
};

const AppContainerReducer = (state = initialState, action) => {
    switch (action.type) {
        case AppContainerActionTypes.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };

        case AppContainerActionTypes.SET_DATA:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state;
    }
};

export default AppContainerReducer;
