import {
    LOADING_STATUS,
    CLIENT_PAGE_PATHS,
    DOMAINS,
    RESPONSE_STATUS,
    HTTP_METHODS,
} from "../common/static/Enums";
import { EvToast } from "../common/components";

const isCompleted = (...args) => {
    for (let i = 0; i < args.length; i++) {
        if (args[i] !== LOADING_STATUS.COMPLETED) {
            return false;
        }
    }
    return true;
};

const isNotYetStarted = (...args) => {
    for (let i = 0; i < args.length; i++) {
        if (args[i] === LOADING_STATUS.NOT_YET_STARTED || !args[i]) {
            return true;
        }
    }
    return false;
};

const isCompletedOrLoading = (...args) => {
    for (let i = 0; i < args.length; i++) {
        if (
            args[i] !== LOADING_STATUS.COMPLETED &&
            args[i] !== LOADING_STATUS.LOADING
        ) {
            return false;
        }
    }

    return true;
};

// doesn't take in account of 0 or false, it gives true for 0 or false
const isNullOrEmpty = (value) => {
    if (typeof value !== "undefined" && value) {
        if (Object.keys(value).length !== 0) {
            return false;
        }
        if (
            typeof value === "number" ||
            typeof value === "string" ||
            typeof value === "boolean"
        ) {
            return false;
        }
    }
    return true;
};

const isNullOrUndefined = (value) => value === undefined || value === null;

const isObjectKeysNullOrEmpty = (value) => {
    if (isNullOrEmpty(value)) {
        return true;
    }
    return Object.keys(value).every((itemKey) => isNullOrEmpty(value[itemKey]));
};

const isCtaObjectValid = (ctaObject) => {
    if (isNullOrEmpty(ctaObject)) {
        return false;
    }
    if (isNullOrEmpty(ctaObject.text) && isNullOrEmpty(ctaObject.icon)) {
        return false;
    }
    return true;
};

const isCtaIconObjectValid = (ctaObject) => ctaObject && ctaObject.icon;

const isCtaIconTextObjectValid = (ctaObject) =>
    ctaObject && ctaObject.text && ctaObject.icon;

const singleDigitPrefix = (digit) => (digit >= 10 ? digit : `0${digit}`);

const dateFormatter = (rawDate, yearDigitCount = 2) => {
    const DateObject = new Date(rawDate);
    const date = singleDigitPrefix(DateObject.getDate());
    const month = singleDigitPrefix(DateObject.getMonth() + 1);
    const year = DateObject.getFullYear()
        .toString()
        .substr(4 - yearDigitCount);
    return `${month}/${date}/${year}`;
};

const timeFormatter = (rawDateTime) => {
    const DateTimeObject = new Date(rawDateTime);
    const hours = DateTimeObject.getHours();
    const minutes = singleDigitPrefix(DateTimeObject.getMinutes());
    const seconds = singleDigitPrefix(DateTimeObject.getSeconds());

    const hours12HoursFormat = hours < 12 ? hours : hours - 12;

    return `${
        hours12HoursFormat === 0 ? 12 : singleDigitPrefix(hours12HoursFormat)
    }:${minutes}:${seconds} ${hours < 12 ? "a.m." : "p.m."}`;
};

const getTimeInHrMin = (rawTimeString) => {
    const result = rawTimeString.split(":", 2);
    const hours = result[0];
    const minutes = result[1];
    return `${hours < 12 ? singleDigitPrefix(hours) : hours - 12}:${minutes} ${
        hours < 12 ? "a.m." : "p.m."
    }`;
};

const timeStampFormatter = (timeStampObject) => {
    if (!isNullOrEmpty(timeStampObject)) {
        return `${dateFormatter(timeStampObject.date, 4)}, ${getTimeInHrMin(
            timeStampObject.time
        )} ${timeStampObject.timeZone}`;
    }
    return null;
};

const getTimeForApi = (rawDateTime) => {
    if (!rawDateTime) {
        return "";
    }
    const DateTimeObject = new Date(rawDateTime);
    const hours = singleDigitPrefix(DateTimeObject.getHours());
    const minutes = singleDigitPrefix(DateTimeObject.getMinutes());
    const seconds = singleDigitPrefix(DateTimeObject.getSeconds());

    return `${hours}:${minutes}:${seconds}`;
};

const getDateForApi = (rawDateTime, isDateObject = false) => {
    if (!rawDateTime) {
        return "";
    }
    let dateObject;
    if (isDateObject) {
        dateObject = rawDateTime;
    } else {
        dateObject = new Date(rawDateTime);
    }
    return `${dateObject.getFullYear()}-${singleDigitPrefix(
        dateObject.getMonth() + 1
    )}-${singleDigitPrefix(dateObject.getDate())}`;
};

const dateTimeFormatter = (rawDateTime) =>
    `${dateFormatter(rawDateTime, 4)} ${timeFormatter(rawDateTime)}`;
const navigationMenuDirectionDecider = (
    navigationOrderArray,
    currentMenuId,
    newMenuId
) =>
    navigationOrderArray.indexOf(newMenuId) -
    navigationOrderArray.indexOf(currentMenuId);

const getClientDetailsPageIdFromPath = (path) => {
    const pageKey = Object.keys(CLIENT_PAGE_PATHS).find(
        (key) => path.indexOf(CLIENT_PAGE_PATHS[key].path) !== -1
    );
    return pageKey ? CLIENT_PAGE_PATHS[pageKey].id : ""; // default
};

const getClientDetailsPageKeyFromId = (id) => {
    const pageKey = Object.keys(CLIENT_PAGE_PATHS).find(
        (key) => id === CLIENT_PAGE_PATHS[key].id
    );
    return pageKey || ""; // default
};

const sortVendorOrder = (vendorData, vendorIds) => {
    const compareVendorNames = (vendor1, vendor2) => {
        if (
            vendorData[vendor1].name.toLowerCase() <
            vendorData[vendor2].name.toLowerCase()
        ) {
            return -1;
        }
        if (
            vendorData[vendor1].name.toLowerCase() >
            vendorData[vendor2].name.toLowerCase()
        ) {
            return 1;
        }
        return 0;
    };
    const sortedVendorList = [...vendorIds];
    sortedVendorList.sort(compareVendorNames);
    return sortedVendorList;
};

const randomNumberGenerator = (minValue = 0, maxValue = 10000) => {
    const min = Math.ceil(minValue);
    const max = Math.floor(maxValue);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const guidGenerator = () => {
    const S4 = () =>
        String.fromCharCode(Math.floor(((Math.random() * 100) % 26) + 97));
    return `${S4()}${S4()}${S4()}${S4()}-${S4()}${S4()}${S4()}${S4()}-${S4()}${S4()}${S4()}`;
};

const removeKeyFromObject = (thisKey, { [thisKey]: _, ...rest }) => rest;

const removeItemFromArray = (key, arr) => arr.filter((item) => item !== key);

const isUrlMatch = (domain, checkString) => {
    if (domain.indexOf(checkString) !== -1) {
        return true;
    }
    return false;
};

const isLocal = () => isUrlMatch(window.location.href, DOMAINS.LOCAL);
const isDev = () => isUrlMatch(window.location.href, DOMAINS.DEV);
const isProd = () => isUrlMatch(window.location.href, DOMAINS.PROD);

const getEnvironment = () => {
    if (isProd()) {
        return DOMAINS.PROD;
    } else if (isDev()) {
        return DOMAINS.DEV;
    } else if (isLocal()) {
        return DOMAINS.LOCAL;
    }
    return DOMAINS.PROD;
};

const queryStringGenerator = (values) => {
    if (isNullOrEmpty(values)) {
        return "";
    }
    let queryString = "";
    Object.keys(values).forEach((key, index) => {
        if (index !== 0) {
            queryString += "&";
        }
        // for array keep it in array
        queryString += `${key}=${values[key]}`;
    });
    return queryString;
};

const isAPISuccessStatus = (response) =>
    response.data.responseStatus === RESPONSE_STATUS.SUCCESS;

const isApiResponseValid = (response) =>
    [
        RESPONSE_STATUS.SUCCESS,
        RESPONSE_STATUS.ERROR_MESSAGE,
        RESPONSE_STATUS.SHOW_ERROR,
    ].includes(response.data.responseStatus);

const onSuccessMessage = (response) => {
    if (
        response.config.method === HTTP_METHODS.GET &&
        response.data.responseStatus === RESPONSE_STATUS.SUCCESS &&
        response.data.message
    ) {
        EvToast.success("", response.data.message);
    }
};

const getSelectedApplicationIdFromSidebar = (sideBarsTabs, pathname) => {
    if (isNullOrEmpty(sideBarsTabs)) {
        return "";
    }
    for (let i = 0; i < sideBarsTabs.length; i++) {
        if (!isNullOrEmpty(sideBarsTabs[i].subTabs)) {
            for (let j = 0; j < sideBarsTabs[i].subTabs.length; j++) {
                if (pathname.includes(sideBarsTabs[i].subTabs[j].action)) {
                    return sideBarsTabs[i].subTabs[j].id;
                }
            }
        }
        if (pathname.includes(sideBarsTabs[i].action)) {
            // if subtabs exists
            return sideBarsTabs[i].id;
        }
    }
    return "";
};

function getQueryParameterByName(name, url) {
    const searchParam = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + searchParam + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const addDataToAllObjectInArray = (arr, newData) => {
    const newArray = arr.map((currentData) => ({
        ...currentData,
        ...newData,
    }));
    return newArray;
};

export {
    isCompleted,
    isNotYetStarted,
    isCompletedOrLoading,
    isNullOrEmpty,
    isNullOrUndefined,
    isObjectKeysNullOrEmpty,
    isCtaObjectValid,
    isCtaIconObjectValid,
    isCtaIconTextObjectValid,
    dateFormatter,
    timeFormatter,
    dateTimeFormatter,
    getDateForApi,
    getTimeForApi,
    navigationMenuDirectionDecider,
    getClientDetailsPageIdFromPath,
    getClientDetailsPageKeyFromId,
    sortVendorOrder,
    randomNumberGenerator,
    guidGenerator,
    removeKeyFromObject,
    removeItemFromArray,
    isLocal,
    isDev,
    isProd,
    getEnvironment,
    queryStringGenerator,
    isAPISuccessStatus,
    isApiResponseValid,
    timeStampFormatter,
    onSuccessMessage,
    getSelectedApplicationIdFromSidebar,
    getQueryParameterByName,
    addDataToAllObjectInArray,
};
