import React from "react";
import { Route, Switch } from "react-router-dom";

import { isProd } from "../utils/CommonUtils";
import IconLibrary from "../common/components/IconLibrary";
import EvLoadingPage from "../common/components/EvLoadingPage";
import CommonComponents from "../common/components/CommonComponents";
import {
    EvSimpleModal,
    EvToast,
    EvText,
    EvFullScreenOverlay,
} from "../common/components";
import ComponentLibraryView from "../features/component-library/views/ComponentLibraryView";
import { CriteriaBuilderCallback } from "../features/criteria-builder/CriteriaBuilder";

const OpenRoutes = () => {
    if (isProd()) {
        return <EvText subHeading>URL not found</EvText>;
    }

    return (
        <div style={{ flex: 1, display: "flex" }}>
            <Switch>
                <Route
                    exact
                    path="/cc-open/components/icons"
                    component={IconLibrary}
                />
                <Route
                    exact
                    path="/cc-open/components/loading-page"
                    component={EvLoadingPage}
                />
                <Route
                    exact
                    path="/cc-open/components"
                    component={CommonComponents}
                />
                <Route
                    path="/cc-open/library"
                    component={ComponentLibraryView}
                />
                <Route
                    path="/cc/cc-open/criteria-builder-callback"
                    component={CriteriaBuilderCallback}
                />
            </Switch>
            <EvSimpleModal />
            <EvToast />
            <EvFullScreenOverlay />
        </div>
    );
};

export default OpenRoutes;
