import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/NotificationsActions";
import API_URLS from "../../../services/apiUrls";
import { getData, postData } from "../service/NotificationsService";
import {
    NOTIFICATION_TEMPLATES,
    NOTIFICATIONS_PAGES,
} from "../static/NotificationsStatics";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { EvFormRenderer } from "../../../common/components/form-components";
import {
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvToast,
} from "../../../common/components";
import { isCompleted } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";

import "../styles/notifications-program-selection.scss";

const styles = {
    container: "ev__notifications-program-selection__container",
    formContainer: "ev__notifications-program-selection__form-container",
    actionContainer: "ev__notifications-program-selection__action-container",
};

const NotificationsProgramSelection = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        getStaticData,
        setControls,
        setDynamicDataApiStatus,
    } = props;

    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    templateId:
                        NOTIFICATION_TEMPLATES.PROGRAM_SELECTION_STATIC.id,
                    url: API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.STATIC,
                }),
                getData(
                    API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.PRE_FILLED_DATA,
                    {
                        clientCode,
                        notificationId: controls.currentNotificationId || "",
                    }
                ),
            ]),
        [getStaticData, clientCode, controls.currentNotificationId]
    );

    useEffect(() => {
        setLoadingStatus(LOADING_STATUS.NOT_YET_STARTED);
        getAllData()
            .then(([staticDataResponse, preFillResponse]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formFields,
                    preFillResponse.data.data
                );
                setFormData(initialFormData);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
            });
    }, [getStaticData, setDynamicDataApiStatus, getAllData]);

    const onSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }

        const isFormDataValid = isFullFormValid(formData);
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        const notificationId = controls.currentNotificationId || "";
        postData(
            API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.POST_DATA,
            { clientCode, notificationId },
            apiData
        )
            .then((submitResponse) => {
                EvToast.success("Saved", "", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                setControls({
                    selectedPageId: NOTIFICATIONS_PAGES.CONTENT.id,
                    currentNotificationId: submitResponse.data.data.id || "",
                });
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "NotificationProgramSelection onFormSubmitClick"
                );
            });
    }, [
        formData,
        submitStatus,
        controls.currentNotificationId,
        clientCode,
        setControls,
    ]);

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionContainer}
            buttonText={staticData.nextCta.text}
            onSubmitClick={onSubmit}
            loadingStatus={submitStatus}
            addLabelSpacing
        />
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formData={formData}
                formFields={staticData.formFields}
                setFormDataState={setFormData}
                qParams={{
                    clientCode,
                }}
            />
            {getActionView()}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                NOTIFICATION_TEMPLATES.PROGRAM_SELECTION_STATIC.id
            ],
            loadingStatus
        )
    ) {
        return <EvLoadingPage />;
    }
    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
        </div>
    );
};

NotificationsProgramSelection.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

NotificationsProgramSelection.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    getStaticData: () => {},
    setControls: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.PROGRAM_SELECTION_STATIC.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsProgramSelection);
