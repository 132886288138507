import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SavedPopulationActions from "../redux/SavedPopulationActions";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import { EvLoadingPage } from "../../../common/components";
import EvNotificationsTagSelectionView from "../../notifications/views/EvNotificationsTagSelectionView";
import {
    SAVED_POPULATION_COMPONENTS,
    SAVED_POPULATION_PAGES,
    SAVED_POPULATION_TEMPLATES,
} from "../static/SavedPopulationStatics";

const SavedPopulationTagSelection = (props) => {
    const {
        controls,
        staticData,
        allSelectedData,
        criteriaRuleFormData,
        allTags,
        categories,

        // actions
        setControls,
        getStaticData,
        setComponentData,
    } = props;

    const { tagSelectionCriteriaId } = controls;

    useEffect(() => {
        getStaticData({
            url: API_URLS.SAVED_POPULATION.TAG_SELECTION.STATIC,
            templateId: SAVED_POPULATION_TEMPLATES.TAG_SELECTION.id,
        });
        getStaticData({
            url: API_URLS.SAVED_POPULATION.TAG_SELECTION.TAG_CATEGORIES,
            templateId: SAVED_POPULATION_TEMPLATES.TAG_CATEGORIES.id,
        });
    }, [getStaticData]);

    const onProceedClick = useCallback(
        (newSelectedData) => {
            setComponentData({
                componentId:
                    SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id,
                data: newSelectedData,
            });
            setControls({
                selectedPageId: SAVED_POPULATION_PAGES.NEW_EDIT_POPULATION.id,
            });
        },
        [setComponentData, setControls]
    );

    const onBackClick = useCallback(() => {
        setControls({
            selectedPageId: SAVED_POPULATION_PAGES.NEW_EDIT_POPULATION.id,
        });
    }, [setControls]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                SAVED_POPULATION_TEMPLATES.TAG_SELECTION.id
            ],
            controls.staticDataApiStatus[
                SAVED_POPULATION_TEMPLATES.TAG_CATEGORIES.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <EvNotificationsTagSelectionView
            tagSelectionCriteriaId={tagSelectionCriteriaId}
            staticData={staticData}
            allTags={allTags}
            allSelectedData={allSelectedData}
            categories={categories.categories ? categories.categories : []}
            criteriaRuleFormStaticData={criteriaRuleFormData}
            onProceedClick={onProceedClick}
            onBackClick={onBackClick}
            showBackButton
        />
    );
};

SavedPopulationTagSelection.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    allSelectedData: PropTypes.object,
    criteriaRuleFormData: PropTypes.object,
    allTags: PropTypes.object,
    categories: PropTypes.object,

    // actions
    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
};

SavedPopulationTagSelection.defaultProps = {
    controls: {},
    staticData: {},
    allSelectedData: {},
    criteriaRuleFormData: {},
    allTags: {},
    categories: {},

    // actions
    setControls: () => {},
    getStaticData: () => {},
    setComponentData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.SavedPopulationReducer.controls,
    staticData:
        state.SavedPopulationReducer.staticData[
            SAVED_POPULATION_TEMPLATES.TAG_SELECTION.id
        ],
    criteriaRuleFormData:
        state.SavedPopulationReducer.staticData[
            SAVED_POPULATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id
        ],
    allSelectedData:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id
        ],
    allTags:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.ALL_TAGS.id
        ],
    categories:
        state.SavedPopulationReducer.staticData[
            SAVED_POPULATION_TEMPLATES.TAG_CATEGORIES.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(SavedPopulationActions.setControls(payload)),
    getStaticData: (payload) =>
        dispatch(SavedPopulationActions.getStaticData(payload)),
    setComponentData: (payload) =>
        dispatch(SavedPopulationActions.setComponentData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedPopulationTagSelection);
