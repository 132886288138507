import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack, push, replace } from "connected-react-router";
import classNames from "classnames";

import ClientCardsActions from "../redux/ClientCardsActions";
import {
    CLIENT_CARDS_COMPONENTS,
    CLIENT_CARDS_ROUTES,
    CLIENT_CARDS_STATICS,
    CLIENT_CARDS_TEMPLATES,
} from "../statics/ClientCardsStatics";
import API_URLS from "../../../services/apiUrls";
import {
    isCompleted,
    isCompletedOrLoading,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import {
    EvBackButton,
    EvButton,
    EvDivider,
    EvHeaderView,
    EvLoadingPage,
    EvText,
} from "../../../common/components";
import { EvCardResolver } from "../../../common/components/ev-cards";
import { getData } from "../service/ClientCardsService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import EvLogger from "../../../utils/EvLogger";
import { createCardListBasedOnType } from "../../../utils/SpecificDataModelUtils";

import "../styles/client-cards-categories-view.scss";

const styles = {
    container: "ev__client-cards-categories-view__container",
    header: {
        container: "ev__client-cards-categories-view__header-container",
    },
    actionView: {
        container: "ev__client-cards-categories-view__action-view-container",
        button: "ev__client-cards-categories-view__action-view-button",
    },
    sectionView: {
        container: "ev__client-cards-categories-view__section-view-container",
        button: "ev__client-cards-categories-view__section-view-button",
    },
    cardsView: {
        container: "ev__client-cards-categories-view__cards-view-container",
        cardWrapper:
            "ev__client-cards-categories-view__cards-view-card-wrapper",
        cardInvisible:
            "ev__client-cards-categories-view__cards-view-card-wrapper-invisible",
        card: "ev__client-cards-categories-view__cards-view-card",
    },
};

const ClientCardsCategoriesView = (props) => {
    const {
        controls,
        staticData,
        cardTypesStatic,
        clientCards,
        vendorPrograms,
        sections,
        location,

        getStaticData,
        setComponentData,
        setDynamicDataApiStatus,
        navigationReplace,
        goBackAction,
        navigateToPage,
        softReset,
    } = props;

    if (
        controls.dynamicDataApiStatus[CLIENT_CARDS_COMPONENTS.CARD_LIST.id] !==
        LOADING_STATUS.COMPLETED
    ) {
        navigationReplace({
            path: CLIENT_CARDS_ROUTES.DASHBOARD.path,
            state: location.state,
        });
    }

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const clientName = useMemo(
        () =>
            location.state && location.state.clientName
                ? location.state.clientName
                : "",
        [location.state]
    );

    const [selectedSectionId, setSelectedSectionId] = useState(
        CLIENT_CARDS_STATICS.ALL_SECTION_ID
    );

    const sectionsLoadingStatus = useMemo(
        () =>
            controls.dynamicDataApiStatus[CLIENT_CARDS_COMPONENTS.SECTIONS.id],
        [controls.dynamicDataApiStatus]
    );

    const cardType = useMemo(() => {
        return location.state.cardDashboardType || "";
    }, [location.state]);

    const cardsOfCurrentType = useMemo(() => {
        if (isNullOrEmpty(clientCards)) {
            return [];
        }
        const filteredList = createCardListBasedOnType(
            clientCards,
            vendorPrograms
        );
        return filteredList[cardType] || [];
    }, [clientCards, cardType, vendorPrograms]);

    const getSectionsData = useCallback(() => {
        if (isCompletedOrLoading(sectionsLoadingStatus)) {
            return; // use from cache or wait till loaded
        }
        setDynamicDataApiStatus({
            [CLIENT_CARDS_COMPONENTS.SECTIONS.id]: LOADING_STATUS.LOADING,
        });
        getData(API_URLS.CLIENT_CARDS.CATEGORIES.SECTIONS, { clientCode })
            .then((sectionsResponseData) => {
                const sectionsData = [
                    ...CLIENT_CARDS_STATICS.ADDITIONAL_SECTIONS,
                    ...(sectionsResponseData.data.data.sections || []),
                ];
                setComponentData({
                    componentId: CLIENT_CARDS_COMPONENTS.SECTIONS.id,
                    data: sectionsData,
                });
                setDynamicDataApiStatus({
                    [CLIENT_CARDS_COMPONENTS.SECTIONS.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [CLIENT_CARDS_COMPONENTS.SECTIONS.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(
                    e,
                    "ClientCardsCategoriesView getSections"
                );
            });
    }, [
        sectionsLoadingStatus,
        clientCode,
        setComponentData,
        setDynamicDataApiStatus,
    ]);

    useEffect(() => {
        getStaticData({
            url: API_URLS.CLIENT_CARDS.CATEGORIES.STATIC,
            templateId: CLIENT_CARDS_TEMPLATES.CATEGORIES_STATICS.id,
        });
        getSectionsData();
    }, [getStaticData, getSectionsData]);

    const onChangeOrderClick = useCallback(() => {
        navigateToPage({
            path: CLIENT_CARDS_ROUTES.REORDER.path,
            state: location.state,
        });
    }, [navigateToPage, location.state]);

    const onSectionClick = useCallback((e, callbackValues) => {
        setSelectedSectionId(callbackValues.sectionId);
    }, []);

    const cardsListForSection = useMemo(() => {
        if (selectedSectionId === CLIENT_CARDS_STATICS.ALL_SECTION_ID) {
            return cardsOfCurrentType;
        }
        return cardsOfCurrentType.filter((cardData) => {
            if (!cardData.section) {
                return false;
            }
            return cardData.section.indexOf(selectedSectionId) !== -1;
        });
    }, [cardsOfCurrentType, selectedSectionId]);

    const cardCountBySection = useMemo(() => {
        const counts = {
            [CLIENT_CARDS_STATICS.ALL_SECTION_ID]: cardsOfCurrentType
                ? cardsOfCurrentType.length
                : 0,
        };
        cardsOfCurrentType.forEach((cardData) => {
            const currentSectionsArray = cardData.section || [];
            currentSectionsArray.forEach((cardSectionId) => {
                counts[cardSectionId] = counts[cardSectionId]
                    ? counts[cardSectionId] + 1
                    : 1;
            });
        });
        return counts;
    }, [cardsOfCurrentType]);

    const onCardClick = useCallback(
        (e, callbackValues) => {
            const currentCardStaticData =
                cardTypesStatic.cardTypes[callbackValues.cardType];
            const cardVendorProgramData =
                vendorPrograms[
                    `${callbackValues.vendorId}-${callbackValues.programId}`
                ];
            navigateToPage({
                path: currentCardStaticData.path,
                state: {
                    ...location.state,
                    vendorCardData: {
                        vendorId: cardVendorProgramData.vendorId,
                        vendorName: cardVendorProgramData.vendorName,
                        programId: cardVendorProgramData.programId,
                        programName: cardVendorProgramData.programName,
                        vendorType: cardVendorProgramData.vendorType,
                        cardId: callbackValues.id,
                        cardName: callbackValues.displayName,
                    },
                },
            });
            // clear dynamic data after navigation
            setTimeout(() => {
                softReset();
            }, 200);
        },
        [
            navigateToPage,
            cardTypesStatic,
            vendorPrograms,
            softReset,
            location.state,
        ]
    );

    const onBackClick = useCallback(() => {
        goBackAction();
    }, [goBackAction]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackClick}>
            {staticData.backCta.text}
        </EvBackButton>
    );

    const getHeaderView = () => {
        const currentCardTypeStatic = cardTypesStatic.cardTypes[cardType] || {};
        return (
            <EvHeaderView
                className={styles.header.container}
                header={staticData.header}
                headerResolvers={{
                    clientName,
                    cardType: currentCardTypeStatic.header,
                }}
            />
        );
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                default
                className={styles.actionView.button}
                onClickHandler={onChangeOrderClick}
            >
                {staticData.reorderCta.text}
            </EvButton>
        </div>
    );

    const getSectionButton = (currentSection) => {
        if (
            isNullOrEmpty(cardCountBySection[currentSection.id]) ||
            cardCountBySection[currentSection.id] <= 0
        ) {
            return null;
        }
        return (
            <EvButton
                key={currentSection.id}
                default={currentSection.id === selectedSectionId}
                secondary={currentSection.id !== selectedSectionId}
                className={styles.sectionView.button}
                onClickHandler={onSectionClick}
                callbackValues={{ sectionId: currentSection.id }}
                count={cardCountBySection[currentSection.id]}
            >
                {currentSection.name}
            </EvButton>
        );
    };

    const getSectionView = () => {
        // if only ALL SECTION ID is there, then dont show section view
        if (Object.keys(cardCountBySection).length <= 1) {
            return null;
        }
        return (
            <div className={styles.sectionView.container}>
                {sections.map(getSectionButton)}
            </div>
        );
    };

    const getCardView = (cardData) => {
        const cardTypeData = cardTypesStatic.cardTypes[cardData.cardType];
        const customCardClass = classNames(styles.cardsView.cardWrapper, {
            [styles.cardsView.cardInvisible]: cardData.visibility === false,
        });

        return (
            <EvButton
                onlyChild
                className={customCardClass}
                onClickHandler={onCardClick}
                callbackValues={cardData}
            >
                <EvCardResolver
                    key={cardData.id}
                    cardData={cardData}
                    cardType={cardTypeData.cardType}
                    cardTypeData={cardTypeData}
                    className={styles.cardsView.card}
                />
            </EvButton>
        );
    };

    const getCardForSection = () => (
        <div className={styles.cardsView.container}>
            {cardsListForSection.length > 0 ? (
                cardsListForSection.map(getCardView)
            ) : (
                <EvText italics>{cardTypesStatic.noCardText}</EvText>
            )}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                CLIENT_CARDS_TEMPLATES.CATEGORIES_STATICS.id
            ],
            controls.dynamicDataApiStatus[CLIENT_CARDS_COMPONENTS.SECTIONS.id]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getActionView()}
            <EvDivider marginVertical={10} />
            {getSectionView()}
            {getCardForSection()}
        </div>
    );
};

ClientCardsCategoriesView.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    cardTypesStatic: PropTypes.object,
    clientCards: PropTypes.array,
    vendorPrograms: PropTypes.object,
    sections: PropTypes.array,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    navigationReplace: PropTypes.func,
    goBackAction: PropTypes.func,
    navigateToPage: PropTypes.func,
    softReset: PropTypes.func,
};

ClientCardsCategoriesView.defaultProps = {
    controls: {},
    staticData: {},
    cardTypesStatic: {},
    clientCards: [],
    vendorPrograms: {},
    sections: [],
    location: {},

    getStaticData: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
    navigationReplace: () => {},
    goBackAction: () => {},
    navigateToPage: () => {},
    softReset: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientCardsReducer.controls,
    staticData:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.CATEGORIES_STATICS.id
        ],
    cardTypesStatic:
        state.ClientCardsReducer.staticData[
            CLIENT_CARDS_TEMPLATES.CARD_TYPES.id
        ],
    clientCards:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.CARD_LIST.id
        ],
    vendorPrograms:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.VENDOR_PROGRAMS.id
        ],
    sections:
        state.ClientCardsReducer.dynamicData[
            CLIENT_CARDS_COMPONENTS.SECTIONS.id
        ] || [],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientCardsActions.getStaticData(payload)),
    softReset: (payload) => dispatch(ClientCardsActions.softReset(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(ClientCardsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(ClientCardsActions.setComponentData(payload)),
    navigationReplace: (payload) =>
        dispatch(replace(payload.path, payload.state)),
    goBackAction: () => dispatch(goBack()),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientCardsCategoriesView);
