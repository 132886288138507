import React from "react";
import {
    EvBackButton,
    EvButton,
    EvDivider,
    EvText,
} from "../../../common/components";

import "../styles/ev-library-buttons.scss";
import { COLORS } from "../../../common/static/VmsStatics";

const styles = {
    container: "ev__ev-library-button__container",
    buttonsContainer: "ev__ev-library-button__buttons-container",
    buttonWrapper: "ev__ev-library-button__button-wrapper",
    descriptionText: "ev__ev-library-button__description-text",
    onlyChildButton: "ev__ev-library-button__only-child-button",
};

const EvLibraryButtons = () => {
    const getButtonsView = () => (
        <div className={styles.buttonsContainer}>
            <div className={styles.buttonWrapper}>
                <EvButton primary>Primary Button</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #fff
                    <br />
                    TextColor: #1b67da
                </EvText>
            </div>

            <div className={styles.buttonWrapper}>
                <EvButton primary count={5} thickButton>
                    Count Button
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #fff
                    <br />
                    TextColor: #1b67da
                    <br />
                    Count: 5
                    <br />
                    thickButton: true
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton primaryFilled>Primary Filled Button</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #1b67da
                    <br />
                    TextColor: #fff
                </EvText>
            </div>

            <div className={styles.buttonWrapper}>
                <EvButton primaryFilled count={20}>
                    Primary Filled Count Button
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #1b67da
                    <br />
                    TextColor: #fff
                    <br />
                    Count: 20
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton primaryFilledDisabled>
                    Primary Filled Disabled
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #cccfd9
                    <br />
                    TextColor: #505664
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton default>Default</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #fff
                    <br />
                    TextColor: #1b67da
                </EvText>
            </div>

            <div className={styles.buttonWrapper}>
                <EvButton default count={4} thickButton>
                    Default Count
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #fff
                    <br />
                    TextColor: #1b67da
                    <br />
                    Count: 4
                    <br />
                    thickButton: true
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton secondary>Secondary Button</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #edf0f5
                    <br />
                    TextColor: #1b67da
                </EvText>
            </div>

            <div className={styles.buttonWrapper}>
                <EvButton secondary count={8} thickButton>
                    Secondary Count Button
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #edf0f5
                    <br />
                    TextColor: #1b67da
                    <br />
                    Count: 8
                    <br />
                    thickButton: true
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton negativeAction>Negative Action</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: #edf0f5
                    <br />
                    TextColor: #505664
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton onlyChild>
                    <div className={styles.onlyChildButton}>
                        <EvText subHeading color={COLORS.WHITE}>
                            Only Child Button
                        </EvText>
                        <EvText color={COLORS.WHITE}>
                            It can contain any text or other component
                        </EvText>
                    </div>
                </EvButton>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvBackButton>Back Button Text</EvBackButton>
                <EvText className={styles.descriptionText}>
                    Button with Back Icon and custom Text
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton iconButton iconName="CHECK">
                    Icon Button
                </EvButton>
                <EvText className={styles.descriptionText}>
                    Button with icon on the left
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton textButton>Only Text Button</EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: null
                    <br />
                    TextColor: #1b67da
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.buttonWrapper}>
                <EvButton textButton underlined>
                    Only Text Underlined Button
                </EvButton>
                <EvText className={styles.descriptionText}>
                    BackgroundColor: null
                    <br />
                    TextColor: #1b67da
                </EvText>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            <EvDivider marginVertical={10} />
            {getButtonsView()}
        </div>
    );
};

export default EvLibraryButtons;
