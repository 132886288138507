import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ClientDetailsActions from "../redux/ClientDetailsActions";
import { CLIENT_DETAILS_ROUTES } from "../statics/ClientDashboardStatics";
import ClientDashboardContainer from "./ClientDashboardContainer";
import ClientDetailsEdit from "./ClientDetailsEditContainer";

const ClientDetailsRoutes = (props) => {
    const { clientCode, clientName, resetClientDetailsDynamicData } = props;
    // cleanup
    useEffect(
        () => () => {
            resetClientDetailsDynamicData();
        },
        [resetClientDetailsDynamicData]
    );

    return (
        <div style={{ marginTop: "12rem" }}>
            <Switch>
                {/*<Route*/}
                {/*  path={CLIENT_DETAILS_ROUTES.CLIENT_BENEFITS.path}*/}
                {/*  exact*/}
                {/*  render={renderProps => (*/}
                {/*    <ClientDetailsBenefits*/}
                {/*      {...renderProps}*/}
                {/*      clientCode={clientCode}*/}
                {/*      clientName={clientName}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  path={CLIENT_DETAILS_ROUTES.BENEFITS_SUMMARY.path}*/}
                {/*  exact*/}
                {/*  render={renderProps => (*/}
                {/*    <ClientDetailsBenefitSummary*/}
                {/*      {...renderProps}*/}
                {/*      clientCode={clientCode}*/}
                {/*      clientName={clientName}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*/>*/}
                <Route
                    path={CLIENT_DETAILS_ROUTES.EDIT_CLIENT.path}
                    exact
                    render={(renderProps) => (
                        <ClientDetailsEdit
                            {...renderProps}
                            clientCode={clientCode}
                            clientName={clientName}
                        />
                    )}
                />
                <Route
                    render={(renderProps) => (
                        <ClientDashboardContainer
                            {...renderProps}
                            clientCode={clientCode}
                            clientName={clientName}
                        />
                    )}
                />
            </Switch>
        </div>
    );
};

ClientDetailsRoutes.propTypes = {
    resetClientDetailsDynamicData: PropTypes.func,
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
};

ClientDetailsRoutes.defaultProps = {
    resetClientDetailsDynamicData: () => {},
    clientCode: "",
    clientName: "",
};

const mapStateToProps = (state) => ({
    // controls: state.Reducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    resetClientDetailsDynamicData: (payload) =>
        dispatch(ClientDetailsActions.resetClientDetailsDynamicData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDetailsRoutes);
