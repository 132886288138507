import CacheActionTypes from "./CacheActionTypes";

const initialState = {
    controls: {},
    cache: {},
};

const CacheReducer = (state = initialState, action) => {
    switch (action.type) {
        // case CacheActionTypes.SET_CONTROLS:
        //   return {
        //     ...state,
        //     controls: {
        //       [action.payload.endpoint]: action.payload.data,
        //     },
        //   };

        case CacheActionTypes.SET_CACHE_ITEM_DATA:
            return {
                ...state,
                cache: {
                    ...state.cache,
                    [action.payload.key]: {
                        data: action.payload.data,
                        type: action.payload.type,
                    },
                },
            };

        case CacheActionTypes.SET_MULTIPLE_CACHE_DATA:
            return {
                ...state,
                cache: {
                    ...state.cache,
                    ...action.payload.cache,
                },
            };

        default:
            return state;
    }
};

export default CacheReducer;
