import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import {
    EvBackButton,
    EvButton,
    EvHeaderView,
} from "../../../../common/components";
import GamedayRoutes from "../../../gameday-content/views/GamedayRoutes";
import ProgramTagsContainer from "../../../program-tags/views/ProgramTagsContainer";
// import VendorProgramContentContainer from "../../../vendor-program-content/views/VendorProgramContentContainer";
import VendorProgramSummary from "../../../vendor-program-summary/views/VendorProgramSummary";
import { VENDOR_PROGRAMS_PAGES } from "../../statics/VendorProgramsStatics";
// import CardsContentRoutes from "../../../cards-dashboard/views/CardsContentRoutes";
import {
    CUSTOMIZE_PROGRAMS_DEPRECATED_COMPONENT_DATA,
    CUSTOMIZE_PROGRAMS_STATICS,
} from "../statics/CustomizeProgramsStatics";
import "../styles/customize-programs-view.scss";

const styles = {
    container: "ev__customize-programs-view__container",
    sideBarContainer: "ev__customize-programs-view__side-bar-container",
    content: {
        container: "ev__customize-programs-view__content-container",
        routeContainer: "ev__customize-programs-view__content-route-container",
    },
    deprecated: {
        container: "ev__customize-programs-view__deprecated-container",
        button: "ev__customize-programs-view__deprecated-button",
    },
};

const CustomizeProgramsView = (props) => {
    const { location, onBackButtonClick, navigateToPage } = props;

    // const getSideBar = () => (
    //   <div className={styles.sideBarContainer}>
    //     <EvSideNavBar
    //       header={staticData.header}
    //       listData={staticData.sideBarsTabs}
    //       onClickHandler={onSidebarClick}
    //       selectedId={selectedApplicationId}
    //     />
    //   </div>
    // );

    const onDeprecatedCtaClick = useCallback(
        (e, callbackValues) => {
            navigateToPage({
                path: callbackValues.cta.action,
                state: location.state,
            });
        },
        [navigateToPage, location.state]
    );

    const getDeprecatedComponent = (viewData) => (
        <div className={styles.deprecated.container}>
            <EvHeaderView
                header={viewData.header}
                description={viewData.description}
            />
            <EvButton
                primary
                onClickHandler={onDeprecatedCtaClick}
                callbackValues={viewData}
                className={styles.deprecated.button}
            >
                {viewData.cta.text}
            </EvButton>
        </div>
    );

    const routeDecider = () => (
        <div className={styles.content.routeContainer}>
            <Switch>
                <Route
                    path={VENDOR_PROGRAMS_PAGES.PROGRAM_TAGS.path}
                    exact
                    render={() => <ProgramTagsContainer />}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.CALLOUTS.path}
                    render={() =>
                        getDeprecatedComponent(
                            CUSTOMIZE_PROGRAMS_DEPRECATED_COMPONENT_DATA.CALLOUTS
                        )
                    }
                />
                {/*<Route*/}
                {/*  path={VENDOR_PROGRAMS_PAGES.VENDOR_PROGRAM_CONTENT.path}*/}
                {/*  exact*/}
                {/*  render={() => <VendorProgramContentContainer />}*/}
                {/*/>*/}
                <Route
                    path={VENDOR_PROGRAMS_PAGES.CARDS_DASHBOARD.path}
                    render={() =>
                        getDeprecatedComponent(
                            CUSTOMIZE_PROGRAMS_DEPRECATED_COMPONENT_DATA.CARDS
                        )
                    }
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.GAMEDAY_CONTENT.path}
                    render={() => <GamedayRoutes />}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.PROGRAM_SUMMARY.path}
                    exact
                    render={() => <VendorProgramSummary />}
                />
            </Switch>
        </div>
    );

    const getContentView = () => (
        <div className={styles.content.container}>
            <EvBackButton onClickHandler={onBackButtonClick}>
                {CUSTOMIZE_PROGRAMS_STATICS.BACK_BUTTON}
            </EvBackButton>
            {routeDecider()}
        </div>
    );

    return (
        <div className={styles.container}>
            {/*{getSideBar()}*/}
            {getContentView()}
        </div>
    );
};

CustomizeProgramsView.propTypes = {
    staticData: PropTypes.object,
    location: PropTypes.object,
    selectedApplicationId: PropTypes.string,

    onBackButtonClick: PropTypes.func,
    onSidebarClick: PropTypes.func,
    navigateToPage: PropTypes.func,
};

CustomizeProgramsView.defaultProps = {
    staticData: {},
    location: {},
    selectedApplicationId: "",

    onBackButtonClick: () => {},
    onSidebarClick: () => {},
    navigateToPage: () => {},
};

export default CustomizeProgramsView;
