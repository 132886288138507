import { apiGet, apiMock, apiPost, apiPut } from "../../../services/api";
import API_URLS from "../../../services/apiUrls";
import EvLogger from "../../../utils/EvLogger";
import getClientDetailsNavigationMenuDataJSON from "../../app-container/static/getClientDetailsNavigationMenuDataJSON";
import { COMMON_RESPONSE } from "../../notifications/static/getNotificationsDataJSON";
import { PRE_PROD_JSON } from "../../pre-prod/statics/getPreProdDataJSON";
import getVendorProgramsDashboardJSON from "../../vendor-programs/statics/getVendorProgramsDashboardJSON";
import getClientDashboardData from "../statics/getClientDashboardDataJSON";
import {
    getClientDetailsDataJSON,
    getClientDetailsStaticDataJSON,
} from "../statics/getClientDetailsDataJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // static data and templates
        case API_URLS.CLIENT_DETAILS.GET_NAVIGATION_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientDetailsNavigationMenuDataJSON)
                : apiGet(url, params);

        case API_URLS.CLIENT_DETAILS.GET_CLIENT_DETAILS_STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientDetailsStaticDataJSON)
                : apiGet(url, params);

        case API_URLS.CLIENT_DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientDashboardData.STATIC)
                : apiGet(url, params);

        // case API_URLS.CLIENT_DASHBOARD.CLIENT_BENEFITS_STATIC:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getClientDetailsBenefitsDataJSON.STATIC)
        //     : apiGet(url, params);
        //
        // case API_URLS.CLIENT_DASHBOARD.CLIENT_BENEFITS_SUMMARY_STATIC:
        //   return USE_MOCK_DATA
        //     ? apiMock(
        //         url,
        //         params,
        //         getClientDetailsBenefitsDataJSON.BENEFIT_SUMMARY_STATIC
        //       )
        //     : apiGet(url, params);

        // dynamic data
        case API_URLS.CLIENT_DETAILS.GET_CLIENT_DETAILS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientDetailsDataJSON)
                : apiGet(url, params);

        // case API_URLS.CLIENT_DASHBOARD.CLIENT_BENEFITS_LIST:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getVendorProgramsDashboardJSON.CURRENT_VENDORS)
        //     : apiGet(url, params);
        //
        // case API_URLS.CLIENT_DASHBOARD.CLIENT_BENEFITS_SUMMARY_DATA:
        //   return USE_MOCK_DATA
        //     ? apiMock(url, params, getClientDetailsBenefitsDataJSON.BENEFIT_SUMMARY)
        //     : apiGet(url, params);

        case API_URLS.CLIENT_DASHBOARD.ACTIVITY_CARD_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, PRE_PROD_JSON.CARD_LIST)
                : apiGet(url, params);

        case API_URLS.CLIENT_DASHBOARD.VENDOR_CARD_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      getVendorProgramsDashboardJSON.CURRENT_VENDORS
                  )
                : apiGet(url, params);

        default:
            EvLogger.warn("ClientDetailsEditView getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const putData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.CLIENT_DETAILS.PUT_CLIENT_DETAILS:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPut(url, qParams, data);

        default:
            EvLogger.warn(
                `ClientDetailsService deleteData unknown URL: ${url}`,
                "cc",
                "load"
            );
            return apiPost(url, qParams, data);
    }
};

//-----------------------------

export { getData, putData };
