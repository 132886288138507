import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AppContainer from "../features/app-container/views/AppContainer";

import DashboardContainer from "../features/dashboard/views/DashboardContainer";
import ClientContainer from "../features/client/views/ClientContainer";
import DataUploaderContainer from "../features/data-uploader/views/DataUploaderContainer";

import {
    EvSimpleModal,
    EvToast,
    EvFullScreenOverlay,
} from "../common/components";
import AddEditUser from "../features/dashboard/views/AddEditUser";
import EnterpriseContentContainer from "../features/enterprise-content/views/EnterpriseContentContainer";
import { CriteriaBuilderCallback } from "../features/criteria-builder/CriteriaBuilder";

class ClosedRoutes extends Component {
    render() {
        return (
            <AppContainer>
                <Switch>
                    <Route
                        path="/cc/dashboard"
                        exact
                        component={DashboardContainer}
                    />
                    <Route path="/cc/client" component={ClientContainer} />
                    <Route
                        path="/cc/enterprise"
                        component={EnterpriseContentContainer}
                    />
                    <Route path="/cc/user" component={AddEditUser} />
                    <Route
                        path="/cc/upload"
                        component={DataUploaderContainer}
                    />
                    <Route
                        path="/cc/cc-open/criteria-builder-callback"
                        component={CriteriaBuilderCallback}
                    />
                    <Route path="/cc" component={DashboardContainer} />
                </Switch>
                <EvSimpleModal />
                <EvToast />
                <EvFullScreenOverlay />
            </AppContainer>
        );
    }
}

export default ClosedRoutes;
