import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvText } from "./index";
import { isNullOrEmpty } from "../../utils/CommonUtils";
import { CTA_TYPE, SIDE_BAR_VISIBILITY_MODE } from "../static/Enums";

import "../styles/ev-side-nav-bar.scss";

const styles = {
    container: "ev__ev-side-nav-bar__container",
    header: {
        container: "ev__ev-side-nav-bar__header-container",
    },
    list: {
        container: "ev__ev-side-nav-bar__list-container",
        itemContainer: "ev__ev-side-nav-bar__list-item-container",
        itemContainerSelected:
            "ev__ev-side-nav-bar__list-item-container-selected",
        itemWrapper: "ev__ev-side-nav-bar__list-item-wrapper",
    },
    subNavBar: {
        container: "ev__ev-side-nav-bar__sub-nav-bar-container",
        innerContainer: "ev__ev-side-nav-bar__sub-nav-bar-inner-container",
        itemWrapper: "ev__ev-side-nav-bar__sub-nav-bar-item-wrapper",
        itemWrapperSelected:
            "ev__ev-side-nav-bar__sub-nav-bar-item-wrapper-selected",
    },
};

const EvSubNavBar = (props) => {
    const { listData, isExpanded, onSelect, selectedId } = props;

    const innerContainerRef = useRef(null);

    const getTabView = (tabData) => {
        const isSelected = selectedId === tabData.id;
        const customWrapperClass = classNames(styles.subNavBar.itemWrapper, {
            [styles.subNavBar.itemWrapperSelected]: isSelected,
        });
        return (
            <EvButton
                key={tabData.id}
                onlyChild
                className={customWrapperClass}
                onClickHandler={onSelect}
                callbackValues={tabData}
            >
                <EvText primaryColored={isSelected} defaultBold={isSelected}>
                    {tabData.label}
                </EvText>
            </EvButton>
        );
    };

    const containerHeight =
        innerContainerRef.current && isExpanded
            ? innerContainerRef.current.clientHeight
            : 0;

    return (
        <div
            className={styles.subNavBar.container}
            style={{ height: containerHeight }}
        >
            <div
                ref={innerContainerRef}
                className={styles.subNavBar.innerContainer}
            >
                {listData.map(getTabView)}
            </div>
        </div>
    );
};

EvSubNavBar.propTypes = {
    listData: PropTypes.array,
    isExpanded: PropTypes.bool,
    onSelect: PropTypes.func,
    selectedId: PropTypes.string,
};

EvSubNavBar.defaultProps = {
    listData: [],
    isExpanded: false,
    onSelect: () => {},
    selectedId: "",
};

const EvSideNavBar = (props) => {
    const {
        header,
        listData,
        selectedId,
        className,
        onClickHandler,
        addOnData,
    } = props;

    const [primarySelectedId, setPrimarySelectedId] = useState();
    const [secondarySelectedId, setSecondarySelectedId] = useState();

    useEffect(() => {
        if (
            selectedId === primarySelectedId ||
            selectedId === secondarySelectedId
        ) {
            return;
        }
        let primaryId = "";
        let secondaryId = "";
        listData.every((listItem) => {
            if (listItem.id === selectedId) {
                primaryId = listItem.id;
                return false;
            } else if (!isNullOrEmpty(listItem.subTabs)) {
                listItem.subTabs.every((subTabItem) => {
                    if (subTabItem.id === selectedId) {
                        primaryId = listItem.id;
                        secondaryId = subTabItem.id;
                        return false;
                    }
                    return true;
                });
                return secondaryId === "";
            }
            return true;
        });
        setPrimarySelectedId(primaryId);
        setSecondarySelectedId(secondaryId);
    }, [selectedId, listData, primarySelectedId, secondarySelectedId]);

    const onPrimaryItemClick = useCallback(
        (e, callbackValues) => {
            if (primarySelectedId === callbackValues.id) {
                // same item is clicked
                if (callbackValues.type !== CTA_TYPE.NONE) {
                    onClickHandler(null, callbackValues);
                }
            } else {
                setPrimarySelectedId(callbackValues.id);
                setSecondarySelectedId("");
                if (callbackValues.type !== CTA_TYPE.NONE) {
                    onClickHandler(null, callbackValues);
                }
            }
        },
        [primarySelectedId, onClickHandler]
    );

    const onSecondaryItemClick = useCallback(
        (e, callbackValues) => {
            if (setSecondarySelectedId === callbackValues.id) {
                return;
            }
            setSecondarySelectedId(callbackValues.id);
            onClickHandler(null, callbackValues);
        },
        [setSecondarySelectedId, onClickHandler]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{header}</EvText>
        </div>
    );

    const getSubNavBar = (itemData) => {
        let isExpanded = primarySelectedId === itemData.id;
        if (
            itemData.subTabsVisibilityType ===
            SIDE_BAR_VISIBILITY_MODE.DEPENDENCY_KEY
        ) {
            if (
                isNullOrEmpty(
                    addOnData[itemData.subTabsVisibilityDependencyKey]
                )
            ) {
                isExpanded = false;
            }
        }
        return (
            <EvSubNavBar
                listData={itemData.subTabs}
                isExpanded={isExpanded}
                onSelect={onSecondaryItemClick}
                selectedId={secondarySelectedId}
            />
        );
    };

    const getNavListItem = (itemData) => {
        const isSelected = primarySelectedId === itemData.id;
        const customContainerClass = classNames(styles.list.itemContainer, {
            [styles.list.itemContainerSelected]: isSelected,
        });
        return (
            <div className={customContainerClass} key={itemData.id}>
                <EvButton
                    onlyChild
                    onClickHandler={onPrimaryItemClick}
                    callbackValues={itemData}
                >
                    <div className={styles.list.itemWrapper}>
                        <EvText
                            primaryColored={isSelected}
                            defaultBold={isSelected}
                        >
                            {itemData.label}
                        </EvText>
                    </div>
                </EvButton>
                {!isNullOrEmpty(itemData.subTabs) && getSubNavBar(itemData)}
            </div>
        );
    };

    const getNavList = () => (
        <div className={styles.list.container}>
            {listData.map(getNavListItem)}
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {header && getHeaderView()}
            {getNavList()}
        </div>
    );
};

EvSideNavBar.propTypes = {
    header: PropTypes.string,
    listData: PropTypes.array,
    selectedId: PropTypes.string,
    className: PropTypes.string,
    onClickHandler: PropTypes.func,
    addOnData: PropTypes.object,
};

EvSideNavBar.defaultProps = {
    header: "",
    listData: [],
    selectedId: "",
    className: "",
    onClickHandler: () => {},
    addOnData: {},

    // selectedId: "tab3",
    // header: "Ev SideNavbar",
    // addOnData: {
    //   vendorId: "vendorId",
    // },
    // listData: [
    //   {
    //     id: "tab1",
    //     label: "Tab 1",
    //     action: "/cc/client/manage-programs/customize-programs/tags",
    //     type: "INTERNAL",
    //   },
    //   {
    //     id: "tab2",
    //     label: "Tab 2",
    //     action: "/cc/client/manage-programs/customize-programs/tags",
    //     type: "NONE",
    //     subTabsVisibilityType: "DEFAULT",
    //     subTabs: [
    //       {
    //         id: "subTab1",
    //         label: "Sub Tab 1",
    //       },
    //       {
    //         id: "subTab2",
    //         label: "Sub Tab 2",
    //       },
    //     ],
    //   },
    //   {
    //     id: "tab3",
    //     label: "Tab 3",
    //     action: "/cc/client/manage-programs/customize-programs/tags",
    //     type: "INTERNAL",
    //     subTabsVisibilityType: "DEPENDENCY_KEY",
    //     subTabsVisibilityDependencyKey: "vendorId",
    //     subTabs: [
    //       {
    //         id: "subTab1",
    //         label: "Sub Tab 1",
    //       },
    //       {
    //         id: "subTab2",
    //         label: "Sub Tab 2",
    //       },
    //     ],
    //   },
    // ],
};

export default EvSideNavBar;
