import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
    EvLoadingPage,
    EvNavigationMenu,
    EvToast,
} from "../../../common/components";
import {
    CLIENT_PAGE_PATHS,
    LOADING_STATUS,
    ROUTES,
    USER_BAR_ICONS,
} from "../../../common/static/Enums";
import { CLIENT_STATICS } from "../../../common/static/VmsStatics";
import { AppContainerContext } from "../../../contexts/Context";
import {
    getClientDetailsPageIdFromPath,
    isCompleted,
} from "../../../utils/CommonUtils";
import ClientContentContainer from "../../client-content/views/ClientContentContainer";
import { CLIENT_DETAILS_ROUTES } from "../../client-dashboard/statics/ClientDashboardStatics";
import ClientDetailsRoutes from "../../client-dashboard/views/ClientDetailsRoutes";
import PreProdContainer from "../../pre-prod/views/PreProdContainer";
import VendorProgramContainer from "../../vendor-programs/routes/VendorProgramContainer";
import ClientActions from "../redux/ClientActions";

// we need to keep it globally, will be used in the navigation sequence
// cant put in props or class variable
// eslint-disable-next-line no-unused-vars
let newNavigationMenuSelectedId = "";

const ClientContainer = (props) => {
    const AppContainerContextValues = useContext(AppContainerContext);

    const {
        fetchNavigationData,
        controls,
        navigationData,
        setControls,
        navigateToPage,
        location,
    } = props;

    const isLoading = !isCompleted(controls.navigationDataStatus);

    // const [isExiting, setIsExiting] = useState({});
    // const [isEntering, setIsEntering] = useState({});

    const clientCode = location.state ? location.state.clientCode || "" : "";
    const clientName = location.state ? location.state.clientName || "" : "";
    const pathname = location.pathname ? location.pathname : "";

    useEffect(() => {
        // if landed on pages other than clientDetails Page without client code
        if (
            !clientCode &&
            pathname !== CLIENT_DETAILS_ROUTES.EDIT_CLIENT.path
        ) {
            return navigateToPage({
                path: ROUTES.DASHBOARD,
            });
        }

        if (controls.navigationDataStatus === LOADING_STATUS.COMPLETED) {
            return;
        }

        fetchNavigationData();
    }, [
        fetchNavigationData,
        pathname,
        clientCode,
        controls.navigationDataStatus,
        navigateToPage,
    ]);

    const onNavigationMenuClick = useCallback(
        (navigationMenuSelectedId, itemData) => {
            // clicked on selected route itself
            if (location.pathname === itemData.action) {
                return;
            }
            // if client code is not present, its probably new client page.
            // should not navigate to other pages if not present
            if (!clientCode) {
                EvToast.warn(
                    CLIENT_STATICS.CLIENT_CODE_NOT_PRESET_TOAS.header,
                    CLIENT_STATICS.CLIENT_CODE_NOT_PRESET_TOAS.description
                );
                return;
            }

            navigateToPage({
                path: itemData.action,
                state: {
                    clientCode,
                    clientName,
                },
            });

            // return;
            // prev path can be found by current browser path, not yet changed
            // const prevSelectedMenuId = getClientDetailsPageIdFromPath(
            //   location.pathname
            // );
            // // to decide animation direction
            // const orderDifference = navigationMenuDirectionDecider(
            //   navigationData.navigationOrder,
            //   prevSelectedMenuId,
            //   navigationMenuSelectedId
            // );
            //
            // // global variable used, will be used by callback
            // newNavigationMenuSelectedId = navigationMenuSelectedId;
            //
            // // no need to preserve animation state, will be reset
            // if (orderDifference < 0) {
            //   setIsExiting({
            //     [prevSelectedMenuId]: ANIMATION_TYPE.FORWARD, // previous id
            //     [navigationMenuSelectedId]: ANIMATION_TYPE.NONE, // current id
            //   });
            //   setIsEntering({
            //     [prevSelectedMenuId]: ANIMATION_TYPE.NONE, // previous id
            //     [navigationMenuSelectedId]: ANIMATION_TYPE.BACKWARD, // current id
            //   });
            // } else {
            //   setIsExiting({
            //     [prevSelectedMenuId]: ANIMATION_TYPE.BACKWARD, // previous id
            //     [navigationMenuSelectedId]: ANIMATION_TYPE.NONE, // current id
            //   });
            //   setIsEntering({
            //     [prevSelectedMenuId]: ANIMATION_TYPE.NONE, // previous id
            //     [navigationMenuSelectedId]: ANIMATION_TYPE.FORWARD, // current id
            //   });
            // }
        },
        [location.pathname, navigateToPage, clientCode, clientName]
    );

    // const onExitCompleteCallback = useCallback(
    //   id => {
    //     // get new path enum key
    //     const PAGE_KEY = getClientDetailsPageKeyFromId(
    //       newNavigationMenuSelectedId
    //     );
    //     // do the actual navigation after exit animation is complete
    //     // this will start the entry animation
    //     navigateToPage({
    //       path: CLIENT_PAGE_PATHS[PAGE_KEY].path,
    //       state: {
    //         clientCode,
    //         clientName,
    //       },
    //     });
    //     // reset exit animation
    //     setIsExiting(prevState => ({ ...prevState, [id]: ANIMATION_TYPE.NONE }));
    //   },
    //   [navigateToPage, newNavigationMenuSelectedId]
    // );
    //
    // const onEntryCompleteCallback = useCallback(id => {
    //   // reset entry animation
    //   setIsEntering(prevState => ({
    //     ...prevState,
    //     [id]: ANIMATION_TYPE.NONE,
    //   }));
    // }, []);

    /* eslint-disable */
    useEffect(() => {
        if (controls.navigationDataStatus === LOADING_STATUS.COMPLETED) {
            const currentSelectedId = getClientDetailsPageIdFromPath(pathname);
            AppContainerContextValues.setSecondaryUserBarView(
                <EvNavigationMenu
                    menuBarData={navigationData.data}
                    menuBarOrderArray={navigationData.navigationOrder}
                    onNavigationMenuClick={onNavigationMenuClick}
                    selectedMenuItemId={currentSelectedId}
                />
            );
            AppContainerContextValues.setUserBarIconsData({
                [USER_BAR_ICONS.PRE_PROD]: {
                    // ...USER_BAR_ICONS_DATA.PRE_PROD, // pre prod removed from user bar
                    state: {
                        clientCode,
                        clientName,
                    },
                },
            });
        }
    }, [controls.navigationDataStatus, navigationData, pathname, clientCode]);
    /* eslint-disable */

    /* eslint-disable */
    useEffect(
        () => () => {
            setControls({
                navigationDataStatus: LOADING_STATUS.NOT_YET_STARTED,
            });
            AppContainerContextValues.setSecondaryUserBarView(null);
            AppContainerContextValues.setUserBarIconsData({
                [USER_BAR_ICONS.PRE_PROD]: {},
            });
        },
        []
    );
    /* eslint-disable */

    // const getContentAnimated = () => (
    //   <div>
    //     <Switch>
    //       <Route
    //         path={CLIENT_PAGE_PATHS.CLIENT_DETAILS.path}
    //         exact
    //         render={props => (
    //           <ContainerHoc
    //             id={CLIENT_PAGE_PATHS.CLIENT_DETAILS.id}
    //             isExiting={isExiting[CLIENT_PAGE_PATHS.CLIENT_DETAILS.id]}
    //             isEntering={isEntering[CLIENT_PAGE_PATHS.CLIENT_DETAILS.id]}
    //             onExitCompleteCallback={onExitCompleteCallback}
    //             onEntryCompleteCallback={onEntryCompleteCallback}
    //           >
    //             <ClientDetailsContainer {...props} />
    //           </ContainerHoc>
    //         )}
    //       />
    //       <Route
    //         path={CLIENT_PAGE_PATHS.MANAGE_PROGRAMS.path}
    //         render={props => (
    //           <ContainerHoc
    //             id={CLIENT_PAGE_PATHS.MANAGE_PROGRAMS.id}
    //             isExiting={isExiting[CLIENT_PAGE_PATHS.MANAGE_PROGRAMS.id]}
    //             isEntering={isEntering[CLIENT_PAGE_PATHS.MANAGE_PROGRAMS.id]}
    //             onExitCompleteCallback={onExitCompleteCallback}
    //             onEntryCompleteCallback={onEntryCompleteCallback}
    //           >
    //             <VendorProgramContainer
    //               {...props}
    //               clientCode={clientCode}
    //               clientName={clientName}
    //             />
    //           </ContainerHoc>
    //         )}
    //       />
    //       <Route
    //         path={CLIENT_PAGE_PATHS.CLIENT_CONTENT.path}
    //         exact
    //         render={props => (
    //           <ContainerHoc
    //             id={CLIENT_PAGE_PATHS.CLIENT_CONTENT.id}
    //             isExiting={isExiting[CLIENT_PAGE_PATHS.CLIENT_CONTENT.id]}
    //             isEntering={isEntering[CLIENT_PAGE_PATHS.CLIENT_CONTENT.id]}
    //             onExitCompleteCallback={onExitCompleteCallback}
    //             onEntryCompleteCallback={onEntryCompleteCallback}
    //           >
    //             <ClientContentContainer
    //               {...props}
    //               clientCode={clientCode}
    //               clientName={clientName}
    //             />
    //           </ContainerHoc>
    //         )}
    //       />
    //       <Route
    //         path={CLIENT_PAGE_PATHS.USER_MAPPING.path}
    //         exact
    //         render={props => (
    //           <ContainerHoc
    //             id={CLIENT_PAGE_PATHS.USER_MAPPING.id}
    //             isExiting={isExiting[CLIENT_PAGE_PATHS.USER_MAPPING.id]}
    //             isEntering={isEntering[CLIENT_PAGE_PATHS.USER_MAPPING.id]}
    //             onExitCompleteCallback={onExitCompleteCallback}
    //             onEntryCompleteCallback={onEntryCompleteCallback}
    //           >
    //             <UserMappingContainer
    //               {...props}
    //               clientCode={clientCode}
    //               clientName={clientName}
    //             />
    //           </ContainerHoc>
    //         )}
    //       />
    //     </Switch>
    //   </div>
    // );

    const getContent = () => (
        <Switch>
            <Route
                path={CLIENT_PAGE_PATHS.CLIENT_DETAILS.path}
                render={(props) => (
                    <ClientDetailsRoutes
                        {...props}
                        clientCode={clientCode}
                        clientName={clientName}
                    />
                )}
            />
            <Route
                path={CLIENT_PAGE_PATHS.MANAGE_PROGRAMS.path}
                render={(props) => (
                    <VendorProgramContainer
                        {...props}
                        clientCode={clientCode}
                        clientName={clientName}
                    />
                )}
            />
            <Route
                path={CLIENT_PAGE_PATHS.CLIENT_CONTENT.path}
                render={(props) => (
                    <ClientContentContainer
                        {...props}
                        clientCode={clientCode}
                        clientName={clientName}
                    />
                )}
            />
            {/*<Route*/}
            {/*  path={CLIENT_PAGE_PATHS.USER_MAPPING.path}*/}
            {/*  exact*/}
            {/*  render={props => (*/}
            {/*    <UserMappingContainer*/}
            {/*      {...props}*/}
            {/*      clientCode={clientCode}*/}
            {/*      clientName={clientName}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            <Route
                path={CLIENT_PAGE_PATHS.PRE_PROD.path}
                exact
                render={(props) => (
                    <PreProdContainer
                        {...props}
                        clientCode={clientCode}
                        clientName={clientName}
                    />
                )}
            />
        </Switch>
    );

    return isLoading ? <EvLoadingPage animatedFadeIn /> : getContent();
};

ClientContainer.propTypes = {
    controls: PropTypes.object,
    navigationData: PropTypes.object,
    location: PropTypes.object,

    fetchNavigationData: PropTypes.func,
    setControls: PropTypes.func,
    navigateToPage: PropTypes.func,
};

ClientContainer.defaultProps = {
    controls: {},
    navigationData: {},
    location: {},

    fetchNavigationData: () => {},
    setControls: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientReducer.controls,
    navigationData: state.ClientReducer.navigationData,
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    fetchNavigationData: (payload) =>
        dispatch(ClientActions.fetchNavigationData(payload)),
    setControls: (payload) => dispatch(ClientActions.setControls(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientContainer);
