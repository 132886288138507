import React from "react";

import "../styles/ev-library-colors.scss";
import { COLORS } from "../../../common/static/VmsStatics";
import { EvDivider, EvText } from "../../../common/components";

const styles = {
    container: "ev__ev-library-colors__container",
    labels: {
        container: "ev__ev-library-colors__labels-container",
        text: "ev__ev-library-colors__labels-text",
    },
    list: "ev__ev-library-colors__list",
    row: {
        previewContainer: "ev__ev-library-colors__row-preview-container",
        preview: "ev__ev-library-colors__row-preview",
        container: "ev__ev-library-colors__row-container",
        text: "ev__ev-library-colors__row-text",
    },
};

const EvLibraryColors = () => {
    const getColorRow = (colorKey) => (
        <div className={styles.row.container}>
            <div className={styles.row.previewContainer}>
                <div
                    className={styles.row.preview}
                    style={{ backgroundColor: COLORS[colorKey] }}
                />
            </div>
            <EvText className={styles.row.text}>{colorKey}</EvText>
            <EvText className={styles.row.text}>{COLORS[colorKey]}</EvText>
            <EvText
                className={styles.row.text}
                color={COLORS[colorKey]}
                headingPrimary
            >
                Text Preview
            </EvText>
        </div>
    );

    const getColorList = () => (
        <div className={styles.list}>
            {Object.keys(COLORS).map(getColorRow)}
        </div>
    );

    const getLabels = () => (
        <div className={styles.labels.container}>
            <EvText className={styles.labels.text} subHeading>
                Preview
            </EvText>
            <EvText className={styles.labels.text} subHeading>
                Name
            </EvText>
            <EvText className={styles.labels.text} subHeading>
                Hex Code
            </EvText>
            <EvText className={styles.labels.text} subHeading>
                Text Preview
            </EvText>
        </div>
    );

    return (
        <div className={styles.container}>
            {getLabels()}
            <EvDivider marginVertical={30} />
            {getColorList()}
        </div>
    );
};

export default EvLibraryColors;
