import NotificationsActionTypes from "./NotificationsActionTypes";
import { getData } from "../service/NotificationsService";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { NOTIFICATIONS_COMPONENTS } from "../static/NotificationsStatics";
import API_URLS from "../../../services/apiUrls";
import EvLogger from "../../../utils/EvLogger";
import { normalizerWithOrderArraySingleLevel } from "../../../utils/Normalizer";

const NotificationsActions = {
    setControls: (payload) => ({
        type: NotificationsActionTypes.SET_CONTROLS,
        payload,
    }),

    setStaticData: (payload) => ({
        type: NotificationsActionTypes.SET_STATIC_DATA,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: NotificationsActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            !isNullOrEmpty(
                getState().NotificationsReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().NotificationsReducer.staticData[payload.templateId]
            ); // static data already loaded
        }
        dispatch(
            NotificationsActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        // return internal promise from then
        return getData(payload.url).then((response) => {
            dispatch(
                NotificationsActions.setStaticData({
                    templateId: payload.templateId,
                    staticData: response.data.data,
                })
            );
            dispatch(
                NotificationsActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    setDynamicDataApiStatus: (payload) => ({
        type: NotificationsActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: NotificationsActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    createTagsDictionary: (payload) => (dispatch, getState) => {
        const clientCode = getState().router.location.state.clientCode || "";

        if (
            getState().NotificationsReducer.controls.dynamicDataApiStatus[
                NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
            ] === LOADING_STATUS.COMPLETED
        ) {
            return;
        }

        dispatch(
            NotificationsActions.setDynamicDataApiStatus({
                [NOTIFICATIONS_COMPONENTS.ALL_TAGS.id]: LOADING_STATUS.LOADING,
            })
        );

        getData(API_URLS.NOTIFICATIONS.TAG_SELECTION.ALL_TAGS, { clientCode })
            .then((allTagsResponse) => {
                const normalisedTags = normalizerWithOrderArraySingleLevel(
                    allTagsResponse.data.data.tags
                );
                normalisedTags.ids.forEach((tagId) => {
                    normalisedTags.value[tagId] = {
                        ...normalisedTags.value[tagId],
                        tagRuleFields: {
                            ...(normalisedTags.value[tagId].tagRuleFields
                                ? normalisedTags.value[tagId].tagRuleFields
                                : {}),
                            mappedField: {
                                id: tagId,
                                name: normalisedTags.value[tagId].displayName,
                                value: tagId,
                            },
                        },
                    };
                });
                dispatch(
                    NotificationsActions.setComponentData({
                        componentId: NOTIFICATIONS_COMPONENTS.ALL_TAGS.id,
                        data: {
                            ...normalisedTags,
                            rawData: allTagsResponse.data.data.tags,
                        },
                    })
                );
                dispatch(
                    NotificationsActions.setDynamicDataApiStatus({
                        [NOTIFICATIONS_COMPONENTS.ALL_TAGS.id]:
                            LOADING_STATUS.COMPLETED,
                    })
                );
            })
            .catch((e) => {
                dispatch(
                    NotificationsActions.setDynamicDataApiStatus({
                        [NOTIFICATIONS_COMPONENTS.ALL_TAGS.id]:
                            LOADING_STATUS.FAILED,
                    })
                );
                EvLogger.errorWithObject(
                    e,
                    "NotificationAction createTagDictionary"
                );
            });
    },

    // when sidebar is clicked, do this to reset notifications partially
    setLandingData: (payload) => ({
        type: NotificationsActionTypes.SET_LANDING_DATA,
        payload,
    }),

    resetAllData: (payload) => ({
        type: NotificationsActionTypes.RESET_ALL_DATA,
        payload,
    }),
};

export default NotificationsActions;
