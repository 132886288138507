import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { PREVIEW_CARD_TYPES } from "../../static/Enums";
import { EvCardImageLandscape, EvCardImagePortrait } from "./index";

import "../../styles/ev-cards/ev-card-resolver.scss";

const styles = {
    container: "ev__card-resolver__container",
    imagePortrait: {
        container: "ev__card-resolver__image-portrait-container",
        image: "ev__card-resolver__image-portrait-image",
    },
    imageLandscape: {
        container: "ev__card-resolver__image-landscape-container",
        image: "ev__card-resolver__image-landscape-image",
    },
};

const EvCardResolver = (props) => {
    const { cardData, cardType, cardTypeData, className } = props;

    const getCard = () => {
        switch (cardType) {
            case PREVIEW_CARD_TYPES.IMAGE_PORTRAIT:
                return (
                    <EvCardImagePortrait
                        header={cardData.displayName}
                        description={
                            cardData.description ? cardData.description : ""
                        }
                        className={styles.imagePortrait.container}
                        imageClassName={styles.imagePortrait.image}
                        imageUrl={cardData.imageUrl} // TODO remove later
                        backgroundColor={cardData.backgroundColor}
                        cardLogo={cardData.cardLogo}
                        backgroundImage={cardData.backgroundImage}
                    />
                );

            case PREVIEW_CARD_TYPES.IMAGE_LANDSCAPE:
                return (
                    <EvCardImageLandscape
                        header={cardData.displayName}
                        description={
                            cardData.description ? cardData.description : ""
                        }
                        headerTextColor={cardTypeData.headerTextColor}
                        className={styles.imageLandscape.container}
                        imageClassName={styles.imageLandscape.image}
                        imageUrl={cardData.imageUrl} // TODO remove later
                        backgroundColor={cardData.backgroundColor}
                        cardLogo={cardData.cardLogo}
                        backgroundImage={cardData.backgroundImage}
                    />
                );

            default:
                return <span />;
        }
    };

    const customContainerClass = classNames(styles.container, className);

    return <div className={customContainerClass}>{getCard()}</div>;
};

EvCardResolver.propTypes = {
    cardData: PropTypes.object,
    cardType: PropTypes.string,
    cardTypeData: PropTypes.object,
    className: PropTypes.string,
};

EvCardResolver.defaultProps = {
    cardType: "",
    cardData: {},
    cardTypeData: {},
    className: "",
};

export default EvCardResolver;
