const EvInfoBoxData = {
    data1: {
        header: "You have unlinked tags.",
        description:
            "The next step is to define and link your eligibility tags based on the eligibility file. This will connect all the necessary data.",
        iconName: "ALERT",
    },
};

export default EvInfoBoxData;
