import APP_COMMON_ACTION_TYPES from "./AppCommonActionTypes";

const initialState = {
    controls: {
        dynamicDataApiStatus: {},
        staticDataApiStatus: {},
    },
    dynamicData: {},
    staticData: {},
};

// NOTE: THIS IS THE NEW EXPERIMENTAL WAY OF HANDLING REDUX DATA.
// IFF SUCCESSFUL ALL APPLICATIONS WILL USE THIS

const AppCommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case APP_COMMON_ACTION_TYPES.SET_DYNAMIC_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case APP_COMMON_ACTION_TYPES.SET_STATIC_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case APP_COMMON_ACTION_TYPES.SET_STATIC_DATA:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.data,
                },
            };

        case APP_COMMON_ACTION_TYPES.SET_COMPONENT_DATA:
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    [action.payload.componentId]: action.payload.data,
                },
            };

        default:
            return state;
    }
};

export default AppCommonReducer;
