import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import { EvButton, EvIcon } from "..";
import FormValidation from "../../../utils/FormValidations";
import { COLORS } from "../../static/VmsStatics";
import { EvInputErrorView } from "./index";
import { FORM_FIELD_TYPES } from "../../static/Enums";

import "../../styles/form-components/ev-date-picker.scss";
// import "react-datepicker/dist/react-datepicker.css";

const EvDatePicker = (props) => {
    const styles = {
        container: "ev__ev-date-picker__container",
        inputWrapper: "ev__ev-date-picker__input-wrapper",
        inputBox: "ev__ev-date-picker__input-box",
        datePickerCustom: "ev__ev-date-picker__date-picker-custom",
    };

    const { type, formItem, formData, value } = props;

    const datePickerRef = useRef(null);

    const minDate = useMemo(() => {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    }, []);

    const handleChange = (date) => {
        const inputDateObject = {
            [props.keyName]: {
                key: props.keyName,
                value: date,
                type: props.type,
            },
            errorObject: {},
            formItemType: type,
        };

        props.handleInputChange(props.keyName, {
            ...inputDateObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputDateObject),
            },
        });
    };

    const onIconClick = useCallback(() => {
        datePickerRef.current.setOpen(true);
    }, []);

    const getIcon = () => (
        <EvButton onlyChild onClickHandler={onIconClick}>
            <EvIcon size={3} iconName="CALENDAR" fillColor={COLORS.PRODUCT} />
        </EvButton>
    );

    return (
        <div className={styles.container}>
            <div className={styles.inputWrapper}>
                <DatePicker
                    ref={datePickerRef}
                    placeholderText={formItem.placeholder}
                    selected={value}
                    onChange={handleChange}
                    className={styles.inputBox}
                    dateFormat="yyyy-MM-dd"
                    minDate={minDate}
                    calendarClassName={styles.datePickerCustom}
                    fixedHeight
                />
                {getIcon()}
            </div>
            <EvInputErrorView errorObject={formData.errorObject} />
        </div>
    );
};

EvDatePicker.propTypes = {
    keyName: PropTypes.string,
    type: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    value: PropTypes.string,
    handleInputChange: PropTypes.func,
};

EvDatePicker.defaultProps = {
    keyName: "",
    type: FORM_FIELD_TYPES.DATE,
    formItem: {},
    formData: {},
    value: "",

    handleInputChange: () => {},
};

export default EvDatePicker;
