import React, { useEffect, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/NotificationsActions";
import { getData, postData } from "../service/NotificationsService";
import API_URLS from "../../../services/apiUrls";
import {
    LOADING_STATUS,
    LIVE_STATUS,
    DEFAULT_LOADING_TEXT,
} from "../../../common/static/Enums";
import {
    NOTIFICATIONS_PAGES,
    NOTIFICATION_TEMPLATES,
    NOTIFICATIONS_COMPONENTS,
} from "../static/NotificationsStatics";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvLoadingPage,
    EvText,
    EvDivider,
    EvButton,
    EvToast,
    EvSummary,
    EvLoader,
} from "../../../common/components";
import EvLogger from "../../../utils/EvLogger";

import "../styles/notifications-summary.scss";

const styles = {
    container: "ev__notifications-summary__container",
    scheduleButton: "ev__notifications-summary__schedule-button",
    header: {
        container: "ev__notifications-summary__header-container",
        text: "ev__notifications-summary__header-text",
    },
    segments: {
        container: "ev__notifications-summary__segment-container",
    },
    footer: {
        container: "ev__notifications-summary__footer-container",
    },
    actionView: {
        container: "ev__notifications-summary__action-view-container",
        loading: "ev__notifications-summary__action-view-loading",
    },
};

const NotificationsSummary = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        summaryData,
        // allTags,

        // actions
        setControls,
        getStaticData,
        // createTagsDictionary,
        setDynamicDataApiStatus,
        setComponentData,
    } = props;

    const notificationId = useMemo(
        () => controls.currentNotificationId || "",
        [controls.currentNotificationId]
    );

    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    useEffect(() => {
        getStaticData({
            templateId: NOTIFICATION_TEMPLATES.SUMMARY_PAGE_STATIC.id,
            url: API_URLS.NOTIFICATIONS.SUMMARY.STATIC,
        });
        setDynamicDataApiStatus({
            [NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id]: LOADING_STATUS.LOADING,
        });
        getData(API_URLS.NOTIFICATIONS.SUMMARY.DYNAMIC, {
            notificationId,
            clientCode,
            populationId: "",
        })
            .then((summaryDataResponse) => {
                setComponentData({
                    componentId: NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id,
                    data: summaryDataResponse.data.data,
                });
                setDynamicDataApiStatus({
                    [NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "NotificationSummary getData");
            });
    }, [
        getStaticData,
        clientCode,
        notificationId,
        setComponentData,
        setDynamicDataApiStatus,
    ]);

    // // create all tags dictionary, if not already preset
    // useEffect(() => {
    //     if (
    //         !isCompleted(
    //             controls.dynamicDataApiStatus[
    //                 NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
    //             ]
    //         )
    //     ) {
    //         createTagsDictionary();
    //     }
    // }, [createTagsDictionary, controls.dynamicDataApiStatus]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onSegmentEditClick = useCallback(
        (actionPage) => {
            setControls({
                selectedPageId: actionPage,
            });
        },
        [setControls]
    );

    const onScheduleClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        postData(API_URLS.NOTIFICATIONS.SUMMARY.POST_SCHEDULE, {
            notificationId,
            clientCode,
        })
            .then((response) => {
                EvToast.success(
                    "Success",
                    "Notifications scheduled successfully"
                );
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                setControls({
                    selectedPageId: NOTIFICATIONS_PAGES.DASHBOARD.id,
                });
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Something went wrong");
                EvLogger.errorWithObject(e, "NotificationSummary onSchedule");
            });
    }, [notificationId, setControls, clientCode, submitStatus]);

    const getSegmentView = (segmentData) => (
        <div className={styles.segments.container}>
            <EvDivider />
            <EvSummary
                id={segmentData.id}
                segmentStaticData={segmentData}
                data={summaryData[segmentData.id]}
                onSegmentEditClick={onSegmentEditClick}
                editable={controls.notificationStatus === LIVE_STATUS.DRAFT}
                // allTags={allTags.value}
                qParams={{
                    clientCode,
                    notificationId,
                }}
            />
        </div>
    );

    const getSegments = () => (
        <div className={styles.segments.container}>
            {staticData.segments.map(getSegmentView)}
        </div>
    );

    const getScheduleButton = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primaryFilled
                className={styles.scheduleButton}
                onClickHandler={onScheduleClick}
            >
                {staticData.sendCta.text}
            </EvButton>
            {submitStatus === LOADING_STATUS.LOADING && (
                <EvLoader size={3} className={styles.actionView.loading} />
            )}
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText className={styles.header.text} subHeading>
                {staticData.header}
            </EvText>
            <EvText className={styles.header.text}>
                {staticData.description}
            </EvText>
            {controls.notificationStatus === LIVE_STATUS.DRAFT &&
                getScheduleButton()}
        </div>
    );

    const getFooter = () => (
        <div className={styles.footer.container}>
            {controls.notificationStatus === LIVE_STATUS.DRAFT &&
                getScheduleButton()}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                NOTIFICATION_TEMPLATES.SUMMARY_PAGE_STATIC.id
            ],
            controls.dynamicDataApiStatus[
                NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id
            ]
            // controls.dynamicDataApiStatus[NOTIFICATIONS_COMPONENTS.ALL_TAGS.id]
        )
    ) {
        return (
            <EvLoadingPage
                loadingText={staticData.loadingText || DEFAULT_LOADING_TEXT}
            />
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getSegments()}
            {getFooter()}
        </div>
    );
};

NotificationsSummary.propTypes = {
    controls: PropTypes.object,
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    summaryData: PropTypes.object,
    allTags: PropTypes.object,

    // actions
    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    createTagsDictionary: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    setComponentData: PropTypes.func,
};

NotificationsSummary.defaultProps = {
    controls: {},
    clientCode: "",
    staticData: {},
    summaryData: {},
    allTags: {},

    // actions
    setControls: () => {},
    getStaticData: () => {},
    createTagsDictionary: () => {},
    setDynamicDataApiStatus: () => {},
    setComponentData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.SUMMARY_PAGE_STATIC.id
        ],
    summaryData:
        state.NotificationsReducer.dynamicData[
            NOTIFICATIONS_COMPONENTS.SUMMARY_DATA.id
        ],
    // allTags:
    //     state.NotificationsReducer.dynamicData[
    //         NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
    //     ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    createTagsDictionary: (payload) =>
        dispatch(NotificationsActions.createTagsDictionary(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(NotificationsActions.setComponentData(payload)),
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsSummary);
