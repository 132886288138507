import { LOADING_STATUS } from "../../../common/static/Enums";
import ClientActionTypes from "./ClientActionTypes";

const initialState = {
    controls: {
        navigationDataStatus: LOADING_STATUS.NOT_YET_STARTED,
        // navigationMenuSelectedId: "", // not needed all checks are done from location.pathname
        // this is done to handle back button navigation
    },
    navigationData: {
        data: {},
        navigationOrder: [],
    },
};

const ClientReducer = (state = initialState, action) => {
    switch (action.type) {
        case ClientActionTypes.SET_CONTROLS: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };
        }

        case ClientActionTypes.SET_DATA: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default:
            return state;
    }
};

export default ClientReducer;
