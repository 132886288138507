import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock, apiPost } from "../../../services/api";
import getEmployerCardDataJSON from "../static/getEmployerCardDataJSON";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        case API_URLS.EMPLOYER_CARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getEmployerCardDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.EMPLOYER_CARD.PREFILL:
            return USE_MOCK_DATA
                ? apiMock(url, params, getEmployerCardDataJSON.PREFILL)
                : apiGet(url, params);

        default:
            EvLogger.warn("EmployerCardService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.EMPLOYER_CARD.SUBMIT:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getEmployerCardDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("EmployerCardService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
