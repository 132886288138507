import { goBack } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import CommonActions from "../../../common/redux/CommonActions";
import { getData, postData } from "../../../common/service/CommonService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    WHERE_TO_APP_ID,
    WHERE_TO_STATICS,
    WHERE_TO_TEMPLATES,
} from "../statics/WhereToStatics";
import WhereToView from "./WhereToView";

const WhereToContainer = (props) => {
    const { staticData, location, getStaticData, goBackAction } = props;

    const [formDataLoadingStatus, setFormDataLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.WHERE_TO.STATIC,
                    templateId: WHERE_TO_TEMPLATES.STATIC.id,
                }),
                getData(API_URLS.WHERE_TO.PREFILL_DATA, {
                    programId: vendorCardData.programId,
                    vendorId: vendorCardData.vendorId,
                    featureId: vendorCardData.cardId,
                    clientCode,
                }),
            ]),
        [clientCode, getStaticData, vendorCardData]
    );

    useEffect(() => {
        if (isCompletedOrLoading(formDataLoadingStatus)) {
            return;
        }
        setFormDataLoadingStatus(LOADING_STATUS.LOADING);
        getAllData()
            .then(([staticDatResponse, prefillDataResponse]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDatResponse.formFields,
                    prefillDataResponse.data.data
                );
                setFormData(initialFormData);
                setFormDataLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setFormDataLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "WhereToContainer getData");
            });
    }, [getAllData, formDataLoadingStatus]);

    const onFormSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.error("", WHERE_TO_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.WHERE_TO.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
            }
        )
            .then((submitResponse) => {
                EvToast.success(
                    "Updated",
                    "Where To Go For Care Card updated!",
                    {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    }
                );
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                goBackAction();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "WhereToContainer onFormSubmit");
            });
    }, [clientCode, formData, submitStatus, vendorCardData, goBackAction]);

    if (!isCompleted(formDataLoadingStatus)) {
        return <EvLoadingPage animatedFadeIn />;
    }
    return (
        <WhereToView
            staticData={staticData}
            formData={formData}
            setFormData={setFormData}
            onFormSubmit={onFormSubmit}
            vendorCardData={vendorCardData}
            submitStatus={submitStatus}
            clientCode={clientCode}
        />
    );
};

WhereToContainer.propTypes = {
    staticData: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    goBackAction: PropTypes.func,
};

WhereToContainer.defaultProps = {
    staticData: {},
    location: {},

    getStaticData: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.CommonReducer[WHERE_TO_APP_ID].staticData[
            WHERE_TO_TEMPLATES.STATIC.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: WHERE_TO_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WhereToContainer);
