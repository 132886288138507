import React, { memo } from "react";
import PropTypes from "prop-types";

import { PREVIEW_CARD_TYPES } from "../static/Enums";
import {
    EvLogoPreview,
    EvBasicPreview,
    EvCtaPreview,
    EvDetailedPreview,
} from ".";

const EvPreviewCard = (props) => {
    const {
        type,
        cta,
        imgSrc,
        imgUrl,
        heading,
        subHeading,
        description,
        subDescription,
        logo,
        bgColor,
    } = props;

    const renderLogoPreview = () => (
        <EvLogoPreview bgColor={bgColor} imgSrc={imgSrc} imgUrl={imgUrl} />
    );

    const renderBasicPreview = () => (
        <EvBasicPreview
            imgUrl={imgUrl}
            heading={heading}
            description={description}
        />
    );

    const renderCtaPreview = () => (
        <EvCtaPreview
            imgUrl={imgUrl}
            heading={heading}
            description={description}
            cta={cta}
        />
    );

    const renderDetailedCardPreview = () => (
        <EvDetailedPreview
            imgUrl={imgUrl}
            heading={heading}
            subHeading={subHeading}
            description={description}
            subDescription={subDescription}
            logo={logo}
        />
    );

    const renderPreviewCard = () => {
        switch (type) {
            case PREVIEW_CARD_TYPES.LOGO:
                return renderLogoPreview();
            case PREVIEW_CARD_TYPES.BASIC_CARD:
                return renderBasicPreview();
            case PREVIEW_CARD_TYPES.CTA_CARD:
                return renderCtaPreview();
            case PREVIEW_CARD_TYPES.DETAILED_CARD:
                return renderDetailedCardPreview();
            default:
                return null;
        }
    };

    return renderPreviewCard();
};

EvPreviewCard.propTypes = {
    type: PropTypes.string,
    imgSrc: PropTypes.string,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    subDescription: PropTypes.string,
    logo: PropTypes.string,
    data: PropTypes.object,
    cta: PropTypes.string,
};

EvPreviewCard.defaultProps = {
    type: "",
    imgSrc: "",
    heading: "",
    subHeading: "",
    description: "",
    subDescription: "",
    logo: "",

    data: {},
    cta: "",
};

export default memo(EvPreviewCard);
