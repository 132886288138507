import { apiGet, apiMock } from "../../../services/api";
import API_URLS from "../../../services/apiUrls";
import {
    getUserDataJSON,
    getUserStaticDataJSON,
} from "../static/getUserDataJSON";
import EvLogger from "../../../utils/EvLogger";

// const USE_MOCK_DATA = true;

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        case API_URLS.APP_USER_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getUserDataJSON)
                : apiGet(url, params);

        case API_URLS.APP_USER_STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getUserStaticDataJSON)
                : apiGet(url, params);

        default:
            EvLogger.warn(
                `AppContainerService getData, unknown API endpoint, ${url}, with params ${params}`,
                "cc_service",
                "api"
            );
            return apiGet(url, params);
    }
};

export { getData };
