import { apiGet, apiMock, apiPost } from "../../../services/api";
import API_URLS from "../../../services/apiUrls";

import {
    getClientListStaticDataJSON,
    getClientListDataJSON,
} from "../static/getClientListDataJSON";
import EvLogger from "../../../utils/EvLogger";
import getUserListDataJSON from "../static/getUserListDataJSON";
import getAddEditUserDataJSON from "../static/getAddEditUserDataJSON";

/* global USE_MOCK_DATA */

//-----------------------------

const getData = (url, params) => {
    switch (url) {
        // static data and templates
        case API_URLS.DASHBOARD.GET_CLIENT_LIST_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientListDataJSON)
                : apiGet(url, params);

        // dynamic data
        case API_URLS.DASHBOARD.GET_CLIENT_LIST_STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientListStaticDataJSON)
                : apiGet(url, params);

        case API_URLS.USERS.LIST_STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, getUserListDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.USERS.USERS_LIST:
            return USE_MOCK_DATA
                ? apiMock(url, params, getUserListDataJSON.USERS_LIST)
                : apiGet(url, params);

        // ADD EDIT USER

        case API_URLS.USERS.EDIT_USER_STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getAddEditUserDataJSON.STATIC)
                : apiGet(url, params);

        case API_URLS.USERS.GET_USER_PREFILL_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getAddEditUserDataJSON.PREFILL)
                : apiGet(url, params);

        default:
            EvLogger.warn("Dashboard Service postData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.USERS.SAVE_USER_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, getAddEditUserDataJSON.SUBMIT, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("Dashboard Service postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
