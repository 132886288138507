import React from "react";
import PropTypes from "prop-types";

import { EvText, EvSimpleCard } from ".";

import "../styles/ev-component-error-msg.scss";

const EvComponentErrorMsg = (props) => {
    const styles = {
        container: "ev__ev-component-error-msg__container",
    };

    return (
        <EvSimpleCard>
            <div className={styles.container}>
                <EvText default>{props.message}</EvText>
            </div>
        </EvSimpleCard>
    );
};

EvComponentErrorMsg.propTypes = {
    message: PropTypes.string,
};
EvComponentErrorMsg.defaultProps = {
    message: "",
};

export default EvComponentErrorMsg;
