const CLIENT_CONTENT_PAGES = {
    DASHBOARD: {
        path: "/cc/client/client-content",
        id: "clientContent_dashboard",
    },
    NOTIFICATIONS: {
        path: "/cc/client/client-content/notifications",
        id: "clientContent_notifications",
    },
    EMPLOYER_CARD: {
        path: "/cc/client/client-content/employer-card",
        id: "clientContent_employerCard",
    },
    CARDS_DASHBOARD: {
        path: "/cc/client/client-content/cards",
        id: "clientContent_cardsDashboard",
    },
    GAMEDAY_SCHEDULE: {
        path: "/cc/client/client-content/gameday-schedule",
        id: "clientContent_gamedaySchedule",
    },
    LINKED_UNLINKED_TAGS: {
        path: "/cc/client/client-content/tags",
        id: "clientContent_tags",
    },
};

const CLIENT_CONTENT_TEMPLATES = {
    STATIC: {
        id: "clientContent__static",
    },
};

export { CLIENT_CONTENT_PAGES, CLIENT_CONTENT_TEMPLATES };
