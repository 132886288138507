import React, { memo } from "react";
import PropTypes from "prop-types";

import { EvText, EvSimpleCard, EvIcon } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-detailed-preview.scss";

const EvDetailedPreview = (props) => {
    const styles = {
        detailedCard: {
            container: "ev__detailed-preview__container",
            imageHolder: "ev__detailed-preview__image-holder",
            image: "ev__detailed-preview__image",
            cardInfo: "ev__detailed-preview__card-info",
            cardDetailedInfo: "ev__detailed-preview__card-detailed-info",
            secondaryImageHolder:
                "ev__detailed-preview__secondary-image-holder",
            secondaryImage: "ev__detailed-preview__secondary-image",
            backButtonWrapper: "ev__detailed-preview__back-button-wrapper",
            iconWrapper: "ev__detailed-preview__icon-wrapper",
            divider: "ev__detailed-preview__divider",
        },
    };

    const {
        heading,
        subHeading,
        description,
        subDescription,
        imgUrl,
        logo,
    } = props;

    const getCardHeader = () => (
        <div>
            <EvSimpleCard
                noPadding
                inlineStyle={{ borderRadius: "0px" }}
                shadow={false}
            >
                <div
                    className={styles.detailedCard.imageHolder}
                    style={{ backgroundImage: `url(${imgUrl})` }}
                    role="img"
                    aria-label="employer card landing page preview"
                >
                    <div className={styles.detailedCard.backButtonWrapper}>
                        <EvIcon
                            iconName="ARROW_THICK_LEFT"
                            bgColor={COLORS.BLACK_TROUT}
                            fillColor={COLORS.WHITE}
                            circular
                            size={3}
                            outerContainerStyleClass={
                                styles.detailedCard.iconWrapper
                            }
                        />
                    </div>
                </div>
                <div className={styles.detailedCard.cardInfo}>
                    <EvText headingSecondary>{heading}</EvText>
                    <EvText default>{subHeading}</EvText>
                </div>
            </EvSimpleCard>
        </div>
    );

    const getCardBody = () => (
        <div className={styles.detailedCard.cardDetailedInfo}>
            <EvText defaultDarkBold>{description}</EvText>
            <EvText smallNormal>{subDescription}</EvText>
            <div className={styles.detailedCard.divider} />
            <div className={styles.detailedCard.secondaryImageHolder}>
                <div
                    role="img"
                    aria-label="company logo"
                    className={styles.detailedCard.secondaryImage}
                    style={{ backgroundImage: `url(${logo})` }}
                />
            </div>
        </div>
    );

    return (
        <div className={styles.detailedCard.container}>
            {getCardHeader()}
            {getCardBody()}
        </div>
    );
};

EvDetailedPreview.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    description: PropTypes.string,
    subDescription: PropTypes.string,
    imgUrl: PropTypes.string,
    logo: PropTypes.string,
};

EvDetailedPreview.defaultProps = {
    heading: "",
    subHeading: "",
    description: "",
    subDescription: "",
    imgUrl: "",
    logo: "",
};

export default memo(EvDetailedPreview);
