import getClientContentStaticDataJSON from "../static/getClientContentDataJSON";
import API_URLS from "../../../services/apiUrls";
import { apiGet, apiMock } from "../../../services/api";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // static data and templates
        case API_URLS.CLIENT_CONTENT.STATIC_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, getClientContentStaticDataJSON)
                : apiGet(url, params);

        default:
            EvLogger.warn("ClientContentService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const dummy = null;

export { getData, dummy };
