import React, { Fragment, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { EvButton, EvIcon, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import {
    ENTERPRISE_NOTIFICATIONS_BACK_BUTTON,
    ENTERPRISE_NOTIFICATIONS_PAGES,
} from "../static/enterpriseNotificationsStatics";
import EnterpriseNotificationsActions from "../redux/EnterpriseNotificationsActions";
import EnterpriseNotificationsClientSelection from "./EnterpriseNotificationsClientSelection";
import EnterpriseNotificationsProgramSelection from "./EnterpriseNotificationsProgramSelection";
import EnterpriseNotificationsContent from "./EnterpriseNotificationsContent";
import EnterpriseNotificationsSummary from "./EnterpriseNotificationsSummary";

import "../styles/enterprise-notifications-container.scss";
import { LIVE_STATUS } from "../../../common/static/Enums";

const styles = {
    backButton: {
        container:
            "ev__enterprise-notifications-container__back-button-container",
        wrapper:
            "ev__enterprise-notifications-container__back-button-inner-wrapper",
        text: "ev__enterprise-notifications-container__back-button-text",
    },
    contentContainer: "ev__notifications-container__content-container",
};

const EnterpriseNotificationsContainer = (props) => {
    const { controls, setControls, resetAllData } = props;

    useEffect(
        () => () => {
            // cleanup code
            resetAllData();
        },
        [resetAllData]
    );

    const getView = () => {
        switch (controls.selectedPageId) {
            case ENTERPRISE_NOTIFICATIONS_PAGES.CLIENT_SELECTION.id:
                return <EnterpriseNotificationsClientSelection />;
            case ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id:
                return <EnterpriseNotificationsProgramSelection />;
            case ENTERPRISE_NOTIFICATIONS_PAGES.CONTENT.id:
                return <EnterpriseNotificationsContent />;
            case ENTERPRISE_NOTIFICATIONS_PAGES.SUMMARY.id:
                return <EnterpriseNotificationsSummary />;
            default:
                return <div>Notifications Dashboard</div>;
        }
    };

    const onBackButtonClick = useCallback(
        (e, callbackOptions) => {
            setControls({
                selectedPageId: callbackOptions.actionPageId,
            });
        },
        [setControls]
    );

    const getBackButton = () => {
        let backButtonObject =
            ENTERPRISE_NOTIFICATIONS_BACK_BUTTON[controls.selectedPageId];
        if (isNullOrEmpty(backButtonObject)) {
            return null;
        }

        if (
            controls.notificationStatus === LIVE_STATUS.SCHEDULED ||
            controls.notificationStatus === LIVE_STATUS.LIVE
        ) {
            backButtonObject =
                ENTERPRISE_NOTIFICATIONS_BACK_BUTTON[
                    ENTERPRISE_NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id
                ];
        }

        return (
            <div className={styles.backButton.container}>
                <EvButton
                    onlyChild
                    onClickHandler={onBackButtonClick}
                    callbackValues={backButtonObject}
                >
                    <div className={styles.backButton.wrapper}>
                        <EvIcon
                            iconName="ARROW_THICK_LEFT"
                            size={3}
                            fillColor={COLORS.PRODUCT}
                        />
                        <EvText
                            primaryColored
                            defaultBold
                            className={styles.backButton.text}
                        >
                            {backButtonObject.text}
                        </EvText>
                    </div>
                </EvButton>
            </div>
        );
    };

    return (
        <Fragment>
            {getBackButton()}
            <div className={styles.contentContainer}>{getView()}</div>
        </Fragment>
    );
};

EnterpriseNotificationsContainer.propTypes = {
    controls: PropTypes.object,
    setControls: PropTypes.func,
    resetAllData: PropTypes.func,
};

EnterpriseNotificationsContainer.defaultProps = {
    controls: {},
    setControls: () => {},
    resetAllData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EnterpriseNotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(EnterpriseNotificationsActions.setControls(payload)),
    resetAllData: (payload) =>
        dispatch(EnterpriseNotificationsActions.resetAllData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseNotificationsContainer);
