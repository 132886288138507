import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvIcon, EvText, EvButton } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-navigation-menu.scss";

const EvNavigationMenu = (props) => {
    const styles = {
        container: "ev__ev-navigation-menu__container",
    };

    const {
        menuBarData,
        selectedMenuItemId,
        onNavigationMenuClick,
        menuBarOrderArray,
    } = props;

    const getMenuItem = (menuItemId) => {
        const menuItem = menuBarData[menuItemId];
        return (
            <EvMenuItem
                key={menuItem.id}
                itemData={menuItem}
                id={menuItem.id}
                isSelected={menuItem.id === selectedMenuItemId}
                onMenuItemClick={onNavigationMenuClick}
            />
        );
    };

    return (
        <div className={styles.container}>
            {menuBarOrderArray.map(getMenuItem)}
        </div>
    );
};

EvNavigationMenu.propTypes = {
    menuBarData: PropTypes.object,
    menuBarOrderArray: PropTypes.array,
    selectedMenuItemId: PropTypes.string,

    onNavigationMenuClick: PropTypes.func,
};

EvNavigationMenu.defaultProps = {
    menuBarData: {},
    menuBarOrderArray: [],
    selectedMenuItemId: "",

    onNavigationMenuClick: () => {},
};

const EvMenuItem = (props) => {
    const styles = {
        menuItem: "ev__ev-navigation-menu__menu-item-container",
        menuItemSelected:
            "ev__ev-navigation-menu__menu-item-container-selected",
    };

    const { id, itemData, isSelected, onMenuItemClick } = props;

    const onItemClick = () => {
        onMenuItemClick(id, itemData);
    };

    const menuItemCustomClass = classNames(styles.menuItem, {
        [styles.menuItemSelected]: isSelected,
    });

    return (
        <EvButton
            onlyChild
            onClickHandler={onItemClick}
            className={menuItemCustomClass}
        >
            <EvIcon
                iconName={itemData.icon}
                size={4}
                fillColor={isSelected ? COLORS.PRODUCT : COLORS.BLACK_TROUT}
            />
            <EvText
                default={!isSelected}
                defaultBold={isSelected}
                primaryColored={isSelected}
            >
                {itemData.text}
            </EvText>
        </EvButton>
    );
};

EvMenuItem.propTypes = {
    id: PropTypes.string,
    isSelected: PropTypes.bool,
    itemData: PropTypes.object,

    onMenuItemClick: PropTypes.func,
};

EvMenuItem.defaultProps = {
    id: "",
    isSelected: false,
    itemData: {},

    onMenuItemClick: () => {},
};

export default memo(EvNavigationMenu);
