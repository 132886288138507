import React, { useCallback, useEffect, useState } from "react";
import { EvLoadingPage, EvToast } from "../../../common/components";
// import PropTypes from "prop-types";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { getData, postData } from "../service/DataUploderService";
import DataUploaderView from "./DataUploaderView";

const DataUploaderContainer = () => {
    const [formTypeStaticsApiStatus, setFormTypeStaticsApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [
        formFieldsStaticsApiStatus,
        setFormFieldsStaticsApiStatus,
    ] = useState(LOADING_STATUS.NOT_YET_STARTED);
    const [prefillDataApiStatus, setPrefillDataApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitFormApiStatus, setSubmitFormApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const [formTypeStatics, setFormTypeStatics] = useState({});
    const [formTypeFormData, setFormTypeFormData] = useState({});

    const [formFieldsStatics, setFormFieldsStatics] = useState({});
    const [formFieldsFormData, setFormFieldsFormData] = useState({});

    useEffect(() => {
        setFormTypeStaticsApiStatus(LOADING_STATUS.LOADING);
        getData(API_URLS.DATA_UPLOADER.GET_FORM_TYPES)
            .then((staticResponse) => {
                setFormTypeStatics(staticResponse.data.data);
                setFormTypeFormData(
                    parseInitialStateFromFormFields(
                        staticResponse.data.data.formFields
                    )
                );
                setFormTypeStaticsApiStatus(LOADING_STATUS.COMPLETED);
            })
            .catch(() => {
                setFormTypeStaticsApiStatus(LOADING_STATUS.FAILED);
            });
    }, []);

    const setFormTypeFormDataLocal = useCallback((newState) => {
        // reset formFields Data
        setFormFieldsFormData({});
        setFormFieldsStatics({});
        setFormFieldsStaticsApiStatus(LOADING_STATUS.NOT_YET_STARTED);
        setFormTypeFormData(newState);
    }, []);

    const onGetFormOfTypeClick = useCallback(() => {
        setFormFieldsStaticsApiStatus(LOADING_STATUS.LOADING);
        const formDataForApi = parseFormDataForApi(formTypeFormData);
        getData(API_URLS.DATA_UPLOADER.GET_FORM_OF_TYPES, formDataForApi)
            .then((formFieldResponse) => {
                setFormFieldsStatics(formFieldResponse.data.data);
                setFormFieldsFormData(
                    parseInitialStateFromFormFields(
                        formFieldResponse.data.data.formFields
                    )
                );
                setFormFieldsStaticsApiStatus(LOADING_STATUS.COMPLETED);
            })
            .catch(() => {
                setFormFieldsStaticsApiStatus(LOADING_STATUS.FAILED);
            });
    }, [formTypeFormData]);

    const onFormFieldsClearClick = useCallback(() => {
        setFormFieldsFormData(
            parseInitialStateFromFormFields(formFieldsStatics.formFields)
        );
    }, [formFieldsStatics]);

    const onFormFieldsPrefillClick = useCallback(() => {
        if (prefillDataApiStatus === LOADING_STATUS.LOADING) {
            return;
        }
        setPrefillDataApiStatus(LOADING_STATUS.LOADING);
        const formTypeApiData = parseFormDataForApi(formTypeFormData);
        const formFieldsApiData = parseFormDataForApi(formFieldsFormData);
        postData(formFieldsStatics.preFillUrl, formTypeApiData, {
            ...formTypeApiData,
            ...formFieldsApiData,
        })
            .then((prefillResponse) => {
                setFormFieldsFormData(
                    parseInitialStateFromFormFields(
                        formFieldsStatics.formFields,
                        prefillResponse.data.data
                    )
                );
                setPrefillDataApiStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((prefillError) => {
                setPrefillDataApiStatus(LOADING_STATUS.FAILED);
                EvToast.error(
                    "Failed",
                    prefillError.data && prefillError.data.message
                        ? prefillError.data.message
                        : "Failed to prefill data"
                );
            });
    }, [
        formFieldsFormData,
        prefillDataApiStatus,
        formFieldsStatics.preFillUrl,
        formTypeFormData,
        formFieldsStatics.formFields,
    ]);

    const onFormFieldsSubmit = useCallback(() => {
        if (formFieldsStaticsApiStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formFieldsFormData)) {
            setFormFieldsFormData(markAllFieldsUsed(formFieldsFormData));
            EvToast.warn(
                "Form values invalid",
                "Not all values in the form is valid"
            );
            return;
        }
        setSubmitFormApiStatus(LOADING_STATUS.LOADING);
        const formTypeApiData = parseFormDataForApi(formTypeFormData);
        const formFieldsApiData = parseFormDataForApi(formFieldsFormData);

        postData(formFieldsStatics.submitUrl, formTypeApiData, {
            ...formTypeApiData,
            ...formFieldsApiData,
        })
            .then((submitResponse) => {
                EvToast.success(
                    "Success",
                    submitResponse.data.message || "Data saved"
                );
                setSubmitFormApiStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((errorResponse) => {
                setSubmitFormApiStatus(LOADING_STATUS.FAILED);
                EvToast.error(
                    "FAILED",
                    errorResponse.data && errorResponse.data.message
                        ? errorResponse.data.message
                        : "Failed to save data"
                );
            });
    }, [
        formFieldsFormData,
        formFieldsStaticsApiStatus,
        formFieldsStatics.submitUrl,
        formTypeFormData,
    ]);

    if (!isCompleted(formTypeStaticsApiStatus)) {
        return <EvLoadingPage />;
    }

    return (
        <DataUploaderView
            // static data
            formTypesStatics={formTypeStatics}
            formFieldsStatics={formFieldsStatics}
            // dorm data state
            formTypeFormData={formTypeFormData}
            formFieldsFormData={formFieldsFormData}
            // set form data state
            setFormTypeFormData={setFormTypeFormDataLocal}
            setFormFieldsFormData={setFormFieldsFormData}
            // functions
            onGetFormOfTypeClick={onGetFormOfTypeClick}
            onFormFieldsSubmit={onFormFieldsSubmit}
            onFormFieldsPrefillClick={onFormFieldsPrefillClick}
            onFormFieldsClearClick={onFormFieldsClearClick}
            // api status
            formFieldsStaticsApiStatus={formFieldsStaticsApiStatus}
            prefillDataApiStatus={prefillDataApiStatus}
            submitFormApiStatus={submitFormApiStatus}
        />
    );
};

DataUploaderContainer.propTypes = {};

DataUploaderContainer.defaultProps = {};

export default DataUploaderContainer;
