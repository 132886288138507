import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { createCardListBasedOnVP } from "../../../utils/SpecificDataModelUtils";
import {
    EvButton,
    EvDropdownMenu,
    EvExpandableView,
    EvSimpleCard,
    EvText,
} from "../../../common/components";
import ClientCardsCampaignsView from "./ClientCardsCampaignsView";
import { randomNumberGenerator } from "../../../utils/CommonUtils";

import "../styles/client-cards-vendor-view.scss";
import { VENDOR_CARD_TYPE } from "../../../common/static/Enums";

const styles = {
    container: "ev__client-cards-vendor-view__container",
    vendorProgram: {
        listContainer:
            "ev__client-cards-vendor-view__vendor-program-list-container",
    },
    item: {
        card: "ev__client-cards-vendor-view__item-card",
        contentWrapper: "ev__client-cards-vendor-view__item-content-wrapper",
        headerContainer: "ev__client-cards-vendor-view__item-header-container",
        headerName: "ev__client-cards-vendor-view__item-header-name",
        headerLogo: "ev__client-cards-vendor-view__item-header-logo",
        cardsView: "ev__client-cards-vendor-view__item-cards-view",
    },
    actionView: {
        container: "ev__client-cards-vendor-view__action-view-container",
        addButton: "ev__client-cards-vendor-view__action-view-add-button",
        addMenu: "ev__client-cards-vendor-view__action-view-add-menu",
    },
};

const ClientCardsVendorView = (props) => {
    const {
        staticData,
        cardTypesStatics,
        cardList,
        vendorProgramsList,
        campaignsTypeFilterData,
        onCardClick,
        onAddCardClick,
    } = props;

    const [expandedVendorProgramId, setExpandedVendorProgramId] = useState("");

    const vendorProgramCardList = useMemo(
        () => createCardListBasedOnVP(cardList, vendorProgramsList),
        [cardList, vendorProgramsList]
    );

    const expandableViewSeed = useMemo(() => randomNumberGenerator(), []);

    const onVendorProgramClick = useCallback(
        (e, callbackValues) => {
            if (expandedVendorProgramId === callbackValues.vendorProgramId) {
                setExpandedVendorProgramId("");
            } else {
                setExpandedVendorProgramId(callbackValues.vendorProgramId);
            }
        },
        [expandedVendorProgramId]
    );

    const getVendorActionView = (currentVendorProgramData) => {
        return (
            <div className={styles.actionView.container}>
                {currentVendorProgramData.vendorType ===
                    VENDOR_CARD_TYPE.CLIENT_VENDOR && (
                    <EvDropdownMenu
                        label={staticData.addCardActionDropdown.text}
                        data={staticData.addCardActionDropdown.data}
                        buttonType="secondary"
                        buttonClassName={styles.actionView.addButton}
                        menuClassName={styles.actionView.addMenu}
                        callbackValues={currentVendorProgramData}
                        onClickHandler={onAddCardClick}
                    />
                )}
            </div>
        );
    };

    const getVendorProgramItem = ([vendorProgramId, vendorProgramItemData]) => {
        const currentVendorProgramData =
            vendorProgramItemData.vendorProgramData;
        const isExpanded = vendorProgramId === expandedVendorProgramId;
        return (
            <EvSimpleCard
                className={styles.item.card}
                noPadding
                key={vendorProgramId}
            >
                <div className={styles.item.contentWrapper}>
                    <EvButton
                        onlyChild
                        callbackValues={{ vendorProgramId }}
                        onClickHandler={onVendorProgramClick}
                    >
                        <div className={styles.item.headerContainer}>
                            <EvText
                                subHeading
                                className={styles.item.headerName}
                            >
                                {`${currentVendorProgramData.vendorName} - ${currentVendorProgramData.programName}`}
                            </EvText>
                            <img
                                alt={currentVendorProgramData.programName}
                                src={currentVendorProgramData.programLogo}
                                className={styles.item.headerLogo}
                            />
                        </div>
                    </EvButton>
                    <EvExpandableView
                        isExpanded={isExpanded}
                        updateSeed={expandableViewSeed}
                        updateDelay={500}
                        contentClassName={styles.item.cardsView}
                    >
                        <div>
                            {getVendorActionView(currentVendorProgramData)}
                            <ClientCardsCampaignsView
                                staticData={cardTypesStatics}
                                cardList={vendorProgramItemData.cardList}
                                vendorProgramsList={vendorProgramsList}
                                campaignsTypeFilterData={
                                    campaignsTypeFilterData
                                }
                                onCardClick={onCardClick}
                            />
                        </div>
                    </EvExpandableView>
                </div>
            </EvSimpleCard>
        );
    };

    const getVendorProgramList = () => (
        <div className={styles.vendorProgram.listContainer}>
            {Object.entries(vendorProgramCardList).map(getVendorProgramItem)}
        </div>
    );

    return <div className={styles.container}>{getVendorProgramList()}</div>;
};

ClientCardsVendorView.propTypes = {
    staticData: PropTypes.object,
    cardTypesStatics: PropTypes.object,
    cardList: PropTypes.array,
    vendorProgramsList: PropTypes.object,
    campaignsTypeFilterData: PropTypes.object,
    onCardClick: PropTypes.func,
    onAddCardClick: PropTypes.func,
};

ClientCardsVendorView.defaultProps = {
    staticData: {},
    cardTypesStatics: {},
    cardList: [],
    vendorProgramsList: {},
    campaignsTypeFilterData: {},
    onCardClick: () => {},
    onAddCardClick: () => {},
};

export default ClientCardsVendorView;
