const CLIENT_SELECTION_MOCK_DATA = {
    CLIENTS_DATA: {
        responseStatus: "SUCCESS",
        data: {
            listData: [
                {
                    id: "1037",
                    name: "UPS",
                    value: "1037",
                },
                {
                    id: "1056",
                    name: "PlayStation",
                    value: "1056",
                },
                {
                    id: "1004",
                    name: "THD",
                    value: "1004",
                },
                {
                    id: "2025",
                    name: "HSCS",
                    value: "2025",
                },
            ],
        },
    },
    NOTIFICATIONS_DATA: {
        responseStatus: "SUCCESS",
        data: {
            notificationList: [
                {
                    notificationId: "abcd6",
                    notificationTitle: "6Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "19:50:00",
                        timeZone: "PST",
                    },
                    clients: "2025, 1056",
                    priority: "normal",
                    status: "DRAFT",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle: "1Hurricane Safety",
                    goLiveDate: {},
                    clients: "1037, 1056",
                    priority: "high",
                    status: "SCHEDULED",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle:
                        "2Icecream Social and a really long name to break in two lines",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    clients: "2025, 1056",
                    priority: "low",
                    status: "SCHEDULED",
                },
                {
                    notificationId: "1aba5271-8ab6-4e74-ad90-d8ece19281af",
                    notificationTitle: "3Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "12:30:00",
                        timeZone: "CST",
                    },
                    clients: "2025, 1056",
                    priority: "normal",
                    status: "LIVE",
                },
                {
                    notificationId: "abcd4",
                    notificationTitle: "4Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "05:30:00",
                        timeZone: "PST",
                    },
                    clients: "2025, 1056",
                    priority: "normal",
                    status: "LIVE",
                },
                {
                    notificationId: "abcd5",
                    notificationTitle: "5Icecream Social",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "15:08:00",
                        timeZone: "CST",
                    },
                    clients: "2025, 1056",
                    priority: "normal",
                    status: "LIVE",
                },
            ],
        },
    },
};

const PROGRAM_SELECTION_MOCK_DATA = {
    PRE_FILLED_DATA: {
        responseStatus: "SUCCESS",
        data: {
            title: "adada",
            linkedProgram: {
                id: "pid_13c03060c16dba8852ea2df84e5e2fd8",
                name: "Dental Services",
                value: "pid_13c03060c16dba8852ea2df84e5e2fd8",
            },
        },
    },
    PROGRAM_SELECTION_POST_SUCCESS: {
        responseStatus: "SUCCESS",
        data: {
            id: "nid_123_1",
        },
    },
    PROGRAM_LIST_DATA: {
        responseStatus: "SUCCESS",
        data: {
            programs: [
                {
                    id: "pid_13c03060c16dba8852ea2df84e5e2fd8",
                    name: "Dental Services",
                    value: "pid_13c03060c16dba8852ea2df84e5e2fd8",
                },
                {
                    id: "pid_209b4a02192ea7c000d2fa06150f34f5",
                    name: "Second Opinion Services",
                    value: "pid_209b4a02192ea7c000d2fa06150f34f5",
                },
                {
                    id: "longName",
                    name:
                        "Some really long name to test the text wrapper feature of Notifications content program selection",
                    value: "MPC",
                },
                {
                    id: "pid_4995bf8c4dc2a61a4ab3a767172f438d",
                    name: "Health and Wellness Campaigns",
                    value: "pid_4995bf8c4dc2a61a4ab3a767172f438d",
                },
                {
                    id: "pid_bfedb9da18ad94b9b3e1ff24a1a8fef7",
                    name: "Physical Therapy/Rehabilitation",
                    value: "pid_bfedb9da18ad94b9b3e1ff24a1a8fef7",
                },
                {
                    id: "pid_df6b892d9638a1a4ce55b0478d5835e5",
                    name: "Blue Access for Members - Claims Center",
                    value: "pid_df6b892d9638a1a4ce55b0478d5835e5",
                },
                {
                    id: "pid_8196e5c56d613f305f3d0a44dab7331b",
                    name: "Onsite Biometric Screening",
                    value: "pid_8196e5c56d613f305f3d0a44dab7331b",
                },
                {
                    id: "pid_5dd60432d0d9a52e3569e16d7eda006f",
                    name: "Blue365",
                    value: "pid_5dd60432d0d9a52e3569e16d7eda006f",
                },
                {
                    id: "pid_085a97e2704ef2e6cc7c2cc1f296df48",
                    name: "Pharmacy Services",
                    value: "pid_085a97e2704ef2e6cc7c2cc1f296df48",
                },
            ],
        },
    },
};

const CONTENT_MOCK_DATA = {
    PRE_FILLED_DATA: {
        responseStatus: "SUCCESS",
        data: {
            title: "Benefits of direct deposit",
            description:
                "Enroll in direct deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
            buttonText: "Notification buttonText",
            tag: "high",
            ctaType: "SSO",
            takeActionDate: "2020-08-25",
            liveDate: "2021-08-25",
            ctaType_button_text: "Enroll Now",
            priority: "high",
            associatedTaskIDs: [
                {
                    taskId: "testTaskId",
                },
            ],
            linkedProgram: {
                id: "pid_13c03060c16dba8852ea2df84e5e2fd8",
                name: "Dental Services",
                value: "pid_13c03060c16dba8852ea2df84e5e2fd8",
            },
            audiences:
                '[{"demo-company" : "active employees in u.s. - dnow lp" , "demo-loc-home_zip_NOT_IN" : ["04936","04945"]}]',

            SSO: {
                id: "pid-sso-1",
                name: "PID SSO 1",
                value: "pid sso 1",
            },
        },
    },
    CTA_DATA: {
        responseStatus: "SUCCESS",
        data: {
            SSO: [
                {
                    id: "pid-sso-1",
                    name: "PID SSO 1",
                    value: "pid sso 1",
                    description: "PID SSO 1 Description",
                },
                {
                    id: "pid-sso-2",
                    name: "PID SSO 2",
                    value: "pid sso 2",
                    description: "PID SSO 2 Description",
                },
                {
                    id: "pid-sso-3",
                    name: "PID SSO 3",
                    value: "pid sso 3",
                    description: "PID SSO 3 Description",
                },
            ],
            INTERNAL: [
                {
                    id: "pid-internal-1",
                    name: "PID INTERNAL 1",
                    value: "pid internal 1",
                    description: "PID INTERNAL 1 Description",
                },
                {
                    id: "pid-internal-2",
                    name: "PID INTERNAL 2",
                    value: "pid internal 2",
                    description: "PID INTERNAL 2 Description",
                },
                {
                    id: "pid-internal-3",
                    name: "PID INTERNAL 3",
                    value: "pid internal 3",
                    description: "PID INTERNAL 3 Description",
                },
            ],
        },
    },
    CONTENT_POST_SUCCESS: {
        responseStatus: "SUCCESS",
        data: {
            id: "nid_123_1",
        },
    },
};

const SUMMARY_MOCK_DATA = {
    NOTIFICATIONS_USER_COUNT_DATA: {
        responseStatus: "SUCCESS",
        data: {
            count: "455",
        },
    },
    NOTIFICATION_SUMMARY_DATA: {
        responseStatus: "SUCCESS",
        data: {
            notificationCard: {
                title: "Benefits of direct deposit",
                linkedProgram: "DM",
                description:
                    "Enroll in Direct Deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
                ctaType: "HREF",
                ctaValue: "directdeposit.sso",
                buttonText: "Enroll Now",
                priority: "high",
                takeActionBy: "2020-01-15",
                taskIds: ["Id1", "Id2"],
            },
            notificationPopulation: {
                audiences:
                    "[{'demo-company' : 'active employees in u.s. - dnow lp' , 'demo-loc-home_zip_NOT_IN' : ['04936','04945']}]",
            },
            scheduleNotification: {
                date: "2020-01-31",
            },
        },
    },
};

const COMMON_RESPONSE = {
    successResponse: {
        responseStatus: "SUCCESS",
        data: {},
    },

    failedResponse: {
        responseStatus: "FAILED",
        data: {},
    },

    showErrorResponse: {
        responseStatus: "SHOW_ERROR",
        data: {},
        message: "Some failed save message",
    },
};

export {
    CLIENT_SELECTION_MOCK_DATA,
    PROGRAM_SELECTION_MOCK_DATA,
    CONTENT_MOCK_DATA,
    SUMMARY_MOCK_DATA,
    COMMON_RESPONSE,
};
