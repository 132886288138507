import GamedayScheduleActionTypes from "./GamedayScheduleActionTypes";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData } from "../service/GamedayScheduleService";

const GamedayScheduleActions = {
    setControls: (payload) => ({
        type: GamedayScheduleActionTypes.SET_CONTROLS,
        payload,
    }),

    setStaticData: (payload) => ({
        type: GamedayScheduleActionTypes.SET_STATIC_DATA,
        payload,
    }),

    setStaticDataApiStatus: (payload) => ({
        type: GamedayScheduleActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            !isNullOrEmpty(
                getState().NotificationsReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().NotificationsReducer.staticData[payload.templateId]
            ); // static data already loaded
        }
        dispatch(
            GamedayScheduleActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        // return internal promise from then
        return getData(payload.url).then((response) => {
            dispatch(
                GamedayScheduleActions.setStaticData({
                    templateId: payload.templateId,
                    staticData: response.data.data,
                })
            );
            dispatch(
                GamedayScheduleActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    setDynamicDataApiStatus: (payload) => ({
        type: GamedayScheduleActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: GamedayScheduleActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    setLandingData: (payload) => ({
        type: GamedayScheduleActionTypes.SET_LANDING_DATA,
        payload,
    }),

    resetAllData: (payload) => ({
        type: GamedayScheduleActionTypes.RESET_ALL_DATA,
        payload,
    }),
};

export default GamedayScheduleActions;
