import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { EvButton, EvSimpleCard, EvText } from "../index";

import "../../styles/ev-cards/ev-client-card.scss";

const styles = {
    container: "ev__ev-client-card__container",
    header: {
        container: "ev__ev-client-card__header-container",
        headerText: "ev__ev-client-card__header-header-text",
        button: "ev__ev-client-card__header-button",
    },
    content: {
        container: "ev__ev-client-card__content-container",
        imageContainer: "ev__ev-client-card__content-image-container",
        clientImage: "ev__ev-client-card__content-client-image",
    },
    details: {
        container: "ev__ev-client-card__details-container",
        textItem: "ev__ev-client-card__details-text-item",
    },
};

const EvClientCard = (props) => {
    const { header, ctaText, values, content, onClickHandler } = props;

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText subHeading className={styles.header.headerText}>
                {header}
            </EvText>
            {ctaText && (
                <EvButton
                    primary
                    className={styles.header.button}
                    onClickHandler={onClickHandler}
                >
                    {ctaText}
                </EvButton>
            )}
        </div>
    );

    const getImageView = () => {
        const imageUrl = stringTemplate(content.imageUrl, values);
        return (
            <div className={styles.content.imageContainer}>
                {imageUrl && (
                    <img
                        alt="client-logo"
                        className={styles.content.clientImage}
                        src={imageUrl}
                    />
                )}
            </div>
        );
    };

    const getDetailsView = () => (
        <div className={styles.details.container}>
            <EvText className={styles.details.textItem}>
                {stringTemplate(content.clientId, values)}
            </EvText>
            <EvText headingSecondary className={styles.details.textItem}>
                {stringTemplate(content.clientName, values)}
            </EvText>
            <EvText className={styles.details.textItem}>
                {stringTemplate(content.url, values)}
            </EvText>
        </div>
    );

    const getContent = () => (
        <div className={styles.content.container}>
            {getImageView()}
            {getDetailsView()}
        </div>
    );

    return (
        <EvSimpleCard className={styles.container}>
            {getHeaderView()}
            {getContent()}
        </EvSimpleCard>
    );
};

EvClientCard.propTypes = {
    header: PropTypes.string,
    ctaText: PropTypes.string,
    values: PropTypes.object,
    content: PropTypes.object,
    onClickHandler: PropTypes.func,
};

EvClientCard.defaultProps = {
    header: "",
    ctaText: "",
    values: {},
    content: {
        imageUrl: "{clientLogo}",
        clientId: "Client ID: {clientCode}",
        clientName: "{clientName}",
        url: "{clientUrl}",
    },
    onClickHandler: () => {},
};

export default EvClientCard;
