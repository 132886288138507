import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    EvButton,
    EvDivider,
    EvExpandableView,
    EvText,
} from "../../../common/components";
import {
    createCardListBasedOnType,
    sortObjectArrayBasedOnKey,
} from "../../../utils/SpecificDataModelUtils";
import { EvCardResolver } from "../../../common/components/ev-cards";
import {
    getFirstIdFromSelectedFormData,
    getFormValuesBasedOnType,
} from "../../../utils/FormUtils";
import { normalizer } from "../../../utils/Normalizer";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import "../styles/client-cards-campaigns-view.scss";

const styles = {
    container: "ev__client-cards-campaigns-view__container",
    type: {
        container: "ev__client-cards-campaigns-view__type-container",
        cardsContainer: "ev__client-cards-campaigns-view__type-cards-container",
        cardWrapper: "ev__client-cards-campaigns-view__type-card-wrapper",
        cardWrapperInvisible:
            "ev__client-cards-campaigns-view__type-card-wrapper-invisible",
        item: "ev__client-cards-campaigns-view__type-item",
    },
    infoMessage: "ev__client-cards-campaigns-view__info-message",
};

const ClientCardsCampaignsView = (props) => {
    const {
        staticData,
        cardList,
        vendorProgramsList,
        campaignsTypeFilterData,
        onCardClick,
    } = props;

    const selectedCampaignsType = useMemo(() => {
        const filteredTypes = getFormValuesBasedOnType(campaignsTypeFilterData);
        return normalizer(filteredTypes);
    }, [campaignsTypeFilterData]);

    const cardListByType = useMemo(() => {
        const parsedCardList = createCardListBasedOnType(
            cardList,
            vendorProgramsList
        );
        const sortedCardListByType = {};
        Object.entries(parsedCardList).forEach(([key, value]) => {
            sortedCardListByType[key] = sortObjectArrayBasedOnKey(
                value,
                "cardPosition"
            );
        });
        return sortedCardListByType;
    }, [cardList, vendorProgramsList]);

    const isFiltersSelected = useMemo(() => {
        const firstSelectedFilterId = getFirstIdFromSelectedFormData(
            campaignsTypeFilterData
        );
        return !isNullOrEmpty(firstSelectedFilterId);
    }, [campaignsTypeFilterData]);

    const getCardItemView = (cardData) => {
        const cardTypeData = staticData.cardTypes[cardData.cardType];
        const customCardWrapperClass = classNames(styles.type.cardWrapper, {
            [styles.type.cardWrapperInvisible]: cardData.visibility === false,
        });
        return (
            <EvButton
                key={cardData.id}
                onlyChild
                onClickHandler={onCardClick}
                callbackValues={cardData}
                className={customCardWrapperClass}
            >
                <EvCardResolver
                    cardData={cardData}
                    cardType={cardTypeData.cardType}
                    cardTypeData={cardTypeData}
                    className={styles.type.item}
                />
            </EvButton>
        );
    };

    const getCardsForSegment = (cardType) => {
        if (cardListByType[cardType] && cardListByType[cardType].length > 0) {
            return cardListByType[cardType].map(getCardItemView);
        }
        return <EvText>{staticData.noCardText}</EvText>;
    };

    const getTypeView = ([id, typeData]) => {
        const isVisible = !isNullOrEmpty(selectedCampaignsType[id]);
        return (
            <EvExpandableView key={id} isExpanded={isVisible}>
                <div className={styles.type.container}>
                    <EvText subHeading>{typeData.header}</EvText>
                    <div className={styles.type.cardsContainer}>
                        {getCardsForSegment(id)}
                    </div>
                </div>
                <EvDivider marginVertical="2rem" />
            </EvExpandableView>
        );
    };

    const getInfoView = (infoMessage) => (
        <EvText italics className={styles.infoMessage}>
            {infoMessage}
        </EvText>
    );

    return (
        <div className={styles.container}>
            {!isFiltersSelected && getInfoView(staticData.noFiltersSelected)}
            {Object.entries(staticData.cardTypes).map(getTypeView)}
        </div>
    );
};

ClientCardsCampaignsView.propTypes = {
    staticData: PropTypes.object,
    cardList: PropTypes.array,
    vendorProgramsList: PropTypes.object,
    campaignsTypeFilterData: PropTypes.object,
    onCardClick: PropTypes.func,
};

ClientCardsCampaignsView.defaultProps = {
    staticData: {},
    cardList: [],
    vendorProgramsList: {},
    campaignsTypeFilterData: {},
    onCardClick: () => {},
};

export default ClientCardsCampaignsView;
