import React, {
    memo,
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvText, EvButton } from "..";
import FormValidation from "../../../utils/FormValidations";
import { getFirstIdFromSelectedFormData } from "../../../utils/FormUtils";

import "../../styles/form-components/ev-dropdown-minimal.scss";

/**
 * Dropdown to be used in UserMapping Form
 */

const DATA_SOURCE_TYPE = {
    FROM_EXTERNAL_URL: "FROM_EXTERNAL_URL", // not yet implemented
    FROM_PROPS: "FROM_PROPS",
    INTERNAL: "INTERNAL",
};

const EvDropDownMinimal = (props) => {
    const styles = {
        container: "ev__ev-dropdown-minimal__container",
        button: {
            container: "ev__ev-dropdown-minimal__button-container",
            label: "ev__ev-dropdown-minimal__button-label",
        },
        dropdown: {
            container: "ev__ev-dropdown-minimal__dropdown-container",
            containerBottomLeft:
                "ev__ev-dropdown-minimal__dropdown-container-bottom-left",
            containerBottomRight:
                "ev__ev-dropdown-minimal__dropdown-container-bottom-right",
            containerFullSize:
                "ev__ev-dropdown-minimal__dropdown-container-full-size",
        },
        dropdownElement: {
            container: "ev__ev-dropdown-minimal__dropdown-element-container",
            text: "ev__ev-dropdown-minimal__dropdown-element-text",
        },
    };

    const {
        onClickHandler,
        formData,
        formItem,
        dataOptions,
        keyName,
        data,
        placeholder,
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const containerRef = useRef(null);

    const selectedId = useMemo(() => getFirstIdFromSelectedFormData(formData), [
        formData,
    ]);

    const onWindowMouseClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onWindowMouseClick);
        return () => {
            document.removeEventListener("mousedown", onWindowMouseClick);
        };
    }, []);

    const onElementClick = useCallback(
        (e, callbackOptions) => {
            setIsOpen(false);
            const inputFieldObject = {
                [callbackOptions.id]: {
                    key: callbackOptions.id,
                    value: callbackOptions.value,
                    name: callbackOptions.name,
                },
                errorObject: {}, // will be overwritten later
                formItemType: formItem.type,
            };
            onClickHandler(keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [formItem, keyName, onClickHandler, formData.errorObject]
    );

    const onDropdownButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const getDropDownButton = () => (
        <EvButton onlyChild onClickHandler={onDropdownButtonClick}>
            <div className={styles.button.container}>
                <EvText default>
                    {formData[selectedId] && formData[selectedId].name
                        ? formData[selectedId].name
                        : placeholder}
                </EvText>
            </div>
        </EvButton>
    );

    const getDropdownElement = (elementData) => (
        <EvButton
            key={elementData.id}
            onlyChild
            onClickHandler={onElementClick}
            callbackValues={{
                id: elementData.id,
                value: elementData.value,
                name: elementData.name,
            }}
            buttonInlineStyle={{ whiteSpace: "normal", textAlign: "left" }}
        >
            <div className={styles.dropdownElement.container}>
                <EvText default className={styles.dropdownElement.text}>
                    {elementData.name}
                </EvText>
            </div>
        </EvButton>
    );

    const getDropdownView = (dropdownData) => {
        const customClass = classNames(
            styles.dropdown.container,
            styles.dropdown.containerFullSize
        );
        const dataSource =
            formItem.options && formItem.options.lazyType
                ? formItem.options.lazyType
                : DATA_SOURCE_TYPE.INTERNAL;
        switch (dataSource) {
            case DATA_SOURCE_TYPE.FROM_PROPS:
                return (
                    <div className={customClass}>
                        {dataOptions.data.map(getDropdownElement)}
                    </div>
                );

            case DATA_SOURCE_TYPE.INTERNAL:
            default:
                return (
                    <div className={customClass}>
                        {dropdownData.map(getDropdownElement)}
                    </div>
                );
        }
        // return (
        //   <div className={customClass}>{dropdownData.map(getDropdownElement)}</div>
        // );
    };

    return (
        <div className={styles.container} ref={containerRef}>
            {getDropDownButton()}
            {isOpen && getDropdownView(data)}
        </div>
    );
};

EvDropDownMinimal.propTypes = {
    /** dropdown list data, list of cta */
    data: PropTypes.array,
    formData: PropTypes.object,
    formItem: PropTypes.object,
    dataOptions: PropTypes.object,

    keyName: PropTypes.string,
    placeholder: PropTypes.string,

    /** common onclick handler, receives the full cta object of the element */
    onClickHandler: PropTypes.func,
};

EvDropDownMinimal.defaultProps = {
    data: [],
    formData: {},
    formItem: {},
    dataOptions: {},

    keyName: "",
    placeholder: "",

    onClickHandler: () => {},
};

export default memo(EvDropDownMinimal);
