import React, { Component } from "react";
import { createPortal } from "react-dom";

import { EvLoader, EvText, EvIcon, EvButton } from ".";
import { COLORS } from "../static/VmsStatics";
import { isNullOrEmpty } from "../../utils/CommonUtils";

import "../styles/ev-fullscreen-overlay.scss";

const overlayRef = React.createRef();

const styles = {
    container: "ev__ev-fullscreen-overlay__container",
    button: "ev__ev-fullscreen-overlay__button",
};

class EvFullScreenOverlay extends Component {
    modalContainerRef = React.createRef();

    constructor(props) {
        super(props);
        EvFullScreenOverlay.singletonRef = this;
    }

    state = {
        isVisible: false,
        isExiting: false,
        showLoading: true,
    };

    static setData = (header, description, options) => {
        EvFullScreenOverlay.singletonRef.setData(header, description, options);
        return EvFullScreenOverlay.singletonRef;
    };

    static show() {
        EvFullScreenOverlay.singletonRef.show();
        return EvFullScreenOverlay.singletonRef;
    }

    static hide() {
        EvFullScreenOverlay.singletonRef.hide();
        return EvFullScreenOverlay.singletonRef;
    }

    closeModalWithAnimation = () => {
        this.setState({
            isExiting: true,
        });
        this.showHideModal(false);
    };

    show = () => {
        this.showHideModal(true);
    };

    hide = () => {
        this.closeModalWithAnimation();
    };

    setData = (header = "", description = "", options = {}) => {
        const {
            showLoading,
            subHeader,
            iconOptions,
            onOverlayClick,
            buttonText,
            buttonAction,
        } = options;
        this.setState({
            header,
            description,
            showLoading,
            subHeader,
            iconOptions,
            onOverlayClick,
            buttonText,
            buttonAction,
        });
    };

    onOverlayClick = () => {
        if (this.state.onOverlayClick) {
            this.state.onOverlayClick();
        }
        // this.closeModalWithAnimation();
    };

    getHeader = () => (
        <EvText default color={COLORS.WHITE_GHOST}>
            {this.state.header}
        </EvText>
    );

    getSubHeader = () => (
        <EvText default color={COLORS.WHITE_GHOST}>
            {this.state.subHeader}
        </EvText>
    );

    getDescription = () => (
        <EvText default color={COLORS.WHITE_GHOST}>
            {this.state.description}
        </EvText>
    );

    getButtonWithAction = () => (
        <EvButton
            className={styles.button}
            onClickHandler={this.state.buttonAction}
            primaryFilled
        >
            {this.state.buttonText}
        </EvButton>
    );

    getIcon = () => (
        <EvIcon
            size={8}
            iconName={this.state.iconOptions.iconName}
            fillColor={this.state.iconOptions.fillColor || COLORS.WHITE}
        />
    );

    showLoader = () => <EvLoader size={8} fillColor={COLORS.WHITE_GHOST} />;

    getContent = () => (
        <div className={styles.container} onClick={this.onOverlayClick}>
            {this.state.showLoading && this.showLoader()}
            {!isNullOrEmpty(this.state.iconOptions) &&
                this.state.iconOptions.iconName &&
                this.getIcon()}
            {this.state.header && this.getHeader()}
            {this.state.subHeader && this.getSubHeader()}
            {this.state.description && this.getDescription()}
            {this.state.buttonText && this.getButtonWithAction()}
        </div>
    );

    getModal = () =>
        this.state.isVisible ? (
            <div ref={overlayRef}>{this.getContent()}</div>
        ) : null;

    showHideModal = (isVisible) => {
        this.setState({
            isVisible,
        });
    };

    render() {
        return createPortal(
            this.getModal(),
            document.getElementById("portal-fs-overlay")
        );
    }
}

export default EvFullScreenOverlay;
