import { RESPONSE_STATUS } from "../common/static/Enums";
import api from "../services/api";
import API_URLS from "../services/apiUrls";
import store from "../store/Store";
import { isNullOrEmpty } from "./CommonUtils";

/* eslint-disable no-console */
/* global USE_MOCK_DATA */

const LOG_TYPE = {
    INFO: "info",
    ERROR: "error",
    WARN: "warn",
    HISTORY: "history",
};

const defaultValues = {
    category: "command-center",
};

const LOG_CATEGORY_TYPES = {
    CC: "cc",
};

const LOG_ACTION_TYPE = {
    CLICK: "click",
    LOAD: "LOAD",
    PARSE: "PARSE",
    DELETE: "DELETE",
    SUBMIT: "SUBMIT",
    INTERNAL: "INTERNAL",
};

const LOG_LOCAL_STORAGE_KEY = "evive_cc_log_queue";
let idleCallbackId = null;

const getQueueFromLocalStorage = () =>
    window.localStorage.getItem(LOG_LOCAL_STORAGE_KEY)
        ? JSON.parse(window.localStorage.getItem(LOG_LOCAL_STORAGE_KEY))
        : [];

const setQueueInLocalStorage = (queue) => {
    localStorage.setItem(LOG_LOCAL_STORAGE_KEY, JSON.stringify(queue));
};

class EvLogger {
    sendEvents = () => {
        const queue = getQueueFromLocalStorage();
        setQueueInLocalStorage([]); // clear immedeatly. on fail, we add it later on
        if (!isNullOrEmpty(queue)) {
            api.post(API_URLS.LOGGER_SERVICE, queue)
                .then((response) => {
                    if (
                        response.data.responseStatus === RESPONSE_STATUS.SUCCESS
                    ) {
                        // logged succesfully
                        idleCallbackId = null;
                    } else {
                        throw new Error("Server did not respond success");
                    }
                })
                .catch(() => {
                    // add logs back to LS
                    const currentQueue = getQueueFromLocalStorage();
                    const newQueue = [...queue, ...currentQueue];
                    setQueueInLocalStorage(newQueue);
                    idleCallbackId = null;
                });
        }
    };

    queueEvents = () => {
        if (!idleCallbackId) {
            // if callback Id alreadt there, no need to request again
            if (window.requestIdleCallback) {
                // if callback available
                idleCallbackId = window.requestIdleCallback(() =>
                    this.sendEvents()
                );
            } else {
                // else send immediately
                this.sendEvents();
            }
        }
    };

    addEvent = (type, event, category, action) => {
        // This is a non-secure UUID
        // TODO: change to non-secure UUID
        let hashUserId = "";
        try {
            hashUserId =
                store.getState().AppContainerReducer.userData.hashUserId || "";
        } catch (e) {
            // console.log("Failed hashUserId", e);
            // log seperate error, for UUID not found
        }
        if (USE_MOCK_DATA) {
            console.log(
                "Mocking logger service",
                JSON.stringify({
                    category,
                    type,
                    event,
                    action,
                    hashUserId,
                })
            );
            return;
        }
        const queue = getQueueFromLocalStorage();
        const timeOfOrigin = Date.now();
        queue.push({
            category,
            type,
            event,
            action,
            timeOfOrigin,
            hashUserId,
        });
        localStorage.setItem(LOG_LOCAL_STORAGE_KEY, JSON.stringify(queue));
        this.queueEvents();
    };

    static log(event = "", category = defaultValues.category, action = "") {
        EvLogger.evLoggerRef.addEvent(LOG_TYPE.INFO, event, category, action);
    }

    static error(event = "", category = defaultValues.category, action = "") {
        EvLogger.evLoggerRef.addEvent(LOG_TYPE.ERROR, event, category, action);
    }

    static errorWithObject(errorObj, fileName, logType = LOG_TYPE.ERROR) {
        // if its EvError type then it will have handled
        if (errorObj.handled !== undefined) {
            // if not handled only then log it
            if (!errorObj.handled) {
                EvLogger.evLoggerRef.addEvent(
                    logType,
                    `${errorObj.fileName}: ${errorObj.message}`,
                    errorObj.category,
                    errorObj.action
                );
                const handledErrorObject = { ...errorObj };
                handledErrorObject.handled = true;
                return handledErrorObject;
            }
        } else {
            // if it doesnt have handled then its not EvError
            // internal errors
            EvLogger.evLoggerRef.addEvent(
                LOG_TYPE.ERROR,
                `${fileName}: ${errorObj.message}`,
                LOG_CATEGORY_TYPES.CC,
                LOG_ACTION_TYPE.INTERNAL
            );
        }
        return errorObj;
    }

    static warn(event = "", category = defaultValues.category, action = "") {
        EvLogger.evLoggerRef.addEvent(LOG_TYPE.WARN, event, category, action);
    }
}

EvLogger.evLoggerRef = new EvLogger();

export default EvLogger;
export { LOG_CATEGORY_TYPES, LOG_ACTION_TYPE, LOG_TYPE };
