import { goBack } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import CommonActions from "../../../common/redux/CommonActions";
import { getData, postData } from "../../../common/service/CommonService";
import { LOADING_STATUS, VENDOR_CARD_TYPE } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    EVIVE_360_APP_ID,
    EVIVE_360_STATICS,
    EVIVE_360_TEMPLATES,
} from "../statics/Evive360Statics";
import Evive360View from "./Evive360View";

const Evive360Container = (props) => {
    const {
        staticData,
        staticDataClientProgram,
        location,
        getStaticData,
        goBackAction,
    } = props;

    const [formDataLoadingStatus, setFormDataLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.EVIVE_360.STATIC,
                    templateId: EVIVE_360_TEMPLATES.STATIC.id,
                }),
                getStaticData({
                    url: API_URLS.EVIVE_360.STATIC_CLIENT_PROGRAM,
                    templateId: EVIVE_360_TEMPLATES.STATIC_CLIENT_PROGRAM.id,
                }),
                getData(API_URLS.EVIVE_360.PREFILL_DATA, {
                    programId: vendorCardData.programId,
                    vendorId: vendorCardData.vendorId,
                    featureId: vendorCardData.cardId,
                    clientCode,
                }),
            ]),
        [clientCode, getStaticData, vendorCardData]
    );

    useEffect(() => {
        if (isCompletedOrLoading(formDataLoadingStatus)) {
            return;
        }
        setFormDataLoadingStatus(LOADING_STATUS.LOADING);
        getAllData()
            .then(
                ([
                    staticDataResponse,
                    staticDataClientProgramResponse,
                    prefillDataResponse,
                ]) => {
                    const currentStaticData =
                        vendorCardData.vendorType ===
                        VENDOR_CARD_TYPE.CLIENT_VENDOR
                            ? staticDataClientProgramResponse
                            : staticDataResponse;
                    const initialFormData = parseInitialStateFromFormFields(
                        currentStaticData.formFields,
                        prefillDataResponse.data.data
                    );
                    setFormData(initialFormData);
                    setFormDataLoadingStatus(LOADING_STATUS.COMPLETED);
                }
            )
            .catch((e) => {
                setFormDataLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "Evive360Container getData");
            });
    }, [getAllData, formDataLoadingStatus, vendorCardData.vendorType]);

    const onFormSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.error("", EVIVE_360_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.EVIVE_360.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
            }
        )
            .then((submitResponse) => {
                EvToast.success("Updated", "Evive 360 Card updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                goBackAction();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "Evive360Container onFormSubmit");
            });
    }, [clientCode, formData, submitStatus, vendorCardData, goBackAction]);

    if (!isCompleted(formDataLoadingStatus)) {
        return <EvLoadingPage animatedFadeIn />;
    }

    return (
        <Evive360View
            clientCode={clientCode}
            staticData={
                vendorCardData.vendorType === VENDOR_CARD_TYPE.CLIENT_VENDOR
                    ? staticDataClientProgram
                    : staticData
            }
            formData={formData}
            setFormData={setFormData}
            onFormSubmit={onFormSubmit}
            vendorCardData={vendorCardData}
            submitStatus={submitStatus}
        />
    );
};

Evive360Container.propTypes = {
    staticData: PropTypes.object,
    staticDataClientProgram: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    goBackAction: PropTypes.func,
};

Evive360Container.defaultProps = {
    staticData: {},
    staticDataClientProgram: {},
    location: {},

    getStaticData: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.CommonReducer[EVIVE_360_APP_ID].staticData[
            EVIVE_360_TEMPLATES.STATIC.id
        ],
    staticDataClientProgram:
        state.CommonReducer[EVIVE_360_APP_ID].staticData[
            EVIVE_360_TEMPLATES.STATIC_CLIENT_PROGRAM.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: EVIVE_360_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Evive360Container);
