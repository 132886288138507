import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import API_URLS from "../../../services/apiUrls";
import { getData, postData } from "../service/enterpriseNotificationsService";
import { ENTERPRISE_NOTIFICATIONS_PAGES } from "../static/enterpriseNotificationsStatics";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { EvFormRenderer } from "../../../common/components/form-components";
import {
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvToast,
} from "../../../common/components";
import { isCompleted } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import EvLogger from "../../../utils/EvLogger";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import EnterpriseNotificationsActions from "../redux/EnterpriseNotificationsActions";
import EvPills from "../../../common/components/form-components/EvPills";

import staticData from "../static/json/program-selection-form.json";

import "../styles/enterprise-notifications-program-selection.scss";

const styles = {
    container: "ev__enterprise-notifications-program-selection__container",
    pill: {
        container:
            "ev__enterprise-notifications-program-selection__pill-container",
        item: "ev__enterprise-notifications-program-selection__pill-item",
    },
    formContainer:
        "ev__enterprise-notifications-program-selection__form-container",
    actionContainer:
        "ev__enterprise-notifications-program-selection__action-container",
};

const EnterpriseNotificationsProgramSelection = (props) => {
    const { clientCodes, controls, setControls } = props;

    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCodesStringValue = useMemo(() => {
        return clientCodes.join(",");
    }, [clientCodes]);

    useEffect(() => {
        setLoadingStatus(LOADING_STATUS.NOT_YET_STARTED);
        getData(
            API_URLS.ENTERPRISE_NOTIFICATIONS.PROGRAM_SELECTION.PRE_FILLED_DATA,
            {
                clientCodes: clientCodesStringValue,
                notificationId: controls.currentNotificationId || "",
            }
        )
            .then((preFillResponse) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticData.formFields,
                    preFillResponse.data.data
                );
                setFormData(initialFormData);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
            });
    }, [clientCodes, clientCodesStringValue, controls.currentNotificationId]);

    const onSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }

        const isFormDataValid = isFullFormValid(formData);
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        const notificationId = controls.currentNotificationId || "";
        postData(
            API_URLS.ENTERPRISE_NOTIFICATIONS.PROGRAM_SELECTION.POST_DATA,
            { clientCodes: clientCodesStringValue, notificationId },
            apiData
        )
            .then((submitResponse) => {
                EvToast.success(
                    "Saved",
                    "enterprise notification program linked",
                    {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    }
                );
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                setControls({
                    selectedPageId: ENTERPRISE_NOTIFICATIONS_PAGES.CONTENT.id,
                    currentNotificationId: submitResponse.data.data.id || "",
                });
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "EnterpriseNotificationProgramSelection onFormSubmitClick"
                );
            });
    }, [
        submitStatus,
        formData,
        controls.currentNotificationId,
        clientCodesStringValue,
        setControls,
    ]);

    const getSelectedClientsView = () => {
        return (
            <div className={styles.pill.container}>
                <EvHeaderView header="Selected Clients" />
                {clientCodes.map((client) => (
                    <EvPills
                        buttonClassName={styles.pill.item}
                        key={client}
                        id={client}
                        value={client}
                    />
                ))}
            </div>
        );
    };

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionContainer}
            buttonText={staticData.nextCta.text}
            onSubmitClick={onSubmit}
            loadingStatus={submitStatus}
            addLabelSpacing
        />
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formData={formData}
                formFields={staticData.formFields}
                setFormDataState={setFormData}
                qParams={{
                    clientCodes: clientCodesStringValue,
                }}
            />
            {getActionView()}
        </div>
    );

    if (!isCompleted(loadingStatus)) {
        return <EvLoadingPage />;
    }
    return (
        <div className={styles.container}>
            {getSelectedClientsView()}
            {getFormView()}
        </div>
    );
};

EnterpriseNotificationsProgramSelection.propTypes = {
    clientCodes: PropTypes.array,
    controls: PropTypes.object,
    setControls: PropTypes.func,
};

EnterpriseNotificationsProgramSelection.defaultProps = {
    clientCodes: [],
    controls: {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EnterpriseNotificationsReducer.controls,
    clientCodes: state.EnterpriseNotificationsReducer.selectedClientCodes,
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(EnterpriseNotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseNotificationsProgramSelection);
