import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvSimpleCard,
    EvText,
    EvButton,
    EvIcon,
    EvToast,
    EvSimpleModal,
    EvLoader,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { EvMiniFormRenderer } from "../../../common/components/form-components";
import { isObjectKeysNullOrEmpty } from "../../../utils/CommonUtils";
import {
    parseFormDataForNames,
    parseFormDataForApi,
    isFullFormValid,
    getAllErrorMessageFromFormData,
} from "../../../utils/FormUtils";
import {
    COLORS,
    USER_MAPPING_STATICS,
} from "../../../common/static/VmsStatics";
import API_URLS from "../../../services/apiUrls";
import EvLogger from "../../../utils/EvLogger";

import "../styles/user-mapping-tag-card.scss";
import {
    deleteData,
    postData,
} from "../../vendor-programs/service/VendorProgramsService";

const UserMappingTagCard = (props) => {
    const styles = {
        mainContainer: "ev__user-mapping-tag-card__main-container",
        customCard: "ev__user-mapping-tag-card__custom-card",
        container: "ev__user-mapping-tag-card__container",
        headerWrapper: "ev__user-mapping-tag-card__header-wrapper",
        buttonsContainer: "ev__user-mapping-tag-card__buttons-container",
        buttonItem: "ev__user-mapping-tag-card__button-item",
        content: "ev__user-mapping-tag-card__content",
        collapsedContent: "ev__user-mapping-tag-card__collapsed-content",
        collapsedContentButton:
            "ev__user-mapping-tag-card__collapsed-content-button",
        errorInfoVIew: "ev__user-mapping-tag-card__error-info-view",
        loaderIcon: "ev__user-mapping-tag-card__loader-icon",
    };

    const {
        tagId,
        isEditable,
        isDefaultEditing,
        header,
        positiveButtonText,
        negativeButtonText,
        formItems,
        formData,
        collapsedTemplate,
        clientCode,

        onTagFormDataChange,
        onSubmitRuleSuccess,
        onRemoveRuleSuccess,
        callbackOptions,
    } = props;

    // in unlinked tags view, isDefaultEditing will be true, so card will open in editing mode by default
    // isDefaultEditing will be passed in the first render only, so no need to watch for changes
    const [isEditing, setIsEditing] = useState(isDefaultEditing);
    const [hasChanged, setHasChanged] = useState(false);
    const [errorMessageValues, setErrorMessageValues] = useState([]);
    const [showLoading, setShowLoading] = useState(false); // used for both set and removing rule

    const collapsedStringValue = useMemo(() => {
        const parsedData = parseFormDataForNames(formData);
        const isFormDataEmpty = isObjectKeysNullOrEmpty(parsedData);
        // if form items are empty, then dont show any string
        return isFormDataEmpty
            ? ""
            : stringTemplate(collapsedTemplate, parsedData);
    }, [formData, collapsedTemplate]);

    const onFormDataChange = useCallback(
        (key, formDataObject) => {
            // clear error values
            setErrorMessageValues([]);

            onTagFormDataChange(tagId, key, formDataObject);
            setHasChanged(true);
        },
        [onTagFormDataChange, setHasChanged, setErrorMessageValues, tagId]
    );

    const onEditClick = useCallback(() => {
        setIsEditing(isEditable && true);
    }, [setIsEditing, isEditable]);

    const onRemoveTagConfirm = useCallback(() => {
        setShowLoading(true);
        deleteData(API_URLS.LINKED_UNLINKED_TAGS.DELETE_TAG_RULE, {
            clientCode,
            id: tagId,
        })
            .then((response) => {
                EvToast.success("Removed", "Tag moved to Unlinked Tags", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                // clear changed warn message
                setHasChanged(false);
                // remove from array
                onRemoveRuleSuccess(tagId);
                setShowLoading(false);
            })
            .catch((e) => {
                setShowLoading(false);
                EvToast.error("Sorry", "Cant remove tag rule");
                EvLogger.errorWithObject(
                    e,
                    "UserMappingTagCard onRemoveTagConfirm"
                );
            });
    }, [onRemoveRuleSuccess, clientCode, tagId]);

    const onRemoveRuleClick = useCallback(() => {
        if (showLoading) {
            return;
        }
        // clear error values
        setErrorMessageValues([]);
        EvSimpleModal.setData({
            header: "Confirm",
            description: "Are you sure you want to remove this tag?",
            positiveButtonText: "Yes, Remove Tag",
            negativeButtonText: "Cancel",
            onPositiveAction: onRemoveTagConfirm,
        }).show();
    }, [setErrorMessageValues, onRemoveTagConfirm, showLoading]);

    const onSubmitRuleConfirm = useCallback(() => {
        const parsedData = parseFormDataForApi(formData);
        setShowLoading(true);
        postData(
            API_URLS.LINKED_UNLINKED_TAGS.POST_TAG_RULE,
            { clientCode },
            { ...parsedData, clientCode, tagId }
        )
            .then((response) => {
                EvToast.success("Saved", "Tag moved to Linked Tags", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                // clear changed warn message
                setHasChanged(false);
                // remove from array
                onSubmitRuleSuccess(tagId);
                // set isEditing false
                setIsEditing(false);
                setShowLoading(false);
            })
            .catch((e) => {
                EvToast.error("Sorry", "Cant save tag rule");
                setShowLoading(false);
                EvLogger.errorWithObject(
                    e,
                    "UserMappingTagCard onSubmitRuleConfirm"
                );
            });
    }, [
        formData,
        setHasChanged,
        clientCode,
        setIsEditing,
        onSubmitRuleSuccess,
        tagId,
    ]);

    const onSubmitRuleClick = useCallback(() => {
        // clear error values
        setErrorMessageValues([]);
        if (showLoading) {
            return;
        }

        const isFormValid = isFullFormValid(formData);
        if (!isFormValid) {
            setErrorMessageValues(getAllErrorMessageFromFormData(formData));
            return;
        }
        EvSimpleModal.setData({
            header: "Confirm",
            description: "Are you sure you want to link this tag?",
            positiveButtonText: "Yes, Link it",
            negativeButtonText: "Cancel",
            onPositiveAction: onSubmitRuleConfirm,
        }).show();
    }, [onSubmitRuleConfirm, formData, setErrorMessageValues, showLoading]);

    const getHeader = () => (
        <div className={styles.headerWrapper}>
            <EvText defaultBold>{header}</EvText>
        </div>
    );

    const getButtons = () => (
        <div className={styles.buttonsContainer}>
            {positiveButtonText && (
                <EvButton
                    primaryFilledDisabled={showLoading}
                    className={styles.buttonItem}
                    primaryFilled={!showLoading}
                    onClickHandler={onSubmitRuleClick}
                    callbackValues={callbackOptions}
                >
                    {positiveButtonText}
                </EvButton>
            )}
            {negativeButtonText && (
                <EvButton
                    primaryFilledDisabled={showLoading}
                    className={styles.buttonItem}
                    primary={!showLoading}
                    callbackValues={callbackOptions}
                    onClickHandler={onRemoveRuleClick}
                >
                    {negativeButtonText}
                </EvButton>
            )}
            {showLoading && <EvLoader className={styles.loaderIcon} size={3} />}
        </div>
    );

    const getCollapsedView = () => (
        <div className={styles.collapsedContent}>
            <EvButton
                onlyChild
                className={styles.collapsedContentButton}
                onClickHandler={onEditClick}
            >
                <EvText>{collapsedStringValue}</EvText>
                {isEditable && (
                    <EvIcon
                        iconName="EDIT"
                        size={3}
                        fillColor={COLORS.BLACK_TROUT}
                    />
                )}
            </EvButton>
        </div>
    );

    const getContent = () => (
        <div className={styles.content}>
            <EvMiniFormRenderer
                formItems={formItems}
                onFormDataChange={onFormDataChange}
                formData={formData}
            />
        </div>
    );

    const getErrorMessageItem = (errorItem) => (
        <EvText key={errorItem} error italics>
            {errorItem}
        </EvText>
    );

    const getErrorInfoView = () => (
        <div className={styles.errorInfoVIew}>
            {hasChanged && (
                <EvText smallNormal>
                    {USER_MAPPING_STATICS.WARNS.CHANGES_NOT_SAVED}
                </EvText>
            )}
            {errorMessageValues.map(getErrorMessageItem)}
        </div>
    );

    return (
        <div className={styles.mainContainer}>
            <EvSimpleCard className={styles.customCard}>
                <div className={styles.container}>
                    {getHeader()}
                    {/* for temp view purpose */}
                    {isEditing && getContent()}
                    {!isEditing && getCollapsedView()}
                    {isEditing && getButtons()}
                    {getErrorInfoView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

UserMappingTagCard.propTypes = {
    isEditable: PropTypes.bool,
    isDefaultEditing: PropTypes.bool, // if by default should be editable
    tagId: PropTypes.string,
    header: PropTypes.string,
    callbackOptions: PropTypes.object,
    positiveButtonText: PropTypes.string,
    negativeButtonText: PropTypes.string,
    formItems: PropTypes.array,
    formData: PropTypes.object,
    collapsedTemplate: PropTypes.string,
    clientCode: PropTypes.string,

    onTagFormDataChange: PropTypes.func,
    onSubmitRuleSuccess: PropTypes.func,
    onRemoveRuleSuccess: PropTypes.func,
    // onPositiveButtonClick: PropTypes.func,
    // onNegativeButtonClick: PropTypes.func,
};

UserMappingTagCard.defaultProps = {
    isEditable: false,
    isDefaultEditing: false, // if by default should be editable
    tagId: "",
    header: "",
    callbackOptions: {},
    positiveButtonText: "",
    negativeButtonText: "",
    formItems: [],
    formData: {},
    collapsedTemplate: "",
    clientCode: "",

    onTagFormDataChange: () => {},
    onSubmitRuleSuccess: () => {},
    onRemoveRuleSuccess: () => {},
    // onPositiveButtonClick: () => {},
    // onNegativeButtonClick: () => {},
};

export default UserMappingTagCard;
