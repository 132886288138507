import CommonActionTypes from "./CommonActionTypes";
import { isCompleted } from "../../utils/CommonUtils";
import { getData } from "../service/CommonService";
import { LOADING_STATUS } from "../static/Enums";

const CommonActions = {
    setControls: (payload) => ({
        type: CommonActionTypes.SET_CONTROLS,
        payload,
    }),
    setStaticDataApiStatus: (payload) => ({
        type: CommonActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),
    setDynamicDataApiStatus: (payload) => ({
        type: CommonActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),
    setStaticData: (payload) => ({
        type: CommonActionTypes.SET_STATIC_DATA,
        payload,
    }),
    setComponentData: (payload) => ({
        type: CommonActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        if (
            isCompleted(
                getState().CommonReducer[payload.applicationId].controls
                    .staticDataApiStatus[payload.templateId]
            )
        ) {
            return Promise.resolve(
                getState().CommonReducer[payload.applicationId].staticData[
                    payload.templateId
                ]
            );
        }
        return getData(payload.url).then((response) => {
            dispatch(
                CommonActions.setStaticData({
                    applicationId: payload.applicationId,
                    templateId: payload.templateId,
                    data: response.data.data,
                })
            );
            dispatch(
                CommonActions.setStaticDataApiStatus({
                    applicationId: payload.applicationId,
                    data: { [payload.templateId]: LOADING_STATUS.COMPLETED },
                })
            );
            return Promise.resolve(response.data.data);
        });
    },
};

export default CommonActions;
