import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import stringTemplate from "string-template";

import DashboardActions from "../redux/DashboardActions";
import API_URLS from "../../../services/apiUrls";
import {
    DASHBOARD_LOADING_MESSAGES,
    DASHBOARD_TEMPLATES,
} from "../static/DashboardStatics";
import { LOADING_STATUS, ROUTES } from "../../../common/static/Enums";
import { getData } from "../services/DashboardServices";
import { dateFormatter, isCompleted } from "../../../utils/CommonUtils";
import {
    EvButton,
    EvClientTicketCard,
    EvLoadingPage,
    EvText,
    EvToast,
} from "../../../common/components";
import { CLIENT_DETAILS_ROUTES } from "../../client-dashboard/statics/ClientDashboardStatics";
import EvLogger from "../../../utils/EvLogger";

import "../styles/client-list-view.scss";

const styles = {
    loadingPage: "ev__client-list-view__loading-page",
    container: "ev__client-list-view__container",
    infoContainer: "ev__client-list-view__info-container",
    actionView: {
        container: "ev__client-list-view__action-view-container",
        button: "ev__client-list-view__action-view-button",
    },
    clients: {
        listContainer: "ev__client-list-view__clients-list-container",
        primaryList: "ev__client-list-view__clients-primary-list",
        secondaryListOuter:
            "ev__client-list-view__clients-secondary-list-outer",
        secondaryListInner:
            "ev__client-list-view__clients-secondary-list-inner",
        expandButton: "ev__client-list-view__clients-expand-button",
        wrapper: "ev__client-list-view__clients-wrapper",
    },
};

const CARD_COUNT_PER_ROW = 4;

const AlphabetizeClients = function (a, b) {
    const nameA = a.clientName.toUpperCase();
    const nameB = b.clientName.toUpperCase();

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
};

const ClientListView = (props) => {
    const { staticData, controls, getStaticData, navigateToPage } = props;

    const [clientList, setClientList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [otherData, setOtherData] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);
    const secondaryInnerContainerRef = useRef(null);

    useEffect(() => {
        getStaticData({
            url: API_URLS.DASHBOARD.GET_CLIENT_LIST_STATIC_DATA,
            templateId: DASHBOARD_TEMPLATES.CLIENT_STATICS.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        getData(API_URLS.DASHBOARD.GET_CLIENT_LIST_DATA)
            .then((clientListResponse) => {
                setClientList(
                    clientListResponse.data.data.clientList.sort(
                        AlphabetizeClients
                    )
                );
                setOtherData({
                    addClientAllowed:
                        clientListResponse.data.data.addClientAllowed,
                });
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Error in loading clients");
                EvLogger.errorWithObject(e, "ClientListView getData");
            });
    }, []);

    const onAddNewClientClick = useCallback(() => {
        navigateToPage({
            path: CLIENT_DETAILS_ROUTES.EDIT_CLIENT.path,
            state: {
                clientCode: "",
            },
        });
    }, [navigateToPage]);

    const onClientClick = useCallback(
        (e, callbackValues) => {
            navigateToPage({
                path: ROUTES.CLIENT_DETAILS,
                state: {
                    clientCode: callbackValues.clientCode,
                    clientName: callbackValues.clientName,
                },
            });
        },
        [navigateToPage]
    );

    const onExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    // addClientAllowed is only temporary till permission engine is in place.
    // DO NOT USE THIS IMPLEMENTATION IN OTHER PLACES
    const getActionView = () => (
        <div className={styles.actionView.container}>
            {otherData.addClientAllowed && (
                <EvButton
                    primary
                    className={styles.actionView.button}
                    onClickHandler={onAddNewClientClick}
                >
                    {staticData.addClientCta.text}
                </EvButton>
            )}
        </div>
    );

    const getNoClientsView = () => {
        if (clientList.length <= 0) {
            return (
                <div className={styles.infoContainer}>
                    <EvText italics>
                        {staticData.noClientAvailableErrorText || ""}
                    </EvText>
                </div>
            );
        }
        return null;
    };

    const getClientCard = (clientData) => (
        <EvButton
            onlyChild
            className={styles.clients.wrapper}
            callbackValues={clientData}
            onClickHandler={onClientClick}
        >
            <EvClientTicketCard
                imgUrl={clientData.clientImage}
                clientName={clientData.clientName}
                updatedDate={stringTemplate(staticData.updatedText, {
                    lastUpdated: dateFormatter(clientData.lastUpdated),
                })}
                backgroundColor={`${clientData.background}`}
            />
        </EvButton>
    );

    const getPrimaryClientList = () => (
        <div className={styles.clients.primaryList}>
            {clientList.slice(0, CARD_COUNT_PER_ROW).map(getClientCard)}
        </div>
    );

    const getSecondaryClientList = () => (
        <div
            className={styles.clients.secondaryListOuter}
            style={{
                height: isExpanded
                    ? secondaryInnerContainerRef.current.clientHeight
                    : 0,
            }}
        >
            <div
                className={styles.clients.secondaryListInner}
                ref={secondaryInnerContainerRef}
            >
                {clientList.slice(CARD_COUNT_PER_ROW).map(getClientCard)}
            </div>
        </div>
    );

    const getClientList = () => (
        <div className={styles.clients.listContainer}>
            {getPrimaryClientList()}
            {getSecondaryClientList()}
            <EvButton
                primary
                className={styles.clients.expandButton}
                onClickHandler={onExpandClick}
            >
                {isExpanded
                    ? staticData.seeLessCta.text
                    : staticData.seeMoreCta.text}
            </EvButton>
        </div>
    );

    if (
        !isCompleted(
            loadingStatus,
            controls.staticDataApiStatus[DASHBOARD_TEMPLATES.CLIENT_STATICS.id]
        )
    ) {
        return (
            <EvLoadingPage
                className={styles.loadingPage}
                loadingText={DASHBOARD_LOADING_MESSAGES.CLIENTS}
                animatedFadeIn
            />
        );
    }

    return (
        <div className={styles.container}>
            {getActionView()}
            {getNoClientsView()}
            {getClientList()}
        </div>
    );
};

ClientListView.propTypes = {
    staticData: PropTypes.object,
    controls: PropTypes.object,
    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
};

ClientListView.defaultProps = {
    staticData: {},
    controls: {},
    getStaticData: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.DashboardReducer.controls,
    staticData:
        state.DashboardReducer.staticData[
            DASHBOARD_TEMPLATES.CLIENT_STATICS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) => {
        dispatch(DashboardActions.getStaticData(payload));
    },
    navigateToPage: (payload) => {
        dispatch(push(payload.path, payload.state));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientListView);
