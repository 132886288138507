import AppContainerActionTypes from "./AppContainerActionTypes";
import { getData } from "../services/AppContainerServices";
import { LOADING_STATUS } from "../../../common/static/Enums";
import EvLogger from "../../../utils/EvLogger";
import API_URLS from "../../../services/apiUrls";

const AppContainerActions = {
    setControls: (payload) => ({
        type: AppContainerActionTypes.SET_CONTROLS,
        payload,
    }),

    fetchData: () => (dispatch) => {
        dispatch(
            AppContainerActions.setControls({
                staticDataLoadingStatus: LOADING_STATUS.LOADING,
                userDataLoadingStatus: LOADING_STATUS.LOADING,
            })
        );
        Promise.all([
            getData(API_URLS.APP_USER_STATIC_DATA),
            getData(API_URLS.APP_USER_DATA),
        ])
            .then(([staticData, userData]) => {
                dispatch(
                    AppContainerActions.setData({
                        staticData: staticData.data.data,
                        userData: userData.data.data,
                    })
                );
                dispatch(
                    AppContainerActions.setControls({
                        staticDataLoadingStatus: LOADING_STATUS.COMPLETED,
                        userDataLoadingStatus: LOADING_STATUS.COMPLETED,
                    })
                );
            })
            .catch((e) => {
                EvLogger.errorWithObject(e, "AppContainerActions fetchData");
            });
    },

    setData: (payload) => ({ type: AppContainerActionTypes.SET_DATA, payload }),
};

export default AppContainerActions;
