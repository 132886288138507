// to understand the structure
import CommonActionTypes from "./CommonActionTypes";
import { CARDS_DASHBOARD_APP_ID } from "../../features/cards-dashboard/statics/CardsDashboardStatics";
import { CURRENT_STATUS_APP_ID } from "../../features/current-status/statics/CurrentStatusStatics";
import { WHERE_TO_APP_ID } from "../../features/where-to/statics/WhereToStatics";
import { EVIVE_360_APP_ID } from "../../features/evive-360/statics/Evive360Statics";
import { RESOURCE_CARD_APP_ID } from "../../features/resource-card/statics/ResourceCardStatics";
import { GAMEDAY_CONTENT_APP_ID } from "../../features/gameday-content/statics/GamedayContentStatics";
import { VENDOR_PROGRAM_CONTENT_APP_ID } from "../../features/vendor-program-content/statics/VendorProgramContentStatic";
import { CLIENT_DASHBOARD_APP_ID } from "../../features/client-dashboard/statics/ClientDashboardStatics";
import { CALLOUTS_APP_ID } from "../../features/callouts/static/CalloutsStatics";

const initialAppState = {
    controls: {
        dynamicDataApiStatus: {},
        staticDataApiStatus: {},
    },
    staticData: {},
    componentData: {},
};

const initialState = {
    [CLIENT_DASHBOARD_APP_ID]: initialAppState,
    [CARDS_DASHBOARD_APP_ID]: initialAppState,
    [CURRENT_STATUS_APP_ID]: initialAppState,
    [WHERE_TO_APP_ID]: initialAppState,
    [EVIVE_360_APP_ID]: initialAppState,
    [RESOURCE_CARD_APP_ID]: initialAppState,
    [GAMEDAY_CONTENT_APP_ID]: initialAppState,
    [VENDOR_PROGRAM_CONTENT_APP_ID]: initialAppState,
    [CALLOUTS_APP_ID]: initialAppState,
};

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case CommonActionTypes.SET_CONTROLS:
            return {
                ...state,
                [action.payload.applicationId]: {
                    ...state[action.payload.applicationId],
                    controls: {
                        ...state[action.payload.applicationId].controls,
                        ...action.payload.data,
                    },
                },
            };

        case CommonActionTypes.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                [action.payload.applicationId]: {
                    ...state[action.payload.applicationId],
                    controls: {
                        ...state[action.payload.applicationId].controls,
                        staticDataApiStatus: {
                            ...state[action.payload.applicationId].controls
                                .staticDataApiStatus,
                            ...action.payload.data,
                        },
                    },
                },
            };

        case CommonActionTypes.SET_DYNAMIC_DATA_API_STATUS:
            return {
                ...state,
                [action.payload.applicationId]: {
                    ...state[action.payload.applicationId],
                    controls: {
                        ...state[action.payload.applicationId].controls,
                        dynamicDataApiStatus: {
                            ...state[action.payload.applicationId].controls
                                .dynamicDataApiStatus,
                            ...action.payload.data,
                        },
                    },
                },
            };

        case CommonActionTypes.SET_STATIC_DATA:
            return {
                ...state,
                [action.payload.applicationId]: {
                    ...state[action.payload.applicationId],
                    staticData: {
                        ...state[action.payload.applicationId].staticData,
                        [action.payload.templateId]: action.payload.data,
                    },
                },
            };

        case CommonActionTypes.SET_COMPONENT_DATA:
            return {
                ...state,
                [action.payload.applicationId]: {
                    ...state[action.payload.applicationId],
                    componentData: {
                        ...state[action.payload.applicationId].componentData,
                        [action.payload.componentId]: action.payload.data,
                    },
                },
            };

        default:
            return state;
    }
};

export default CommonReducer;
