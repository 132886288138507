import { apiGet, apiMock, apiPost } from "../../../services/api";
import API_URLS from "../../../services/apiUrls";
import {
    CLIENT_SELECTION_MOCK_DATA,
    CONTENT_MOCK_DATA,
    PROGRAM_SELECTION_MOCK_DATA,
    SUMMARY_MOCK_DATA,
} from "../static/mock-data/getEnterpriseNotificationsData";
import EvLogger from "../../../utils/EvLogger";
import { COMMON_RESPONSE } from "../../notifications/static/getNotificationsDataJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        //  dynamic data: client selection page
        case API_URLS.ENTERPRISE_NOTIFICATIONS.CLIENT_SELECTION.CLIENTS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, CLIENT_SELECTION_MOCK_DATA.CLIENTS_DATA)
                : apiGet(url, params);

        case API_URLS.ENTERPRISE_NOTIFICATIONS.CLIENT_SELECTION
            .NOTIFICATIONS_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      CLIENT_SELECTION_MOCK_DATA.NOTIFICATIONS_DATA
                  )
                : apiGet(url, params);

        // dynamic data: program selection
        case API_URLS.ENTERPRISE_NOTIFICATIONS.PROGRAM_SELECTION
            .PRE_FILLED_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      PROGRAM_SELECTION_MOCK_DATA.PRE_FILLED_DATA
                  )
                : apiGet(url, params);

        // dynamic data: content
        case API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.PRE_FILLED_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, CONTENT_MOCK_DATA.PRE_FILLED_DATA)
                : apiGet(url, params);

        case API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.CTA_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, CONTENT_MOCK_DATA.CTA_DATA)
                : apiGet(url, params);

        //  dynamic data: summary page
        case API_URLS.ENTERPRISE_NOTIFICATIONS.SUMMARY.SUMMARY_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      SUMMARY_MOCK_DATA.NOTIFICATION_SUMMARY_DATA
                  )
                : apiGet(url, params);

        default:
            EvLogger.warn("NotificationsService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.ENTERPRISE_NOTIFICATIONS.PROGRAM_SELECTION.POST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      PROGRAM_SELECTION_MOCK_DATA.PROGRAM_SELECTION_POST_SUCCESS,
                      data
                  )
                : apiPost(url, qParams, data);
        case API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.POST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      CONTENT_MOCK_DATA.CONTENT_POST_SUCCESS,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.ENTERPRISE_NOTIFICATIONS.SUMMARY
            .POST_PUBLISH_NOTIFICATION:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("NotificationsService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
