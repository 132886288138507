const EVIVE_360_APP_ID = "vendorProgramsCards__evive360";

const EVIVE_360_TEMPLATES = {
    STATIC: {
        id: "evive360__static",
    },
    STATIC_CLIENT_PROGRAM: {
        id: "evive360__staticClientProgram",
    },
};

const EVIVE_360_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
};

export { EVIVE_360_APP_ID, EVIVE_360_TEMPLATES, EVIVE_360_STATICS };
