const GamedayScheduleActionTypes = {
    SET_CONTROLS: "GamedayScheduleActionTypes/SET_CONTROLS",
    SET_STATIC_DATA_API_STATUS:
        "GamedayScheduleActionTypes/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS:
        "GamedayScheduleActionTypes/SET_DYNAMIC_DATA_API_STATUS",
    SET_STATIC_DATA: "GamedayScheduleActionTypes/SET_STATIC_DATA_FOR_PAGE",
    SET_COMPONENT_DATA: "GamedayScheduleActionTypes/SET_COMPONENT_DATA",
    SET_LANDING_DATA: "GamedayScheduleActionTypes/SET_LANDING_DATA",
    RESET_ALL_DATA: "GamedayScheduleActionTypes/RESET_ALL_DATA",
};

export default GamedayScheduleActionTypes;
