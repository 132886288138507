const EVIVE_360_CARD_DATA = {
    data1: {
        // logoImage:
        //   "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/HingeHealth.png",
        logoImage:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/MDLive.png",
        backgroundColor: "#153159",
        category: "Health",
        header: "BCBSM Online Behavioral Health Visits",
        description:
            "With logo and background color. Access a doctor from the comfort of your home (by video or phone) for behavioral health visits.",
        vendorDetails: "bcbsmonlinevisits.com",
        contact: "1-844-606-1608",
        positiveButtonText: "Mark as interested",
        negativeButtonText: "Mark as not interested",
        ctaText: "Show less",
    },
    data2: {
        backgroundImage:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        category: "Health",
        header: "With Background Image",
        description:
            "Access a doctor from the comfort of your home (by video or phone) for behavioral health visits.",
        vendorDetails: "bcbsmonlinevisits.com",
        contact: "1-844-606-1608",
        positiveButtonText: "Mark as interested",
        negativeButtonText: "Mark as not interested",
        ctaText: "Show less",
    },
};

const WTGFC_CARD_DATA = {
    data1: {
        header: "Convenience care",
        description: "Click below to check available hours",
        list: [
            "Clinics, like the Kroger Little Clinic, can help with allergies, influenza, and much more.",
            "They primarily treat common illnesses such as cold and flu, sore throat, headaches, etc., and can also provide vaccinations.",
        ],
        amountText: "30% Coinsurance only (no deductible)",
        ctaText: "Click to learn more",
    },
    data2: {
        header: "Multiple CTAs",
        description: "Click below to check available hours",
        list: [
            "Clinics, like the Kroger Little Clinic, can help with allergies, influenza, and much more.",
            "They primarily treat common illnesses such as cold and flu, sore throat, headaches, etc., and can also provide vaccinations.",
        ],
        amountText: "30% Coinsurance only (no deductible)",
        ctaText: ["Click to learn more", "External link"],
    },
};

const ACTION_CARD_DATA = {
    data1: {
        header: "HealthEquity",
        description:
            "To view your balance or make adjustments to your account, go to the HealthEquity website.",
        actionText: "Take action",
    },
    data2: {
        description:
            "To view your balance or make adjustments to your account, go to the HealthEquity website.",
        actionText: "Take action",
    },
};

const QUESTION_CARD_DATA = {
    data1: {
        header: "What's a Health savings account?",
        description:
            "This is an account that lets you set aside pre-tax money for health-related expenses. You decide how much to contribute, as long as you don’t go over the limit set by the government.",
    },
};

const CHECKLIST_CARD_DATA = {
    data1: {
        header: "What should I know? ",
        iconName: "CHECK",
        fillColor: "#fff",
        bgColor: "#1B67DA",
        circularIcon: true,
        list: [
            "This is an account that lets you set aside pre-tax money for health-related expenses. You decide how much to contribute, as long as you don’t go over the limit set by the government.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        ],
    },
};

const LANDSCAPE_IMAGE_CARD_DATA = {
    data1: {
        cardLogo:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/clickTocall.png",
        backgroundColor: "#153159",
        header: "COVID-19: What to know",
        description:
            "This is a card with png logo and bg color. Ten the hastened steepest feelings pleasant few surprise property.",
        headerTextColor: "#000a23",
    },
    data2: {
        backgroundImage:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        header: "Card with Background Image",
        description: "",
        headerTextColor: "#000a23",
    },
    data3: {
        imageUrl:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/spendingsum.png",
        header: "Deprecated Card style",
        description: "",
        headerTextColor: "#000a23",
    },
    data4: {
        cardLogo:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/CVSPharmacy.png",
        backgroundColor: "#153159",
        header: "COVID-19: What to know",
        description:
            "This is a large card with png logo and bg color. Ten the hastened steepest feelings pleasant few surprise property.",
        headerTextColor: "#000a23",
    },
    data5: {
        header: "COVID-19: What to know",
        description: "This is the same card when image is not supplied",
        headerTextColor: "#000a23",
    },
};

const PORTRAIT_IMAGE_CARD_DATA = {
    data1: {
        // TODO: deprecated remove later
        imageUrl:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/clickTocall.png",
        header: "COVID-19: What to know",
        description:
            "Ten the hastened steepest feelings pleasant few surprise property.",
        headerTextColor: "#000a23",
    },
    data2: {
        backgroundImage:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",

        header: "COVID-19: What to know",
        description: "",
        headerTextColor: "#000a23",
    },
    data3: {
        cardLogo:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/digitalwallet.png",
        backgroundColor: "#153159",
        header: "COVID-19",
        description: "Some description",
        headerTextColor: "#000a23",
    },
    data4: {
        cardLogo:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/CVSPharmacy.png",
        backgroundColor: "#153159",
        header: "COVID-19",
        description: "Some description",
        headerTextColor: "#000a23",
    },
};

const RESOURCE_CARD_DATA = {
    data1: {
        header: "PNG Image with background color",
        backgroundColor: "#e5edff",
        logoImage:
            "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/common/vendor_icons/1060_HCSC/VendorLogos/DeltaDental.png",
        backgroundImage: "",
        ctaText: "Learn more",
        description:
            "Daily monitoring can really help you learn how your body reacts when your blood glucose changes. Livongo makes it easier to monitor it daily so you can pick up on those patterns.",
    },
    data2: {
        header: "Background Image",
        backgroundColor: "",
        logoImage: "",
        backgroundImage:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        ctaText: "Learn more",
        description:
            "Daily monitoring can really help you learn how your body reacts when your blood glucose changes. Livongo makes it easier to monitor it daily so you can pick up on those patterns.",
    },
    data3: {
        header: "Mulitple Buttons",
        backgroundColor: "",
        logoImage: "",
        backgroundImage:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        ctaText: ["Learn more", "Learn some more things"],
        description:
            "Daily monitoring can really help you learn how your body reacts when your blood glucose changes. Livongo makes it easier to monitor it daily so you can pick up on those patterns.",
    },
    data4: {
        header: "No Image",
        backgroundColor: "",
        logoImage: "",
        backgroundImage: "",
        ctaText: "Learn more",
        description:
            "Daily monitoring can really help you learn how your body reacts when your blood glucose changes. Livongo makes it easier to monitor it daily so you can pick up on those patterns.",
    },
};

const INFO_CARD_DATA = {
    data1: {
        header: "You have unlinked tags.",
        description:
            "The next step is to define and link your eligibility tags based on the eligibility file. This will connect all the necessary data.",
        iconName: "ALERT",
    },
};

const SIMPLE_ITEM_CARD_DATA = {
    data1: {
        header: "Diabetes management",
        subHeader: "Memory",
        description: "Some Description about the card",
    },
    data2: {
        header: "Diabetes management",
        subHeader: "Memory",
    },
    data3: {
        header: "Diabetes management",
        subHeader: "Memory",
        description:
            "Some Description about the card. Some really long description to check the text wrap feature",
    },
};

const GAME_CONTENT_CARD_DATA = {
    data1: {
        imageUrl:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        header: "Game Card Header",
        description:
            "Exercise not only keeps you healthy, but it can also help manage stress and anxiety. Staying active increases the brain’s endorphins, resulting in more energy and optimism. Regular exercise can improve your mood, increase self-confidence and lower depression and anxiety symptoms.",
        subHeader1: "Question:",
        content1:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore?",
        subHeader2: "Correct answer:",
        content2: "Lorem ipsum dolor",
    },
    data2: {
        imageUrl:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        description:
            "Exercise not only keeps you healthy, but it can also help manage stress and anxiety. Staying active increases the brain’s endorphins, resulting in more energy and optimism. Regular exercise can improve your mood, increase self-confidence and lower depression and anxiety symptoms.",
    },
};

const CLIENT_CARD_DATA = {
    data1: {
        header: "Client Details",
        ctaText: "Edit client details",
        values: {
            clientLogo:
                "https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/command_center/images/uat/121ec2f6a4e0d99413534ffc1b63ee9bcfb83790233c90dc76ba788293b0d546ecbacea7105e1ef9570d6444f443328dcc3b9a50b3f9e234ed79053118d7625e.png",
            clientCode: "70-04c3e90c-c6c6-5e0d-a01b-e57",
            clientName: "ACME Company Name",
        },
    },
};

export {
    EVIVE_360_CARD_DATA,
    WTGFC_CARD_DATA,
    ACTION_CARD_DATA,
    QUESTION_CARD_DATA,
    CHECKLIST_CARD_DATA,
    LANDSCAPE_IMAGE_CARD_DATA,
    PORTRAIT_IMAGE_CARD_DATA,
    RESOURCE_CARD_DATA,
    INFO_CARD_DATA,
    SIMPLE_ITEM_CARD_DATA,
    GAME_CONTENT_CARD_DATA,
    CLIENT_CARD_DATA,
};
