const CURRENT_STATUS_APP_ID = "vendorProgramsCards__currentStatus";

const CURRENT_STATUS_TEMPLATES = {
    STATICS: {
        id: "currentStatus__static",
    },
    STATIC_CLIENT_PROGRAM: {
        id: "currentStatus__staticClientProgram",
    },
};

const CURRENT_STATUS_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
};

export {
    CURRENT_STATUS_APP_ID,
    CURRENT_STATUS_TEMPLATES,
    CURRENT_STATUS_STATICS,
};
