import { COLORS } from "../../../common/static/VmsStatics";

const TOAST_OBJECTS = {
    INFO_WITH_ICON_AND_HEADER: {
        header: "Toast 1",
        description: "Description about Toast 1",
        icon: {
            iconName: "USER",
            fillColor: COLORS.PRODUCT,
        },
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_INFO: {
        header: "Toast 2",
        description: "Description about Toast 2",
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_TOAST: {
        description: "Description about Toast 2",
        // type: "INFO", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_ERROR: {
        description: "This is a error message",
        // type: "ERROR", // no need to send this
        // duration: 2000, // no need to send this
    },
    SIMPLE_SUCCESS: {
        description: "This is a success message",
        icon: {
            iconName: "CHECK",
            fillColor: COLORS.WHITE,
        },
        // type: "SUCCESS", // no need to send this
        // duration: 5000, // no need to send this
    },
    SIMPLE_WARN: {
        description: "This is a warn message",
        // type: "WARN", // no need to send this
        // duration: 5000, // no need to send this
    },
    ERROR_WITH_ICON: {
        header: "Error",
        description: "This is a error message with icon",
        icon: {
            iconName: "CROSS_THICK",
            fillColor: COLORS.WHITE,
        },
        // type: "WARN", // no need to send this
        // duration: 5000, // no need to send this
    },
    WARN_WITH_ICON: {
        header: "Warn",
        description: "This is a warn message with icon",
        icon: {
            iconName: "ALERT",
            fillColor: COLORS.WHITE,
        },
        // type: "WARN", // no need to send this
        // duration: 5000, // no need to send this
    },
};

const DROPDOWN_MENU_OBJECTS = {
    data1: {
        label: "Dropdown",
        data: [
            {
                text: "Dashboard",
                type: "internal",
                id: "dashboard",
                action: "/cc/dashboard",
            },
            {
                text: "Users",
                type: "internal",
                id: "users",
                action: "/cc/Users",
            },
            {
                text: "Logout",
                type: "internal",
                id: "logout",
                action: "/cc/logout",
            },
        ],
    },
    minimalData1: {
        data: [
            {
                text: "is equal to",
                type: "internal",
                id: "isEqualTo",
                value: "isEqualTo",
                action: "",
            },
            {
                text: "is not equal to",
                type: "internal",
                id: "isNotEqualTo",
                value: "isNotEqualTo",
                action: "",
            },
            {
                text: "is equal to on of the following",
                type: "internal",
                id: "isEqualToOneOf",
                value: "isNotEqualTo",
                action: "",
            },
        ],
    },
};

const CONFIRMATION_MODAL_OBJECTS = {
    data1: {
        header: "Are you sure?",
        description:
            "You can handle if you want to cancel on overlay click or not",
        positiveButtonText: "Ok great!",
        negativeButtonText: "No Idea!",
        onPositiveAction: () => {
            console.log("PositiveAction");
        },
        onNegativeAction: () => {
            console.log("NegativeAction");
        },
        // exitOnOverlayClick: true,
        // exitOnActionClick: true,
        // allowBackgroundScroll: true,
    },
};

const FULL_SCREEN_OVERLAY_MODAL_OBJECTS = {
    data1: {
        header: "Header text",
        description: "Some long description text",
        options: {
            showLoading: true,
            subHeader: "SubHeader text",
            iconOptions: {
                iconName: "ALERT",
                fillColor: COLORS.RED_MONZA,
            },
            buttonText: "Close",
            buttonAction: () => {
                console.log("Action fired");
            },
            onOverlayClick: () => {
                console.log("Overlay clicked");
            },
        },
    },
};

const SAMPLE_CATEGORY_MENU_DATA = [
    {
        id: "cat1",
        displayName: "User Profile",
        subCategories: [
            {
                id: "cat1_1",
                displayName: "MSK Issues",
            },
            {
                id: "cat1_2",
                displayName: "Cardio",
            },
        ],
    },
    {
        id: "cat11",
        displayName: "User Profile",
    },
    {
        id: "cat2",
        displayName: "Pharmacy",
        subCategories: [
            {
                id: "cat2_1",
                displayName: "MSK Issues",
            },
            {
                id: "cat2_2",
                displayName: "Immunodeficiencies Cardiovascular and metabolic",
            },
        ],
    },
    {
        id: "cat3",
        displayName:
            "Claims and diagnoses Claims and diagnoses Claims and diagnoses",
        subCategories: [
            {
                id: "cat3_1",
                displayName: "Cardiovascular and metabolic",
            },
            {
                id: "cat3_2",
                displayName: "Cancer and related treatments",
            },
            {
                id: "cat3_3",
                displayName: "Cardiovascular and metabolic",
            },
            {
                id: "cat3_4",
                displayName: "Cancer and related treatments",
            },
            {
                id: "cat3_5",
                displayName: "Cardiovascular and metabolic",
            },
            {
                id: "cat3_6",
                displayName: "Cancer and related treatments",
            },
        ],
    },
];

const SAMPLE_TAB_SELECTOR_DATA = [
    {
        id: "tab1",
        displayName: "Tab 1 (Default)",
    },
    {
        id: "tab2",
        displayName: "Tab 2 Items",
    },
    {
        id: "tab3",
        displayName: "Others",
    },
];

const SIDE_NAVBAR_DATA = {
    header: "Ev SideNavBar",
    listData: {},
    data: [
        {
            id: "tab1",
            label: "Tab 1",
            action: "/cc/client/manage-programs/customize-programs/tags",
            type: "INTERNAL",
        },
        {
            id: "tab2",
            label: "Tab 2",
            action: "/cc/client/manage-programs/customize-programs/tags",
            type: "NONE",
            subTabsVisibilityType: "DEFAULT",
            subTabs: [
                {
                    id: "subTab1",
                    label: "Sub Tab 1",
                },
                {
                    id: "subTab2",
                    label: "Sub Tab 2",
                },
            ],
        },
        {
            id: "tab3",
            label: "Tab 3",
            action: "/cc/client/manage-programs/customize-programs/tags",
            type: "INTERNAL",
            subTabsVisibilityType: "DEPENDENCY_KEY",
            subTabsVisibilityDependencyKey: "vendorId",
            subTabs: [
                {
                    id: "subTab1",
                    label: "Sub Tab 1",
                },
                {
                    id: "subTab2",
                    label: "Sub Tab 2",
                },
            ],
        },
    ],
};

export {
    TOAST_OBJECTS,
    DROPDOWN_MENU_OBJECTS,
    CONFIRMATION_MODAL_OBJECTS,
    FULL_SCREEN_OVERLAY_MODAL_OBJECTS,
    SAMPLE_CATEGORY_MENU_DATA,
    SAMPLE_TAB_SELECTOR_DATA,
    SIDE_NAVBAR_DATA,
};
