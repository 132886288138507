const LINKED_UNLINKED_TAGS_COMPONENTS = {
    ALL_TAGS: {
        id: "linkedUnlinkedTags__allTags",
    },
    UNLINKED_TAGS: {
        id: "linkedUnlinkedTags__unlinkedTags",
    },
    LINKED_TAGS: {
        id: "linkedUnlinkedTags__linkedTags",
    },
};

const LINKED_UNLINKED_TAGS_TEMPLATES = {
    STATICS: {
        id: "linkedUnlinkedTags__statics",
    },
};

const LINKED_UNLINKED_TABS = {
    UNLINKED_TAGS: "unlinkedTags",
    LINKED_TAGS: "linkedTags",
};

export {
    LINKED_UNLINKED_TAGS_COMPONENTS,
    LINKED_UNLINKED_TAGS_TEMPLATES,
    LINKED_UNLINKED_TABS,
};
