import React from "react";
import EnterpriseContentView from "./EnterpriseContentView";

const EnterpriseContentContainer = () => {
    return (
        <div style={{ marginTop: "12rem" }}>
            <EnterpriseContentView />
        </div>
    );
};

export default EnterpriseContentContainer;
