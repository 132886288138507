import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvText } from "./index";
import { COLORS } from "../static/VmsStatics";
import { isNullOrEmpty } from "../../utils/CommonUtils";

import "../styles/ev-category-menu.scss";

const styles = {
    container: "ev__ev-category-menu__container",
    header: {
        container: "ev__ev-category-menu__header-container",
    },
    categories: {
        container: "ev__ev-category-menu__categories-container",
        itemContainer: "ev__ev-category-menu__categories-item-container",
        itemContainerSelected:
            "ev__ev-category-menu__categories-item-container-selected",
        headerButton: "ev__ev-category-menu__categories-header-button",
        headerText: "ev__ev-category-menu__categories-header-text",
        icon: "ev__ev-category-menu__categories-icon",
        iconSelected: "ev__ev-category-menu__categories-icon-selected",
    },
    subCategories: {
        container: "ev__ev-category-menu__sub-categories-container",
        containerExpanded:
            "ev__ev-category-menu__sub-categories-container-expanded",
        innerContainer: "ev__ev-category-menu__sub-categories-inner-container",
        itemContainer: "ev__ev-category-menu__sub-categories-item-container",
    },
};

const EvSubCategoryMenu = (props) => {
    const { subCategoriesData, isExpanded, onSelect, selectedId } = props;

    const innerContainerRef = useRef(null);

    const customContainerClass = classNames(styles.subCategories.container, {
        [styles.subCategories.containerExpanded]: isExpanded,
    });

    const getSubCategoriesItem = (subCategoryItemData) => {
        const isSelected = isExpanded && selectedId === subCategoryItemData.id;
        return (
            <EvButton
                key={subCategoryItemData.id}
                onlyChild
                className={styles.subCategories.itemContainer}
                onClickHandler={onSelect}
                callbackValues={{ id: subCategoryItemData.id }}
            >
                <EvText defaultBold={isSelected}>
                    {subCategoryItemData.displayName}
                </EvText>
            </EvButton>
        );
    };

    const containerHeight =
        innerContainerRef.current && isExpanded
            ? innerContainerRef.current.clientHeight
            : 0;

    return (
        <div
            className={customContainerClass}
            style={{ height: containerHeight }}
        >
            <div
                className={styles.subCategories.innerContainer}
                ref={innerContainerRef}
            >
                {subCategoriesData.map(getSubCategoriesItem)}
            </div>
        </div>
    );
};

EvSubCategoryMenu.propTypes = {
    isExpanded: PropTypes.bool,
    selectedId: PropTypes.string,
    subCategoriesData: PropTypes.array,
    onSelect: PropTypes.func,
};

EvSubCategoryMenu.defaultProps = {
    isExpanded: false,
    selectedId: "",
    subCategoriesData: [],
    onSelect: () => {},
};

const EvCategoryMenu = (props) => {
    const {
        header,
        className,
        categoriesData,
        // allOptionObject,
        onCategorySelect,
        selectedCategoryIdFromParent,
        hardCategoryIds,
    } = props;

    const [selectedCategoryId, setSelectedCategoryId] = useState(
        selectedCategoryIdFromParent || ""
    );
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");

    useEffect(() => {
        if (hardCategoryIds.indexOf(selectedCategoryIdFromParent) !== -1) {
            setSelectedCategoryId(selectedCategoryIdFromParent);
            setSelectedSubCategoryId("");
        }
    }, [hardCategoryIds, selectedCategoryIdFromParent]);

    const onCategoriesClick = useCallback(
        (e, callbackValue) => {
            if (callbackValue.id === selectedCategoryId) {
                if (!isNullOrEmpty(callbackValue.subCategories)) {
                    setSelectedCategoryId("");
                    setSelectedSubCategoryId("");
                }
            } else {
                setSelectedSubCategoryId("");
                setSelectedCategoryId(callbackValue.id);

                if (
                    isNullOrEmpty(callbackValue.subCategories) ||
                    callbackValue.subCategories.length <= 0
                ) {
                    onCategorySelect(callbackValue.id);
                }
            }
        },
        [selectedCategoryId, onCategorySelect]
    );

    const onSubCategoriesClick = useCallback(
        (e, callbackValues) => {
            if (selectedSubCategoryId === callbackValues.id) {
                return;
            }
            setSelectedSubCategoryId(callbackValues.id);
            onCategorySelect(callbackValues.id);
        },
        [selectedSubCategoryId, onCategorySelect]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText>{header}</EvText>
        </div>
    );

    const getSubCategoriesView = (categoryItemData) => (
        <EvSubCategoryMenu
            isExpanded={categoryItemData.id === selectedCategoryId}
            selectedId={selectedSubCategoryId}
            subCategoriesData={categoryItemData.subCategories}
            onSelect={onSubCategoriesClick}
        />
    );

    const getCategoryItem = (categoryItemData) => {
        const isSelected = categoryItemData.id === selectedCategoryId;
        const customContainerClass = classNames(
            styles.categories.itemContainer,
            {
                [styles.categories.itemContainerSelected]: isSelected,
            }
        );
        const customIconClass = classNames(styles.categories.icon, {
            [styles.categories.iconSelected]: isSelected,
        });
        return (
            <div key={categoryItemData.id} className={customContainerClass}>
                <EvButton
                    onlyChild
                    className={styles.categories.headerButton}
                    onClickHandler={onCategoriesClick}
                    callbackValues={categoryItemData}
                >
                    <EvText
                        defaultBold={isSelected}
                        color={isSelected ? COLORS.PRODUCT : COLORS.BLACK_TROUT}
                        className={styles.categories.headerText}
                    >
                        {categoryItemData.displayName}
                    </EvText>
                    {categoryItemData.subCategories &&
                        categoryItemData.subCategories.length > 0 && (
                            <EvIcon
                                outerContainerStyleClass={customIconClass}
                                iconName="ARROW_ANGLE_DOWN"
                                size={3}
                            />
                        )}
                </EvButton>
                {categoryItemData.subCategories &&
                    categoryItemData.subCategories.length > 0 &&
                    getSubCategoriesView(categoryItemData)}
            </div>
        );
    };

    const getCategoriesView = () => (
        <div className={styles.categories.container}>
            {/*{!isNullOrEmpty(allOptionObject) && getCategoryItem(allOptionObject)}*/}
            {categoriesData.map(getCategoryItem)}
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {getHeaderView()}
            {getCategoriesView()}
        </div>
    );
};

EvCategoryMenu.propTypes = {
    header: PropTypes.string,
    className: PropTypes.string,
    selectedCategoryIdFromParent: PropTypes.string,
    categoriesData: PropTypes.array,
    onCategorySelect: PropTypes.func,
    // allOptionObject: PropTypes.object,
    hardCategoryIds: PropTypes.array,
};

EvCategoryMenu.defaultProps = {
    header: "",
    // header: "SEARCH BY CATEGORIES",
    className: "",
    selectedCategoryIdFromParent: "",
    hardCategoryIds: [],
    // allOptionObject: {},
    // allOptionObject: {
    //   id: "ALL",
    //   displayName: "All Tags",
    // },
    categoriesData: [],
    // categoriesData: [
    //   {
    //     id: "cat1",
    //     displayName: "User Profile",
    //     subCategories: [
    //       {
    //         id: "cat1_1",
    //         displayName: "MSK Issues",
    //       },
    //       {
    //         id: "cat1_2",
    //         displayName: "Cardio",
    //       },
    //     ],
    //   },
    //   {
    //     id: "cat11",
    //     displayName: "User Profile",
    //   },
    //   {
    //     id: "cat2",
    //     displayName: "Pharmacy",
    //     subCategories: [
    //       {
    //         id: "cat2_1",
    //         displayName: "MSK Issues",
    //       },
    //       {
    //         id: "cat2_2",
    //         displayName: "Immunodeficiencies Cardiovascular and metabolic",
    //       },
    //     ],
    //   },
    //   {
    //     id: "cat3",
    //     displayName:
    //       "Claims and diagnoses Claims and diagnoses Claims and diagnoses",
    //     subCategories: [
    //       {
    //         id: "cat3_1",
    //         displayName: "Cardiovascular and metabolic",
    //       },
    //       {
    //         id: "cat3_2",
    //         displayName: "Cancer and related treatments",
    //       },
    //       {
    //         id: "cat3_3",
    //         displayName: "Cardiovascular and metabolic",
    //       },
    //       {
    //         id: "cat3_4",
    //         displayName: "Cancer and related treatments",
    //       },
    //       {
    //         id: "cat3_5",
    //         displayName: "Cardiovascular and metabolic",
    //       },
    //       {
    //         id: "cat3_6",
    //         displayName: "Cancer and related treatments",
    //       },
    //     ],
    //   },
    // ],
    onCategorySelect: () => {},
};

export default EvCategoryMenu;
