import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { replace } from "connected-react-router";

import VendorProgramsDashboardContainer from "../vendor-programs-dashboard/views/VendorProgramsDashboardContainer";
import CustomizeProgramsContainer from "../customize-program/views/CustomizeProgramsContainer";
import NewVendorProgramContainer from "../new-vendor-program/views/NewVendorProgramContainer";
import {
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAMS_TEMPLATES,
} from "../statics/VendorProgramsStatics";

import "../styles/vendor-program-container.scss";
import VendorProgramsActions from "../redux/VendorProgramsActions";
import API_URLS from "../../../services/apiUrls";
import {
    getSelectedApplicationIdFromSidebar,
    isCompleted,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import { EvLoadingPage, EvSideNavBar } from "../../../common/components";
import { CTA_TYPE } from "../../../common/static/Enums";
import LinkedUnlinkedTagsView from "../../linked-unlinked-tags/views/LinkedUnlinkedTagsView";
import SavedPopulationContainer from "../../saved-population/views/SavedPopulationContainer";

const styles = {
    container: "ev__vendor-program-container__container",
    sidebar: "ev__vendor-program-container__sidebar",
    contentContainer: "ev__vendor-program-container__content-container",
};

const VendorProgramContainer = (props) => {
    const {
        clientCode,
        clientName,
        controls,
        staticData,
        location,

        getStaticData,
        replaceAction,
    } = props;

    const [selectedNavbarId, setSelectedNavbarId] = useState("");

    useEffect(() => {
        // static data not yet loaded
        if (isNullOrEmpty(staticData)) {
            return;
        }
        const selectedApplicationId = getSelectedApplicationIdFromSidebar(
            staticData.sideBarsTabs,
            location.pathname
        );
        setSelectedNavbarId(
            selectedApplicationId || staticData.sideBarsTabs[0].id
        );
    }, [staticData, location.pathname]);

    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    useEffect(() => {
        getStaticData({
            templateId: VENDOR_PROGRAMS_TEMPLATES.VENDOR_PROGRAM_SIDEBAR.id,
            url: API_URLS.VENDOR_PROGRAMS.SIDEBAR_STATICS,
        });
    }, [getStaticData]);

    const onNavbarClick = useCallback(
        (e, callbackValues) => {
            setSelectedNavbarId(callbackValues.id);
            if (callbackValues.type === CTA_TYPE.INTERNAL) {
                if (location.pathname === callbackValues.action) {
                    return;
                }
                const isPathInsideProgram =
                    callbackValues.action.indexOf(
                        "/cc/client/manage-programs/customize-programs/"
                    ) !== -1;
                replaceAction({
                    path: callbackValues.action,
                    state: {
                        clientCode,
                        clientName,
                        ...(isPathInsideProgram ? { vendorCardData } : {}),
                    },
                });
            }
        },
        [location, replaceAction, clientCode, clientName, vendorCardData]
    );

    const getSideBar = () => (
        <EvSideNavBar
            className={styles.sidebar}
            header={staticData.header}
            listData={staticData.sideBarsTabs}
            onClickHandler={onNavbarClick}
            addOnData={vendorCardData}
            selectedId={selectedNavbarId}
        />
    );

    const getContent = () => (
        <div className={styles.contentContainer}>
            <Switch>
                <Route
                    path={VENDOR_PROGRAMS_PAGES.CUSTOMIZE_PROGRAMS.path}
                    render={(routeProps) => (
                        <CustomizeProgramsContainer
                            {...routeProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM.path}
                    exact
                    render={(routeProps) => (
                        <NewVendorProgramContainer {...routeProps} {...props} />
                    )}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.TAGS.path}
                    exact
                    render={(routeProps) => (
                        <LinkedUnlinkedTagsView {...routeProps} {...props} />
                    )}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.SAVED_POPULATION.path}
                    exact
                    render={(routeProps) => (
                        <SavedPopulationContainer {...routeProps} {...props} />
                    )}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.DASHBOARD.path}
                    render={(routeProps) => (
                        <VendorProgramsDashboardContainer
                            {...routeProps}
                            {...props}
                        />
                    )}
                />
                <Route
                    path={VENDOR_PROGRAMS_PAGES.DASHBOARD.path}
                    render={(routeProps) => (
                        <VendorProgramsDashboardContainer
                            {...routeProps}
                            {...props}
                        />
                    )}
                />
                {/* default for base path*/}
                <Route
                    path={VENDOR_PROGRAMS_PAGES.BASE.path}
                    render={(routeProps) => (
                        <VendorProgramsDashboardContainer
                            {...routeProps}
                            {...props}
                        />
                    )}
                />
            </Switch>
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                VENDOR_PROGRAMS_TEMPLATES.VENDOR_PROGRAM_SIDEBAR.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getSideBar()}
            {getContent()}
        </div>
    );
};

VendorProgramContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    staticData: PropTypes.object,
    controls: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    replaceAction: PropTypes.func,
};

VendorProgramContainer.defaultProps = {
    clientCode: "",
    clientName: "",
    staticData: {},
    controls: {},
    location: {},

    getStaticData: () => {},
    replaceAction: () => {},
};

const mapStateToProps = (state) => ({
    location: state.router.location,
    controls: state.VendorProgramsReducer.controls,
    staticData:
        state.VendorProgramsReducer.staticData[
            VENDOR_PROGRAMS_TEMPLATES.VENDOR_PROGRAM_SIDEBAR.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    replaceAction: (payload) => dispatch(replace(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorProgramContainer);
