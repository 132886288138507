import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { EvText } from "../index";

import "../../styles/content-items/ev-content-options.scss";

const styles = {
    container: "ev__ev-content-options__container",
};

const EvContentOptions = (props) => {
    const { label, displayResolver, allValues, options, dependency } = props;

    const displayString = stringTemplate(displayResolver, {
        ...allValues,
        [dependency]: options[allValues[dependency]]
            ? options[allValues[dependency]].name
            : "",
    });

    return (
        <div className={styles.container}>
            <EvText
                defaultDark
                dangerous
            >{`${label} <b>${displayString}</b>`}</EvText>
        </div>
    );
};

EvContentOptions.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
    options: PropTypes.object,
};

EvContentOptions.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
    options: {},
};

export default EvContentOptions;
