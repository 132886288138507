const EMPLOYER_CARD_TEMPLATES = {
    STATIC: {
        id: "employerCard_static",
    },
};

const STATIC_STRINGS = {
    FORM_INVALID_TOAST: "Some of the values are not valid.",
};

export { EMPLOYER_CARD_TEMPLATES, STATIC_STRINGS };
