import PropTypes from "prop-types";
import React from "react";
import { EvText } from "../index";

const styles = {
    container: "ev__ev-form-description-text__container",
};

const EvFormDescriptionText = (props) => {
    const { keyName, formData } = props;
    return (
        <div className={styles.container}>
            <EvText>{formData[keyName].value}</EvText>
        </div>
    );
};

EvFormDescriptionText.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    options: PropTypes.object,
};

EvFormDescriptionText.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    options: {},
};

export default EvFormDescriptionText;
