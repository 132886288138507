import { getData } from "../../common/service/CommonService";
import CacheActionTypes from "./CacheActionTypes";
import { isNullOrEmpty, queryStringGenerator } from "../../utils/CommonUtils";

const CACHE_ITEM_PREFIX = "cache_";

const CacheActions = {
    getCachedData: (payload) => (dispatch, getState) => {
        const { url, qParams, type } = payload;
        const itemKey = `${CACHE_ITEM_PREFIX}${url}_${queryStringGenerator(
            qParams
        )}`;

        const preCachedData = getState().CacheReducer.cache[itemKey];

        if (!isNullOrEmpty(preCachedData)) {
            return Promise.resolve(preCachedData.data);
        }

        return getData(url, qParams).then((response) => {
            dispatch(
                CacheActions.setCache({
                    key: itemKey,
                    data: response.data.data,
                    type: type || "",
                })
            );
            return Promise.resolve(response.data.data);
        });
    },

    setCache: (payload) => ({
        type: CacheActionTypes.SET_CACHE_ITEM_DATA,
        payload,
    }),

    // used to clear targeted cache
    setMultipleCacheData: (payload) => ({
        type: CacheActionTypes.SET_MULTIPLE_CACHE_DATA,
        payload,
    }),

    clearCacheByType: (payload) => (dispatch, getState) => {
        const { type } = payload;
        const reducerCache = getState().CacheReducer.cache;
        let newCache = {};

        Object.entries(reducerCache).forEach((entry) => {
            newCache = {
                ...newCache,
                [entry[0]]: entry[1].type === type ? {} : entry[1],
            };
        });
        dispatch(CacheActions.setMultipleCacheData({ cache: newCache }));
    },

    clearCacheByURL: (payload) => (dispatch, getState) => {
        const { url } = payload;
        const reducerCache = getState().CacheReducer.cache;
        let newCache = {};
        Object.entries(reducerCache).forEach((entry) => {
            const currentUrl = entry[0].split("_")[1]; // takes the second part. keys looks like cache_url/url_qParams
            newCache = {
                ...newCache,
                [entry[0]]: currentUrl === url ? {} : entry[1],
            };
        });
        dispatch(CacheActions.setMultipleCacheData({ cache: newCache }));
    },
};

export default CacheActions;
