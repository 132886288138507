import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvDivider, EvIcon, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/ev-landing-page-preview-card.scss";

const styles = {
    container: "ev__ev-landing-page-preview-card__container",
    card: {
        container: "ev__ev-landing-page-preview-card__card-container",
        imageView: "ev__ev-landing-page-preview-card__card-image-view",
        backButtonContainer:
            "ev__ev-landing-page-preview-card__card-back-button-container",
        textContainer: "ev__ev-landing-page-preview-card__card-text-container",
        subHeaderText: "ev__ev-landing-page-preview-card__card-sub-header-text",
    },
    description: {
        container: "ev__ev-landing-page-preview-card__description-container",
        text: "ev__ev-landing-page-preview-card__description-text",
    },
    divider: "ev__ev-landing-page-preview-card__divider",
    clientLogo: {
        container: "ev__ev-landing-page-preview-card__client-logo-container",
        logo: "ev__ev-landing-page-preview-card__client-logo-logo",
    },
};

const EvLandingPagePreviewCard = (props) => {
    const {
        header,
        subHeader,
        descriptionHeader,
        description,
        bgImage,
        className,
        clientLogo,
    } = props;

    const getCardView = () => (
        <div className={styles.card.container}>
            <div
                className={styles.card.imageView}
                style={{ backgroundImage: `url(${bgImage})` }}
            />
            <div className={styles.card.backButtonContainer}>
                <EvIcon
                    iconName="ARROW_THICK_LEFT"
                    fillColor={COLORS.WHITE}
                    size={3}
                />
            </div>
            <div className={styles.card.textContainer}>
                <EvText headingSecondary>{header}</EvText>
                <EvText className={styles.card.subHeaderText}>
                    {subHeader}
                </EvText>
            </div>
        </div>
    );

    const getDescriptionView = () => (
        <div className={styles.description.container}>
            <EvText defaultDarkBold>{descriptionHeader}</EvText>
            <EvText className={styles.description.text}>{description}</EvText>
        </div>
    );

    const getClientLogoView = () => (
        <div className={styles.clientLogo.container}>
            <div
                className={styles.clientLogo.logo}
                style={{ backgroundImage: `url(${clientLogo})` }}
            />
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {getCardView()}
            {getDescriptionView()}
            <EvDivider className={styles.divider} />
            {getClientLogoView()}
        </div>
    );
};

EvLandingPagePreviewCard.propTypes = {
    header: PropTypes.string,
    subHeader: PropTypes.string,
    descriptionHeader: PropTypes.string,
    description: PropTypes.string,
    bgImage: PropTypes.string,
    clientLogo: PropTypes.string,
    className: PropTypes.string,
};

EvLandingPagePreviewCard.defaultProps = {
    header: "",
    subHeader: "",
    descriptionHeader: "",
    description: "",
    bgImage: "",
    clientLogo: "",
    className: "",
};

export default EvLandingPagePreviewCard;
