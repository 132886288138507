export const getCampaignsURL = () => {
    if (window.location.hostname === "dev-campaigns.ehealthreminders.net") {
        return "https://campaigns.dev.evive.io/";
    }

    if (window.location.hostname === "ehealthreminders.net") {
        return "https://campaigns.uat.evive.io/";
    }

    return "https://campaigns.evive.io/";
};
