const GAMEDAY_CONTENT_APP_ID = "vendorPrograms__gamedayContent";

const GAMEDAY_CONTENT_PAGES = {
    DASHBOARD: {
        id: "gamedayContent__dashboard",
        path: "/cc/client/manage-programs/customize-programs/gameday-content",
    },
    GAME: {
        id: "gamedayContent__game",
        path:
            "/cc/client/manage-programs/customize-programs/gameday-content/game",
    },
    GAME_CONFIGURE: {
        id: "gamedayContent__gameConfigure",
        path:
            "/cc/client/manage-programs/customize-programs/gameday-content/configure",
    },
};

const GAMEDAY_CONTENT_TEMPLATES = {
    DASHBOARD_STATIC: {
        id: "gamedayContent__dashboardStatic",
    },
    GAME_STATIC: {
        MEMORY: {
            id: "gamedayContent__gameStaticMemory",
        },
        PICK_N_WIN: {
            id: "gamedayContent__gameStaticPickNWin",
        },
        LOST_LETTERS: {
            id: "gamedayContent__gameStaticLostLetters",
        },
    },
    CONFIGURE_STATIC: {
        id: "gamedayContent__configureStatic",
    },
};

const GAMEDAY_CONTENT_COMPONENTS = {
    GAME_LIST: {
        id: "gamedayContent__gameList",
    },
    GAME_DATA: {
        id: "gamedayContent__gameData",
    },
    GAME_CONFIGURATION: {
        id: "gamedayContent__gameConfiguration",
    },
};

const GAMEDAY_CONTENT_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
};

export {
    GAMEDAY_CONTENT_APP_ID,
    GAMEDAY_CONTENT_PAGES,
    GAMEDAY_CONTENT_COMPONENTS,
    GAMEDAY_CONTENT_TEMPLATES,
    GAMEDAY_CONTENT_STATICS,
};
