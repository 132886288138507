import React from "react";
import PropType from "prop-types";

import { EvText } from "../index";
import { getFirstIdFromSelectedFormData } from "../../../utils/FormUtils";

import "../../styles/form-components/ev-form-string-lable.scss";

const styles = {
    container: "ev__ev-form-label__container",
    text: "ev__ev-form-label__text",
};

const EvFormStringLabel = (props) => {
    const { formItem, formData } = props;

    const displayStringKey = getFirstIdFromSelectedFormData(formData);
    const displayObject = displayStringKey
        ? formData[displayStringKey].value
        : "";
    let displayString = "";

    // NOTE: currently object is not used anywhere. string is used in client details has codes

    // for places where it has full id,name,value object
    if (typeof displayObject === "object") {
        displayString = displayObject.name
            ? displayObject.name
            : displayObject.value || "";
    } else {
        // for some places it is a direct string
        displayString = displayObject;
    }

    const getContent = () => {
        if (displayString) {
            return <EvText className={styles.text}>{displayString}</EvText>;
        }
        return (
            <EvText italics className={styles.text}>
                {formItem.placeholder}
            </EvText>
        );
    };

    return <div className={styles.container}>{getContent()}</div>;
};

EvFormStringLabel.propTypes = {
    keyName: PropType.string,
    formItem: PropType.object,
    formData: PropType.object,
    options: PropType.object,
};

EvFormStringLabel.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    options: {},
};

export default EvFormStringLabel;
