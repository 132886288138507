import axios from "axios";
import { getCampaignsURL } from "../utils/getCampaignsURL";
import { apiGet } from "./api";

const CACHE = new Map();

export const getSecurityToken = async () => {
    if (CACHE.has("accessToken")) {
        return CACHE.get("accessToken");
    }

    const response = await apiGet("/cc/api/security/api/token");

    if (response.data.responseStatus === "SUCCESS") {
        CACHE.set("accessToken", response.data.data.token);

        return response.data.data.token;
    }

    throw new Error("Unable to retrieve access token");
};

export const campaignsAPI = axios.create({
    baseURL: getCampaignsURL(),
    headers: {
        "Content-Type": "application/json",
    },
});

campaignsAPI.interceptors.request.use(
    (config) => {
        return getSecurityToken().then((accessToken) => {
            config.headers["Authorization"] = accessToken;

            return Promise.resolve(config);
        });
    },
    (error) => {
        return Promise.reject(error);
    }
);
