import React, { memo } from "react";
import PropTypes from "prop-types";

import { isNullOrEmpty } from "../../../utils/CommonUtils";
import FormValidation from "../../../utils/FormValidations";
import { EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/form-components/ev-color-text-picker.scss";

const EvColorTextPicker = (props) => {
    const styles = {
        container: "ev__ev-color-text-picker__container",
        innerContainer: "ev__ev-color-text-picker__inner-container",
        errorContainer: "ev__ev-color-text-picker__error-container",
        colorContainer: "ev__ev-color-text-picker__color-container",
        inputContainer: "ev__ev-color-text-picker__input-container",
        hashLabel: "ev__ev-color-text-picker__hash-label",
        inputBox: "ev__ev-color-text-picker__input-box",
    };

    const {
        keyName,
        handleInputChange,
        value,
        placeholder,
        editable,
        type,
        formData,
        formItem,
        showError,
    } = props;

    const onChange = (e) => {
        const inputFieldObject = {
            [keyName]: {
                key: keyName,
                value: e.target.value.trim().substr(0, 7).toUpperCase(),
                // type: type,
            },
            errorObject: {},
            formItemType: type,
        };
        // handleInputChange(keyName, inputFieldObject);
        handleInputChange(keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
            },
        });
    };

    const onBlur = (e) => {
        const inputFieldObject = {
            [keyName]: {
                key: keyName,
                value: e.target.value.trim().substr(0, 7).toUpperCase(),
                // type: type,
            },
            errorObject: {},
            formItemType: type,
        };
        // handleInputChange(keyName, inputFieldObject);
        handleInputChange(keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    };

    const getColorView = () => (
        <div
            className={styles.colorContainer}
            style={{ backgroundColor: `${value || placeholder}` }}
        />
    );

    const getInputView = () => (
        <div className={styles.inputContainer}>
            {/* <span className={styles.hashLabel}>#</span> */}
            <input
                placeholder={placeholder}
                className={styles.inputBox}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={!editable}
            />
        </div>
    );

    const getErrorView = () => {
        if (
            isNullOrEmpty(formData.errorObject) ||
            !formData.errorObject.isUsed
        ) {
            return null;
        }
        return Object.keys(formData.errorObject.errorMessages).map((err) => (
            <div>
                <EvText color={COLORS.RED_MONZA} smallBold>
                    {formData.errorObject.errorMessages[err]}
                </EvText>
            </div>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                {getColorView()}
                {getInputView()}
            </div>
            <div className={styles.errorContainer}>
                {showError && getErrorView()}
            </div>
        </div>
    );
};

EvColorTextPicker.propTypes = {
    keyName: PropTypes.string,
    type: PropTypes.string,
    // label: PropTypes.string,
    // className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    editable: PropTypes.bool,
    showError: PropTypes.bool,
    formData: PropTypes.object,
    formItem: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvColorTextPicker.defaultProps = {
    keyName: "",
    type: "",
    // label: "",
    // className: "",
    placeholder: "",
    value: "",
    editable: true,
    showError: true,
    formData: {},
    formItem: {},

    handleInputChange: () => {},
};

export default memo(EvColorTextPicker);
