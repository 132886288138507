import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
    EvFormDescriptionText,
    EvFormResolver,
    EvFormTemplateRenderer,
} from ".";
import { EvText } from "..";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import {
    resetSubFormObjects,
    getSelectedSubFormItemData,
} from "../../../utils/FormUtils";
import { FORM_FIELD_TYPES } from "../../static/Enums";

import "../../styles/form-components/ev-form-renderer.scss";

const EvFormRenderer = (props) => {
    const styles = {
        container: "ev__ev-form-renderer__container",
        itemContainer: "ev__ev-form-renderer__item-container",
        labelContainer: "ev__ev-form-renderer__label-container",
        labelText: "ev__ev-form-renderer__label-text",
        descriptionContainer: "ev__ev-form-renderer__description-container",
        inputContainer: "ev__ev-form-renderer__input-container",
        subFormContainer: "ev__ev-form-renderer__sub-form-container",
    };

    const {
        formFields,
        formData,
        initialFormData,
        dataOptions,
        onFormDataChange,
        callbackValues,
        setFormDataState,
        qParams,

        inputContainerClassName,
        labelContainerClassName,
    } = props;

    const onFormDataChangeLocal = (key, newFormData, formItem) => {
        // remove and add new sub form data
        let newFormItemObject = { [key]: newFormData };
        if (!isNullOrEmpty(formItem.subForm)) {
            newFormItemObject = resetSubFormObjects(
                key,
                newFormItemObject,
                formItem,
                initialFormData
            );
        }
        onFormDataChange(key, newFormItemObject, callbackValues);
        setFormDataState({
            ...formData,
            ...newFormItemObject,
        });
    };

    const getLabelView = (formItem) => {
        const customContainerClass = classNames(
            styles.labelContainer,
            labelContainerClassName
        );
        return (
            <div className={customContainerClass}>
                {formItem.label && (
                    <EvText className={styles.labelText} defaultDark>
                        {formItem.label}
                    </EvText>
                )}
                {formItem.description && (
                    <EvText smallNormal className={styles.descriptionContainer}>
                        {formItem.description}
                    </EvText>
                )}
            </div>
        );
    };

    const getInputView = (formItem) => {
        const customContainerClass = classNames(
            styles.inputContainer,
            inputContainerClassName
        );
        return (
            <div key={formItem.id} className={customContainerClass}>
                <EvFormResolver
                    keyName={formItem.id}
                    formItem={formItem}
                    type={formItem.type}
                    handleInputChange={onFormDataChangeLocal}
                    formData={formData[formItem.id]}
                    fullFormData={formData}
                    dataOptions={dataOptions[formItem.id]}
                    qParams={qParams}
                />
            </div>
        );
    };

    const getSubFormView = (parentId, subForm) => {
        // this is an array of form items
        const subFormItems = getSelectedSubFormItemData(
            subForm,
            formData[parentId]
        );
        // check there are sub forms to render
        if (isNullOrEmpty(subFormItems)) {
            return <span />;
        }
        // ATTENTION! this creates a recursive loops. that is intended
        return (
            <div className={styles.subFormContainer}>
                {/* eslint-disable-next-line no-use-before-define */}
                {getFormItemsFromArray(subFormItems)}
            </div>
        );
    };

    const getFormItem = (formItem) => {
        // do no add this class if type hidden, else it adds extra space
        const itemContainerStyle = classNames({
            [styles.itemContainer]:
                formItem.type !== FORM_FIELD_TYPES.HIDDEN_TEXT,
        });

        // for DATE TIME ZONE, the parent form will not be there, in that case it should continue to the normal form render
        // hence "formData[formItem.id] &&" check is added
        if (
            formData[formItem.id] &&
            formData[formItem.id].isVisible === false
        ) {
            return <span />;
        }

        switch (formItem.type) {
            case FORM_FIELD_TYPES.FORM_TEMPLATE:
                return (
                    <div key={formItem.id}>
                        <div className={itemContainerStyle}>
                            <EvFormTemplateRenderer
                                keyName={formItem.id}
                                formItem={formItem}
                                handleInputChange={onFormDataChangeLocal}
                                formData={formData[formItem.id]}
                                initialFormData={initialFormData}
                                qParams={qParams}
                            />
                        </div>
                    </div>
                );

            case FORM_FIELD_TYPES.DESCRIPTION_TEXT:
                return (
                    <div key={formItem.id}>
                        <div className={itemContainerStyle}>
                            <EvFormDescriptionText
                                keyName={formItem.id}
                                formItem={formItem}
                                formData={formData[formItem.id]}
                            />
                        </div>
                    </div>
                );

            default:
                return (
                    <div key={formItem.id}>
                        <div className={itemContainerStyle}>
                            {getLabelView(formItem)}
                            {getInputView(formItem)}
                        </div>
                        {!isNullOrEmpty(formItem.subForm) &&
                            getSubFormView(formItem.id, formItem.subForm)}
                    </div>
                );
        }
    };

    // this is called in multiple places, it should have its own local array
    const getFormItemsFromArray = (formFieldsArray) =>
        formFieldsArray.map(getFormItem);

    // console.log("RENDER", formData);

    return <div>{getFormItemsFromArray(formFields)}</div>;
};

EvFormRenderer.propTypes = {
    callbackValues: PropTypes.object,
    formFields: PropTypes.array,
    formData: PropTypes.object,
    initialFormData: PropTypes.object,
    qParams: PropTypes.object,
    dataOptions: PropTypes.object,
    inputContainerClassName: PropTypes.string,
    labelContainerClassName: PropTypes.string,

    setFormDataState: PropTypes.func,
    onFormDataChange: PropTypes.func,
};

EvFormRenderer.defaultProps = {
    callbackValues: {},
    formFields: [],
    formData: {},
    initialFormData: {},
    qParams: {},
    dataOptions: {},
    inputContainerClassName: "",
    labelContainerClassName: "",

    setFormDataState: () => {},
    onFormDataChange: () => {},
};

export default EvFormRenderer;
