import React, { memo } from "react";
import PropTypes from "prop-types";

import { EvText, EvIcon, EvButton } from ".";
import { COLORS } from "../static/VmsStatics";
import { ICON_NAMES } from "../static/Enums";

import "../styles/ev-check-box.scss";

/**
 * CheckBox component for VMS
 */
const EvCheckBox = (props) => {
    const styles = {
        container: "ev__ev-check-box__container",
        iconContainer: "ev__ev-check-box__icon-container",
    };

    const { id, keyName, value, label, isChecked, handleCheckBox } = props;

    const handleChecked = () => {
        handleCheckBox(id, keyName, value, !isChecked);
    };

    const getEvCheckBoxButton = () => (
        <div className={styles.container}>
            <div className={styles.iconContainer}>
                <EvButton onlyChild onClickHandler={handleChecked}>
                    {isChecked ? (
                        <EvIcon
                            iconName={ICON_NAMES.CHECKBOX_CHECK}
                            size={3}
                            fillColor={COLORS.PRODUCT}
                        />
                    ) : (
                        <EvIcon
                            iconName={ICON_NAMES.CHECKBOX_UNCHECK}
                            strokeColor={COLORS.PRODUCT}
                            fillColor={COLORS.WHITE}
                            size={3}
                        />
                    )}
                </EvButton>
            </div>
            {label && <EvText default>{label}</EvText>}
        </div>
    );

    return getEvCheckBoxButton();
};

EvCheckBox.propTypes = {
    /** id of the checkbox */
    id: PropTypes.string.isRequired,
    /** key of the checkbox */
    keyName: PropTypes.string.isRequired,
    /** value of the checkbox */
    value: PropTypes.string.isRequired,
    /** label of the checkbox(optional) */
    label: PropTypes.string,

    /** boolean for evaluating if the checkbox is checked */
    isChecked: PropTypes.bool,

    /** function to handle checkbox */
    handleCheckBox: PropTypes.func.isRequired,
};

EvCheckBox.defaultProps = {
    label: "",

    isChecked: false,
};

export default memo(EvCheckBox);
