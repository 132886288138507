import { createSelector } from "reselect";

const userStaticDataStateSelector = (state) =>
    state.AppContainerReducer.staticData;

const userDataStateSelector = (state) => state.AppContainerReducer.userData;

const getUserBarDataSelector = createSelector(
    userStaticDataStateSelector,
    userDataStateSelector,
    (userStaticData, userData) => ({
        logoUrl: userStaticData.productLogo,
        userId: userData.emailId,
        notificationData: userData.notificationData,
    })
);

const dummy = null;

export { getUserBarDataSelector, dummy };
