const GAMEDAY_SCHEDULE_PAGES = {
    DASHBOARD: {
        id: "gamedayScehdule__dashboard",
    },
    SELECTION: {
        id: "gamedayScehdule__selction",
    },
    CONTENT: {
        id: "gamedayScehdule__content",
    },
    TIME: {
        id: "gamedayScehdule__time",
    },
    SUMMARY: {
        id: "gamedayScehdule__summary",
    },
};

const GAMEDAY_SCHEDULE_TEMPLATES = {
    DASHBOARD: {
        id: "gamedayScheduleTemplates__dashboard",
    },
    SELECTION: {
        id: "gamedayScheduleTemplates__selection",
    },
    CONTENT: {
        id: "gamedayScheduleTemplates__content",
    },
    TIME: {
        id: "gamedayScheduleTemplates__time",
    },
    SUMMARY: {
        id: "gamedayScheduleTemplates__summary",
    },
};

const GAMEDAY_SCHEDULE_COMPONENTS = {
    SCHEDULED_GAMES: {
        id: "gamedayScheduleComponents__scheduledGames",
    },
    GAME_LIST: {
        id: "gamedayScheduleTemplates__gameList",
    },
    GAME_CONTENT_PREFILL: {
        id: "gamedayScheduleTemplates__gameContentPrefill",
    },
    GAME_TIME: {
        id: "gamedayScheduleTemplates__gameTime",
    },
    SUMMARY_DATA: {
        id: "gamedayScheduleTemplates__summaryData",
    },
};

const CLIENT_GAME_CARDS_TYPE = {
    CHECKED: "CHECKED", // shown
    UNCHECKED: "UNCHECKED", // hidden
};

const GAMEDAY_SCHEDULE_STATIC = {
    backButtonText: "Back",
    FORM_INVALID: "Some of the form values are not valid",
};

export {
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_TEMPLATES,
    GAMEDAY_SCHEDULE_COMPONENTS,
    CLIENT_GAME_CARDS_TYPE,
    GAMEDAY_SCHEDULE_STATIC,
};
