import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import API_URLS from "../../../services/apiUrls";
import { getData } from "../service/NotificationsService";
import { LOADING_STATUS, POPULATION_TYPES } from "../../../common/static/Enums";
import {
    NOTIFICATIONS_COMPONENTS,
    NOTIFICATIONS_PAGES,
} from "../static/NotificationsStatics";
import NotificationsActions from "../redux/NotificationsActions";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import {
    EvButton,
    EvText,
    EvSimpleCard,
    EvLoadingPage,
} from "../../../common/components";

import "../styles/notifications-target-population.scss";

const NotificationsTargetPopulation = (props) => {
    const styles = {
        header: "ev__target-population__header",
        headerText: "ev__target-population__header-text",
        actionView: {
            container: "ev__target-population__action-view-container",
            primary: "ev__target-population__action-view-primary",
            secondary: "ev__target-population__action-view-secondary",
            buttons: "ev__target-population__action-view-buttons",
        },
        currentPopulation: {
            container: "ev__target-population__current-population-container",
        },
        savedPopulation: {
            header: "ev__target-population__saved-population-header",
            infoText: "ev__target-population__saved-population-info-text",
            wrapper: "ev__target-population__saved-population-wrapper",
            card: "ev__target-population__saved-population-card",
            cardWrapper: "ev__target-population__saved-population-card-wrapper",
            populationName:
                "ev__target-population__saved-population-card-population-name",
        },
        loadMoreButton: "ev__target-population__load-more-button",
    };

    // const initialNumToRender = 6;

    const {
        clientCode,
        controls,
        staticData,
        getStaticData,
        setControls,
        setDynamicDataApiStatus,
    } = props;

    const [savedPopulationData, setSavedPopulationData] = useState({});

    const [savedPopulationDataApiStatus, setSavedPopulationDataApiStatus] =
        useState(LOADING_STATUS.NOT_YET_STARTED);

    // const [isExpanded, setIsExpanded] = useState(false);

    const getStaticDataLocal = useCallback(
        () =>
            getStaticData({
                url: API_URLS.NOTIFICATIONS.TARGET_POPULATION.STATIC,
                templateId: NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
            }),
        [getStaticData]
    );

    const getDynamicData = useCallback(
        () =>
            getData(
                API_URLS.NOTIFICATIONS.TARGET_POPULATION.SAVED_POPULATION_DATA,
                {
                    notificationId: controls.currentNotificationId,
                    clientCode,
                }
            ),
        [clientCode, controls.currentNotificationId]
    );

    useEffect(() => {
        setSavedPopulationDataApiStatus(LOADING_STATUS.LOADING);
        Promise.all([getStaticDataLocal(), getDynamicData()]).then(
            ([staticDataResponse, dynamicDataResponse]) => {
                setSavedPopulationData(dynamicDataResponse.data.data);
                setSavedPopulationDataApiStatus(LOADING_STATUS.COMPLETED);
            }
        );
    }, [
        getStaticDataLocal,
        getDynamicData,
        setSavedPopulationData,
        setSavedPopulationDataApiStatus,
    ]);

    const isLoading = () =>
        !isCompleted(
            controls.staticDataApiStatus[
                NOTIFICATIONS_PAGES.TARGET_POPULATION.id
            ],
            savedPopulationDataApiStatus
        );

    const onPopulationCardClick = useCallback(
        (e, callbackValues) => {
            const { populationData, isNewPopulation } = callbackValues;
            // clear  population criteria list data, it only clears from here
            // it should not clear when landing to new population page from tag selection
            setDynamicDataApiStatus({
                [NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
            setControls({
                selectedPageId:
                    populationData.type === POPULATION_TYPES.CSV
                        ? NOTIFICATIONS_PAGES.UPLOAD_POPULATION.id
                        : NOTIFICATIONS_PAGES.NEW_POPULATION.id,
                currentNotificationPopulationId: populationData.id || "",
                isNewPopulation: isNewPopulation || "",
            });
        },
        [setControls, setDynamicDataApiStatus]
    );

    const onUploadPopulationClick = useCallback(() => {
        setControls({
            selectedPageId: NOTIFICATIONS_PAGES.UPLOAD_POPULATION.id,
        });
    }, [setControls]);

    const onSkipClick = useCallback(() => {
        setControls({
            selectedPageId: NOTIFICATIONS_PAGES.SCHEDULE.id,
        });
    }, [setControls]);

    // const onLoadMore = () => {
    //     setIsExpanded(true);
    // };

    const getHeaderView = () => (
        <div className={styles.header}>
            <EvText className={styles.headerText} subHeading>
                {staticData.header}
            </EvText>
            <EvText className={styles.headerText} defaultDark>
                {staticData.description}
            </EvText>
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <div className={styles.actionView.primary}>
                <EvButton
                    onClickHandler={onPopulationCardClick}
                    primaryFilled
                    callbackValues={{
                        populationData: {},
                        isNewPopulation: true,
                    }}
                    className={styles.actionView.buttons}
                >
                    {staticData.newPopulationCta.text}
                </EvButton>
                <EvButton
                    className={styles.actionView.buttons}
                    secondary
                    onClickHandler={onUploadPopulationClick}
                >
                    {staticData.populationUploadCta.text}
                </EvButton>
            </div>
            <div className={styles.actionView.secondary}>
                {!isNullOrEmpty(savedPopulationData.currentPopulation) && (
                    <EvButton textButton onClickHandler={onSkipClick}>
                        {staticData.skipCta.text}
                    </EvButton>
                )}
            </div>
        </div>
    );

    // const getSavedPopulationHeader = () => (
    //     <div className={styles.savedPopulation.header}>
    //         <EvText defaultDark>{staticData.savedListHeader}</EvText>
    //     </div>
    // );

    const getSavedPopulationCard = (populationData) => (
        <EvButton
            key={populationData.id}
            onClickHandler={onPopulationCardClick}
            onlyChild
            className={styles.savedPopulation.cardWrapper}
            callbackValues={{ populationData, isNewPopulation: false }}
        >
            <EvSimpleCard className={styles.savedPopulation.card}>
                <EvText
                    defaultDarkBold
                    className={styles.savedPopulation.populationName}
                >
                    {populationData.title}
                </EvText>
                {populationData.lastUsed && (
                    <EvText smallNormal>
                        {staticData.lastSavedText}
                        {populationData.lastUsed}
                    </EvText>
                )}
            </EvSimpleCard>
        </EvButton>
    );

    // const getSavedPopulationList = () => {
    //     // empty population list
    //     if (savedPopulationData.savedPopulationList.length <= 0) {
    //         return (
    //             <EvText
    //                 className={styles.savedPopulation.infoText}
    //                 italics
    //                 smallNormal
    //             >
    //                 {staticData.noPopulationMessage}
    //             </EvText>
    //         );
    //     }
    //     if (!isExpanded) {
    //         return savedPopulationData.savedPopulationList
    //             .slice(0, initialNumToRender)
    //             .map(getSavedPopulationCard);
    //     }
    //     return savedPopulationData.savedPopulationList.map(
    //         getSavedPopulationCard
    //     );
    // };

    const currentPopulationSelectionView = () => {
        if (!isNullOrEmpty(savedPopulationData.currentPopulation)) {
            return (
                <div className={styles.currentPopulation.container}>
                    <EvText defaultDark>
                        {staticData.continuePopulationHeader}
                    </EvText>
                    {getSavedPopulationCard(
                        savedPopulationData.currentPopulation
                    )}
                </div>
            );
        }
        return <span />;
    };

    // const getLoadMoreButton = () => (
    //     <div className={styles.loadMoreButton}>
    //         <EvButton
    //             secondary
    //             buttonInlineStyle={{
    //                 flex: 1,
    //                 height: "5rem",
    //             }}
    //             onClickHandler={onLoadMore}
    //         >
    //             {staticData.loadMoreCta.text}
    //         </EvButton>
    //     </div>
    // );

    const renderPageContent = () => (
        <div>
            {getHeaderView()}
            {getActionView()}
            {/* to show current population card */}
            {/* {!isNullOrEmpty(savedPopulationData.currentPopulation) && */}
            {/*  getSavedPopulationCard(savedPopulationData.currentPopulation)} */}
            {currentPopulationSelectionView()}
            {/*{getSavedPopulationHeader()}*/}
            {/*<div className={styles.savedPopulation.wrapper}>*/}
            {/*    {getSavedPopulationList()}*/}
            {/*</div>*/}
            {/*{!isExpanded &&*/}
            {/*    savedPopulationData.savedPopulationList.length >*/}
            {/*        initialNumToRender &&*/}
            {/*    getLoadMoreButton()}*/}
        </div>
    );

    return isLoading() ? <EvLoadingPage /> : renderPageContent();
};

NotificationsTargetPopulation.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    controls: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

NotificationsTargetPopulation.defaultProps = {
    clientCode: "",
    staticData: {},
    controls: {},

    getStaticData: () => {},
    setControls: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATIONS_PAGES.TARGET_POPULATION.id
        ],
    controls: state.NotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsTargetPopulation);
