import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/ev-banner-preview-card.scss";
import { EvSimpleCard, EvText } from "../../../common/components";

const styles = {
    container: "ev__banner-preview-card__container",
    cardClass: "ev__banner-preview-card__card-class",
    innerContainer: "ev__banner-preview-card__inner-container",
    header: {
        container: "ev__banner-preview-card__header-container",
    },
    content: {
        container: "ev__banner-preview-card__content-container",
        imageView: "ev__banner-preview-card__content-image-view",
        textContainer: "ev__banner-preview-card__content-text-container",
        descriptionText: "ev__banner-preview-card__content-description-text",
    },
};

const EvBannerPreviewCard = (props) => {
    const { header, description, bgImage, className } = props;

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText subHeading>{header}</EvText>
        </div>
    );

    const getContentView = () => (
        <div className={styles.content.container}>
            <div
                className={styles.content.imageView}
                style={{ backgroundImage: `url(${bgImage})` }}
            />
            <div className={styles.content.textContainer}>
                <EvText
                    headingSecondary
                    className={styles.content.descriptionText}
                >
                    {description}
                </EvText>
            </div>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding className={styles.cardClass}>
                <div className={styles.innerContainer}>
                    {getHeaderView()}
                    {getContentView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvBannerPreviewCard.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    bgImage: PropTypes.string,
    className: PropTypes.string,
};

EvBannerPreviewCard.defaultProps = {
    header: "",
    description: "",
    bgImage: "",
    className: "",
};

export default EvBannerPreviewCard;
