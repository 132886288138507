import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";

import { isNullOrEmpty, removeKeyFromObject } from "../../../utils/CommonUtils";
import FormValidation from "../../../utils/FormValidations";
import { EvButton, EvIcon, EvLoader, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";
import { reservedKeywordCheck } from "../../../utils/FormUtils";
import { ICON_NAMES, LOADING_STATUS } from "../../static/Enums";
import { getData } from "../../service/CommonService";
import EvLogger from "../../../utils/EvLogger";

import "../../styles/form-components/ev-checklist-dropdown.scss";
import { EvInputErrorView } from "./index";

const DATA_SOURCE_TYPE = {
    FROM_EXTERNAL_URL: "FROM_EXTERNAL_URL", // not yet implemented
    FROM_PROPS: "FROM_PROPS", // not yet implemented
    INTERNAL: "INTERNAL",
};

const styles = {
    container: "ev__ev-checklist-dropdown__container",
    dropdown: {
        container: "ev__ev-checklist-dropdown__dropdown-container",
        infoContainer: "ev__ev-checklist-dropdown__dropdown-info-container",
        placeholderText: "ev__ev-checklist-dropdown__dropdown-placeholder-text",
        button: "ev__ev-checklist-dropdown__dropdown-button",
        buttonOpen: "ev__ev-checklist-dropdown__dropdown-button-open",
        buttonDisabled: "ev__ev-checklist-dropdown__dropdown-button-disabled",
        icon: "ev__ev-checklist-dropdown__dropdown-icon",
    },
    menu: {
        container: "ev__ev-checklist-dropdown__menu-container",
    },
    items: {
        container: "ev__ev-checklist-dropdown__items-container",
        noItemsText: "ev__ev-checklist-dropdown__items-no-item-text",
        removeIcon: "ev__ev-checklist-dropdown__items-remove-icon",
        animationWrapper: "ev__ev-checklist-dropdown__items-animation-wrapper",
    },
    menuItem: {
        container: "ev__ev-checklist-dropdown__menu-item-container",
        valueText: "ev__ev-checklist-dropdown__menu-item-value-text",
    },
};

const EvChecklistDropdownItem = (props) => {
    const { data, isSelected, onChange } = props;

    const getIcon = () =>
        isSelected ? (
            <EvIcon
                iconName={ICON_NAMES.CHECKBOX_CHECK}
                fillColor={COLORS.PRODUCT}
                size={3}
            />
        ) : (
            <EvIcon
                iconName={ICON_NAMES.CHECKBOX_UNCHECK}
                strokeColor={COLORS.PRODUCT}
                fillColor={COLORS.WHITE}
                size={3}
            />
        );

    return (
        <EvButton
            onClickHandler={onChange}
            onlyChild
            className={styles.menuItem.container}
            callbackValues={{
                ...data,
                isSelected,
            }}
        >
            {getIcon()}
            <EvText className={styles.menuItem.valueText}>{data.name}</EvText>
        </EvButton>
    );
};

EvChecklistDropdownItem.propTypes = {
    data: PropTypes.object,
    isSelected: PropTypes.bool,
    onChange: PropTypes.func,
};

EvChecklistDropdownItem.defaultProps = {
    data: {},
    isSelected: false,
    onChange: () => {},
};

const EvChecklistDropdown = (props) => {
    const {
        keyName,
        formItem,
        formData,
        placeholder,
        handleInputChange,
        editable,
        showError,
    } = props;

    const dropdownContainerRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [dataList, setDataList] = useState(formItem.data || []);
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    useEffect(() => {
        if (formItem.options && formItem.options.dataSourceType) {
            switch (formItem.options.dataSourceType) {
                case DATA_SOURCE_TYPE.INTERNAL:
                    // already initialized
                    setLoadingStatus(LOADING_STATUS.COMPLETED);
                    break;
                case DATA_SOURCE_TYPE.FROM_PROPS:
                    // yet to implement
                    break;
                case DATA_SOURCE_TYPE.FROM_EXTERNAL_URL:
                    setLoadingStatus(LOADING_STATUS.LOADING);
                    getData(formItem.options.listDataEndpoint)
                        .then((listDataResponse) => {
                            setDataList(
                                listDataResponse.data.data[
                                    formItem.options.listTargetKey
                                ]
                            );
                            setLoadingStatus(LOADING_STATUS.COMPLETED);
                        })
                        .catch((e) => {
                            setLoadingStatus(LOADING_STATUS.FAILED);
                            EvLogger.errorWithObject(
                                e,
                                "EvChecklistDropdown loadDataList from external url"
                            );
                        });
                    break;

                default:
                // do nothing
            }
        }
    }, [formItem.options]);

    const onDropdownButtonClick = useCallback(() => {
        if (editable) {
            setIsOpen(!isOpen);
        }
    }, [isOpen, editable]);

    const onWindowMouseClick = (e) => {
        if (
            dropdownContainerRef.current &&
            !dropdownContainerRef.current.contains(e.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", onWindowMouseClick);
        return () => {
            document.removeEventListener("mousedown", onWindowMouseClick);
        };
    }, []);

    const onRemoveItemClick = useCallback(
        (e, callbackOptions) => {
            const { itemId } = callbackOptions;
            if (itemId) {
                const newFormData = removeKeyFromObject(itemId, formData);
                const newValues =
                    newFormData?.selectedValues?.filter(
                        (item) => item.id !== itemId
                    ) || [];
                handleInputChange(keyName, {
                    ...newFormData,
                    selectedValues: newValues,
                    errorObject: {
                        ...formData.errorObject,
                        ...FormValidation(formItem, newFormData),
                    },
                });
            }
        },
        [formData, handleInputChange, keyName, formItem]
    );

    const onSelectionChange = useCallback(
        (e, callbackValues) => {
            if (callbackValues.isSelected) {
                onRemoveItemClick(null, { itemId: callbackValues.id });
            } else {
                const data = {
                    key: callbackValues.id,
                    value: callbackValues.value,
                    name: callbackValues.name,
                    type: formItem.type,
                };
                const newFormData = {
                    ...formData,
                    [callbackValues.id]: data,
                    selectedValues: [...(formData?.selectedValues || []), data],
                    errorObject: {}, // will be overwritten later
                    formItemType: formItem.type,
                };
                handleInputChange(keyName, {
                    ...newFormData,
                    errorObject: {
                        ...formData.errorObject,
                        ...FormValidation(formItem, newFormData),
                    },
                });
            }
        },
        [formData, formItem, handleInputChange, keyName, onRemoveItemClick]
    );

    const customButtonClass = classNames(styles.dropdown.button, {
        [styles.dropdown.buttonOpen]: isOpen,
        [styles.dropdown.buttonDisabled]: !editable,
    });

    const getDropdownButton = () => (
        <EvButton
            onlyChild
            className={customButtonClass}
            disabledView={!editable}
            onClickHandler={onDropdownButtonClick}
        >
            <EvText
                color={editable ? COLORS.BLACK_TROUT : COLORS.WHITE_GHOST}
                className={styles.dropdown.placeholderText}
            >
                {placeholder}
            </EvText>
            <EvIcon
                outerContainerStyleClass={styles.dropdown.icon}
                iconName="ARROW_THICK_DOWN"
                size={3}
                fillColor={editable ? COLORS.PRODUCT : COLORS.WHITE_GHOST}
            />
        </EvButton>
    );

    const getDropdownItems = (menuData) => (
        <EvChecklistDropdownItem
            key={menuData.id}
            data={menuData}
            isSelected={!isNullOrEmpty(formData[menuData.id])}
            onChange={onSelectionChange}
        />
    );

    const getDropdownInfoView = () => {
        if (loadingStatus === LOADING_STATUS.LOADING) {
            return (
                <div className={styles.dropdown.infoContainer}>
                    <EvLoader size={3} />
                </div>
            );
        } else if (loadingStatus === LOADING_STATUS.FAILED) {
            return (
                <div className={styles.dropdown.infoContainer}>
                    <EvIcon
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                        size={5}
                    />
                </div>
            );
        }
        return null;
    };

    const getDropdownMenu = () => (
        <div className={styles.menu.container}>
            {loadingStatus === LOADING_STATUS.COMPLETED &&
                dataList.map(getDropdownItems)}
            {getDropdownInfoView()}
        </div>
    );

    const getItemPill = ([itemKey, itemData]) => {
        if (reservedKeywordCheck(itemKey)) {
            return null;
        }
        const showRemoveIcon =
            editable && formItem.options.showRemoveIcon !== false;
        return (
            <CSSTransition
                key={itemKey}
                timeout={500}
                className={styles.items.animationWrapper}
                classNames={styles.items.animationWrapper}
            >
                <div>
                    <EvText>{itemData.name}</EvText>
                    {showRemoveIcon && (
                        <EvButton
                            onlyChild
                            onClickHandler={onRemoveItemClick}
                            callbackValues={{ itemId: itemKey }}
                        >
                            <EvIcon
                                iconName="CROSS_THICK"
                                size={2}
                                fillColor={COLORS.BLACK_TROUT}
                                outerContainerStyleClass={
                                    styles.items.removeIcon
                                }
                            />
                        </EvButton>
                    )}
                </div>
            </CSSTransition>
        );
    };

    const getItemList = () => (
        <TransitionGroup className={styles.items.container}>
            {Object.entries(formData).map(getItemPill)}
        </TransitionGroup>
    );

    const getNoItemView = () => {
        if (!(formItem.options && formItem.options.noItemAddedInfo)) {
            return null;
        } else if (Object.keys(formData).length > 3) {
            // 3 is for the formKeys: errors and type and selectedValues
            return null;
        }
        return (
            <EvText smallNormal italics>
                {formItem.options.noItemAddedInfo}
            </EvText>
        );
    };

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    return (
        <div className={styles.container}>
            <div
                className={styles.dropdown.container}
                ref={dropdownContainerRef}
            >
                {getDropdownButton()}
                {isOpen && getDropdownMenu()}
            </div>
            {formItem.options &&
                formItem.options.showSelectedItems &&
                getItemList()}
            {formItem.options &&
                formItem.options.showSelectedItems &&
                getNoItemView()}
            {showError && getErrorView()}
        </div>
    );
};

EvChecklistDropdown.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func,
    editable: PropTypes.bool,
    showError: PropTypes.bool,
};

EvChecklistDropdown.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    placeholder: "",
    handleInputChange: () => {},
    editable: true,
    showError: true,
};

export default EvChecklistDropdown;
