import ClientActionTypes from "./ClientActionTypes";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData } from "../../client-dashboard/service/ClientDetailsService";
import { normalizerWithOrderArray } from "../../../utils/Normalizer";
import { getClientDetailsPageIdFromPath } from "../../../utils/CommonUtils";
import API_URLS from "../../../services/apiUrls";
import EvLogger from "../../../utils/EvLogger";

const ClientActions = {
    setControls: (payload) => ({
        type: ClientActionTypes.SET_CONTROLS,
        payload,
    }),

    setData: (payload) => ({
        type: ClientActionTypes.SET_DATA,
        payload,
    }),

    fetchNavigationData: (payload) => (dispatch, getState) => {
        dispatch(
            ClientActions.setControls({
                navigationDataStatus: LOADING_STATUS.LOADING,
            })
        );
        getData(API_URLS.CLIENT_DETAILS.GET_NAVIGATION_DATA)
            .then((response) => {
                const normalizedNavigationData = normalizerWithOrderArray(
                    response.data.data.menuOptions
                );
                dispatch(
                    ClientActions.setData({
                        navigationData: {
                            data: normalizedNavigationData.value,
                            navigationOrder: normalizedNavigationData.ids,
                        },
                    })
                );
                dispatch(
                    ClientActions.setControls({
                        navigationDataStatus: LOADING_STATUS.COMPLETED,
                        navigationMenuSelectedId: getClientDetailsPageIdFromPath(
                            getState().router.location.pathname
                        ),
                    })
                );
            })
            .catch((e) => {
                ClientActions.setControls({
                    navigationDataStatus: LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(
                    e,
                    "ClientActions fetchNavigationData"
                );
            });
    },
};

export default ClientActions;
