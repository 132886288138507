import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
    EvButton,
    EvDivider,
    EvHeaderView,
    EvLoader,
    EvLoadingPage,
    EvSimpleModal,
    EvSummary,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import {
    DEFAULT_LOADING_TEXT,
    LOADING_STATUS,
} from "../../../common/static/Enums";
import CacheActions from "../../../reducers/cache/CacheActions";
import API_URLS from "../../../services/apiUrls";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import { groupCardsByType } from "../../../utils/SpecificDataModelUtils";
import VendorProgramsActions from "../../vendor-programs/redux/VendorProgramsActions";
import {
    deleteData,
    getData,
} from "../../vendor-programs/service/VendorProgramsService";
import { VENDOR_PROGRAMS_PAGES } from "../../vendor-programs/statics/VendorProgramsStatics";
import { VENDOR_PROGRAM_SUMMARY_TEMPLATES } from "../statics/VendorProgramSummaryStatics";
import "../styles/vendor-program-summary.scss";

const styles = {
    container: "ev__vendor-program-summary__container",
    header: {
        container: "ev__vendor-program-summary__header-container",
        content: "ev__vendor-program-summary__header-content",
    },
    actionView: {
        container: "ev__vendor-program-summary__action-view-container",
        button: "ev__vendor-program-summary__action-view-button",
        loading: "ev__vendor-program-summary__action-view-loading",
    },
    content: {
        container: "ev__vendor-program-summary__content-container",
        segmentWrapper: "ev__vendor-program-summary__content-segment-wrapper",
    },
};

const VendorProgramSummary = (props) => {
    const {
        controls,
        staticData,
        location,
        getStaticData,
        clearCacheByURL,
        navigateToPage,
    } = props;

    const [summaryData, setSummaryData] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [removeLoadingStatus, setRemoveLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode =
        location.state && location.state.clientCode
            ? location.state.clientCode
            : "";

    const clientName =
        location.state && location.state.clientName
            ? location.state.clientName
            : "";

    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    useEffect(() => {
        getStaticData({
            url: API_URLS.VENDOR_PROGRAMS.SUMMARY.PAGE_STATIC,
            templateId: VENDOR_PROGRAM_SUMMARY_TEMPLATES.PAGE_STATICS.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        if (isCompletedOrLoading(loadingStatus)) {
            return;
        }
        getData(API_URLS.VENDOR_PROGRAMS.SUMMARY.SUMMARY_DATA, {
            clientCode,
            vendorId: vendorCardData.vendorId,
            programId: vendorCardData.programId,
        })
            .then((summaryResponse) => {
                const groupedCardsData = groupCardsByType(
                    summaryResponse.data.data.vendorProgramCards.featureCardList
                );
                setSummaryData({
                    ...summaryResponse.data.data,
                    vendorProgramCards: {
                        ...summaryResponse.data.data.vendorProgramCards,
                        ...groupedCardsData,
                    },
                });
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "VendorProgramSummary getSummaryData"
                );
            });
    }, [loadingStatus, clientCode, vendorCardData]);

    // const onBackClick = useCallback(() => {
    //   goBackAction();
    // }, [goBackAction]);

    const onRemoveProgramConfirm = useCallback(() => {
        if (removeLoadingStatus === LOADING_STATUS.LOADING) {
            return;
        }
        setRemoveLoadingStatus(LOADING_STATUS.LOADING);
        deleteData(
            API_URLS.VENDOR_PROGRAMS.REMOVE_PROGRAM,
            {},
            {
                clientCode,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
            }
        )
            .then((removeProgramResponse) => {
                EvToast.success("", "Program Removed", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setRemoveLoadingStatus(LOADING_STATUS.COMPLETED);
                navigateToPage({
                    path: VENDOR_PROGRAMS_PAGES.DASHBOARD.path,
                    state: {
                        clientCode,
                        clientName,
                    },
                });
                clearCacheByURL({
                    url:
                        API_URLS.COMMON.LAZY_MODAL_NOTIFICATION_CONTENT
                            .LIST_DATA,
                });
            })
            .catch((e) => {
                setRemoveLoadingStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Failed to remove program");
                EvLogger.errorWithObject(
                    e,
                    "ClientProgramsContainer onRemoveProgramConfirm"
                );
            });
    }, [
        vendorCardData,
        navigateToPage,
        removeLoadingStatus,
        clearCacheByURL,
        clientCode,
        clientName,
    ]);

    const onRemoveProgramClick = useCallback(() => {
        const confirmationModalData = {
            header: staticData.removeProgramCta.confirmationModal.header,
            description:
                staticData.removeProgramCta.confirmationModal.description,
            positiveButtonText:
                staticData.removeProgramCta.confirmationModal.positiveCta.text,
            negativeButtonText:
                staticData.removeProgramCta.confirmationModal.negativeCta.text,
            onPositiveAction: onRemoveProgramConfirm,
        };
        EvSimpleModal.setData(confirmationModalData).show();
    }, [staticData, onRemoveProgramConfirm]);

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primary
                className={styles.actionView.button}
                onClickHandler={onRemoveProgramClick}
            >
                {staticData.removeProgramCta.text}
            </EvButton>
            {removeLoadingStatus === LOADING_STATUS.LOADING && (
                <EvLoader className={styles.actionView.loading} size={3} />
            )}
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvHeaderView
                className={styles.header.content}
                header={staticData.header}
                headerResolvers={vendorCardData}
                description={staticData.description}
            />
        </div>
    );

    const getSegments = (segmentStaticData) => (
        <div
            key={segmentStaticData.id}
            className={styles.content.segmentWrapper}
        >
            <EvDivider />
            <EvSummary
                id={segmentStaticData.id}
                segmentStaticData={segmentStaticData}
                data={summaryData[segmentStaticData.id]}
                editable={false}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                }}
            />
        </div>
    );

    const getContent = () => (
        <div className={styles.content.container}>
            {staticData.segments.map(getSegments)}
        </div>
    );

    if (
        !isCompleted(
            loadingStatus,
            controls.staticDataApiStatus[
                VENDOR_PROGRAM_SUMMARY_TEMPLATES.PAGE_STATICS.id
            ]
        )
    ) {
        return (
            <EvLoadingPage
                loadingText={staticData.loadingText || DEFAULT_LOADING_TEXT}
            />
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getActionView()}
            {getContent()}
        </div>
    );
};

VendorProgramSummary.propTypes = {
    staticData: PropTypes.object,
    controls: PropTypes.object,
    location: PropTypes.object,
    getStaticData: PropTypes.func,
    // goBackAction: PropTypes.func,
    clearCacheByURL: PropTypes.func,
    navigateToPage: PropTypes.func,
};

VendorProgramSummary.defaultProps = {
    controls: {},
    staticData: {},
    location: {},
    getStaticData: () => {},
    // goBackAction: () => {},
    clearCacheByURL: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    staticData:
        state.VendorProgramsReducer.staticData[
            VENDOR_PROGRAM_SUMMARY_TEMPLATES.PAGE_STATICS.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    clearCacheByURL: (payload) =>
        dispatch(CacheActions.clearCacheByURL(payload)),
    // goBackAction: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorProgramSummary);
