import { apiMock, apiPost } from "../../services/api";

import { uploadImageMockData } from "../static/components-mock/EvImageUploaderMockData";

// const USE_MOCK_DATA = true;

/* global USE_MOCK_DATA */

//-----------------------------

const postUploadImageReal = (url, qParams, data) => apiPost(url, qParams, data);

const postUploadImageMock = (url, qParams, data) =>
    apiMock(url, qParams, uploadImageMockData, data);

const postFileUpload = USE_MOCK_DATA
    ? postUploadImageMock
    : postUploadImageReal;

//-----------------------------

const dummy = null;

export { postFileUpload, dummy };
