import React, { memo, useState } from "react";
import PropTypes from "prop-types";

import { EvButton, EvIcon, EvLoader, EvText } from "..";

import { LOADING_STATUS } from "../../static/Enums";
import { COLORS } from "../../static/VmsStatics";

import { isNullOrEmpty } from "../../../utils/CommonUtils";
import FormValidation from "../../../utils/FormValidations";

import "../../styles/form-components/ev-file-uploader.scss";
/**
 * This component will be used to upload images
 */

const EvFileUploader = (props) => {
    const styles = {
        container: "ev__ev-file-uploader__container",
        innerContainer: "ev__ev-file-uploader__inner-container",
        button: "ev__ev-file-uploader__button",
        input: "ev__ev-file-uploader__input",
        iconContainer: "ev__ev-file-uploader__icon-container",
        errorContainer: "ev__ev-file-uploader__error-container",
    };
    const [isUploading, setIsUploading] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const {
        handleInputChange,
        label,
        keyName,
        type,
        showError,
        formData,
        formItem,
    } = props;

    const fileUploadRef = React.createRef(null);

    const setFileChange = (result) => {
        const inputFieldObject = {
            [keyName]: {
                key: keyName,
                value: result,
                // type: props.type,
            },
            errorObject: {},
            formItemType: type,
        };
        handleInputChange(keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    };

    const onUploadButtonClick = () => {
        // already loading some image
        if (isUploading === LOADING_STATUS.LOADING) {
            return;
        }
        if (fileUploadRef.current) {
            // reset previous content, else it will not trigger on change for same content
            fileUploadRef.current.value = "";
            fileUploadRef.current.click();
        }
    };

    const onFileChange = (e) => {
        e.preventDefault();
        if (!e.target.files[0]) {
            return; // if no file is selected
        }
        setIsUploading(LOADING_STATUS.LOADING);
        const reader = new FileReader();
        const fileName = e.target.files[0];
        reader.onloadend = () => {
            setFileChange(reader.result);
            setIsUploading(LOADING_STATUS.COMPLETED);
        };
        reader.onerror = () => {
            setIsUploading(LOADING_STATUS.FAILED);
        };
        reader.readAsText(fileName);
    };

    const getIcon = () => {
        switch (isUploading) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                // only if file content is there
                if (formData[keyName] && formData[keyName].value) {
                    return (
                        <EvIcon
                            size={3}
                            iconName="CHECK"
                            fillColor={COLORS.PRODUCT}
                        />
                    );
                }
                return <span />;

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        size={3}
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getErrorView = () => {
        if (
            isNullOrEmpty(formData.errorObject) ||
            !formData.errorObject.isUsed
        ) {
            return null;
        }
        return Object.keys(formData.errorObject.errorMessages).map((err) => (
            <div>
                <EvText color={COLORS.RED_MONZA} smallBold>
                    {formData.errorObject.errorMessages[err]}
                </EvText>
            </div>
        ));
    };

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <input
                    ref={fileUploadRef}
                    className={styles.input}
                    type="file"
                    onChange={onFileChange}
                />
                <EvButton onClickHandler={onUploadButtonClick} primary>
                    {label}
                </EvButton>
                <div className={styles.iconContainer}>{getIcon()}</div>
            </div>
            <div className={styles.errorContainer}>
                {showError && getErrorView()}
            </div>
        </div>
    );
};

EvFileUploader.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    keyName: PropTypes.string,
    formData: PropTypes.object,
    formItem: PropTypes.object,
    showError: PropTypes.bool,
    handleInputChange: PropTypes.func,
};

EvFileUploader.defaultProps = {
    label: "",
    type: "",
    keyName: "",
    formData: {},
    formItem: {},
    showError: true,
    handleInputChange: () => {},
};

export default memo(EvFileUploader);
