import ClientContentActionTypes from "./ClientContentActionTypes";

const initialState = {
    controls: {
        staticDataApiStatus: {},
        dynamicDataApiStatus: {},
    },
    staticData: {},
    dynamicData: {},
};

const ClientContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ClientContentActionTypes.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };
        case ClientContentActionTypes.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };
        case ClientContentActionTypes.SET_DYNAMIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };
        case ClientContentActionTypes.SET_STATIC_DATA_FOR_PAGE:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.page]: action.payload.data,
                },
            };
        case ClientContentActionTypes.SET_COMPONENT_DATA:
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    [action.payload.componentId]: {
                        ...state[action.payload.componentId],
                        ...action.payload.data,
                    },
                },
            };

        default:
            return state;
    }
};

export default ClientContentReducer;
