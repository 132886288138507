import { goBack } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import stringTemplate from "string-template";
import {
    EvButton,
    EvDivider,
    EvIcon,
    EvLoader,
    EvLoadingPage,
    EvText,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { EvFormRenderer } from "../../../common/components/form-components";
import CommonActions from "../../../common/redux/CommonActions";
import { getData, postData } from "../../../common/service/CommonService";
import { LOADING_STATUS, RESPONSE_STATUS } from "../../../common/static/Enums";
import { COLORS } from "../../../common/static/VmsStatics";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    GAMEDAY_CONTENT_APP_ID,
    GAMEDAY_CONTENT_COMPONENTS,
    GAMEDAY_CONTENT_PAGES,
    GAMEDAY_CONTENT_STATICS,
    GAMEDAY_CONTENT_TEMPLATES,
} from "../statics/GamedayContentStatics";
import "../styles/gameday-content-configure.scss";

const styles = {
    container: "ev__gameday-content-configure__container",
    header: {
        container: "ev__gameday-content-configure__header-container",
        headingText: "ev__gameday-content-configure__header-heading-text",
        descriptionText:
            "ev__gameday-content-configure__header-description-text",
    },
    form: {
        container: "ev__gameday-content-configure__form-container",
    },
    actionView: {
        container: "ev__gameday-content-configure__action-view-container",
        labelContainer:
            "ev__gameday-content-configure__action-view-label-container",
        buttonContainer:
            "ev__gameday-content-configure__action-view-button-container",
        button: "ev__gameday-content-configure__action-view-button",
    },
};

const GamedayContentConfigure = (props) => {
    const {
        controls,
        staticData,
        location,
        getStaticData,
        setDynamicDataApiStatus,
        goBackAction,
    } = props;

    const [formData, setFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [initialFormData, setInitialFormData] = useState({});

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const navigateToGameContentDashboard = useCallback(() => {
        goBackAction({
            path: GAMEDAY_CONTENT_PAGES.DASHBOARD.path,
            state: location.state,
        });
    }, [location, goBackAction]);

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    templateId: GAMEDAY_CONTENT_TEMPLATES.CONFIGURE_STATIC.id,
                    url: API_URLS.GAME_DAY_CONTENT.CONFIGURE.STATIC,
                }),
                getData(API_URLS.GAME_DAY_CONTENT.CONFIGURE.PREFILL, {
                    programId: vendorCardData.programId,
                    vendorId: vendorCardData.vendorId,
                    featureId: vendorCardData.featureId,
                    clientCode,
                }),
            ]),
        [
            getStaticData,
            vendorCardData.programId,
            clientCode,
            vendorCardData.featureId,
            vendorCardData.vendorId,
        ]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_CONTENT_COMPONENTS.GAME_CONFIGURATION.id]:
                LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, prefillDataResponse]) => {
                if (
                    prefillDataResponse.data.responseStatus ===
                    RESPONSE_STATUS.SUCCESS
                ) {
                    const parsedFormData = parseInitialStateFromFormFields(
                        staticDataResponse.formFields,
                        prefillDataResponse.data.data
                    );
                    setInitialFormData(prefillDataResponse.data.data);
                    setFormData(parsedFormData);

                    setDynamicDataApiStatus({
                        [GAMEDAY_CONTENT_COMPONENTS.GAME_CONFIGURATION.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                } else {
                    throw new Error(prefillDataResponse);
                }
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_CONTENT_COMPONENTS.GAME_CONFIGURATION.id]:
                        LOADING_STATUS.FAILED,
                });
            });
    }, [getAllData, setDynamicDataApiStatus]);

    const onSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", GAMEDAY_CONTENT_STATICS.FORM_INVALID);
            return;
        }

        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.GAME_DAY_CONTENT.CONFIGURE.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.featureId,
            }
        )
            .then((submitResponse) => {
                EvToast.success("Updated", "Game Configuration updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                navigateToGameContentDashboard();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "GamedayContentConfigure onSubmit");
            });
    }, [
        vendorCardData.featureId,
        vendorCardData.vendorId,
        submitStatus,
        formData,
        vendorCardData.programId,
        clientCode,
        navigateToGameContentDashboard,
    ]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_CONTENT_COMPONENTS.GAME_CONFIGURATION.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText dangerous subHeading>
                {stringTemplate(staticData.header, vendorCardData)}
            </EvText>
            <EvText className={styles.header.descriptionText}>
                {staticData.description}
            </EvText>
        </div>
    );

    const getFormView = () => (
        <div className={styles.form.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                initialFormData={initialFormData}
                setFormDataState={setFormData}
            />
        </div>
    );

    const getStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                        size={3}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                        size={3}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <div className={styles.actionView.labelContainer} />
            <div className={styles.actionView.buttonContainer}>
                <EvButton
                    onClickHandler={onSubmitClick}
                    primaryFilled
                    className={styles.actionView.button}
                >
                    {staticData.submitCta.text}
                </EvButton>
                {getStatusIcon()}
            </div>
        </div>
    );

    if (
        !isCompleted(
            controls.dynamicDataApiStatus[
                GAMEDAY_CONTENT_COMPONENTS.GAME_CONFIGURATION.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            <EvDivider marginVertical={10} />
            {getFormView()}
            {getActionView()}
        </div>
    );
};

GamedayContentConfigure.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,

    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    goBackAction: PropTypes.func,
};

GamedayContentConfigure.defaultProps = {
    controls: {},
    staticData: {},

    location: {},

    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.CommonReducer[GAMEDAY_CONTENT_APP_ID].controls,
    staticData:
        state.CommonReducer[GAMEDAY_CONTENT_APP_ID].staticData[
            GAMEDAY_CONTENT_TEMPLATES.CONFIGURE_STATIC.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    setDynamicDataApiStatus: (payload) =>
        dispatch(
            CommonActions.setDynamicDataApiStatus({
                data: payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayContentConfigure);
