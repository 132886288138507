const CARDS_DASHBOARD_APP_ID = "vendorPrograms__cardsDashboard";

// const CARDS_DASHBOARD_TEMPLATES = {
//   STATICS: {
//     id: "cardDashboard__static",
//   },
//   STATIC_CLIENT_PROGRAM_CARDS: {
//     id: "cardDashboard__clientProgramStatic",
//   },
// };

// const CARDS_DASHBOARD_COMPONENTS = {
//   CARD_LIST: {
//     id: "cardDashboard__cardList",
//   },
// };

const CARDS_PAGES = {
    // DASHBOARD: {
    //   id: "vendorPrograms__cardsDashboard",
    //   path: "/cc/client/manage-programs/customize-programs/cards",
    // },

    RESOURCE_CARD: {
        id: "cardsContent__resourceCard",
        path: "/cc/client/client-content/cards/content/resource-card",
    },
    CURRENT_STATUS: {
        id: "cardsContent__currentStatus",
        path: "/cc/client/client-content/cards/content/current-status",
    },
    WHERE_TO_GO_FOR_CARE: {
        id: "cardsContent__whereTo",
        path: "/cc/client/client-content/cards/content/where-to",
    },
    EVIVE_360: {
        id: "cardsContent__evive360",
        path: "/cc/client/client-content/cards/content/evive-360",
    },
    CALLOUTS: {
        id: "cardsContent__callouts",
        path: "/cc/client/client-content/cards/content/callouts",
    },
};

const CARDS_VISIBILITY = {
    VISIBLE: "VISIBLE",
    NOT_VISIBLE: "NOT_VISIBLE",
};

export {
    CARDS_DASHBOARD_APP_ID,
    // CARDS_DASHBOARD_COMPONENTS,
    // CARDS_DASHBOARD_TEMPLATES,
    CARDS_PAGES,
    CARDS_VISIBILITY,
};
