import React, { Fragment, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    NotificationsContent,
    NotificationsScheduling,
    NotificationsNewPopulation,
    TargetPopulation,
    NotificationsSummary,
    NotificationsDashboard,
    NotificationsTagSelection,
    NotificationsUploadPopulation,
} from ".";
import { CACHE_DATA_TYPE } from "../../../common/static/Enums";
import {
    NOTIFICATIONS_PAGES,
    NOTIFICATIONS_BACK_BUTTON,
} from "../static/NotificationsStatics";
import NotificationsActions from "../redux/NotificationsActions";
import { EvButton, EvIcon, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";

import "../styles/notifications-container.scss";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import CacheActions from "../../../reducers/cache/CacheActions";
import NotificationsProgramSelection from "./NotificationsProgramSelection";

const styles = {
    backButton: {
        container: "ev__notifications-container__back-button-container",
        wrapper: "ev__notifications-container__back-button-inner-wrapper",
        text: "ev__notifications-container__back-button-text",
    },
    contentContainer: "ev__notifications-container__content-container",
};

const NotificationsContainer = (props) => {
    const {
        clientCode,
        controls,
        setControls,
        resetAllData,
        clearCacheByType,
    } = props;

    useEffect(() => {
        clearCacheByType({ type: CACHE_DATA_TYPE.TAGS });
    }, [clearCacheByType]);

    useEffect(
        () => () => {
            // cleanup code
            resetAllData();
        },
        [resetAllData]
    );

    const getView = () => {
        switch (controls.selectedPageId) {
            case NOTIFICATIONS_PAGES.DASHBOARD.id:
                return <NotificationsDashboard clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id:
                return (
                    <NotificationsProgramSelection clientCode={clientCode} />
                );

            case NOTIFICATIONS_PAGES.CONTENT.id:
                return <NotificationsContent clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.TARGET_POPULATION.id:
                return <TargetPopulation clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.UPLOAD_POPULATION.id:
                return (
                    <NotificationsUploadPopulation clientCode={clientCode} />
                );

            case NOTIFICATIONS_PAGES.NEW_POPULATION.id:
                return <NotificationsNewPopulation clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.TAG_SELECTION.id:
                return <NotificationsTagSelection clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.SCHEDULE.id:
                return <NotificationsScheduling clientCode={clientCode} />;

            case NOTIFICATIONS_PAGES.SUMMARY.id:
                return <NotificationsSummary clientCode={clientCode} />;

            default:
                return <div>Notifications Dashboard</div>;
        }
    };

    const onBackButtonClick = useCallback(
        (e, callbackOptions) => {
            setControls({
                selectedPageId: callbackOptions.actionPageId,
            });
        },
        [setControls]
    );

    const getBackButton = () => {
        const backButtonObject =
            NOTIFICATIONS_BACK_BUTTON[controls.selectedPageId];
        if (isNullOrEmpty(backButtonObject)) {
            return <span />;
        }
        return (
            <div className={styles.backButton.container}>
                <EvButton
                    onlyChild
                    onClickHandler={onBackButtonClick}
                    callbackValues={backButtonObject}
                >
                    <div className={styles.backButton.wrapper}>
                        <EvIcon
                            iconName="ARROW_THICK_LEFT"
                            size={3}
                            fillColor={COLORS.PRODUCT}
                        />
                        <EvText
                            primaryColored
                            defaultBold
                            className={styles.backButton.text}
                        >
                            {backButtonObject.text}
                        </EvText>
                    </div>
                </EvButton>
            </div>
        );
    };

    // const onTempNavbarClick = (e, callbackOptions) => {
    //   setControls({
    //     selectedPageId: callbackOptions.id,
    //   });
    // };

    // // TODO: Temporary
    // const getTempNavbarButton = navItemKey => {
    //   const navItem = NOTIFICATIONS_PAGES[navItemKey];
    //   return (
    //     <EvButton
    //       key={navItem.id}
    //       onClickHandler={onTempNavbarClick}
    //       callbackValues={navItem}
    //       buttonInlineStyle={{
    //         marginRight: 5,
    //         marginBottom: 5,
    //       }}
    //       primary
    //     >
    //       {navItem.displayName}
    //     </EvButton>
    //   );
    // };
    //
    // // TODO: Temporary
    // const getTempNavigationBar = () => (
    //   <div>{Object.keys(NOTIFICATIONS_PAGES).map(getTempNavbarButton)}</div>
    // );

    return (
        <Fragment>
            {/* <EvText error>***Temporary Navbar only. Will be removed later***</EvText> */}
            {/* {getTempNavigationBar()} */}
            {getBackButton()}
            <div className={styles.contentContainer}>{getView()}</div>
        </Fragment>
    );
};

NotificationsContainer.propTypes = {
    clientCode: PropTypes.string,
    // clientName: PropTypes.string,
    controls: PropTypes.object,
    setControls: PropTypes.func,
    resetAllData: PropTypes.func,
    clearCacheByType: PropTypes.func,
};

NotificationsContainer.defaultProps = {
    clientCode: "",
    // clientName: "",
    controls: {},
    setControls: () => {},
    resetAllData: () => {},
    clearCacheByType: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    resetAllData: (payload) =>
        dispatch(NotificationsActions.resetAllData(payload)),
    clearCacheByType: (payload) =>
        dispatch(CacheActions.clearCacheByType(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContainer);
