import React, { Component } from "react";
import classNames from "classnames";

import { EvText, EvIcon } from "./index";
import { COLORS } from "../static/VmsStatics";

import {
    isNullOrEmpty,
    guidGenerator,
    removeKeyFromObject,
    removeItemFromArray,
} from "../../utils/CommonUtils";
import { setAnimationCallbackDirectRef } from "../../utils/AnimationHelper";

import "../styles/ev-toast.scss";

const TOAST_TYPE = {
    INFO: "INFO",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    WARN: "WARN",
};

const TOAST_DURATION = {
    SHORT: 3000,
    NORMAL: 5000,
    LONG: 8000,
};

const TOAST_ICON_OBJECTS = {
    CHECK: {
        iconName: "CHECK",
        fillColor: COLORS.WHITE,
    },
    ALERT: {
        iconName: "ALERT",
        fillColor: COLORS.WHITE,
    },
};

const TOAST_GENERIC_MESSAGES = {
    WENT_WRONG: "Something went wrong",
};

class EvToast extends Component {
    styles = {
        container: "ev__ev-toast__container",
        contentContainer: "ev__ev-toast__content-container",
        toastContainer: "ev__ev-toast__toast-container",
        toastListContainer: "ev__ev-toast__toast-list-container",
        toastItem: {
            wrapper: "ev__ev-toast__toast-item-wrapper",
            wrapperExiting: "ev__ev-toast__toast-item-wrapper-exiting",
            innerWrapper: "ev__ev-toast__toast-item-inner-wrapper",
            wrapperType: {
                info: "ev__ev-toast__toast-item-wrapper-type-info",
                error: "ev__ev-toast__toast-item-wrapper-type-error",
                success: "ev__ev-toast__toast-item-wrapper-type-success",
                warn: "ev__ev-toast__toast-item-wrapper-type-warn",
            },
            content: "ev__ev-toast__toast-item-content",
            iconContainer: "ev__ev-toast__toast-item-icon-container",
            textContainer: "ev__ev-toast__toast-item-text-container",
        },
    };

    constructor(props) {
        super(props);
        EvToast.singletonRef = this;
    }

    state = {
        toasts: {},
        toastOrder: [],
    };

    static show = (
        toastObject,
        type = TOAST_TYPE.INFO,
        duration = TOAST_DURATION.NORMAL
    ) => {
        EvToast.singletonRef.addToastObject(toastObject, type, duration);
    };

    static info = (toastObject, duration = TOAST_DURATION.NORMAL) => {
        EvToast.singletonRef.addToastObject(
            toastObject,
            TOAST_TYPE.INFO,
            duration
        );
    };

    // easy and direct
    static success = (
        header = "",
        message = "",
        options = {},
        duration = TOAST_DURATION.NORMAL
    ) => {
        if (EvToast.singletonRef) {
            EvToast.singletonRef.addToastObject(
                {
                    header,
                    description: message,
                    ...options,
                },
                TOAST_TYPE.SUCCESS,
                duration
            );
        }
    };

    // with full option support, will be removed later
    static successAdv = (toastObject, duration = TOAST_DURATION.NORMAL) => {
        EvToast.singletonRef.addToastObject(
            toastObject,
            TOAST_TYPE.SUCCESS,
            duration
        );
    };

    // with full option support, will be removed later
    static errorAdv = (toastObject, duration = TOAST_DURATION.NORMAL) => {
        EvToast.singletonRef.addToastObject(
            toastObject,
            TOAST_TYPE.ERROR,
            duration
        );
    };

    // easy and direct
    static error = (
        header = "",
        message = "",
        options = {},
        duration = TOAST_DURATION.NORMAL
    ) => {
        if (EvToast.singletonRef) {
            EvToast.singletonRef.addToastObject(
                {
                    header,
                    description: message,
                    ...options,
                },
                TOAST_TYPE.ERROR,
                duration
            );
        }
    };

    static warn = (
        header = "",
        message = "",
        options = {},
        duration = TOAST_DURATION.NORMAL
    ) => {
        if (EvToast.singletonRef) {
            EvToast.singletonRef.addToastObject(
                {
                    header,
                    description: message,
                    ...options,
                },
                TOAST_TYPE.WARN,
                duration
            );
        }
    };

    static warnAdv = (toastObject, duration = TOAST_DURATION.NORMAL) => {
        EvToast.singletonRef.addToastObject(
            toastObject,
            TOAST_TYPE.WARN,
            duration
        );
    };

    static clear = () => {
        EvToast.singletonRef.clearToasts();
    };

    addToastObject = (
        toastObject,
        type = TOAST_TYPE.INFO,
        duration = TOAST_DURATION.NORMAL
    ) => {
        const toastId = guidGenerator();
        this.setState((prevState) => ({
            toasts: {
                ...prevState.toasts,
                [toastId]: {
                    ...toastObject,
                    type,
                },
            },
            toastOrder: [...prevState.toastOrder, toastId],
        }));
        setTimeout(() => {
            this.setRemovingAnimation(toastId);
        }, duration);
    };

    setRemovingAnimation = (toastId) => {
        if (!isNullOrEmpty(this.state.toasts[toastId])) {
            this.setState((prevState) => ({
                toasts: {
                    ...prevState.toasts,
                    [toastId]: {
                        ...prevState.toasts[toastId],
                        isExiting: true,
                    },
                },
            }));

            // to remove the element from state once animation complete
            const element = document.getElementById(toastId);
            setAnimationCallbackDirectRef(element, () => {
                this.removeToastById(toastId);
            });
        }
    };

    removeToastById = (toastId) => {
        if (!isNullOrEmpty(this.state.toasts[toastId])) {
            this.setState((prevState) => ({
                toasts: removeKeyFromObject(toastId, prevState.toasts),
                toastOrder: removeItemFromArray(toastId, prevState.toastOrder),
            }));
        }
    };

    clearToasts = () => {
        this.setState({
            toasts: {},
            toastOrder: [],
        });
    };

    getToastTypeCustomClass = (type = TOAST_TYPE.INFO) =>
        classNames(this.styles.toastItem.innerWrapper, {
            [this.styles.toastItem.wrapperType.info]: type === TOAST_TYPE.INFO,
            [this.styles.toastItem.wrapperType.error]:
                type === TOAST_TYPE.ERROR,
            [this.styles.toastItem.wrapperType.success]:
                type === TOAST_TYPE.SUCCESS,
            [this.styles.toastItem.wrapperType.warn]: type === TOAST_TYPE.WARN,
        });

    getToastIconView = (toastItemIcon) => (
        <div className={this.styles.toastItem.iconContainer}>
            <EvIcon
                iconName={toastItemIcon.iconName}
                fillColor={toastItemIcon.fillColor || COLORS.WHITE}
            />
        </div>
    );

    getToastTextView = (toastItem) => (
        <div className={this.styles.toastItem.textContainer}>
            {toastItem.header && (
                <EvText color={COLORS.WHITE} headingSecondary>
                    {toastItem.header}
                </EvText>
            )}
            {toastItem.description && (
                <EvText color={COLORS.WHITE} default>
                    {toastItem.description}
                </EvText>
            )}
        </div>
    );

    getToastItemView = (toastItemId) => {
        const toastItem = this.state.toasts[toastItemId];
        const customInnerWrapperClass = this.getToastTypeCustomClass(
            toastItem.type
        );
        const customWrapperClass = classNames(this.styles.toastItem.wrapper, {
            [this.styles.toastItem.wrapperExiting]: toastItem.isExiting,
        });
        return (
            <div
                id={toastItemId}
                key={toastItemId}
                className={customWrapperClass}
            >
                <div className={customInnerWrapperClass}>
                    <div className={this.styles.toastItem.content}>
                        {!isNullOrEmpty(toastItem.icon) &&
                            this.getToastIconView(toastItem.icon)}
                        {this.getToastTextView(toastItem)}
                    </div>
                </div>
            </div>
        );
    };

    getToastListView = () => (
        <div className={this.styles.toastListContainer}>
            {this.state.toastOrder.map(this.getToastItemView)}
        </div>
    );

    getContent = () => (
        <div className={this.styles.contentContainer}>
            <div className={this.styles.toastContainer}>
                {this.getToastListView()}
            </div>
        </div>
    );

    render() {
        return <div className={this.styles.container}>{this.getContent()}</div>;
    }
}

export default EvToast;
export { TOAST_DURATION, TOAST_ICON_OBJECTS, TOAST_GENERIC_MESSAGES };
