const EV_ERROR_TYPES = {
    UN_CATEGORIZED: "UN_CATEGORIZED",
    HARD_REDIRECT: "HARD_REDIRECT",
    UNAUTHORIZED: "UNAUTHORIZED",
    SERVER_ERROR: "SERVER_ERROR",
    SERVER_INTERNAL: "SERVER_INTERNAL",
};

class EvError extends Error {
    constructor({
        fileName = "",
        type = EV_ERROR_TYPES.UN_CATEGORIZED,
        message,
        category,
        action,
        handled = true,
        ...params
    }) {
        super(params);
        this.fileName = fileName;
        this.handled = handled;
        this.message = message;
        this.action = action; // event type
        this.category = category;
        this.type = type; // error type
    }

    // toString() {
    //   return `EvError file:${this.fileName} type:${this.type} message:${
    //     this.message
    //   }`;
    // }

    // markHandled(isHandled = true) {
    //   this.handled = isHandled;
    // }
}

export default EvError;
export { EV_ERROR_TYPES };
