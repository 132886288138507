import EnterpriseNotificationsActionTypes from "./EnterpriseNotificationsActionTypes";

const EnterpriseNotificationsActions = {
    setClientCodes: (payload) => ({
        type: EnterpriseNotificationsActionTypes.SET_CLIENT_CODES,
        payload,
    }),
    setControls: (payload) => ({
        type: EnterpriseNotificationsActionTypes.SET_CONTROLS,
        payload,
    }),

    resetAllData: (payload) => ({
        type: EnterpriseNotificationsActionTypes.RESET_ALL_DATA,
        payload,
    }),
};

export default EnterpriseNotificationsActions;
