import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SavedPopulationActions from "../redux/SavedPopulationActions";
import {
    SAVED_POPULATION_TEMPLATES,
    SAVED_POPULATION_COMPONENTS,
} from "../static/SavedPopulationStatics";
import API_URLS from "../../../services/apiUrls";
import { LOADING_STATUS } from "../../../common/static/Enums";
import {
    deleteData,
    getData,
    postData,
} from "../service/SavedPopulationService";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseCriteriaFormDataForApi,
    parseFormDataForApi,
    parseInitialFormDataForCriteriaList,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    guidGenerator,
    isCompleted,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import {
    EvLoadingPage,
    EvToast,
    EvSimpleModal,
} from "../../../common/components";
import EvNotificationsEditPopulationView from "../../notifications/views/EvNotificationsEditPopulationView";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import EvLogger from "../../../utils/EvLogger";

const SavedPopulationNewEditPopulation = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        populationData,
        populationCriteriaData,
        allTags,

        // actions
        setControls,
        getStaticData,
        createTagsDictionary,
        setComponentData,
        setDynamicDataApiStatus,
    } = props;

    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const currentPopulationId = controls.currentNotificationPopulationId || "";

    const getAllRequiredData = useCallback(
        (populationId) => {
            // must call details api after static data, it has optional dependency
            Promise.all([
                getStaticData({
                    url: API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.STATIC,
                    templateId:
                        SAVED_POPULATION_TEMPLATES.NEW_EDIT_POPULATION.id,
                }),
                getStaticData({
                    url:
                        API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION
                            .CRITERIA_RULE_FORM_DATA,
                    templateId:
                        SAVED_POPULATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id,
                }),
            ])
                .then(([staticDataResponse, criteriaRuleFormDataResponse]) => {
                    // if criteria details is not present already
                    // when you come back from tag selection page, it should not overwrite this data
                    if (
                        !isCompleted(
                            controls.dynamicDataApiStatus[
                                SAVED_POPULATION_COMPONENTS
                                    .POPULATION_CRITERIA_DATA.id
                            ]
                        )
                    ) {
                        setDynamicDataApiStatus({
                            [SAVED_POPULATION_COMPONENTS
                                .POPULATION_CRITERIA_DATA.id]:
                                LOADING_STATUS.LOADING,
                        });

                        getData(
                            API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION
                                .POPULATION_DATA,
                            {
                                clientCode,
                                populationId,
                            }
                        )
                            .then((populationDataResponse) => {
                                // includes population name and details form data
                                const initialDetailsFormData = parseInitialStateFromFormFields(
                                    staticDataResponse.populationFormFields,
                                    populationDataResponse.data.data
                                        .populationDetails
                                );

                                setComponentData({
                                    componentId:
                                        SAVED_POPULATION_COMPONENTS
                                            .POPULATION_DATA.id,
                                    data: initialDetailsFormData,
                                });

                                // this is for all the criteria form data
                                let initialListFormData = {};
                                // if criteria list is empty add one empty criteria initially
                                if (
                                    populationDataResponse.data.data
                                        .criteriaList.length <= 0
                                ) {
                                    initialListFormData = {
                                        [guidGenerator()]: {},
                                    };
                                } else {
                                    initialListFormData = parseInitialFormDataForCriteriaList(
                                        criteriaRuleFormDataResponse.formFields,
                                        populationDataResponse.data.data
                                            .criteriaList
                                    );
                                }

                                setComponentData({
                                    componentId:
                                        SAVED_POPULATION_COMPONENTS
                                            .POPULATION_CRITERIA_DATA.id,
                                    data: initialListFormData,
                                });

                                setDynamicDataApiStatus({
                                    [SAVED_POPULATION_COMPONENTS
                                        .POPULATION_CRITERIA_DATA.id]:
                                        LOADING_STATUS.COMPLETED,
                                    [SAVED_POPULATION_COMPONENTS.POPULATION_DATA
                                        .id]: LOADING_STATUS.COMPLETED,
                                });
                            })
                            .catch((e) => {
                                throw e;
                            });
                    }
                })
                .catch((e) => {
                    EvToast.error("Sorry", "Something went wrong");
                    setDynamicDataApiStatus({
                        [SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA
                            .id]: LOADING_STATUS.FAILED,
                        [SAVED_POPULATION_COMPONENTS.POPULATION_DATA.id]:
                            LOADING_STATUS.FAILED,
                    });
                    setControls({
                        selectedPageId: SAVED_POPULATION_TEMPLATES.DASHBOARD.id,
                    });
                    EvLogger.errorWithObject(
                        e,
                        "SavedPopulationNewEditPops getAllData"
                    );
                });
        },
        [
            getStaticData,
            setComponentData,
            setDynamicDataApiStatus,
            clientCode,
            controls.dynamicDataApiStatus,
            setControls,
        ]
    );

    useEffect(() => {
        getAllRequiredData(currentPopulationId);
        createTagsDictionary();
    }, [currentPopulationId, getAllRequiredData, createTagsDictionary]);

    const onCriteriaClick = useCallback(
        (criteriaId) => {
            setControls({
                tagSelectionCriteriaId: criteriaId,
                selectedPageId: SAVED_POPULATION_TEMPLATES.TAG_SELECTION.id,
            });
        },
        [setControls]
    );

    const onDetailsFormDataChange = useCallback(
        (newFormData) => {
            setComponentData({
                componentId: SAVED_POPULATION_COMPONENTS.POPULATION_DATA.id,
                data: newFormData,
            });
        },
        [setComponentData]
    );

    const onRemoveCriteriaClick = useCallback(
        (newCriteriaListFormData) => {
            setComponentData({
                componentId:
                    SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id,
                data: newCriteriaListFormData,
            });
        },
        [setComponentData]
    );

    const onAddNewCriteriaClick = useCallback(() => {
        const guid = guidGenerator();
        setComponentData({
            componentId:
                SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id,
            data: {
                ...populationCriteriaData,
                [guid]: {},
            },
        });
    }, [populationCriteriaData, setComponentData]);

    const onSaveButtonClick = useCallback(() => {
        // if population name form is not valid
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(populationData)) {
            setComponentData({
                componentId: SAVED_POPULATION_COMPONENTS.POPULATION_DATA.id,
                data: {
                    ...markAllFieldsUsed(populationData),
                },
            });
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = {
            clientCode,
            populationId: currentPopulationId,
            ...parseFormDataForApi(populationData),
            criteriaList: parseCriteriaFormDataForApi(
                populationCriteriaData
            ).filter((criteria) => !isNullOrEmpty(criteria)),
        };
        postData(
            API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.SAVE_POPULATION,
            {},
            apiData
        )
            .then((response) => {
                EvToast.success("Saved", "Population Saved", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setControls({
                    selectedPageId: SAVED_POPULATION_TEMPLATES.DASHBOARD.id,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "SavedPopulationNewEditPops onFormSubmitClick"
                );
            });
    }, [
        populationData,
        populationCriteriaData,
        setComponentData,
        clientCode,
        currentPopulationId,
        submitStatus,
        setControls,
    ]);

    const deletePopulation = useCallback(() => {
        deleteData(
            API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.DELETE_POPULATION,
            {
                clientCode,
                populationId: currentPopulationId,
            }
        )
            .then((response) => {
                EvToast.success("Deleted", "Population Deleted");
                setControls({
                    selectedPageId: SAVED_POPULATION_TEMPLATES.DASHBOARD.id,
                });
            })
            .catch((e) => {
                EvToast.error("Sorry", "Something went wrong");
                EvLogger.errorWithObject(
                    e,
                    "SavedPopulationNewEditPops deletePops"
                );
            });
    }, [clientCode, currentPopulationId, setControls]);

    const onDeleteClick = useCallback(() => {
        EvSimpleModal.setData({
            header: staticData.deletePopulationConfirmationModal.header,
            description:
                staticData.deletePopulationConfirmationModal.description,
            positiveButtonText:
                staticData.deletePopulationConfirmationModal.positiveCta.text,
            negativeButtonText:
                staticData.deletePopulationConfirmationModal.negativeCta.text,
            onPositiveAction: deletePopulation,
        }).show();
    }, [deletePopulation, staticData]);

    const onBackClick = useCallback(() => {
        const confirmationModalData = {
            header: staticData.backConfirmationModal.header,
            description: staticData.backConfirmationModal.description,
            positiveButtonText:
                staticData.backConfirmationModal.positiveCta.text,
            negativeButtonText:
                staticData.backConfirmationModal.negativeCta.text,
            onPositiveAction: () => {
                setControls({
                    selectedPageId: SAVED_POPULATION_TEMPLATES.DASHBOARD.id,
                });
            },
            onNegativeAction: () => {
                // do nothing
            },
        };
        EvSimpleModal.setData(confirmationModalData).show();
    }, [setControls, staticData]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                SAVED_POPULATION_TEMPLATES.NEW_EDIT_POPULATION.id
            ],
            controls.staticDataApiStatus[
                SAVED_POPULATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id
            ],
            controls.dynamicDataApiStatus[
                SAVED_POPULATION_COMPONENTS.POPULATION_DATA.id
            ],
            controls.dynamicDataApiStatus[
                SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id
            ],
            controls.dynamicDataApiStatus[
                SAVED_POPULATION_COMPONENTS.ALL_TAGS.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }
    return (
        <EvNotificationsEditPopulationView
            staticData={staticData}
            populationDetailsFormData={populationData}
            allTags={allTags}
            criteriaListFormData={populationCriteriaData}
            onDetailsFormDataChange={onDetailsFormDataChange}
            onAddNewCriteriaClick={onAddNewCriteriaClick}
            onSaveButtonClick={onSaveButtonClick}
            onCriteriaClick={onCriteriaClick}
            onDeleteClick={onDeleteClick}
            onRemoveCriteria={onRemoveCriteriaClick}
            showDeleteOption={currentPopulationId !== ""}
            submitStatus={submitStatus}
            showBackButton
            onBackClick={onBackClick}
        />
    );
};

SavedPopulationNewEditPopulation.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    populationData: PropTypes.object,
    populationCriteriaData: PropTypes.object,
    allTags: PropTypes.object,

    // actions
    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    createTagsDictionary: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

SavedPopulationNewEditPopulation.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},
    populationData: {},
    populationCriteriaData: {},
    allTags: {},

    // actions
    setControls: () => {},
    getStaticData: () => {},
    createTagsDictionary: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.SavedPopulationReducer.controls,
    staticData:
        state.SavedPopulationReducer.staticData[
            SAVED_POPULATION_TEMPLATES.NEW_EDIT_POPULATION.id
        ],
    populationData:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.POPULATION_DATA.id
        ],
    populationCriteriaData:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.POPULATION_CRITERIA_DATA.id
        ],
    allTags:
        state.SavedPopulationReducer.dynamicData[
            SAVED_POPULATION_COMPONENTS.ALL_TAGS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(SavedPopulationActions.setControls(payload)),
    getStaticData: (payload) =>
        dispatch(SavedPopulationActions.getStaticData(payload)),
    createTagsDictionary: (payload) =>
        dispatch(SavedPopulationActions.createTagsDictionary(payload)),
    setComponentData: (payload) =>
        dispatch(SavedPopulationActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(SavedPopulationActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedPopulationNewEditPopulation);
