import React, { useMemo } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import "../styles/ev-population-criteria-card.scss";
import { EvButton, EvText } from "./index";
import { parseFormDataForNames } from "../../utils/FormUtils";
import { TAGS_TYPE } from "../static/Enums";

const styles = {
    container: "ev__ev-population-criteria-card__container",
    header: {
        container: "ev__ev-population-criteria-card__header-container",
        text: "ev__ev-population-criteria-card__header-text",
        button: "ev__ev-population-criteria-card__header-button",
    },
    card: {
        button: "ev__ev-population-criteria-card__card-button",
        wrapper: "ev__ev-population-criteria-card__card-wrapper",
    },

    rule: {
        wrapper: "ev__ev-population-criteria-card__rule-wrapper",
        text: "ev__ev-population-criteria-card__rule-text",
    },
};

const EvPopulationCriteriaCard = (props) => {
    const {
        criteriaId,
        count,
        staticData,
        criteriaFormData,
        allTags,
        onCriteriaClick,
        onRemoveCriteriaClick,
    } = props;

    const criteriaSimplifiedFormData = useMemo(() => {
        const simplifiedTagData = {};
        Object.keys(criteriaFormData).forEach((tagId) => {
            simplifiedTagData[tagId] = parseFormDataForNames(
                criteriaFormData[tagId]
            );
        });
        return simplifiedTagData;
    }, [criteriaFormData]);

    const getCriteriaHeaderView = () => (
        <div className={styles.header.container}>
            <EvText className={styles.header.text}>
                {stringTemplate(staticData.heading, { count: count + 1 })}
            </EvText>
            <EvButton
                iconName="EDIT"
                iconButton
                className={styles.header.button}
                onClickHandler={onCriteriaClick}
                callbackValues={{ criteriaId }}
            >
                {staticData.editButtonCta.text}
            </EvButton>
            <EvButton
                iconName="DELETE"
                iconButton
                className={styles.header.button}
                onClickHandler={onRemoveCriteriaClick}
                callbackValues={{ criteriaId }}
            >
                {staticData.removeButtonCta.text}
            </EvButton>
        </div>
    );

    const getCriteriaDetailsTagsItemView = (tagId) => {
        const tagData = allTags[tagId];
        const tagRuleText =
            tagData.type === TAGS_TYPE.RESTRICTED
                ? tagData.description
                : stringTemplate(
                      staticData.ruleDisplayTemplate,
                      criteriaSimplifiedFormData[tagId]
                  );
        return (
            <div key={tagId} className={styles.rule.wrapper}>
                <EvText defaultDarkBold>{tagData.displayName}</EvText>
                <EvText className={styles.rule.text}>{tagRuleText}</EvText>
            </div>
        );
    };

    const getCriteriaContentView = () => {
        const currentCriteriaObjectKeys = Object.keys(criteriaFormData);
        return (
            <EvButton
                onlyChild
                className={styles.card.button}
                onClickHandler={onCriteriaClick}
                callbackValues={{ criteriaId }}
            >
                <div className={styles.card.wrapper}>
                    {/* if criteria has no rules or tags */}
                    {currentCriteriaObjectKeys.length <= 0 && (
                        <EvText primaryColored defaultBold>
                            {staticData.placeholder}
                        </EvText>
                    )}
                    {currentCriteriaObjectKeys.map(
                        getCriteriaDetailsTagsItemView
                    )}
                </div>
            </EvButton>
        );
    };

    return (
        <div className={styles.container}>
            {getCriteriaHeaderView()}
            {getCriteriaContentView()}
        </div>
    );
};

EvPopulationCriteriaCard.propTypes = {
    criteriaId: PropTypes.string,
    count: PropTypes.number,
    staticData: PropTypes.object,
    criteriaFormData: PropTypes.object,
    allTags: PropTypes.object,

    onCriteriaClick: PropTypes.func,
    onRemoveCriteriaClick: PropTypes.func,
};

EvPopulationCriteriaCard.defaultProps = {
    criteriaId: "cid1",
    count: 1,
    onCriteriaClick: () => {},
    onRemoveCriteriaClick: () => {},

    // extra dummy
    staticData: {
        heading: "Criteria rule {count}",
        editButtonCta: { text: "Edit Rule" },
        removeButtonCta: { text: "Remove" },
        ruleDisplayTemplate: "{mappedField} {operator} [{values}]",
        criteriaDividerText: "OR",
        placeholder: "Add Tags",
    },
    allTags: {
        ELIG_IS_SPOUSE: {
            displayName: "Spouse",
            value: "ELIG_IS_SPOUSE",
            id: "ELIG_IS_SPOUSE",
            tagRuleFields: {
                mappedField: {
                    id: "FT",
                    name: "Full Time",
                    value: "FT",
                },
                operator: "contains",
                values: ["1001"],
            },
            editable: false,
        },
    },
    criteriaFormData: {
        ELIG_IS_SPOUSE: {
            mappedField: {
                FT: {
                    key: "FT",
                    value: "FT",
                    name: "Full Time",
                },
                errorObject: {
                    isValid: true,
                    errorMessages: {},
                },
                formItemType: "LABEL",
            },
            operator: {
                contains: {
                    key: "contains",
                    value: "contains",
                    name: "contains",
                },
                errorObject: {
                    isValid: true,
                    errorMessages: {},
                },
                formItemType: "MINIMAL_DROPDOWN",
            },
            values: {
                values: {
                    key: "values",
                    value: "1001",
                },
                errorObject: {
                    isValid: true,
                    errorMessages: {},
                },
                formItemType: "CSV_TEXT",
            },
        },
    },
};

export default EvPopulationCriteriaCard;
