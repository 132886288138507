import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/NotificationsActions";
import API_URLS from "../../../services/apiUrls";
import {
    NOTIFICATION_TEMPLATES,
    NOTIFICATIONS_COMPONENTS,
    NOTIFICATIONS_PAGES,
} from "../static/NotificationsStatics";
import { isCompleted } from "../../../utils/CommonUtils";
import { EvLoadingPage } from "../../../common/components";
import EvNotificationsTagSelectionView from "./EvNotificationsTagSelectionView";

const NotificationsTagSelection = (props) => {
    const {
        staticData,
        criteriaRuleFormStaticData,
        controls,
        allTags,
        allSelectedData,
        categories,
        getStaticData,
        setControls,
        setComponentData,
    } = props;

    const { tagSelectionCriteriaId } = controls;

    useEffect(() => {
        getStaticData({
            url: API_URLS.NOTIFICATIONS.TAG_SELECTION.STATIC,
            templateId: NOTIFICATION_TEMPLATES.TAG_SELECTION_STATIC.id,
        });
        getStaticData({
            url:
                API_URLS.NOTIFICATIONS.NEW_POPULATIONS
                    .TEMPLATE_CRITERIA_FORM_DATA,
            templateId: NOTIFICATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id,
        });
        getStaticData({
            url: API_URLS.NOTIFICATIONS.NEW_POPULATIONS.TAG_CATEGORIES,
            templateId: NOTIFICATION_TEMPLATES.TAG_CATEGORIES.id,
        });
    }, [getStaticData]);

    const onProceedClick = useCallback(
        (newSelectedData) => {
            setComponentData({
                componentId:
                    NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id,
                data: newSelectedData,
            });
            setControls({
                selectedPageId: NOTIFICATIONS_PAGES.NEW_POPULATION.id,
            });
        },
        [setComponentData, setControls]
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                NOTIFICATION_TEMPLATES.TAG_SELECTION_STATIC.id
            ],
            controls.staticDataApiStatus[
                NOTIFICATION_TEMPLATES.TAG_CATEGORIES.id
            ],
            controls.dynamicDataApiStatus[NOTIFICATIONS_COMPONENTS.ALL_TAGS.id]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <EvNotificationsTagSelectionView
            staticData={staticData}
            tagSelectionCriteriaId={tagSelectionCriteriaId}
            allTags={allTags}
            allSelectedData={allSelectedData}
            onProceedClick={onProceedClick}
            criteriaRuleFormStaticData={criteriaRuleFormStaticData}
            categories={categories.categories ? categories.categories : []}
        />
    );
};

NotificationsTagSelection.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    criteriaRuleFormStaticData: PropTypes.object,
    allTags: PropTypes.object,
    allSelectedData: PropTypes.object,
    categories: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

NotificationsTagSelection.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},
    criteriaRuleFormStaticData: {},
    allTags: {},
    allSelectedData: {},
    categories: {},

    getStaticData: () => {},
    setControls: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.TAG_SELECTION_STATIC.id
        ],
    categories:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.TAG_CATEGORIES.id
        ],
    criteriaRuleFormStaticData:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id
        ],
    allTags:
        state.NotificationsReducer.dynamicData[
            NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
        ],
    allSelectedData:
        state.NotificationsReducer.dynamicData[
            NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    setComponentData: (payload) =>
        dispatch(NotificationsActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsTagSelection);
