import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon, EvText } from "./index";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-back-button.scss";

const styles = {
    button: "ev__ev-back-button__container",
    wrapper: "ev__ev-back-button__wrapper",
    text: "ev__ev-back-button__text",
};

const EvBackButton = (props) => {
    const { children, onClickHandler, callbackValues, className } = props;
    const customClass = classNames(styles.button, className);
    return (
        <EvButton
            onlyChild
            className={customClass}
            onClickHandler={onClickHandler}
            callbackValues={callbackValues}
        >
            <div className={styles.wrapper}>
                <EvIcon
                    iconName="ARROW_THICK_LEFT"
                    size={3}
                    fillColor={COLORS.PRODUCT}
                />
                <EvText primaryColored defaultBold className={styles.text}>
                    {children}
                </EvText>
            </div>
        </EvButton>
    );
};

EvBackButton.propTypes = {
    children: PropTypes.node,
    onClickHandler: PropTypes.func,
    callbackValues: PropTypes.object,
    className: PropTypes.string,
};

EvBackButton.defaultProps = {
    children: <span />,
    onClickHandler: () => {},
    callbackValues: {},
    className: "",
};

export default EvBackButton;
