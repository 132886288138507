import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";
import classNames from "classnames";

import {
    EvSimpleCard,
    EvText,
    EvButton,
    EvIcon,
    EvSimpleModal,
    EvContentModal,
    EvLoader,
} from "../../../common/components";
import { EvPillsInput } from "../../../common/components/form-components";
import ProgramTagsSelectionModal from "./ProgramTagsSelectionModal";
import { COLORS } from "../../../common/static/VmsStatics";
import { setAnimationCallbackDirectRef } from "../../../utils/AnimationHelper";
import { guidGenerator, removeKeyFromObject } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";

import "../styles/program-tags-view.scss";

const ProgramTagsView = (props) => {
    const styles = {
        container: "ev__program-tags-view__container",
        info: {
            container: "ev__program-tags-view__info-container",
            wrapper: "ev__program-tags-view__info-wrapper",
            descriptionText: "ev__program-tags-view__info-description-text",
        },
        header: {
            container: "ev__program-tags-view__header-container",
        },
        rule: {
            container: "ev__program-tags-view__rule-container",
            containerIsExiting:
                "ev__program-tags-view__rule-container-is-exiting",
            inputWrapper: "ev__program-tags-view__rule-input-wrapper",
            buttonContainer: "ev__program-tags-view__rule-button-container",
            buttonContentWrapper:
                "ev__program-tags-view__rule-button-content-wrapper",
            inputClass: "ev__program-tags-view__rule-input-class",
        },
        divider: {
            container: "ev__program-tags-view__divider-container",
            wrapper: "ev__program-tags-view__divider-wrapper",
            line: "ev__program-tags-view__divider-line",
            bubble: "ev__program-tags-view__divider-bubble",
            buttonMocker: "ev__program-tags-view__divider-button-mocker",
        },
        actionView: {
            container: "ev__program-tags-view__action-view-container",
            buttonContainer:
                "ev__program-tags-view__action-view-buttons-container",
        },
        tagSelectionModal: "ev__program-tags-view__tag-selection-modal",
    };

    const {
        staticData,
        modalStaticData,
        formData,
        allTags,

        programName,
        vendorName,
        tagSubmitStatus,

        onFormDataChange,
        submitTags,
    } = props;

    const [isExitingIds, setIsExitingIds] = useState({});
    const [isSelectionModalVisible, setIsSelectionModalVisible] = useState(
        false
    );
    const [selectionModalRuleId, setSelectionModalRuleId] = useState("");

    const getRuleElementId = (ruleId) => `ev__program-tags-view_rule_${ruleId}`;

    const removeRuleFromFormData = (ruleId) => {
        const el = document.getElementById(getRuleElementId(ruleId));
        setIsExitingIds((prevIds) => ({
            ...prevIds,
            [ruleId]: true,
        }));
        setAnimationCallbackDirectRef(el, () => {
            const newFormData = removeKeyFromObject(ruleId, formData);
            onFormDataChange(ruleId, {}, newFormData);
        });
    };

    const onRemoveRuleClick = (e, callbackValues) => {
        const { ruleId } = callbackValues;
        if (ruleId) {
            const confirmationModalData = {
                header: staticData.removeCtaModal.header,
                description: staticData.removeCtaModal.description,
                positiveButtonText: staticData.removeCtaModal.positiveCta.text,
                negativeButtonText: staticData.removeCtaModal.negativeCta.text,
                onPositiveAction: () => {
                    removeRuleFromFormData(ruleId);
                },
                onNegativeAction: () => {
                    // do nothing
                },
            };
            EvSimpleModal.setData(confirmationModalData).show();
        }
    };

    const onAddNewRuleClick = () => {
        const newRuleId = guidGenerator();
        const formItemData = {
            [newRuleId]: {},
        };
        const newFormData = {
            ...formData,
            ...formItemData,
        };
        onFormDataChange(newRuleId, formItemData, newFormData);
    };

    const onPillsFormDataChange = useCallback(
        (key, formObject) => {
            const newFormData = {
                ...formData,
                ...formObject,
            };
            onFormDataChange(key, formObject, newFormData);
        },
        [onFormDataChange, formData]
    );

    // selection modal related functions start
    const onPillsValueClick = (ruleId) => {
        setSelectionModalRuleId(ruleId);
        setIsSelectionModalVisible(true);
    };

    const onSelectionModalExitComplete = () => {
        setIsSelectionModalVisible(false);
    };

    const onModalCloseClick = () => {
        // close without saving
        setIsSelectionModalVisible(false);
    };

    const onModalUpdateTags = (ruleId, ruleData) => {
        const formItemData = {
            [ruleId]: ruleData,
        };
        const newFormData = {
            ...formData,
            ...formItemData,
        };
        onFormDataChange(ruleId, formItemData, newFormData);
        setIsSelectionModalVisible(false);
    };

    // end of selection modal functions

    const getInfoView = () => (
        <div className={styles.info.container}>
            <EvSimpleCard className={styles.info.wrapper}>
                <EvText defaultDarkBold>{staticData.info.header}</EvText>
                <EvText className={styles.info.descriptionText} defaultDark>
                    {staticData.info.description}
                </EvText>
            </EvSimpleCard>
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>
                <span
                    dangerouslySetInnerHTML={{
                        __html: stringTemplate(staticData.header, {
                            programName,
                            vendorName,
                        }),
                    }}
                />
            </EvText>
        </div>
    );

    // TODO: change to EvTextDivider
    const getRuleDividerView = () => (
        <div className={styles.divider.container}>
            <div className={styles.divider.wrapper}>
                <div className={styles.divider.line} />
                <div className={styles.divider.bubble}>
                    <EvText defaultBold>{staticData.ruleDividerText}</EvText>
                </div>
                <div className={styles.divider.line} />
            </div>
            <div className={styles.divider.buttonMocker} />
        </div>
    );

    // the id is required for the animation
    const getRuleItem = (ruleId, count) => {
        const customContainerClass = classNames(styles.rule.container, {
            [styles.rule.containerIsExiting]: isExitingIds[ruleId],
        });
        return (
            <div
                key={ruleId}
                id={getRuleElementId(ruleId)}
                className={customContainerClass}
            >
                {/* dont show divider for the first row */}
                {count > 0 && getRuleDividerView()}
                <EvText defaultDark>
                    {stringTemplate(staticData.ruleHeader, {
                        count: count + 1,
                    })}
                </EvText>
                <div className={styles.rule.inputWrapper}>
                    <EvPillsInput
                        key={ruleId}
                        id={ruleId}
                        className={styles.rule.inputClass}
                        onFormDataChange={onPillsFormDataChange}
                        formData={formData[ruleId]}
                        onValueClick={onPillsValueClick}
                        placeHolder={staticData.rulePlaceholder}
                    />
                    <EvButton
                        callbackValues={{ ruleId }}
                        negativeAction
                        className={styles.rule.buttonContainer}
                        onClickHandler={onRemoveRuleClick}
                    >
                        <div className={styles.rule.buttonContentWrapper}>
                            <EvIcon
                                size={3}
                                fillColor={COLORS.BLACK_TROUT}
                                iconName={staticData.removeRuleCta.icon}
                            />
                            <EvText>{staticData.removeRuleCta.text}</EvText>
                        </div>
                    </EvButton>
                </div>
            </div>
        );
    };

    const getSelectedRulesView = () => (
        <div>{Object.keys(formData).map(getRuleItem)}</div>
    );

    const getActionStatusIcon = () => {
        switch (tagSubmitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;
            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        iconName="CHECK"
                        size={3}
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                );
            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        iconName="ALERT"
                        size={3}
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <div className={styles.actionView.buttonContainer}>
                <EvButton
                    onClickHandler={onAddNewRuleClick}
                    primary
                    className={styles.actionView.buttons}
                >
                    {staticData.addNewRuleCta.text}
                </EvButton>
            </div>
            <div className={styles.actionView.buttonContainer}>
                <EvButton
                    onClickHandler={submitTags}
                    primaryFilled={tagSubmitStatus !== LOADING_STATUS.LOADING}
                    primaryFilledDisabled={
                        tagSubmitStatus === LOADING_STATUS.LOADING
                    }
                    className={styles.actionView.buttons}
                >
                    {staticData.saveCta.text}
                </EvButton>
                {getActionStatusIcon()}
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {getInfoView()}
            {getHeaderView()}
            {getSelectedRulesView()}
            {getActionView()}
            <EvContentModal
                isVisible={isSelectionModalVisible}
                noPadding
                contentClassName={styles.tagSelectionModal}
                onExitComplete={onSelectionModalExitComplete}
            >
                <ProgramTagsSelectionModal
                    staticData={modalStaticData}
                    formItemKey={selectionModalRuleId}
                    formData={formData[selectionModalRuleId]}
                    allTags={allTags}
                    updateTags={onModalUpdateTags}
                    onCloseClick={onModalCloseClick}
                />
            </EvContentModal>
        </div>
    );
};

ProgramTagsView.propTypes = {
    staticData: PropTypes.object,
    modalStaticData: PropTypes.object,
    formData: PropTypes.object,
    allTags: PropTypes.object,

    tagSubmitStatus: PropTypes.string,
    programName: PropTypes.string,
    vendorName: PropTypes.string,

    onFormDataChange: PropTypes.func,
    submitTags: PropTypes.func,
};

ProgramTagsView.defaultProps = {
    staticData: {},
    modalStaticData: {},
    formData: {},
    allTags: {},

    tagSubmitStatus: "",
    programName: "",
    vendorName: "",

    onFormDataChange: () => {},
    submitTags: () => {},
};

export default ProgramTagsView;
