import { LOADING_STATUS } from "../../../common/static/Enums";
import { isCompleted } from "../../../utils/CommonUtils";
import GamedayScheduleActions from "../../gameday-schedule/redux/GamedayScheduleActions";
import NotificationsActions from "../../notifications/redux/NotificationsActions";
import { getData } from "../service/ClientContentService";
import { CLIENT_CONTENT_PAGES } from "../static/ClientContentStatics";
import ClientContentActionTypes from "./ClientContentActionTypes";

const ClientContentActions = {
    setControls: (payload) => ({
        type: ClientContentActionTypes.SET_CONTROLS,
        payload,
    }),
    setStaticDataApiStatus: (payload) => ({
        type: ClientContentActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),
    setDynamicDataApiStatus: (payload) => ({
        type: ClientContentActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),
    setStaticDataForPage: (payload) => ({
        type: ClientContentActionTypes.SET_STATIC_DATA_FOR_PAGE,
        payload,
    }),
    setComponentData: (payload) => ({
        type: ClientContentActionTypes.SET_COMPONENT_DATA,
        payload,
    }),
    getStaticData: (payload) => (dispatch, getState) => {
        if (
            isCompleted(
                getState().ClientContentReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            return Promise.resolve(
                getState().ClientContentReducer.staticData[payload.templateId]
            );
        }

        dispatch(
            ClientContentActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );

        return getData(payload.url).then((response) => {
            dispatch(
                ClientContentActions.setStaticDataForPage({
                    page: payload.templateId,
                    data: response.data.data,
                })
            );
            dispatch(
                ClientContentActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },
    // to reset application opening data when sidebar is clicked
    setApplicationLandingData: (payload) => (dispatch) => {
        switch (payload.applicationId) {
            case CLIENT_CONTENT_PAGES.NOTIFICATIONS.id:
                dispatch(NotificationsActions.setLandingData());
                break;

            case CLIENT_CONTENT_PAGES.GAMEDAY_SCHEDULE.id:
                dispatch(GamedayScheduleActions.setLandingData());
                break;

            // case CLIENT_CONTENT_PAGES.CARDS_DASHBOARD.id:
            //   dispatch(ClientCardsActions.setLandingData());
            //   break;

            default:
                break;
        }
    },
};

export default ClientContentActions;
