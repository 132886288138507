const EvDropdownMenuData = {
    data1: {
        label: "Dropdown",
        data: [
            {
                text: "Dashboard",
                type: "internal",
                id: "dashboard",
                action: "/cc/dashboard",
            },
            {
                text: "Users",
                type: "internal",
                id: "users",
                action: "/cc/Users",
            },
            {
                text: "Logout",
                type: "internal",
                id: "logout",
                action: "/cc/logout",
            },
        ],
    },
    minimalData1: {
        data: [
            {
                text: "is equal to",
                type: "internal",
                id: "isEqualTo",
                value: "isEqualTo",
                action: "",
            },
            {
                text: "is not equal to",
                type: "internal",
                id: "isNotEqualTo",
                value: "isNotEqualTo",
                action: "",
            },
            {
                text: "is equal to on of the following",
                type: "internal",
                id: "isEqualToOneOf",
                value: "isNotEqualTo",
                action: "",
            },
        ],
    },
};

export default EvDropdownMenuData;
