const DOMAINS = {
    LOCAL: "localhost",
    DEV: "dev-campaigns.ehealthreminders.net",
    PROD: "myevive",
};

const LOADING_STATUS = {
    NOT_YET_STARTED: "NOT_YET_STARTED",
    LOADING: "LOADING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
};

const RESPONSE_STATUS = {
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    SHOW_ERROR: "SHOW_ERROR", // replaces component with error message
    ERROR_MESSAGE: "ERROR_MESSAGE", // adds error message in the view
    HARD_REDIRECT: "HARD_REDIRECT", // for hard redirection such as login or any other page
};

const FORM_FIELD_TYPES = {
    TEXT: "TEXT",
    PREFIX_TEXT: "PREFIX_TEXT",
    COLOR: "COLOR",
    DROPDOWN: "DROPDOWN",
    LAZY_DROPDOWN: "LAZY_DROPDOWN",
    IMAGE_UPLOAD: "IMAGE_UPLOAD",
    BASE_FILE_UPLOAD: "BASE_FILE_UPLOAD",
    FILE_UPLOAD: "FILE_UPLOAD",
    TOGGLE: "TOGGLE",
    SMALL_TEXT_AREA: "SMALL_TEXT_AREA",
    LARGE_TEXT_AREA: "LARGE_TEXT_AREA",
    HIDDEN_TEXT: "HIDDEN_TEXT",
    PILLS_INPUT: "PILLS_INPUT", // Not used
    LAZY_MODAL_SELECTION: "LAZY_MODAL_SELECTION",
    MINIMAL_DROPDOWN: "MINIMAL_DROPDOWN",
    LAZY_MINIMAL_DROPDOWN: "LAZY_MINIMAL_DROPDOWN",
    CSV_TEXT: "CSV_TEXT",
    TSV_TEXT: "TSV_TEXT",
    RADIO: "RADIO",
    STRING_LABEL: "STRING_LABEL",
    LABEL: "LABEL",
    DATE: "DATE",
    TIME: "TIME",
    DATE_TIME_ZONE: "DATE_TIME_ZONE",
    BULLETS: "BULLETS",
    FORM_TEMPLATE: "FORM_TEMPLATE",
    TEXT_LIST: "TEXT_LIST",
    CHECKLIST_DROPDOWN: "CHECKLIST_DROPDOWN",
    DESCRIPTION_TEXT: "DESCRIPTION_TEXT",
    CRITERIA_BUILDER: "CRITERIA_BUILDER",
};

const CONTENT_FIELD_TYPES = {
    LABEL: "LABEL",
    LAZY_TEXT: "LAZY_TEXT",
    OPTIONS: "OPTIONS",
    DATE: "DATE",
    TIME: "TIME",
    CRITERIA_ARRAY: "CRITERIA_ARRAY",
    IMAGE: "IMAGE",
    CARDS_ARRAY: "CARDS_ARRAY",
    PILLS_ARRAY: "PILLS_ARRAY",
    ARRAY: "ARRAY",
};

const FORM_VISIBILITY_MODE = {
    ALWAYS: "ALWAYS",
    ONLY_IF_VALUE_PRESENT: "ONLY_IF_VALUE_PRESENT",
    DEPENDENCY_KEY: "DEPENDENCY_KEY",
};

const SIDE_BAR_VISIBILITY_MODE = {
    DEFAULT: "DEFAULT",
    DEPENDENCY_KEY: "DEPENDENCY_KEY",
};

const TEXT_AREA_ROW_COUNT = {
    SMALL_TEXT_AREA: "2",
    LARGE_TEXT_AREA: "8",
};

const CSS_TRANSFORM_PROPERTIES = {
    OPACITY: "opacity",
    MAX_HEIGHT: "max-height",
};

const BASE_URL = "/cc";

const ROUTES = {
    DASHBOARD: `${BASE_URL}/dashboard`,
    USER: `${BASE_URL}/user`,
    CLIENT_DETAILS: `${BASE_URL}/client/details`,
    LOGIN: `${BASE_URL}/api/login`,
};

const ICON_NAMES = {
    CHECKBOX_CHECK: "CHECKBOX_CHECK",
    CHECKBOX_UNCHECK: "CHECKBOX_UNCHECK",
    CHECK: "CHECK",
};

const CLIENT_PAGE_PATHS = {
    CLIENT_DETAILS: {
        path: "/cc/client/details",
        id: "client_details",
    },
    MANAGE_PROGRAMS: {
        path: "/cc/client/manage-programs",
        id: "manage_programs",
    },
    CLIENT_CONTENT: {
        path: "/cc/client/client-content",
        id: "client_content",
    },
    USER_MAPPING: {
        path: "/cc/client/user-mapping",
        id: "user_mapping",
    },
    PRE_PROD: {
        path: "/cc/client/pre-prod",
        id: "pre_prod",
    },
};

const USER_MAPPING_PAGES = {
    MAIN_PAGE: {
        id: "userMappingMainPage",
    },
    UNLINKED_PAGE: {
        id: "userMappingUnlinkedPage",
    },
    LINKED_PAGE: {
        id: "userMappingLinkedPage",
    },
};

const USER_MAPPING_COMPONENTS = {
    SIDE_BAR: {
        id: "userMappingSideBar",
    },
    ALL_TAGS: {
        id: "userMappingAllTags",
    },
    UNLINKED_TAGS: {
        id: "unlinkedTags",
    },
    LINKED_TAGS: {
        id: "linkedTags",
    },
    TAG_ITEM_STATIC: {
        id: "userMappingTagItemStatic",
    },
    DATA_FIELD_MODAL: {
        id: "userMappingTagItemStatic",
    },
    SAVED_POPULATION: {
        id: "savedPopulation",
    },
};

const CTA_TYPE = {
    NONE: "NONE",
    HREF: "HREF",
    INTERNAL: "INTERNAL",
    ACTION: "ACTION",
};

// used for internal cases
const CTA_ACTIONS = {
    REMOVE: "REMOVE",
    CHANGE_TAB: "CHANGE_TAB",
};

const PREVIEW_CARD_TYPES = {
    LOGO: "LOGO",
    BASIC_CARD: "BASIC_CARD",
    CTA_CARD: "CTA_CARD",
    DETAILED_CARD: "DETAILED_CARD",

    // NEW
    QUESTION_CARD: "QUESTION_CARD",
    CHECKLIST_CARD: "CHECKLIST_CARD",
    IMAGE_PORTRAIT: "IMAGE_PORTRAIT",
    IMAGE_LANDSCAPE: "IMAGE_LANDSCAPE",
    ACTION_CARD: "ACTION_CARD",
    WHERE_TO_CARD: "WHERE_TO_CARD",
    EVIVE_360_CARD: "EVIVE_360_CARD",
    RESOURCE_CARD: "RESOURCE_CARD",
    GAME_CARD_CONTENT_CARD: "GAME_CARD_CONTENT_CARD",
};

const FILE_UPLOADER_TEMPLATE_IDS = {
    BULK_LOADER: "static_dropdown_bulk_loader",
};

const ACTION_TYPES = {
    POSITIVE: "POSITIVE",
    NEGATIVE: "NEGATIVE",
};

const LIVE_STATUS = {
    DRAFT: "DRAFT",
    SCHEDULED: "SCHEDULED",
    LIVE: "LIVE",
};

const TABLE_FIELD_TYPES = {
    TEXT: "TEXT",
    PRIMARY_TEXT: "PRIMARY_TEXT",
    TIME_STAMP: "TIME_STAMP",
    DATA_OPTIONS: "DATA_OPTIONS",
};

const SERVER_FORMATS = {
    FULL_DATE_TIME: "MMM D YYYY...",
    DATE: "YYYY-MM-DD",
    TIME: "HH:mm:SS",
};

const UI_FORMATS = {
    FULL_DATE: "MM/DD/YYYY",
    FULL_TIME_MERIDIEM: "h:mm A",
    FULL_TIME_WITH_SECONDS_MERIDIEM: "hh:mm:ss A",
};

const TAGS_TYPE = {
    PRESET: "PRESET",
    CUSTOM: "CUSTOM",
    RESTRICTED: "RESTRICTED",
};

const CACHE_DATA_TYPE = {
    TAGS: "CACHE_TAGS",
    FORM_DROPDOWN: "FORM_DROPDOWN",
    LAZY_MODAL: "LAZY_MODAL",
};

const HTTP_METHODS = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
};

const USER_BAR_ICONS = {
    PRE_PROD: "PRE_PROD",
};

const KEY_CODES = {
    COMMA: 188,
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    BACKSPACE: 8,
    SHIFT: 16,
    LEFT_ARROW: 37,
    RIGHT_ARROW: 39,
    A: 65,
    Z: 90,
    SPACE: 32,
    NUMBER_0: 48,
    NUMBER_9: 57,
};

const VENDOR_CARD_TYPE = {
    DEFAULT_VENDOR: "DEFAULT_VENDOR",
    CLIENT_VENDOR: "CLIENT_VENDOR",
};

const DEFAULT_LOADING_TEXT = "Loading...";

const POPULATION_TYPES = {
    TAGS: "TAGS",
    CSV: "CSV",
};

export {
    LOADING_STATUS,
    FORM_FIELD_TYPES,
    CONTENT_FIELD_TYPES,
    FORM_VISIBILITY_MODE,
    ROUTES,
    BASE_URL,
    CSS_TRANSFORM_PROPERTIES,
    RESPONSE_STATUS,
    ICON_NAMES,
    CLIENT_PAGE_PATHS,
    TEXT_AREA_ROW_COUNT,
    CTA_TYPE,
    DOMAINS,
    PREVIEW_CARD_TYPES,
    CTA_ACTIONS,
    USER_MAPPING_PAGES,
    USER_MAPPING_COMPONENTS,
    FILE_UPLOADER_TEMPLATE_IDS,
    ACTION_TYPES,
    LIVE_STATUS,
    TABLE_FIELD_TYPES,
    SERVER_FORMATS,
    UI_FORMATS,
    TAGS_TYPE,
    CACHE_DATA_TYPE,
    HTTP_METHODS,
    KEY_CODES,
    USER_BAR_ICONS,
    VENDOR_CARD_TYPE,
    DEFAULT_LOADING_TEXT,
    POPULATION_TYPES,
    SIDE_BAR_VISIBILITY_MODE,
};
