const ClientDetailsActionTypes = {
    SET_CONTROLS: "CLIENT_DETAILS/SET_CONTROLS",
    SET_STATIC_DATA_API_STATUS: "CLIENT_DETAILS/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS: "CLIENT_DETAILS/SET_DYNAMIC_DATA_API_STATUS",
    SET_STATIC_DATA_FOR_PAGE: "CLIENT_DETAILS/SET_STATIC_DATA_FOR_PAGE",
    SET_COMPONENT_DATA: "CLIENT_DETAILS/SET_COMPONENT_DATA",
    RESET_CLIENT_DETAILS_DYNAMIC_DATA:
        "CLIENT_DETAILS/RESET_CLIENT_DETAILS_DYNAMIC_DATA",
};

export default ClientDetailsActionTypes;
