import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvBackButton,
    EvButton,
    EvContentModal,
    EvDivider,
    EvHeaderView,
    EvSearchBar,
    EvText,
} from "../../../../common/components";
import VendorProgramSelectionCard from "./VendorProgramSelectionCard";
import NewVendorProgramSelectionModal from "./NewVendorProgramSelectionModal";
import { isNullOrEmpty } from "../../../../utils/CommonUtils";
import {
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAMS_STATICS,
} from "../../statics/VendorProgramsStatics";
import EvLogger from "../../../../utils/EvLogger";

import "../styles/new-vendor-program-selection.scss";

const styles = {
    container: "ev__new-vendor-program-selection__container",
    header: {
        container: "ev__new-vendor-program-selection__header-container",
    },
    actionView: {
        container: "ev__new-vendor-program-selection__action-view-container",
        button: "ev__new-vendor-program-selection__action-view-button",
    },
    searchBar: "ev__new-vendor-program-selection__search-bar",
    infoContainer: "ev__new-vendor-program-selection__info-container",
    vendorList: {
        container: "ev__new-vendor-program-selection__vendor-list-container",
        card: "ev__new-vendor-program-selection__vendor-list-card",
    },
};

const NewVendorProgramSelection = (props) => {
    const {
        clientName,
        staticData,
        allVendorData,
        filteredVendorData,
        clientVendorData,
        programsData,
        selectedVendorPrograms,

        onSearchKeyChange,
        onSelectedVendorProgramsChange,
        onClientProgramAdd,
        goToInternalPage,
        goBackAction,
    } = props;

    const [expandedVendorId, setExpandedVendorId] = useState("");

    const [
        isProgramSelectionModalOpen,
        setIsProgramSelectionModalOpen,
    ] = useState(false);

    const onClientProgramsSelectClick = useCallback(() => {
        setIsProgramSelectionModalOpen(true);
    }, []);

    const onProgramSelectionModalClose = useCallback(() => {
        setIsProgramSelectionModalOpen(false);
    }, []);

    const onVendorSelect = useCallback(
        (e, callbackValues) => {
            EvLogger.log(
                `VendorProgram: User expanded card for vendorId: ${callbackValues.vendorId}`
            );
            if (callbackValues.vendorId === expandedVendorId) {
                setExpandedVendorId("");
            } else {
                setExpandedVendorId(callbackValues.vendorId);
            }
        },
        [expandedVendorId]
    );

    const onClientProgramAddLocal = useCallback(
        (programItemsData) => {
            onClientProgramAdd(programItemsData);
            onProgramSelectionModalClose();

            // if client vendor is already present
            if (clientVendorData.id) {
                setExpandedVendorId(clientVendorData.id);
            }
        },
        [clientVendorData.id, onClientProgramAdd, onProgramSelectionModalClose]
    );

    const onProceedClick = useCallback(() => {
        EvLogger.log(
            `VendorProgram: User clicked to proceed to preview page with data: ${JSON.stringify(
                selectedVendorPrograms
            )}`
        );
        goToInternalPage(VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM_REVIEW.id);
    }, [goToInternalPage, selectedVendorPrograms]);

    const getBackButton = () => (
        <EvBackButton onClickHandler={goBackAction}>
            {VENDOR_PROGRAMS_STATICS.BACK_BUTTON_TEXT}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            dangerous
            header={staticData.header}
            headerResolvers={{ clientName }}
            description={staticData.subHeader}
            className={styles.header.container}
        />
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                secondary
                className={styles.actionView.button}
                onClickHandler={onClientProgramsSelectClick}
            >
                {staticData.addClientVendorCta.text}
            </EvButton>
            <EvButton
                primaryFilled
                className={styles.actionView.button}
                onClickHandler={onProceedClick}
            >
                {stringTemplate(staticData.newVendorCta.text, {
                    count: Object.keys(selectedVendorPrograms).length,
                })}
            </EvButton>
        </div>
    );

    const getSearchBar = () => (
        <EvSearchBar
            className={styles.searchBar}
            placeholder={staticData.searchPlaceholder}
            onChange={onSearchKeyChange}
        />
    );

    const getClientVendors = () => (
        <VendorProgramSelectionCard
            key={clientVendorData.id}
            id={clientVendorData.id}
            isExpanded={clientVendorData.id === expandedVendorId}
            className={styles.vendorList.card}
            staticData={staticData.vendor}
            vendorData={clientVendorData}
            programsData={programsData}
            selectedPrograms={selectedVendorPrograms[clientVendorData.id]}
            onProgramsChange={onSelectedVendorProgramsChange}
            onVendorSelect={onVendorSelect}
        />
    );

    const getDefaultVendors = (vendorId) => (
        <VendorProgramSelectionCard
            key={vendorId}
            id={vendorId}
            isExpanded={vendorId === expandedVendorId}
            className={styles.vendorList.card}
            staticData={staticData.vendor}
            vendorData={filteredVendorData.value[vendorId]}
            programsData={programsData}
            selectedPrograms={selectedVendorPrograms[vendorId]}
            onProgramsChange={onSelectedVendorProgramsChange}
            onVendorSelect={onVendorSelect}
        />
    );

    const getInfoView = (message) => (
        <div className={styles.infoContainer}>
            <EvText italics>{message}</EvText>
        </div>
    );

    const getVendorsView = () => (
        <div className={styles.vendorList.container}>
            {!isNullOrEmpty(clientVendorData) && getClientVendors()}
            {filteredVendorData.ids.length <= 0 &&
                allVendorData.ids.length > 0 &&
                getInfoView(staticData.searchNoResultMessage)}
            {filteredVendorData.ids.length > 0 &&
                filteredVendorData.ids.map(getDefaultVendors)}
        </div>
    );

    const getProgramSelectionModal = () => (
        <EvContentModal
            isVisible={isProgramSelectionModalOpen}
            onExitComplete={onProgramSelectionModalClose}
        >
            <NewVendorProgramSelectionModal
                onModalCloseClick={onProgramSelectionModalClose}
                programsData={programsData}
                staticData={staticData.clientProgramsModal}
                currentPrograms={clientVendorData.programs}
                onProgramSelect={onClientProgramAddLocal}
            />
        </EvContentModal>
    );

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getActionView()}
            <EvDivider marginVertical={20} />
            {getSearchBar()}
            {getVendorsView()}
            {getProgramSelectionModal()}
        </div>
    );
};

NewVendorProgramSelection.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    staticData: PropTypes.object,
    allVendorData: PropTypes.object,
    filteredVendorData: PropTypes.object,
    clientVendorData: PropTypes.object,
    programsData: PropTypes.object,
    selectedVendorPrograms: PropTypes.object,

    onSearchKeyChange: PropTypes.func,
    onSelectedVendorProgramsChange: PropTypes.func,
    onClientProgramAdd: PropTypes.func,
    goToInternalPage: PropTypes.func,
    goBackAction: PropTypes.func,
};

NewVendorProgramSelection.defaultProps = {
    clientCode: "",
    clientName: "",
    staticData: {},
    allVendorData: {},
    filteredVendorData: {},
    clientVendorData: {},
    programsData: {},
    selectedVendorPrograms: {},

    onSearchKeyChange: () => {},
    onSelectedVendorProgramsChange: () => {},
    onClientProgramAdd: () => {},
    goToInternalPage: () => {},
    goBackAction: () => {},
};

export default NewVendorProgramSelection;
