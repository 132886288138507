import React from "react";
import PropTypes from "prop-types";

import { PREVIEW_CARD_TYPES } from "../../static/Enums";
import {
    Ev360Card,
    EvCardWhereTo,
    EvGameContentCard,
    EvPreviewActionCard,
    EvPreviewChecklistCard,
    EvPreviewQuestionCard,
    EvPreviewResourceCard,
} from "./index";

const EvPreviewCardResolver = (props) => {
    const { previewType, previewVariables, staticContent } = props;
    switch (previewType) {
        case PREVIEW_CARD_TYPES.QUESTION_CARD:
            return (
                <EvPreviewQuestionCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                />
            );

        case PREVIEW_CARD_TYPES.CHECKLIST_CARD:
            return (
                <EvPreviewChecklistCard
                    header={previewVariables.header}
                    list={previewVariables.list}
                    bgColor={staticContent.bgColor}
                    fillColor={staticContent.fillColor}
                    iconName={staticContent.iconName}
                />
            );

        case PREVIEW_CARD_TYPES.ACTION_CARD:
            return (
                <EvPreviewActionCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                    actionText={previewVariables.actionText}
                />
            );

        case PREVIEW_CARD_TYPES.WHERE_TO_CARD:
            return (
                <EvCardWhereTo
                    header={previewVariables.header}
                    description={previewVariables.description}
                    list={previewVariables.list}
                    amountText={previewVariables.amountText}
                    ctaText={previewVariables.ctaText}
                />
            );

        case PREVIEW_CARD_TYPES.EVIVE_360_CARD:
            return (
                <Ev360Card
                    header={previewVariables.header}
                    description={previewVariables.description}
                    category={previewVariables.category}
                    ctaText={previewVariables.ctaText}
                    positiveButtonText={previewVariables.positiveButtonText}
                    negativeButtonText={previewVariables.negativeButtonText}
                    logoImage={previewVariables.logoImage}
                    backgroundImage={previewVariables.backgroundImage}
                    backgroundColor={previewVariables.backgroundColor}
                />
            );

        case PREVIEW_CARD_TYPES.RESOURCE_CARD:
            return (
                <EvPreviewResourceCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                    logoImage={previewVariables.logoImage}
                    backgroundColor={previewVariables.backgroundColor}
                    backgroundImage={previewVariables.backgroundImage}
                    ctaText={previewVariables.ctaText}
                />
            );

        case PREVIEW_CARD_TYPES.GAME_CARD_CONTENT_CARD:
            return (
                <EvGameContentCard
                    header={previewVariables.header}
                    description={previewVariables.description}
                    imageUrl={previewVariables.imageUrl}
                    subHeader1={previewVariables.subHeader1}
                    subHeader2={previewVariables.subHeader2}
                    content1={previewVariables.content1}
                    content2={previewVariables.content2}
                />
            );

        default:
            return <span />;
    }
};

EvPreviewCardResolver.propTypes = {
    previewType: PropTypes.string,
    previewVariables: PropTypes.object,
    staticContent: PropTypes.object,
};

EvPreviewCardResolver.defaultProps = {
    previewType: "",
    previewVariables: {},
    staticContent: {},
};

export default EvPreviewCardResolver;
