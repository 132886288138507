import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { EvLoadingPage } from "../../../common/components";
import { CTA_TYPE } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import {
    getSelectedApplicationIdFromSidebar,
    isCompleted,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import ClientContentActions from "../redux/ClientContentActions";
import {
    CLIENT_CONTENT_PAGES,
    CLIENT_CONTENT_TEMPLATES,
} from "../static/ClientContentStatics";
import ClientContentView from "./ClientContentView";

const ClientContentContainer = ({
    clientCode,
    clientName,
    controls,
    staticData,
    location,
    getStaticData,
    navigateToPage,
    setApplicationLandingData,
}) => {
    useEffect(() => {
        getStaticData({
            url: API_URLS.CLIENT_CONTENT.STATIC_DATA,
            templateId: CLIENT_CONTENT_TEMPLATES.STATIC.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        // if static data not yet loaded
        if (isNullOrEmpty(staticData.sideBarsTabs)) {
            return;
        }

        // if directly lands on dashboard
        if (location.pathname === CLIENT_CONTENT_PAGES.DASHBOARD.path) {
            navigateToPage({
                path: staticData.sideBarsTabs[0].action,
                state: location.state,
            });
        }
    }, [staticData, location.pathname, navigateToPage, location.state]);

    const selectedApplicationId = useMemo(
        () =>
            getSelectedApplicationIdFromSidebar(
                staticData.sideBarsTabs,
                location.pathname
            ),
        [staticData, location.pathname]
    );

    const onSidebarClick = useCallback(
        (_, callbackOptions) => {
            if (callbackOptions.type === CTA_TYPE.INTERNAL) {
                const newSelectedApplicationId = getSelectedApplicationIdFromSidebar(
                    staticData.sideBarsTabs,
                    callbackOptions.action
                );

                setApplicationLandingData({
                    applicationId: newSelectedApplicationId,
                });

                navigateToPage({
                    path: callbackOptions.action,
                    state: location.state,
                });
            }
        },
        [location, navigateToPage, staticData, setApplicationLandingData]
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[CLIENT_CONTENT_TEMPLATES.STATIC.id]
        )
    ) {
        return <EvLoadingPage animatedFadeIn />;
    }

    return (
        <div style={{ marginTop: "12rem" }}>
            <ClientContentView
                clientCode={clientCode}
                clientName={clientName}
                staticData={staticData}
                selectedApplicationId={selectedApplicationId}
                onSidebarClick={onSidebarClick}
            />
        </div>
    );
};

ClientContentContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    location: PropTypes.object,
    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
    setApplicationLandingData: PropTypes.func,
};

ClientContentContainer.defaultProps = {
    clientCode: "",
    clientName: "",
    controls: {},
    staticData: {},
    location: {},
    getStaticData: () => {},
    navigateToPage: () => {},
    setApplicationLandingData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientContentReducer.controls,
    staticData:
        state.ClientContentReducer.staticData[
            CLIENT_CONTENT_TEMPLATES.STATIC.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientContentActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
    setApplicationLandingData: (payload) =>
        dispatch(ClientContentActions.setApplicationLandingData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientContentContainer);
