import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CACHE_DATA_TYPE } from "../../../common/static/Enums";
import SavedPopulationDashboard from "./SavedPopulationDashboard";
import SavedPopulationNewEditPopulation from "./SavedPopulationNewEditPopulation";
import SavedPopulationTagSelection from "./SavedPopulationTagSelection";
import { EvText } from "../../../common/components";
import SavedPopulationActions from "../redux/SavedPopulationActions";
import CacheActions from "../../../reducers/cache/CacheActions";
import { SAVED_POPULATION_TEMPLATES } from "../static/SavedPopulationStatics";

const SavedPopulationContainer = (props) => {
    const { clientCode, controls, resetData, clearCacheByType } = props;

    useEffect(() => {
        clearCacheByType({ type: CACHE_DATA_TYPE.TAGS });
    }, [clearCacheByType]);

    // cleanup code
    useEffect(
        () => () => {
            resetData();
        },
        [resetData]
    );

    const getContent = () => {
        switch (controls.selectedPageId) {
            case SAVED_POPULATION_TEMPLATES.DASHBOARD.id:
                return <SavedPopulationDashboard clientCode={clientCode} />;

            case SAVED_POPULATION_TEMPLATES.NEW_EDIT_POPULATION.id:
                return (
                    <SavedPopulationNewEditPopulation clientCode={clientCode} />
                );

            case SAVED_POPULATION_TEMPLATES.TAG_SELECTION.id:
                return <SavedPopulationTagSelection clientCode={clientCode} />;

            default:
                return <EvText error>Page not found</EvText>;
        }
    };

    return getContent();
};

SavedPopulationContainer.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    // actions
    resetData: PropTypes.func,
    clearCacheByType: PropTypes.func,
};

SavedPopulationContainer.defaultProps = {
    clientCode: "",
    controls: {},
    // actions
    resetData: () => {},
    clearCacheByType: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.SavedPopulationReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    resetData: (payload) => dispatch(SavedPopulationActions.resetData(payload)),
    clearCacheByType: (payload) =>
        dispatch(CacheActions.clearCacheByType(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SavedPopulationContainer);
