import PropTypes from "prop-types";
import React from "react";
import { EvText } from "../../../common/components";
import { EvClientCard } from "../../../common/components/ev-cards";
import "../styles/client-dashboard-view.scss";
import ClientDashboardActivityCard from "./ClientDashboardActivityCard";
import ClientDashboardCampaignsCard from "./ClientDashboardCampaignsCard";
import ClientDashboardVendorCard from "./ClientDashboardVendorCard";

const styles = {
    container: "ev__client-dashboard-view__container",
    header: {
        container: "ev__client-dashboard-view__header-container",
        subHeaderText: "ev__client-dashboard-view__header-sub-header-text",
    },
    content: {
        container: "ev__client-dashboard-view__content-container",
        cardWrapper: "ev__client-dashboard-view__content-card-wrapper",
        primaryContent: "ev__client-dashboard-view__content-primary",
        secondaryContent: "ev__client-dashboard-view__content-secondary",
        // secondaryImage: "ev__client-dashboard-view__content-secondary-image",
        // button: "ev__client-dashboard-view__content-button",
    },
};

const ClientDashboardView = (props) => {
    const {
        clientCode,
        clientDetails,
        staticData,
        goToPage,
        goToEditPage,
    } = props;

    // Temporary, this button will be replaces with card later on
    // const goToBenefits = useCallback(() => {
    //   goToPage(CLIENT_DETAILS_ROUTES.CLIENT_BENEFITS.path);
    // }, [goToPage]);

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
            <EvText defaultDarkBold className={styles.header.subHeaderText}>
                {staticData.subHeader}
            </EvText>
        </div>
    );

    const getClientCard = () => (
        <div className={styles.content.cardWrapper}>
            <EvClientCard
                header={staticData.clientCard.header}
                ctaText={staticData.clientCard.ctaText}
                content={staticData.clientCard.content}
                values={clientDetails}
                onClickHandler={goToEditPage}
            />
        </div>
    );

    // const getBenefitCard = () => (
    //   <EvSimpleCard className={styles.content.card} noPadding>
    //     <EvText headingPrimary>Benefits</EvText>
    //     <EvButton
    //       onClickHandler={goToBenefits}
    //       primary
    //       className={styles.content.button}
    //     >
    //       {staticData.vendorProgramsCta.text}
    //     </EvButton>
    //   </EvSimpleCard>
    // );

    const getActivityCard = () => (
        <div className={styles.content.cardWrapper}>
            <ClientDashboardActivityCard
                staticData={staticData.activityCard}
                clientCode={clientCode}
                goToPage={goToPage}
            />
        </div>
    );

    const getVendorCard = () => (
        <div className={styles.content.cardWrapper}>
            <ClientDashboardVendorCard
                staticData={staticData.vendorCard}
                clientCode={clientCode}
                goToPage={goToPage}
            />
        </div>
    );

    const getCampaignsCard = () => (
        <div className={styles.content.cardWrapper}>
            <ClientDashboardCampaignsCard
                staticData={staticData.campaignsCard}
                clientCode={clientCode}
                goToPage={goToPage}
            />
        </div>
    );

    // const getContentView = () => (
    //   <div className={styles.content.container}>
    //     {getPrimaryContent()}
    //     <EvSimpleCard className={styles.content.secondaryContent}>
    //       <img
    //         className={styles.content.secondaryImage}
    //         src="https://59b602507414a6d288cf-98a1a17df509e6abbdfa88e2a08a0e33.ssl.cf1.rackcdn.com/command%20center/commandcenter_logo_hires.png"
    //       />goToPage
    //       <EvText>Coming Soon</EvText>
    //     </EvSimpleCard>
    //   </div>
    // );

    return (
        <div className={styles.container}>
            {getHeaderView()}
            <div className={styles.content.container}>
                <div className={styles.content.primaryContent}>
                    {getClientCard()}
                    {getActivityCard()}
                    {getCampaignsCard()}
                    {/*{getBenefitCard()}*/}
                </div>
                <div className={styles.content.secondaryContent}>
                    {getVendorCard()}
                </div>
            </div>
        </div>
    );
};

ClientDashboardView.propTypes = {
    staticData: PropTypes.object,
    clientCode: PropTypes.string,
    clientDetails: PropTypes.object,
    goToPage: PropTypes.func,
    goToEditPage: PropTypes.func,
};

ClientDashboardView.defaultProps = {
    staticData: {},
    clientCode: "",
    clientDetails: {},
    goToPage: () => {},
    goToEditPage: () => {},
};

export default ClientDashboardView;
