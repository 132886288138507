import ClientDetailsActionTypes from "./ClientDetailsActionTypes";
import { LOADING_STATUS } from "../../../common/static/Enums";

const initialState = {
    controls: {
        staticDataApiStatus: {},
        dynamicDataApiStatus: {},
        clientDetailsSubmitClientDetails: LOADING_STATUS.NOT_YET_STARTED,
    },
    staticData: {},
    dynamicData: {},
    clientData: {},
};

const ClientDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ClientDetailsActionTypes.SET_CONTROLS: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };
        }

        case ClientDetailsActionTypes.SET_STATIC_DATA_API_STATUS: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };
        }

        case ClientDetailsActionTypes.SET_DYNAMIC_DATA_API_STATUS: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {
                        ...state.controls.dynamicDataApiStatus,
                        ...action.payload,
                    },
                },
            };
        }

        case ClientDetailsActionTypes.SET_STATIC_DATA_FOR_PAGE: {
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.staticData,
                },
            };
        }

        case ClientDetailsActionTypes.SET_COMPONENT_DATA: {
            return {
                ...state,
                dynamicData: {
                    ...state.dynamicData,
                    [action.payload.componentId]: action.payload.data,
                },
            };
        }

        case ClientDetailsActionTypes.RESET_CLIENT_DETAILS_DYNAMIC_DATA: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    dynamicDataApiStatus: {},
                },
                dynamicData: {},
            };
        }

        default:
            return state;
    }
};

export default ClientDetailsReducer;
