import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { COLORS } from "../static/VmsStatics";

import "../styles/ev-divider.scss";

const EvDivider = (props) => {
    const styles = {
        container: "ev__ev-divider__container",
    };

    const { marginHorizontal, marginVertical } = props;

    const customClass = classNames(styles.container, props.className);

    return (
        <div
            className={customClass}
            style={{
                height: props.height,
                borderRadius: props.height / 2,
                backgroundColor: props.color,
                marginTop: marginVertical === null ? undefined : marginVertical,
                marginBottom:
                    marginVertical === null ? undefined : marginVertical,
                marginLeft:
                    marginHorizontal === null ? undefined : marginHorizontal,
                marginRight:
                    marginHorizontal === null ? undefined : marginHorizontal,
            }}
        />
    );
};

EvDivider.propTypes = {
    height: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    marginHorizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginVertical: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EvDivider.defaultProps = {
    height: 2,
    color: COLORS.WHITE_GHOST,
    className: "",
    marginHorizontal: null,
    marginVertical: null,
};

export default memo(EvDivider);
