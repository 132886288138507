import DashboardActionTypes from "./DashbaordActionTypes";
import { LOADING_STATUS } from "../../../common/static/Enums";

const initialState = {
    controls: {
        clientListDataLoadingStatus: LOADING_STATUS.NOT_YET_STARTED,
        clientListStaticDataLoadingStatus: LOADING_STATUS.NOT_YET_STARTED,
        staticDataApiStatus: {},
        dynamicDataApiStatus: {},
    },
    clientListData: {},
    clientListStaticData: {},
    staticData: {},
    dynamicData: {},
};

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DashboardActionTypes.SET_CONTROLS: {
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };
        }

        case DashboardActionTypes.SET_DATA: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case DashboardActionTypes.SET_STATIC_DATA_API_STATUS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    staticDataApiStatus: {
                        ...state.controls.staticDataApiStatus,
                        ...action.payload,
                    },
                },
            };

        case DashboardActionTypes.SET_STATIC_DATA:
            return {
                ...state,
                staticData: {
                    ...state.staticData,
                    [action.payload.templateId]: action.payload.data,
                },
            };

        default:
            return state;
    }
};

export default DashboardReducer;
