import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import stringTemplate from "string-template";
import {
    EvButton,
    EvDivider,
    EvIcon,
    EvLoader,
    EvSimpleCard,
    EvSimpleModal,
    EvText,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { COLORS } from "../../../common/static/VmsStatics";
import API_URLS from "../../../services/apiUrls";
import {
    dateFormatterFromFullZString,
    timeFormatterFromFullZString,
} from "../../../utils/DateTimeUtils";
import EvLogger from "../../../utils/EvLogger";
import { postData } from "../service/PreProdService";
import { PRE_PROD_CARD_STATUS } from "../statics/PreProdStatics";
import "../styles/pre-prod-checklist-card.scss";

// const CHECK_VALUES = {
//     CHECKED: "CHECKED",
//     UNCHECKED: "UNCHECKED",
// };

const styles = {
    container: "ev__pre-prod-checklist-card__container",
    simpleCard: "ev__pre-prod-checklist-card__simple-card",
    header: {
        container: "ev__pre-prod-checklist-card__header-container",
        text: "ev__pre-prod-checklist-card__header-text",
    },
    checklist: {
        container: "ev__pre-prod-checklist-card__checklist-container",
        itemContainer: "ev__pre-prod-checklist-card__checklist-item-container",
        itemValue: "ev__pre-prod-checklist-card__checklist-item-value",
    },
    info: {
        container: "ev__pre-prod-checklist-card__info-container",
    },
    actionView: {
        container: "ev__pre-prod-checklist-card__action-view-container",
        button: "ev__pre-prod-checklist-card__action-view-button",
        text: "ev__pre-prod-checklist-card__action-view-text",
    },
};

const PreProdChecklistCard = (props) => {
    const {
        clientCode,
        cardData,
        staticData,
        dataTypeStaticData,
        onEditCard,
        onCardApprove,
    } = props;

    const [approveCardApiStatus, setApproveCardApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const actionDate = useMemo(
        () => dateFormatterFromFullZString(cardData.cardDetails.actionDate),
        [cardData]
    );
    const actionTime = useMemo(
        () => timeFormatterFromFullZString(cardData.cardDetails.actionDate),
        [cardData]
    );

    const onApproveCardConfirm = useCallback(
        (e, callbackOptions) => {
            setApproveCardApiStatus(LOADING_STATUS.LOADING);
            postData(
                API_URLS.PRE_PROD.APPROVE_CARD,
                { clientCode, id: callbackOptions.cardId },
                {}
            )
                .then((approveResponse) => {
                    EvToast.success("Approved", "Card is approved for PROD", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    setApproveCardApiStatus(LOADING_STATUS.COMPLETED);
                    onCardApprove(callbackOptions.cardId);
                })
                .catch((errorResponse) => {
                    EvToast.error("Sorry", "Cannot approve card for PROD", {
                        icon: TOAST_ICON_OBJECTS.ALERT,
                    });
                    setApproveCardApiStatus(LOADING_STATUS.FAILED);
                    EvLogger.errorWithObject(
                        e,
                        "PreProdChecklistCard onApprove"
                    );
                });
        },
        [onCardApprove, clientCode]
    );

    const onApproveCardClick = useCallback(
        (e, callbackOptions) => {
            // already done, dont do anything
            if (
                approveCardApiStatus === LOADING_STATUS.LOADING ||
                approveCardApiStatus === LOADING_STATUS.COMPLETED
            ) {
                return;
            }
            EvSimpleModal.setData({
                ...staticData.approveCardConfirmationModal,
                onPositiveAction: () => {
                    onApproveCardConfirm(e, callbackOptions);
                },
            }).show();
        },
        [
            onApproveCardConfirm,
            approveCardApiStatus,
            staticData.approveCardConfirmationModal,
        ]
    );

    // takes directly from isApprovable bool now
    const isApproveEnabled = useMemo(
        () =>
            // !cardData.checklistSet.some(
            //   check => check.check !== CHECK_VALUES.CHECKED
            // ),
            !!cardData.isApprovable,
        [cardData]
    );

    const infoMessage = useMemo(() => {
        if (cardData.message && dataTypeStaticData.message) {
            return dataTypeStaticData.message[cardData.message] || "";
        }
        return "";
    }, [cardData, dataTypeStaticData]);

    const getHeaderView = () => {
        const subHeaderTemplate =
            cardData.status === PRE_PROD_CARD_STATUS.APPROVED
                ? dataTypeStaticData.approvedSubHeader
                : dataTypeStaticData.subHeader;
        return (
            <div className={styles.header.container}>
                <EvText defaultDark>
                    {stringTemplate(
                        dataTypeStaticData.header,
                        cardData.cardDetails
                    )}
                </EvText>
                <EvText smallNormal className={styles.header.text}>
                    {stringTemplate(subHeaderTemplate, {
                        ...cardData.cardDetails,
                        actionDate,
                        actionTime,
                    })}
                </EvText>
            </div>
        );
    };

    const getIcon = (type) => {
        const iconParams = staticData.values[type];
        return (
            <EvIcon
                iconName={iconParams.iconName}
                size={2}
                circular
                fillColor={iconParams.fillColor}
                bgColor={iconParams.backgroundColor}
            />
        );
    };

    const getChecklistItemView = (itemData) => (
        <div key={itemData.id} className={styles.checklist.itemContainer}>
            {getIcon(itemData.check)}
            <EvText className={styles.checklist.itemValue}>
                {itemData.value}
            </EvText>
        </div>
    );

    const getCheckListView = () => (
        <div className={styles.checklist.container}>
            {cardData.checklistSet.map(getChecklistItemView)}
        </div>
    );

    const getInfoView = (msg) => (
        <div className={styles.info.container}>
            <EvText italics>{msg}</EvText>
        </div>
    );

    const getActionView = () => {
        // if approved, dont show any buttons
        if (approveCardApiStatus === LOADING_STATUS.COMPLETED) {
            return (
                <div className={styles.actionView.container}>
                    <EvIcon
                        iconName="CHECK"
                        size={3}
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                    <EvText italics className={styles.actionView.text}>
                        {staticData.approvedText}
                    </EvText>
                </div>
            );
        }
        return (
            <div className={styles.actionView.container}>
                <EvButton
                    primary={isApproveEnabled}
                    primaryFilledDisabled={!isApproveEnabled}
                    noClickCallbackOnDisabled
                    className={styles.actionView.button}
                    onClickHandler={onApproveCardClick}
                    callbackValues={{ cardId: cardData.id }}
                >
                    {approveCardApiStatus === LOADING_STATUS.LOADING ? (
                        <EvLoader size={3} />
                    ) : (
                        staticData.approveCta.text
                    )}
                </EvButton>
                <EvButton
                    secondary
                    className={styles.actionView.button}
                    onClickHandler={onEditCard}
                    callbackValues={{
                        cardId: cardData.id,
                        path: dataTypeStaticData.target.path,
                        params: cardData.target.params,
                    }}
                >
                    {staticData.editCta.text}
                </EvButton>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <EvSimpleCard noPadding className={styles.simpleCard}>
                {getHeaderView()}
                <EvDivider />
                {getCheckListView()}
                {infoMessage && getInfoView(infoMessage)}
                {cardData.status === PRE_PROD_CARD_STATUS.PENDING &&
                    getActionView()}
            </EvSimpleCard>
        </div>
    );
};

PreProdChecklistCard.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    cardData: PropTypes.object,
    dataTypeStaticData: PropTypes.object,
    onEditCard: PropTypes.func,
    onCardApprove: PropTypes.func,
};

PreProdChecklistCard.defaultProps = {
    clientCode: PropTypes.string,
    staticData: {},
    cardData: {},
    dataTypeStaticData: {},
    onEditCard: () => {},
    onCardApprove: () => {},
};

export default PreProdChecklistCard;
