import React from "react";
import PropTypes from "prop-types";

import {
    EvText,
    EvSubmitActionView,
    EvBackButton,
} from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import { EDIT_CLIENT_DETAILS_STATICS } from "../statics/ClientDashboardStatics";

import "../styles/client-details-edit.scss";

const ClientDetailsEditView = (props) => {
    const styles = {
        container: "ev__client-details-edit__container",
        header: {
            container: "ev__client-details-edit__header-container",
        },
        formContainer: "ev__client-details-edit__form-container",
        action: {
            container: "ev__client-details-edit__action-container",
            button: "ev__client-details-edit__action-button",
        },
        // left here for teh submit button only
        formFields: {
            container: "ev__client-details-edit__form-fields-container",
            labelContainer:
                "ev__client-details-edit__form-fields-label-container",
        },
        formReview: {
            container: "ev__client-details-edit__form-review-container",
            imageHolder: "ev__client-details-edit__form-review-image-holder",
            imageItem: "ev__client-details-edit__form-review-image-item",
        },
    };

    const {
        clientCode,
        formData,
        initialFormData,
        formFields,
        staticData,
        submitStatus,

        setFormDataState,
        onFormSubmit,
        onBackClick,
    } = props;

    const getActionView = () => (
        <div className={styles.action.container}>
            <div className={styles.formFields.labelContainer} />
            <EvSubmitActionView
                onSubmitClick={onFormSubmit}
                loadingStatus={submitStatus}
                buttonText={staticData.submitCta.text}
                className={styles.action.button}
            />
        </div>
    );

    const renderInputFields = () => (
        <div className={styles.formFields.container}>
            <EvFormRenderer
                formFields={formFields}
                formData={formData}
                setFormDataState={setFormDataState}
                params={{ clientCode }}
                initialFormData={initialFormData}
            />
            {getActionView()}
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
        </div>
    );

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackClick}>
            {EDIT_CLIENT_DETAILS_STATICS.BACK_BUTTON_TEXT}
        </EvBackButton>
    );

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            <div className={styles.formContainer}>
                {renderInputFields()}
                {/* {!isNullOrEmpty(staticData.logoPreview) && */}
                {/*  renderImagePreview(staticData.logoPreview)} */}
            </div>
        </div>
    );
};

ClientDetailsEditView.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    formData: PropTypes.object,
    initialFormData: PropTypes.object,
    formFields: PropTypes.array,
    submitStatus: PropTypes.string,

    setFormDataState: PropTypes.func,
    onFormSubmit: PropTypes.func,
    onBackClick: PropTypes.func,
};

ClientDetailsEditView.defaultProps = {
    clientCode: "",
    staticData: {},
    formData: {},
    initialFormData: {},
    formFields: [],
    submitStatus: "",

    setFormDataState: () => {},
    onFormSubmit: () => {},
    onBackClick: () => {},
};

export default ClientDetailsEditView;
