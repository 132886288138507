const DASHBOARD_TEMPLATES = {
    CLIENT_STATICS: {
        id: "dashboard__clientStatics",
    },
    USERS_LIST_STATICS: {
        id: "dashboard__usersListStatics",
    },
};

const DASHBOARD_LOADING_MESSAGES = {
    CLIENTS: "Loading your clients...",
    USERS: "Loading users...",
};

const ADD_EDIT_USER_TEMPLATES = {
    STATIC: {
        id: "dashboard__addEditUserStatics",
    },
};

const ADD_EDIT_USER_STATICS = {
    SAVED: "User details saved",
    FORM_INVALID: "Some of the form values are not valid",
};

export {
    DASHBOARD_TEMPLATES,
    DASHBOARD_LOADING_MESSAGES,
    ADD_EDIT_USER_TEMPLATES,
    ADD_EDIT_USER_STATICS,
};
