import React, { useCallback } from "react";
import PropTypes from "prop-types";
// import stringTemplate from "string-template";

import {
    EvBackButton,
    // EvButton,
    // EvSimpleModal,
    EvSubmitActionView,
    EvText,
    // EvTextDivider,
} from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
// import { TAGS_TYPE } from "../../../common/static/Enums";
// import { parseFormDataForNames } from "../../../utils/FormUtils";
// import { isNullOrEmpty, removeKeyFromObject } from "../../../utils/CommonUtils";

import "../styles/ev-notifications-edit-population-view.scss";

const styles = {
    container: "ev__ev-notifications-edit-population-view__container",
    backContainer: "ev__ev-notifications-edit-population-view__back-container",
    populationAction: {
        container:
            "ev__ev-notifications-edit-population-view__population-action-container",
        button: "ev__ev-notifications-edit-population-view__population-action-button",
    },
    headerContainer:
        "ev__ev-notifications-edit-population-view__header-container",
    headerText: "ev__ev-notifications-edit-population-view__header-text",
    detailsContainer:
        "ev__ev-notifications-edit-population-view__details-container",
    criteria: {
        container:
            "ev__ev-notifications-edit-population-view__criteria-container",
        itemListContainer:
            "ev__ev-notifications-edit-population-view__criteria-list-container",
        itemContainer:
            "ev__ev-notifications-edit-population-view__criteria-item-container",
        itemDivider:
            "ev__ev-notifications-edit-population-view__criteria-item-divider",
        itemHeader:
            "ev__ev-notifications-edit-population-view__criteria-item-header",
        headerText:
            "ev__ev-notifications-edit-population-view__criteria-item-header-text",
        ruleText:
            "ev__ev-notifications-edit-population-view__criteria-item-rule-text",
        headerButton:
            "ev__ev-notifications-edit-population-view__criteria-item-header-button",
        detailsButton:
            "ev__ev-notifications-edit-population-view__criteria-item-details-button",
        detailsWrapper:
            "ev__ev-notifications-edit-population-view__criteria-item-details-wrapper",
        detailsTagItemWrapper:
            "ev__ev-notifications-edit-population-view__criteria-item-details-tag-item-wrapper",
    },
    actionableButtonContainer:
        "ev__ev-notifications-edit-population-view__actionable-button-container",
    actionableButtons:
        "ev__ev-notifications-edit-population-view__actionable-buttons",
    actionView: "ev__ev-notifications-edit-population-view__action-view",
};

const EvNotificationsEditPopulationView = (props) => {
    const {
        staticData,
        // allTags,
        // showDeleteOption,
        populationDetailsFormData,
        onDetailsFormDataChange,
        // criteriaListFormData,
        submitStatus,
        showBackButton,
        onSaveButtonClick,
        // onAddNewCriteriaClick,
        // onCriteriaClick,
        // onDeleteClick,
        // onRemoveCriteria,
        onBackClick,
    } = props;

    // const criteriaSimplifiedFormData = useMemo(() => {
    //     const simplifiedCriteriaData = {};
    //     Object.keys(criteriaListFormData).forEach((criteriaId) => {
    //         const simplifiedTagData = {};
    //         Object.keys(criteriaListFormData[criteriaId]).forEach((tagId) => {
    //             simplifiedTagData[tagId] = parseFormDataForNames(
    //                 criteriaListFormData[criteriaId][tagId]
    //             );
    //         });
    //         simplifiedCriteriaData[criteriaId] = simplifiedTagData;
    //     });
    //     return simplifiedCriteriaData;
    // }, [criteriaListFormData]);

    const onDetailsFormDataChangeLocal = useCallback(
        (key, formItemObject) => {
            onDetailsFormDataChange({
                ...populationDetailsFormData,
                ...formItemObject,
            });
        },
        [populationDetailsFormData, onDetailsFormDataChange]
    );

    // const onCriteriaClickLocal = useCallback(
    //     (e, callbackValues) => {
    //         const { criteriaId } = callbackValues;
    //         onCriteriaClick(criteriaId);
    //     },
    //     [onCriteriaClick]
    // );
    //
    // const onRemoveCriteriaClick = useCallback(
    //     (e, callbackValues) => {
    //         const { criteriaId } = callbackValues;
    //         const confirmationModalData = {
    //             header: staticData.removeConfirmationModal.header,
    //             description: staticData.removeConfirmationModal.description,
    //             positiveButtonText:
    //                 staticData.removeConfirmationModal.positiveCta.text,
    //             negativeButtonText:
    //                 staticData.removeConfirmationModal.negativeCta.text,
    //             onPositiveAction: () => {
    //                 const newCriteriaListFormData = removeKeyFromObject(
    //                     criteriaId,
    //                     criteriaListFormData
    //                 );
    //                 onRemoveCriteria(newCriteriaListFormData);
    //             },
    //             onNegativeAction: () => {
    //                 // do nothing
    //             },
    //         };
    //         EvSimpleModal.setData(confirmationModalData).show();
    //     },
    //     [criteriaListFormData, staticData, onRemoveCriteria]
    // );

    const getBackButton = () => (
        <EvBackButton
            className={styles.backContainer}
            onClickHandler={onBackClick}
        >
            {staticData.backCta.text}
        </EvBackButton>
    );

    // const getPopulationActionView = () => (
    //     <div className={styles.populationAction.container}>
    //         {showDeleteOption &&
    //             !isNullOrEmpty(staticData.deletePopulationCta) && (
    //                 <EvButton
    //                     className={styles.populationAction.button}
    //                     secondary
    //                     onClickHandler={onDeleteClick}
    //                 >
    //                     {staticData.deletePopulationCta.text}
    //                 </EvButton>
    //             )}
    //     </div>
    // );

    const getHeaderView = () => (
        <div className={styles.headerContainer}>
            <EvText className={styles.headerText} subHeading>
                {staticData.header}
            </EvText>
            {/*<EvText className={styles.headerText} defaultDark>*/}
            {/*    {staticData.description}*/}
            {/*</EvText>*/}
        </div>
    );

    const getDetailsView = () => (
        <div className={styles.detailsContainer}>
            <EvFormRenderer
                formData={populationDetailsFormData}
                formFields={staticData.populationFormFields}
                onFormDataChange={onDetailsFormDataChangeLocal}
            />
        </div>
    );

    // const getCriteriaHeaderView = (count, criteriaId) => (
    //     <div className={styles.criteria.itemHeader}>
    //         <EvText className={styles.criteria.headerText}>
    //             {stringTemplate(staticData.criteria.heading, {
    //                 count: count + 1,
    //             })}
    //         </EvText>
    //         <EvButton
    //             iconName="EDIT"
    //             iconButton
    //             className={styles.criteria.headerButton}
    //             onClickHandler={onCriteriaClickLocal}
    //             callbackValues={{ criteriaId }}
    //         >
    //             {staticData.criteria.editButtonCta.text}
    //         </EvButton>
    //         <EvButton
    //             iconName="DELETE"
    //             iconButton
    //             className={styles.criteria.headerButton}
    //             onClickHandler={onRemoveCriteriaClick}
    //             callbackValues={{ criteriaId }}
    //         >
    //             {staticData.criteria.removeButtonCta.text}
    //         </EvButton>
    //     </div>
    // );
    //
    // const getCriteriaItemView = (criteriaId, count) => {
    //     // purposely done inside the function, it needs criteriaId
    //     const getCriteriaDetailsTagsItemView = (tagId) => {
    //         const tagData = allTags.value[tagId];
    //         const tagRuleText =
    //             tagData.type === TAGS_TYPE.RESTRICTED
    //                 ? tagData.description
    //                 : stringTemplate(
    //                       staticData.criteria.ruleDisplayTemplate,
    //                       criteriaSimplifiedFormData[criteriaId][tagId]
    //                   );
    //         return (
    //             <div
    //                 key={tagId}
    //                 className={styles.criteria.detailsTagItemWrapper}
    //             >
    //                 <EvText defaultDarkBold>{tagData.displayName}</EvText>
    //                 <EvText className={styles.criteria.ruleText}>
    //                     {tagRuleText}
    //                 </EvText>
    //             </div>
    //         );
    //     };
    //
    //     const currentCriteriaObjectKeys = Object.keys(
    //         criteriaListFormData[criteriaId]
    //     );
    //     return (
    //         <div key={criteriaId} className={styles.criteria.itemContainer}>
    //             {getCriteriaHeaderView(count, criteriaId)}
    //             <EvButton
    //                 onlyChild
    //                 className={styles.criteria.detailsButton}
    //                 onClickHandler={onCriteriaClickLocal}
    //                 callbackValues={{ criteriaId }}
    //             >
    //                 <div className={styles.criteria.detailsWrapper}>
    //                     {/* if criteria has no rules or tags */}
    //                     {currentCriteriaObjectKeys.length <= 0 && (
    //                         <EvText primaryColored defaultBold>
    //                             {staticData.criteria.placeholder}
    //                         </EvText>
    //                     )}
    //                     {currentCriteriaObjectKeys.map(
    //                         getCriteriaDetailsTagsItemView
    //                     )}
    //                 </div>
    //             </EvButton>
    //             {count < Object.keys(criteriaListFormData).length - 1 && (
    //                 <EvTextDivider className={styles.criteria.itemDivider}>
    //                     {staticData.criteria.criteriaDividerText}
    //                 </EvTextDivider>
    //             )}
    //         </div>
    //     );
    // };
    //
    // const getCriteriaView = () => (
    //     <div className={styles.criteria.container}>
    //         <EvText defaultDark>{staticData.criteriaHeading}</EvText>
    //         <div className={styles.criteria.itemListContainer}>
    //             {Object.keys(criteriaListFormData).map(getCriteriaItemView)}
    //         </div>
    //     </div>
    // );

    const getButtonView = () => (
        <div className={styles.actionableButtonContainer}>
            {/*<EvButton*/}
            {/*    className={styles.actionableButtons}*/}
            {/*    iconButton*/}
            {/*    iconName="PLUS"*/}
            {/*    onClickHandler={onAddNewCriteriaClick}*/}
            {/*>*/}
            {/*    {staticData.addCriteriaCta.text}*/}
            {/*</EvButton>*/}
            <EvSubmitActionView
                className={styles.actionView}
                onSubmitClick={onSaveButtonClick}
                loadingStatus={submitStatus}
                buttonText={staticData.nextButtonCta.text}
            />
        </div>
    );

    return (
        <div className={styles.container}>
            {showBackButton && getBackButton()}
            {/*{getPopulationActionView()}*/}
            {getHeaderView()}
            {getDetailsView()}
            {/*{getCriteriaView()}*/}
            {getButtonView()}
        </div>
    );
};

EvNotificationsEditPopulationView.propTypes = {
    staticData: PropTypes.object,
    showDeleteOption: PropTypes.bool,
    populationDetailsFormData: PropTypes.object,
    allTags: PropTypes.object,
    criteriaListFormData: PropTypes.object,
    submitStatus: PropTypes.string,
    showBackButton: PropTypes.bool,

    onDetailsFormDataChange: PropTypes.func,
    onAddNewCriteriaClick: PropTypes.func,
    onSaveButtonClick: PropTypes.func,
    onCriteriaClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onRemoveCriteria: PropTypes.func,
    onBackClick: PropTypes.func,
};

EvNotificationsEditPopulationView.defaultProps = {
    staticData: {},
    showDeleteOption: false,
    populationDetailsFormData: {},
    allTags: {},
    criteriaListFormData: {},
    submitStatus: "",
    showBackButton: false,

    onDetailsFormDataChange: () => {},
    onAddNewCriteriaClick: () => {},
    onSaveButtonClick: () => {},
    onCriteriaClick: () => {},
    onDeleteClick: () => {},
    onRemoveCriteria: () => {},
    onBackClick: () => {},
};

export default EvNotificationsEditPopulationView;
