import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvDivider, EvSimpleCard, EvText } from "../index";
import { COLORS } from "../../static/VmsStatics";

import "../../styles/ev-cards/ev-360-card.scss";

const styles = {
    container: "ev__ev-360-card__container",
    innerContainer: "ev__ev-360-card__inner-container",
    imageView: {
        container: "ev__ev-360-card__image-view-container",
        innerContainer: "ev__ev-360-card__image-view-inner-container",
        fullImage: "ev__ev-360-card__image-view-full-image",
        logoWrapper: "ev__ev-360-card__image-view-logo-wrapper",
        logo: "ev__ev-360-card__image-view-logo",
        // image: "ev__ev-360-card__image-view-image",
    },
    category: {
        container: "ev__ev-360-card__category-container",
        wrapper: "ev__ev-360-card__category-wrapper",
    },
    headerView: {
        container: "ev__ev-360-card__header-view-container",
    },
    content: {
        container: "ev__ev-360-card__content-container",
        description: "ev__ev-360-card__content-description",
        vendorDetails: "ev__ev-360-card__content-vendor-details",
        contact: "ev__ev-360-card__content-vendor-contact",
    },
    cardActions: {
        container: "ev__ev-360-card__card-actions-container",
        button: "ev__ev-360-card__card-actions-button",
    },
    actionView: {
        container: "ev__ev-360-card__action-view-container",
        button: "ev__ev-360-card__action-view-button",
    },
};

const Ev360Card = (props) => {
    const {
        logoImage,
        backgroundImage,
        backgroundColor,
        category,
        header,
        description,
        // vendorDetails,
        // contact,
        positiveButtonText,
        negativeButtonText,
        ctaText,

        className,
    } = props;

    const getBackgroundImage = () => (
        <div
            className={styles.imageView.fullImage}
            style={{ backgroundImage: `url(${backgroundImage})` }}
        />
    );

    const getLogoImage = () => (
        <div
            className={styles.imageView.logoWrapper}
            style={{ backgroundColor }}
        >
            <div
                className={styles.imageView.logo}
                style={{ backgroundImage: `url(${logoImage})` }}
            />
        </div>
    );

    const getImageView = () => (
        <div className={styles.imageView.container}>
            <div className={styles.imageView.innerContainer}>
                {backgroundImage
                    ? getBackgroundImage()
                    : logoImage
                    ? getLogoImage()
                    : null}
            </div>
        </div>
    );

    const getCategoryView = () => (
        <div className={styles.category.container}>
            <div className={styles.category.wrapper}>
                <EvText color={COLORS.PRODUCT} smallBold>
                    {category}
                </EvText>
            </div>
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.headerView.container}>
            <EvText defaultDarkBold>{header}</EvText>
        </div>
    );

    const getContent = () => (
        <div className={styles.content.container}>
            <EvText className={styles.content.description} dangerous>
                {description}
            </EvText>
            {/* <EvText className={styles.content.vendorDetails}>{vendorDetails}</EvText> */}
            {/* <EvText className={styles.content.contact}>{contact}</EvText> */}
        </div>
    );

    const getCardActions = () => (
        <div className={styles.cardActions.container}>
            <EvText
                className={styles.cardActions.button}
                color={COLORS.PRODUCT}
                defaultBold
            >
                {positiveButtonText}
            </EvText>
            <EvText
                className={styles.cardActions.button}
                color={COLORS.PRODUCT}
                defaultBold
            >
                {negativeButtonText}
            </EvText>
        </div>
    );

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvText
                className={styles.actionView.button}
                color={COLORS.PRODUCT}
                defaultBold
            >
                {ctaText}
            </EvText>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding>
                <div className={styles.innerContainer}>
                    {(backgroundImage || logoImage) && getImageView()}
                    {(backgroundImage || logoImage) && <EvDivider />}
                    {getCategoryView()}
                    {getHeaderView()}
                    {getContent()}
                    {getCardActions()}
                    {getActionView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

Ev360Card.propTypes = {
    logoImage: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,
    category: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    // vendorDetails: PropTypes.string,
    // contact: PropTypes.string,
    positiveButtonText: PropTypes.string,
    negativeButtonText: PropTypes.string,
    ctaText: PropTypes.string,

    className: PropTypes.string,
};

Ev360Card.defaultProps = {
    logoImage: "",
    backgroundImage: "",
    backgroundColor: "",
    category: "",
    header: "",
    description: "",
    // vendorDetails: "BCBSM Online Visits APP",
    // contact: "1-844-606-1608",
    positiveButtonText: "",
    negativeButtonText: "",
    ctaText: "",

    className: "",
};

export default Ev360Card;
