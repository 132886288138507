import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ImageCropper from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

import { EvButton, EvContentModal, EvToast } from "./index";

import "../styles/ev-image-cropper.scss";

const styles = {
    container: "ev__ev-image-cropper__container",
    contentContainer: "ev__ev-image-cropper__content-container",
    actionContainer: "ev__ev-image-cropper__action-container",
    actionButton: "ev__ev-image-cropper__action-button",
    cropperImage: "ev__ev-image-cropper__crop-image",
};

const EvImageCropper = (props) => {
    const {
        isEditorVisible,
        selectedImageData,
        cropHeight,
        cropWidth,
        onImageCropped,
        onCancelClick,
    } = props;

    const selectedImageRef = useRef();

    const [isOpen, setIsOpen] = useState(isEditorVisible);
    const [imageParams, setImageParams] = useState({});
    const [editorCropParams, setEditorCropParams] = useState({
        width: cropWidth,
        height: cropHeight,
    });

    useEffect(() => {
        setIsOpen(isEditorVisible);
    }, [isEditorVisible]);

    const onEditorImageLoad = useCallback((img) => {
        setImageParams({
            width: img.width,
            height: img.height,
        });
        selectedImageRef.current = img;
    }, []);

    const onEditorChange = useCallback(
        (crop) => {
            const newWidth =
                crop.x + crop.width > imageParams.width
                    ? imageParams.width - crop.x
                    : crop.width;

            setEditorCropParams({
                ...crop,
                width: newWidth,
                aspect: cropWidth / cropHeight,
            });
        },
        [cropWidth, cropHeight, imageParams]
    );

    const setEditorCompletedCrop = useCallback(() => {
        //
    }, []);

    const cropImage = useCallback(
        () =>
            new Promise((resolve, reject) => {
                if (
                    editorCropParams.width <= 0 ||
                    editorCropParams.height <= 0
                ) {
                    EvToast.error("Oops!", "Crop area not selected");
                    reject(new Error("Crop area not selected"));
                }
                const canvas = document.createElement("canvas");
                canvas.width = cropWidth;
                canvas.height = cropHeight;
                canvas.src = selectedImageData;
                const ctx = canvas.getContext("2d");
                const imageScale = {
                    x:
                        selectedImageRef.current.naturalWidth /
                        selectedImageRef.current.width,
                    y:
                        selectedImageRef.current.naturalHeight /
                        selectedImageRef.current.height,
                };
                ctx.drawImage(
                    selectedImageRef.current,
                    editorCropParams.x * imageScale.x,
                    editorCropParams.y * imageScale.y,
                    editorCropParams.width * imageScale.x,
                    editorCropParams.height * imageScale.y,
                    0,
                    0,
                    cropWidth,
                    cropHeight
                );

                canvas.toBlob((blob) => {
                    resolve(blob);
                });
            }),
        [
            editorCropParams,
            selectedImageRef,
            cropWidth,
            cropHeight,
            selectedImageData,
        ]
    );

    const onEditorApplyClick = useCallback(() => {
        cropImage()
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    onImageCropped(reader.result);
                    setIsOpen(false);
                };
            })
            .catch((e) => {});
    }, [cropImage, onImageCropped]);

    const onUploadOriginalClick = useCallback(() => {
        setIsOpen(false);
        onImageCropped(selectedImageData);
    }, [onImageCropped, selectedImageData]);

    const onDownloadClick = useCallback(() => {
        cropImage()
            .then((blob) => {
                const anchor = document.createElement("a");
                anchor.download = "croppedImage.png";
                anchor.href = URL.createObjectURL(blob);
                anchor.click();
            })
            .catch((e) => {});
    }, [cropImage]);

    const onCancelClickLocal = useCallback(() => {
        setIsOpen(false);
        onCancelClick();
    }, [onCancelClick]);

    return (
        <EvContentModal
            isVisible={isOpen}
            exitOnOverlayClick={false}
            contentClassName={styles.container}
        >
            <div className={styles.contentContainer}>
                <ImageCropper
                    src={selectedImageData}
                    crop={editorCropParams}
                    onChange={onEditorChange}
                    onComplete={setEditorCompletedCrop}
                    onImageLoaded={onEditorImageLoad}
                    imageStyle={{ maxWidth: "80vw", maxHeight: "70vh" }}
                />
                <div className={styles.actionContainer}>
                    <EvButton
                        negativeAction
                        className={styles.actionButton}
                        onClickHandler={onCancelClickLocal}
                    >
                        Cancel
                    </EvButton>
                    <EvButton
                        iconButton
                        iconName="ARROW_THICK_DOWN"
                        className={styles.actionButton}
                        onClickHandler={onDownloadClick}
                    >
                        Download Cropped Image
                    </EvButton>
                    <EvButton
                        negativeAction
                        className={styles.actionButton}
                        onClickHandler={onUploadOriginalClick}
                    >
                        Upload Original Image
                    </EvButton>
                    <EvButton
                        primaryFilled
                        className={styles.actionButton}
                        onClickHandler={onEditorApplyClick}
                    >
                        Apply
                    </EvButton>
                </div>
            </div>
        </EvContentModal>
    );
};

EvImageCropper.propTypes = {
    isEditorVisible: PropTypes.bool,
    selectedImageData: PropTypes.string,
    cropWidth: PropTypes.number,
    cropHeight: PropTypes.number,
    onImageCropped: PropTypes.func,
    onCancelClick: PropTypes.func,
};

EvImageCropper.defaultProps = {
    isEditorVisible: false,
    selectedImageData: "",
    cropWidth: 200,
    cropHeight: 200,
    onImageCropped: () => {},
    onCancelClick: () => {},
};

export default EvImageCropper;
