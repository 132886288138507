import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { EvButton, EvIcon } from "..";
import FormValidation from "../../../utils/FormValidations";
import { COLORS } from "../../static/VmsStatics";
import { EvInputErrorView } from "./index";

import "../../styles/form-components/ev-time-picker.scss";

const EvTimePicker = (props) => {
    const styles = {
        container: "ev__ev-time-picker__container",
        inputWrapper: "ev__ev-time-picker__input-wrapper",
        inputBox: "ev__ev-time-picker__input-box",
        timePickerCustom: "ev__ev-time-picker__time-picker-custom",
        timePickerListItem: "ev__ev-time-picker__time-picker-list-item",
    };

    const { type, formItem, formData, value } = props;

    const datePickerRef = useRef(null);

    const handleChange = (date) => {
        const inputTimeObject = {
            [props.keyName]: {
                key: props.keyName,
                value: date,
                type: props.type,
            },
            errorObject: {},
            formItemType: type,
        };

        props.handleInputChange(props.keyName, {
            ...inputTimeObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputTimeObject),
            },
        });
    };

    const onIconClick = useCallback(() => {
        datePickerRef.current.setOpen(true);
    }, []);

    const getTimeListClassName = useCallback(() => styles.timePickerListItem, [
        styles.timePickerListItem,
    ]);

    const getIcon = () => (
        <EvButton onlyChild onClickHandler={onIconClick}>
            <EvIcon size={3} iconName="CLOCK" fillColor={COLORS.PRODUCT} />
        </EvButton>
    );

    return (
        <div>
            <div className={styles.inputWrapper}>
                <DatePicker
                    ref={datePickerRef}
                    placeholderText="Time"
                    selected={value}
                    onChange={handleChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={styles.inputBox}
                    calendarClassName={styles.timePickerCustom}
                    timeClassName={getTimeListClassName}
                />
                {getIcon()}
            </div>
            <EvInputErrorView errorObject={formData.errorObject} />
        </div>
    );
};

EvTimePicker.propTypes = {
    keyName: PropTypes.string,
    type: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),

    handleInputChange: PropTypes.func,
};

EvTimePicker.defaultProps = {
    keyName: "",
    type: "",
    formItem: {},
    formData: {},
    value: "",

    handleInputChange: () => {},
};

export default EvTimePicker;
