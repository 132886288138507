const getGamedayScheduleDashboardDataJSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Game Day scheduling",
            description: "Set a date and time to launch a new Game Day game.",
            newGameCta: { text: "Schedule new game" },
            noGamesMessage: "Sorry, currently there are no scheduled games",
            scheduledGameTable: {
                header: "Upcoming games",
                loadMoreCta: { text: "Load more" },
                tableFields: [
                    {
                        id: "gameName",
                        label: "Game name",
                        type: "PRIMARY_TEXT",
                        resolver: "{gameName}",
                    },
                    {
                        id: "gameCategory",
                        label: "Status",
                        type: "DATA_OPTIONS",
                        resolver: "",
                        data: {
                            MEMORY: {
                                id: "MEMORY",
                                value: "MEMORY",
                                name: "Memory",
                            },
                            LOST_LETTERS: {
                                id: "LOST_LETTERS",
                                value: "LOST_LETTERS",
                                name: "Lost Letters",
                            },
                            PICK_N_WIN: {
                                id: "PICK_N_WIN",
                                value: "PICK_N_WIN",
                                name: "Pick'n Win",
                            },
                        },
                    },
                    {
                        id: "goLiveDate",
                        label: "Go-live date",
                        type: "TIME_STAMP",
                        resolver: "{date}, {time} {timeZone}",
                    },
                    {
                        id: "status",
                        label: "Status",
                        type: "DATA_OPTIONS",
                        resolver: "",
                        data: {
                            DRAFT: {
                                id: "draft",
                                name: "Draft",
                                value: "DRAFT",
                            },
                            LIVE: {
                                id: "live",
                                name: "Live",
                                value: "LIVE",
                            },
                            SCHEDULED: {
                                id: "scheduled",
                                name: "Scheduled",
                                value: "SCHEDULED",
                            },
                        },
                    },
                ],
            },
        },
    },
    SCHEDULED_GAMES: {
        responseStatus: "SUCCESS",
        data: {
            scheduledGames: [
                {
                    id: "scheduledId1",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "MEMORY",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "DRAFT",
                },
                {
                    id: "scheduledId2",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "PICK_N_WIN",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "SCHEDULED",
                },
                {
                    id: "scheduledId3",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "PICK_N_WIN",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "SCHEDULED",
                },
                {
                    id: "scheduledId4",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "PICK_N_WIN",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "SCHEDULED",
                },
                {
                    id: "scheduledId5",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "PICK_N_WIN",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "SCHEDULED",
                },
                {
                    id: "scheduledId6",
                    gameId: "gameId1",
                    gameName: "Diabetes management",
                    gameCategory: "PICK_N_WIN",
                    vendorProgramName: "Amul - Dairy Products",
                    goLiveDate: {
                        date: "2020-01-31",
                        time: "16:00:00",
                        timeZone: "CST",
                    },
                    status: "SCHEDULED",
                },
            ],
        },
    },
};

const getGamedayScheduleGameSelectionDataJSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Schedule a game",
            description: "Select one of these configured games to schedule.",
            noGamesMessage: "There are no available games for this client",
            gameCategories: {
                MEMORY: {
                    displayName: "Memory",
                },
                LOST_LETTERS: {
                    displayName: "Lost Letters",
                },
                PICK_N_WIN: {
                    displayName: "Pick N Win",
                },
            },
        },
    },
    GAME_LIST: {
        responseStatus: "SUCCESS",
        data: {
            clientCode: "9999",
            gameCards: [
                {
                    gameName: "Game ABC",
                    gameCategory: "LOST_LETTERS",
                    gameId:
                        "LOST_LETTERS~gid_0784f765-66b0-4e2b-90d2-f67a91d537c0",
                    vendorProgramName: "Amul - Dairy Products",
                    gameStatus: "CHECKED",
                },
                {
                    gameName: "Game ABC",
                    gameCategory: "PICK_N_WIN",
                    gameId:
                        "PICK_N_WIN~gid_0784f765-66b0-4e2b-90d2-f67a91d537c0",
                    vendorProgramName: "Amul - Dairy Products",
                    gameStatus: "UNCHECKED",
                },
                {
                    gameName: "Game Z",
                    gameCategory: "MEMORY",
                    gameId: "MEMORY~gid_d03218f0-5909-42fa-b396-0a84326a486d",
                    vendorProgramName: "Hello Heart - Metabolic Syndrome",
                    gameStatus: "CHECKED",
                },
                {
                    gameName: "Game Z",
                    gameCategory: "LOST_LETTERS",
                    gameId:
                        "LOST_LETTERS~gid_d03218f0-5909-42fa-b396-0a84326a486d",
                    vendorProgramName: "Hello Heart - Metabolic Syndrome",
                    gameStatus: "CHECKED",
                },
                {
                    gameName: "Game Z",
                    gameCategory: "PICK_N_WIN",
                    gameId:
                        "PICK_N_WIN~gid_d03218f0-5909-42fa-b396-0a84326a486d",
                    vendorProgramName: "Hello Heart - Metabolic Syndrome",
                    gameStatus: "CHECKED",
                },
            ],
        },
    },
};

const getGamedayScheduleContentDataJSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Editing {gameCategory} content for {vendorProgramName}",
            description:
                "Review your game content to make sure it’s ready to launch. If you’d like to make edits, visit the card content dashboard in the Vendor Programs tab.",
            submitCta: { text: "Schedule game" },
            gameCategories: {
                MEMORY: {
                    displayName: "Memory",
                },
                LOST_LETTERS: {
                    displayName: "Lost Letters",
                },
                PICK_N_WIN: {
                    displayName: "Pick N Win",
                },
            },
            formFields: {
                MEMORY: [
                    {
                        id: "gameName",
                        label: "Game Name",
                        type: "TEXT",
                        defaultValue: "",
                        editable: false,
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter data field",
                            },
                        ],
                    },
                    {
                        id: "instructions",
                        label: "Game Instructions",
                        description: "250 character max",
                        type: "SMALL_TEXT_AREA",
                        defaultValue: "",
                        editable: false,
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter data field",
                            },
                            {
                                type: "MAX_LENGTH",
                                value: "250",
                                errMsg: "Max length is 250 characters",
                            },
                        ],
                    },
                    {
                        id: "gameCards",
                        type: "FORM_TEMPLATE",
                        label: "",
                        itemLabel: "Image {count}",
                        description: "",
                        previewCta: { text: "Preview", iconName: "VISIBLE" },
                        // addItemCta: { text: "Add Card" },
                        // removeItemCta: { text: "Remove Card" },
                        editable: false,
                        formItems: [
                            {
                                id: "gameImage",
                                label: "Image",
                                description:
                                    "Maximum size limit: 1 MB Square ratio only.",
                                placeholder: "Upload image",
                                type: "IMAGE_UPLOAD",
                                defaultValue:
                                    "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                                editable: true,
                                options: {
                                    uploadEndpoint: "/cc/api/upload-image",
                                    showPreview: true,
                                },
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        errMsg: "This field is mandatory",
                                    },
                                ],
                            },
                            {
                                id: "bodyText",
                                label: "Body Text",
                                description: "300 character max",
                                placeholder: "",
                                type: "SMALL_TEXT_AREA",
                                editable: true,
                                validations: [
                                    {
                                        type: "MANDATORY",
                                        errMsg: "This field is mandatory",
                                    },
                                    {
                                        type: "MAX_LENGTH",
                                        value: "300",
                                        errMsg: "300 character max",
                                    },
                                ],
                            },
                            {
                                id: "imageTitle",
                                label: "Image title (optional)",
                                description: "25 character max",
                                placeholder: "",
                                type: "TEXT",
                                editable: true,
                                validations: [
                                    {
                                        type: "MAX_LENGTH",
                                        value: "25",
                                        errMsg: "25 character max",
                                    },
                                ],
                            },
                            {
                                id: "ctaType",
                                label: "CTA (optional)",
                                description: "",
                                type: "DROPDOWN",
                                defaultValue: "NONE",
                                editable: true,
                                validations: [],
                                data: [
                                    { id: "href", name: "HREF", value: "HREF" },
                                    { id: "sso", name: "SSO", value: "SSO" },
                                    {
                                        id: "internal",
                                        name: "INTERNAL",
                                        value: "INTERNAL",
                                    },
                                    { id: "tel", name: "TEL", value: "TEL" },
                                    { id: "none", name: "NONE", value: "NONE" },
                                ],
                                subForm: [
                                    {
                                        id: "ctaValue",
                                        matchId: "sso",
                                        label: "CTA value",
                                        description: "",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "buttonText",
                                        matchId: "sso",
                                        label: "Button Text",
                                        description: "25 character max",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MAX_LENGTH",
                                                value: "25",
                                                errMsg: "25 character max",
                                            },
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "ctaValue",
                                        matchId: "href",
                                        label: "CTA value",
                                        description: "",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "buttonText",
                                        matchId: "href",
                                        label: "Button Text",
                                        description: "25 character max",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MAX_LENGTH",
                                                value: "25",
                                                errMsg: "25 character max",
                                            },
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "ctaValue",
                                        matchId: "tel",
                                        label: "CTA value",
                                        description: "",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "buttonText",
                                        matchId: "tel",
                                        label: "Button Text",
                                        description: "25 character max",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MAX_LENGTH",
                                                value: "25",
                                                errMsg: "25 character max",
                                            },
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "ctaValue",
                                        matchId: "internal",
                                        label: "CTA value",
                                        description: "",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                    {
                                        id: "buttonText",
                                        matchId: "internal",
                                        label: "Button Text",
                                        description: "25 character max",
                                        placeholder: "",
                                        type: "TEXT",
                                        editable: true,
                                        validations: [
                                            {
                                                type: "MAX_LENGTH",
                                                value: "25",
                                                errMsg: "25 character max",
                                            },
                                            {
                                                type: "MANDATORY",
                                                errMsg:
                                                    "This field is mandatory",
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                        validations: [
                            {
                                type: "MIN_ITEM_COUNT",
                                value: 6,
                                errMsg: "Must contain exactly 6 content cards",
                            },
                            {
                                type: "MAX_ITEM_COUNT",
                                value: 6,
                                errMsg: "Must contain exactly 6 content cards",
                            },
                        ],
                        cardPreview: {
                            previewType: "GAME_CARD_CONTENT_CARD",
                            contentKeys: {
                                description: "bodyText",
                                imageUrl: "gameImage",
                            },
                            staticContent: {
                                header: "",
                                subHeader1: "",
                                subHeader2: "",
                                content1: "",
                                content2: "",
                            },
                        },
                    },
                    {
                        id: "gameId",
                        label: "",
                        type: "HIDDEN_TEXT",
                        defaultValue: "",
                    },
                ],
            },
        },
    },
    PREFILL: {
        responseStatus: "SUCCESS",
        data: {
            gameName: "Mental Health",
            instructions:
                "It's easy to play. Once you click the start button, you’ll get 2 minutes on the clock to pair matching sets of pictures. After each pair, you'll get a quick blurb about your mental health.",
            gameCards: [
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
                {
                    gameImage:
                        "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
                    bodyText:
                        "Sleep is vital for re-energizing and allowing your body to naturally heal from the stress and toxins weakening your immune system all day. Aim for 8 hours a night if possible to give your body plenty of time to recuperate from the stress of the day.",
                    imageTitle: "ImageTitle",
                },
            ],
        },
    },
};

const getGamedayScheduleTimeDataJSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Set launch date for {gameName}",
            submitCta: { text: "Next" },
            formFields: [
                {
                    id: "launchDate",
                    label: "Launch Date",
                    description: "",
                    placeholder: "",
                    type: "DATE_TIME_ZONE",
                    editable: true,
                    dateFormField: {
                        id: "ldate",
                        label: "",
                        description: "",
                        placeholder: "Date",
                        type: "DATE",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter date",
                            },
                        ],
                    },
                    timeFormField: {
                        id: "ltime",
                        label: "",
                        description: "",
                        placeholder: "Time",
                        type: "TIME",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter time",
                            },
                        ],
                    },
                    timeZoneFormField: {
                        id: "timeZone",
                        label: "Select a time zone",
                        description: "",
                        placeholder: "Select a time zone",
                        type: "DROPDOWN",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please select data field",
                            },
                        ],
                        data: [
                            {
                                id: "cst",
                                name: "Central Standard Time",
                                value: "CST",
                            },
                            {
                                id: "ist",
                                name: "Indian Standard Time",
                                value: "IST",
                            },
                        ],
                    },
                },
                {
                    id: "expirationDate",
                    label: "Expiration Date",
                    description: "",
                    placeholder: "",
                    type: "DATE_TIME_ZONE",
                    editable: true,
                    dateFormField: {
                        id: "edate",
                        label: "",
                        description: "",
                        placeholder: "Date",
                        type: "DATE",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter date",
                            },
                        ],
                    },
                    timeFormField: {
                        id: "etime",
                        label: "",
                        description: "",
                        placeholder: "Time",
                        type: "TIME",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please enter time",
                            },
                        ],
                    },
                    timeZoneFormField: {
                        id: "timeZone",
                        label: "Select a time zone",
                        description: "",
                        placeholder: "Select a time zone",
                        type: "DROPDOWN",
                        editable: true,
                        defaultValue: "",
                        validations: [
                            {
                                type: "MANDATORY",
                                errMsg: "Please select data field",
                            },
                        ],
                        data: [
                            {
                                id: "cst",
                                name: "Central Standard Time",
                                value: "CST",
                            },
                            {
                                id: "ist",
                                name: "Indian Standard Time",
                                value: "IST",
                            },
                        ],
                    },
                },
            ],
        },
    },
    PREFILL: {
        responseStatus: "SUCCESS",
        data: {
            ldate: "2020-05-26",
            ltime: "19:30:00",
            timeZone: "CST",
            edate: "2020-05-30",
            etime: "19:30:00",
        },
    },
    SUBMIT: {
        responseStatus: "SUCCESS",
        data: {
            id: "scheduledId_1",
        },
    },
};

const getGamedayScheduleSummaryDataJSON = {
    STATIC: {
        responseStatus: "SUCCESS",
        data: {
            header: "Review and schedule",
            description:
                "Everything look good? If so, schedule your launch! You can still make edits later if you need to.",
            submitCta: { text: "Schedule launch" },
            segments: [
                {
                    id: "gameDetails",
                    actionPage: "",
                    editable: true,
                    heading: "CONTENT",
                    fields: [
                        {
                            id: "title",
                            label: "Game title:",
                            dependency: "title",
                            displayResolver: "{title}",
                            type: "LABEL",
                        },
                        {
                            id: "description",
                            label: "Description:",
                            dependency: "description",
                            displayResolver: "{description}",
                            type: "LABEL",
                        },
                        {
                            id: "gameType",
                            label: "Game Type:",
                            dependency: "gameType",
                            displayResolver: "{gameType}",
                            type: "OPTIONS",
                            data: {
                                MEMORY: {
                                    id: "MEMORY",
                                    value: "MEMORY",
                                    name: "Memory",
                                },
                                LOST_LETTERS: {
                                    id: "LOST_LETTERS",
                                    value: "LOST_LETTERS",
                                    name: "Lost Letters",
                                },
                                PICK_N_WIN: {
                                    id: "PICK_N_WIN",
                                    value: "PICK_N_WIN",
                                    name: "Pick'n Win",
                                },
                            },
                        },
                        {
                            id: "vendorProgram",
                            label: "vendorProgram:",
                            dependency: "vendorProgram",
                            displayResolver: "{vendorProgram}",
                            type: "LABEL",
                        },
                        {
                            id: "cta",
                            label: "CTA Type:",
                            dependency: "cta",
                            displayResolver: "{cta}",
                            type: "LABEL",
                        },
                        {
                            id: "ctaValue",
                            label: "SSO Link:",
                            dependency: "ctaValue",
                            displayResolver: "{ctaValue}",
                            type: "LABEL",
                        },
                    ],
                },
                {
                    id: "launchDetails",
                    actionPage: "gamedayScehdule__time",
                    editable: true,
                    heading: "LAUNCH DETAILS",
                    fields: [
                        {
                            id: "ldate",
                            label: "Launch Date:",
                            dependency: "ldate",
                            displayResolver: "{ldate}",
                            type: "DATE",
                        },
                        {
                            id: "ltime",
                            label: "Launch Time",
                            dependency: "ltime",
                            displayResolver: "{ltime} {timeZone}",
                            type: "TIME",
                        },
                        {
                            id: "edate",
                            label: "Expiry Date:",
                            dependency: "edate",
                            displayResolver: "{edate}",
                            type: "DATE",
                        },
                        {
                            id: "etime",
                            label: "Expiry Time",
                            dependency: "etime",
                            displayResolver: "{etime} {timeZone}",
                            type: "TIME",
                        },
                    ],
                },
            ],
        },
    },
    PREFILL: {
        responseStatus: "SUCCESS",
        data: {
            title: "Game title: Benefits of direct deposit",
            description:
                "Enroll in Direct Deposit today. It’s quick, easy, and it saves paper. (Plus, you can print paper records whenever you’d like.)",
            gameType: "PICK_N_WIN",
            vendorProgram: "Livongo - Diabetes management",
            cta: "SSO",
            ctaValue: "Livongo.sso",
            ldate: "2020-01-31",
            ltime: "16:00:00",
            edate: "2020-02-20",
            etime: "20:00:00",
            timeZone: "CST",
        },
    },
    SUBMIT: {
        responseStatus: "SUCCESS",
        data: {},
    },
};

export {
    getGamedayScheduleDashboardDataJSON,
    getGamedayScheduleGameSelectionDataJSON,
    getGamedayScheduleContentDataJSON,
    getGamedayScheduleTimeDataJSON,
    getGamedayScheduleSummaryDataJSON,
};
