import axios from "axios";
import { EvToast } from "../common/components";
import { HTTP_METHODS, RESPONSE_STATUS, ROUTES } from "../common/static/Enums";
import { isNullOrEmpty, queryStringGenerator } from "../utils/CommonUtils";
import EvError, { EV_ERROR_TYPES } from "../utils/EvError";
import EvLogger, { LOG_CATEGORY_TYPES, LOG_TYPE } from "../utils/EvLogger";

const api = axios.create();

api.defaults.headers.post["Content-Type"] = "application/json";

// api.interceptors.request.use(
//   config => {
//     config.withCredentials = false;
//     return config;
//   },
//   error => Promise.reject(error)
// );

const MOCK_API_DELAY = 500;

api.interceptors.response.use(
    (response) => {
        if (response.status >= 200 && response.status < 300) {
            // if valid responseStatus from BE
            if (response.data.responseStatus === RESPONSE_STATUS.SUCCESS) {
                if (
                    response.config.method === HTTP_METHODS.GET &&
                    response.data.message
                ) {
                    EvToast.success("", response.data.message);
                }
                return response;
            } else if (
                response.data.responseStatus === RESPONSE_STATUS.FAILED
            ) {
                EvToast.error(
                    "",
                    response.data.message || "Something went wrong"
                );
                return Promise.reject(
                    EvLogger.errorWithObject(
                        new EvError({
                            fileName: "API INTERCEPTOR FAILED",
                            message: `${response.config.method} ${
                                response.config.url
                            } with data ${
                                response.config.data
                            } failed with status: ${
                                response.status
                            } response: ${JSON.stringify(response.data)}`,
                            category: LOG_CATEGORY_TYPES.CC,
                            type: EV_ERROR_TYPES.SERVER_INTERNAL,
                            handled: false,
                        }),
                        "",
                        LOG_TYPE.ERROR
                    )
                );
            } else if (
                response.data.responseStatus === RESPONSE_STATUS.SHOW_ERROR
            ) {
                if (response.data.message) {
                    EvToast.error("", response.data.message);
                }
                return Promise.reject(
                    EvLogger.errorWithObject(
                        new EvError({
                            fileName: "API INTERCEPTOR SHOW ERROR",
                            message: `${response.config.method} ${
                                response.config.url
                            } with data ${
                                response.config.data
                            } failed with status: ${
                                response.status
                            } response: ${JSON.stringify(response.data)}`,
                            category: LOG_CATEGORY_TYPES.CC,
                            type: EV_ERROR_TYPES.SERVER_INTERNAL,
                            handled: false,
                        }),
                        "",
                        LOG_TYPE.WARN
                    )
                );
            } else if (
                response.data.responseStatus === RESPONSE_STATUS.HARD_REDIRECT
            ) {
                // for hard redirection
                if (response.data.redirectUrl) {
                    window.location = response.data.redirectUrl;
                    EvLogger.log(
                        `${response.data.responseStatus} to ${response.data.redirectUrl}, response: ${response.data}`
                    );
                } else {
                    EvLogger.error(
                        `${response.data.responseStatus} found with no redirectUrl, response: ${response.data}`
                    );
                }
                return Promise.reject(
                    new EvError({
                        fileName: "API HARD_REDIRECT",
                        message: "HARD_REDIRECT",
                        category: LOG_CATEGORY_TYPES.CC,
                        type: EV_ERROR_TYPES.UNAUTHORIZED,
                    })
                );
            }
        }
        return Promise.reject(response);
    },
    (error) => {
        if (error.response && error.response.status === 403) {
            EvToast.warn("Session Timeout", "Trying to re-login");
            window.location.href = ROUTES.LOGIN;
            return Promise.reject(
                EvLogger.errorWithObject(
                    new EvError({
                        fileName: "API 403",
                        message: `Session Timeout. Redirecting to login page`,
                        category: LOG_CATEGORY_TYPES.CC,
                        type: EV_ERROR_TYPES.UNAUTHORIZED,
                        handled: false,
                    }),
                    "",
                    LOG_TYPE.INFO
                )
            );
        }

        // all other server error
        return Promise.reject(
            EvLogger.errorWithObject(
                new EvError({
                    fileName: "API INTERCEPTOR REQUEST ERROR",
                    message: `${error.response.config.method} ${
                        error.response.config.url
                    } with data ${
                        error.response.config.data
                    } failed with status: ${
                        error.response.status
                    } response: ${JSON.stringify(error.message)}`,
                    category: LOG_CATEGORY_TYPES.CC,
                    type: EV_ERROR_TYPES.SERVER_INTERNAL,
                    handled: false,
                }),
                "",
                LOG_TYPE.ERROR
            )
        );
    }
);

// common network calls
const apiGet = (url, qParams) => {
    let fullUrl = url;
    if (!isNullOrEmpty(qParams)) {
        fullUrl = `${url}?${queryStringGenerator(qParams)}`;
    }
    return api.get(fullUrl);
};

const apiPost = (url, qParams, data) => {
    let fullUrl = url;
    if (!isNullOrEmpty(qParams)) {
        fullUrl = `${url}?${queryStringGenerator(qParams)}`;
    }
    return api.post(fullUrl, data);
};

const apiDelete = (url, qParams, data) => {
    let fullUrl = url;
    if (!isNullOrEmpty(qParams)) {
        fullUrl = `${url}?${queryStringGenerator(qParams)}`;
    }
    return api.delete(fullUrl, { data });
};

const apiPut = (url, qParams, data) => {
    let fullUrl = url;
    if (!isNullOrEmpty(qParams)) {
        fullUrl = `${url}?${queryStringGenerator(qParams)}`;
    }
    return api.put(fullUrl, data);
};

const apiMock = (url, params = {}, mockData = {}, bodyData = {}) => {
    // eslint-disable-next-line no-console
    console.info(
        `Mocking api ${url} with qData ${JSON.stringify(
            params
        )}, bData ${JSON.stringify(bodyData)}`
    );
    return new Promise((resolve) => {
        setTimeout(() => resolve({ data: mockData }), MOCK_API_DELAY);
    });
};

export default api;
export { apiGet, apiPost, apiMock, apiDelete, apiPut };
