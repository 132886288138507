import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import { EvButton, EvSimpleCard, EvText } from "../../../common/components";
import { CLIENT_PAGE_PATHS } from "../../../common/static/Enums";

import "../styles/client-dashboard-campaigns-card.scss";

const styles = {
    container: "ev__client-dashboard-campaigns-card__container",
    contentContainer: "ev__client-dashboard-campaigns-card__content-container",
    contentContainerInner:
        "ev__client-dashboard-campaigns-card__content-container-inner",
    header: {
        container: "ev__client-dashboard-campaigns-card__header-container",
    },
    action: {
        container: "ev__client-dashboard-campaigns-card__action-container",
        button: "ev__client-dashboard-campaigns-card__action-button",
    },
};

const ClientDashboardCampaignsCard = (props) => {
    const { staticData, goToPage } = props;

    const contentContainerRef = useRef(null);

    const onCtaClick = useCallback(() => {
        goToPage(CLIENT_PAGE_PATHS.CLIENT_CONTENT.path);
    }, [goToPage]);

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText headingPrimary>{staticData.header}</EvText>
            {staticData.content && <EvText>{staticData.content}</EvText>}
        </div>
    );

    const getActionView = () => (
        <div className={styles.action.container}>
            <EvButton
                className={styles.action.button}
                primary
                onClickHandler={onCtaClick}
            >
                {staticData.cta.text}
            </EvButton>
        </div>
    );
    const getContent = () => (
        <div className={styles.contentContainer}>
            <div
                ref={contentContainerRef}
                className={styles.contentContainerInner}
            >
                {getHeaderView()}
                {getActionView()}
            </div>
        </div>
    );

    return (
        <EvSimpleCard noPadding className={styles.container}>
            {getContent()}
        </EvSimpleCard>
    );
};

ClientDashboardCampaignsCard.propTypes = {
    staticData: PropTypes.object,
    goToPage: PropTypes.func,
};

ClientDashboardCampaignsCard.defaultProps = {
    staticData: {
        // header: "Activity Log",
        // content: "{count} pending changes",
        // noActivityPendingText: "All changes approved",
        // cta: { text: "View all activity" },
    },
    goToPage: () => {},
};

export default ClientDashboardCampaignsCard;
