import React, { memo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton } from "../index";
import FormValidation from "../../../utils/FormValidations";

import "../../styles/form-components/ev-input-field.scss";
import { EvInputErrorView } from "./index";

/**
 * This is an input field along with *some text* on it's left if passed as "label" prop.
 * keyName prop is mandatory.
 */

const EvInputField = (props) => {
    const styles = {
        mainContainer: "ev__ev-input-field__main-container",
        inputContainer: "ev__ev-input-field__input-container",
        linkContainer: "ev__ev-input-field__link-container",
        errorContainer: "ev__ev-input-field__error-container",
        inputBox: "ev__ev-input-field__input-box",
        label: "ev__ev-input-field__label",
    };

    const {
        placeholder,
        inlineStyles,
        value,
        editable,
        type,
        formItem,
        showError,
        formData,
    } = props;

    const customClass = classNames(styles.inputBox, props.className);

    const onExternalLinkClick = useCallback(() => {
        window.open(value, "_blank");
    }, [value]);

    const onChange = (e) => {
        const inputFieldObject = {
            [props.keyName]: {
                key: props.keyName,
                value: e.target.value,
                type: props.type,
            },
            errorObject: {}, // will be overwritten later
            formItemType: type,
        };
        props.handleInputChange(props.keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
            },
        });
    };

    const onBlur = (e) => {
        const inputFieldObject = {
            [props.keyName]: {
                key: props.keyName,
                value: e.target.value,
                type: props.type,
            },

            errorObject: {}, // will be overwritten later
            formItemType: type,
        };
        props.handleInputChange(props.keyName, {
            ...inputFieldObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, inputFieldObject),
                isUsed: true,
            },
        });
    };

    const getInputField = (inputFieldData) => (
        <input
            key={props.keyName}
            className={customClass}
            style={inputFieldData.inlineStyles}
            placeholder={inputFieldData.placeholder}
            onChange={onChange}
            onBlur={onBlur}
            type={props.type}
            value={inputFieldData.value}
            disabled={!editable}
        />
    );

    const getErrorView = () => {
        // if (isNullOrEmpty(formData.errorObject) || !formData.errorObject.isUsed) {
        //   return null;
        // }
        // return Object.keys(formData.errorObject.errorMessages).map(err => (
        //   <div>
        //     <EvText color={COLORS.RED_MONZA} smallBold>
        //       {formData.errorObject.errorMessages[err]}
        //     </EvText>
        //   </div>
        // ));
        return <EvInputErrorView errorObject={formData.errorObject} />;
    };

    const getExternalLinkView = () => {
        if (!value) {
            return null;
        }
        return (
            <div className={styles.linkContainer}>
                <EvButton
                    onlyChild
                    linkButton
                    onClickHandler={onExternalLinkClick}
                >
                    {formItem.options.hrefPreviewCtaText || "Open"}
                </EvButton>
            </div>
        );
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.inputContainer}>
                {getInputField({ placeholder, inlineStyles, value })}
            </div>
            {formItem.options &&
                formItem.options.hrefPreview &&
                getExternalLinkView()}
            <div className={styles.errorContainer}>
                {showError && getErrorView()}
            </div>
        </div>
    );
};

EvInputField.propTypes = {
    /** use along inputField to give a label */
    label: PropTypes.string,
    /** use along inputField to give a placeholder */
    placeholder: PropTypes.string,
    /** key of the inputField */
    keyName: PropTypes.string,
    /** type of the inputField */
    type: PropTypes.string,
    /** is the field editable */
    editable: PropTypes.bool,
    /** className for the inputField */
    className: PropTypes.string,
    /** className for the inputField */
    value: PropTypes.string,

    /** customStyles for the inputField, if any */
    inlineStyles: PropTypes.object,

    /** function to handle input change */
    handleInputChange: PropTypes.func,
};

EvInputField.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    keyName: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editable: PropTypes.bool,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    showError: PropTypes.bool,

    inlineStyles: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvInputField.defaultProps = {
    label: "",
    placeholder: "",
    keyName: "",
    className: "",
    type: "",
    value: "",
    editable: true,
    formItem: {},
    formData: {},
    showError: true,

    inlineStyles: {},

    handleInputChange: () => {},
};

export default memo(EvInputField);
