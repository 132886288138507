import { apiGet, apiMock, apiPost } from "../../../services/api";
import API_URLS from "../../../services/apiUrls";
import {
    NOTIFICATION_CONTENT_PAGE_JSON,
    TARGET_POPULATION_JSON,
    NEW_POPULATION_PAGE_JSON,
    TAG_SELECTION_PAGE_JSON,
    SCHEDULE_PAGE_JSON,
    DASHBOARD_PAGE_JSON,
    COMMON_RESPONSE,
    SUMMARY_PAGE_JSON,
    NOTIFICATION_PROGRAM_SELECTION,
    POPULATION_UPLOAD_JSON,
} from "../static/getNotificationsDataJSON";
import EvLogger from "../../../utils/EvLogger";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // static data and templates
        case API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NOTIFICATION_PROGRAM_SELECTION.STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.CONTENT.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NOTIFICATION_CONTENT_PAGE_JSON.STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.TARGET_POPULATION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, TARGET_POPULATION_JSON.STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.UPLOAD_POPULATION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, POPULATION_UPLOAD_JSON.STATIC)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.NEW_POPULATIONS.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NEW_POPULATION_PAGE_JSON.PAGE_STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.NEW_POPULATIONS.TEMPLATE_CRITERIA_FORM_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NEW_POPULATION_PAGE_JSON.CRITERIA_RULE_FORM_DATA
                  )
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.TAG_SELECTION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.PAGE_STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.NEW_POPULATIONS.TAG_CATEGORIES:
            return USE_MOCK_DATA
                ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.CATEGORIES)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.SCHEDULE.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, SCHEDULE_PAGE_JSON.STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.SUMMARY.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, SUMMARY_PAGE_JSON.STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, DASHBOARD_PAGE_JSON.STATIC_DATA)
                : apiGet(url, params);

        // dynamic data: program selection
        case API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.PRE_FILLED_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NOTIFICATION_PROGRAM_SELECTION.PRE_FILLED_DATA
                  )
                : apiGet(url, params);

        // dynamic data: content
        case API_URLS.NOTIFICATIONS.CONTENT.PRE_FILLED_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NOTIFICATION_CONTENT_PAGE_JSON.PRE_FILLED_DATA
                  )
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.CONTENT.LAZY_MODAL_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NOTIFICATION_CONTENT_PAGE_JSON.LAZY_MODAL_DATA
                  )
                : apiGet(url, params);

        // dynamic data: target population
        case API_URLS.NOTIFICATIONS.TARGET_POPULATION.SAVED_POPULATION_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, TARGET_POPULATION_JSON.SAVED_POPULATION)
                : apiGet(url, params);

        case API_URLS.NOTIFICATIONS.UPLOAD_POPULATION.UPLOADED_POPULATION_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, POPULATION_UPLOAD_JSON.POPULATION_DATA)
                : apiGet(url, params);

        // dynamic data: new population
        case API_URLS.NOTIFICATIONS.NEW_POPULATIONS.GET_POPULATION_DETAILS:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NEW_POPULATION_PAGE_JSON.GET_POPULATION_DETAILS
                  )
                : apiGet(url, params);

        //  dynamic data: scheduling page
        case API_URLS.NOTIFICATIONS.SCHEDULE.PRE_FILLED_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, SCHEDULE_PAGE_JSON.PRE_FILLED_DATA)
                : apiGet(url, params);

        //  dynamic data: notification dashboard page
        case API_URLS.NOTIFICATIONS.DASHBOARD.NOTIFICATIONS_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, params, DASHBOARD_PAGE_JSON.NOTIFICATIONS_DATA)
                : apiGet(url, params);

        //  dynamic data: summary page
        case API_URLS.NOTIFICATIONS.SUMMARY.DYNAMIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      SUMMARY_PAGE_JSON.NOTIFICATION_SUMMARY_DATA
                  )
                : apiGet(url, params);

        // dynamic data: new tag selection
        case API_URLS.NOTIFICATIONS.TAG_SELECTION.ALL_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, params, TAG_SELECTION_PAGE_JSON.COMBINED_TAGS)
                : apiGet(url, params);

        default:
            EvLogger.warn("NotificationsService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.NOTIFICATIONS.PROGRAM_SELECTION.POST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      NOTIFICATION_PROGRAM_SELECTION.CONTENT_POST_SUCCESS,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.NOTIFICATIONS.CONTENT.POST_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      qParams,
                      NOTIFICATION_CONTENT_PAGE_JSON.CONTENT_POST_SUCCESS,
                      data
                  )
                : apiPost(url, qParams, data);

        case API_URLS.NOTIFICATIONS.NEW_POPULATIONS.SAVE_POPULATION_DETAILS:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        case API_URLS.NOTIFICATIONS.UPLOAD_POPULATION.SUBMIT_POPULATION:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        case API_URLS.NOTIFICATIONS.SCHEDULE.POST_DATA:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        case API_URLS.NOTIFICATIONS.SUMMARY.POST_SCHEDULE:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("NotificationsService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData };
