const EvClientTicketCardData = {
    data1: {
        imgUrl: "",
        clientName: "Client 1",
        updatedDate: "Updated: 05/22/19",
        userCount: {
            icon: "USER",
            text: "2 users",
        },
        status: "LIVE",
    },
    data2: {
        imgUrl:
            "https://thumbs.dreamstime.com/b/ai-file-icon-web-set-technological-bacground-143848408.jpg",
        clientName: "Client 2",
        updatedDate: "Updated: 06/22/19",
        userCount: {
            icon: "USER",
            text: "2 users",
        },
        status: "NOT LIVE",
    },
    data3: {
        imgUrl: "",
        clientName: "Client 3",
        updatedDate: "Updated: 05/22/19",
        userCount: {
            icon: "USER",
            text: "2 users",
        },
        status: "NOT LIVE",
    },
};

export default EvClientTicketCardData;
