const CALLOUTS_APP_ID = "campaigns__callouts";

const CALLOUTS_TEMPLATES = {
    // STATIC: {
    //   id: "callouts__static",
    // },
    LIST_VIEW_STATIC: {
        id: "callouts__listViewStatic",
    },
    CONTENT_STATIC: {
        id: "callouts__contentStatic",
    },
};
//
// const CALLOUTS_COMPONENTS = {
//   FORM_DATA: {
//     id: "callouts__formData",
//   },
// };

const CALLOUT_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
};

const CALLOUTS_PAGES = {
    LIST_VIEW: {
        path: "/cc/client/manage-programs/customize-programs/callouts",
        id: "callouts__listView",
    },
    CONTENT: {
        path: "/cc/client/manage-programs/customize-programs/callouts/content",
        id: "callouts__content",
    },
};

export {
    // CALLOUTS_COMPONENTS,
    CALLOUTS_TEMPLATES,
    CALLOUT_STATICS,
    CALLOUTS_PAGES,
    CALLOUTS_APP_ID,
};
