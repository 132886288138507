import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import ResourceCardContainer from "../../resource-card/views/ResourceCardContainer";
// import CardsDashboardContainer from "./CardsDashboardContainer";
import CurrentStatusContainer from "../../current-status/views/CurrentStatusContainer";
import WhereToContainer from "../../where-to/views/WhereToContainer";
import Evive360Container from "../../evive-360/views/Evive360Container";
import { CARDS_PAGES } from "../statics/CardsDashboardStatics";
import { EvBackButton } from "../../../common/components";
import CalloutsContent from "../../callouts/views/CalloutsContent";

import "../styles/cards-content-routes.scss";

const styles = {
    container: "ev__cards-content-routes__container",
    backButtonContainer: "ev__cards-content-routes__back-button-container",
};

const CardsContentRoutes = (props) => {
    const { goBackAction } = props;

    return (
        <div className={styles.container}>
            <EvBackButton
                onClickHandler={goBackAction}
                className={styles.backButtonContainer}
            >
                Back
            </EvBackButton>
            <Switch>
                <Route
                    path={CARDS_PAGES.RESOURCE_CARD.path}
                    exact
                    render={() => <ResourceCardContainer />}
                />
                <Route
                    path={CARDS_PAGES.CURRENT_STATUS.path}
                    exact
                    render={() => <CurrentStatusContainer />}
                />
                <Route
                    path={CARDS_PAGES.WHERE_TO_GO_FOR_CARE.path}
                    exact
                    render={() => <WhereToContainer />}
                />
                <Route
                    path={CARDS_PAGES.EVIVE_360.path}
                    exact
                    render={() => <Evive360Container />}
                />
                <Route
                    path={CARDS_PAGES.CALLOUTS.path}
                    exact
                    render={() => <CalloutsContent />}
                />
                {/* default */}
                {/*<Route render={() => <CardsDashboardContainer />} />*/}
            </Switch>
        </div>
    );
};

CardsContentRoutes.propTypes = {
    goBackAction: PropTypes.func,
};

CardsContentRoutes.defaultProps = {
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    // controls: state.Reducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardsContentRoutes);
