import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "../styles/ev-expandable-view.scss";

const styles = {
    container: "ev__ev-expandable-view__container",
    contentContainer: "ev__ev-expandable-view__content-container",
};

const EvExpandableView = (props) => {
    const {
        updateDelay,
        updateSeed,
        isExpanded,
        animateOpacity,
        children,
        className,
        contentClassName,
    } = props;

    const contentContainerRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(0); // must be in a state, else it will not trigger render or on ref change

    useEffect(() => {
        setTimeout(() => {
            setContentHeight(
                contentContainerRef.current &&
                    contentContainerRef.current.clientHeight
                    ? `${contentContainerRef.current.clientHeight}px`
                    : 0
            );
        }, updateDelay);
    }, [isExpanded, updateSeed, updateDelay]);
    // isExpanded is added so that it calculates every time it changes
    // this is required for nested expandable views

    const customContainerClass = classNames(styles.container, className);
    const customContentContainerClass = classNames(
        styles.contentContainer,
        contentClassName
    );

    return (
        <div
            className={customContainerClass}
            style={{
                height: isExpanded ? contentHeight : 0,
                opacity: isExpanded ? 1 : animateOpacity ? 0 : 1,
            }}
        >
            <div
                className={customContentContainerClass}
                ref={contentContainerRef}
            >
                {children}
            </div>
        </div>
    );
};

EvExpandableView.propTypes = {
    updateDelay: PropTypes.number,
    updateSeed: PropTypes.number, // used to trigger recalculation of height for any change in children
    isExpanded: PropTypes.bool,
    animateOpacity: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.oneOfType(PropTypes.string, PropTypes.array),
    contentClassName: PropTypes.oneOfType(PropTypes.string, PropTypes.array),
};

EvExpandableView.defaultProps = {
    updateDelay: 0,
    updateSeed: 0,
    isExpanded: true,
    animateOpacity: true,
    children: null,
    className: "",
    contentClassName: "",
};

export default EvExpandableView;
