import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import "../../styles/content-items/ev-content-image.scss";

const styles = {
    container: "ev__ev-content-image__container",
    image: "ev__ev-content-image__image",
};

const EvContentImage = (props) => {
    const { id, displayResolver, allValues } = props;
    const imageUrl = stringTemplate(displayResolver, allValues);
    return (
        <div className={styles.container}>
            <img className={styles.image} alt={id} src={imageUrl} />
        </div>
    );
};

EvContentImage.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentImage.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    displayResolver: "",
    allValues: {},
};

export default EvContentImage;
