import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import {
    // guidGenerator,
    isCompleted,
    // isCompletedOrLoading,
    // isNullOrEmpty,
} from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    // parseCriteriaFormDataForApi,
    parseFormDataForApi,
    // parseInitialFormDataForCriteriaList,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import NotificationsActions from "../redux/NotificationsActions";
import { getData, postData } from "../service/NotificationsService";
import {
    NOTIFICATIONS_COMPONENTS,
    NOTIFICATIONS_PAGES,
    // NOTIFICATION_TEMPLATES,
} from "../static/NotificationsStatics";
import EvNotificationsEditPopulationView from "./EvNotificationsEditPopulationView";

const NotificationsNewPopulation = (props) => {
    const {
        clientCode,
        controls,
        staticData,

        // allTags,
        // criteriaListFormData,
        populationDetailsFormData,

        // actions
        getStaticData,
        setDynamicDataApiStatus,
        setComponentData,
        // createTagsDictionary,
        setControls,
    } = props;

    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    // const getAllRequiredData = useCallback(
    //     (clientCode, notificationId, populationId, isNewPopulation = false) => {
    //         if (
    //             isCompletedOrLoading(
    //                 controls.staticDataApiStatus[
    //                     NOTIFICATIONS_PAGES.NEW_POPULATION.id
    //                 ],
    //                 controls.staticDataApiStatus[
    //                     NOTIFICATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id
    //                 ],
    //                 controls.dynamicDataApiStatus[
    //                     NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id
    //                 ]
    //             )
    //         ) {
    //             return;
    //         }
    //         // must call details api after static data, it has optional dependency
    //         Promise.all([
    //             getStaticData({
    //                 templateId: NOTIFICATIONS_PAGES.NEW_POPULATION.id,
    //                 url: API_URLS.NOTIFICATIONS.NEW_POPULATIONS.STATIC,
    //             }),
    //             getStaticData({
    //                 templateId:
    //                     NOTIFICATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id,
    //                 url: API_URLS.NOTIFICATIONS.NEW_POPULATIONS
    //                     .TEMPLATE_CRITERIA_FORM_DATA,
    //             }),
    //         ])
    //             .then(([staticDataResponse, criteriaRuleFormDataResponse]) => {
    //                 // if criteria details is not present already
    //                 if (
    //                     !isCompleted(
    //                         controls.dynamicDataApiStatus[
    //                             NOTIFICATIONS_COMPONENTS
    //                                 .NEW_POPULATION_CRITERIA_LIST.id
    //                         ]
    //                     )
    //                 ) {
    //                     setDynamicDataApiStatus({
    //                         [NOTIFICATIONS_COMPONENTS
    //                             .NEW_POPULATION_CRITERIA_LIST.id]:
    //                             LOADING_STATUS.LOADING,
    //                     });
    //
    //                     getData(
    //                         API_URLS.NOTIFICATIONS.NEW_POPULATIONS
    //                             .GET_POPULATION_DETAILS,
    //                         {
    //                             clientCode,
    //                             notificationId,
    //                             populationId,
    //                             isNewPopulation,
    //                         }
    //                     )
    //                         .then((populationDetailsResponse) => {
    //                             const initialDetailsFormData =
    //                                 parseInitialStateFromFormFields(
    //                                     staticDataResponse.populationFormFields,
    //                                     populationDetailsResponse.data.data
    //                                         .populationDetails
    //                                 );
    //                             setComponentData({
    //                                 componentId:
    //                                     NOTIFICATIONS_COMPONENTS
    //                                         .NEW_POPULATION_DETAILS.id,
    //                                 data: initialDetailsFormData,
    //                             });
    //
    //                             // this is for all the criteria form data
    //                             let initialListFormData = {};
    //                             // if criteria list is empty add one empty criteria initially
    //                             if (
    //                                 populationDetailsResponse.data.data
    //                                     .criteriaList.length <= 0
    //                             ) {
    //                                 initialListFormData = {
    //                                     [guidGenerator()]: {},
    //                                 };
    //                             } else {
    //                                 initialListFormData =
    //                                     parseInitialFormDataForCriteriaList(
    //                                         criteriaRuleFormDataResponse.formFields,
    //                                         populationDetailsResponse.data.data
    //                                             .criteriaList
    //                                     );
    //                             }
    //                             setComponentData({
    //                                 componentId:
    //                                     NOTIFICATIONS_COMPONENTS
    //                                         .NEW_POPULATION_CRITERIA_LIST.id,
    //                                 data: initialListFormData,
    //                             });
    //                             setDynamicDataApiStatus({
    //                                 [NOTIFICATIONS_COMPONENTS
    //                                     .NEW_POPULATION_CRITERIA_LIST.id]:
    //                                     LOADING_STATUS.COMPLETED,
    //                             });
    //                         })
    //                         .catch((e) => {
    //                             // if population data not preset or some error
    //                             setControls({
    //                                 selectedPageId:
    //                                     NOTIFICATIONS_PAGES.TARGET_POPULATION
    //                                         .id,
    //                             });
    //                             EvLogger.errorWithObject(
    //                                 e,
    //                                 "NotificationsNewPopulation getPopulationData"
    //                             );
    //                         });
    //                 }
    //             })
    //             .catch((e) => {
    //                 EvLogger.errorWithObject(
    //                     e,
    //                     "NotificationsNewPopulation getData"
    //                 );
    //             });
    //     },
    //     [
    //         controls.staticDataApiStatus,
    //         getStaticData,
    //         setDynamicDataApiStatus,
    //         setComponentData,
    //         setControls,
    //         controls.dynamicDataApiStatus,
    //     ]
    // );
    //
    // useEffect(() => {
    //     if (
    //         !isCompletedOrLoading(
    //             controls.dynamicDataApiStatus[
    //                 NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
    //             ]
    //         )
    //     ) {
    //         createTagsDictionary();
    //     }
    // }, [createTagsDictionary, controls.dynamicDataApiStatus]);
    //
    // useEffect(() => {
    //     // dont put client code and notification id in dependency list, else it will call again and again
    //     getAllRequiredData(
    //         clientCode,
    //         controls.currentNotificationId || "",
    //         controls.currentNotificationPopulationId || "",
    //         controls.isNewPopulation || false
    //     );
    // }, [
    //     getAllRequiredData,
    //     clientCode,
    //     controls.currentNotificationId,
    //     controls.currentNotificationPopulationId,
    //     controls.isNewPopulation,
    // ]);

    const getAllRequiredData = useCallback(
        (clientCode, notificationId, populationId, isNewPopulation = false) =>
            Promise.all([
                getStaticData({
                    templateId: NOTIFICATIONS_PAGES.NEW_POPULATION.id,
                    url: API_URLS.NOTIFICATIONS.NEW_POPULATIONS.STATIC,
                }),
                getData(
                    API_URLS.NOTIFICATIONS.NEW_POPULATIONS
                        .GET_POPULATION_DETAILS,
                    {
                        clientCode,
                        notificationId,
                        populationId,
                        isNewPopulation,
                    }
                ),
            ]),
        [getStaticData]
    );

    useEffect(() => {
        getAllRequiredData(
            clientCode,
            controls.currentNotificationId || "",
            controls.currentNotificationPopulationId || "",
            controls.isNewPopulation || false
        )
            .then(([staticResponse, populationDetailsResponse]) => {
                const initialDetailsFormData = parseInitialStateFromFormFields(
                    staticResponse.populationFormFields,
                    {
                        title: populationDetailsResponse.data.data
                            .populationDetails.title,
                        audiences:
                            populationDetailsResponse.data.data.audiences,
                    }
                );
                setComponentData({
                    componentId:
                        NOTIFICATIONS_COMPONENTS.NEW_POPULATION_DETAILS.id,
                    data: initialDetailsFormData,
                });
                setDynamicDataApiStatus({
                    [NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                // if population data not preset or some error
                setControls({
                    selectedPageId: NOTIFICATIONS_PAGES.TARGET_POPULATION.id,
                });
                EvLogger.errorWithObject(
                    e,
                    "NotificationsNewPopulation getPopulationData"
                );
            });
    }, [
        getAllRequiredData,
        setComponentData,
        setControls,
        setDynamicDataApiStatus,
        clientCode,
        controls.currentNotificationId,
        controls.currentNotificationPopulationId,
        controls.isNewPopulation,
    ]);

    const onDetailsFormDataChange = useCallback(
        (newFormData) => {
            setComponentData({
                componentId: NOTIFICATIONS_COMPONENTS.NEW_POPULATION_DETAILS.id,
                data: newFormData,
            });
        },
        [setComponentData]
    );

    // // used to navigate to tag selection page, for box click and edit
    // const onCriteriaClick = useCallback(
    //     (criteriaId) => {
    //         setControls({
    //             tagSelectionCriteriaId: criteriaId,
    //             selectedPageId: NOTIFICATIONS_PAGES.TAG_SELECTION.id,
    //         });
    //     },
    //     [setControls]
    // );
    //
    // const onRemoveCriteriaClick = useCallback(
    //     (newCriteriaListFormData) => {
    //         setComponentData({
    //             componentId:
    //                 NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id,
    //             data: newCriteriaListFormData,
    //             // clearPrevious: true,
    //         });
    //     },
    //     [setComponentData]
    // );
    //
    // const onAddNewCriteriaClick = useCallback(() => {
    //     const guid = guidGenerator();
    //     setComponentData({
    //         componentId:
    //             NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id,
    //         data: {
    //             ...criteriaListFormData,
    //             [guid]: {},
    //         },
    //     });
    // }, [criteriaListFormData, setComponentData]);

    const onSaveButtonClick = useCallback(() => {
        // if population name form is not valid
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (!isFullFormValid(populationDetailsFormData)) {
            setComponentData({
                componentId: NOTIFICATIONS_COMPONENTS.NEW_POPULATION_DETAILS.id,
                data: {
                    ...markAllFieldsUsed(populationDetailsFormData),
                },
            });
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = {
            clientCode,
            populationId: controls.currentNotificationPopulationId || "",
            notificationId: controls.currentNotificationId || "",
            ...parseFormDataForApi(populationDetailsFormData),
            // criteriaList: parseCriteriaFormDataForApi(
            //     criteriaListFormData
            // ).filter((criteria) => !isNullOrEmpty(criteria)),
        };
        postData(
            API_URLS.NOTIFICATIONS.NEW_POPULATIONS.SAVE_POPULATION_DETAILS,
            {},
            apiData
        )
            .then((response) => {
                EvToast.success("Saved", "Audience saved", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setControls({
                    selectedPageId: NOTIFICATIONS_PAGES.SCHEDULE.id,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvToast.error("", "Error in saving");
                EvLogger.errorWithObject(e, "NotificationNewPopulation onSave");
            });
    }, [
        clientCode,
        controls.currentNotificationId,
        controls.currentNotificationPopulationId,
        setControls,
        // criteriaListFormData,
        populationDetailsFormData,
        setComponentData,
        submitStatus,
    ]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[NOTIFICATIONS_PAGES.NEW_POPULATION.id],
            controls.dynamicDataApiStatus[
                NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id
            ]
            // controls.dynamicDataApiStatus[NOTIFICATIONS_COMPONENTS.ALL_TAGS.id],
            // controls.staticDataApiStatus[
            //     NOTIFICATION_TEMPLATES.CRITERIA_RULE_FORM_DATA.id
            // ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <EvNotificationsEditPopulationView
            staticData={staticData}
            populationDetailsFormData={populationDetailsFormData}
            // allTags={allTags}
            // criteriaListFormData={criteriaListFormData}
            onDetailsFormDataChange={onDetailsFormDataChange}
            // onAddNewCriteriaClick={onAddNewCriteriaClick}
            onSaveButtonClick={onSaveButtonClick}
            // onCriteriaClick={onCriteriaClick}
            // onRemoveCriteria={onRemoveCriteriaClick}
            submitStatus={submitStatus}
        />
    );
};

NotificationsNewPopulation.propTypes = {
    clientCode: "",
    controls: PropTypes.object,
    staticData: PropTypes.object,

    // allTags: PropTypes.object,
    // criteriaListFormData: PropTypes.object,
    populationDetailsFormData: PropTypes.object,

    // actions
    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    // createTagsDictionary: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    setComponentData: PropTypes.func,
};

NotificationsNewPopulation.defaultProps = {
    clientCode: PropTypes.string,
    // clientName: PropTypes.string,
    controls: {},
    staticData: {},

    // allTags: {},
    // criteriaListFormData: {},
    populationDetailsFormData: {},

    // actions
    getStaticData: () => {},
    setControls: () => {},
    // createTagsDictionary: () => {},
    setDynamicDataApiStatus: () => {},
    setComponentData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATIONS_PAGES.NEW_POPULATION.id
        ],
    // allTags:
    //     state.NotificationsReducer.dynamicData[
    //         NOTIFICATIONS_COMPONENTS.ALL_TAGS.id
    //     ],
    // criteriaListFormData:
    //     state.NotificationsReducer.dynamicData[
    //         NOTIFICATIONS_COMPONENTS.NEW_POPULATION_CRITERIA_LIST.id
    //     ],
    populationDetailsFormData:
        state.NotificationsReducer.dynamicData[
            NOTIFICATIONS_COMPONENTS.NEW_POPULATION_DETAILS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
    // createTagsDictionary: (payload) =>
    //     dispatch(NotificationsActions.createTagsDictionary(payload)),
    setComponentData: (payload) =>
        dispatch(NotificationsActions.setComponentData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(NotificationsActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsNewPopulation);
