import React from "react";
import PropTypes from "prop-types";

import { EvFormRenderer } from "../../../common/components/form-components";
import {
    EvButton,
    EvDivider,
    EvLoader,
    EvLoadingPage,
    EvText,
} from "../../../common/components";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";

import "../styles/data-uploader-view.scss";

const styles = {
    container: "ev__data-uploader__container",
    divider: "ev__data-uploader__divider",
    actionContainer: "ev__data-uploader__action-container",
    formWrapper: "ev__data-uploader__form-wrapper",
    submitButton: "ev__data-uploader__submit-button",
};

const DataUploaderView = (props) => {
    const {
        formTypesStatics,
        formFieldsStatics,
        formFieldsStaticsApiStatus,
        submitFormApiStatus,
        prefillDataApiStatus,

        formTypeFormData,
        formFieldsFormData,

        setFormTypeFormData,
        setFormFieldsFormData,

        onGetFormOfTypeClick,
        onFormFieldsSubmit,
        onFormFieldsPrefillClick,
        onFormFieldsClearClick,
    } = props;

    const getFormTypeView = () => (
        <div className={styles.formWrapper}>
            <EvFormRenderer
                formFields={formTypesStatics.formFields}
                setFormDataState={setFormTypeFormData}
                formData={formTypeFormData}
            />
            <div className={styles.actionContainer}>
                <EvButton
                    primaryFilled
                    onClickHandler={onGetFormOfTypeClick}
                    className={styles.submitButton}
                >
                    Get Form
                </EvButton>
            </div>
        </div>
    );

    const getFormFieldsActionView = () => (
        <div className={styles.actionContainer}>
            <EvButton
                primaryFilled
                className={styles.submitButton}
                onClickHandler={onFormFieldsPrefillClick}
            >
                PreFill
            </EvButton>
            <EvButton
                primaryFilled
                className={styles.submitButton}
                onClickHandler={onFormFieldsClearClick}
            >
                Clear
            </EvButton>
            {prefillDataApiStatus === LOADING_STATUS.LOADING && (
                <EvLoader size={3} />
            )}
        </div>
    );

    const getFormSubmitActionView = () => (
        <div className={styles.actionContainer}>
            <EvButton
                className={styles.submitButton}
                primaryFilled
                onClickHandler={onFormFieldsSubmit}
            >
                Submit
            </EvButton>
            {submitFormApiStatus === LOADING_STATUS.LOADING && (
                <EvLoader size={3} />
            )}
        </div>
    );

    const getFormFieldsView = () => {
        if (formFieldsStaticsApiStatus === LOADING_STATUS.LOADING) {
            return <EvLoadingPage animatedFadeIn />;
        }

        if (isNullOrEmpty(formFieldsStatics)) {
            return <span />;
        }

        return (
            <div className={styles.formWrapper}>
                {getFormFieldsActionView()}
                <EvFormRenderer
                    formFields={formFieldsStatics.formFields}
                    setFormDataState={setFormFieldsFormData}
                    formData={formFieldsFormData}
                />
                {getFormSubmitActionView()}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <EvText headingSecondary>CC Data Uploader</EvText>
            <EvDivider className={styles.divider} />
            {getFormTypeView()}
            <EvDivider className={styles.divider} />
            {getFormFieldsView()}
        </div>
    );
};

DataUploaderView.propTypes = {
    formTypesStatics: PropTypes.object,
    formFieldsStatics: PropTypes.object,
    formFieldsStaticsApiStatus: PropTypes.string,
    prefillDataApiStatus: PropTypes.string,
    submitFormApiStatus: PropTypes.string,

    formTypeFormData: PropTypes.object,
    formFieldsFormData: PropTypes.object,

    setFormTypeFormData: PropTypes.func,
    setFormFieldsFormData: PropTypes.func,
    onGetFormOfTypeClick: PropTypes.func,
    onFormFieldsSubmit: PropTypes.func,
    onFormFieldsPrefillClick: PropTypes.func,
    onFormFieldsClearClick: PropTypes.func,
};

DataUploaderView.defaultProps = {
    formTypesStatics: {},
    formFieldsStatics: {},
    formFieldsStaticsApiStatus: LOADING_STATUS.NOT_YET_STARTED,
    prefillDataApiStatus: LOADING_STATUS.NOT_YET_STARTED,
    submitFormApiStatus: LOADING_STATUS.NOT_YET_STARTED,

    formTypeFormData: {},
    formFieldsFormData: {},

    setFormTypeFormData: () => {},
    setFormFieldsFormData: () => {},
    onGetFormOfTypeClick: () => {},
    onFormFieldsSubmit: () => {},
    onFormFieldsPrefillClick: () => {},
    onFormFieldsClearClick: () => {},
};

export default DataUploaderView;
