import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import { LOADING_STATUS } from "../../../common/static/Enums";
import { EvButton, EvIcon, EvLoader, EvText } from "../../../common/components";
import { EvFormRenderer } from "../../../common/components/form-components";
import { COLORS } from "../../../common/static/VmsStatics";
import { parsePreviewVariablesFromFormData } from "../../../utils/FormUtils";
import EvPreviewCardResolver from "../../../common/components/ev-cards/EvPreviewCardResolver";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import "../styles/evive-360-view.scss";

const styles = {
    container: "ev__evive-360-view__container",
    headerView: {
        container: "ev__evive-360-view__header-view-container",
        headerText: "ev__evive-360-view__header-view-header-text",
        descriptionText: "ev__evive-360-view__header-view-description-text",
    },
    formContainer: "ev__evive-360-view__form-container",
    actionView: {
        container: "ev__evive-360-view__action-view-container",
        button: "ev__evive-360-view__action-view-button",
        icon: "ev__evive-360-view__action-view-icon",
    },
    preview: {
        header: "ev__where-to-view__preview-header",
        container: "ev__where-to-view__preview-container",
    },
};

const Evive360View = (props) => {
    const {
        clientCode,
        vendorCardData,
        staticData,
        formData,
        setFormData,
        submitStatus,
        onFormSubmit,
    } = props;

    const getHeaderView = () => (
        <div className={styles.headerView.container}>
            <EvText
                subHeading
                dangerous
                className={styles.headerView.headerText}
            >
                {stringTemplate(staticData.header, {
                    vendorName: vendorCardData.vendorName,
                    programName: vendorCardData.programName,
                })}
            </EvText>
            <EvText className={styles.headerView.descriptionText}>
                {staticData.description}
            </EvText>
        </div>
    );

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormData}
                qParams={{
                    clientCode,
                    vendorId: vendorCardData.vendorId,
                    programId: vendorCardData.programId,
                    cardId: vendorCardData.cardId,
                }}
            />
        </div>
    );

    const getSubmitStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        size={3}
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        size={3}
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primaryFilled
                onClickHandler={onFormSubmit}
                className={styles.actionView.button}
            >
                {staticData.submitCta.text}
            </EvButton>
            {getSubmitStatusIcon()}
        </div>
    );

    const getPreviewCard = () => {
        const previewVariables = parsePreviewVariablesFromFormData(
            formData,
            staticData.cardPreview.contentKeys,
            staticData.cardPreview.staticContent
        );
        return (
            <div className={styles.preview.container}>
                <EvText className={styles.preview.header}>
                    {staticData.cardPreview.header}
                </EvText>
                <EvPreviewCardResolver
                    previewType={staticData.cardPreview.previewType}
                    previewVariables={previewVariables}
                    staticContent={staticData.cardPreview.staticContent}
                />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {getActionView()}
            {!isNullOrEmpty(staticData.cardPreview) && getPreviewCard()}
        </div>
    );
};

Evive360View.propTypes = {
    vendorCardData: PropTypes.object,
    staticData: PropTypes.object,
    clientCode: PropTypes.string,

    formData: PropTypes.object,
    setFormData: PropTypes.func,
    submitStatus: PropTypes.string,

    onFormSubmit: PropTypes.func,
};

Evive360View.defaultProps = {
    vendorCardData: {},
    staticData: {},
    clientCode: "",

    formData: {},
    setFormData: () => {},
    submitStatus: LOADING_STATUS.NOT_YET_STARTED,

    onFormSubmit: () => {},
};

export default Evive360View;
