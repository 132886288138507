import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/ev-simple-card.scss";

/**
 * Simple card to wrap elements to have shadow and broder radius
 */
const EvSimpleCard = (props) => {
    const styles = {
        container: "ev__ev-simple-card__container",
        shadow: "ev__ev-simple-card__shadow",
        padding: "ev__ev-simple-card__padding",
        background: "ev__ev-simple-card__background",
    };
    const customClass = classNames(
        styles.container,
        {
            [styles.shadow]: props.shadow,
            [styles.padding]: !props.noPadding,
            [styles.background]: !props.noBackground,
        },
        props.className
    );
    return (
        <div className={customClass} style={props.inlineStyle}>
            {props.children}
        </div>
    );
};

EvSimpleCard.propTypes = {
    shadow: PropTypes.bool,
    noPadding: PropTypes.bool,
    noBackground: PropTypes.bool,

    className: PropTypes.string,
    inlineStyle: PropTypes.object,
    children: PropTypes.node,
};

EvSimpleCard.defaultProps = {
    /** if required to hide shadow */
    shadow: true,
    /** if required to remove inner default padding of 1em */
    noPadding: false,
    /** if required to remove the default bgcolor */
    noBackground: false,

    /** custom class to custom container class */
    className: "",
    /** inline styles to overwrite all other */
    inlineStyle: {},
    /** internal child nodes to render */
    children: null,
};

export default memo(EvSimpleCard);
