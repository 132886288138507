export const CLIENT_DASHBOARD_APP_ID = "clientDashboard__dashboardStatic";

const CLIENT_DASHBOARD_TEMPLATES = {
    DASHBOARD_STATIC: {
        id: "clientDashboard__dashboardStatic",
    },
};

const CLIENT_DETAILS_ROUTES = {
    CLIENT_DASHBOARD: {
        path: "/cc/client/details/dashboard",
        id: "clientDetails_dashboard",
    },
    // CLIENT_BENEFITS: {
    //   path: "/cc/client/details/benefits",
    //   id: "clientDetails_benefits",
    // },
    EDIT_CLIENT: {
        path: "/cc/client/details/edit",
        id: "clientDetails_editClient",
    },
    // BENEFITS_SUMMARY: {
    //   path: "/cc/client/details/benefits-summary",
    //   id: "clientDetails_benefitsSummary",
    // },
};

const CLIENT_DETAILS_COMPONENTS = {
    CURRENT_DETAILS: {
        id: "current_details",
    },
    CLIENT_DATA: {
        id: "clientDetails__clientData",
    },
    // BENEFITS_LIST: {
    //   id: "clientDetails__benefitsList",
    // },
    // BENEFIT_SUMMARY: {
    //   id: "clientDetails__benefitSummary",
    // },
};

const CLIENT_DETAILS_TEMPLATES = {
    EDIT_CLIENT_STATIC: {
        id: "clientDetails__editClientStatic",
    },
    // BENEFITS_STATIC: {
    //   id: "clientDetails__benefitsStatic",
    // },
};

const EDIT_CLIENT_DETAILS_STATICS = {
    BACK_BUTTON_TEXT: "Back",
    FORM_INVALID: "Some of the form values are not valid",
};

// const CLIENT_DETAILS_BENEFITS_STATICS = {
//   BACK_BUTTON_TEXT: "Back",
// };

const DASHBOARD_CARDS_STATICS = {
    // ACTIVITY_LOGS_LOADING: "Loading activity logs...",
};

export {
    CLIENT_DASHBOARD_TEMPLATES,
    CLIENT_DETAILS_ROUTES,
    CLIENT_DETAILS_COMPONENTS,
    CLIENT_DETAILS_TEMPLATES,
    EDIT_CLIENT_DETAILS_STATICS,
    // CLIENT_DETAILS_BENEFITS_STATICS,
    DASHBOARD_CARDS_STATICS,
};
