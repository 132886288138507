import { isNullOrEmpty } from "./CommonUtils";
import { normalizerWithOrderArraySingleLevel } from "./Normalizer";

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

const occurrenceInString = (word, textData, searchInKey = "name") => {
    const count = (
        word[searchInKey]
            .toLowerCase()
            .match(new RegExp(escapeRegExp(textData.toLowerCase()), "g")) || []
    ).length;
    const indexOfCharacter = word[searchInKey]
        .toLowerCase()
        .indexOf(escapeRegExp(textData.toLowerCase()));
    return {
        ...word,
        occurrence: count,
        index: indexOfCharacter,
    };
};

const fuzzySearch = (arr, searchingCharacter, searchInKey = ["name"]) => {
    const order = [];

    arr.forEach((obj) => {
        let matchObject = {};
        searchInKey.forEach((currentSearchKey) => {
            const itemData = obj[currentSearchKey].toLowerCase();
            const textData = searchingCharacter.toLowerCase();
            if (itemData.indexOf(textData) > -1) {
                const currentMatchObject = occurrenceInString(
                    obj,
                    searchingCharacter,
                    currentSearchKey
                );
                if (
                    matchObject.index > currentMatchObject.index ||
                    !matchObject.index
                ) {
                    matchObject = currentMatchObject;
                }
            }
        });
        if (!isNullOrEmpty(matchObject)) {
            order.push(matchObject);
        }
    });
    order.sort((a, b) => {
        if (a.index < b.index) {
            return -1;
        }
        if (a.index > b.index) {
            return 1;
        }
        if (a.index === b.index) {
            if (a.occurrence < b.occurrence) {
                return 1;
            }
            if (a.occurrence > b.occurrence) {
                return -1;
            }
        }
        return 0;
    });
    return order;
};

const fuzzySearchInNormalizedArray = (
    values,
    searchingCharacter,
    searchInKey
) => {
    const dataArray = Object.values(values);
    const searchResult = fuzzySearch(
        dataArray,
        searchingCharacter,
        searchInKey
    );
    return normalizerWithOrderArraySingleLevel(searchResult);
};

export default fuzzySearch;
export { fuzzySearchInNormalizedArray };
