import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import ClientDashboardView from "./ClientDashboardView";
import {
    CLIENT_DASHBOARD_TEMPLATES,
    CLIENT_DETAILS_COMPONENTS,
    CLIENT_DETAILS_ROUTES,
} from "../statics/ClientDashboardStatics";
import API_URLS from "../../../services/apiUrls";
import { isNotYetStarted, isCompleted } from "../../../utils/CommonUtils";
import { EvLoadingPage } from "../../../common/components";
import { LOADING_STATUS } from "../../../common/static/Enums";
import ClientDetailsActions from "../redux/ClientDetailsActions";
import { getData } from "../service/ClientDetailsService";
import EvLogger from "../../../utils/EvLogger";

const ClientDashboardContainer = (props) => {
    const {
        clientCode,
        clientName,
        clientData,
        controls,
        staticData,

        getStaticData,
        navigateToPage,
        setComponentData,
        setDynamicDataApiStatus,
    } = props;

    const clientDataLoadingStatus = useMemo(
        () =>
            controls.dynamicDataApiStatus[
                CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id
            ],
        [controls.dynamicDataApiStatus]
    );

    useEffect(() => {
        getStaticData({
            url: API_URLS.CLIENT_DASHBOARD.STATIC,
            templateId: CLIENT_DASHBOARD_TEMPLATES.DASHBOARD_STATIC.id,
        });
    }, [getStaticData]);

    useEffect(() => {
        if (!isNotYetStarted(clientDataLoadingStatus)) {
            return;
        }
        setDynamicDataApiStatus({
            [CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id]: LOADING_STATUS.LOADING,
        });
        getData(API_URLS.CLIENT_DETAILS.GET_CLIENT_DETAILS_DATA, {
            clientCode,
        })
            .then((clientDetailsResponse) => {
                setComponentData({
                    componentId: CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id,
                    data: clientDetailsResponse.data.data,
                });
                setDynamicDataApiStatus({
                    [CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "ClientDashboardContainer getData");
            });
    }, [
        clientCode,
        setComponentData,
        setDynamicDataApiStatus,
        clientDataLoadingStatus,
    ]);

    const goToPage = useCallback(
        (path, params) => {
            navigateToPage({
                path,
                state: {
                    clientCode,
                    clientName,
                    ...params,
                },
            });
        },
        [navigateToPage, clientCode, clientName]
    );

    const goToEditPage = useCallback(() => {
        navigateToPage({
            path: CLIENT_DETAILS_ROUTES.EDIT_CLIENT.path,
            state: {
                clientCode,
                clientName,
            },
        });
    }, [navigateToPage, clientCode, clientName]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                CLIENT_DASHBOARD_TEMPLATES.DASHBOARD_STATIC.id
            ],
            controls.dynamicDataApiStatus[
                CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <ClientDashboardView
            staticData={staticData}
            clientDetails={clientData}
            clientCode={clientCode}
            goToPage={goToPage}
            goToEditPage={goToEditPage}
        />
    );
};

ClientDashboardContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    clientData: PropTypes.object,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    getStaticData: PropTypes.func,
    navigateToPage: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

ClientDashboardContainer.defaultProps = {
    clientCode: "",
    clientName: "",
    clientData: {},
    controls: {},
    staticData: {},

    getStaticData: () => {},
    navigateToPage: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.ClientDetailsReducer.controls,
    clientData:
        state.ClientDetailsReducer.dynamicData[
            CLIENT_DETAILS_COMPONENTS.CLIENT_DATA.id
        ],
    staticData:
        state.ClientDetailsReducer.staticData[
            CLIENT_DASHBOARD_TEMPLATES.DASHBOARD_STATIC.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(ClientDetailsActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(ClientDetailsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(ClientDetailsActions.setComponentData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDashboardContainer);
