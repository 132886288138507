import { push } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { EvLoadingPage } from "../../../../common/components";
import { LOADING_STATUS } from "../../../../common/static/Enums";
import API_URLS from "../../../../services/apiUrls";
import { isCompleted } from "../../../../utils/CommonUtils";
import EvLogger from "../../../../utils/EvLogger";
import VendorProgramsActions from "../../redux/VendorProgramsActions";
import { getData } from "../../service/VendorProgramsService";
import {
    VENDOR_PROGRAMS_PAGES,
    VENDOR_PROGRAMS_TEMPLATES,
    VENDOR_PROGRAM_COMPONENTS,
} from "../../statics/VendorProgramsStatics";
import VendorProgramsDashboard from "./VendorProgramsDashboard";

const VendorProgramsDashboardContainer = (props) => {
    const {
        clientCode,
        clientName,
        controls,
        staticData,
        currentVendors,

        // actions
        setControls,
        getStaticData,
        setDynamicDataApiStatus,
        setComponentData,
        navigateToPage,
    } = props;

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
            setControls({
                newVendorProgramSaved: false,
            });
        },
        [setControls, setDynamicDataApiStatus]
    );

    useEffect(() => {
        getStaticData({
            templateId: VENDOR_PROGRAMS_TEMPLATES.VENDOR_DASHBOARD.id,
            url: API_URLS.VENDOR_PROGRAMS.DASHBOARD.STATIC,
        });
    }, [getStaticData]);

    const loadData = useCallback(async () => {
        try {
            var { data } = await getData(
                API_URLS.VENDOR_PROGRAMS.CURRENT_VENDORS_DATA,
                { clientCode }
            );
        } catch (e) {
            setDynamicDataApiStatus({
                [VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id]:
                    LOADING_STATUS.FAILED,
            });

            EvLogger.errorWithObject(
                e,
                "VendorProgramsDashboardContainer getCurrentVendorData"
            );

            return;
        }
        setComponentData({
            componentId: [VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id],
            data: data.data.preSelected,
        });

        setDynamicDataApiStatus({
            [VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id]:
                LOADING_STATUS.COMPLETED,
        });
    }, [clientCode, setComponentData, setDynamicDataApiStatus]);

    useEffect(() => {
        // no need to load if already loaded or loading
        const loadingStatus =
            controls.dynamicDataApiStatus[
                VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id
            ];

        if (
            loadingStatus === LOADING_STATUS.COMPLETED ||
            loadingStatus === LOADING_STATUS.LOADING
        ) {
            return;
        }

        setDynamicDataApiStatus({
            [VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id]:
                LOADING_STATUS.LOADING,
        });

        loadData();
    }, [
        setDynamicDataApiStatus,
        setComponentData,
        controls.dynamicDataApiStatus,
        loadData,
    ]);

    const onVendorCardClick = useCallback(
        (e, callbackOptions) => {
            EvLogger.log(
                `VendorProgram: User clicked on vendor card for vendorId: ${callbackOptions.vendorId}, programId: ${callbackOptions.programId}`
            );
            navigateToPage({
                path: VENDOR_PROGRAMS_PAGES.PROGRAM_SUMMARY.path,
                state: {
                    clientCode,
                    clientName,
                    vendorCardData: {
                        vendorId: callbackOptions.vendorId,
                        vendorName: callbackOptions.vendorName,
                        programId: callbackOptions.programId,
                        programName: callbackOptions.programName,
                        vendorType: callbackOptions.vendorType,
                    },
                },
            });
        },
        [navigateToPage, clientCode, clientName]
    );

    const onNewVendorProgramClick = useCallback(() => {
        EvLogger.log(`VendorProgram: User clicked on add new vendor program`);
        setDynamicDataApiStatus({
            [VENDOR_PROGRAM_COMPONENTS.VENDORS.id]:
                LOADING_STATUS.NOT_YET_STARTED,
        });
        navigateToPage({
            path: VENDOR_PROGRAMS_PAGES.NEW_VENDOR_PROGRAM.path,
            state: {
                clientCode,
                clientName,
            },
        });
    }, [navigateToPage, setDynamicDataApiStatus, clientCode, clientName]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                VENDOR_PROGRAMS_TEMPLATES.VENDOR_DASHBOARD.id
            ],
            controls.dynamicDataApiStatus[
                VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }
    return (
        <VendorProgramsDashboard
            controls={controls}
            clientCode={clientCode}
            staticData={staticData}
            currentVendors={currentVendors}
            onVendorCardClick={onVendorCardClick}
            onNewVendorProgramClick={onNewVendorProgramClick}
        />
    );
};

VendorProgramsDashboardContainer.propTypes = {
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    currentVendors: PropTypes.array,

    // actions
    setControls: PropTypes.func,
    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    setComponentData: PropTypes.func,
    navigateToPage: PropTypes.func,
};

VendorProgramsDashboardContainer.defaultProps = {
    clientCode: "",
    clientName: "",
    controls: {},
    staticData: {},
    currentVendors: [],

    // actions
    setControls: () => {},
    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
    setComponentData: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    staticData:
        state.VendorProgramsReducer.staticData[
            VENDOR_PROGRAMS_TEMPLATES.VENDOR_DASHBOARD.id
        ],
    currentVendors:
        state.VendorProgramsReducer.dynamicData[
            VENDOR_PROGRAM_COMPONENTS.CURRENT_VENDORS.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(VendorProgramsActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(VendorProgramsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(VendorProgramsActions.setComponentData(payload)),
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VendorProgramsDashboardContainer);
