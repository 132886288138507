import React from "react";
import PropTypes from "prop-types";

import { CONTENT_FIELD_TYPES } from "../../static/Enums";
import {
    EvContentCriteria,
    EvContentDate,
    EvContentLabel,
    EvContentOptions,
    EvContentTime,
    EvContentImage,
    EvContentCardsArray,
    EvContentPillsArray,
    EvContentLazyText,
} from "..";
import EvContentArray from "./EvContentArray";

const EvContentResolver = (props) => {
    const {
        id,
        label,
        type,
        dependency,
        data,
        options,
        displayResolver,
        itemData,
        otherData,
        qParams,
    } = props;

    if (dependency && !data[dependency]) {
        return <span />;
    }

    switch (type) {
        case CONTENT_FIELD_TYPES.LABEL:
            return (
                <EvContentLabel
                    id={id}
                    label={label}
                    dependency={dependency}
                    allValues={data}
                    displayResolver={displayResolver}
                />
            );

        case CONTENT_FIELD_TYPES.LAZY_TEXT:
            return (
                <EvContentLazyText
                    id={id}
                    label={label}
                    dependency={dependency}
                    allValues={data}
                    displayResolver={displayResolver}
                    dataEndpoint={itemData.dataEndpoint}
                    qParams={qParams}
                />
            );

        case CONTENT_FIELD_TYPES.OPTIONS:
            return (
                <EvContentOptions
                    id={id}
                    label={label}
                    dependency={dependency}
                    allValues={data}
                    displayResolver={displayResolver}
                    options={options}
                />
            );

        case CONTENT_FIELD_TYPES.DATE:
            return (
                <EvContentDate
                    id={id}
                    label={label}
                    dependency={dependency}
                    allValues={data}
                    displayResolver={displayResolver}
                />
            );

        case CONTENT_FIELD_TYPES.TIME:
            return (
                <EvContentTime
                    id={id}
                    label={label}
                    dependency={dependency}
                    displayResolver={displayResolver}
                    allValues={data}
                />
            );

        case CONTENT_FIELD_TYPES.CRITERIA_ARRAY:
            return (
                <EvContentCriteria
                    id={id}
                    label={label}
                    dependency={dependency}
                    displayResolver={displayResolver}
                    allValues={data}
                    itemData={itemData}
                    allTags={otherData.allTags}
                />
            );

        case CONTENT_FIELD_TYPES.IMAGE:
            return (
                <EvContentImage
                    id={id}
                    label={label}
                    dependency={dependency}
                    allValues={data}
                    displayResolver={displayResolver}
                />
            );

        case CONTENT_FIELD_TYPES.CARDS_ARRAY:
            return (
                <EvContentCardsArray
                    id={id}
                    label={label}
                    dependency={dependency}
                    displayResolver={displayResolver}
                    allValues={data}
                />
            );

        case CONTENT_FIELD_TYPES.PILLS_ARRAY:
            return (
                <EvContentPillsArray
                    id={id}
                    label={label}
                    dependency={dependency}
                    displayResolver={displayResolver}
                    allValues={data}
                />
            );

        case CONTENT_FIELD_TYPES.ARRAY:
            return (
                <EvContentArray
                    id={id}
                    label={label}
                    dependency={dependency}
                    displayResolver={displayResolver}
                    allValues={data}
                />
            );

        default:
            return <div>Unknown</div>;
    }
};

EvContentResolver.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    dependency: PropTypes.string,
    displayResolver: PropTypes.string,
    data: PropTypes.object,
    options: PropTypes.object,
    itemData: PropTypes.object, // only to use the full item data
    otherData: PropTypes.object,
    qParams: PropTypes.object,
};

EvContentResolver.defaultProps = {
    id: "",
    label: "",
    type: "",
    dependency: "",
    displayResolver: "",
    data: {},
    options: {},
    itemData: {},
    otherData: {},
    qParams: {},
};

export default EvContentResolver;
