import EnterpriseNotificationsActionTypes from "./EnterpriseNotificationsActionTypes";
import { ENTERPRISE_NOTIFICATIONS_PAGES } from "../static/enterpriseNotificationsStatics";

const initialState = {
    controls: {
        selectedPageId: ENTERPRISE_NOTIFICATIONS_PAGES.CLIENT_SELECTION.id,
        notificationStatus: "",
        currentNotificationId: "",
    },
    selectedClientCodes: [],
};

const EnterpriseNotificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EnterpriseNotificationsActionTypes.SET_CLIENT_CODES:
            return {
                ...state,
                selectedClientCodes: action.payload,
            };
        case EnterpriseNotificationsActionTypes.SET_CONTROLS:
            return {
                ...state,
                controls: {
                    ...state.controls,
                    ...action.payload,
                },
            };

        case EnterpriseNotificationsActionTypes.RESET_ALL_DATA:
            return initialState;

        default:
            return state;
    }
};

export default EnterpriseNotificationsReducer;
