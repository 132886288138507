import PropTypes from "prop-types";
import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import { EvButton, EvDropdownMenu, EvIcon, EvTooltip } from ".";
import { isNullOrEmpty } from "../../utils/CommonUtils";
import { CTA_TYPE, ROUTES } from "../static/Enums";
import "../styles/ev-user-bar.scss";

const EvUserBar = (props) => {
    const styles = {
        mainComponent: "ev__ev-user-bar__main-component",
        logoImage: "ev__ev-user-bar__logo-image",
        topBarRightSection: "ev__ev-user-bar__right-section",
        userText: "ev__ev-user-bar__user-text",
        userIconContainer: "ev__ev-user-bar__user-icon-container",
        userIconItem: "ev__ev-user-bar__user-icon-item",
    };

    const {
        userBarData,
        dropdownData,
        navigationReplace,
        userIconData,
        navigateToPage,
    } = props;

    const onClickHandler = (ctaData) => {
        switch (ctaData.type) {
            case CTA_TYPE.HREF:
                window.location = ctaData.action;
                break;

            case CTA_TYPE.INTERNAL:
                navigationReplace({ action: ctaData.action });
                break;

            default:
        }
    };

    const onUserIconClick = useCallback(
        (e, callbackOptions) => {
            navigateToPage({
                path: callbackOptions.route,
                state: callbackOptions.state,
            });
        },
        [navigateToPage]
    );

    const getLogo = (logoUrl) => (
        <Link to={ROUTES.DASHBOARD}>
            <img className={styles.logoImage} src={logoUrl} alt="" />
        </Link>
    );

    const getUserId = (userId) => (
        <div className={styles.userText}>{userId}</div>
    );

    // const getNotificationIcon = (notificationData) => (
    //     <EvNotificationIcon count={notificationData.length} />
    // );

    const getUserDropdown = (userDropdownData) => (
        <EvDropdownMenu
            label={getUserId(userBarData.userId)}
            data={userDropdownData.dropdownData}
            onClickHandler={onClickHandler}
        />
    );

    const getIconView = (iconData) => {
        if (isNullOrEmpty(iconData[1])) {
            return <span key={iconData[0]} />;
        }
        return (
            <EvTooltip
                key={iconData[0]}
                value={iconData[1].tooltip}
                offsetTop={30}
            >
                <EvButton
                    onlyChild
                    onClickHandler={onUserIconClick}
                    callbackValues={iconData[1]}
                >
                    <EvIcon
                        outerContainerStyleClass={styles.userIconItem}
                        iconName={iconData[1].iconName}
                        size={4}
                        fillColor={iconData[1].fillColor}
                    />
                </EvButton>
            </EvTooltip>
        );
    };

    const getUserIcons = () => (
        <div className={styles.userIconContainer}>
            {Object.entries(userIconData).map(getIconView)}
        </div>
    );

    return (
        <div className={styles.mainComponent}>
            {userBarData.logoUrl && getLogo(userBarData.logoUrl)}
            <div className={styles.topBarRightSection}>
                {/* TODO: to be added later */}
                {/*{getNotificationIcon(userBarData.notificationData)}*/}
                {getUserIcons()}
                {/* username moved inside the dropdown button for now */}
                {/*{userBarData.userId && getUserId(userBarData.userId)}*/}
                {getUserDropdown({ dropdownData })}
            </div>
        </div>
    );
};

EvUserBar.propTypes = {
    userBarData: PropTypes.object,
    userIconData: PropTypes.object,
    dropdownData: PropTypes.array,

    navigationReplace: PropTypes.func,
    navigateToPage: PropTypes.func,
};

EvUserBar.defaultProps = {
    userBarData: {},
    userIconData: {},
    dropdownData: [],

    navigationReplace: () => {},
    navigateToPage: () => {},
};

export default memo(EvUserBar);
