import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import stringTemplate from "string-template";

import NotificationsActions from "../redux/NotificationsActions";
import API_URLS from "../../../services/apiUrls";
import {
    NOTIFICATION_TEMPLATES,
    NOTIFICATIONS_PAGES,
} from "../static/NotificationsStatics";

import {
    EvHeaderView,
    EvLoadingPage,
    EvSimpleFileSelector,
    EvSubmitActionView,
    EvText,
    EvToast,
} from "../../../common/components";
import { isCompleted, isNullOrEmpty } from "../../../utils/CommonUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { getData, postData } from "../service/NotificationsService";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { EvFormRenderer } from "../../../common/components/form-components";

import "../styles/notifications-upload-population.scss";

const styles = {
    container: "ev__notifications-upload-population__container",
    formView: "ev__notifications-upload-population__form-view",
    uploadView: {
        container: "ev__notifications-upload-population__upload-view-container",
        label: "ev__notifications-upload-population__upload-view-label",
        buttonContainer:
            "ev__notifications-upload-population__upload-view-button-container",
        button: "ev__notifications-upload-population__upload-view-button",
        currentFileDetails:
            "ev__notifications-upload-population__upload-view-current-file-details",
    },
    actionView: "ev__notifications-upload-population__action-view",
};

const NotificationsUploadPopulation = (props) => {
    const {
        clientCode,
        staticData,
        controls,
        getStaticData,
        setControls,
    } = props;

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [formData, setFormData] = useState({});
    const [fileData, setFileData] = useState({});
    const [fileBase64, setFileBase64] = useState("");

    const notificationId = controls.currentNotificationId;

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.NOTIFICATIONS.UPLOAD_POPULATION.STATIC,
                    templateId:
                        NOTIFICATION_TEMPLATES.UPLOAD_POPULATION_STATIC.id,
                }),
                getData(
                    API_URLS.NOTIFICATIONS.UPLOAD_POPULATION
                        .UPLOADED_POPULATION_DATA,
                    {
                        clientCode,
                        notificationId,
                    }
                ),
            ]),
        [getStaticData, clientCode, notificationId]
    );

    useEffect(() => {
        getAllData()
            .then(([staticDataResponse, populationDataResponse]) => {
                const initialFormData = parseInitialStateFromFormFields(
                    staticDataResponse.populationFormFields,
                    populationDataResponse.data.data.populationData
                );
                setFormData(initialFormData);
                setFileData(populationDataResponse.data.data.fileData);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setLoadingStatus(LOADING_STATUS.FAILED);
            });
    }, [getAllData]);

    const onFileSelect = useCallback(
        (selectedFileData, selectedFileDetails) => {
            setFileData({
                fileName: selectedFileDetails.name,
            });
            setFileBase64(selectedFileData);
        },
        []
    );

    const onSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (isNullOrEmpty(fileData)) {
            EvToast.error("", "Upload CSV file to continue");
            return;
        }
        if (!isFullFormValid(formData)) {
            setFormData(markAllFieldsUsed(formData));
            return;
        }
        const apiData = {
            populationData: parseFormDataForApi(formData),
            fileData: {
                fileName: fileData.fileName,
                base64: fileBase64,
            },
            notificationId,
        };
        setSubmitStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.NOTIFICATIONS.UPLOAD_POPULATION.SUBMIT_POPULATION,
            { clientCode },
            apiData
        )
            .then((submitResponse) => {
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                setControls({
                    selectedPageId: NOTIFICATIONS_PAGES.SCHEDULE.id,
                });
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
            });
    }, [
        submitStatus,
        formData,
        fileBase64,
        fileData,
        setControls,
        clientCode,
        notificationId,
    ]);

    const getHeaderView = () => (
        <EvHeaderView
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getDetailsFormView = () => (
        <div className={styles.formView}>
            <EvFormRenderer
                formData={formData}
                formFields={staticData.populationFormFields}
                setFormDataState={setFormData}
            />
        </div>
    );

    const getUploadView = () => (
        <div className={styles.uploadView.container}>
            <EvText className={styles.uploadView.label}>
                {staticData.uploadFile.label}
            </EvText>
            <div className={styles.uploadView.buttonContainer}>
                <EvSimpleFileSelector
                    buttonText={staticData.uploadFile.buttonText}
                    className={styles.uploadView.button}
                    onFileSelect={onFileSelect}
                />
                {!isNullOrEmpty(fileData) && (
                    <EvText className={styles.uploadView.currentFileDetails}>
                        {stringTemplate(
                            staticData.uploadFile.fileDetailsResolver,
                            fileData
                        )}
                    </EvText>
                )}
            </div>
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionView}
            buttonText={staticData.nextButtonCta.text}
            onSubmitClick={onSubmit}
            loadingStatus={submitStatus}
        />
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                NOTIFICATION_TEMPLATES.UPLOAD_POPULATION_STATIC.id
            ],
            loadingStatus
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getDetailsFormView()}
            {getUploadView()}
            {getActionView()}
        </div>
    );
};

NotificationsUploadPopulation.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    controls: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
};

NotificationsUploadPopulation.defaultProps = {
    clientCode: "",
    staticData: {},
    controls: {},

    getStaticData: () => {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.NotificationsReducer.controls,
    staticData:
        state.NotificationsReducer.staticData[
            NOTIFICATION_TEMPLATES.UPLOAD_POPULATION_STATIC.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsUploadPopulation);
