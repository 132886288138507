import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import className from "classnames";

import { EvText, EvIcon, EvButton } from "../../../common/components";

import { COLORS } from "../../../common/static/VmsStatics";
import { isNullOrEmpty, removeKeyFromObject } from "../../../utils/CommonUtils";
import { setAnimationCallbackDirectRef } from "../../../utils/AnimationHelper";

import "../styles/program-tags-selection-modal.scss";

const ProgramTagsSelectionModal = (props) => {
    const styles = {
        container: "ev__program-tags-selection-modal__container",
        header: {
            container: "ev__program-tags-selection-modal__header-container",
            textContainer:
                "ev__program-tags-selection-modal__header-text-container",
            iconContainer:
                "ev__program-tags-selection-modal__header-icon-container",
        },
        selectedTags: {
            container:
                "ev__program-tags-selection-modal__selected-tags-container",
            tagWrapper:
                "ev__program-tags-selection-modal__selected-tags-tag-wrapper",
            tagWrapperExiting:
                "ev__program-tags-selection-modal__selected-tags-tag-wrapper-exiting",
            tagInnerWrapper:
                "ev__program-tags-selection-modal__selected-tags-tag-inner-wrapper",
            iconButton:
                "ev__program-tags-selection-modal__selected-tags-icon-button",
            icon: "ev__program-tags-selection-modal__selected-tags-icon",
        },
        allTags: {
            container: "ev__program-tags-selection-modal__all-tags-container",
            descriptionText:
                "ev__program-tags-selection-modal__all-tags-description-text",
            tagsContainer:
                "ev__program-tags-selection-modal__all-tags-tags-container",
        },
        tagItem: {
            wrapperButton:
                "ev__program-tags-selection-modal__tag-item-wrapper-button",
            wrapper: "ev__program-tags-selection-modal__tag-item-wrapper",
            wrapperSelected:
                "ev__program-tags-selection-modal__tag-item-wrapper-selected",
            wrapperExpanded:
                "ev__program-tags-selection-modal__tag-item-wrapper-expanded",
            headerContainer:
                "ev__program-tags-selection-modal__tag-item-header-container",
            headerTextWrapper:
                "ev__program-tags-selection-modal__tag-item-header-text-wrapper",
            headerIcon:
                "ev__program-tags-selection-modal__tag-item-header-icon",
            actionButton:
                "ev__program-tags-selection-modal__tag-item-action-button",
            actionButtonExpanded:
                "ev__program-tags-selection-modal__tag-item-action-button-expanded",
            descriptionContainer:
                "ev__program-tags-selection-modal__tag-item-description-container",
            descriptionContainerExpanded:
                "ev__program-tags-selection-modal__tag-item-description-container-expanded",
        },
        modalActions: {
            container:
                "ev__program-tags-selection-modal__modal-actions-container",
            bottomGradient:
                "ev__program-tags-selection-modal__modal-actions-bottom-gradient",
        },
    };

    const {
        formItemKey,
        staticData,
        allTags,
        formData,
        updateTags,
        onCloseClick,
    } = props;

    const [expandedTagId, setExpandedTagId] = useState("");
    const [selectedTags, setSelectedTags] = useState({});

    const generateSelectedTagPillId = useCallback(
        (tagId) => `program-tags-selection-modal__tag-id__${tagId}`,
        []
    );

    const onTagItemClick = useCallback(
        (e, options) => {
            // deselect select tag, already selected
            if (options.tagId === expandedTagId) {
                setExpandedTagId("");
            } else {
                setExpandedTagId(options.tagId);
            }
        },
        [setExpandedTagId, expandedTagId]
    );

    const onTagActionAssignClick = useCallback(
        (e, options) => {
            setSelectedTags((prevSelectedTags) => ({
                ...prevSelectedTags,
                [options.tagId]: {
                    id: options.tagId,
                    name: options.tagData.header,
                    value: options.tagId,
                },
            }));
        },
        [setSelectedTags]
    );

    const onTagRemoveClick = useCallback(
        (e, options) => {
            const tagDomEl = document.getElementById(
                generateSelectedTagPillId(options.tagId)
            );
            // set exit animation first
            setSelectedTags((prevSelectedTags) => ({
                ...prevSelectedTags,
                [options.tagId]: {
                    ...prevSelectedTags[options.tagId],
                    isExiting: true,
                },
            }));
            // remove from state once collapse animation is finished
            setAnimationCallbackDirectRef(tagDomEl, () => {
                setSelectedTags((prevSelectedTags) =>
                    removeKeyFromObject(options.tagId, prevSelectedTags)
                );
            });
        },
        [setSelectedTags, generateSelectedTagPillId]
    );

    const onUpdateClick = () => {
        updateTags(formItemKey, selectedTags);
    };

    useEffect(() => {
        setSelectedTags(formData);
    }, [formData]);

    const getHeader = () => (
        <div className={styles.header.container}>
            <div className={styles.header.textContainer}>
                <EvText headingSecondary>{staticData.header}</EvText>
            </div>
            <EvButton
                onClickHandler={onCloseClick}
                onlyChild
                className={styles.header.iconContainer}
            >
                <EvIcon
                    circular
                    bgColor={COLORS.BLACK_TROUT}
                    fillColor={COLORS.WHITE}
                    iconName="CROSS_THICK"
                    size={3}
                />
            </EvButton>
        </div>
    );

    const getSelectedTagItemPill = (tagId) => {
        const tagData = allTags.value[tagId];
        const customWrapperClass = className(styles.selectedTags.tagWrapper, {
            [styles.selectedTags.tagWrapperExiting]:
                selectedTags[tagId].isExiting,
        });
        return (
            <div
                key={tagId}
                id={generateSelectedTagPillId(tagId)}
                className={customWrapperClass}
            >
                <div className={styles.selectedTags.tagInnerWrapper}>
                    <EvText defaultBold>{tagData.header}</EvText>
                    <EvButton
                        onlyChild
                        className={styles.selectedTags.iconButton}
                        onClickHandler={onTagRemoveClick}
                        callbackValues={{ tagId, tagData }}
                    >
                        <EvIcon
                            size={3}
                            iconName="CROSS_THICK"
                            circular
                            fillColor={COLORS.WHITE}
                            bgColor={COLORS.BLACK_TROUT}
                            outerContainerStyleClass={styles.selectedTags.icon}
                        />
                    </EvButton>
                </div>
            </div>
        );
    };

    const getSelectedTagsView = () => {
        // no tags selected
        if (Object.keys(selectedTags).length <= 0) {
            return <span />;
        }
        return (
            <div className={styles.selectedTags.container}>
                <EvText defaultBold>{staticData.selectedLabel}</EvText>
                {Object.keys(selectedTags).map(getSelectedTagItemPill)}
            </div>
        );
    };

    const getTagItemHeaderTextView = (tagId, tagData, isSelected) => (
        <EvButton
            key={tagId}
            onlyChild
            onClickHandler={onTagItemClick}
            callbackValues={{ tagId }}
            className={styles.tagItem.wrapperButton}
        >
            <div className={styles.tagItem.headerTextWrapper}>
                <EvText defaultDarkBold>{tagData.header}</EvText>
                {isSelected && (
                    <EvIcon
                        iconName="CHECK"
                        size={3}
                        circular
                        bgColor={COLORS.FRUIT_SALAD}
                        fillColor={COLORS.WHITE}
                        outerContainerStyleClass={styles.tagItem.headerIcon}
                    />
                )}
            </div>
        </EvButton>
    );

    const getTagItemActionButtons = (
        tagId,
        tagData,
        isSelected,
        isExpanded
    ) => {
        const customActionButtonClass = className(styles.tagItem.actionButton, {
            [styles.tagItem.actionButtonExpanded]: isExpanded,
        });
        if (isSelected) {
            return (
                <EvButton
                    className={customActionButtonClass}
                    default
                    buttonInlineStyle={{ backgroundColor: COLORS.WHITE_MYSTIC }}
                    onClickHandler={onTagRemoveClick}
                    callbackValues={{ tagId, tagData }}
                >
                    {staticData.removeTag.text}
                </EvButton>
            );
        }
        return (
            <EvButton
                className={customActionButtonClass}
                primaryFilled
                onClickHandler={onTagActionAssignClick}
                callbackValues={{ tagId, tagData }}
            >
                {staticData.selectTag.text}
            </EvButton>
        );
    };

    const getTagItemView = (tagId) => {
        const tagData = allTags.value[tagId];
        const isExpanded = tagId === expandedTagId;
        const isSelected = !isNullOrEmpty(selectedTags[tagId]);
        const customDescriptionClass = className(
            styles.tagItem.descriptionContainer,
            {
                [styles.tagItem.descriptionContainerExpanded]: isExpanded,
            }
        );
        const customWrapperClass = className(styles.tagItem.wrapper, {
            [styles.tagItem.wrapperExpanded]: isExpanded,
            [styles.tagItem.wrapperSelected]: isSelected,
        });
        return (
            <div key={tagId} className={customWrapperClass}>
                <div className={styles.tagItem.headerContainer}>
                    {/* only the header is clickable, since the card contains another clikable element */}
                    {/* we cant make the whole card clickable */}
                    {getTagItemHeaderTextView(tagId, tagData, isSelected)}
                    {getTagItemActionButtons(
                        tagId,
                        tagData,
                        isSelected,
                        isExpanded
                    )}
                </div>
                <div className={customDescriptionClass}>
                    <EvText default>{tagData.description}</EvText>
                </div>
            </div>
        );
    };

    const getTagsListView = () => (
        <div className={styles.allTags.container}>
            <div className={styles.allTags.wrapper}>
                <EvText className={styles.allTags.descriptionText} default>
                    {staticData.description}
                </EvText>
                <div className={styles.allTags.tagsContainer}>
                    {allTags.ids.map(getTagItemView)}
                </div>
            </div>
        </div>
    );

    const getModalActionsView = () => (
        <div className={styles.modalActions.container}>
            <div className={styles.modalActions.bottomGradient} />
            <EvButton primaryFilled onClickHandler={onUpdateClick}>
                {staticData.saveCta.text}
            </EvButton>
        </div>
    );

    return (
        <div className={styles.container}>
            {getHeader()}
            {getSelectedTagsView()}
            {getTagsListView()}
            {getModalActionsView()}
        </div>
    );
};

ProgramTagsSelectionModal.propTypes = {
    formItemKey: PropTypes.string,
    staticData: PropTypes.object,
    allTags: PropTypes.object,
    formData: PropTypes.object,
    updateTags: PropTypes.func,
    onCloseClick: PropTypes.func,
};

ProgramTagsSelectionModal.defaultProps = {
    formItemKey: "",
    staticData: {},
    allTags: {},
    formData: {},
    updateTags: () => {},
    onCloseClick: () => {},
};

export default ProgramTagsSelectionModal;
