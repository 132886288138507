import classNames from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { EvButton, EvIcon, EvText } from ".";
import { ACTION_TYPES, ICON_NAMES } from "../static/Enums";
import { COLORS } from "../static/VmsStatics";
import "../styles/ev-expandable-card.scss";

const EvExpandableCard = (props) => {
    const styles = {
        cardContainer: "ev__ev-expandable-card__card-container",
        cardContainerSelected:
            "ev__ev-expandable-card__card-container-selected",
        cardContainerExpanded:
            "ev__ev-expandable-card__card-container-expanded",
        cardContentContainer: "ev__ev-expandable-card__card-content-container",
        cardHeaderContainer: "ev__ev-expandable-card__card-header-container",
        cardHeader: "ev__ev-expandable-card__card-header",
        cardTitleAndIconContainer:
            "ev__ev-expandable-card__card-title-icon-container",
        cardDetailsWrapper: "ev__ev-expandable-card__card-details-wrapper",
        cardDetailsExpanded: "ev__ev-expandable-card__card-details-expanded",
        cardDetailsContent: "ev__ev-expandable-card__card-details-content",
        selectButton: "ev__ev-expandable-card__select-button",
        cardContentBody: "ev__ev-expandable-card__card-content-body",
        title: "ev__ev-expandable-card__title",
        checkIcon: "ev__ev-expandable-card__check-icon",
        description: "ev__ev-expandable-card__description",
    };

    const {
        id,
        name,
        value,
        description,
        children,
        ctaText,
        ctaType,
        ctaAction,
        isSelected,
        isExpanded,
        onHeaderClick,
    } = props;

    const renderCollapsedView = () => (
        <div className={styles.cardHeader}>
            <EvButton
                className={styles.cardHeaderContainer}
                onlyChild
                onClickHandler={onHeaderClick}
                callbackValues={{
                    id,
                }}
            >
                <div className={styles.cardTitleAndIconContainer}>
                    <div className={styles.title}>
                        <EvText defaultDarkBold={isExpanded}>{name}</EvText>
                    </div>
                    {isSelected && (
                        <EvIcon
                            iconName={ICON_NAMES.CHECK}
                            bgColor={COLORS.FRUIT_SALAD}
                            fillColor={COLORS.WHITE}
                            circular
                            size={3}
                            outerContainerStyleClass={styles.checkIcon}
                        />
                    )}
                </div>
            </EvButton>
            {ctaText && isExpanded && (
                <EvButton
                    primaryFilled={ctaType === ACTION_TYPES.POSITIVE}
                    negativeAction={ctaType === ACTION_TYPES.NEGATIVE}
                    onClickHandler={ctaAction}
                    callbackValues={{
                        id,
                        name,
                        value,
                    }}
                >
                    {ctaText}
                </EvButton>
            )}
        </div>
    );

    const renderExpandedView = () => {
        const customExpandedViewClass = classNames(styles.cardDetailsWrapper, {
            [styles.cardDetailsExpanded]: isExpanded,
        });
        if (children) {
            return children;
        }
        return (
            <div className={customExpandedViewClass}>
                <div className={styles.cardDetailsContent}>
                    <div className={styles.cardContentBody}>
                        <div className={styles.description}>
                            <EvText default>{description}</EvText>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderCard = () => {
        const customContainerClass = classNames(styles.cardContainer, {
            [styles.cardContainerExpanded]: isExpanded,
            [styles.cardContainerSelected]: isSelected,
        });
        return (
            <div className={customContainerClass}>
                <div className={styles.cardContentContainer}>
                    {renderCollapsedView()}
                    {isExpanded && renderExpandedView()}
                </div>
            </div>
        );
    };

    return <div>{renderCard()}</div>;
};

EvExpandableCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    ctaText: PropTypes.string,
    ctaType: PropTypes.string,
    isExpanded: PropTypes.bool,

    isSelected: PropTypes.bool,

    ctaAction: PropTypes.func,
    onHeaderClick: PropTypes.func,
};

EvExpandableCard.defaultProps = {
    id: "",
    name: "",
    value: "",
    description: "",
    children: null,
    ctaText: "",
    ctaType: ACTION_TYPES.POSITIVE,
    isExpanded: false,

    isSelected: false,

    ctaAction: () => {},
    onHeaderClick: () => {},
};

export default memo(EvExpandableCard);
