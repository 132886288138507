const SavedPopulationActionTypes = {
    SET_CONTROLS: "SAVED_POPULATION/SET_CONTROLS",
    SET_TEMPLATE_DATA: "SAVED_POPULATION/SET_TEMPLATE_DATA",
    SET_COMPONENT_DATA: "SAVED_POPULATION/SET_COMPONENT_DATA",
    SET_STATIC_DATA_API_STATUS: "SAVED_POPULATION/SET_STATIC_DATA_API_STATUS",
    SET_DYNAMIC_DATA_API_STATUS: "SAVED_POPULATION/SET_DYNAMIC_DATA_API_STATUS",
    RESET_DATA: "SAVED_POPULATION/RESET_DATA",
};

export default SavedPopulationActionTypes;
