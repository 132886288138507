import dateTime from "date-and-time";
import PropTypes from "prop-types";
import React from "react";
import stringTemplate from "string-template";
import { SERVER_FORMATS, UI_FORMATS } from "../../static/Enums";
import "../../styles/content-items/ev-content-date.scss";
import { EvText } from "../index";

const styles = {
    container: "ev__ev-content-date__container",
};

const EvContentTime = (props) => {
    const { label, timeFormat, displayResolver, allValues, dependency } = props;
    const time = dateTime.parse(allValues[dependency], SERVER_FORMATS.TIME);
    const displayTime = dateTime.format(time, timeFormat);
    const displayText = stringTemplate(displayResolver, {
        ...allValues,
        [dependency]: displayTime,
    });
    return (
        <div className={styles.container}>
            <EvText
                defaultDark
                dangerous
            >{`${label} <b>${displayText}</b>`}</EvText>
        </div>
    );
};

EvContentTime.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    dependency: PropTypes.string,
    timeFormat: PropTypes.string,
    displayResolver: PropTypes.string,
    allValues: PropTypes.object,
};

EvContentTime.defaultProps = {
    id: "",
    label: "",
    dependency: "",
    timeFormat: UI_FORMATS.FULL_TIME_MERIDIEM,
    displayResolver: "",
    allValues: {},
};

export default EvContentTime;
