import React, { memo, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { EvButton, EvIcon, EvImageCropper, EvLoader, EvToast } from "..";
import { EvInputErrorView } from ".";

import { postFileUpload } from "../../service/EvImageUploaderService";
import { LOADING_STATUS, FORM_FIELD_TYPES } from "../../static/Enums";
import { COLORS } from "../../static/VmsStatics";
import FormValidation from "../../../utils/FormValidations";
import EvLogger from "../../../utils/EvLogger";
import API_URLS from "../../../services/apiUrls";

import "../../styles/form-components/ev-base-file-uploader.scss";

/**
 * This component will be used to upload images
 */

const EvBaseFileUploader = (props) => {
    const styles = {
        container: "ev__ev-base-file-uploader__container",
        innerContainer: "ev__ev-base-file-uploader__inner-container",
        button: "ev__ev-base-file-uploader__button",
        input: "ev__ev-base-file-uploader__input",
        iconContainer: "ev__ev-base-file-uploader__icon-container",
        errorContainer: "ev__ev-base-file-uploader__error-container",
        preview: {
            container: "ev__ev-base-file-uploader__preview-container",
            linkContainer: "ev__ev-base-file-uploader__preview-link-container",
            image: "ev__ev-base-file-uploader__preview-image",
        },
        secondaryActions: {
            container: "ev__ev-base-file-uploader__secondary-action-container",
            removeButtonText:
                "ev__ev-base-file-uploader__secondary-action-remove-button-text",
        },
    };
    const [isUploading, setIsUploading] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const {
        handleInputChange,
        placeholder,
        // apiData,
        keyName,
        type,
        showError,
        formData,
        formItem,
    } = props;

    const fileUploadRef = React.createRef(null);

    const [selectedImageData, setSelectedImageData] = useState();
    const [isEditorVisible, setIsEditorVisible] = useState(false);

    // if network fails, not BE error
    const onFileUploadError = useCallback((e) => {
        setIsUploading(LOADING_STATUS.FAILED);
        EvLogger.errorWithObject(e, "EvBaseFileUploader onUpload");
    }, []);

    const updateUrlInFormData = useCallback(
        (url) => {
            const inputFieldObject = {
                [keyName]: {
                    key: keyName,
                    value: url,
                    // type: props.type,
                },
                errorObject: {},
                formItemType: type,
            };
            // handleInputChange(keyName, inputFieldObject);
            handleInputChange(keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [handleInputChange, formItem, keyName, type, formData]
    );

    const onFileUploadSuccess = useCallback(
        (response) => {
            // if (response.data.responseStatus === RESPONSE_STATUS.SUCCESS) {
            setIsUploading(LOADING_STATUS.COMPLETED);
            updateUrlInFormData(response.data.data.url);
        },
        [updateUrlInFormData]
    );

    const onUploadButtonClick = useCallback(() => {
        // already loading some image
        if (isUploading === LOADING_STATUS.LOADING) {
            return;
        }
        if (fileUploadRef.current) {
            setIsEditorVisible(false);
            fileUploadRef.current.value = "";
            fileUploadRef.current.click();
        }
    }, [isUploading, fileUploadRef]);

    const uploadFile = useCallback(
        (fileBase64) => {
            const endpointUrl =
                formItem.options && formItem.options.uploadEndpoint
                    ? formItem.options.uploadEndpoint
                    : API_URLS.IMAGE_UPLOADER.UPLOAD_IMAGE;

            setIsUploading(LOADING_STATUS.LOADING);

            const apiData = {
                fileBase64,
                templateType: formItem.id,
                folderName: formItem?.options?.folderName ?? "",
                ...(formItem.options && formItem.options.apiData
                    ? formItem.options.apiData
                    : {}),
            };
            postFileUpload(endpointUrl, {}, apiData)
                .then(onFileUploadSuccess)
                .catch(onFileUploadError);
        },
        [formItem, onFileUploadSuccess, onFileUploadError]
    );

    const handleFileChange = useCallback(
        (e) => {
            e.preventDefault();
            if (!e.target.files[0]) {
                return; // if no file is selected
            }
            const reader = new FileReader();
            const fileName = e.target.files[0];

            reader.onloadend = () => {
                if (formItem.options && formItem.options.allowCrop) {
                    const selectedImageTemp = new Image();
                    selectedImageTemp.src = reader.result;
                    selectedImageTemp.onload = () => {
                        if (
                            selectedImageTemp.width < formItem.options.width ||
                            selectedImageTemp.height < formItem.options.height
                        ) {
                            EvToast.error(
                                "Oops!",
                                `Selected image must be at least ${formItem.options.width}px x ${formItem.options.height}px`
                            );
                            return;
                        }
                        setSelectedImageData(reader.result);
                        setIsEditorVisible(true);
                    };
                } else {
                    uploadFile(reader.result);
                }
            };

            reader.readAsDataURL(fileName);
        },
        [formItem, uploadFile]
    );

    const onImageCropped = useCallback(
        (croppedImageBase64Data) => {
            uploadFile(croppedImageBase64Data);
        },
        [uploadFile]
    );

    const onPreviewLinkClick = useCallback((e, callbackValues) => {
        const win = window.open(callbackValues.url, "_blank");
        win.focus();
    }, []);

    const onRemoveImageClick = useCallback(() => {
        updateUrlInFormData(formItem.defaultValue ? formItem.defaultValue : "");
        setIsUploading(LOADING_STATUS.NOT_YET_STARTED);
    }, [updateUrlInFormData, formItem]);

    const closeImageEditor = useCallback(() => {
        // setIsUploading(LOADING_STATUS.CANCELLED);
        setIsEditorVisible(false);
    }, []);

    const getIcon = () => {
        switch (isUploading) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        size={3}
                        iconName="CHECK"
                        fillColor={COLORS.PRODUCT}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        size={3}
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                    />
                );

            default:
                return <span />;
        }
    };

    const getRemoveButton = () => {
        const fileUrl = formData[keyName].value;
        const isDefault = formData[keyName].value === formItem.defaultValue;
        if (formItem.type === FORM_FIELD_TYPES.IMAGE_UPLOAD) {
            if (
                formItem.options &&
                formItem.options.clearText &&
                fileUrl &&
                !isDefault
            ) {
                return (
                    <EvButton
                        iconButton
                        iconName="DELETE"
                        onClickHandler={onRemoveImageClick}
                    >
                        {formItem.options.clearText}
                    </EvButton>
                );
            }
        }
        return null;
    };

    const getSecondaryActionView = () => (
        <div className={styles.secondaryActions.container}>
            {getRemoveButton()}
        </div>
    );

    const getPreviewView = () => {
        const fileUrl = formData[keyName].value;
        if (!fileUrl) {
            return <span />;
        }
        if (formItem.type === FORM_FIELD_TYPES.IMAGE_UPLOAD) {
            return (
                <div className={styles.preview.container}>
                    <img
                        alt={`Preview ${keyName}`}
                        src={fileUrl}
                        className={styles.preview.image}
                    />
                </div>
            );
        }
        // for BASE_FILE_UPLOAD
        return formItem.options.previewCta &&
            formItem.options.previewCta.text ? (
            <div className={styles.preview.linkContainer}>
                <EvButton
                    onClickHandler={onPreviewLinkClick}
                    linkButton
                    callbackValues={{ url: fileUrl }}
                >
                    {formItem.options.previewCta.text}
                </EvButton>
            </div>
        ) : (
            <span />
        );
    };

    const getImageEditorView = () => (
        <EvImageCropper
            isEditorVisible={isEditorVisible}
            selectedImageData={selectedImageData}
            onCancelClick={closeImageEditor}
            cropWidth={formItem.options ? formItem.options.width || null : null}
            cropHeight={
                formItem.options ? formItem.options.height || null : null
            }
            onImageCropped={onImageCropped}
        />
    );

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <input
                    ref={fileUploadRef}
                    className={styles.input}
                    type="file"
                    onChange={handleFileChange}
                />
                <EvButton onClickHandler={onUploadButtonClick} primary>
                    {placeholder}
                </EvButton>
                <div className={styles.iconContainer}>{getIcon()}</div>
            </div>
            {formItem.options &&
                formItem.options.showPreview &&
                getPreviewView()}
            {getSecondaryActionView()}
            {getImageEditorView()}
            {showError && getErrorView()}
        </div>
    );
};

EvBaseFileUploader.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    keyName: PropTypes.string,
    apiData: PropTypes.object,
    formData: PropTypes.object,
    formItem: PropTypes.object,
    showError: PropTypes.bool,
    handleInputChange: PropTypes.func,
};

EvBaseFileUploader.defaultProps = {
    label: "",
    placeholder: "",
    type: "",
    keyName: "",
    apiData: {},
    formData: {},
    formItem: {},
    showError: true,
    handleInputChange: () => {},
};

export default memo(EvBaseFileUploader);
