import React from "react";
import PropTypes from "prop-types";
import classNameGen from "classnames";

import { EvText } from "./index";

import "../styles/ev-text-divider.scss";

const styles = {
    container: "ev__ev-text-divider__container",
    line: "ev__ev-text-divider__line",
    bubble: "ev__ev-text-divider__bubble",
};

const EvTextDivider = (props) => {
    const { children, className } = props;

    const getBubbleView = () => (
        <div className={styles.bubble}>
            <EvText defaultBold>{children}</EvText>
        </div>
    );

    return (
        <div className={classNameGen(styles.container, className)}>
            <div className={styles.line} />
            {children && getBubbleView()}
            <div className={styles.line} />
        </div>
    );
};

EvTextDivider.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

EvTextDivider.defaultProps = {
    children: null,
    className: "",
};

export default EvTextDivider;
