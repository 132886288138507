import { createSelector } from "reselect";
import StringTemplate from "string-template";

// const clientListDataStateSelector = state =>
//   state.DashboardReducer.clientListData;
//
// const clientListStaticDataStateSelector = state =>
//   state.DashboardReducer.clientListStaticData;

const userStaticDataStateSelector = (state) =>
    state.AppContainerReducer.staticData;

const userDataStateSelector = (state) => state.AppContainerReducer.userData;

// const getClientListStaticDataSelector = createSelector(
//   clientListStaticDataStateSelector,
//   clientListStaticData => ({
//     // static
//     addClientCta: clientListStaticData.addClientCta,
//     clientListHeader: clientListStaticData.header,
//     // userCountIcon: clientListStaticData.userCountIcon,
//     seeMoreCta: clientListStaticData.seeMoreCta,
//     seeLessCta: clientListStaticData.seeLessCta,
//     noClientAvailableErrorText: clientListStaticData.noClientAvailableErrorText,
//   })
// );

// const getClientListDataSelector = createSelector(
//   clientListStaticDataStateSelector,
//   clientListDataStateSelector,
//   (clientListStaticData, clientListData) => ({
//     // dynamic
//     addClientAllowed: clientListData.addClientAllowed,
//     clientList: clientListData.clientList.map(client => ({
//       ...client,
//       id: client.clientCode,
//       // status: clientListStaticData.statusValues[client.clientStatus],
//       // userCountIcon: {
//       //   ...clientListStaticData.userCountIcon,
//       //   text: StringTemplate(clientListStaticData.userCountIcon.text, {
//       //     userCount: client.userCount,
//       //   }),
//       // },
//       updatedText: StringTemplate(clientListStaticData.updatedText, {
//         lastUpdated: dateFormatter(client.lastUpdated),
//       }),
//       backgroundColor: client.background || COLORS.PRODUCT,
//     })),
//   })
// );

const getUserDataSelector = createSelector(
    userStaticDataStateSelector,
    userDataStateSelector,
    (userStaticData, userData) => ({
        welcomeHeader: StringTemplate(userStaticData.welcomeHeader, {
            userName: userData.userName,
        }),
    })
);

export {
    getUserDataSelector,
    // getClientListStaticDataSelector,
};
