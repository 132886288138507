import React, { useEffect, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import CommonActions from "../../../common/redux/CommonActions";
import API_URLS from "../../../services/apiUrls";
import {
    CURRENT_STATUS_APP_ID,
    CURRENT_STATUS_STATICS,
    CURRENT_STATUS_TEMPLATES,
} from "../statics/CurrentStatusStatics";
import { getData, postData } from "../../../common/service/CommonService";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import CurrentStatusView from "./CurrentStatusView";
import { isCompleted, isCompletedOrLoading } from "../../../utils/CommonUtils";
import { LOADING_STATUS, VENDOR_CARD_TYPE } from "../../../common/static/Enums";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import EvLogger from "../../../utils/EvLogger";

const CurrentStatusContainer = (props) => {
    const {
        controls,
        staticData,
        staticDataClientProgram,
        getStaticData,
        location,
        goBackAction,
    } = props;

    const [infoFormData, setInfoFormData] = useState({});
    const [introductionCardFormData, setIntroductionCardFormData] = useState(
        {}
    );
    const [checklistCardFormData, setChecklistCardFormData] = useState({});
    const [questionCardFormData, setQuestionCardFormData] = useState({});
    const [vendorCardFormData, setVendorCardFormData] = useState({});

    const [formDataLoadingStatus, setFormDataLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );

    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const getAllData = useCallback(() => {
        return Promise.all([
            getStaticData({
                url: API_URLS.CURRENT_STATUS.STATIC,
                templateId: CURRENT_STATUS_TEMPLATES.STATICS.id,
            }),
            getStaticData({
                url: API_URLS.CURRENT_STATUS.STATIC_CLIENT_PROGRAM,
                templateId: CURRENT_STATUS_TEMPLATES.STATIC_CLIENT_PROGRAM.id,
            }),
            getData(API_URLS.CURRENT_STATUS.PREFILL_DATA, {
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
                clientCode,
            }),
        ]);
    }, [getStaticData, vendorCardData, clientCode]);

    useEffect(() => {
        if (isCompletedOrLoading(formDataLoadingStatus)) {
            return;
        }
        setFormDataLoadingStatus(LOADING_STATUS.LOADING);
        getAllData()
            .then(
                ([
                    staticDataResponse,
                    staticDataClientProgramResponse,
                    prefillDataResponse,
                ]) => {
                    const currentStaticData =
                        vendorCardData.vendorType ===
                        VENDOR_CARD_TYPE.CLIENT_VENDOR
                            ? staticDataClientProgramResponse
                            : staticDataResponse;

                    const initialInfoFormData = parseInitialStateFromFormFields(
                        currentStaticData.infoForm.formItems,
                        prefillDataResponse.data.data
                    );

                    const initialIntroductionCardFormData = parseInitialStateFromFormFields(
                        currentStaticData.introductionCardFormItems.formItems,
                        prefillDataResponse.data.data
                    );

                    const initialChecklistCardFormData = parseInitialStateFromFormFields(
                        currentStaticData.checklistCardFormItems.formItems,
                        prefillDataResponse.data.data
                    );

                    const initialQuestionCardFormData = parseInitialStateFromFormFields(
                        currentStaticData.questionCardFormItems.formItems,
                        prefillDataResponse.data.data
                    );

                    const initialVendorCardFormData = parseInitialStateFromFormFields(
                        currentStaticData.vendorCardFormItems.formItems,
                        prefillDataResponse.data.data
                    );

                    setInfoFormData(initialInfoFormData);
                    setIntroductionCardFormData(
                        initialIntroductionCardFormData
                    );
                    setChecklistCardFormData(initialChecklistCardFormData);
                    setQuestionCardFormData(initialQuestionCardFormData);
                    setVendorCardFormData(initialVendorCardFormData);

                    setFormDataLoadingStatus(LOADING_STATUS.COMPLETED);
                }
            )
            .catch((e) => {
                setFormDataLoadingStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "CurrentStatusContainer getData");
            });
    }, [getAllData, formDataLoadingStatus, vendorCardData.vendorType]);

    const onFormSubmit = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        if (
            !(
                isFullFormValid(infoFormData) &&
                isFullFormValid(introductionCardFormData) &&
                isFullFormValid(checklistCardFormData) &&
                isFullFormValid(questionCardFormData) &&
                isFullFormValid(vendorCardFormData)
            )
        ) {
            setInfoFormData(markAllFieldsUsed(infoFormData));
            setIntroductionCardFormData(
                markAllFieldsUsed(introductionCardFormData)
            );
            setChecklistCardFormData(markAllFieldsUsed(checklistCardFormData));
            setQuestionCardFormData(markAllFieldsUsed(questionCardFormData));
            setVendorCardFormData(markAllFieldsUsed(vendorCardFormData));
            EvToast.error("", CURRENT_STATUS_STATICS.FORM_INVALID);
            return;
        }
        const apiData = {
            ...parseFormDataForApi(infoFormData),
            ...parseFormDataForApi(introductionCardFormData),
            ...parseFormDataForApi(checklistCardFormData),
            ...parseFormDataForApi(questionCardFormData),
            ...parseFormDataForApi(vendorCardFormData),
        };
        postData(
            API_URLS.CURRENT_STATUS.SUBMIT,
            {
                clientCode,
            },
            {
                ...apiData,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.cardId,
            }
        )
            .then((submitResponse) => {
                EvToast.success("Updated", "Current Status Card updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                goBackAction();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "CurrentStatusContainer onFormSubmit"
                );
            });
    }, [
        clientCode,
        goBackAction,
        vendorCardData,
        submitStatus,
        infoFormData,
        introductionCardFormData,
        checklistCardFormData,
        questionCardFormData,
        vendorCardFormData,
    ]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[CURRENT_STATUS_TEMPLATES.STATICS.id],
            formDataLoadingStatus
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <CurrentStatusView
            clientCode={clientCode}
            staticData={
                vendorCardData.vendorType === VENDOR_CARD_TYPE.CLIENT_VENDOR
                    ? staticDataClientProgram
                    : staticData
            }
            infoFormData={infoFormData}
            introductionCardFormData={introductionCardFormData}
            checklistCardFormData={checklistCardFormData}
            questionCardFormData={questionCardFormData}
            vendorCardFormData={vendorCardFormData}
            setInfoFormData={setInfoFormData}
            setIntroductionCardFormData={setIntroductionCardFormData}
            setChecklistCardFormData={setChecklistCardFormData}
            setQuestionCardFormData={setQuestionCardFormData}
            setVendorCardFormData={setVendorCardFormData}
            submitStatus={submitStatus}
            onFormSubmit={onFormSubmit}
            vendorCardData={vendorCardData}
        />
    );
};

CurrentStatusContainer.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    staticDataClientProgram: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    goBackAction: PropTypes.func,
};

CurrentStatusContainer.defaultProps = {
    controls: {},
    staticData: {},
    staticDataClientProgram: {},
    location: {},

    getStaticData: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.CommonReducer[CURRENT_STATUS_APP_ID].controls,
    staticData:
        state.CommonReducer[CURRENT_STATUS_APP_ID].staticData[
            CURRENT_STATUS_TEMPLATES.STATICS.id
        ],
    staticDataClientProgram:
        state.CommonReducer[CURRENT_STATUS_APP_ID].staticData[
            CURRENT_STATUS_TEMPLATES.STATIC_CLIENT_PROGRAM.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: CURRENT_STATUS_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentStatusContainer);
