const COMPONENTS_CATEGORIES = {
    COMMON: {
        name: "Common",
        id: "componentCategory_common",
        path: "/cc-open/library/common",
    },
    CARDS: {
        name: "Cards",
        id: "componentCategory_cards",
        path: "/cc-open/library/cards",
    },
    FORM_ITEMS: {
        name: "Forms",
        id: "componentCategory_forms",
        path: "/cc-open/library/forms",
    },
    BUTTONS: {
        name: "Buttons",
        id: "componentCategory_buttons",
        path: "/cc-open/library/buttons",
    },
    TYPOGRAPHY: {
        name: "Typography",
        id: "componentCategory_typography",
        path: "/cc-open/library/typography",
    },
    ICONS: {
        name: "Icons",
        id: "componentCategory_icons",
        path: "/cc-open/library/icons",
    },
    COLORS: {
        name: "Colors",
        id: "componentCategory_colors",
        path: "/cc-open/library/colors",
    },
};

const dummy = null;

export { COMPONENTS_CATEGORIES, dummy };
