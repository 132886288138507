import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";

import {
    guidGenerator,
    isNullOrEmpty,
    removeKeyFromObject,
} from "../../../utils/CommonUtils";
import FormValidation from "../../../utils/FormValidations";
import { EvButton, EvIcon, EvText, EvToast } from "../index";
import { COLORS } from "../../static/VmsStatics";
import { reservedKeywordCheck } from "../../../utils/FormUtils";
import { KEY_CODES } from "../../static/Enums";

import "../../styles/form-components/ev-text-list.scss";

const styles = {
    container: "ev__ev-text-list__container",
    inputContainer: "ev__ev-text-list__input-container",
    inputBox: "ev__ev-text-list__input-box",
    inputBoxDisabled: "ev__ev-text-list__input-box-disabled",
    addIconContainer: "ev__ev-text-list__add-icon-container",
    items: {
        container: "ev__ev-text-list__items-container",
        noItemsText: "ev__ev-text-list__items-no-item-text",
        removeIcon: "ev__ev-text-list__items-remove-icon",
        animationWrapper: "ev__ev-text-list__items-animation-wrapper",
    },
};

const EvTextList = (props) => {
    const {
        keyName,
        formItem,
        formData,
        inlineStyles,
        placeholder,
        handleInputChange,
        editable,
    } = props;

    const addButtonRef = useRef(null);

    const [inputValue, setInputValue] = useState("");

    const onAddItemClick = useCallback(
        (e, cb, addByBlur = false) => {
            if (isNullOrEmpty(inputValue)) {
                // if coming from blur, then dont show toast, there are some cases blur triggers this
                if (!addByBlur) {
                    EvToast.warn("", "Enter value to add them in the list");
                }
                return;
            }
            const guid = guidGenerator();
            const newFormData = {
                ...formData,
                [guid]: {
                    key: guid,
                    value: inputValue,
                    type: formItem.type,
                },
                errorObject: {}, // will be overwritten later
                formItemType: formItem.type,
            };
            handleInputChange(keyName, {
                ...newFormData,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, newFormData),
                },
            });
            setInputValue("");
        },
        [formData, formItem, handleInputChange, inputValue, keyName]
    );

    const onWindowMouseClick = useCallback(
        (e) => {
            if (
                addButtonRef.current &&
                !addButtonRef.current.contains(e.target)
            ) {
                onAddItemClick(null, null, true);
            }
        },
        [onAddItemClick]
    );

    useEffect(() => {
        document.addEventListener("mousedown", onWindowMouseClick);
        return () => {
            document.removeEventListener("mousedown", onWindowMouseClick);
        };
    }, [onWindowMouseClick]);

    // const onBlur = useCallback(() => {
    // if (isNullOrEmpty(inputValue)) {
    //   return;
    // }
    // onAddItemClick();
    // }, []);

    const onKeyDown = useCallback(
        (e) => {
            switch (e.keyCode) {
                case KEY_CODES.ENTER:
                    onAddItemClick();
                    break;

                case KEY_CODES.ESC:
                    setInputValue("");
                    break;

                default:
                    break;
            }
        },
        [onAddItemClick]
    );

    const onInputChange = useCallback((e) => {
        setInputValue(e.target.value);
    }, []);

    const onRemoveItemClick = useCallback(
        (e, callbackOptions) => {
            const { guid } = callbackOptions;
            if (guid) {
                const newFormData = removeKeyFromObject(guid, formData);
                handleInputChange(keyName, {
                    ...newFormData,
                    errorObject: {
                        ...formData.errorObject,
                        ...FormValidation(formItem, newFormData),
                    },
                });
            }
        },
        [formData, handleInputChange, keyName, formItem]
    );

    const customInputClassName = classNames(styles.inputBox, {
        [styles.inputBoxDisabled]: !editable,
    });

    const getInputField = () => (
        <input
            key={props.keyName}
            className={customInputClassName}
            style={inlineStyles}
            placeholder={placeholder}
            onChange={onInputChange}
            onKeyDown={onKeyDown}
            // onBlur={onBlur}
            value={inputValue}
            disabled={!editable}
        />
    );

    const getAddIcon = () => (
        <EvButton onlyChild onClickHandler={onAddItemClick}>
            <EvIcon
                iconName="PLUS"
                size={4}
                fillColor={COLORS.PRODUCT}
                outerContainerStyleClass={styles.addIconContainer}
            />
        </EvButton>
    );

    const getItemPill = ([itemKey, itemData]) => {
        if (reservedKeywordCheck(itemKey)) {
            return null;
        }
        return (
            <CSSTransition
                key={itemKey}
                timeout={500}
                className={styles.items.animationWrapper}
                classNames={styles.items.animationWrapper}
            >
                <div>
                    <EvText>{itemData.value}</EvText>
                    {editable && (
                        <EvButton
                            onlyChild
                            onClickHandler={onRemoveItemClick}
                            callbackValues={{ guid: itemKey }}
                        >
                            <EvIcon
                                iconName="CROSS_THICK"
                                size={2}
                                fillColor={COLORS.BLACK_TROUT}
                                outerContainerStyleClass={
                                    styles.items.removeIcon
                                }
                            />
                        </EvButton>
                    )}
                </div>
            </CSSTransition>
        );
    };

    const getItemList = () => (
        <TransitionGroup className={styles.items.container}>
            {Object.entries(formData).map(getItemPill)}
        </TransitionGroup>
    );

    const getNoItemView = () => {
        if (!(formItem.options && formItem.options.noItemAddedInfo)) {
            return null;
        } else if (Object.keys(formData).length > 2) {
            // 2 is for the formKeys: errors and type
            return null;
        }
        return (
            <EvText smallNormal italics>
                {formItem.options.noItemAddedInfo}
            </EvText>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer} ref={addButtonRef}>
                {getInputField()}
                {editable && getAddIcon()}
            </div>
            {getItemList()}
            {getNoItemView()}
        </div>
    );
};

EvTextList.propTypes = {
    keyName: PropTypes.string,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    inlineStyles: PropTypes.object,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func,
    editable: PropTypes.bool,
    showError: PropTypes.bool,
};

EvTextList.defaultProps = {
    keyName: "",
    formItem: {},
    formData: {},
    placeholder: "",
    inlineStyles: {},
    handleInputChange: () => {},
    editable: true,
    showError: true,
};

export default EvTextList;
