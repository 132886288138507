import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvFormRenderer } from "./index";
import { EvButton, EvText, EvToast } from "../index";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parsePreviewVariablesFromFormData,
} from "../../../utils/FormUtils";
import EvPreviewCardResolver from "../ev-cards/EvPreviewCardResolver";
import { TOAST_ICON_OBJECTS } from "../EvToast";

import "../../styles/form-components/ev-preview-form-renderer.scss";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { FORM_VISIBILITY_MODE } from "../../static/Enums";

const styles = {
    container: "ev__ev-preview-form-renderer__container",
    header: {
        container: "ev__ev-preview-form-renderer__header-container",
        descriptionContainer:
            "ev__ev-preview-form-renderer__header-description-container",
        text: "ev__ev-preview-form-renderer__header-text",
    },
    contentContainer: "ev__ev-preview-form-renderer__content-container",
    formContainer: "ev__ev-preview-form-renderer__form-container",
};

const DISPLAY_MODE = {
    FORM: "FORM",
    PREVIEW: "PREVIEW",
};

const EvPreviewFormRenderer = (props) => {
    const {
        header,
        description,
        className,
        previewCta,
        editCta,
        formFields,
        formData,
        initialFormData,
        cardPreview,
        callbackValues,
        editable,
        qParams,
        options,
        dataOptions,

        setFormDataState,
        onFormDataChange,
    } = props;

    const [displayMode, setDisplayMode] = useState(
        cardPreview.defaultView || DISPLAY_MODE.FORM
    );

    const onViewToggleClick = useCallback(
        (e, callbackOptions) => {
            if (callbackOptions.displayMode === DISPLAY_MODE.PREVIEW) {
                setDisplayMode(DISPLAY_MODE.FORM);
            } else {
                if (!isFullFormValid(formData)) {
                    setFormDataState(markAllFieldsUsed(formData));
                    EvToast.warn(
                        "Sorry",
                        "Validate form items to see preview",
                        {
                            icon: TOAST_ICON_OBJECTS.ALERT,
                        }
                    );
                    return;
                }
                setDisplayMode(DISPLAY_MODE.PREVIEW);
            }
        },
        [setFormDataState, formData]
    );

    const isVisible = useMemo(() => {
        if (
            !isNullOrEmpty(options) &&
            options.visibilityMode === FORM_VISIBILITY_MODE.DEPENDENCY_KEY
        ) {
            if (
                options.visibilityDependencyKey &&
                formData[options.visibilityDependencyKey] &&
                formData[options.visibilityDependencyKey][
                    options.visibilityDependencyKey
                ].value === true
            ) {
                return false;
            }
        }
        return true;
    }, [options, formData]);

    const getPreviewView = () => {
        const previewVariables = parsePreviewVariablesFromFormData(
            formData,
            cardPreview.contentKeys,
            cardPreview.staticContent
        );
        return (
            <EvPreviewCardResolver
                previewType={cardPreview.previewType}
                previewVariables={previewVariables}
                staticContent={cardPreview.staticContent}
            />
        );
    };

    const getFormView = () => (
        <div className={styles.formContainer}>
            <EvFormRenderer
                formFields={formFields}
                formData={formData}
                setFormDataState={setFormDataState}
                onFormDataChange={onFormDataChange}
                callbackValues={callbackValues}
                initialFormData={initialFormData}
                qParams={qParams}
                dataOptions={dataOptions}
            />
        </div>
    );

    const getContent = () => {
        // if editable is false, then there is no edit view
        if (!editable) {
            return getPreviewView();
        }
        switch (displayMode) {
            case DISPLAY_MODE.FORM:
                return getFormView();

            case DISPLAY_MODE.PREVIEW:
                return getPreviewView();

            default:
                return <span />;
        }
    };

    const getToggleButton = () => (
        <EvButton
            iconButton
            iconName={
                displayMode === DISPLAY_MODE.PREVIEW
                    ? editCta.iconName
                    : previewCta.iconName
            }
            callbackValues={{
                displayMode,
            }}
            onClickHandler={onViewToggleClick}
        >
            {displayMode === DISPLAY_MODE.PREVIEW
                ? editCta.text
                : previewCta.text}
        </EvButton>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText className={styles.header.text} defaultDarkBold>
                {header}
            </EvText>
            {/* defaults to true, this takes care of undefined */}
            {!(editable === false) &&
                !isNullOrEmpty(previewCta) &&
                !isNullOrEmpty(editCta) &&
                isVisible &&
                getToggleButton()}
        </div>
    );

    const getDescriptionView = () => (
        <div className={styles.header.descriptionContainer}>
            <EvText>{description}</EvText>
        </div>
    );

    const getNoFormInfoView = () => <EvText>{options.noVisibleText}</EvText>;

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {getHeaderView()}
            {description && getDescriptionView()}
            <div className={styles.contentContainer}>
                {isVisible ? getContent() : getNoFormInfoView()}
            </div>
        </div>
    );
};

EvPreviewFormRenderer.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    previewCta: PropTypes.object,
    editCta: PropTypes.object,
    formFields: PropTypes.array,
    formData: PropTypes.object,
    initialFormData: PropTypes.object,
    cardPreview: PropTypes.object,
    callbackValues: PropTypes.object,
    qParams: PropTypes.object,
    options: PropTypes.object,
    dataOptions: PropTypes.object,
    editable: PropTypes.bool,

    setFormDataState: PropTypes.func,
    onFormDataChange: PropTypes.func,
};

EvPreviewFormRenderer.defaultProps = {
    header: "",
    description: "",
    className: "",
    previewCta: {},
    editCta: {},
    formFields: [],
    formData: {},
    initialFormData: {},
    cardPreview: {},
    callbackValues: {},
    qParams: {},
    options: {},
    dataOptions: {},
    editable: true,

    setFormDataState: () => {},
    onFormDataChange: () => {},
};

export default EvPreviewFormRenderer;
