import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    EvBackButton,
    EvDivider,
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvSummary,
    EvToast,
} from "../../../common/components";
import {
    LIVE_STATUS,
    LOADING_STATUS,
    RESPONSE_STATUS,
} from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import GamedayScheduleActions from "../redux/GamedayScheduleActions";
import { getData, postData } from "../service/GamedayScheduleService";
import {
    GAMEDAY_SCHEDULE_COMPONENTS,
    GAMEDAY_SCHEDULE_PAGES,
    GAMEDAY_SCHEDULE_STATIC,
    GAMEDAY_SCHEDULE_TEMPLATES,
} from "../static/GamedayScheduleStatics";
import "../styles/gameday-schedule-summary.scss";

const styles = {
    container: "ev__gameday-schedule-summary__container",
    headerContainer: "ev__gameday-schedule-summary__header-container",
    summary: {
        container: "ev__gameday-schedule-summary__summary-container",
        segment: "ev__gameday-schedule-summary__summary-segment",
    },
    actionView: {
        container: "ev__gameday-schedule-summary__action-view-container",
    },
};

const GamedayScheduleSummary = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        getStaticData,
        setControls,
        setDynamicDataApiStatus,
    } = props;

    const currentGameData = controls.currentGameData || {};

    const [prefillData, setPrefillData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    url: API_URLS.GAME_DAY_SCHEDULE.SUMMARY.STATIC,
                    templateId: GAMEDAY_SCHEDULE_TEMPLATES.SUMMARY.id,
                }),
                getData(API_URLS.GAME_DAY_SCHEDULE.SUMMARY.PREFILL, {
                    clientCode,
                    id: controls.scheduleId,
                    gameId: currentGameData.gameId,
                }),
            ]),
        [clientCode, controls.scheduleId, currentGameData.gameId, getStaticData]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_SCHEDULE_COMPONENTS.SUMMARY_DATA.id]:
                LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, prefillDataResponse]) => {
                if (
                    prefillDataResponse.data.responseStatus ===
                    RESPONSE_STATUS.SUCCESS
                ) {
                    setPrefillData(prefillDataResponse.data.data);
                    setDynamicDataApiStatus({
                        [GAMEDAY_SCHEDULE_COMPONENTS.SUMMARY_DATA.id]:
                            LOADING_STATUS.COMPLETED,
                    });
                } else if (
                    prefillDataResponse.data.responseStatus ===
                    RESPONSE_STATUS.SHOW_ERROR
                ) {
                    EvToast.error("Sorry", prefillDataResponse.data.message);
                    throw new Error(prefillDataResponse.data);
                } else {
                    throw new Error(prefillDataResponse);
                }
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_SCHEDULE_COMPONENTS.SUMMARY_DATA.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "GamedayScheduleSummary getData");
            });
    }, [clientCode, getAllData, setDynamicDataApiStatus]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_SCHEDULE_COMPONENTS.SUMMARY_DATA.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const apiData = {
            id: controls.scheduleId,
            gameId: currentGameData.gameId,
        };
        setSubmitStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.GAME_DAY_SCHEDULE.SUMMARY.SUBMIT,
            {
                clientCode,
            },
            apiData
        )
            .then((submitResponse) => {
                EvToast.success("Scheduled", "Game Scheduled");
                setControls({
                    currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(
                    e,
                    "GamedayScheduleSummary onSubmitClick"
                );
            });
    }, [
        clientCode,
        controls.scheduleId,
        currentGameData.gameId,
        setControls,
        submitStatus,
    ]);

    const onBackButtonClick = useCallback(() => {
        if (currentGameData.status === LIVE_STATUS.DRAFT) {
            setControls({
                currentPageId: GAMEDAY_SCHEDULE_PAGES.TIME.id,
            });
        } else {
            setControls({
                currentPageId: GAMEDAY_SCHEDULE_PAGES.DASHBOARD.id,
            });
        }
    }, [currentGameData.status, setControls]);

    const onSegmentEditClick = useCallback(
        (actionPage) => {
            setControls({
                currentPageId: actionPage,
            });
        },
        [setControls]
    );

    const getBackButton = () => (
        <EvBackButton onClickHandler={onBackButtonClick}>
            {GAMEDAY_SCHEDULE_STATIC.backButtonText}
        </EvBackButton>
    );

    const getHeaderView = () => (
        <EvHeaderView
            className={styles.headerContainer}
            header={staticData.header}
            description={staticData.description}
        />
    );

    const getSegmentView = (segmentData) => (
        <div key={segmentData.id} className={styles.summary.segment}>
            <EvSummary
                id={segmentData.id}
                segmentStaticData={segmentData}
                data={prefillData}
                onSegmentEditClick={onSegmentEditClick}
                editable={currentGameData.status === LIVE_STATUS.DRAFT}
            />
            <EvDivider />
        </div>
    );

    const getSummaryView = () => (
        <div className={styles.summary.container}>
            {staticData.segments.map(getSegmentView)}
        </div>
    );

    const getActionView = () => (
        <EvSubmitActionView
            className={styles.actionView.container}
            buttonText={staticData.submitCta.text}
            onSubmitClick={onSubmitClick}
            loadingStatus={submitStatus}
            isDisabled={currentGameData.status !== LIVE_STATUS.DRAFT}
        />
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[GAMEDAY_SCHEDULE_TEMPLATES.SUMMARY.id],
            controls.dynamicDataApiStatus[
                GAMEDAY_SCHEDULE_COMPONENTS.SUMMARY_DATA.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getBackButton()}
            {getHeaderView()}
            {getSummaryView()}
            {getActionView()}
        </div>
    );
};

GamedayScheduleSummary.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
};

GamedayScheduleSummary.defaultProps = {
    clientCode: "",
    controls: "",
    staticData: {},

    getStaticData: () => {},
    setControls: () => {},
    setDynamicDataApiStatus: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.GamedayScheduleReducer.controls,
    staticData:
        state.GamedayScheduleReducer.staticData[
            GAMEDAY_SCHEDULE_TEMPLATES.SUMMARY.id
        ],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(GamedayScheduleActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(GamedayScheduleActions.setControls(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(GamedayScheduleActions.setDynamicDataApiStatus(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedayScheduleSummary);
