import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/EnterpriseNotificationsActions";
import { getData, postData } from "../service/enterpriseNotificationsService";
import API_URLS from "../../../services/apiUrls";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { ENTERPRISE_NOTIFICATIONS_PAGES } from "../static/enterpriseNotificationsStatics";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvHeaderView,
    EvLoadingPage,
    EvSubmitActionView,
    EvText,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { EvFormRenderer } from "../../../common/components/form-components";
import EvLogger from "../../../utils/EvLogger";
import EvPills from "../../../common/components/form-components/EvPills";

import staticData from "../static/json/content-form.json";

import "../styles/enterprise-notifications-content.scss";

const styles = {
    container: "ev__enterprise-notifications-content__container",
    loadingContainer: "ev__enterprise-notifications-content__loading-container",
    pill: {
        container: "ev__enterprise-notifications-content__pill-container",
        item: "ev__enterprise-notifications-content__pill-item",
    },
    form: {
        container: "ev__enterprise-notifications-content__form-container",
        submitButton:
            "ev__enterprise-notifications-content__form-submit-button",
        submitContainer:
            "ev__enterprise-notifications-content__form-submit-container",
    },

    preview: {
        container: "ev__enterprise-notifications-content__preview-container",
        header: "ev__enterprise-notifications-content__preview-header",
        notificationCard:
            "ev__enterprise-notifications-content__preview-notification-card",
    },
};

const EnterpriseNotificationsContent = (props) => {
    const { clientCodes, controls, setControls } = props;

    const [formData, setFormData] = useState({});
    const [programId, setProgramId] = useState("");
    const [lazyModalDataList, setLazyModalDataList] = useState({});

    const [preFillDataApiStatus, setPreFillDataApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCodesStringValue = useMemo(() => {
        return clientCodes.join(",");
    }, [clientCodes]);

    const getAllData = useCallback(
        () =>
            Promise.all([
                getData(
                    API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.PRE_FILLED_DATA,
                    {
                        notificationId: controls.currentNotificationId || "",
                        clientCodes: clientCodesStringValue,
                    }
                ),
                getData(API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.CTA_DATA, {
                    notificationId: controls.currentNotificationId || "",
                    clientCodes: clientCodesStringValue,
                }),
            ]),
        [clientCodesStringValue, controls.currentNotificationId]
    );

    useEffect(() => {
        setPreFillDataApiStatus(LOADING_STATUS.LOADING);
        getAllData().then(([dynamicDataResponse, lazyModalDataResponse]) => {
            const initialFormData = parseInitialStateFromFormFields(
                staticData.formFields,
                dynamicDataResponse.data.data
            );
            setProgramId(
                dynamicDataResponse.data?.data?.linkedProgram?.id || ""
            );
            setFormData(initialFormData);
            setLazyModalDataList(lazyModalDataResponse.data.data);
            setPreFillDataApiStatus(LOADING_STATUS.COMPLETED);
        });
    }, [getAllData, setFormData, setPreFillDataApiStatus]);

    const isLoaded = isCompleted(preFillDataApiStatus);

    const getSelectedClientsView = () => {
        return (
            <div className={styles.pill.container}>
                <EvHeaderView header="Selected Clients" />
                {clientCodes.map((client) => (
                    <EvPills
                        buttonClassName={styles.pill.item}
                        key={client}
                        id={client}
                        value={client}
                    />
                ))}
            </div>
        );
    };

    const onFormSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const notificationId = controls.currentNotificationId || "";
        const isFormDataValid = isFullFormValid(formData);
        // if not valid, show all error messages
        if (!isFormDataValid) {
            setFormData(markAllFieldsUsed(formData));
        } else {
            setSubmitStatus(LOADING_STATUS.LOADING);
            const apiData = parseFormDataForApi(formData);
            postData(
                API_URLS.ENTERPRISE_NOTIFICATIONS.CONTENT.POST_DATA,
                {
                    clientCodes: clientCodesStringValue,
                },
                {
                    ...apiData,
                    notificationId,
                    linkedProgram: programId,
                }
            )
                .then((response) => {
                    EvToast.success(
                        "Saved",
                        "enterprise notification content saved",
                        {
                            icon: TOAST_ICON_OBJECTS.CHECK,
                        }
                    );
                    setSubmitStatus(LOADING_STATUS.COMPLETED);
                    setControls({
                        selectedPageId:
                            ENTERPRISE_NOTIFICATIONS_PAGES.SUMMARY.id,
                    });
                })
                .catch((e) => {
                    setSubmitStatus(LOADING_STATUS.FAILED);
                    EvToast.error("Sorry", "Something went wrong", {
                        icon: TOAST_ICON_OBJECTS.CHECK,
                    });
                    EvLogger.errorWithObject(
                        e,
                        "NotificationContent onFormSubmitClick"
                    );
                });
        }
    }, [
        clientCodesStringValue,
        controls.currentNotificationId,
        formData,
        programId,
        setControls,
        submitStatus,
    ]);

    const getFormView = () => (
        <div className={styles.form.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormData}
                dataOptions={lazyModalDataList}
            />
            <EvSubmitActionView
                addLabelSpacing
                buttonClass={styles.form.submitButton}
                className={styles.form.submitContainer}
                buttonText={staticData.nextCta.text}
                onSubmitClick={onFormSubmitClick}
                loadingStatus={submitStatus}
            />
        </div>
    );

    const getHeaderView = () => (
        <div>
            <EvText subHeading>{staticData.header}</EvText>
        </div>
    );

    if (!isLoaded) {
        return (
            <div className={styles.loadingContainer}>
                <EvLoadingPage />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getSelectedClientsView()}
            {getFormView()}
        </div>
    );
};

EnterpriseNotificationsContent.propTypes = {
    clientCodes: PropTypes.array,
    controls: PropTypes.object,
    setControls: PropTypes.func,
};

EnterpriseNotificationsContent.defaultProps = {
    clientCodes: [],
    controls: {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EnterpriseNotificationsReducer.controls,
    clientCodes: state.EnterpriseNotificationsReducer.selectedClientCodes,
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseNotificationsContent);
