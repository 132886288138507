import React, { memo } from "react";
import PropTypes from "prop-types";
import className from "classnames";

import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { FORM_FIELD_TYPES } from "../../static/Enums";
import FormValidation from "../../../utils/FormValidations";

import "../../styles/form-components/ev-toggle-button.scss";

const EvToggleButton = (props) => {
    const styles = {
        container: "ev__ev-toggle-button-container",
        label: "ev__ev-toggle-button-label",
        toggle: "ev__ev-toggle-button",
        slider: "ev__ev-toggle-button-slider",
        sliderChecked: "ev__ev-toggle-button-checked",
    };

    const toggleLabelStyles = className(styles.toggle, props.className);

    const { formData, keyName, handleInputChange, type, formItem } = props;

    const isChecked = () => {
        if (!isNullOrEmpty(formData)) {
            return formData.value;
        }
        return false;
    };

    const handleOnChange = () => {
        const selectedObject = {
            [keyName]: {
                key: keyName,
                value: !isChecked(),
                type,
            },
            errorObject: {},
            formItemType: type,
        };
        handleInputChange(keyName, {
            ...selectedObject,
            errorObject: {
                ...formData.errorObject,
                ...FormValidation(formItem, selectedObject),
            },
        });
    };

    return (
        <div className={styles.container}>
            {props.label && <span className={styles.label}>{props.label}</span>}
            <label className={toggleLabelStyles} htmlFor={props.id}>
                <input
                    type="checkbox"
                    id={props.keyName}
                    checked={isChecked()}
                    value={isChecked()}
                    onChange={handleOnChange}
                />
                <span className={styles.slider} />
            </label>
        </div>
    );
};

EvToggleButton.propTypes = {
    keyName: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,

    formItem: PropTypes.object,
    formData: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvToggleButton.defaultProps = {
    keyName: "",
    className: "",
    label: "",
    type: FORM_FIELD_TYPES.TOGGLE,

    formData: {},
    formItem: {},

    handleInputChange: () => {},
};

export default memo(EvToggleButton);
