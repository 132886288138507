import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { EvLoadingPage, EvToast } from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import EmployerCardActions from "../redux/EmployerCardActions";
import { getData, postData } from "../service/EmployerCardService";
import {
    EMPLOYER_CARD_TEMPLATES,
    STATIC_STRINGS,
} from "../static/EmployerCardStatics";
import EmployerCardView from "./EmployerCardView";

const EmployerCardContainer = (props) => {
    const { clientCode, controls, staticData, getStaticData } = props;

    const [bannerFormData, setBannerFormData] = useState({});
    const [landingPageFormData, setLandingPageFormData] = useState({});
    const [formDataLoadingStatus, setFormDataLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [submitApiStatus, setSubmitApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    useEffect(() => {
        getStaticData({
            url: API_URLS.EMPLOYER_CARD.STATIC,
            templateId: EMPLOYER_CARD_TEMPLATES.STATIC.id,
        }).then((staticDataResponse) => {
            setFormDataLoadingStatus(LOADING_STATUS.LOADING);
            getData(API_URLS.EMPLOYER_CARD.PREFILL, { clientCode })
                .then((prefillResponse) => {
                    const initialBannerFormData = parseInitialStateFromFormFields(
                        staticDataResponse.bannerFormData.formFields,
                        prefillResponse.data.data
                    );
                    const initialLandingPageFormData = parseInitialStateFromFormFields(
                        staticDataResponse.landingPageFormData.formFields,
                        prefillResponse.data.data
                    );
                    setBannerFormData(initialBannerFormData);
                    setLandingPageFormData(initialLandingPageFormData);
                    setFormDataLoadingStatus(LOADING_STATUS.COMPLETED);
                })
                .catch((e) => {
                    setFormDataLoadingStatus(LOADING_STATUS.FAILED);
                    EvLogger.errorWithObject(
                        e,
                        "EmployerCardContainer getData"
                    );
                });
        });
    }, [getStaticData, clientCode]);

    const onFormSubmit = useCallback(() => {
        if (submitApiStatus === LOADING_STATUS.LOADING) {
            return;
        }

        const isBannerFormValid = isFullFormValid(bannerFormData);
        const isLandingFormValid = isFullFormValid(landingPageFormData);

        if (!isBannerFormValid || !isLandingFormValid) {
            setBannerFormData(markAllFieldsUsed(bannerFormData));
            setLandingPageFormData(markAllFieldsUsed(landingPageFormData));
            EvToast.warn("", STATIC_STRINGS.FORM_INVALID_TOAST);
            return;
        }

        setSubmitApiStatus(LOADING_STATUS.LOADING);

        const formDataForApi = {
            ...parseFormDataForApi(bannerFormData),
            ...parseFormDataForApi(landingPageFormData),
            clientCode,
        };
        postData(API_URLS.EMPLOYER_CARD.SUBMIT, {}, formDataForApi)
            .then((submitResponse) => {
                setSubmitApiStatus(LOADING_STATUS.COMPLETED);
                EvToast.success("", "Employer Card details updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
            })
            .catch((e) => {
                setSubmitApiStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                EvLogger.errorWithObject(
                    e,
                    "EmployerCardContainer onFormSubmit"
                );
            });
    }, [bannerFormData, landingPageFormData, clientCode, submitApiStatus]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[EMPLOYER_CARD_TEMPLATES.STATIC.id],
            formDataLoadingStatus
        )
    ) {
        return <EvLoadingPage animatedFadeIn />;
    }

    return (
        <EmployerCardView
            staticData={staticData}
            bannerFormData={bannerFormData}
            landingPageFormData={landingPageFormData}
            setBannerFormData={setBannerFormData}
            setLandingPageFormData={setLandingPageFormData}
            onFormSubmit={onFormSubmit}
            submitStatus={submitApiStatus}
        />
    );
};

EmployerCardContainer.propTypes = {
    clientCode: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    getStaticData: PropTypes.func,
};

EmployerCardContainer.defaultProps = {
    clientCode: "",
    controls: {},
    staticData: {},

    getStaticData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EmployerCardReducer.controls,
    staticData:
        state.EmployerCardReducer.staticData[EMPLOYER_CARD_TEMPLATES.STATIC.id],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(EmployerCardActions.getStaticData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmployerCardContainer);
