import React, { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactJson from "react-json-view";

import FormValidation from "../../../utils/FormValidations";
import { EvInputErrorView } from "./index";
import { EvButton, EvIcon, EvLoader, EvToast } from "../index";
import { getData, postData } from "../../service/CommonService";
import API_URLS from "../../../services/apiUrls";
import {
    getEnvironment,
    queryStringGenerator,
} from "../../../utils/CommonUtils";
import { DOMAINS, LOADING_STATUS } from "../../static/Enums";
import { COLORS } from "../../static/VmsStatics";
import EvLogger from "../../../utils/EvLogger";

import "../../styles/form-components/ev-form-criteria-builder.scss";

/**
 * This is an input field for criteria builder along with *some text* on it's left if passed as "label" prop.
 * keyName prop is mandatory.
 */

const STATICS = {
    editCta: {
        name: "Edit in Criteria Builder",
    },
    criteriaBuilderEndpoint: {
        [DOMAINS.LOCAL]: "https://audience.dev.evive.io/criteria/builder",
        [DOMAINS.DEV]: "https://audience.dev.evive.io/criteria/builder",
        [DOMAINS.PROD]: "https://audience.evive.io/criteria/builder",
    },
    callbackUrl: `${window.location.origin}/cc/cc-open/criteria-builder-callback`,
    errors: {
        getRefValue: "Either old tag is present or the audience is invalid",
        getValue: "Failed to get value from criteria builder",
    },
};
const EvFormCriteriaBuilder = (props) => {
    const styles = {
        mainContainer: "ev__ev-form-criteria-builder__main-container",
        inputContainer: "ev__ev-form-criteria-builder__input-container",
        errorContainer: "ev__ev-form-criteria-builder__error-container",
        inputBox: "ev__ev-form-criteria-builder__input-box",
        label: "ev__ev-form-criteria-builder__label",
        actionView: {
            container: "ev__ev-form-criteria-builder__action-view-container",
            editButton: "ev__ev-form-criteria-builder__action-view-edit-button",
        },
    };

    const jsonFieldStyle = {
        backgroundColor: "#fff",
        borderRadius: "1rem",
        border: "none",
        flex: "1",
        outline: "none",
        alignItems: "center",
        paddingLeft: "1.5rem",
        fontFamily: "'Overpass', Arial",
        fontWeight: "800",
        fontSize: "16px",
    };

    const [loadingStatus, setLoadingStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const [editEnabled, setEditEnabled] = useState(false);

    const {
        placeholder,
        inlineStyles,
        value,
        type,
        formItem,
        showError,
        formData,
    } = props;

    const customClass = classNames(styles.inputBox, props.className);

    const onChange = useCallback(
        (value) => {
            const inputFieldObject = {
                [props.keyName]: {
                    key: props.keyName,
                    value,
                    type: props.type,
                },
                errorObject: {}, // will be overwritten later
                formItemType: type,
            };
            props.handleInputChange(props.keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                },
            });
        },
        [formData.errorObject, formItem, props, type]
    );

    const onBlur = useCallback(
        (e) => {
            const inputFieldObject = {
                [props.keyName]: {
                    key: props.keyName,
                    value: e.target.value,
                    type: props.type,
                },

                errorObject: {}, // will be overwritten later
                formItemType: type,
            };
            props.handleInputChange(props.keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [formData.errorObject, formItem, props, type]
    );

    const handleJsonEdit = useCallback(
        ({ updated_src }) => {
            const inputFieldObject = {
                [props.keyName]: {
                    key: props.keyName,
                    value: JSON.stringify(updated_src),
                    type: props.type,
                },

                errorObject: {}, // will be overwritten later
                formItemType: type,
            };
            props.handleInputChange(props.keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    isUsed: true,
                },
            });
        },
        [formData.errorObject, formItem, props, type]
    );

    const getCriteriaBuilderRefId = useCallback(() => {
        return new Promise((resolve, reject) => {
            postData(
                API_URLS.COMMON.CRITERIA_BUILDER.GET_REF_ID,
                {},
                {
                    audience: value,
                }
            )
                .then((responseData) => {
                    if (responseData.data.responseStatus === "FAILED") {
                        reject("Error");
                    }
                    resolve(responseData.data.data.refId || "");
                })
                .catch((e) => {
                    reject("Error");
                });
        });
    }, [value]);

    const openCriteriaBuilder = useCallback((refId) => {
        return new Promise((resolve) => {
            const criteriaPopup = window.open(
                `${
                    STATICS.criteriaBuilderEndpoint[getEnvironment()]
                }?${queryStringGenerator({
                    refId,
                    // callback: "http://localhost:3000/cc/criteria-builder-callback",
                    callback: STATICS.callbackUrl,
                })}`,
                "Criteria Builder",
                "width=1024,height=768,toolbar=no,menubar=no,location=no,status=no"
            );
            // 'https://audience.dev.evive.io/criteria/builder?refId=5774ad26-26da-4049-aba3-91688244792a&callback=http://localhost:3000/cc/criteria-builder-callback',
            // 'http://localhost:3000/cc/criteria-builder-callback?refId=1234',

            window.criteriaBuilderCallback = (e) => {
                // console.log("CUSTOM EVENT", e)
                resolve(e.refId);
                criteriaPopup.close();
            };
        });
    }, []);

    const onEditClick = useCallback(async () => {
        setLoadingStatus(LOADING_STATUS.LOADING);
        try {
            const refId = await getCriteriaBuilderRefId();
            const newRefId = await openCriteriaBuilder(refId);
            const response = await getData(
                API_URLS.COMMON.CRITERIA_BUILDER.GET_VALUE,
                {
                    refId: newRefId,
                }
            );

            if (response.data.responseStatus === "SUCCESS") {
                onChange(response.data.data.audience);
                setLoadingStatus(LOADING_STATUS.COMPLETED);
            } else {
                EvToast.error("Error", STATICS.errors.getValue);
                setEditEnabled(true);
                setLoadingStatus(LOADING_STATUS.FAILED);
            }
        } catch (e) {
            EvLogger.errorWithObject(
                e,
                "EvFormCriteriaBuilder onEditClick data"
            );
            EvToast.error("Error", STATICS.errors.getRefValue);
            setEditEnabled(true);
            setLoadingStatus(LOADING_STATUS.FAILED);
        }
    }, [getCriteriaBuilderRefId, openCriteriaBuilder, onChange]);

    const getInputJsonField = (inputFieldData) => {
        let parseJson;
        try {
            parseJson = JSON.parse(inputFieldData.value);
        } catch (e) {
            return (
                <input
                    key={props.keyName}
                    className={customClass}
                    style={inputFieldData.inlineStyles}
                    placeholder={inputFieldData.placeholder}
                    onChange={onBlur}
                    onBlur={onBlur}
                    type={props.type}
                    value={inputFieldData.value}
                />
            );
        }

        return (
            <ReactJson
                {...(editEnabled && {
                    onAdd: handleJsonEdit,
                    onEdit: handleJsonEdit,
                    onDelete: handleJsonEdit,
                })}
                src={parseJson ?? {}}
                name={"audience"}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
                iconStyle={"triangle"}
                quotesOnKeys={false}
                theme={"summerfruit:inverted"}
                style={jsonFieldStyle}
            />
        );
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton
                className={styles.actionView.editButton}
                secondary
                iconButton
                iconName="EDIT"
                onClickHandler={onEditClick}
                disabledView={loadingStatus === LOADING_STATUS.LOADING}
            >
                {STATICS.editCta.name}
            </EvButton>
            {loadingStatus === LOADING_STATUS.LOADING && <EvLoader size={4} />}
            {loadingStatus === LOADING_STATUS.FAILED && (
                <EvIcon
                    size={4}
                    iconName="ALERT"
                    fillColor={COLORS.RED_MONZA}
                />
            )}
        </div>
    );

    const getErrorView = () => {
        return <EvInputErrorView errorObject={formData.errorObject} />;
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.inputContainer}>
                {getInputJsonField({ placeholder, inlineStyles, value })}
            </div>
            {getActionView()}
            <div className={styles.errorContainer}>
                {showError && getErrorView()}
            </div>
        </div>
    );
};

EvFormCriteriaBuilder.propTypes = {
    /** use along inputField to give a label */
    label: PropTypes.string,
    /** use along inputField to give a placeholder */
    placeholder: PropTypes.string,
    /** key of the inputField */
    keyName: PropTypes.string,
    /** type of the inputField */
    type: PropTypes.string,
    /** is the field editable */
    editable: PropTypes.bool,
    /** className for the inputField */
    className: PropTypes.string,
    /** className for the inputField */
    value: PropTypes.string,

    /** customStyles for the inputField, if any */
    inlineStyles: PropTypes.object,

    /** function to handle input change */
    handleInputChange: PropTypes.func,
};

EvFormCriteriaBuilder.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    keyName: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    editable: PropTypes.bool,
    formItem: PropTypes.object,
    formData: PropTypes.object,
    showError: PropTypes.bool,

    inlineStyles: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvFormCriteriaBuilder.defaultProps = {
    label: "",
    placeholder: "",
    keyName: "",
    className: "",
    type: "",
    value: "",
    editable: true,
    formItem: {},
    formData: {},
    showError: true,

    inlineStyles: {},

    handleInputChange: () => {},
};

export default memo(EvFormCriteriaBuilder);
