import React, { memo } from "react";
import { EvText } from "../../../common/components";
import "../styles/ev-footer.scss";

const EvFooter = () => {
    const styles = {
        container: "ev__ev-footer__container",
    };

    return (
        <div className={styles.container}>
            {/* Todo: to be added later. */}
            {/* <EvButton textButton>Privacy Policy*</EvButton> */}
            {/* <EvButton textButton>Terms*</EvButton> */}
            <EvText smallNormal>© 2020 Evive Health, LLC</EvText>
            {/*<a href="/cc/api/logout">*/}
            {/*  <EvButton textButton>Logout</EvButton>*/}
            {/*</a>*/}
        </div>
    );
};

export default memo(EvFooter);
