const FORM_ITEMS = [
    {
        id: "evText",
        label: "Text",
        description: "Some text input description",
        placeholder: "Enter text",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "MANDATORY",
                errMsg: "Shows mandatory error text here",
            },
        ],
    },
    {
        id: "evPrefixText",
        label: "Prefix Text",
        description: "Text with a prefix dropdown",
        placeholder: "www.myevive.com",
        prefixPlaceholder: "Select Prefix",
        type: "PREFIX_TEXT",
        editable: true,
        defaultValue: "http://",
        validations: [
            {
                type: "MANDATORY",
                errMsg: "Shows mandatory prefix error here",
            },
            { type: "MIN_LENGTH", value: "10", errMsg: "Must enter href link" },
        ],
        data: [
            {
                id: "https",
                value: "https://",
                name: "HTTPS",
            },
            {
                id: "http",
                value: "http://",
                name: "HTTP",
            },
        ],
        options: {
            hrefPreview: true,
            hrefPreviewCtaText: "Open link {href}",
        },
    },
    {
        id: "evColorPicker",
        label: "Color Picker",
        description: "Enter hex code to see color preview",
        placeholder: "EDF0F5",
        type: "COLOR",
        defaultValue: "",
        editable: true,
        validations: [
            {
                type: "HEX_COLOR",
                errMsg: "Must be a valid 6 digit hex color code",
            },
        ],
    },
    {
        id: "evRadio",
        label: "Radio Button",
        description: "Select only one",
        placeholder: "",
        defaultValue: "opt1",
        type: "RADIO",
        editable: true,
        data: [
            {
                id: "opt1",
                name: "Option 1",
                value: "opt1",
            },
            {
                id: "opt2",
                name: "Option 2",
                value: "opt2",
            },
        ],
    },
    {
        id: "evImageUploader",
        label: "Upload Image",
        description:
            "DO NOT UPLOAD IMAGE HERE. Maximum size limit of 1MB. Maximum dimensions 490px * 280px. Only PNG files accepted",
        placeholder: "Upload logo",
        type: "IMAGE_UPLOAD",
        editable: true,
        options: {
            showPreview: true,
            clearText: "Remove Image",
        },
    },
    {
        id: "ev-file-upload",
        label: "Upload File",
        description: "DO NOT UPLOAD FILE HERE.",
        type: "FILE_UPLOAD",
        defaultValue: "",
    },
    {
        id: "baseFile",
        label: "Base File Uploader",
        description:
            "DO NOT UPLOAD FILE HERE. Used to upload files in base64 format.",
        placeholder: "Upload File",
        type: "BASE_FILE_UPLOAD",
        editable: true,
        defaultValue:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        options: {
            uploadEndpoint: "/cc/api/upload-image",
            showPreview: true,
            apiData: {
                fileType: "gamePdfUpload",
            },
        },
        validations: [
            // {
            //   type: "MANDATORY",
            //   errMsg: "Required fields",
            // },
        ],
    },
    {
        id: "imageCropper",
        label: "Image Cropper",
        description:
            "DO NOT UPLOAD FILE HERE. Selected Image can be cropped before uploading",
        placeholder: "Upload Image",
        type: "IMAGE_UPLOAD",
        editable: true,
        defaultValue:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        options: {
            uploadEndpoint: "/cc/api/upload-image",
            showPreview: true,
            allowCrop: true,
            width: 200,
            height: 400,
            apiData: {
                fileType: "imageCropper",
            },
        },
        validations: [
            // {
            //   type: "MANDATORY",
            //   errMsg: "Required fields",
            // },
        ],
    },
    {
        id: "evListTextInput",
        label: "Text List",
        description: "Add a list of text input",
        placeholder: "Enter text",
        type: "TEXT_LIST",
        editable: true,
        defaultValue: "",
        validations: [],
        options: {
            noItemAddedInfo:
                "No items in the list. Add a name and press Enter. This message is optional",
        },
    },
    {
        id: "evChecklistDropdownInput",
        label: "Checklist Dropdown",
        description: "Check all options in the dropdown",
        placeholder: "Select options",
        type: "CHECKLIST_DROPDOWN",
        editable: true,
        defaultValue: "",
        validations: [],
        data: [
            {
                id: "en",
                value: "en",
                name: "English",
            },
            {
                id: "sp",
                value: "sp",
                name: "Spanish",
            },
            {
                id: "lang3",
                value: "lang3",
                name: "Language 3",
            },
            {
                id: "lang4",
                value: "lang4",
                name: "Language 4",
            },
            {
                id: "lang5",
                value: "lang5",
                name: "Language 5",
            },
            {
                id: "lang6",
                value: "lang6",
                name: "Language 6",
            },
            {
                id: "lang7",
                value: "lang7",
                name: "Language 7",
            },
            {
                id: "lang8",
                value: "lang8",
                name: "Language 8",
            },
            {
                id: "lang9",
                value: "lang9",
                name: "Language 9",
            },
            {
                id: "lang10",
                value: "lang10",
                name: "Language 10",
            },
        ],
        options: {
            showSelectedItems: true,
            dataSourceType: "INTERNAL",
            noItemAddedInfo:
                "No items in the list. Add a name and press Enter. This message is optional",
        },
    },
    {
        id: "evDropdown",
        label: "Dropdown",
        description: "Select a option from a list",
        placeholder: "Select Type",
        type: "DROPDOWN",
        editable: true,
        validations: [],
        defaultValue: "",
        data: [
            {
                id: "dd_option_1",
                name: "Option 1",
                value: "options_1",
            },
            {
                id: "dd_option_2",
                name: "Option 2",
                value: "options_2",
            },
            {
                id: "dd_option_3",
                name: "Option 3",
                value: "options_3",
            },
            {
                id: "dd_option_4",
                name: "Option 4",
                value: "options_4",
            },
        ],
    },
    {
        id: "evTextArea",
        label: "Text Area",
        description:
            "Text area to write more than one line. It can be resized by user",
        placeholder: "",
        type: "SMALL_TEXT_AREA",
        editable: true,
        validations: [],
        subForm: [],
        data: [],
    },
    {
        id: "evDate",
        label: "Date",
        description: "Select date.",
        placeholder: "",
        type: "DATE",
        editable: true,
        validations: [],
    },
    {
        id: "evTime",
        label: "Time",
        description: "Select time.",
        placeholder: "",
        type: "TIME",
        editable: true,
        validations: [],
    },
    {
        id: "evSubform",
        label: "Subform",
        description: "Show other form items based on selection",
        placeholder: "Select Type",
        type: "DROPDOWN",
        editable: true,
        validations: [],
        defaultValue: "",
        data: [
            {
                id: "opt-text",
                name: "Text Box",
                value: "opt-text",
            },
            {
                id: "opt-radio",
                name: "Radio",
                value: "opt-radio",
            },
            {
                id: "opt-none",
                name: "None",
                value: "opt-none",
            },
        ],
        subForm: [
            {
                id: "subText",
                matchId: "opt-text",
                label: "Sub-form Text",
                description: "This is a sub form",
                placeholder: "Enter sub form value",
                type: "TEXT",
                editable: true,
            },
            {
                id: "subRadio",
                matchId: "opt-radio",
                label: "Sub-form Radio",
                description: "This is a sub form",
                placeholder: "",
                defaultValue: "",
                type: "RADIO",
                editable: true,
                data: [
                    {
                        id: "opt1",
                        name: "Option 1",
                        value: "opt1",
                    },
                    {
                        id: "opt2",
                        name: "Option 2",
                        value: "opt2",
                    },
                ],
            },
        ],
    },
    {
        id: "evBullets",
        label: "Bullets",
        description: "",
        placeholder: "",
        type: "BULLETS",
        editable: true,
        validations: [],
        subForm: [],
        data: [],
        options: {
            removeCta: {
                iconName: "DELETE",
                text: "Remove",
            },
            addCta: { text: "Add new bullet", iconName: "PLUS" },
        },
    },
    {
        id: "evDateTimeZone",
        label: "Date Time Zone",
        description: "",
        placeholder: "",
        type: "DATE_TIME_ZONE",
        editable: true,
        dateFormField: {
            id: "date",
            label: "",
            description: "",
            placeholder: "Date",
            type: "DATE",
            editable: true,
            defaultValue: "",
        },
        timeFormField: {
            id: "time",
            label: "",
            description: "",
            placeholder: "Time",
            type: "TIME",
            editable: true,
            defaultValue: "",
        },
        timeZoneFormField: {
            id: "timeZone",
            label: "Select a time zone",
            description: "",
            placeholder: "Select a time zone",
            type: "DROPDOWN",
            editable: true,
            defaultValue: "",
            data: [
                {
                    id: "cst",
                    name: "Central Standard Time",
                    value: "CST",
                },
                {
                    id: "ist",
                    name: "Indian Standard Time",
                    value: "IST",
                },
            ],
        },
    },
    {
        id: "previewImage",
        label: "Banner Image with preview",
        description:
            "Suggested size: 1900x1266 px. If no image is uploaded, the MyEvive default banner will be displayed.",
        placeholder: "Upload image",
        type: "IMAGE_UPLOAD",
        editable: true,
        defaultValue:
            "https://e9ea2d0aeaa29c37b705-2cad9b850be07674bbe9265091e516d2.ssl.cf1.rackcdn.com/Notification/1121x879_web.png",
        options: {
            uploadEndpoint: "/cc/api/upload-image",
            showPreview: true,
        },
        validations: [
            // {
            //   type: "MANDATORY",
            //   errMsg: "Required fields",
            // },
        ],
    },
    {
        id: "demoTemplate",
        type: "FORM_TEMPLATE",
        label: "Form Templates",
        itemLabel: "Card {count} content:",
        description:
            "A array of for templates can be added here. User can add any number of items (validations required for restrictions) based on a form template. Form template can contain a collection of other form items",
        addItemCta: { text: "Add Card" },
        removeItemCta: { text: "Remove Card" },
        formItems: [
            {
                id: "formTemplateText",
                label: "Text",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "Description is required",
                    },
                ],
                subForm: [],
                data: [],
            },
            {
                id: "formTemplateDescription",
                label: "Description",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                validations: [],
                subForm: [],
                data: [],
            },
        ],
        validations: [
            {
                type: "MIN_ITEM_COUNT",
                value: 2,
                errMsg: "Must contain at least 2 points",
            },
            {
                type: "MAX_ITEM_COUNT",
                value: 5,
                errMsg: "Can contain maximum 5 points",
            },
        ],
    },
    {
        id: "demoPreviewTemplate",
        type: "FORM_TEMPLATE",
        label: "Form Templates With Preview",
        itemLabel: "Card {count} content:",
        description:
            "Along with features of above form template, we can set a preview for each item",
        addItemCta: { text: "Add Card" },
        removeItemCta: { text: "Remove Card" },
        previewCta: { text: "Preview", iconName: "VISIBLE" },
        editCta: { text: "Edit Card", iconName: "EDIT" },
        formItems: [
            {
                id: "formTemplateText",
                label: "Text",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                validations: [
                    {
                        type: "MANDATORY",
                        value: "",
                        errMsg: "Description is required",
                    },
                ],
                subForm: [],
                data: [],
            },
            {
                id: "formTemplateDescription",
                label: "Description",
                description: "",
                placeholder: "",
                type: "TEXT",
                editable: true,
                validations: [],
                subForm: [],
                data: [],
            },
        ],
        cardPreview: {
            previewType: "QUESTION_CARD",
            contentKeys: {
                header: "formTemplateText",
                description: "formTemplateDescription",
            },
            staticContent: {},
        },
        validations: [
            {
                type: "MIN_ITEM_COUNT",
                value: 2,
                errMsg: "Must contain at least 2 points",
            },
            {
                type: "MAX_ITEM_COUNT",
                value: 5,
                errMsg: "Can contain maximum 5 points",
            },
        ],
    },
];

const initialFormValue = {
    evListTextInput: ["Item 1", "Item 2"],
    // evPrefixText: "https://www.myevive.com",
    evChecklistDropdownInput: [
        {
            id: "en",
            value: "en",
            name: "English",
        },
        {
            id: "sp",
            value: "sp",
            name: "Spanish",
        },
    ],
    // formTemplate: [
    //   {
    //     formTemplateText: "templateText1",
    //     formTemplateDescription: "templateDescription1",
    //   },
    //   {
    //     formTemplateText: "templateText2",
    //     formTemplateDescription: "templateDescription2",
    //   },
    // ],
    // formTemplatePreview: [
    //   {
    //     formTemplateText: "templateText1",
    //     formTemplateDescription: "templateDescription1",
    //   },
    // ],
};

const VALIDATED_FORM_ITEMS = [
    {
        id: "evTextMandatory",
        label: "Mandatory Text",
        description: "You must enter some value",
        placeholder: "Enter text",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "MANDATORY",
                errMsg: "Shows mandatory error text here",
            },
        ],
    },
    {
        id: "evTextMaxLength",
        label: "Max Length Text",
        description: "Max length of 10 characters",
        placeholder: "Enter text",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            { type: "MAX_LENGTH", value: "10", errMsg: "10 character maximum" },
        ],
    },
    {
        id: "evTextMinLength",
        label: "Min Length Text",
        description: "Min length of 10 characters",
        placeholder: "Enter text",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            { type: "MIN_LENGTH", value: "10", errMsg: "10 character minimum" },
        ],
    },
    {
        id: "evTextEmail",
        label: "Email Text",
        description: "Must be a valid email address",
        placeholder: "Enter Email",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "CHECK_EMAIL",
                value: "",
                errMsg: "Must be an valid email",
            },
        ],
    },
    {
        id: "evTextNumber",
        label: "Numeric Text",
        description: "Must be a number",
        placeholder: "Enter Number",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "CHECK_NUMBER",
                value: "",
                errMsg: "Must be an valid number",
            },
        ],
    },
    {
        id: "evHecColor",
        label: "Enter Hex Color Code",
        description: "Must be a valid hex color code",
        placeholder: "#FF00FF",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "HEX_COLOR",
                value: "",
                errMsg: "Must be a valid hex color code",
            },
        ],
    },
    {
        id: "evAlphaNumeric",
        label: "Enter Alphanumeric Value",
        description: "No special characters other than spaces",
        placeholder: "Enter alphanumeric",
        type: "TEXT",
        editable: true,
        defaultValue: "",
        validations: [
            {
                type: "ALPHA_NUMERIC",
                value: "",
                errMsg: "Must be a alpha numeric",
            },
        ],
    },
];

const MINIMAL_FORM_ITEMS = [
    {
        id: "minimalLabel",
        label: "",
        description: "",
        placeholder: "",
        type: "LABEL",
        editable: false,
        validations: [],
    },
    {
        id: "minimalDropdown",
        type: "MINIMAL_DROPDOWN",
        label: "",
        description: "",
        placeholder: "Set operator",
        editable: true,
        defaultValue: "",
        dataEndpoint: "",
        validations: [
            {
                type: "MANDATORY",
                errMsg: "Please enter operator",
            },
        ],
        data: [
            {
                id: "isEqual",
                name: "is Equal to",
                value: "isEqual",
            },
            {
                id: "isNotEqual",
                name: "is Not Equal to",
                value: "isNotEqual",
            },
            {
                id: "isIn",
                name: "is on of the following",
                value: "isIn",
            },
        ],
    },
    {
        id: "minimalTsv",
        type: "TSV_TEXT",
        label: "",
        description: "",
        placeholder: "[Enter values separated by comma]",
        editable: true,
        defaultValue: [],
        dataEndpoint: "",
        data: [],
        validations: [
            {
                type: "MANDATORY",
                errMsg: "Please enter values",
            },
        ],
    },
];

const minimalFormInitialData = {
    minimalLabel: { id: "labelText", value: "Label Text", name: "Label Text" },
};

export {
    FORM_ITEMS,
    initialFormValue,
    VALIDATED_FORM_ITEMS,
    MINIMAL_FORM_ITEMS,
    minimalFormInitialData,
};
