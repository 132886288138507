import React from "react";
import PropTypes from "prop-types";
import stringTemplate from "string-template";

import {
    EvButton,
    EvInfoBox,
    EvText,
    EvTicketCard,
} from "../../../../common/components";
import {
    dateFormatterFromFullZString,
    timeFormatterFromFullZString,
} from "../../../../utils/DateTimeUtils";
import { UI_FORMATS } from "../../../../common/static/Enums";

import "../styles/vendor-programs-dashboard.scss";

const styles = {
    container: "ev__vendor-programs-dashboard__container",
    header: {
        infoBox: "ev__vendor-programs-dashboard__header-info-box",
        container: "ev__vendor-programs-dashboard__header-container",
        button: "ev__vendor-programs-dashboard__header-button",
        text: "ev__vendor-programs-dashboard__header-text",
    },
    noVendorView: {
        container: "ev__vendor-programs-dashboard__no-vendor-container",
        innerContainer:
            "ev__vendor-programs-dashboard__no-vendor-inner-container",
    },
    vendorListContainer: "ev__vendor-programs-dashboard__vendor-list-container",
    vendorCard: {
        container: "ev__vendor-programs-dashboard__vendor-card-container",
        contentWrapper:
            "ev__vendor-programs-dashboard__vendor-card-content-wrapper",
        text: "ev__vendor-programs-dashboard__vendor-card-text",
    },
};

const VendorProgramsDashboard = (props) => {
    const {
        controls,
        staticData,
        currentVendors,
        onNewVendorProgramClick,
        onVendorCardClick,
    } = props;

    const getHeader = () => (
        <div className={styles.header.container}>
            {controls.newVendorProgramSaved && (
                <EvInfoBox
                    containerClassName={styles.header.infoBox}
                    header={staticData.addedInfo.header}
                    description={staticData.addedInfo.description}
                />
            )}
            <EvButton
                className={styles.header.button}
                primaryFilled
                onClickHandler={onNewVendorProgramClick}
            >
                {staticData.newVendorCta.text}
            </EvButton>
            <EvText className={styles.header.text} subHeading>
                {staticData.header}
            </EvText>
        </div>
    );

    const getNoVendorView = () => (
        <div className={styles.noVendorView.container}>
            <div className={styles.noVendorView.innerContainer}>
                <EvText>{staticData.noVendorProgramErrorMessage}</EvText>
            </div>
        </div>
    );

    const getVendorCardContent = (vendorCardData) => (
        <div className={styles.vendorCard.contentWrapper}>
            <EvText className={styles.vendorCard.text} headingSecondary>
                {vendorCardData.vendorName}
            </EvText>
            <EvText className={styles.vendorCard.text} defaultBold>
                {vendorCardData.programName}
            </EvText>
            <EvText className={styles.vendorCard.text} default italics>
                {vendorCardData.lastModified
                    ? stringTemplate(staticData.programLastModifiedText, {
                          date: dateFormatterFromFullZString(
                              vendorCardData.lastModified
                          ),
                          time: timeFormatterFromFullZString(
                              vendorCardData.lastModified,
                              UI_FORMATS.FULL_TIME_WITH_SECONDS_MERIDIEM
                          ),
                      })
                    : staticData.noModifiedDateMessage}
            </EvText>
        </div>
    );

    const getVendorCard = (vendorCardData) => (
        <EvButton
            onlyChild
            key={`${vendorCardData.vendorId}_${vendorCardData.programId}`}
            onClickHandler={onVendorCardClick}
            callbackValues={vendorCardData}
        >
            <div className={styles.vendorCard.container}>
                <EvTicketCard
                    imageInlineStyle={{
                        backgroundColor: "#fff",
                    }}
                    backgroundColor="#fff"
                    imgUrl={vendorCardData.programLogo}
                    content={getVendorCardContent(vendorCardData)}
                    noContentPadding
                    noLogoMessage={staticData.noProgramLogoMessage}
                />
            </div>
        </EvButton>
    );

    const getVendorList = () => (
        <div className={styles.vendorListContainer}>
            {currentVendors.map(getVendorCard)}
        </div>
    );

    return (
        <div className={styles.container}>
            {getHeader()}
            {currentVendors.length <= 0 && getNoVendorView()}
            {currentVendors.length > 0 && getVendorList()}
        </div>
    );
};

VendorProgramsDashboard.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    currentVendors: PropTypes.array,

    onNewVendorProgramClick: PropTypes.func,
    onVendorCardClick: PropTypes.func,
};

VendorProgramsDashboard.defaultProps = {
    controls: {},
    staticData: {},
    currentVendors: [],

    onNewVendorProgramClick: () => {},
    onVendorCardClick: () => {},
};

export default VendorProgramsDashboard;
