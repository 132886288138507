import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getData } from "../service/NotificationsService";
import API_URLS from "../../../services/apiUrls";
import { LOADING_STATUS, LIVE_STATUS } from "../../../common/static/Enums";
import { NOTIFICATIONS_PAGES } from "../static/NotificationsStatics";
import { isCompleted } from "../../../utils/CommonUtils";
import NotificationsActions from "../redux/NotificationsActions";
import {
    EvText,
    EvButton,
    EvTable,
    EvLoadingPage,
} from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";
import EvLogger from "../../../utils/EvLogger";

import "../styles/notifications-dashboard.scss";

const NotificationsDashboard = (props) => {
    const {
        clientCode,
        controls,
        staticData,
        setControls,
        getStaticData,
    } = props;

    const styles = {
        header: "ev__notifications-dashboard__header",
        description: "ev__notifications-dashboard__description",
        scheduleButton: "ev__notifications-dashboard__schedule-button",
    };

    const [notificationsData, setNotificationsData] = useState({});
    const [
        notificationsDataApiStatus,
        setNotificationsDataApiStatus,
    ] = useState(LOADING_STATUS.NOT_YET_STARTED);

    const getStaticDataLocal = useCallback(
        () =>
            getStaticData({
                url: API_URLS.NOTIFICATIONS.DASHBOARD.STATIC,
                templateId: NOTIFICATIONS_PAGES.DASHBOARD.id,
            }),
        [getStaticData]
    );

    const getDynamicData = useCallback(
        () =>
            getData(API_URLS.NOTIFICATIONS.DASHBOARD.NOTIFICATIONS_DATA, {
                clientCode,
            }),
        [clientCode]
    );

    useEffect(() => {
        setNotificationsDataApiStatus(LOADING_STATUS.LOADING);
        Promise.all([getStaticDataLocal(), getDynamicData()]).then(
            ([staticDataResponse, dynamicDataResponse]) => {
                setNotificationsData(dynamicDataResponse.data.data);
                setNotificationsDataApiStatus(LOADING_STATUS.COMPLETED);
            }
        );
    }, [getStaticDataLocal, getDynamicData, setNotificationsDataApiStatus]);

    const goToNotificationPage = useCallback(
        (notificationId, newPageId, notificationStatus) => {
            setControls({
                selectedPageId: newPageId,
                currentNotificationId: notificationId,
                currentNotificationPopulationId: "",
                tagSelectionCriteriaId: "",
                notificationStatus: notificationStatus || LIVE_STATUS.DRAFT,
            }); // clear notification data if any
        },
        [setControls]
    );

    const onScheduleButtonClick = useCallback(() => {
        goToNotificationPage("", NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id);
    }, [goToNotificationPage]);

    const onNotificationClick = useCallback(
        (e, notificationData) => {
            switch (notificationData.status) {
                case LIVE_STATUS.DRAFT:
                    goToNotificationPage(
                        notificationData.notificationId,
                        NOTIFICATIONS_PAGES.PROGRAM_SELECTION.id
                    );
                    break;
                case LIVE_STATUS.SCHEDULED:
                case LIVE_STATUS.LIVE:
                    goToNotificationPage(
                        notificationData.notificationId,
                        NOTIFICATIONS_PAGES.SUMMARY.id,
                        notificationData.status
                    );
                    break;

                default:
                    EvLogger.warn(
                        `NotificationDashboard openNotification: unknown status: ${notificationData.status}`,
                        "cc",
                        "click"
                    );
            }
        },
        [goToNotificationPage]
    );

    const renderHeader = () => (
        <div className={styles.header}>
            <EvText subHeading>{staticData.header}</EvText>
        </div>
    );

    const renderDescription = () => (
        <div className={styles.description}>
            <EvText default color={COLORS.BLACK}>
                {staticData.description}
            </EvText>
        </div>
    );

    const renderScheduleButton = () => (
        <EvButton
            primaryFilled
            className={styles.scheduleButton}
            onClickHandler={onScheduleButtonClick}
        >
            {staticData.scheduleCta.text}
        </EvButton>
    );

    const renderTableHeader = () => (
        <div className={styles.header}>
            <EvText subHeading>
                {staticData.latestUploadsStaticData.header}
            </EvText>
        </div>
    );

    const renderNotificationsTable = () =>
        notificationsData.notificationList.length > 0 ? (
            <EvTable
                tableFields={staticData.latestUploadsStaticData.tableFields}
                tableData={notificationsData.notificationList}
                loadMoreCta={staticData.latestUploadsStaticData.loadMoreCta}
                onRowClick={onNotificationClick}
                isRowClickable
            />
        ) : (
            <div>
                <EvText smallNormal italics>
                    {staticData.noNotificationsMessage}
                </EvText>
            </div>
        );

    const renderPageContent = () => (
        <div>
            {renderHeader()}
            {renderDescription()}
            {renderScheduleButton()}
            {renderTableHeader()}
            {renderNotificationsTable()}
        </div>
    );

    const isLoaded = isCompleted(
        controls.staticDataApiStatus[NOTIFICATIONS_PAGES.DASHBOARD.id],
        notificationsDataApiStatus
    );

    return isLoaded ? renderPageContent() : <EvLoadingPage />;
};

NotificationsDashboard.propTypes = {
    clientCode: PropTypes.string,
    staticData: PropTypes.object,
    controls: PropTypes.object,

    getStaticData: PropTypes.func,
    setControls: PropTypes.func,
};

NotificationsDashboard.defaultProps = {
    clientCode: "",
    staticData: {},
    controls: {},

    getStaticData: () => {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    staticData:
        state.NotificationsReducer.staticData[NOTIFICATIONS_PAGES.DASHBOARD.id],
    controls: state.NotificationsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(NotificationsActions.getStaticData(payload)),
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsDashboard);
