import { goBack } from "connected-react-router";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import stringTemplate from "string-template";
import {
    EvButton,
    EvIcon,
    EvLoader,
    EvLoadingPage,
    EvText,
    EvToast,
} from "../../../common/components";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import { EvFormRenderer } from "../../../common/components/form-components";
import CommonActions from "../../../common/redux/CommonActions";
import { getData, postData } from "../../../common/service/CommonService";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { COLORS } from "../../../common/static/VmsStatics";
import API_URLS from "../../../services/apiUrls";
import { isCompleted } from "../../../utils/CommonUtils";
import EvLogger from "../../../utils/EvLogger";
import {
    isFullFormValid,
    markAllFieldsUsed,
    parseFormDataForApi,
    parseInitialStateFromFormFields,
} from "../../../utils/FormUtils";
import {
    GAMEDAY_CONTENT_APP_ID,
    GAMEDAY_CONTENT_COMPONENTS,
    GAMEDAY_CONTENT_STATICS,
    GAMEDAY_CONTENT_TEMPLATES,
} from "../statics/GamedayContentStatics";
import "../styles/gameday-content-game.scss";

const styles = {
    container: "ev_gameday-content-game__container",
    header: {
        container: "ev_gameday-content-game__header-container",
    },
    form: {
        container: "ev_gameday-content-game__form-container",
    },
    actionView: {
        container: "ev_gameday-content-game__action-view-container",
    },
};

const GamedayContentGame = (props) => {
    const {
        controls,
        location,

        allStaticData,
        getStaticData,
        setDynamicDataApiStatus,
        goBackAction,
    } = props;

    const clientCode = useMemo(
        () =>
            location.state && location.state.clientCode
                ? location.state.clientCode
                : "",
        [location.state]
    );
    const vendorCardData = useMemo(
        () =>
            location.state && location.state.vendorCardData
                ? location.state.vendorCardData
                : {},
        [location.state]
    );

    const staticData =
        allStaticData[
            GAMEDAY_CONTENT_TEMPLATES.GAME_STATIC[vendorCardData.gameType].id
        ];

    const [formData, setFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState({});
    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const navigateToGameContentDashboard = useCallback(() => {
        goBackAction();
    }, [goBackAction]);

    const getAllData = useCallback(
        () =>
            Promise.all([
                getStaticData({
                    templateId:
                        GAMEDAY_CONTENT_TEMPLATES.GAME_STATIC[
                            vendorCardData.gameType
                        ].id,
                    url:
                        API_URLS.GAME_DAY_CONTENT.GAME.STATIC[
                            vendorCardData.gameType
                        ],
                }),
                getData(
                    API_URLS.GAME_DAY_CONTENT.GAME.PREFILL[
                        vendorCardData.gameType
                    ],
                    {
                        gameId: vendorCardData.gameId,
                        programId: vendorCardData.programId,
                        vendorId: vendorCardData.vendorId,
                        featureId: vendorCardData.featureId,
                        clientCode,
                    }
                ),
            ]),
        [
            getStaticData,
            clientCode,
            vendorCardData.programId,
            vendorCardData.featureId,
            vendorCardData.gameId,
            vendorCardData.gameType,
            vendorCardData.vendorId,
        ]
    );

    useEffect(() => {
        setDynamicDataApiStatus({
            [GAMEDAY_CONTENT_COMPONENTS.GAME_DATA.id]: LOADING_STATUS.LOADING,
        });
        getAllData()
            .then(([staticDataResponse, prefillDataResponse]) => {
                const parsedFormData = parseInitialStateFromFormFields(
                    staticDataResponse.formFields,
                    prefillDataResponse.data.data
                );
                setFormData(parsedFormData);
                setInitialFormData(prefillDataResponse.data.data);
                setDynamicDataApiStatus({
                    [GAMEDAY_CONTENT_COMPONENTS.GAME_DATA.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [GAMEDAY_CONTENT_COMPONENTS.GAME_DATA.id]:
                        LOADING_STATUS.FAILED,
                });
            });
    }, [getAllData, setDynamicDataApiStatus]);

    const onSubmitClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const isFormValid = isFullFormValid(formData);
        if (!isFormValid) {
            setFormData(markAllFieldsUsed(formData));
            EvToast.warn("", GAMEDAY_CONTENT_STATICS.FORM_INVALID);
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        const apiData = parseFormDataForApi(formData);
        postData(
            API_URLS.GAME_DAY_CONTENT.GAME.SUBMIT[vendorCardData.gameType],
            {
                clientCode,
            },
            {
                ...apiData,
                gameId: vendorCardData.gameId,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
                featureId: vendorCardData.featureId,
            }
        )
            .then((submitResponse) => {
                EvToast.success("Updated", "Game Content updated!", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                navigateToGameContentDashboard();
            })
            .catch((e) => {
                EvToast.error("Sorry", "Could not update data!", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "GamedayContentGame onSubmit");
            });
    }, [
        formData,
        clientCode,
        vendorCardData.programId,
        vendorCardData.vendorId,
        vendorCardData.gameId,
        vendorCardData.featureId,
        vendorCardData.gameType,
        submitStatus,
        navigateToGameContentDashboard,
    ]);

    // cleanup
    useEffect(
        () => () => {
            setDynamicDataApiStatus({
                [GAMEDAY_CONTENT_COMPONENTS.GAME_DATA.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText dangerous subHeading>
                {stringTemplate(staticData.header, vendorCardData)}
            </EvText>
        </div>
    );

    const getFormView = () => (
        <div className={styles.form.container}>
            <EvFormRenderer
                formFields={staticData.formFields}
                formData={formData}
                setFormDataState={setFormData}
                initialFormData={initialFormData}
            />
        </div>
    );

    const getStatusIcon = () => {
        switch (submitStatus) {
            case LOADING_STATUS.LOADING:
                return <EvLoader size={3} />;

            case LOADING_STATUS.COMPLETED:
                return (
                    <EvIcon
                        iconName="CHECK"
                        fillColor={COLORS.FRUIT_SALAD}
                        size={3}
                    />
                );

            case LOADING_STATUS.FAILED:
                return (
                    <EvIcon
                        iconName="ALERT"
                        fillColor={COLORS.RED_MONZA}
                        size={3}
                    />
                );

            default:
                return <span />;
        }
    };

    const getActionView = () => (
        <div className={styles.actionView.container}>
            <EvButton primaryFilled onClickHandler={onSubmitClick}>
                {staticData.submitCta.text}
            </EvButton>
            {getStatusIcon()}
        </div>
    );

    if (
        !isCompleted(
            controls.staticDataApiStatus[
                GAMEDAY_CONTENT_TEMPLATES.GAME_STATIC[vendorCardData.gameType]
                    .id
            ],
            controls.dynamicDataApiStatus[
                GAMEDAY_CONTENT_COMPONENTS.GAME_DATA.id
            ]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getFormView()}
            {getActionView()}
        </div>
    );
};

GamedayContentGame.propTypes = {
    allStaticData: PropTypes.object,
    controls: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    goBackAction: PropTypes.func,
};

GamedayContentGame.defaultProps = {
    allStaticData: {},
    controls: {},
    location: {},

    getStaticData: () => {},
    setDynamicDataApiStatus: () => {},
    goBackAction: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.CommonReducer[GAMEDAY_CONTENT_APP_ID].controls,
    allStaticData: state.CommonReducer[GAMEDAY_CONTENT_APP_ID].staticData,
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(
            CommonActions.getStaticData({
                ...payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    setDynamicDataApiStatus: (payload) =>
        dispatch(
            CommonActions.setDynamicDataApiStatus({
                data: payload,
                applicationId: GAMEDAY_CONTENT_APP_ID,
            })
        ),
    goBackAction: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GamedayContentGame);
