import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import classNames from "classnames";
import { Switch, Route } from "react-router-dom";

import {
    EvLibraryButtons,
    EvLibraryColors,
    EvLibraryForms,
    EvLibraryIcons,
    EvLibraryTypography,
    EvLibraryCards,
    EvLibraryCommon,
} from "./index";
import { EvButton, EvDivider, EvText } from "../../../common/components";
import { COMPONENTS_CATEGORIES } from "../statics/ComponentLibraryViewStatics";
import { isNullOrEmpty } from "../../../utils/CommonUtils";

import "../styles/component-library-view.scss";

const styles = {
    container: "ev__component-library-view__container",
    sidebar: {
        container: "ev__component-library-view__sidebar-container",

        divider: "ev__component-library-view__sidebar-divider",
    },

    sidebarHeader: {
        container: "ev__component-library-view__sidebar-header-container",
        icon: "ev__component-library-view__sidebar-header-icon",
    },

    category: {
        container: "ev__component-library-view__category-container",
        button: "ev__component-library-view__category-button",
        wrapper: "ev__component-library-view__category-wrapper",
        wrapperSelected:
            "ev__component-library-view__category-wrapper-selected",
    },
    content: {
        container: "ev__component-library-view__content-container",
        headerContainer: "ev__component-library-view__content-header-container",
        headerText: "ev__component-library-view__content-header-text",
        routeContainer: "ev__component-library-view__content-route-container",
    },
};

const ComponentLibraryView = (props) => {
    const { location, navigateToPage } = props;

    const selectedCategoryData = useMemo(() => {
        return location.state &&
            !isNullOrEmpty(location.state.selectedCategoryData)
            ? location.state.selectedCategoryData
            : {
                  name: "",
                  id: "",
                  path: "",
              };
    }, [location.state]);

    const onCategoryClick = useCallback(
        (e, callbackOptions) => {
            navigateToPage({
                path: callbackOptions.path,
                state: {
                    selectedCategoryData: callbackOptions,
                },
            });
        },
        [navigateToPage]
    );

    const getCategoryItems = (categoryItemKey) => {
        const categoryData = COMPONENTS_CATEGORIES[categoryItemKey];
        const wrapperCustomClass = classNames(styles.category.wrapper, {
            [styles.category.wrapperSelected]:
                categoryData.id === selectedCategoryData.id,
        });
        return (
            <EvButton
                key={categoryItemKey}
                onlyChild
                className={styles.category.button}
                callbackValues={categoryData}
                onClickHandler={onCategoryClick}
            >
                <div className={wrapperCustomClass}>
                    <EvText subHeading>{categoryData.name}</EvText>
                </div>
            </EvButton>
        );
    };

    const getSidebarView = () => (
        <div className={styles.sidebar.container}>
            <div className={styles.sidebarHeader.container}>
                <div className={styles.sidebarHeader.icon} />
                <EvText headingSecondary>Component Library</EvText>
            </div>
            <EvDivider className={styles.sidebar.divider} />
            <div className={styles.category.container}>
                {Object.keys(COMPONENTS_CATEGORIES).map(getCategoryItems)}
            </div>
        </div>
    );

    const getContent = () => (
        <div className={styles.content.container}>
            <div className={styles.content.headerContainer}>
                <EvText headingPrimary className={styles.content.headerText}>
                    {selectedCategoryData.name}
                </EvText>
            </div>
            <div className={styles.content.routeContainer}>
                <Switch>
                    <Route
                        path={COMPONENTS_CATEGORIES.ICONS.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryIcons {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.COLORS.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryColors {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.TYPOGRAPHY.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryTypography {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.BUTTONS.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryButtons {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.FORM_ITEMS.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryForms {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.CARDS.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryCards {...routeProps} />
                        )}
                    />
                    <Route
                        path={COMPONENTS_CATEGORIES.COMMON.path}
                        exact
                        render={(routeProps) => (
                            <EvLibraryCommon {...routeProps} />
                        )}
                    />
                </Switch>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {getSidebarView()}
            {getContent()}
        </div>
    );
};

ComponentLibraryView.propTypes = {
    location: PropTypes.object,
    navigateToPage: PropTypes.func,
};

ComponentLibraryView.defaultProps = {
    location: {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentLibraryView);
