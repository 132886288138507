const EvFormDropdownData = {
    data1: {
        label: "Filter by role user",
        placeholder: "All Clients",
        data: [
            {
                id: "dd_option_1",
                name: "Option 1",
                value: "options_1",
            },
            {
                id: "dd_option_2",
                name: "Option 2",
                value: "options_2",
            },
            {
                id: "dd_option_3",
                name: "Option 3",
                value: "options_3",
            },
            {
                id: "dd_option_4",
                name: "Option 4",
                value: "options_4",
            },
            {
                id: "dd_option_5",
                name: "Option 5",
                value: "options_5",
            },
        ],
    },
};

export default EvFormDropdownData;
