import EmployerCardActionTypes from "./EmployerCardActionTypes";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { isCompleted } from "../../../utils/CommonUtils";
import { getData } from "../service/EmployerCardService";

const EmployerCardActions = {
    setStaticDataApiStatus: (payload) => ({
        type: EmployerCardActionTypes.SET_STATIC_DATA_API_STATUS,
        payload,
    }),

    setDynamicDataApiStatus: (payload) => ({
        type: EmployerCardActionTypes.SET_DYNAMIC_DATA_API_STATUS,
        payload,
    }),

    setComponentData: (payload) => ({
        type: EmployerCardActionTypes.SET_COMPONENT_DATA,
        payload,
    }),

    setStaticData: (payload) => ({
        type: EmployerCardActionTypes.SET_STATIC_DATA,
        payload,
    }),

    getStaticData: (payload) => (dispatch, getState) => {
        // TODO: check this logic later
        // do not load static data if already in reducer
        if (
            isCompleted(
                getState().EmployerCardReducer.controls.staticDataApiStatus[
                    payload.templateId
                ]
            )
        ) {
            // aready in store
            return Promise.resolve(
                getState().EmployerCardReducer.staticData[payload.templateId]
            );
        }
        dispatch(
            EmployerCardActions.setStaticDataApiStatus({
                [payload.templateId]: LOADING_STATUS.LOADING,
            })
        );
        return getData(payload.url).then((response) => {
            dispatch(
                EmployerCardActions.setStaticData({
                    templateId: payload.templateId,
                    data: response.data.data,
                })
            );
            dispatch(
                EmployerCardActions.setStaticDataApiStatus({
                    [payload.templateId]: LOADING_STATUS.COMPLETED,
                })
            );
            return Promise.resolve(response.data.data);
        });
    },
};

export default EmployerCardActions;
