import { CSS_TRANSFORM_PROPERTIES } from "../common/static/Enums";

const setAnimationCallback = (
    ref,
    callback,
    property = CSS_TRANSFORM_PROPERTIES.OPACITY
) => {
    if (ref.current) {
        ref.current.addEventListener("transitionend", function cb(e) {
            if (e.propertyName === property) {
                // remove both
                ref.current.removeEventListener("animationend", cb);
                ref.current.removeEventListener("transitionend", cb);
                callback();
            }
        });
        ref.current.addEventListener("animationend", function cb() {
            // animation does not have an property
            // remove both
            ref.current.removeEventListener("animationend", cb);
            ref.current.removeEventListener("transitionend", cb);
            callback();
        });
    }
};

const setAnimationCallbackDirectRef = (
    ref,
    callback,
    property = CSS_TRANSFORM_PROPERTIES.OPACITY
) => {
    if (ref) {
        ref.addEventListener("transitionend", function cb(e) {
            if (e.propertyName === property) {
                ref.removeEventListener("transitionend", cb);
                callback();
            }
        });
    }
};

export { setAnimationCallback, setAnimationCallbackDirectRef };
