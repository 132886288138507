const WHERE_TO_APP_ID = "vendorProgramsCards__whereTo";

const WHERE_TO_TEMPLATES = {
    STATIC: {
        id: "whereTo__static",
    },
};

const WHERE_TO_STATICS = {
    FORM_INVALID: "Some of the form values are not valid",
};

export { WHERE_TO_APP_ID, WHERE_TO_TEMPLATES, WHERE_TO_STATICS };
