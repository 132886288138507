import React from "react";

import {
    EvButton,
    EvCategoryMenu,
    EvDivider,
    EvDropdownMenu,
    EvFullScreenOverlay,
    EvIcon,
    EvLoader,
    EvLoadingDots,
    EvSideNavBar,
    // EvSideNavBarList,
    EvSimpleModal,
    EvTabSelector,
    EvText,
    EvTextDivider,
    EvToast,
    EvTooltip,
} from "../../../common/components";
import { TOAST_DURATION } from "../../../common/components/EvToast";
import {
    CONFIRMATION_MODAL_OBJECTS,
    DROPDOWN_MENU_OBJECTS,
    FULL_SCREEN_OVERLAY_MODAL_OBJECTS,
    SAMPLE_CATEGORY_MENU_DATA,
    SAMPLE_TAB_SELECTOR_DATA,
    SIDE_NAVBAR_DATA,
    TOAST_OBJECTS,
} from "../statics/EvLibraryCommonStatics";

import "../styles/ev-library-common.scss";
import { COLORS } from "../../../common/static/VmsStatics";
import { DROP_DOWN_MENU_TYPE } from "../../../common/components/EvDropdownMenu";

const styles = {
    container: "ev__ev-library-common__container",
    component: {
        container: "ev__ev-library-common__component-container",
        itemsContainer: "ev__ev-library-common__component-items-container",
        itemWrapper: "ev__ev-library-common__component-item-wrapper",
    },
};

const EvLibraryCommon = () => {
    const getToastComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Ev Toast</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvButton primary onClickHandler={() => EvToast.clear()}>
                        Clear
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.info(
                                TOAST_OBJECTS.INFO_WITH_ICON_AND_HEADER,
                                TOAST_DURATION.NORMAL
                            )
                        }
                    >
                        Info with header and icon
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.info(
                                TOAST_OBJECTS.SIMPLE_INFO,
                                TOAST_DURATION.LONG
                            )
                        }
                    >
                        Simple Info
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.successAdv(
                                TOAST_OBJECTS.SIMPLE_SUCCESS,
                                TOAST_DURATION.NORMAL
                            )
                        }
                    >
                        Simple Success
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.errorAdv(
                                TOAST_OBJECTS.SIMPLE_ERROR,
                                TOAST_DURATION.SHORT
                            )
                        }
                    >
                        Simple Error
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.warnAdv(
                                TOAST_OBJECTS.SIMPLE_WARN,
                                TOAST_DURATION.SHORT
                            )
                        }
                    >
                        Simple Warn
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.errorAdv(
                                TOAST_OBJECTS.ERROR_WITH_ICON,
                                TOAST_DURATION.SHORT
                            )
                        }
                    >
                        Error with icon
                    </EvButton>
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvToast.warnAdv(
                                TOAST_OBJECTS.WARN_WITH_ICON,
                                TOAST_DURATION.SHORT
                            )
                        }
                    >
                        Warn with icon
                    </EvButton>
                </div>
            </div>
        </div>
    );

    const getTooltipComponent = () => {
        const customTooltipContent = () => (
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    border: "2px solid #ccc",
                    padding: 10,
                    display: "flex",
                }}
            >
                <EvIcon iconName="ALERT" fillColor={COLORS.RED_MONZA} />
                <EvText>Custom tooltip with icon</EvText>
            </div>
        );

        return (
            <div className={styles.component.container}>
                <EvText subHeading>Ev Tooltip</EvText>
                <EvDivider marginVertical={10} />
                <div className={styles.component.itemsContainer}>
                    <div className={styles.component.itemWrapper}>
                        <EvTooltip value="This is the tooltip content">
                            <EvText>Some text which has DEFAULT tooltip</EvText>
                        </EvTooltip>
                    </div>

                    <div className={styles.component.itemWrapper}>
                        <EvTooltip getCustomTooltipView={customTooltipContent}>
                            <EvText>Some text which has CUSTOM tooltip</EvText>
                        </EvTooltip>
                    </div>
                </div>
            </div>
        );
    };

    const getDropdownMenuComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Ev Dropdown Menu</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvDropdownMenu
                        label="Dropdown Left"
                        data={DROPDOWN_MENU_OBJECTS.data1.data}
                    />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvDropdownMenu
                        label="Dropdown Right"
                        data={DROPDOWN_MENU_OBJECTS.data1.data}
                        type={DROP_DOWN_MENU_TYPE.BOTTOM_RIGHT}
                    />
                </div>
            </div>
        </div>
    );

    const getLoaderComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>EvLoader</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvText>Default</EvText>
                    <EvLoader />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvText>Custom</EvText>
                    <EvLoader size={4} fillColor="red" />
                </div>
            </div>
        </div>
    );

    const getLoadingDotsComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>EvLoadingDots</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvLoadingDots />
                </div>
            </div>
        </div>
    );

    const getTextDivider = () => (
        <div className={styles.component.container}>
            <EvText subHeading>EvTextDivider</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div
                    style={{ minWidth: "50rem" }}
                    className={styles.component.itemWrapper}
                >
                    <EvTextDivider>OR</EvTextDivider>
                </div>
            </div>
        </div>
    );

    const getConfirmationModalComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Confirmation Modal</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvButton
                        primary
                        onClickHandler={() =>
                            EvSimpleModal.setData(
                                CONFIRMATION_MODAL_OBJECTS.data1
                            ).show()
                        }
                    >
                        Open Confirmation Modal
                    </EvButton>
                </div>
            </div>
        </div>
    );

    const getOverlayComponent = () => {
        const {
            header,
            description,
            options,
        } = FULL_SCREEN_OVERLAY_MODAL_OBJECTS.data1;
        return (
            <div className={styles.component.container}>
                <EvText subHeading>Full Screen Overlay</EvText>
                <EvText smallNormal>
                    Can be used to disable full screen while loading
                </EvText>
                <EvDivider marginVertical={10} />
                <div className={styles.component.itemsContainer}>
                    <div className={styles.component.itemWrapper}>
                        <EvButton
                            primary
                            onClickHandler={() =>
                                EvFullScreenOverlay.setData(
                                    header,
                                    description,
                                    {
                                        ...options,
                                        buttonAction: () =>
                                            EvFullScreenOverlay.hide(),
                                    }
                                ).show()
                            }
                        >
                            Open Overlay
                        </EvButton>
                    </div>
                </div>
            </div>
        );
    };

    const getEvCategoryMenu = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Category Menu</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvCategoryMenu
                        categoriesData={SAMPLE_CATEGORY_MENU_DATA}
                        onCategorySelect={(id) => console.log("SELECTED", id)}
                    />
                </div>
            </div>
        </div>
    );

    const getTabSelectorView = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Tab Selector</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvTabSelector tabsData={SAMPLE_TAB_SELECTOR_DATA} />
                </div>
            </div>
        </div>
    );

    // const getSideNavbarView = () => (
    //   <div className={styles.component.container}>
    //     <EvText subHeading>SideNavbar (DEPRECATED)</EvText>
    //     <EvDivider marginVertical={10} />
    //     <div className={styles.component.itemsContainer}>
    //       <div className={styles.component.itemWrapper}>
    //         <EvSideNavBarList
    //           header={SIDE_NAVBAR_DATA.header}
    //           listData={SIDE_NAVBAR_DATA.data}
    //           // selectedId={}
    //           // onClickHandler={null}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // );

    const getSideNavbarNEWView = () => (
        <div className={styles.component.container}>
            <EvText subHeading>SideNavbar</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvSideNavBar
                        header={SIDE_NAVBAR_DATA.header}
                        listData={SIDE_NAVBAR_DATA.data}
                        // selectedId={}
                        // onClickHandler={null}
                        onClickHandler={(e, cb) => console.log("CALLBACK", cb)}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {getToastComponent()}
            {getTooltipComponent()}
            {getDropdownMenuComponent()}
            {getLoaderComponent()}
            {getLoadingDotsComponent()}
            {getTextDivider()}
            {getConfirmationModalComponent()}
            {getOverlayComponent()}
            {getEvCategoryMenu()}
            {getTabSelectorView()}
            {/*{getSideNavbarView()}*/}
            {getSideNavbarNEWView()}
        </div>
    );
};

export default EvLibraryCommon;
