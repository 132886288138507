import {
    SAVED_POPULATION_DASHBOARD_DATA,
    NEW_EDIT_POPULATION_DATA,
    TAG_SELECTION_DATA,
    COMBINED_TAGS,
} from "../static/getSavedPopulationDataJSON";
import { apiGet, apiMock, apiPost, apiDelete } from "../../../services/api";
import EvLogger from "../../../utils/EvLogger";
import API_URLS from "../../../services/apiUrls";
import { COMMON_RESPONSE } from "../../notifications/static/getNotificationsDataJSON";

/* global USE_MOCK_DATA */

const getData = (url, params) => {
    switch (url) {
        // template data
        case API_URLS.SAVED_POPULATION.DASHBOARD.STATIC:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      SAVED_POPULATION_DASHBOARD_DATA.DASHBOARD.STATIC_DATA
                  )
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, NEW_EDIT_POPULATION_DATA.STATIC_DATA)
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION
            .CRITERIA_RULE_FORM_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NEW_EDIT_POPULATION_DATA.CRITERIA_RULE_FORM_DATA
                  )
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.TAG_SELECTION.STATIC:
            return USE_MOCK_DATA
                ? apiMock(url, params, TAG_SELECTION_DATA.PAGE_STATIC_DATA)
                : apiGet(url, params);

        // dynamic data
        case API_URLS.SAVED_POPULATION.DASHBOARD.POPULATION_LIST:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      SAVED_POPULATION_DASHBOARD_DATA.DASHBOARD.SAVED_POPULATION
                  )
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.POPULATION_DATA:
            return USE_MOCK_DATA
                ? apiMock(
                      url,
                      params,
                      NEW_EDIT_POPULATION_DATA.GET_POPULATION_DETAILS
                  )
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.ALL_TAGS:
            return USE_MOCK_DATA
                ? apiMock(url, params, COMBINED_TAGS)
                : apiGet(url, params);

        case API_URLS.SAVED_POPULATION.TAG_SELECTION.TAG_CATEGORIES:
            return USE_MOCK_DATA
                ? apiMock(url, params, TAG_SELECTION_DATA.CATEGORIES)
                : apiGet(url, params);

        default:
            EvLogger.warn("SavedPopulationService getData unknown URL:", url);
            return apiGet(url, params);
    }
};

const postData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.SAVE_POPULATION:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiPost(url, qParams, data);

        default:
            EvLogger.warn("SavedPopulationService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

const deleteData = (url, qParams, data) => {
    switch (url) {
        case API_URLS.SAVED_POPULATION.NEW_EDIT_POPULATION.DELETE_POPULATION:
            return USE_MOCK_DATA
                ? apiMock(url, qParams, COMMON_RESPONSE.successResponse, data)
                : apiDelete(url, qParams, data);

        default:
            EvLogger.warn("SavedPopulationService postData unknown URL:", url);
            return apiPost(url, qParams, data);
    }
};

export { getData, postData, deleteData };
