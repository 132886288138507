import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvText } from "../index";

import "../../styles/ev-cards/ev-card-image-landscape.scss";

const styles = {
    container: "ev__ev-card-image-landscape__container",
    imageView: {
        container: "ev__ev-card-image-landscape__image-view-container",
        image: "ev__ev-card-image-landscape__image-view-image",
        logoWrapper: "ev__ev-card-image-landscape__image-view-logo-wrapper",
        logo: "ev__ev-card-image-landscape__image-view-logo",
        backgroundImage: "ev__ev-card-image-landscape__image-view-bg-image",
    },
    content: {
        container: "ev__ev-card-image-landscape__content-container",
        header: "ev__ev-card-image-landscape__content-header",
        description: "ev__ev-card-image-landscape__content-description",
    },
};

const EvCardImageLandscape = (props) => {
    const {
        header,
        description,
        imageUrl, // DEPRECATED REMOVE LATER

        cardLogo,
        backgroundColor,
        backgroundImage,

        className,
        imageClassName,
        headerTextColor,
    } = props;

    const getOldImageFormat = () => {
        const customImageClassName = classNames(
            styles.imageView.image,
            imageClassName
        );
        return (
            <div className={styles.imageView.container}>
                <div
                    className={customImageClassName}
                    style={{ backgroundImage: `url(${imageUrl})` }}
                />
            </div>
        );
    };

    const getCardLogo = () => {
        const customImageClassName = classNames(
            styles.imageView.logo,
            imageClassName
        );
        return (
            <div
                className={styles.imageView.logoWrapper}
                style={{ backgroundColor }}
            >
                <div
                    className={customImageClassName}
                    style={{ backgroundImage: `url(${cardLogo})` }}
                />
            </div>
        );
    };

    const getBackgroundImage = () => {
        const customImageClassName = classNames(
            styles.imageView.backgroundImage,
            imageClassName
        );
        return (
            <div
                className={customImageClassName}
                style={{ backgroundImage: `url(${backgroundImage})` }}
            />
        );
    };

    const imageFormatDecider = () => {
        if (imageUrl) {
            return getOldImageFormat(); // TODO: deprecated remove later
        } else if (backgroundImage) {
            return getBackgroundImage();
        } else if (cardLogo) {
            return getCardLogo();
        }
        return null;
    };

    const getImageView = () => (
        <div className={styles.imageView.container}>{imageFormatDecider()}</div>
    );

    const getContentView = () => (
        <div className={styles.content.container}>
            <div className={styles.content.header}>
                <EvText defaultDarkBold color={headerTextColor}>
                    {header}
                </EvText>
            </div>
            <div className={styles.content.description}>
                <EvText>{description}</EvText>
            </div>
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            {getImageView()}
            {getContentView()}
        </div>
    );
};

EvCardImageLandscape.propTypes = {
    imageUrl: PropTypes.string, // DEPRECATED REMOVE LATER

    cardLogo: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundColor: PropTypes.string,

    header: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    imageClassName: PropTypes.string,
    headerTextColor: PropTypes.string,
};

EvCardImageLandscape.defaultProps = {
    imageUrl: "", // DEPRECATED REMOVE LATER

    cardLogo: "",
    backgroundImage: "",
    backgroundColor: "",

    header: "",
    description: "",
    className: "",
    imageClassName: "",
    headerTextColor: "#000a23",
};

export default EvCardImageLandscape;
