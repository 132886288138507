import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import VendorProgramsActions from "../../vendor-programs/redux/VendorProgramsActions";
import { LOADING_STATUS } from "../../../common/static/Enums";
import API_URLS from "../../../services/apiUrls";
import { getData, postData } from "../service/ProgramTagsService";
import { normalizerWithOrderArray } from "../../../utils/Normalizer";
import {
    parseFormDataToDirectArrayValues,
    parseInitialStateFromPillsInput,
} from "../../../utils/FormUtils";
import {
    guidGenerator,
    isCompleted,
    isNullOrEmpty,
} from "../../../utils/CommonUtils";
import { EvLoadingPage } from "../../../common/components";
import ProgramTagsView from "./ProgramTagsView";
import {
    PROGRAM_TAGS_COMPONENTS,
    PROGRAM_TAGS_TEMPLATES,
} from "../statics/ProgramTagsStatics";
import EvToast, {
    TOAST_ICON_OBJECTS,
} from "../../../common/components/EvToast";
import EvLogger from "../../../utils/EvLogger";

const ProgramTagsContainer = (props) => {
    const {
        controls,
        staticData,
        modalStaticData,
        allTags,
        location,

        // actions
        getStaticData,
        setComponentData,
        setDynamicDataApiStatus,
    } = props;

    const [selectedTagsFormData, setSelectedTagsFormData] = useState({});
    const [tagSubmitStatus, setTagSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCode =
        location.state && location.state.clientCode
            ? location.state.clientCode
            : "";
    const vendorCardData =
        location.state && location.state.vendorCardData
            ? location.state.vendorCardData
            : {};

    const parseSelectedTagData = useCallback((selectedRules, allTagsData) => {
        let formData = parseInitialStateFromPillsInput(
            selectedRules,
            allTagsData
        );
        // if not rows are there, add one row for default
        if (isNullOrEmpty(formData)) {
            formData = {
                [guidGenerator()]: {},
            };
        }
        setSelectedTagsFormData(formData);
    }, []);

    useEffect(() => {
        getStaticData({
            templateId: PROGRAM_TAGS_TEMPLATES.PROGRAM_TAGS_STATICS.id,
            url: API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.STATIC,
        });
        getStaticData({
            templateId: PROGRAM_TAGS_TEMPLATES.SELECTION_MODAL.id,
            url: API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SELECTION_MODAL_STATIC,
        });
    }, [getStaticData]);

    useEffect(() => {
        // extra call when going back
        if (!vendorCardData.programId) {
            return;
        }
        setDynamicDataApiStatus({
            [PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id]: LOADING_STATUS.LOADING,
        });
        Promise.all([
            getData(API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SELECTED_TAGS, {
                clientCode,
                programId: vendorCardData.programId,
                vendorId: vendorCardData.vendorId,
            }),
            getData(API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.ALL_TAGS, {
                clientCode,
            }),
        ])
            .then(([selectedTagsResponse, allTagsResponse]) => {
                const allTagsNormalized = normalizerWithOrderArray(
                    allTagsResponse.data.data.tags
                );
                parseSelectedTagData(
                    selectedTagsResponse.data.data.selectedRules,
                    allTagsNormalized.value
                );
                setComponentData({
                    componentId: PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id,
                    data: allTagsNormalized,
                });

                setDynamicDataApiStatus({
                    [PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id]:
                        LOADING_STATUS.COMPLETED,
                });
            })
            .catch((e) => {
                setDynamicDataApiStatus({
                    [PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id]:
                        LOADING_STATUS.FAILED,
                });
                EvLogger.errorWithObject(e, "ProgramTagsContainer getAllData");
            });
    }, [
        clientCode,
        vendorCardData.programId,
        vendorCardData.vendorId,
        setComponentData,
        setDynamicDataApiStatus,
        parseSelectedTagData,
    ]);

    const onFormDataChange = useCallback(
        (key, formItemObject, newFullFormData) => {
            setSelectedTagsFormData(newFullFormData);
        },
        []
    );

    const onSubmitTag = useCallback(() => {
        if (tagSubmitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        const parsedSelectedTagsForApi = parseFormDataToDirectArrayValues(
            selectedTagsFormData
        );
        const apiBodyData = {
            programId: vendorCardData.programId,
            vendorId: vendorCardData.vendorId,
            clientCode,
            selectedRules: parsedSelectedTagsForApi,
        };
        setTagSubmitStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.VENDOR_PROGRAMS.PROGRAM_TAGS.SUBMIT_TAGS,
            {},
            apiBodyData
        )
            .then((response) => {
                setTagSubmitStatus(LOADING_STATUS.COMPLETED);
                EvToast.success("Updated", "Program Tags Updated", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
            })
            .catch((e) => {
                setTagSubmitStatus(LOADING_STATUS.FAILED);
                EvToast.error("", "Error in saving data");
                EvLogger.errorWithObject(e, "ProgramTagsContainer onSubmitTag");
            });
    }, [
        tagSubmitStatus,
        selectedTagsFormData,
        clientCode,
        vendorCardData.programId,
        vendorCardData.vendorId,
    ]);

    if (
        !isCompleted(
            controls.dynamicDataApiStatus[PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id],
            controls.staticDataApiStatus[
                PROGRAM_TAGS_TEMPLATES.PROGRAM_TAGS_STATICS.id
            ],
            controls.staticDataApiStatus[
                PROGRAM_TAGS_TEMPLATES.SELECTION_MODAL.id
            ]
        )
    ) {
        return <EvLoadingPage animatedFadeIn />;
    }
    return (
        <ProgramTagsView
            staticData={staticData}
            modalStaticData={modalStaticData}
            formData={selectedTagsFormData}
            allTags={allTags}
            programName={vendorCardData.programName}
            vendorName={vendorCardData.vendorName}
            tagSubmitStatus={tagSubmitStatus}
            onFormDataChange={onFormDataChange}
            submitTags={onSubmitTag}
        />
    );
};

ProgramTagsContainer.propTypes = {
    controls: PropTypes.object,
    staticData: PropTypes.object,
    modalStaticData: PropTypes.object,
    allTags: PropTypes.object,
    location: PropTypes.object,

    getStaticData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    setComponentData: PropTypes.func,
};

ProgramTagsContainer.defaultProps = {
    controls: {},
    staticData: {},
    modalStaticData: {},
    allTags: {},
    location: {},

    // actions
    setDynamicDataApiStatus: () => {},
    getStaticData: () => {},
    setComponentData: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.VendorProgramsReducer.controls,
    staticData:
        state.VendorProgramsReducer.staticData[
            PROGRAM_TAGS_TEMPLATES.PROGRAM_TAGS_STATICS.id
        ],
    modalStaticData:
        state.VendorProgramsReducer.staticData[
            PROGRAM_TAGS_TEMPLATES.SELECTION_MODAL.id
        ],
    allTags:
        state.VendorProgramsReducer.dynamicData[
            PROGRAM_TAGS_COMPONENTS.ALL_TAGS.id
        ],
    location: state.router.location,
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) =>
        dispatch(VendorProgramsActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(VendorProgramsActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(VendorProgramsActions.setComponentData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgramTagsContainer);
