import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../../styles/ev-cards/ev-game-content-card.scss";
import { EvSimpleCard, EvText } from "../index";

const styles = {
    container: "ev__ev-game-content-card__container",
    card: "ev__ev-game-content-card__card",
    innerContainer: "ev__ev-game-content-card__inner-container",
    imageView: {
        container: "ev__ev-game-content-card__image-view-container",
        image: "ev__ev-game-content-card__image-view-image",
    },
    content: {
        container: "ev__ev-game-content-card__content-container",
        headerText: "ev__ev-game-content-card__content-header-text",
        descriptionText: "ev__ev-game-content-card__content-description-text",
    },
    subContent: {
        container: "ev__ev-game-content-card__sub-content-container",
        headerText: "ev__ev-game-content-card__sub-content-header-text",
        descriptionText:
            "ev__ev-game-content-card__sub-content-description-text",
    },
};

const EvGameContentCard = (props) => {
    const {
        imageUrl,
        header,
        description,
        subHeader1,
        subHeader2,
        content1,
        content2,

        className,
    } = props;

    const getImageView = () => (
        <div className={styles.imageView.container}>
            <div
                className={styles.imageView.image}
                style={{ backgroundImage: `url(${imageUrl})` }}
            />
        </div>
    );

    const getSubContentView = (subContentHeader, subContentDescription) => (
        <div className={styles.subContent.container}>
            <EvText className={styles.subContent.headerText} defaultBold>
                {subContentHeader}
            </EvText>
            <EvText className={styles.subContent.descriptionText}>
                {subContentDescription}
            </EvText>
        </div>
    );

    const getContentView = () => (
        <div className={styles.content.container}>
            {header && (
                <EvText className={styles.content.headerText} defaultBold>
                    {header}
                </EvText>
            )}
            {description && (
                <EvText className={styles.content.descriptionText}>
                    {description}
                </EvText>
            )}
            {subHeader1 && content1 && getSubContentView(subHeader1, content1)}
            {subHeader2 && content2 && getSubContentView(subHeader2, content2)}
        </div>
    );

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvSimpleCard noPadding className={styles.card}>
                <div className={styles.innerContainer}>
                    {imageUrl && getImageView()}
                    {getContentView()}
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvGameContentCard.propTypes = {
    imageUrl: PropTypes.string,
    header: PropTypes.string,
    description: PropTypes.string,
    subHeader1: PropTypes.string,
    content1: PropTypes.string,
    subHeader2: PropTypes.string,
    content2: PropTypes.string,

    className: PropTypes.string,
};

EvGameContentCard.defaultProps = {
    imageUrl: "",
    header: "",
    description: "",
    subHeader1: "",
    content1: "",
    subHeader2: "",
    content2: "",

    className: "",
};

export default EvGameContentCard;
