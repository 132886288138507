import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationsActions from "../redux/EnterpriseNotificationsActions";
import { getData, postData } from "../service/enterpriseNotificationsService";
import API_URLS from "../../../services/apiUrls";
import {
    DEFAULT_LOADING_TEXT,
    LIVE_STATUS,
    LOADING_STATUS,
} from "../../../common/static/Enums";
import { isCompleted } from "../../../utils/CommonUtils";
import {
    EvButton,
    EvDivider,
    EvHeaderView,
    EvLoader,
    EvLoadingPage,
    EvSummary,
    EvText,
    EvToast,
} from "../../../common/components";
import EvLogger from "../../../utils/EvLogger";
import { ENTERPRISE_NOTIFICATIONS_PAGES } from "../static/enterpriseNotificationsStatics";
import EvPills from "../../../common/components/form-components/EvPills";

import staticData from "../static/json/summary-form.json";

import "../styles/enterprise-notifications-summary.scss";

const styles = {
    container: "ev__enterprise-notifications-summary__container",
    scheduleButton: "ev__enterprise-notifications-summary__schedule-button",
    header: {
        container: "ev__enterprise-notifications-summary__header-container",
        text: "ev__enterprise-notifications-summary__header-text",
    },
    pill: {
        container: "ev__enterprise-notifications-summary__pill-container",
        item: "ev__enterprise-notifications-summary__pill-item",
    },
    segments: {
        container: "ev__enterprise-notifications-summary__segment-container",
    },
    footer: {
        container: "ev__enterprise-notifications-summary__footer-container",
    },
    actionView: {
        container:
            "ev__enterprise-notifications-summary__action-view-container",
        loading: "ev__enterprise-notifications-summary__action-view-loading",
    },
};

const EnterpriseNotificationsSummary = (props) => {
    const { clientCodes, controls, setControls } = props;

    const notificationId = useMemo(() => controls.currentNotificationId || "", [
        controls.currentNotificationId,
    ]);

    const [summaryData, setSummaryData] = useState({});
    const [summaryDataApiStatus, setSummaryDataApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const [submitStatus, setSubmitStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );

    const clientCodesStringValue = useMemo(() => {
        return clientCodes.join(",");
    }, [clientCodes]);

    useEffect(() => {
        getData(API_URLS.ENTERPRISE_NOTIFICATIONS.SUMMARY.SUMMARY_DATA, {
            notificationId,
            clientCodes: clientCodesStringValue,
            populationId: "",
        })
            .then((summaryDataResponse) => {
                setSummaryData(summaryDataResponse.data.data);
                setSummaryDataApiStatus(LOADING_STATUS.COMPLETED);
            })
            .catch((e) => {
                setSummaryDataApiStatus(LOADING_STATUS.FAILED);
                EvLogger.errorWithObject(e, "NotificationSummary getData");
            });
    }, [notificationId, clientCodesStringValue]);

    const onSegmentEditClick = useCallback(
        (actionPage) => {
            setControls({
                selectedPageId: actionPage,
            });
        },
        [setControls]
    );

    const onScheduleClick = useCallback(() => {
        if (submitStatus === LOADING_STATUS.LOADING) {
            return;
        }
        setSubmitStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.ENTERPRISE_NOTIFICATIONS.SUMMARY.POST_PUBLISH_NOTIFICATION,
            {
                notificationId,
                clientCodes: clientCodesStringValue,
            }
        )
            .then((response) => {
                EvToast.success(
                    "Success",
                    "enterprise notifications published successfully"
                );
                setSubmitStatus(LOADING_STATUS.COMPLETED);
                setControls({
                    selectedPageId:
                        ENTERPRISE_NOTIFICATIONS_PAGES.CLIENT_SELECTION.id,
                });
            })
            .catch((e) => {
                setSubmitStatus(LOADING_STATUS.FAILED);
                EvToast.error("Sorry", "Something went wrong");
                EvLogger.errorWithObject(e, "NotificationSummary onSchedule");
            });
    }, [submitStatus, notificationId, clientCodesStringValue, setControls]);

    const getSelectedClientsView = () => {
        return (
            <div className={styles.pill.container}>
                <EvHeaderView header="Selected Clients" />
                {clientCodes.map((client) => (
                    <EvPills
                        buttonClassName={styles.pill.item}
                        key={client}
                        id={client}
                        value={client}
                    />
                ))}
            </div>
        );
    };

    const getSegmentView = (segmentData) => (
        <div className={styles.segments.container}>
            <EvDivider />
            <EvSummary
                id={segmentData.id}
                segmentStaticData={segmentData}
                data={summaryData[segmentData.id]}
                onSegmentEditClick={onSegmentEditClick}
                editable={controls.notificationStatus === LIVE_STATUS.DRAFT}
                // allTags={allTags.value}
                qParams={{
                    clientCodes: clientCodesStringValue,
                    notificationId,
                }}
            />
        </div>
    );

    const getSegments = () => (
        <div className={styles.segments.container}>
            {staticData.segments.map(getSegmentView)}
        </div>
    );

    const getPublishButton = () => (
        <div className={styles.actionView.container}>
            <EvButton
                primaryFilled
                className={styles.scheduleButton}
                onClickHandler={onScheduleClick}
            >
                {staticData.sendCta.text}
            </EvButton>
            {submitStatus === LOADING_STATUS.LOADING && (
                <EvLoader size={3} className={styles.actionView.loading} />
            )}
        </div>
    );

    const getHeaderView = () => (
        <div className={styles.header.container}>
            <EvText className={styles.header.text} subHeading>
                {staticData.header}
            </EvText>
            <EvText className={styles.header.text}>
                {staticData.description}
            </EvText>
            {controls.notificationStatus === LIVE_STATUS.DRAFT &&
                getPublishButton()}
        </div>
    );

    const getFooter = () => (
        <div className={styles.footer.container}>
            {controls.notificationStatus === LIVE_STATUS.DRAFT &&
                getPublishButton()}
        </div>
    );

    if (!isCompleted(summaryDataApiStatus)) {
        return (
            <EvLoadingPage
                loadingText={staticData.loadingText || DEFAULT_LOADING_TEXT}
            />
        );
    }

    return (
        <div className={styles.container}>
            {getHeaderView()}
            {getSelectedClientsView()}
            {getSegments()}
            {getFooter()}
        </div>
    );
};

EnterpriseNotificationsSummary.propTypes = {
    clientCodes: PropTypes.array,
    controls: PropTypes.object,
    setControls: PropTypes.func,
};

EnterpriseNotificationsSummary.defaultProps = {
    clientCodes: [],
    controls: {},
    setControls: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.EnterpriseNotificationsReducer.controls,
    clientCodes: state.EnterpriseNotificationsReducer.selectedClientCodes,
});

const mapDispatchToProps = (dispatch) => ({
    setControls: (payload) =>
        dispatch(NotificationsActions.setControls(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseNotificationsSummary);
