import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import CommonReducer from "../common/redux/CommonReducer";
import AppContainerReducer from "../features/app-container/redux/AppContainerReducer";
import AppCommonReducer from "../features/app/redux/AppCommonReducer";
import ClientCardsReducer from "../features/client-cards/redux/ClientCardsReducer";
import ClientContentReducer from "../features/client-content/redux/ClientContentReducer";
import ClientDetailsReducer from "../features/client-dashboard/redux/ClientDetailsReducer";
import ClientReducer from "../features/client/redux/ClientReducer";
import DashboardReducer from "../features/dashboard/redux/DashboardReducer";
import EmployerCardReducer from "../features/employer-card/redux/EmployerCardReducer";
import GamedayScheduleReducer from "../features/gameday-schedule/redux/GamedayScheduleReducer";
// import UserMappingReducer from "../features/user-mapping/redux/UserMappingReducer";
import NotificationsReducer from "../features/notifications/redux/NotificationsReducer";
import PreProdReducer from "../features/pre-prod/redux/PreProdReducer";
import SavedPopulationReducer from "../features/saved-population/redux/SavedPopulationReducer";
import VendorProgramsReducer from "../features/vendor-programs/redux/VendorProgramsReducer";
import CacheReducer from "./cache/CacheReducer";
import EnterpriseNotificationsReducer from "../features/enterprise-notifications/redux/EnterpriseNotificationsReducer";

const reducer = (history) =>
    combineReducers({
        AppContainerReducer,
        DashboardReducer,
        ClientReducer,
        ClientDetailsReducer,
        VendorProgramsReducer,
        ClientContentReducer,
        // UserMappingReducer,
        NotificationsReducer,
        EmployerCardReducer,
        SavedPopulationReducer,
        PreProdReducer,
        CommonReducer,
        CacheReducer,
        GamedayScheduleReducer,
        ClientCardsReducer,
        AppCommonReducer,
        EnterpriseNotificationsReducer,
        router: connectRouter(history),
    });

export default reducer;

// export default combineReducers(history => ({
//   AppContainerReducer,
//   DashboardReducer,
//   ClientDetailsReducer,
//   router: connectRouter(history),
// }));
