import PropTypes from "prop-types";
import React, { memo } from "react";
import { EvText, EvTicketCard, EvTooltip } from ".";
// import { COLORS } from "../static/VmsStatics";
import "../styles/ev-client-ticket-card.scss";

const EvClientTicketCard = (props) => {
    const styles = {
        container: "ev__ev-client-ticket-card__container",
        content: {
            container: "ev__ev-client-ticket-card__content-container",
            overflowGradient:
                "ev__ev-client-ticket-card__content-overflow-gradient",
        },
        footer: {
            container: "ev__ev-client-ticket-card__footer-container",
            userContainer: "ev__ev-client-ticket-card__footer-user-container",
            statusContainer:
                "ev__ev-client-ticket-card__footer-status-container",
            bubble: "ev__ev-client-ticket-card__footer-status-bubble",
        },
    };

    const getContentView = (contentProps) => (
        <div className={styles.content.container}>
            <EvTooltip value={contentProps.clientName} offsetTop={30}>
                <EvText headingSecondary>{contentProps.clientName}</EvText>
            </EvTooltip>
            <EvText>{contentProps.updatedDate}</EvText>
            <div className={styles.content.overflowGradient} />
        </div>
    );

    // const getFooterView = footerProps => (
    //   <div className={styles.footer.container}>
    //     <div className={styles.footer.userContainer}>
    //       <EvIcon
    //         innerContainerInlineStyle={{ marginLeft: 0 }}
    //         iconName={footerProps.userCount.iconName}
    //         size={3}
    //         fillColor={COLORS.BLACK_TROUT}
    //       />
    //       <EvText defaultBold>{footerProps.userCount.text}</EvText>
    //     </div>
    //     <div className={styles.footer.statusContainer}>
    //       <div className={styles.footer.bubble}>
    //         <EvText defaultBold primaryColored>
    //           {footerProps.status}
    //         </EvText>
    //       </div>
    //     </div>
    //   </div>
    // );

    const { imgUrl, clientName, updatedDate, backgroundColor } = props;

    return (
        <div className={styles.container}>
            <EvTicketCard
                imgUrl={imgUrl}
                backgroundColor={backgroundColor}
                showBackgroundOnNoImage
                content={getContentView({ clientName, updatedDate })}
            />
        </div>
    );
};

EvClientTicketCard.propTypes = {
    imgUrl: PropTypes.string,
    clientName: PropTypes.string,
    updatedDate: PropTypes.string,
    backgroundColor: PropTypes.string,
};

EvClientTicketCard.defaultProps = {
    imgUrl: "",
    clientName: "",
    updatedDate: "",
    backgroundColor: "",
};

export default memo(EvClientTicketCard);
