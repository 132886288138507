import React, { memo } from "react";
import PropTypes from "prop-types";

import { EvText, EvSimpleCard, EvButton, EvIcon } from ".";
import { COLORS } from "../static/VmsStatics";

import "../styles/ev-cta-preview.scss";

const EvCtaPreview = (props) => {
    const styles = {
        ctaCard: {
            mainContainer: "ev__cta-preview__main-container",
            contentContainer: "ev__cta-preview__content-container",
            headerContainer: "ev__cta-preview__header-container",
            imageHolder: "ev__cta-preview__image-holder",
            image: "ev__cta-preview__image",
            titleContainer: "ev__cta-preview__title-container",
            ctaButtonContainer: "ev__cta-preview__cta-button-container",
            descriptionContainer: "ev__cta-preview__description-container",
            backButtonWrapper: "ev__cta-preview__back-button-wrapper",
            iconWrapper: "ev__cta-preview__icon-wrapper",
            divider: "ev__cta-preview__divider",
        },
    };

    const { heading, description, imgUrl, cta } = props;

    return (
        <div className={styles.ctaCard.mainContainer}>
            <EvSimpleCard noPadding>
                <div className={styles.ctaCard.contentContainer}>
                    <div className={styles.ctaCard.headerContainer}>
                        <EvButton onlyChild>
                            <div className={styles.ctaCard.backButtonWrapper}>
                                <EvIcon
                                    iconName="ARROW_THICK_LEFT"
                                    bgColor={COLORS.BLACK_TROUT}
                                    fillColor={COLORS.WHITE}
                                    circular
                                    size={3}
                                    outerContainerStyleClass={
                                        styles.ctaCard.iconWrapper
                                    }
                                />
                            </div>
                        </EvButton>
                        <div className={styles.ctaCard.imageHolder}>
                            <div
                                role="img"
                                aria-label="program logo"
                                className={styles.ctaCard.image}
                                style={{ backgroundImage: `url(${imgUrl})` }}
                            />
                        </div>
                        <EvButton onlyChild>
                            <div className={styles.ctaCard.backButtonWrapper}>
                                <EvIcon
                                    size={5}
                                    iconName="HEART_FILLED"
                                    fillColor={COLORS.BLACK_TROUT}
                                    outerContainerStyleClass={
                                        styles.ctaCard.iconWrapper
                                    }
                                />
                            </div>
                        </EvButton>
                    </div>
                    <div className={styles.ctaCard.titleContainer}>
                        <EvText headingSecondary>{heading}</EvText>
                    </div>
                    <div className={styles.ctaCard.ctaButtonContainer}>
                        <EvText defaultDarkBold color={COLORS.WHITE}>
                            {cta}
                        </EvText>
                    </div>
                </div>
                <div className={styles.ctaCard.divider} />
                <div className={styles.ctaCard.descriptionContainer}>
                    <EvText default>{description}</EvText>
                </div>
            </EvSimpleCard>
        </div>
    );
};

EvCtaPreview.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    imgUrl: PropTypes.string,

    cta: PropTypes.string,
};

EvCtaPreview.defaultProps = {
    heading: "",
    description: "",
    imgUrl: "",

    cta: "",
};

export default memo(EvCtaPreview);
