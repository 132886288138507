import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import stringTemplate from "string-template";

import { EvDropdownComponent, EvInputErrorView } from "./index";
import FormValidation from "../../../utils/FormValidations";
import { isNullOrEmpty } from "../../../utils/CommonUtils";
import { EvButton } from "../index";

import "../../styles/form-components/ev-prefix-text-input.scss";

const styles = {
    container: "ev__ev-prefix-text-input__container",
    innerContainer: "ev__ev-prefix-text-input__inner-container",
    prefix: {
        container: "ev__ev-prefix-text-input__prefix-container",
    },
    input: {
        container: "ev__ev-prefix-text-input__input-container",
        inputBox: "ev__ev-prefix-text-input__input-box",
    },
    linkButton: "ev__ev-prefix-text-input__link-button",
};

const EvPrefixTextInput = (props) => {
    const {
        keyName,
        className,
        placeholder,
        type,
        value,
        editable,
        showError,
        formData,
        formItem,
        handleInputChange,
    } = props;

    const getPrefixFromString = useCallback(
        (stringValue) => {
            const currentObject = formItem.data.find(
                (dataItem) =>
                    dataItem.value ===
                    stringValue.substring(0, dataItem.value.length)
            );
            return currentObject;
        },
        [formItem]
    );

    const selectedDropdownObject = useMemo(() => {
        return getPrefixFromString(value) || null;
    }, [value, getPrefixFromString]);

    const inputValueString = useMemo(() => {
        if (
            selectedDropdownObject &&
            value.substring(0, selectedDropdownObject.value.length) ===
                selectedDropdownObject.value
        ) {
            return value.substring(selectedDropdownObject.value.length);
        }
        return value;
    }, [value, selectedDropdownObject]);

    const handleInputChangeLocal = useCallback(
        (newValue, onBlur = false) => {
            const inputFieldObject = {
                [keyName]: {
                    key: keyName,
                    value: newValue,
                    // type,
                },
                errorObject: {}, // will be overwritten later
                formItemType: type,
            };
            handleInputChange(keyName, {
                ...inputFieldObject,
                errorObject: {
                    ...formData.errorObject,
                    ...FormValidation(formItem, inputFieldObject),
                    ...(onBlur ? { isUsed: true } : {}),
                },
            });
        },
        [keyName, formData, handleInputChange, formItem, type]
    );

    const onChange = useCallback(
        (e) => {
            let newValue = "";
            const prefixInString = getPrefixFromString(e.target.value);
            if (!isNullOrEmpty(prefixInString)) {
                newValue = e.target.value;
            } else {
                newValue = !isNullOrEmpty(selectedDropdownObject)
                    ? `${selectedDropdownObject.value}${e.target.value}`
                    : e.target.value;
            }

            handleInputChangeLocal(newValue);
        },
        [handleInputChangeLocal, selectedDropdownObject, getPrefixFromString]
    );

    const onBlur = useCallback(
        (e) => {
            const newValue = !isNullOrEmpty(selectedDropdownObject)
                ? `${selectedDropdownObject.value}${e.target.value}`
                : e.target.value;
            handleInputChangeLocal(newValue, true);
        },
        [handleInputChangeLocal, selectedDropdownObject]
    );

    const onDropdownChange = useCallback(
        (ddId, ddName, ddValue) => {
            const newValue = `${ddValue}${inputValueString}`;
            handleInputChangeLocal(newValue);
        },
        [handleInputChangeLocal, inputValueString]
    );

    const onExternalLinkClick = useCallback(() => {
        window.open(value, "_blank");
    }, [value]);

    const getPrefixDropDown = () => (
        <EvDropdownComponent
            className={styles.prefix.container}
            minWidth={50}
            dataList={formItem.data}
            placeholder={formItem.prefixPlaceholder}
            editable={editable}
            onChange={onDropdownChange}
            selectedObject={selectedDropdownObject}
        />
    );

    const getInputField = () => (
        <div className={styles.input.container}>
            <input
                className={styles.input.inputBox}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={inputValueString}
                disabled={!editable}
            />
        </div>
    );

    const getErrorView = () => (
        <EvInputErrorView errorObject={formData.errorObject} />
    );

    const getLinkButton = () => {
        if (
            formItem.options &&
            formItem.options.hrefPreview &&
            formItem.options.hrefPreviewCtaText
        ) {
            return (
                <EvButton
                    onClickHandler={onExternalLinkClick}
                    className={styles.linkButton}
                    linkButton
                >
                    {stringTemplate(formItem.options.hrefPreviewCtaText, {
                        href: value,
                    })}
                </EvButton>
            );
        }
        return null;
    };

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <div className={styles.innerContainer}>
                {getPrefixDropDown()}
                {getInputField()}
            </div>
            {inputValueString && getLinkButton()}
            {showError && getErrorView()}
        </div>
    );
};
EvPrefixTextInput.propTypes = {
    keyName: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    editable: PropTypes.bool,
    showError: PropTypes.bool,
    formItem: PropTypes.object,
    formData: PropTypes.object,

    handleInputChange: PropTypes.func,
};

EvPrefixTextInput.defaultProps = {
    keyName: "",
    className: "",
    placeholder: "",
    value: "",
    type: "",
    editable: true,
    showError: true,
    formItem: {},
    formData: {},

    handleInputChange: () => {},
};

export default EvPrefixTextInput;
