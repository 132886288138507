import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import CardsContentRoutes from "../../cards-dashboard/views/CardsContentRoutes";
import { CLIENT_CONTENT_PAGES } from "../../client-content/static/ClientContentStatics";
import ClientCardsActions from "../redux/ClientCardsActions";
import { CLIENT_CARDS_ROUTES } from "../statics/ClientCardsStatics";
import ClientCardsCategoriesView from "./ClientCardsCategoriesView";
import ClientCardsDashboard from "./ClientCardsDashboard";
import ClientCardsOrderView from "./ClientCardsOrderView";

const ClientCardsRoutes = (props) => {
    const { softReset } = props;

    useEffect(
        () => () => {
            softReset();
        },
        [softReset]
    );

    const getRoute = () => (
        <div>
            <Switch>
                <Route
                    path={CLIENT_CARDS_ROUTES.CATEGORIES.path}
                    render={() => <ClientCardsCategoriesView />}
                />
                <Route
                    path={CLIENT_CARDS_ROUTES.REORDER.path}
                    render={() => <ClientCardsOrderView />}
                />
                <Route
                    path={CLIENT_CARDS_ROUTES.CONTENT.path}
                    render={() => <CardsContentRoutes />}
                />
                <Route
                    path={CLIENT_CONTENT_PAGES.CARDS_DASHBOARD.path}
                    render={() => <ClientCardsDashboard />}
                />
                <Route
                    path={CLIENT_CARDS_ROUTES.DASHBOARD.path}
                    render={() => <ClientCardsDashboard />}
                />
            </Switch>
        </div>
    );

    return getRoute();
};

ClientCardsRoutes.propTypes = {
    softReset: PropTypes.func,
};

ClientCardsRoutes.defaultProps = {
    softReset: () => {},
};

const mapStateToProps = (state) => ({
    // controls: state.ClientCardsReducer.controls,
});

const mapDispatchToProps = (dispatch) => ({
    softReset: (payload) => dispatch(ClientCardsActions.softReset(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientCardsRoutes);
