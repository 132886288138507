import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { EvButton, EvIcon } from "./index";
import { COLORS } from "../static/VmsStatics";
import { KEY_CODES } from "../static/Enums";

import "../styles/ev-search-bar.scss";

const styles = {
    container: "ev__ev-search-bar__container",
    inputBox: "ev__ev-search-bar__input-box",
};

const EvSearchBar = (props) => {
    const { placeholder, className, onChange, triggerDelay, minChars } = props;

    const [searchValue, setSearchValue] = useState("");
    const intervalId = useRef(null);

    const onSearchKeyChange = useCallback(
        (currentSearchKey, instant = false) => {
            setSearchValue(currentSearchKey);
            if (currentSearchKey.trim().length < minChars) {
                onChange("");
                clearTimeout(intervalId.current);
                return;
            }
            if (intervalId.current) {
                clearTimeout(intervalId.current);
            }

            if (instant) {
                onChange(currentSearchKey);
            } else {
                intervalId.current = setTimeout(() => {
                    onChange(currentSearchKey);
                }, triggerDelay);
            }
        },
        [onChange, triggerDelay, minChars]
    );

    const onChangeLocal = useCallback(
        (e) => {
            const { value } = e.target;
            onSearchKeyChange(value);
        },
        [onSearchKeyChange]
    );

    const onKeyDown = useCallback(
        (e) => {
            switch (e.keyCode) {
                case KEY_CODES.ENTER:
                    onSearchKeyChange(searchValue, true);
                    break;
                default:
            }
        },
        [onSearchKeyChange, searchValue]
    );

    const onSearchIconClick = useCallback(() => {
        onSearchKeyChange(searchValue, true);
    }, [onSearchKeyChange, searchValue]);

    const onClearClick = useCallback(() => {
        setSearchValue("");
        clearTimeout(intervalId.current);
        onChange("");
    }, [onChange]);

    const customContainerClass = classNames(styles.container, className);

    return (
        <div className={customContainerClass}>
            <EvButton onlyChild onClickHandler={onSearchIconClick}>
                <EvIcon
                    iconName="SEARCH"
                    fillColor={COLORS.WHITE_GHOST}
                    size={5}
                />
            </EvButton>
            <input
                value={searchValue}
                className={styles.inputBox}
                placeholder={placeholder}
                onChange={onChangeLocal}
                onKeyDown={onKeyDown}
            />
            <EvButton onlyChild onClickHandler={onClearClick}>
                <EvIcon
                    iconName="CROSS_THIN"
                    fillColor={COLORS.WHITE_GHOST}
                    size={3}
                />
            </EvButton>
        </div>
    );
};

EvSearchBar.propTypes = {
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    triggerDelay: PropTypes.number,
    minChars: PropTypes.number,
};

EvSearchBar.defaultProps = {
    placeholder: "",
    className: "",
    onChange: () => {},
    triggerDelay: 500,
    minChars: 3,
};

export default EvSearchBar;
