import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Base64 } from "js-base64";

import PreProdActions from "../redux/PreProdActions";
import { getData, postData } from "../service/PreProdService";
import API_URLS from "../../../services/apiUrls";
import { LOADING_STATUS } from "../../../common/static/Enums";
import { normalizerWithOrderArraySingleLevel } from "../../../utils/Normalizer";
import {
    addDataToAllObjectInArray,
    isCompleted,
    queryStringGenerator,
} from "../../../utils/CommonUtils";
import {
    EvLoadingPage,
    EvSimpleModal,
    EvToast,
} from "../../../common/components";
import PreProdView from "./PreProdView";
import { TOAST_ICON_OBJECTS } from "../../../common/components/EvToast";
import EvLogger from "../../../utils/EvLogger";
import { getApprovalCardIdsFromPreProdList } from "../../../utils/SpecificDataModelUtils";
import {
    PRE_PROD_CARD_STATUS,
    PRE_PROD_COMPONENTS,
    PRE_PROD_TAB_IDS,
    PRE_PROD_TEMPLATES,
} from "../statics/PreProdStatics";

Base64.extendString();

const PreProdContainer = (props) => {
    const {
        clientCode,
        clientName,
        controls,
        staticData,
        preApprovals,
        approvedCardList,

        // actions
        getStaticData,
        setComponentData,
        setDynamicDataApiStatus,
        resetData,
        navigateToPage,
    } = props;

    const [approveAllApiStatus, setApproveAllApiStatus] = useState(
        LOADING_STATUS.NOT_YET_STARTED
    );
    const [selectedTabId, setSelectedTabId] = useState();
    const [hasListChanged, setHasListChanged] = useState(false);

    // used for both pending and approved cards
    const getCardsList = useCallback(
        (
            apiUrl,
            componentId,
            targetKey = "preApprovals",
            status = PRE_PROD_CARD_STATUS.PENDING
        ) => {
            setDynamicDataApiStatus({
                [componentId]: LOADING_STATUS.LOADING,
            });
            getData(apiUrl, { clientCode })
                .then((cardListResponse) => {
                    const preApprovalListNormalisedObject = normalizerWithOrderArraySingleLevel(
                        addDataToAllObjectInArray(
                            cardListResponse.data.data[targetKey],
                            {
                                status,
                            }
                        )
                    );
                    setComponentData({
                        componentId,
                        data: preApprovalListNormalisedObject,
                    });
                    setDynamicDataApiStatus({
                        [componentId]: LOADING_STATUS.COMPLETED,
                    });
                })
                .catch((e) => {
                    setDynamicDataApiStatus({
                        [componentId]: LOADING_STATUS.FAILED,
                    });
                    EvLogger.errorWithObject(
                        e,
                        "PreProdContainer onFormSubmitClick"
                    );
                });
        },
        [setComponentData, setDynamicDataApiStatus, clientCode]
    );

    useEffect(() => {
        getStaticData({
            url: API_URLS.PRE_PROD.STATIC_DATA,
            templateId: PRE_PROD_TEMPLATES.PAGE_STATIC_DATA.id,
        }).then((staticDataResponse) => {
            setSelectedTabId(
                staticDataResponse.tabsData[0]
                    ? staticDataResponse.tabsData[0].id
                    : ""
            );
        });
    }, [getStaticData]);

    useEffect(() => {
        getCardsList(
            API_URLS.PRE_PROD.CARD_LIST,
            PRE_PROD_COMPONENTS.PRE_APPROVALS.id,
            "preApprovals",
            PRE_PROD_CARD_STATUS.PENDING
        );
    }, [getCardsList]);

    useEffect(
        () => () => {
            resetData();
        },
        [resetData]
    );

    const onTabChange = useCallback(
        (selectedId) => {
            if (
                selectedId === PRE_PROD_TAB_IDS.APPROVED &&
                controls.dynamicDataApiStatus[
                    PRE_PROD_COMPONENTS.APPROVED_LIST.id
                ] !== LOADING_STATUS.COMPLETED
            ) {
                getCardsList(
                    API_URLS.PRE_PROD.APPROVED_CARD_LIST,
                    PRE_PROD_COMPONENTS.APPROVED_LIST.id,
                    "recentlyPublished",
                    PRE_PROD_CARD_STATUS.APPROVED
                );
            } else if (
                selectedId === PRE_PROD_TAB_IDS.PENDING &&
                hasListChanged
            ) {
                getCardsList(
                    API_URLS.PRE_PROD.CARD_LIST,
                    PRE_PROD_COMPONENTS.PRE_APPROVALS.id,
                    "preApprovals",
                    PRE_PROD_CARD_STATUS.PENDING
                );
                setHasListChanged(false);
            }
            setSelectedTabId(selectedId);
        },
        [getCardsList, controls.dynamicDataApiStatus, hasListChanged]
    );

    const onApproveAll = useCallback(() => {
        const approvalbleCards = getApprovalCardIdsFromPreProdList(
            preApprovals.ids,
            preApprovals.value
        );
        if (approvalbleCards.length <= 0) {
            EvToast.warn("", "No cards to approve");
            return;
        }
        setApproveAllApiStatus(LOADING_STATUS.LOADING);
        postData(
            API_URLS.PRE_PROD.APPROVE_ALL,
            { clientCode },
            { ids: approvalbleCards }
        )
            .then((approveResponse) => {
                EvToast.success("Approved", "All cards are approved for PROD", {
                    icon: TOAST_ICON_OBJECTS.CHECK,
                });
                setApproveAllApiStatus(LOADING_STATUS.COMPLETED);
                getCardsList(
                    API_URLS.PRE_PROD.CARD_LIST,
                    PRE_PROD_COMPONENTS.PRE_APPROVALS.id,
                    "preApprovals",
                    PRE_PROD_CARD_STATUS.PENDING
                ); // TEMP: to reload the page data
                // setHasListChanged(true);  // no need, we are already reloading data
            })
            .catch((e) => {
                EvToast.error("Sorry", "Cannot approve cards for PROD", {
                    icon: TOAST_ICON_OBJECTS.ALERT,
                });
                EvLogger.errorWithObject(e, "PreProdContainer onApproveAll");
                setApproveAllApiStatus(LOADING_STATUS.FAILED);
            });
    }, [getCardsList, preApprovals, clientCode]);

    const onApproveAllClick = useCallback(() => {
        if (approveAllApiStatus === LOADING_STATUS.LOADING) {
            return;
        }
        EvSimpleModal.setData({
            ...staticData.approveAllCardsConfirmationModal,
            onPositiveAction: onApproveAll,
        }).show();
    }, [onApproveAll, staticData, approveAllApiStatus]);

    const onEditCard = useCallback(
        (e, callbackOptions) => {
            EvLogger.log(
                `PreProd: User clicked on edit programs for cardId: ${callbackOptions.cardId}`
            );
            navigateToPage({
                path: callbackOptions.path,
                state: callbackOptions.params,
            });
        },
        [navigateToPage]
    );

    const onCardApprove = useCallback(
        (id) => {
            setHasListChanged(true);
            setDynamicDataApiStatus({
                [PRE_PROD_COMPONENTS.APPROVED_LIST.id]:
                    LOADING_STATUS.NOT_YET_STARTED,
            });
        },
        [setDynamicDataApiStatus]
    );

    const onTestClick = useCallback(() => {
        if (staticData.testAllCta.action) {
            const qParams = {
                clientCode: clientCode.toBase64URL(),
            };
            const url = `${staticData.testAllCta.action}?${queryStringGenerator(
                qParams
            )}`;
            window.open(url, "_blank");
        }
    }, [staticData, clientCode]);

    if (
        !isCompleted(
            controls.staticDataApiStatus[PRE_PROD_TEMPLATES.PAGE_STATIC_DATA.id]
        )
    ) {
        return <EvLoadingPage />;
    }

    return (
        <PreProdView
            clientCode={clientCode}
            clientName={clientName}
            staticData={staticData}
            preApprovals={preApprovals}
            approvedCardList={approvedCardList}
            onApproveAll={onApproveAllClick}
            onEditCard={onEditCard}
            controls={controls}
            approveAllApiStatus={approveAllApiStatus}
            onTestClick={onTestClick}
            selectedTabId={selectedTabId}
            onTabChange={onTabChange}
            onCardApprove={onCardApprove}
        />
    );
};

PreProdContainer.propTypes = {
    id: PropTypes.string,
    clientCode: PropTypes.string,
    clientName: PropTypes.string,
    controls: PropTypes.object,
    staticData: PropTypes.object,
    preApprovals: PropTypes.object,
    approvedCardList: PropTypes.object,

    // actions
    getStaticData: PropTypes.func,
    setComponentData: PropTypes.func,
    setDynamicDataApiStatus: PropTypes.func,
    resetData: PropTypes.func,
    navigateToPage: PropTypes.func,
};

PreProdContainer.defaultProps = {
    id: "",
    clientCode: "",
    clientName: "",
    controls: {},
    staticData: {},
    preApprovals: {},
    approvedCardList: {},

    // actions
    getStaticData: () => {},
    setComponentData: () => {},
    setDynamicDataApiStatus: () => {},
    resetData: () => {},
    navigateToPage: () => {},
};

const mapStateToProps = (state) => ({
    controls: state.PreProdReducer.controls,
    staticData:
        state.PreProdReducer.staticData[PRE_PROD_TEMPLATES.PAGE_STATIC_DATA.id],
    preApprovals:
        state.PreProdReducer.dynamicData[PRE_PROD_COMPONENTS.PRE_APPROVALS.id],
    approvedCardList:
        state.PreProdReducer.dynamicData[PRE_PROD_COMPONENTS.APPROVED_LIST.id],
});

const mapDispatchToProps = (dispatch) => ({
    getStaticData: (payload) => dispatch(PreProdActions.getStaticData(payload)),
    setDynamicDataApiStatus: (payload) =>
        dispatch(PreProdActions.setDynamicDataApiStatus(payload)),
    setComponentData: (payload) =>
        dispatch(PreProdActions.setComponentData(payload)),
    resetData: (payload) => dispatch(PreProdActions.resetData(payload)),
    navigateToPage: (payload) => dispatch(push(payload.path, payload.state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreProdContainer);
