import React from "react";
import {
    EvDivider,
    EvIcon,
    EvIconText,
    EvNotificationIcon,
    EvText,
} from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";
import ICON_DATA from "../../../common/static/EvSvgIconData";
import { EV_ICON_TEXT_OBJECTS } from "../statics/EvLibraryIconsStatics";

import "../styles/ev-library-icons.scss";

const styles = {
    container: "ev_ev-library-icons__container",
    demo: {
        container: "ev_ev-library-icons__demo-container",
        iconContainer: "ev_ev-library-icons__demo-icon-container",
    },
    list: {
        container: "ev_ev-library-icons__list-container",
    },
    row: {
        labelContainer: "ev_ev-library-icons__row-label-container",
        labelText: "ev_ev-library-icons__row-label-text",
        container: "ev_ev-library-icons__row-container",
        name: "ev_ev-library-icons__row-name",
        iconWrapper: "ev_ev-library-icons__row-icon-wrapper",
    },
    component: {
        container: "ev_ev-library-icons__component-container",
        itemsContainer: "ev_ev-library-icons__component-items-container",
        itemWrapper: "ev_ev-library-icons__component-item-wrapper",
    },
};

const EvLibraryIcons = () => {
    const getEvIconsTextComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>EvIconText</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvIconText
                        iconObject={EV_ICON_TEXT_OBJECTS.type1}
                        containerInlineStyle={{ marginRight: 10 }}
                    />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvIconText
                        iconProps={{ circular: true }}
                        iconObject={EV_ICON_TEXT_OBJECTS.type2}
                        containerInlineStyle={{ marginRight: 10 }}
                    />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvIconText
                        iconObject={EV_ICON_TEXT_OBJECTS.type3}
                        containerInlineStyle={{ marginRight: 10 }}
                    />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvIconText
                        iconObject={EV_ICON_TEXT_OBJECTS.type4}
                        containerInlineStyle={{ marginRight: 10 }}
                    />
                </div>
            </div>
        </div>
    );

    const getEvIconsCountComponent = () => (
        <div className={styles.component.container}>
            <EvText subHeading>Icons with count</EvText>
            <EvDivider marginVertical={10} />
            <div className={styles.component.itemsContainer}>
                <div className={styles.component.itemWrapper}>
                    <EvNotificationIcon count={0} />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvNotificationIcon count={1} />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvNotificationIcon count={99} />
                </div>

                <div className={styles.component.itemWrapper}>
                    <EvNotificationIcon
                        iconFillColor={COLORS.PRODUCT}
                        count={10}
                        bubbleColor={COLORS.BLACK_TROUT}
                    />
                </div>
            </div>
        </div>
    );

    const getDemoIcons = () => (
        <div className={styles.demo.container}>
            <div className={styles.demo.iconContainer}>
                <EvIcon iconName="SETTINGS" fillColor={COLORS.PRODUCT} />
                <EvText>Default</EvText>
            </div>
            <div className={styles.demo.iconContainer}>
                <EvIcon
                    iconName="SETTINGS"
                    circular
                    bgColor={COLORS.PRODUCT}
                    fillColor={COLORS.WHITE}
                />
                <EvText>Circular</EvText>
            </div>
            <div className={styles.demo.iconContainer}>
                <EvIcon
                    iconName="SETTINGS"
                    bordered
                    circular
                    size={5}
                    borderColor={COLORS.PRODUCT}
                    fillColor={COLORS.PRODUCT}
                />
                <EvText>Bordered</EvText>
            </div>
        </div>
    );

    const getRowLabels = () => (
        <div className={styles.row.labelContainer}>
            <EvText subHeading className={styles.row.labelText}>
                Icon Name
            </EvText>
            <EvText subHeading className={styles.row.labelText}>
                Basic
            </EvText>
            <EvText subHeading className={styles.row.labelText}>
                Circular Fill
            </EvText>
            <EvText subHeading className={styles.row.labelText}>
                Circular Bordered
            </EvText>
            <EvText subHeading className={styles.row.labelText}>
                Bordered Fill
            </EvText>
        </div>
    );

    const getIconRow = (iconKey) => (
        <div key={iconKey} className={styles.row.container}>
            <EvText className={styles.row.name}>{iconKey}</EvText>
            <div key={`${iconKey}_1`} className={styles.row.iconWrapper}>
                <EvIcon
                    iconName={iconKey}
                    fillColor={COLORS.BLACK_TROUT}
                    strokeColor="#fff"
                    size={7}
                />
            </div>
            <div key={`${iconKey}_2`} className={styles.row.iconWrapper}>
                <EvIcon
                    iconName={iconKey}
                    bgColor={COLORS.PRODUCT}
                    fillColor="#fff"
                    circular
                    size={4}
                />
            </div>
            <div key={`${iconKey}_3`} className={styles.row.iconWrapper}>
                <EvIcon
                    iconName={iconKey}
                    fillColor={COLORS.BLACK_TROUT}
                    bgColor="#fff"
                    strokeColor="#fff"
                    size={4}
                    borderColor="#aaa"
                    circular
                    bordered
                />
            </div>
            <div key={`${iconKey}_4`} className={styles.row.iconWrapper}>
                <EvIcon
                    iconName={iconKey}
                    fillColor="#fff"
                    bgColor={COLORS.FRUIT_SALAD}
                    strokeColor="#fff"
                    size={4}
                    borderColor="#aaa"
                />
            </div>
        </div>
    );

    const getIconsList = () => (
        <div className={styles.list.container}>
            {Object.keys(ICON_DATA).map(getIconRow)}
        </div>
    );

    return (
        <div className={styles.container}>
            {getEvIconsTextComponent()}
            {getEvIconsCountComponent()}
            <EvDivider marginVertical={20} />
            {getDemoIcons()}
            <EvDivider marginVertical={20} />
            {getRowLabels()}
            <EvDivider marginVertical={20} />
            {getIconsList()}
        </div>
    );
};

export default EvLibraryIcons;
