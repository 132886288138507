import React from "react";
import PropTypes from "prop-types";

import "../styles/ev-loading-dots.scss";
import { EvIcon } from "./index";
import { COLORS } from "../static/VmsStatics";

const styles = {
    container: "ev__ev-loading-dots__container",
    icons: {
        icon: "ev__ev-loading-dots__icon",
        icon1: "ev__ev-loading-dots__icon-1",
        icon2: "ev__ev-loading-dots__icon-2",
        icon3: "ev__ev-loading-dots__icon-3",
    },
};

const EvLoadingDots = (props) => {
    const { size, color } = props;
    return (
        <div className={styles.container}>
            <EvIcon
                iconName="CIRCLE"
                size={size}
                fillColor={color}
                outerContainerStyleClass={`${styles.icons.icon} ${styles.icons.icon1}`}
            />
            <EvIcon
                iconName="CIRCLE"
                size={size}
                fillColor={color}
                outerContainerStyleClass={`${styles.icons.icon} ${styles.icons.icon2}`}
            />
            <EvIcon
                iconName="CIRCLE"
                size={size}
                fillColor={color}
                outerContainerStyleClass={`${styles.icons.icon} ${styles.icons.icon3}`}
            />
        </div>
    );
};

EvLoadingDots.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

EvLoadingDots.defaultProps = {
    size: 2,
    color: COLORS.BLACK_TROUT,
};

export default EvLoadingDots;
