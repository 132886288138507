import React, { memo } from "react";
import PropTypes from "prop-types";

import "../styles/ev-logo-preview.scss";

const EvLogoPreview = (props) => {
    const styles = {
        logoCard: {
            container: "ev__logo-preview__container",
            imageHolder: "ev__logo-preview__image-holder",
            imageItem: "ev__logo-preview__image-item",
        },
    };

    const { imgSrc, bgColor, imgUrl } = props;

    return (
        <div className={styles.logoCard.container}>
            <div
                className={styles.logoCard.imageHolder}
                style={{ backgroundColor: bgColor }}
            >
                {imgSrc && (
                    <img
                        alt=""
                        className={styles.logoCard.imageItem}
                        src={imgUrl}
                    />
                )}
            </div>
        </div>
    );
};

EvLogoPreview.propTypes = {
    imgSrc: PropTypes.string,
    bgColor: PropTypes.string,
    imgUrl: PropTypes.string,
};

EvLogoPreview.defaultProps = {
    imgSrc: "",
    bgColor: "",
    imgUrl: "",
};

export default memo(EvLogoPreview);
