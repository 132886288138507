import React from "react";

import "../styles/ev-library-typography.scss";
import { EvDivider, EvText } from "../../../common/components";
import { COLORS } from "../../../common/static/VmsStatics";

const styles = {
    container: "ev__ev-library-typography__container",
    textContainer: "ev__ev-library-typography__text-container",
    textWrapper: "ev__ev-library-typography__text-wrapper",
    text: "ev__ev-library-typography__text",
};

const dummyHeader = "I turn coffee into code...";
const dummyParagraph =
    "80 days around the world, we’ll find a pot of gold just sitting where the rainbow’s ending. Time — we’ll fight against the time, and we’ll fly on the white wings of the wind. 80 days around the world, no we won’t say a word before the ship is really back. Round, round, all around the world. Round, all around the world. Round, all around the world. Round, all around the world...";
const dummySmallSentence =
    "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...";

const EvLibraryTypography = () => {
    const getTextView = () => (
        <div className={styles.textContainer}>
            <div className={styles.textWrapper}>
                <EvText className={styles.text} headingPrimary>
                    HEADING PRIMARY, 20px 300 #505664
                </EvText>
                <EvText className={styles.text} headingPrimary>
                    {dummyHeader.toUpperCase()}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} headingSecondary>
                    Heading Secondary, 20px 800 #000a23
                </EvText>
                <EvText className={styles.text} headingSecondary>
                    {dummyHeader}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} subHeading>
                    SubHeading, 20px normal #000a23
                </EvText>
                <EvText className={styles.text} subHeading>
                    {dummyHeader}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text}>
                    Default, 16px normal #505664
                </EvText>
                <EvText className={styles.text}>{dummySmallSentence}</EvText>
                <EvText className={styles.text}>{dummyParagraph}</EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} defaultBold>
                    Default Bold, 16px 800 #505664
                </EvText>
                <EvText className={styles.text} defaultBold>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} defaultBold>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} defaultDark>
                    Default Dark, 16px normal #000a23
                </EvText>
                <EvText className={styles.text} defaultDark>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} defaultDark>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} defaultDarkBold>
                    Default Dark Bold, 16px 800 #000aa23
                </EvText>
                <EvText className={styles.text} defaultDarkBold>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} defaultDarkBold>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} primaryColored>
                    Default, Primary Colored, 16px 800
                </EvText>
                <EvText className={styles.text} primaryColored>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} primaryColored>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} color={COLORS.RED_MONZA}>
                    Default, Custom Colored, 16px 800
                </EvText>
                <EvText className={styles.text} color={COLORS.RED_MONZA}>
                    {dummySmallSentence}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} smallNormal>
                    Small Normal, 12px normal #505664
                </EvText>
                <EvText className={styles.text} smallNormal>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} smallNormal>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} smallBold>
                    Small Bold, 12px 800 #505664
                </EvText>
                <EvText className={styles.text} smallBold>
                    {dummySmallSentence}
                </EvText>
                <EvText className={styles.text} smallBold>
                    {dummyParagraph}
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} underlined>
                    Default Underlined
                </EvText>
                <EvText className={styles.text} underlined>
                    Can be in combination with others.
                </EvText>
            </div>

            <EvDivider marginVertical={10} />

            <div className={styles.textWrapper}>
                <EvText className={styles.text} italics>
                    Default Italics
                </EvText>
                <EvText className={styles.text} italics>
                    Can be in combination with others.
                </EvText>
                <EvText className={styles.text} italics>
                    {dummyParagraph}
                </EvText>
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            <EvDivider marginVertical={10} />
            {getTextView()}
        </div>
    );
};

export default EvLibraryTypography;
