import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { EvContentResolver, EvIcon, EvText, EvButton } from "./index";
import { COLORS } from "../static/VmsStatics";
import { CONTENT_FIELD_TYPES } from "../static/Enums";

import "../styles/ev-summary.scss";

const styles = {
    container: "ev__ev-summary__container",
    headerContainer: "ev__ev-summary__header-container",
    contentContainer: "ev__ev-summary__content-container",
    contentWrapper: "ev__ev-summary__content-wrapper",
};

const EvSummary = (props) => {
    const {
        id,
        segmentStaticData,
        editable,
        data,
        allTags,
        qParams,
        onSegmentEditClick,
    } = props;

    const onSegmentEditClickLocal = useCallback(() => {
        onSegmentEditClick(segmentStaticData.actionPage);
    }, [onSegmentEditClick, segmentStaticData.actionPage]);

    const getSegmentItem = (itemData) => (
        <div key={itemData.id} className={styles.contentWrapper}>
            <EvContentResolver
                id={itemData.id}
                label={itemData.label}
                type={itemData.type}
                dependency={itemData.dependency}
                displayResolver={itemData.displayResolver}
                data={data}
                options={itemData.data}
                itemData={itemData}
                qParams={qParams}
                otherData={{
                    allTags:
                        itemData.type === CONTENT_FIELD_TYPES.CRITERIA_ARRAY
                            ? allTags
                            : {},
                }}
            />
        </div>
    );

    const getEditButton = () => (
        <EvButton onClickHandler={onSegmentEditClickLocal} onlyChild>
            <EvIcon iconName="EDIT" size={3} fillColor={COLORS.PRODUCT} />
        </EvButton>
    );

    return (
        <div key={id} className={styles.container}>
            <div className={styles.headerContainer}>
                <EvText defaultBold>{segmentStaticData.heading}</EvText>
                {segmentStaticData.editable &&
                    editable &&
                    segmentStaticData.actionPage &&
                    getEditButton()}
            </div>
            {segmentStaticData.description && (
                <EvText>{segmentStaticData.description}</EvText>
            )}
            <div className={styles.contentContainer}>
                {segmentStaticData.fields.map(getSegmentItem)}
            </div>
        </div>
    );
};

EvSummary.propTypes = {
    id: PropTypes.string,
    segmentStaticData: PropTypes.object,
    editable: PropTypes.bool,
    data: PropTypes.object,
    allTags: PropTypes.object,
    qParams: PropTypes.object,
    onSegmentEditClick: PropTypes.func,
};

EvSummary.defaultProps = {
    id: "",
    segmentStaticData: {},
    editable: true,
    data: {},
    allTags: {},
    qParams: {},
    onSegmentEditClick: () => {},
};

export default EvSummary;
